import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { HistoryStorage } from '../../../../../utils/historyStorage';

export interface SetPagination {
	curPage?: number;
	totalCount?: number;
	listCount?: number;
	pageCount?: number;
}

interface UsePaginationParameter {
	initListCount?: number;
	initPageCount?: number;
	isCenter?: boolean;
	historyKey?: string;
}
export interface PaginationQueries {
	curPage: number;
	listCount: number;
}
export interface UsePaginationResponse {
	/** 현재 페이지 번호 */
	curPage: number;
	/** 현재 페이지 변경 */
	setCurPage: React.Dispatch<React.SetStateAction<number>>;
	/** 총 게시글 수 */
	totalCount: number;
	/** 한 페이지당 보여질 게시글의 수 */
	listCount: number;
	/** 한 화면에 출력 될 페이지네이션의 수 */
	pageCount: number;
	/** 총 페이지의 수 */
	totalPage: number;
	/** 화면에 보이는 페이지네이션의 시작 번호 */
	startPage: number;
	/** 한화면에 보이는 페이지네이션의 끝 번호 */
	endPage: number;
	/**
	 * 페이지네이션 설정
	 * @param param0
	 * @param param0.curPage 현재 페이지 설정
	 * @param param0.totalCount 총 게시글 수
	 * @param param0.listCount 한 페이지당 보여질 게시글의 수
	 * @param param0.pageCount 한 화면에 출력 될 페이지네이션의 수
	 */
	setPagination: (arg: SetPagination) => void;
	resetCurPage: () => void;
}
interface StoredData {
	storedCurPage: null | number;
	storedListCount: null | number;
}
/**
 * pagination setting hooks
 * @param {object} param0
 * @param {number} param0.initListCount - 한 페이지당 보여질 게시글의 수, 설정하지 않을 시 10
 * @param {number} param0.initPageCount - 한 화면에 출력 될 페이지네이션의 수, 설정하지 않을 시 5
 * @param {boolean} param0.isCenter - 페이지가 항상 가운데에 있도록 설정
 * @returns {object}
 */
const usePagination = (params?: UsePaginationParameter): UsePaginationResponse => {
	const { initListCount, initPageCount, isCenter, historyKey } = params ?? {};

	const history = historyKey && new HistoryStorage<PaginationQueries>(historyKey);

	// history에 저장되어 있을경우 값 설정
	const storedData = useMemo(() => {
		const result: StoredData = {
			storedCurPage: null,
			storedListCount: null,
		};
		if (history && historyKey) {
			const storedData = history.get();

			if (storedData) {
				result.storedCurPage = storedData.curPage;
				result.storedListCount = storedData.listCount;
			}
		}
		return result;
	}, []);

	const [curPage, setCurPage] = useState(storedData.storedCurPage ?? 1);
	const [totalCount, setTotalCount] = useState(0);
	const [listCount, setListCount] = useState(storedData.storedListCount ?? initListCount ?? 10);
	const [pageCount, setPageCount] = useState(initPageCount ?? 5);

	const totalPage = useMemo(() => {
		if (totalCount === 0) return 0;
		return Math.ceil(totalCount / listCount);
	}, [totalCount, listCount]);

	const handleHistory = useCallback(
		({ changeListCount, changeCurPage }: { changeListCount?: number; changeCurPage?: number }) => {
			if (history && historyKey) {
				history.set({ listCount: changeListCount ?? listCount, curPage: changeCurPage ?? curPage });
			}
		},
		[listCount, curPage, history, historyKey],
	);
	const startPage = useMemo(() => {
		let start = 1;
		if (isCenter) {
			start = curPage - Math.floor(pageCount / 2);
			// 시작 페이지가 1보다 작을 경우, 시작 페이지는 1로
			if (start < 1) start = 1;
			// 시작 페이지와 페이지 카운트를 더했을 때, 총 페이지 수를 넘어가는 경우
			if (start + pageCount - 1 > totalPage) start = totalPage - pageCount + 1;
			// 총 페이지 수가 페이지 카운트보다 작은 경우, 시작 페이지는 1
			if (totalPage < pageCount) start = 1;
		} else {
			start = Math.floor((curPage - 1) / pageCount) * pageCount + 1;
		}
		return start;
	}, [curPage, pageCount]);

	const endPage = useMemo(() => {
		const end = Math.min(startPage + pageCount - 1, totalPage);
		return end;
	}, [pageCount, startPage, totalPage]);

	const setPagination = ({ curPage, totalCount, listCount, pageCount }: SetPagination) => {
		if (typeof totalCount === 'number') setTotalCount(totalCount);
		if (typeof curPage === 'number') setCurPage(curPage);
		if (typeof listCount === 'number') setListCount(listCount);
		if (typeof pageCount === 'number') setPageCount(pageCount);

		handleHistory({ changeCurPage: curPage, changeListCount: listCount });
	};

	const resetCurPage = useCallback(() => {
		if (curPage > 1) {
			setCurPage(1);
			handleHistory({ changeCurPage: 1, changeListCount: listCount });
		}
	}, [curPage, listCount]);

	return {
		curPage,
		setCurPage,
		totalCount,
		listCount,
		pageCount,
		totalPage,
		startPage,
		endPage,
		setPagination,
		resetCurPage,
	};
};

export default usePagination;
