import { formatPathArray } from '../../../utils/format';
import { type MenuType } from '../../Nav/data/NavData';

/**
 * 재귀방법으로 menu search
 * @param menuList
 * @param path
 * @param pathIndex
 * @returns
 */
const findMenuRecursive = (menuList: MenuType[], path: string[], pathIndex = 0): MenuType | undefined => {
	for (const menu of menuList) {
		const formattedPath = formatPathArray(menu.path);
		// 현재 depth의 path가 일치하는지 확인
		if (formattedPath[0] === path[pathIndex]) {
			// 다음 depth가 없거나 마지막 path 요소에 도달했다면 label 반환
			if (!menu.nextDepth || pathIndex === path.length - 1) {
				return menu;
			}

			// 다음 depth 탐색
			const result = findMenuRecursive(menu.nextDepth, path, pathIndex + 1);
			if (result) {
				return result;
			}
		}
	}

	// 일치하는 항목을 찾지 못한 경우
	return undefined;
};

const findMenu = (list: MenuType[], path: string[]) => {
	return findMenuRecursive(list, path);
};

export default findMenu;
