import React from 'react';

import styled from 'styled-components';

const texts = [
	'단골 고객이 볼 수 있는 상세페이지에 사용되는 대표 사진입니다.',
	'권장 크기를 초과 시 자동 리사이징되며, 가로/세로 비율이 자동 크롭됩니다.',
	'5MB 이하의 JPG, JPEG, PNG 형식만 등록할 수 있습니다. (최대 5장)',
] as const;

export const ImageInfoText = () => {
	return (
		<Container>
			{texts.map((text, idx) => (
				<li key={'image--info--' + idx}>{text}</li>
			))}
		</Container>
	);
};

const Container = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;

	li {
		color: ${({ theme }) => theme.colors.gray.gray_800};
		${({ theme }) => theme.font.body.body_3};
		&::before {
			content: '- ';
		}
	}
`;
