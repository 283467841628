import { useQuery } from '@tanstack/react-query';

import { type GetContractsDetailRequest, type GetContractsRequest } from './types';
import { api } from '..';

export const contractsKeys = {
	all: ['contracts'] as const,
	getContracts: (params: GetContractsRequest) => [...contractsKeys.all, params] as const,
	getContractsDetail: (params: GetContractsDetailRequest) => [...contractsKeys.all, 'detail', params] as const,
};

export const useGetContracts = (params: GetContractsRequest) => {
	const { storeId } = params;
	const queryInfo = useQuery({
		queryKey: contractsKeys.getContracts(params),
		queryFn: async () => await api.contracts.getContracts(params),
		enabled: !!storeId,
	});

	return queryInfo;
};

export const useGetContractsDetail = (params: GetContractsDetailRequest) => {
	const { storeId, id } = params;
	const queryInfo = useQuery({
		queryKey: contractsKeys.getContractsDetail(params),
		queryFn: async () => await api.contracts.getContractsDetail(params),
		enabled: !!storeId && !!id,
	});

	return queryInfo;
};
