import React, { useCallback, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FormItem, TextArea, TextField } from '../../../../components/Forms';
import { Modal, type ModalProps } from '../../../../components/Modals';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { useGetTemplateDetail, usePostTemplates, usePutTemplateDetail } from '../../../../services/templates/queries';
import { type PostTemplatesRequest } from '../../../../services/templates/types';

interface PhraseModalProps extends ModalProps {
	refetch: () => void;
	phraseId?: number;
}

export const PhraseModal = ({ phraseId, refetch }: PhraseModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleClose } = context.modal.useDispatch();

	// phraseId의 여부로 새 문구 등록인지, 문구 수정인지 판단
	const typePhrase = useMemo(() => {
		const result = {
			title: '새 문구 등록',
			button: '등록',
		};
		if (phraseId) {
			result.title = '문구 관리';
			result.button = '저장';
		}
		return result;
	}, [phraseId]);

	const initValues: PostTemplatesRequest = useMemo(
		() => ({ storeId: userInfo.storeId, content: '', title: '' }),
		[userInfo.storeId],
	);
	const { values, onChangeValues, dispatch } = useValues(initValues);

	// 문구아이디가 있을 시 문구 데이터 정보를 받아옴
	const {
		data,
		isSuccess,
		refetch: detailRefetch,
	} = useGetTemplateDetail({ storeId: userInfo?.storeId, id: phraseId });

	// 받아온 데이터가 있을 시 values에 setting
	useEffect(() => {
		if (isSuccess && !!data) dispatch('SET', { content: data.content, title: data.title });
	}, [data, isSuccess]);

	// content, title이 있어야 등록/수정 가능
	const isValid = useMemo(() => {
		return values.content.length > 0 && values.title.length > 0;
	}, [values]);

	// 새 문구 등록
	const { mutate: postMutate } = usePostTemplates({
		onSuccess: () => {
			toast.success('새 문구가 정상적으로 등록되었습니다.');
			handleClose();
			refetch();
		},
		onError: (err) => {
			toast.error('새 문구 등록에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	// 문구 수정
	const { mutate: putMutate } = usePutTemplateDetail({
		onSuccess: () => {
			toast.success('입력한 정보가 저장되었습니다.');
			detailRefetch();
			handleClose();
			refetch();
		},
		onError: (error) => {
			toast.error(`저장에 실패하였습니다.\n${error.response?.data.message}`);
		},
	});

	// submit function
	const onSubmit = useCallback(() => {
		if (!userInfo) return;
		// 문구 수정 일 때
		if (phraseId) {
			putMutate({ id: phraseId, ...values });
		}
		// 새 문구 저장일 떄
		else {
			postMutate(values);
		}
	}, [userInfo, values, phraseId]);
	return (
		<Modal
			header={{ title: typePhrase.title }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: typePhrase.button,
						onClick: onSubmit,
						shouldPrevent: true,
						style: { width: '10rem' },
						disabled: !isValid,
					},
				],
			}}
		>
			<Container>
				<FormItem label={'제목'} isRequired>
					<TextField
						placeholder="제목을 입력해주세요."
						name="title"
						value={values.title}
						onChange={onChangeValues}
						maxLength={20}
						count={{ show: true, max: 20 }}
					/>
				</FormItem>
				<FormItem label={'내용'} isRequired>
					<TextArea
						maxLength={300}
						style={{ height: '17.6rem' }}
						count={{ show: true, max: 300 }}
						placeholder="내용을 입력해주세요."
						name="content"
						value={values.content}
						onChange={onChangeValues}
					/>
				</FormItem>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	min-width: 80rem;

	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem;
`;
