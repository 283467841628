import React from 'react';

import { SNS_VALID_MESSAGE, SNSLabels } from './consts';
import { FormItem, TextField } from '../../../../components/Forms';
import { type SNSType } from '../../../../services/pharm/types';

interface SnsInputProps {
	type: SNSType;
	value: string;
	onChange?: (type: SNSType, value: string) => void;
}
export const SnsInput = ({ type, value, onChange }: SnsInputProps) => {
	return (
		<FormItem
			label={SNSLabels[type].label}
			labelStyle={{ width: '11.2rem', gap: '0.8rem' }}
			renderLabelPrefix={
				<img
					src={'/images/sns' + SNSLabels[type].src}
					alt={SNSLabels[type].src}
					style={{ width: '2rem', height: '2rem' }}
				/>
			}
			statusMessage={value.length && !value.includes(SNSLabels[type].validUrl) ? SNS_VALID_MESSAGE : ''}
		>
			<TextField
				placeholder={'https://'}
				value={value}
				onChange={(e) => {
					onChange?.(type, e.target.value);
				}}
			/>
		</FormItem>
	);
};
