import React, { useRef, useState } from 'react';

import { PushTriggerOption } from '@sendbird/chat';
import { type GroupChannel } from '@sendbird/chat/groupChannel';
import styled from 'styled-components';

import { getChannelTitle, getChannelUnreadMessageCount, getLastMessage, getLastMessageCreatedAt } from './utils';
import { Flex } from '../../../../components/Common';
import { Badge, Icon, IconID, Label } from '../../../../components/Display';
import { ContextMenu, MenuItems } from '../../../../components/Modals/ContextMenu';
import { useSBStateContext } from '../../../../context/sendbirdSdk';
import { getMember } from '../../../Channel/context/utils';
import { ChannelAvatar } from '../ChannelAvatar';
import { ChannelStatusMenu } from '../ChannelStatusMenu';

export interface ChannelListItemProps {
	channel: GroupChannel;
	onClick: () => void;
	isSelected?: boolean;
}
export const ChannelListItem = (props: ChannelListItemProps) => {
	const { channel, onClick, isSelected = false } = props;
	const { stores } = useSBStateContext();
	const { userId } = stores.userStore.user;
	const itemRef = useRef<HTMLDivElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const { profileUrl, connectionStatus } = getMember(channel, userId);

	return (
		<>
			<Container
				$isSelected={isSelected}
				onClick={onClick}
				ref={itemRef}
				onContextMenu={(e) => {
					e.preventDefault();
					setMenuOpen(true);
				}}
			>
				<Wrapper>
					<ChannelAvatar
						profileUrl={profileUrl}
						status={{ isOnline: connectionStatus === 'online', borderWidth: '0.2rem', statusSize: '1.4rem' }}
					/>
					<Flex $direction={'column'} $alignItems={'start'} $flex={'1 0 0'}>
						<Content $justify={'between'} $alignItems={'center'} $alignSelf={'stretch'}>
							<Flex $alignItems={'center'} $gap={'0.4rem'}>
								<Label className={'channel-name'} $fontWeight={'bold'} $fontStyle={'title_3'}>
									{getChannelTitle(channel, userId)}
								</Label>
								{channel.myPushTriggerOption === PushTriggerOption.OFF && (
									<Flex $flex={'0'} $justify={'center'} $alignItems={'center'}>
										<Icon id={IconID.NOTIFICATION_OFF} width={'1.4rem'} height={'1.4rem'} />
									</Flex>
								)}
							</Flex>
							<Label className={'last-message-at'} $fontStyle={'caption_1'} $color={'gray_600'}>
								{getLastMessageCreatedAt(channel)}
							</Label>
						</Content>
						<Content $alignItems={'center'} $justify={'between'} $gap={'8px'} $alignSelf={'stretch'}>
							<Label className={'last-message'} $fontStyle={'body_3'} $color={'gray_700'}>
								{getLastMessage(channel)}
							</Label>
							{getChannelUnreadMessageCount(channel) ? (
								<Badge count={getChannelUnreadMessageCount(channel)} bgColor={'red_600'} />
							) : null}
						</Content>
					</Flex>
				</Wrapper>
			</Container>
			{menuOpen && (
				<ContextMenu
					isOpen={menuOpen}
					menuItems={() => {
						return (
							<MenuItems
								parentRef={itemRef}
								closeDropdown={() => {
									setMenuOpen(false);
								}}
								isOnCursor={true}
							>
								<ChannelStatusMenu
									channel={channel}
									currentUserId={userId}
									close={() => {
										setMenuOpen(false);
									}}
								/>
							</MenuItems>
						);
					}}
				/>
			)}
		</>
	);
};

const Container = styled(Flex)<{ $isSelected: boolean }>`
	padding: 1.6rem 0;
	flex-direction: column;
	cursor: pointer;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background-color: ${({ theme, $isSelected }) =>
		$isSelected ? theme.colors.primary.primary_100 : theme.colors.white};

	${Label} {
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;

		&.channel-name {
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&.last-message-at {
			min-width: fit-content;
			white-space: nowrap;
		}

		&.last-message {
			height: 3.2rem;
			max-width: 17rem;
		}
	}
`;

const Wrapper = styled(Flex)`
	padding: 0 1.6rem;
	align-items: flex-start;
	gap: 1.2rem;
	align-self: stretch;
	overflow: hidden;
	width: 100%;
	> :first-child {
		min-width: 4rem;
	}
	> :last-child {
		width: calc(100% - 7.2rem);
	}
`;

const Content = styled(Flex)`
	width: 100%;
	overflow: hidden;

	> :first-child {
		width: 100%;
		overflow: hidden;
	}
`;
