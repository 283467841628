import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../../../components/Buttons';
import { Icon, IconID } from '../../../components/Display';

const Navigate = () => {
	const navigate = useNavigate();
	return (
		<Container>
			<IconButton
				onClick={() => {
					navigate(-1);
				}}
				// disabled={location.key === 'default'}
			>
				<Icon
					id={IconID.BTN_ROUND_BACK}
					defaultColor={'gray_900'}
					disabledColor={'gray_500'}
					// isDisabled={location.key === 'default'}
				/>
			</IconButton>
			{/* 앞으로가기 버튼 */}
			{/* <IconButton
				onClick={() => {
					navigate(1);
				}}
				disabled={window.history.state?.idx > 0}
			>
				<Icon
					id={IconID.BTN_ROUND_NEXT}
					defaultColor={'gray_900'}
					disabledColor={'gray_500'}
					isDisabled={window.history.state?.idx > 0}
				/>
			</IconButton> */}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 0.4rem;
`;
export default Navigate;
