import { useEffect, useMemo } from 'react';

import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import {
	type GetTemplatesRequest,
	type DeleteTemplatesRequest,
	type PostTemplatesRequest,
	type GetTemplateDetailParams,
	type PutTemplateDetailRequest,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';

export const templatesKeys = {
	all: ['templates'] as const,
	getList: (pagination: PaginationQueries, values: GetTemplatesRequest) =>
		[...templatesKeys.all, 'list', values, pagination] as const,
	getInfinityList: (values: GetTemplatesRequest) => [...templatesKeys.all, 'list', 'infinity', values] as const,
	getTemplateDetail: (id?: number, storeId?: string | undefined) =>
		[...templatesKeys.all, 'detail', id, storeId] as const,
	getTemplateUsage: (storeId: string) => [...templatesKeys.all, 'usage', storeId],
};
interface UseGetTemplates {
	isAll: boolean;
}

export const useGetTemplates = (storeId: string, props?: UseGetTemplates) => {
	const { isAll } = props ?? {};
	const initValues: GetTemplatesRequest = useMemo(
		() => ({
			storeId,
			sorts: {
				property: 'createdAt',
				direction: 'DESC',
			},
		}),
		[storeId],
	);
	const pagination = usePagination({ initListCount: isAll ? 999 : 10 });
	const { curPage, listCount, setPagination } = pagination;
	const queries = useValues(initValues);

	const queryInfo = useQuery({
		queryKey: templatesKeys.getList({ curPage, listCount }, queries.values),
		queryFn: async () =>
			await api.templates.getTemplates({
				page: curPage,
				limit: listCount,
				...queries.values,
			}),
		enabled: !!storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const useGetInfinityTemplates = (storeId: string, limit: number = 6) => {
	const initValues: GetTemplatesRequest = useMemo(
		() => ({
			storeId,
			sorts: {
				property: 'createdAt',
				direction: 'DESC',
			},
		}),
		[storeId],
	);
	const queries = useValues(initValues);

	const queryInfo = useInfiniteQuery({
		queryKey: templatesKeys.getInfinityList(queries.values),
		queryFn: async ({ pageParam }) => {
			const data = await api.templates.getTemplates({ page: pageParam, limit, ...queries.values });
			return data;
		},
		getNextPageParam: (lastPage, pages) => {
			const maxPages = Math.ceil(lastPage.total / limit);
			const nextPage = pages.length + 1;
			return nextPage <= maxPages ? nextPage : undefined;
		},
		initialPageParam: 1,
	});

	return { ...queryInfo };
};

export const useDeleteTemplates = (props: MutateCallback<DeleteTemplatesRequest, any>) => {
	const { onError, onSuccess } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: DeleteTemplatesRequest) => await api.templates.deleteTemplates(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const usePostTemplates = (props: MutateCallback<PostTemplatesRequest, any>) => {
	const { onError, onSuccess } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PostTemplatesRequest) => await api.templates.postTemplates(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetTemplateDetail = ({ storeId, id }: GetTemplateDetailParams) => {
	const queryInfo = useQuery({
		queryKey: templatesKeys.getTemplateDetail(id, storeId),
		queryFn: async () => await api.templates.getTemplateDetail({ storeId, id }),
		enabled: !!id && !!storeId,
	});
	return queryInfo;
};

export const usePutTemplateDetail = (props: MutateCallback<PutTemplateDetailRequest, any>) => {
	const { onError, onSuccess } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PutTemplateDetailRequest) => await api.templates.putTemplateDetail(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetTemplatesUsage = (storeId: string) =>
	useQuery({
		queryKey: templatesKeys.getTemplateUsage(storeId),
		queryFn: async () => await api.templates.getTemplatesUsage(storeId),
		enabled: !!storeId,
	});
