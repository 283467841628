import { type Columns } from '../../../../components/Table/ListTable/types';
import { type MemberData } from '../../../../services/member/types';
import { GENDER_VALUES } from '../../../../utils/consts';
import { formatBirth } from '../../../../utils/format';

export const groupCreateMemberModalTableColumns: Columns<MemberData> = [
	{ key: 'name', headerName: '회원명', isClick: true },
	{ key: 'birth', headerName: '생년월일', isClick: true },
	{
		key: 'gender',
		headerName: '성별',
		isClick: true,
		renderComponent: (value) => GENDER_VALUES[value],
	},
	{
		key: 'registrationAt',
		headerName: '약국 등록일',
		renderComponent: (value) => formatBirth(value),
		isClick: true,
	},
];
