import { Single, type SingleProps } from './Single';
import { SingleCardList, type SingleCardListProps } from './SingleCardList';
import { WithDates, type WithDatesProps } from './WithDates';
import { fractionNumber } from '../../../utils/format';

type CompareType = 'count' | 'percent';

export interface ComparisonCardProps {
	title: string;
	value: { cur?: number; prev?: number };
	unit?: string;
	type?: CompareType;
}
export const ComparisonCard = {
	Single,
	WithDates,
	CardList: SingleCardList,
};

export type { SingleProps, WithDatesProps, SingleCardListProps };

export function calculateDiff(type: CompareType, cur: number, prev: number) {
	if (prev === 0) {
		return type === 'count' ? cur : 0;
	}

	const sub = cur - prev;

	if (type === 'count') {
		return sub;
	}

	const percentage = sub / prev;

	if (!isNaN(percentage)) {
		return fractionNumber(percentage * 100, 1);
	}

	return 0;
}
