import { type Columns } from '../../../../../../components/Table/ListTable/types';
import { type MemberPayment } from '../../../../../../services/member/types';
import { PAY_TYPE_VALUES, MEDICATION_VALUES } from '../../../../../../utils/consts';

export const paymentTableColumn: Columns<MemberPayment> = [
	{
		key: 'approvalAt',
		headerName: '승인일시',
		width: { max: '', min: '29rem' },
		isClick: true,
	},
	{
		key: 'medicationType',
		headerName: '거래 유형',
		renderComponent: (value) => MEDICATION_VALUES[value],
		width: { max: '', min: '29rem' },
		defaultValue: '-',
		isClick: true,
	},
	{
		key: 'payType',
		headerName: '결제 유형',
		renderComponent: (value) => PAY_TYPE_VALUES[value],
		width: { max: '', min: '29rem' },
		isClick: true,
	},
	{
		key: 'totalAmount',
		headerName: '총 결제 금액',
		renderComponent: (value) => value.toLocaleString() + '원',
		style: { justifyContent: 'flex-end' },
		width: { max: '', min: '29rem' },
		isClick: true,
	},
];
