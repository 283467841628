import React, { useEffect, useMemo } from 'react';

import { TransformComponent, useControls } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { IconButton } from '../../Buttons';
import { Icon, IconID, InfoText, Label } from '../../Display';

import { CANVAS_ID } from './index';

interface Handlers {
	zoomIn: () => void;
	zoomOut: () => void;
	resetTransform: () => void;
}

interface ViewerTransformProps {
	isQr: boolean;
	resetZoomRef: React.RefObject<HTMLButtonElement>;
	contentRef: React.RefObject<HTMLDivElement>;
	count: { total: number; current: number };
	expiredAt: { show: boolean; value: string };
	setCanvasRef: (node: HTMLCanvasElement | null) => void;
}
export const ViewerTransform = ({
	isQr,
	resetZoomRef,
	contentRef,
	count,
	expiredAt,
	setCanvasRef,
}: ViewerTransformProps) => {
	const { zoomIn, zoomOut, resetTransform } = useControls();

	const handleQrSize = (type: 'zoomIn' | 'zoomOut') => {
		const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
		const currentSize = canvas.style.width || canvas.style.height;
		const sizeArr = ['10rem', '20rem', '30rem', '50rem'];
		const indexOf = sizeArr.indexOf(currentSize);
		if ((indexOf === 0 && type === 'zoomOut') || (indexOf === sizeArr.length - 1 && type === 'zoomIn')) {
			return;
		}
		switch (type) {
			case 'zoomIn':
				canvas.style.width = sizeArr[indexOf + 1];
				canvas.style.height = sizeArr[indexOf + 1];
				break;
			case 'zoomOut':
				canvas.style.width = sizeArr[indexOf - 1];
				canvas.style.height = sizeArr[indexOf - 1];
				break;
			default:
		}
	};

	const handlers: Handlers = useMemo(
		() =>
			isQr
				? {
						zoomIn: () => {
							handleQrSize('zoomIn');
						},
						zoomOut: () => {
							handleQrSize('zoomOut');
						},
						resetTransform,
					}
				: {
						zoomIn,
						zoomOut,
						resetTransform,
					},
		[isQr, handleQrSize, zoomIn, zoomOut, resetTransform],
	);

	useEffect(() => {
		const handleWheel = (event: WheelEvent) => {
			if (isQr) {
				if (event.deltaY > 0) {
					handlers.zoomOut();
				} else {
					handlers.zoomIn();
				}
			}
		};
		if (contentRef.current) {
			contentRef.current.addEventListener('wheel', handleWheel);
		}

		return () => {
			if (contentRef.current) {
				contentRef.current.removeEventListener('wheel', handleWheel);
			}
		};
	}, [isQr]);

	return (
		<React.Fragment>
			<BottomWrapper>
				{count.total > 1 && (
					<CountWrapper>
						<Icon id={IconID.VIEWER_PHOTO} width={'2rem'} height={'2rem'} />
						<Label $color={'white'} $textAlign={'center'} $fontStyle={'title_3'} $fontWeight={'bold'}>
							{count.current}
						</Label>
						<Label $color={'white'} $textAlign={'center'} $fontStyle={'title_3'}>
							{' / ' + count.total}
						</Label>
					</CountWrapper>
				)}
				<ZoomButtonWrapper>
					<ZoomButton
						onClick={() => {
							handlers.zoomOut();
						}}
					>
						<Icon id={IconID.VIEWER_ZOOM_OUT} className={'zoom-out'} />
					</ZoomButton>
					<ZoomButton
						onClick={() => {
							handlers.zoomIn();
						}}
					>
						<Icon id={IconID.VIEWER_ZOOM_IN} className={'zoom-in'} />
					</ZoomButton>
					<ZoomButton
						ref={resetZoomRef}
						style={{ display: 'none' }}
						onClick={() => {
							handlers.resetTransform();
						}}
					>
						<></>
					</ZoomButton>
				</ZoomButtonWrapper>
				{expiredAt.show && (
					<InfoText
						text={expiredAt.value + '에 이미지가 만료됩니다.'}
						color={{ background: 'gray_900', font: 'gray_200' }}
						innerStyle={{ alignItems: 'center' }}
						style={{ backgroundColor: 'transparent', position: 'absolute', right: 0 }}
					/>
				)}
			</BottomWrapper>
			<TransformComponent>
				<ViewContent ref={contentRef}>
					<canvas id={CANVAS_ID} ref={setCanvasRef}></canvas>
				</ViewContent>
			</TransformComponent>
		</React.Fragment>
	);
};

const BottomWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 6.4rem;
	padding: 0.8rem 2.4rem;
	align-items: center;
	position: absolute;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1009;

	.mark-info-icon-wrapper {
		> div > svg {
			color: rgba(0, 0, 0, 0.7);
		}
	}
`;

const CountWrapper = styled.div`
	display: flex;
	padding: 0.6rem 1.2rem;
	align-items: center;
	gap: 0.8rem;
	border-radius: 999px;
	background: rgba(0, 0, 0, 0.3);
`;

const ZoomButtonWrapper = styled.div`
	display: flex;
	position: absolute;
	left: calc(50% - 4.8rem);
	border-radius: 4px;
	overflow: hidden;
	padding: 0.8rem 1.2rem;
	gap: 1.6rem;
`;

const ZoomButton = styled(IconButton)`
	width: 4.4rem;
	height: 4.4rem;
`;

const ViewContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;

	@page {
		display: block;
	}

	@media print {
		display: block;
		> canvas {
			width: auto;
			height: auto;
		}
	}
`;
