import React, { useState } from 'react';

import styled from 'styled-components';

import { VStack } from '../../../../components/Common';
import { Modal } from '../../../../components/Modals';
import context from '../../../../context';
import { CustomDatePicker } from '../../../../lib/reactDatepicker/CustomDatePicker';

interface RestDayModalProps {
	excludeDates: string[];
	onRegister: (date: Date) => void;
}
export const RestDayModal = ({ onRegister, excludeDates }: RestDayModalProps) => {
	const [selected, setSelected] = useState(() => new Date());
	const { handleClose } = context.modal.useDispatch();
	const handleDateChange = (date: Date) => {
		setSelected(date);
	};

	const handleMonthChange = (date: Date) => {
		setSelected(date);
	};
	return (
		<Modal
			header={{ title: '임시 휴무일 등록' }}
			footer={{
				$hasBorderTop: true,
				button: [
					{
						onClick: handleClose,
						buttonType: 'LINE',
						color: 'SECONDARY',
						children: '취소',
						style: { width: '10rem' },
					},
					{
						onClick: () => {
							onRegister(selected);
						},
						color: 'PRIMARY',
						children: '등록',
						shouldPrevent: true,
						style: { width: '10rem' },
					},
				],
			}}
		>
			<Wrapper>
				<CustomDatePicker
					customInput={<></>}
					showMonthYearPicker={false}
					inline
					selected={selected}
					onChange={handleDateChange}
					onSelect={handleDateChange}
					onMonthChange={handleMonthChange}
					minDate={new Date()}
					excludeDates={excludeDates.map((date) => new Date(date))}
				/>
			</Wrapper>
		</Modal>
	);
};

const Wrapper = styled(VStack)`
	padding: 0 1.6rem;
	align-items: center;
	gap: 1.2rem;
	align-self: stretch;
	width: 36rem;
	max-height: 90rem;
	.react-datepicker {
		width: 32.8rem;
		box-shadow: none;
	}
`;
