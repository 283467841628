import { type GroupChannel, type Member } from '@sendbird/chat/groupChannel';

import { MEMBER_USERID_PREFIX, PHARM_USERID_PREFIX } from '../../../pages/CRM/Chat/consts';

export function isContextMenuClosed() {
	return document.getElementById('goodpharm-contextmenu-portal')?.childElementCount === 0;
}

export function getMessageTopOffset(messageCreatedAt: number): number | null {
	const element = document.querySelectorAll(`[data-sb-created-at="${messageCreatedAt}"]`)?.[0];
	if (element instanceof HTMLElement) {
		return element.offsetTop - 10;
	}
	return null;
}

// 개발 : goodpharm_dev_group_channels_{storeId}_{userId}
// 운영 : goodpharm_group_channels_{storeId}_{userId}
export function isInvalidChannelUrl(channel: GroupChannel, currentUserId: string, memberUserId: string): boolean {
	const storeId = getStoreId(currentUserId);
	const memberId = getMemberId(memberUserId).toString();
	if (process.env.REACT_APP_HOST_ENV === 'production') {
		return channel.url !== `goodpharm_group_channels_${storeId}_${memberId}`;
	}
	if (process.env.REACT_APP_HOST_ENV === 'development') {
		return channel.url !== `goodpharm_dev_group_channels_${storeId}_${memberId}`;
	}
	return false;
}
export type DisabledReason = 'frozen' | 'mute' | 'invalid' | 'none';
export function getDisabledReason(channel: GroupChannel | null, currentUserId: string): DisabledReason {
	if (!channel) return 'invalid';
	if (channel.isFrozen) {
		return 'frozen';
	}

	const { isMuted, userId } = getMember(channel, currentUserId) ?? {};

	if (isMuted) {
		return 'mute';
	}

	if (isInvalidChannelUrl(channel, currentUserId, userId) || channel.memberCount !== 2) {
		return 'invalid';
	}

	return 'none';
}

export function getMember(channel: GroupChannel | null, currentUserId: string): Member {
	return (channel?.members ?? []).find((member) => member.userId !== currentUserId)! ?? {};
}

export function getMemberId(userId: string): number {
	try {
		return Number(userId?.replace(MEMBER_USERID_PREFIX, ''));
	} catch (e) {
		return 0;
	}
}

export function getStoreId(userId: string): string {
	try {
		return userId.replace(PHARM_USERID_PREFIX, '');
	} catch (e) {
		return '';
	}
}
