import React, { useMemo } from 'react';

import styled from 'styled-components';

import { type FilterProps } from '../..';
import { type SearchRequest } from '../../../../services/types';
import { isValidCheck } from '../../../../utils/isValidCheck';
import { Button } from '../../../Buttons';
import { Icon, IconID } from '../../../Display';
import { FilterListItem } from '../FilterListItem';
import { FilterListItemTag } from '../FilterListItemTag';

export const FilterList = <T extends object>({ items, resetCurPage, queries }: FilterProps<T>) => {
	const { values, dispatch } = queries;
	const activeItems = useMemo(() => {
		return items.filter((item) => isValidCheck(String(item.key), values[item.key]));
	}, [items, values]);

	if (activeItems.length > 0 || (values as SearchRequest<T>)?.search?.searchKeyword) {
		return (
			<FilterListWrapper>
				<div className="item__wrapper">
					{activeItems.map((item) => (
						<FilterListItem<T>
							key={'active-item-' + String(item.key)}
							item={item}
							queries={queries}
							resetCurPage={resetCurPage}
						/>
					))}
					{(values as SearchRequest<T>)?.search?.searchKeyword && (
						<FilterListItemTag
							label={'검색'}
							value={(values as SearchRequest<T>).search.searchKeyword!}
							handleReset={() => {
								dispatch('DELETE', 'search' as keyof T);
							}}
						/>
					)}
				</div>
				<Button
					buttonType="LINE"
					className="reset__button"
					size={{ $fontSize: 'S', $paddingSize: 'M', $heightSize: 'M' }}
					onClick={() => {
						resetCurPage();
						dispatch('RESET');
					}}
				>
					<Icon id={IconID.ROTATE_RIGHT} defaultColor="primary_600" width="1.6rem" height="1.6rem" />
					초기화
				</Button>
			</FilterListWrapper>
		);
	}
	return <React.Fragment />;
};

const FilterListWrapper = styled.ul`
	display: flex;
	gap: 1.2rem;
	padding-top: 1.6rem;
	border-top: 1px solid #e9ecef;
	margin-top: 1.6rem;

	.item__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 0.8rem;
		flex: 1;
	}
`;
