import React, { Fragment, createContext, useCallback, useContext, useState } from 'react';

import { useWindowKeyDown } from '../../hooks/useKeyDown';

import type { ModalContextType } from './types';
import type { Children } from '../../components/types';

const ModalContext = createContext<ModalContextType>({
	handleOpen: () => {},
	handleClose: () => {},
});

export const ModalProvider = ({ children }: Children) => {
	const [modals, setModals] = useState<JSX.Element[]>([]);

	const handleOpen = useCallback((props: JSX.Element) => {
		setModals((prev) => [...prev, props]);
	}, []);

	const handleClose = useCallback((options?: { isAll?: boolean }) => {
		setModals((prev) => (options?.isAll ? [] : prev.slice(0, -1)));
	}, []);

	useWindowKeyDown('Escape', () => {
		if (modals.length > 0) {
			handleClose();
		}
	});

	return (
		<ModalContext.Provider value={{ handleOpen, handleClose }}>
			{children}
			<div id={'goodpharm-contextmenu-portal'} style={{ position: 'relative', zIndex: 991 }} />
			<div id={'modal-portal'} style={{ position: 'relative', zIndex: 992 }} />
			{modals.length > 0 && modals.map((modal, idx) => <Fragment key={'modal--' + String(idx)}>{modal}</Fragment>)}
		</ModalContext.Provider>
	);
};

const useDispatch = () => {
	const ctx = useContext(ModalContext);
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!ctx) throw new Error('useContext should be in Provider');
	return ctx;
};

export default { useDispatch };
