import { useEffect, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
	type DetailDefaultParams,
	type GetPharmNoticeRequest,
	type DeletePharmRestDayRequest,
	type OpenHours,
	type PharmRequest,
	type PostPharmRestDaysRequest,
	type PutPharmProfileRequest,
	type PostNoticeRequest,
	type NoticeItem,
} from './types';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';
import { api } from '../index';

export const pharmsKeys = {
	all: ['pharms'] as const,
	info: ({ storeId }: PharmRequest) => [...pharmsKeys.all, 'info', storeId] as const,
	image: ({ storeId }: PharmRequest) => [...pharmsKeys.all, 'image', storeId] as const,
	profile: ({ storeId }: PharmRequest) => [...pharmsKeys.all, 'profile', storeId] as const,
	hours: ({ storeId }: PharmRequest) => [...pharmsKeys.all, 'hours', storeId] as const,
	rest: ({ storeId }: PharmRequest) => [...pharmsKeys.all, 'rest', storeId] as const,
	noticeList: (queries: GetPharmNoticeRequest, pagination: PaginationQueries) =>
		[...pharmsKeys.all, 'noticeList', queries, pagination] as const,
	noticeDetail: ({ storeId, id }: DetailDefaultParams) => [...pharmsKeys.all, 'noticeDetail', storeId, id] as const,
} as const;

export const useGetPharmImage = ({ storeId }: PharmRequest) => {
	return useQuery({
		queryKey: pharmsKeys.image({ storeId }),
		queryFn: async () => await api.pharm.getPharmImage({ storeId }),
		enabled: !!storeId,
		retry: false,
	});
};

export const useGetPharmInfo = ({ storeId }: PharmRequest) => {
	return useQuery({
		queryKey: pharmsKeys.info({ storeId }),
		queryFn: async () => await api.pharm.getPharmInfo({ storeId }),
		enabled: !!storeId,
	});
};

export const useGetPharmProfile = ({ storeId }: PharmRequest) => {
	return useQuery({
		queryKey: pharmsKeys.profile({ storeId }),
		queryFn: async () => await api.pharm.getPharmProfile({ storeId }),
		enabled: !!storeId,
	});
};

export const usePutPharmProfile = ({ storeId }: PharmRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, Error, PutPharmProfileRequest>({
		mutationFn: async (params: PutPharmProfileRequest) => {
			await api.pharm.putPharmProfile(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.profile({ storeId }),
			});
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.image({ storeId }),
			});
		},
	});
};

export const useGetPharmOpenHours = ({ storeId }: PharmRequest) => {
	return useQuery({
		queryKey: pharmsKeys.hours({ storeId }),
		queryFn: async () => await api.pharm.getPharmOpenHours({ storeId }),
		enabled: !!storeId,
	});
};

export const OpenHoursMutationKeys = [...pharmsKeys.all, 'hours'];

export const usePutPharmOpenHours = ({ storeId }: PharmRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, Error, PharmRequest & OpenHours>({
		mutationFn: async (params: PharmRequest & OpenHours) => {
			await api.pharm.putPharmOpenHours(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.hours({ storeId }),
			});
		},
		mutationKey: OpenHoursMutationKeys,
	});
};

export const useGetPharmRestDays = ({ storeId }: PharmRequest) => {
	return useQuery({
		queryKey: pharmsKeys.rest({ storeId }),
		queryFn: async () => await api.pharm.getPharmRestDays({ storeId }),
		enabled: !!storeId,
	});
};

export const usePostPharmRestDays = ({ storeId }: PharmRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, Error, PostPharmRestDaysRequest>({
		mutationFn: async (params: PostPharmRestDaysRequest) => {
			await api.pharm.postPharmRestDays(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.rest({ storeId }),
			});
		},
	});
};

export const useDeletePharmRestDays = ({ storeId }: PharmRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, Error, DeletePharmRestDayRequest>({
		mutationFn: async (params: DeletePharmRestDayRequest) => {
			await api.pharm.deletePharmRestDays(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.rest({ storeId }),
			});
		},
	});
};

export const useGetNoticeList = (storeId: string) => {
	const initQueries: GetPharmNoticeRequest = useMemo(
		() => ({
			storeId,
			sorts: [
				{ direction: 'DESC', property: 'isMain' },
				{ direction: 'DESC', property: 'createdAt' },
			],
		}),
		[storeId],
	);
	const queries = useValues(initQueries);

	const pagination = usePagination({ initListCount: 4 });
	const { listCount, curPage, setPagination } = pagination;

	const queryInfo = useQuery({
		queryKey: pharmsKeys.noticeList(queries.values, { listCount, curPage }),
		queryFn: async () => await api.pharm.getNoticeList({ ...queries.values, limit: listCount, page: curPage }),
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const usePostNotice = (props?: MutateCallback<PostNoticeRequest, NoticeItem>) => {
	const { onError, onSuccess } = props ?? {};
	return useMutation({
		mutationFn: async (param: PostNoticeRequest) => await api.pharm.postNotice(param),
		onSuccess,
		onError,
	});
};

export const NoticePublicMutationKeys = [...pharmsKeys.all, 'notice', 'public'];

export const usePatchNoticePublic = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.patchNoticePublic(params),
		onSuccess,
		onError,
		mutationKey: NoticePublicMutationKeys,
	});
};

export const useDeleteNoticePublic = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.deleteNoticePublic(params),
		onSuccess,
		onError,
		mutationKey: NoticePublicMutationKeys,
	});
};

export const NoticeMainMutationKeys = [...pharmsKeys.all, 'notice', 'main'];

export const usePatchNoticeMain = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.patchNoticeMain(params),
		onSuccess,
		onError,
		mutationKey: NoticeMainMutationKeys,
	});
};

export const useDeleteNoticeMain = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.deleteNoticeMain(params),
		onSuccess,
		onError,
		mutationKey: NoticeMainMutationKeys,
	});
};

export const usePatchNoticePush = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.patchNoticePush(params),
		onSuccess,
		onError,
	});
};

export const useGetNotice = (props: DetailDefaultParams) => {
	return useQuery({
		queryKey: pharmsKeys.noticeDetail(props),
		queryFn: async () => await api.pharm.getNotice(props),
		enabled: !!props.id && !!props.storeId,
	});
};

export const usePutNotice = (props?: MutateCallback<PostNoticeRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: PostNoticeRequest) => await api.pharm.putNotice(params),
		onSuccess,
		onError,
	});
};

export const useDeleteNotice = (props?: MutateCallback<DetailDefaultParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: DetailDefaultParams) => await api.pharm.deleteNotice(params),
		onSuccess,
		onError,
	});
};
