import React, { useCallback, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import context from '../../../context';
import { useGetGroups, usePostGroupMembers } from '../../../services/groups/queries';
import { type Group } from '../../../services/groups/types';
import { Select } from '../../Forms';
import { type CategoryOptions } from '../../Forms/Select/components/Options';
import { ConfirmModal } from '../ConfirmModal';
import { Modal, type ModalProps } from '../Modal';

interface GroupsModalProps extends ModalProps {
	userIds: number[] | number;
	disabledGroupsId?: number[];
	onSuccess: () => void;
}

export const GroupModal = ({ userIds, onSuccess, disabledGroupsId }: GroupsModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleOpen, handleClose } = context.modal.useDispatch();
	const [groupId, setGroupId] = useState<number>(-1);

	const { data: groupsData } = useGetGroups({ initListCount: 999 });

	const groupOptions = useMemo(() => {
		const result: CategoryOptions<Group> = [];
		if (!groupsData) return result;

		return groupsData.content.reduce((acc, cur) => {
			const group = acc.find((g) => g.category === cur.categoryName);
			if (group) {
				group.options.push(cur);
			} else {
				acc.push({ category: cur.categoryName, options: [cur] });
			}
			return acc;
		}, result);
	}, [groupsData]);

	const { mutate } = usePostGroupMembers({
		onSuccess: () => {
			onSuccess();
			handleClose();
		},
		onError: (error) => {
			toast.error('그룹 설정에 실패하였습니다.\n' + error.response?.data.message);
		},
	});

	const onSubmit = useCallback(() => {
		if (!userInfo || !groupId || !groupsData?.content) return;
		const findGroup = groupsData.content.find((group) => group.id === groupId);
		// 다중 지정일 땐 confirm modal 아니면 바로 적용
		if (Array.isArray(userIds)) {
			handleOpen(
				<ConfirmModal
					confirm={() => {
						mutate({ groupId, storeId: userInfo?.storeId, data: { userIds } });
					}}
					message={`선택한 회원을 모두 ‘${findGroup?.name}’ 그룹에 등록하시겠습니까?`}
					buttonMessage={{ shouldPrevent: true }}
				/>,
			);
		} else {
			mutate({ groupId, storeId: userInfo?.storeId, data: { userIds: [userIds] } });
		}
	}, [userInfo, groupId, groupsData?.content, userIds]);

	return (
		<Modal
			isScroll={false}
			header={{ title: '그룹 지정' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '등록',
						onClick: onSubmit,
						disabled: groupId < 0,
						style: { width: '10rem' },
						shouldPrevent: true,
					},
				],
			}}
		>
			<GroupsModalForm>
				<Select<Group>
					options={groupOptions}
					value={groupId}
					selectKey="id"
					label="name"
					onClick={(key) => {
						setGroupId(Number(key));
					}}
					disabledKeys={disabledGroupsId}
					placeholder="지정할 그룹을 선택해주세요."
					optionsStyle={{ maxHeight: '30.4rem' }}
				/>
			</GroupsModalForm>
		</Modal>
	);
};

const GroupsModalForm = styled.div`
	width: 48rem;
	padding: 1.2rem 2rem 3.2rem;
`;
