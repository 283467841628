import React, { useState } from 'react';

import { Panel } from './Panel';
import { Tabs } from '../../../components/Display';
import { type PeriodUnit } from '../../../services/statistics/queries';
import { HistoryStorage } from '../../../utils/historyStorage';
import { Container } from '../components/styles';

export const labels: Record<PeriodUnit, string> = {
	daily: '일별 분석',
	weekly: '주별 분석',
	monthly: '월별 분석',
};

const historyKey = 'unit-dateRange';

export type DateRange = [Date | null, Date | null];
interface HistoryStorageType {
	unit: PeriodUnit;
	dateRange: DateRange;
}

function Period() {
	const history = new HistoryStorage<HistoryStorageType>(historyKey);
	const [unit, setUnit] = useState<PeriodUnit>(history.get()?.unit ?? 'daily');
	const initialDateRange = history.get()?.dateRange ?? [null, null];
	const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);

	const handleChangeTab = (id: string | number) => {
		let newUnit: PeriodUnit = unit;
		switch (id) {
			case 'daily' || 0:
				newUnit = 'daily';
				break;
			case 'weekly' || 1:
				newUnit = 'weekly';
				break;
			case 'monthly' || 2:
				newUnit = 'monthly';
				break;
		}
		if (newUnit !== unit) {
			setDateRange([null, null]);
			setUnit(newUnit);
			history.set({ unit: newUnit, dateRange: [null, null] }, true);
		}
	};

	const tabConfigs: Array<{ label: string; id: PeriodUnit }> = [
		{ label: labels.daily, id: 'daily' },
		{ label: labels.weekly, id: 'weekly' },
		{ label: labels.monthly, id: 'monthly' },
	];

	return (
		<Container>
			<Tabs
				hasBorderBottom={true}
				tabGaps={{ vertical: '0', horizon: '4.4rem' }}
				tabStyle={{ padding: '0 0.2rem 0.8rem', height: '3.6rem', zIndex: 2 }}
				labelStyle={{ $fontStyle: 'title_2' }}
				borderStyle={{ weight: { selected: '2px', default: '0px' } }}
				onChange={(idx, id) => {
					handleChangeTab(id ?? idx);
				}}
				contents={tabConfigs}
				disableAutoSelect
				defaultIndex={tabConfigs.findIndex(({ id }) => id === unit)}
				panel={
					<Panel
						unit={unit}
						dateRange={dateRange}
						onChangePeriod={(updated) => {
							setDateRange(updated);
							history.set({ unit, dateRange: updated }, true);
						}}
					/>
				}
			/>
		</Container>
	);
}

export default Period;
