import React, { type HTMLAttributes, useMemo, type CSSProperties } from 'react';

import styled from 'styled-components';

import { type UseValuesReturn } from '../../../../hooks/useValues';
import { type Period } from '../../../../services/types';
import { PeriodDate, Radio, Select } from '../../../Forms';
import { handleMultiSelect } from '../../utils';
import { type FilterItemType } from '../FilterModal/types';

interface ItemProps<T extends object> extends HTMLAttributes<HTMLDivElement> {
	item: FilterItemType<T>;
	hasAll?: boolean;
	queries: UseValuesReturn<T>;
	style?: CSSProperties;
	isFilterList?: boolean;
}

export const FilterItem = <T extends object>({
	item,
	queries,
	hasAll = true,
	style,
	isFilterList = false,
}: ItemProps<T>) => {
	const { label, type, selectProps, radioProps, periodDateProps } = item;
	const { values, dispatch, onChangeValues } = queries;

	const renderItem = useMemo(() => {
		switch (type) {
			case 'SELECT':
				if (selectProps) {
					const { value, onClick, multiple, ...restProps } = selectProps;
					return (
						<Select
							value={value ?? values[item.key]}
							onClick={(key) => {
								let newValue = key;
								if (multiple) {
									newValue = handleMultiSelect<typeof key>(key, value ?? values[item.key], multiple.allKey);
								}
								onClick ? onClick(key) : dispatch('SET', { [item.key]: newValue } as Partial<T>);
							}}
							multiple={multiple}
							{...restProps}
						/>
					);
				}
				break;
			case 'RADIO':
				if (radioProps) {
					const { selectValue, onChange, options, ...restProps } = radioProps;
					return (
						<Radio
							selectValue={selectValue ?? values[item.key]}
							onChange={onChange ?? onChangeValues}
							options={hasAll ? options : options.slice(1)}
							{...restProps}
						/>
					);
				}
				break;
			case 'PERIOD': {
				const DateValues = values[item.key] as Period;

				return (
					<PeriodDate
						start={DateValues.start}
						end={DateValues.end}
						onChange={(date, key) => {
							if (key === 'all') {
								dispatch('SET', {
									[item.key]: date,
								} as Partial<T>);
							} else {
								dispatch('SET', {
									[item.key]: { ...values[item.key], [key]: date },
								} as Partial<T>);
							}
						}}
						isAll={hasAll}
						periodDirection={hasAll ? 'ROW' : 'COLUMN'}
						{...periodDateProps}
					/>
				);
			}
		}
	}, [item, queries]);
	return (
		<ItemWrapper style={style}>
			{label && !isFilterList && <label>{label}</label>}
			{renderItem}
		</ItemWrapper>
	);
};

const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	> label {
		${({ theme }) => theme.font.body.body_2};
		font-weight: 500;
		color: ${({ theme }) => theme.colors.black};
	}
`;
