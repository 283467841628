import {
	type GetContractsRequest,
	type GetContractsDetailRequest,
	type GetContractsResponse,
	type Contract,
} from './types';
import { Get } from '../index';

export const contracts = {
	getContracts: async <T = GetContractsResponse>({ storeId }: GetContractsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/contracts`);
		return response.data;
	},
	getContractsDetail: async <T = Contract>({ storeId, id }: GetContractsDetailRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/contracts/${id}`);
		return response.data;
	},
};
