import React, { useCallback, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { usePutUserInfo } from '../../../services/users/queries';
import { type PutUserInfoRequest } from '../../../services/users/types';
import { formatPhoneNumber } from '../../../utils/format';
import { FormItem, TextField } from '../../Forms';
import { Modal } from '../Modal';

export const ChangePhoneModal = () => {
	const { userInfo } = context.user.useValue();
	const { handleClose } = context.modal.useDispatch();
	const initQueries: PutUserInfoRequest = useMemo(() => ({ phone: '', name: '' }), []);
	const { values, dispatch } = useValues(initQueries);

	useEffect(() => {
		if (!userInfo) return;
		dispatch('SET', { name: userInfo.name });
	}, [userInfo]);

	const { mutate } = usePutUserInfo({
		onSuccess: () => {
			toast.success('연락처가 변경되었습니다.');
			handleClose();
		},
		onError: (err) => {
			toast.error('연락처 변경에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onSubmit = useCallback(() => {
		const { phone } = values;
		const replacePhone = phone.replaceAll('-', '');
		mutate({ ...values, phone: replacePhone });
	}, [values]);
	const isValid = useMemo(() => !!values.phone && values.phone.length >= 12, [values]);

	return (
		<Modal
			header={{ title: '연락처 변경' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '변경',
						onClick: onSubmit,
						style: { width: '10rem' },
						shouldPrevent: true,
						disabled: !isValid,
					},
				],
			}}
		>
			<Container>
				<FormItem
					label={'새 연락처'}
					isRequired
					statusMessage={!isValid && values.phone.length >= 1 ? '핸드폰번호는 10자리 또는 11자리만 가능합니다.' : ''}
				>
					<TextField
						value={values.phone ?? ''}
						inputSize={'lg'}
						onChange={(e) => {
							dispatch('SET', { phone: formatPhoneNumber(e.target.value) });
						}}
						name="phone"
						maxLength={13}
					/>
				</FormItem>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 80rem;
`;
