import React, { useMemo } from 'react';

import { subMonths } from 'date-fns/subMonths';

import { InsideTitle } from '../../../components/Common';
import { PeriodChart } from '../components/PeriodChart';
import { PeriodDetails } from '../components/StatsTable';
import { Content } from '../components/styles';
import { formatPeriod, getDateEndOfUnit } from '../utils';

interface ExtrasProps {
	storeId: string;
	startDate: string;
	endDate: string;
	unit: 'weekday' | 'monthly';
}

export function Extras({ storeId, startDate, endDate, unit }: ExtrasProps) {
	const period = useMemo(() => {
		if (unit === 'monthly') {
			const [start, end] = [subMonths(startDate, 11), getDateEndOfUnit(endDate, 'monthly')];
			return formatPeriod([start, end]);
		}
		return { startDate, endDate };
	}, [startDate, endDate]);

	return (
		<>
			<Content $gap={'1.2rem'}>
				<InsideTitle title={`${unit === 'monthly' ? '월별' : '요일별'} 매출/마진`} />
				<PeriodChart
					storeId={storeId}
					{...period}
					unit={unit}
					style={{ height: 456, padding: unit === 'monthly' ? 0 : '0 18rem' }}
				/>
				<PeriodDetails
					storeId={storeId}
					{...period}
					unit={unit}
					maxHeight={unit === 'monthly' ? '36.5rem' : undefined}
					isScroll={unit === 'monthly'}
				/>
			</Content>
		</>
	);
}
