import React, { useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { groupDetailRows } from './rows';
import { groupMemberTableColumns } from './tableColumns';
import { Button } from '../../../../components/Buttons';
import { Flex, InsideTitle } from '../../../../components/Common';
import { Description, Icon, IconID } from '../../../../components/Display';
import { ConfirmModal } from '../../../../components/Modals';
import { ListTable } from '../../../../components/Table';
import context from '../../../../context';
import { useSelectedItems } from '../../../../hooks/useSelectedItems';
import {
	useDeleteGroupDetail,
	useDeleteGroupMembers,
	useGetGroupDetail,
	useGetGroupMembers,
	usePostGroupMembers,
} from '../../../../services/groups/queries';
import { type GetGroupDetailRequest, type GroupMember } from '../../../../services/groups/types';
import { type BasicMemberInfo } from '../../../../services/member/types';
import { handleChangeSorts } from '../../../../utils/onChangeSorts';
import { GroupAddModal } from '../GroupAddModal';
import { MemberAddModal } from '../MemberAddModal';

const GroupDetail = () => {
	const { groupId } = useParams();
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const navigate = useNavigate();

	const params = useMemo(() => {
		const result: GetGroupDetailRequest = { groupId: undefined, storeId: undefined };
		if (groupId) result.groupId = Number(groupId);
		if (userInfo?.storeId) result.storeId = userInfo?.storeId;
		return result;
	}, [groupId, userInfo?.storeId]);

	const { data, refetch } = useGetGroupDetail(params);

	const { mutate: deleteGroupDetailMutate } = useDeleteGroupDetail({
		onSuccess: () => {
			navigate(-1);
			toast.success('그룹이 성공적으로 삭제되었습니다.');
		},
		onError: () => {
			toast.error('그룹을 삭제하지 못했습니다.');
		},
	});

	const {
		data: groupMembersData,
		refetch: groupMembersRefetch,
		pagination,
		isLoading,
		queries,
	} = useGetGroupMembers(params);

	const { selectedItems, handleSelectedItems } = useSelectedItems<BasicMemberInfo>({
		dependency: [queries.values, pagination.curPage, pagination.listCount],
	});

	const { mutate: postGroupMemberMutate } = usePostGroupMembers({
		onSuccess: () => {
			groupMembersRefetch();
			toast.success('선택한 회원이 그룹에 추가되었습니다.');
		},
		onError: () => {
			toast.error('그룹 추가에 실패하였습니다.');
		},
	});

	const { mutate: deleteGroupMemberMutate } = useDeleteGroupMembers({
		onSuccess: () => {
			handleSelectedItems('RESET');
			pagination.resetCurPage();
			groupMembersRefetch();
			toast.success('선택한 회원이 그룹에서 지정 해제되었습니다.');
		},
		onError: () => {
			toast.error('지정 해제에 실패하였습니다.');
		},
	});

	const handleSelectedMembers = useCallback(
		(param: GroupMember[]) => {
			const userIds = param.map((user) => user.userId);
			postGroupMemberMutate({ ...params, data: { userIds } });
		},
		[params],
	);

	const handleGroupMembersDelete = useCallback(() => {
		const userIds = selectedItems.map((user) => user.userId);
		deleteGroupMemberMutate({ ...params, data: { userIds } });
	}, [selectedItems, params]);

	const handleGroupDelete = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					deleteGroupDetailMutate(params);
				}}
				message={'정말로 그룹을 삭제하시겠습니까?'}
				buttonMessage={{ shouldPrevent: true }}
			/>,
		);
	}, [params]);

	return (
		<React.Fragment>
			<Container>
				<div className="top">
					<InsideTitle
						title="기본 정보"
						rightRender={
							!!data &&
							!data.isSystem && (
								<Flex $gap="1rem">
									<Button buttonType="LINE" color="RED" onClick={handleGroupDelete}>
										그룹 삭제
									</Button>
									<Button
										buttonType="LINE"
										color="SECONDARY"
										onClick={() => {
											handleOpen(
												<GroupAddModal
													refetch={() => {
														refetch();
													}}
												/>,
											);
										}}
									>
										<Icon id={IconID.GROUP_ADD} width="2rem" height="2rem" />
										그룹 수정
									</Button>
								</Flex>
							)
						}
					/>
					<Description data={data} rows={groupDetailRows} />
				</div>
				<div className="bottom">
					<InsideTitle
						title="그룹 회원 목록"
						rightRender={
							<div className="inside__title--right__render">
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={handleGroupMembersDelete}
									disabled={selectedItems.length <= 0}
									shouldPrevent={true}
								>
									<Icon id={IconID.GROUP_MINUS} width="2rem" height="2rem" isDisabled={selectedItems.length <= 0} />
									그룹 해제
								</Button>
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={() => {
										handleOpen(<MemberAddModal handleSelectedMembers={handleSelectedMembers} />);
									}}
								>
									<Icon id={IconID.USER_PLUS} width="2rem" height="2rem" />
									회원 추가
								</Button>
							</div>
						}
					/>
					<ListTable
						isLoading={isLoading}
						data={groupMembersData?.content}
						columns={groupMemberTableColumns}
						selected={{ selectKey: 'userId', multiple: { selectedItems, handleSelectedItems } }}
						pagination={pagination}
						unit="명"
						emptyMessage={'그룹에 포함된 회원이 없습니다.'}
						onCategoryClick={(column) => {
							handleChangeSorts(queries.values.sorts, column, (sorts) => {
								queries.dispatch('SET', {
									sorts,
								});
							});
						}}
						sortValues={queries.values.sorts}
					/>
				</div>
			</Container>
		</React.Fragment>
	);
};
const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;

	.inside__title {
		&--right__render {
			display: flex;
			gap: 1rem;
		}
	}
`;
export default GroupDetail;
