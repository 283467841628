import React, { useMemo } from 'react';

import styled from 'styled-components';

import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { useGetGroups } from '../../../services/groups/queries';
import { type Group } from '../../../services/groups/types';
import { type PostAndDeleteMemberData } from '../../../services/member/types';
import { Options, type CategoryOptions } from '../../Forms/Select/components/Options';
import { Modal, type ModalProps } from '../Modal';

interface GroupsModalProps extends ModalProps {
	disabledGroupIds?: number[];
	onSelect: (groupIds: number[], groups: Group[]) => void;
	isFavoriteCount?: boolean;
}

export const GroupsModal = ({ disabledGroupIds, onSelect, isFavoriteCount = false }: GroupsModalProps) => {
	const { data: groupsData } = useGetGroups({ initListCount: 999 });
	const { handleClose } = context.modal.useDispatch();

	const groupOptions = useMemo(() => {
		const result: CategoryOptions<Group> = [];
		if (!groupsData) return result;

		return groupsData.content.reduce((acc, cur) => {
			const group = acc.find((g) => g.category === cur.categoryName);
			if (group) {
				group.options.push(cur);
			} else {
				acc.push({ category: cur.categoryName, options: [cur] });
			}
			return acc;
		}, result);
	}, [groupsData]);

	const initValues: PostAndDeleteMemberData = {
		groupIds: [],
	};
	const { values, dispatch } = useValues(initValues);

	return (
		<Modal
			header={{ title: '그룹 지정' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '저장',
						onClick: () => {
							let filterGroups: Group[] = [];
							if (groupsData) {
								filterGroups = groupsData?.content.filter((group) => values.groupIds.includes(group.id));
							}
							onSelect(values.groupIds, filterGroups);
							handleClose();
						},
						shouldPrevent: true,
						style: { width: '10rem' },
					},
				],
			}}
		>
			<Container>
				<Options<Group>
					options={groupOptions}
					value={values.groupIds}
					selectKey="id"
					label="name"
					onClick={(key) => {
						const valueSet = new Set(values.groupIds);
						const selected = key as number;
						if (valueSet.has(selected)) {
							valueSet.delete(selected);
						} else {
							valueSet.add(selected);
						}

						dispatch('SET', { groupIds: Array.from(valueSet) } as Partial<PostAndDeleteMemberData>);
					}}
					disabledKeys={disabledGroupIds}
					optionsStyle={{ width: '100%', maxHeight: '50rem' }}
					customLabel={
						isFavoriteCount
							? (item, options) => (
									<CustomLabel $isSelected={options.isSelected}>
										<p className="group_name">{item.name}</p>
										<p className="group_count">인원 {item.favoriteMemberCount}명</p>
									</CustomLabel>
								)
							: undefined
					}
				/>
			</Container>
		</Modal>
	);
};
const CustomLabel = styled.div<{ $isSelected: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	padding: 0.8rem 0;
	.group_name {
		${({ theme }) => theme.font.label.label_2}
	}
	.group_count {
		${({ theme }) => theme.font.caption.caption_1}
		color:${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary.primary_700 : theme.colors.gray.gray_700)};
	}
`;

const Container = styled.div`
	width: 48rem;
`;
