import SendbirdChat, { LocalCacheConfig, LogLevel, type SendbirdChatParams } from '@sendbird/chat';
import { GroupChannelModule, type SendbirdGroupChat } from '@sendbird/chat/groupChannel';

const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID!;

/**
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/application/authenticating-a-user/authentication#2-initialize-the-chat-sdk-with-app_id}
 */
export function setupSendbird() {
	const params: SendbirdChatParams<[GroupChannelModule]> = {
		appId: APP_ID,
		modules: [new GroupChannelModule()],
		localCacheEnabled: true,
		localCacheConfig: new LocalCacheConfig({
			maxSize: Number.MAX_SAFE_INTEGER,
		}),
		logLevel: LogLevel.WARN,
	};
	return SendbirdChat.init(params) as SendbirdGroupChat;
}
