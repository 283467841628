import Default from './Default';
import Header from './Header';
import Nav from './Nav';
import UnRequireAuth from './UnRequireAuth';

export const Layout = {
	Header,
	Default,
	UnRequireAuth,
	Nav,
};
