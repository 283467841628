import React from 'react';

import { type UserMessage } from '@sendbird/chat/message';
import styled, { css } from 'styled-components';

import { DeletedMessageBody } from './DeletedMessageBody';
import { FileMessageBody } from './FileMessageBody';
import { ImageMessageBody } from './ImageMessageBody';
import { OGMessageBody } from './OGMessageBody';
import { TextMessageBody } from './TextMessageBody';
import { isDeletedMessage, isFileMessage, isImageMessage, isOGMessage } from './utils';
import { VStack } from '../../../../../components/Common';
import { SCROLL_BUFFER } from '../../../../../utils/consts';
import { useChannelContext } from '../../../context/ChannelProvider';

import type { CoreMessageType } from '../../../utils';

interface BasicMessageProps {
	isByMe: boolean;
	message: CoreMessageType;
}
export const BasicMessage = (props: BasicMessageProps) => {
	const { message, isByMe } = props;
	const { scrollRef, scrollDistanceFromBottomRef, scrollPubSub } = useChannelContext();

	const showDeletedMessage = isDeletedMessage(message);
	const showFileMessage = isFileMessage(message) && !isImageMessage(message.data);
	const showImageMessage = isImageMessage(message.data);
	const showOGMessage = isOGMessage(message);
	const showTextMessage = !isFileMessage(message);

	/**
	 * 1. Move the message list scroll
	 *    when each message's height is changed by `image rendered`
	 * 2. Keep the scrollBottom value after fetching new message list
	 */

	const onMessageContentSizeChanged = (isBottomMessageAffected = false) => {
		const elem = scrollRef.current;
		if (elem) {
			const latestDistance = scrollDistanceFromBottomRef.current;
			const currentDistance = elem.scrollHeight - elem.scrollTop - elem.offsetHeight;
			if (latestDistance < currentDistance && (!isBottomMessageAffected || latestDistance < SCROLL_BUFFER)) {
				const diff = currentDistance - latestDistance;
				// Move the scroll as much as the height of the message has changed
				scrollPubSub.publish('scroll', { top: elem.scrollTop + diff, lazy: false, animated: false });
			}
		}
	};

	if (showDeletedMessage) {
		return <DeletedMessageBody {...props} />;
	}

	if (showFileMessage) {
		return <FileMessageBody {...props} />;
	}

	return (
		<VStack $gap="0.8rem">
			<MessageWrapper $isByMe={isByMe} $isImage={showImageMessage}>
				{showImageMessage ? <ImageMessageBody {...props} onMessageHeightChange={onMessageContentSizeChanged} /> : <></>}
				{showTextMessage && (
					<TextMessageBody message={message as UserMessage} isByMe={isByMe} isImageMessage={showImageMessage} />
				)}
			</MessageWrapper>
			{showOGMessage && (
				<OGMessageBody message={message} isByMe={isByMe} onMessageHeightChange={onMessageContentSizeChanged} />
			)}
		</VStack>
	);
};

const MessageWrapper = styled(VStack)<{ $isByMe: boolean; $isImage: boolean }>`
	${({ $isImage }) =>
		$isImage &&
		css`
			border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		`};
	border-radius: ${({ $isByMe }) => ($isByMe ? `12px 2px 12px 12px` : `2px 12px 12px 12px`)};
	overflow: hidden;
	align-self: ${({ $isByMe }) => ($isByMe ? 'end' : 'start')};
`;
