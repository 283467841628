import {
	type PasswordRequest,
	type LoginRequest,
	type LoginResponse,
	type UserInfo,
	type PutUserInfoRequest,
	type PasswordMatchesResponse,
	type UsersRequest,
	type GetUsersRequest,
	type GetUsersResponse,
	type PostUsersRequest,
	type UserDetailRequest,
	type PutUserDetailRequest,
	type PostCheckDuplicateAccountRequest,
	type PostCheckDuplicateAccountResponse,
	type PutPasswordRequest,
	type HeartbeatRequest,
} from './types';
import { formatQueryString } from '../../utils/format';
import { Delete, Get, Post, Put } from '../index';

export const user = {
	login: async <T = LoginResponse, R = LoginRequest>(params: R) => {
		const response = await Post<T>(`/login`, params);
		return response.data;
	},
	getUserInfo: async <T = UserInfo>(): Promise<T> => {
		const response = await Get<T>('/pharm-users/me');
		return response.data;
	},
	putUserInfo: async <T = any>(data: PutUserInfoRequest): Promise<T> => {
		const response = await Put<T>('/pharm-users/me', data);
		return response.data;
	},
	putPassword: async <T = any>(data: PutPasswordRequest): Promise<T> => {
		const response = await Put<T>('/pharm-users/me/password', data);
		return response.data;
	},
	postPasswordMatches: async <T = PasswordMatchesResponse>(data: PasswordRequest): Promise<T> => {
		const response = await Post<T>('/pharm-users/me/password/matches', data);
		return response.data;
	},

	getMaster: async <T = UserInfo>({ storeId }: UsersRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/users/master`);
		return response.data;
	},
	getUsers: async <T = GetUsersResponse>({ storeId, ...rest }: GetUsersRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/users${formatQueryString(rest)}`);
		return response.data;
	},
	postUsers: async <T = UserInfo>({ storeId, ...data }: PostUsersRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/users`, data);
		return response.data;
	},
	getUserDetail: async <T = UserInfo>({ storeId, id }: UserDetailRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/users/${id}`);
		return response.data;
	},
	putUserDetail: async <T = any>({ storeId, id, ...data }: PutUserDetailRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/users/${id}`, data);
		return response.data;
	},
	deleteUserDetail: async <T = any>({ storeId, id }: UserDetailRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/users/${id}`);
		return response.data;
	},
	postCheckDuplicateAccount: async <T = PostCheckDuplicateAccountResponse>(
		data: PostCheckDuplicateAccountRequest,
	): Promise<T> => {
		const response = await Post<T>(`/check-duplicate/account`, data);
		return response.data;
	},
	postHeartbeat: async <T = string, R = HeartbeatRequest>(data: R) => (await Post<T>('/heartbeat', data)).data,
};
