import { type RefObject, useEffect } from 'react';

interface Options {
	hasClassName?: string;
}

/**
 * ref가 아닌 다른곳을 클릭했을 때 callback function을 실행
 * @param ref - element RefObject
 * @param callback - 실행시킬 함수
 * @param options
 * @param option.hasDatePicker - 클릭한 element class에 특정 문자열이 포함되어 있는지 확인
 */
const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void, options?: Options) => {
	function handleClickOutside(e: MouseEvent) {
		const targetElement = e.target as HTMLElement;
		if (ref.current && !ref.current.contains(targetElement)) {
			if (options?.hasClassName) {
				const classList = Array.from(targetElement.classList);
				if (classList.some((className) => options.hasClassName && className.includes(options.hasClassName))) return;
			}
			callback();
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, callback]);
};

export default useOutsideClick;
