import React, { type CSSProperties } from 'react';

import {
	Bar,
	CartesianGrid,
	Cell,
	ComposedChart,
	Legend,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	type XAxisProps,
	YAxis,
	type YAxisProps,
} from 'recharts';

import Theme from '../../../lib/styledComponents/Theme';
import { type ColorKeys } from '../../../utils/changeColorKeyToType';
import { CustomLegend } from '../CustomLegend';
import { CustomXAxis } from '../CustomXAxis';
import { CustomYAxis } from '../CustomYAxis';
import { ChartWrapper } from '../styles';

import type { Props as BarProps } from 'recharts/types/cartesian/Bar';
import type { Props as LineProps } from 'recharts/types/cartesian/Line';
import type { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import type { Props as LegendProps } from 'recharts/types/component/Legend';
import type { TooltipProps } from 'recharts/types/component/Tooltip';

type InternalBarProps = Omit<BarProps, 'ref'>;
type InternalLineProps = Omit<LineProps, 'ref'>;
type InternalLegendProps = Pick<LegendProps, 'wrapperStyle' | 'payload'> & { innerStyle?: CSSProperties };
type InternalXAxisProps = XAxisProps & { fontColor?: ColorKeys };

export interface ComposedProps extends CategoricalChartProps {
	wrapperStyle?: CSSProperties;
	showGrid?: boolean;
	legendProps?: InternalLegendProps;
	xAxisProps?: InternalXAxisProps[];
	yAxisProps?: YAxisProps[];
	barProps?: InternalBarProps[];
	barColors?: string[];
	lineProps?: InternalLineProps[];
	tooltipProps?: TooltipProps<ValueType, NameType>;
}

export function Composed(props: ComposedProps) {
	const {
		wrapperStyle,
		data,
		width,
		height,
		margin,
		showGrid = true,
		legendProps,
		xAxisProps,
		yAxisProps,
		barProps,
		barColors,
		lineProps,
		tooltipProps = { active: false },
	} = props;

	return (
		<ChartWrapper style={{ height, ...wrapperStyle }}>
			<ResponsiveContainer minHeight={height}>
				<ComposedChart data={data} width={width} height={height} margin={margin}>
					{showGrid && <CartesianGrid vertical={false} stroke={Theme.colors.gray.gray_500} strokeDasharray={3} />}
					{xAxisProps?.map(({ dataKey, fontColor, ...rest }, idx) => (
						<XAxis
							key={`xAxis-${idx}`}
							dataKey={dataKey}
							tickLine={false}
							axisLine={{ stroke: Theme.colors.gray.gray_500 }}
							tickSize={20}
							interval={0}
							tick={(props) => <CustomXAxis props={props} fontColor={fontColor} />}
							{...rest}
						/>
					))}
					{yAxisProps ? (
						yAxisProps.map(({ dataKey, ...rest }, idx) => (
							<YAxis
								key={`yAxis-${idx}`}
								dataKey={dataKey}
								tickLine={false}
								axisLine={{ stroke: Theme.colors.gray.gray_500 }}
								tick={<CustomYAxis />}
								{...rest}
							/>
						))
					) : (
						<YAxis
							tickLine={false}
							axisLine={{ stroke: Theme.colors.gray.gray_500 }}
							tickSize={16}
							tick={<CustomYAxis />}
						/>
					)}
					<Legend
						verticalAlign={'top'}
						{...legendProps}
						content={(props) => <CustomLegend props={props} innerStyle={legendProps?.innerStyle} />}
					/>
					<Tooltip {...tooltipProps} />
					{barProps?.map(({ dataKey, fill, ...rest }, idx) => (
						<Bar key={`bar-${idx}`} dataKey={dataKey} fill={fill} {...rest}>
							{data?.map((_, idx) => <Cell key={`cell-${idx}`} fill={barColors?.[idx] ?? fill} />)}
						</Bar>
					))}
					{lineProps?.map(({ dataKey, stroke, ...rest }, idx) => (
						<Line
							key={`line-${idx}`}
							dataKey={dataKey}
							stroke={stroke}
							dot={false}
							activeDot={false}
							isAnimationActive={false}
							{...rest}
						/>
					))}
				</ComposedChart>
			</ResponsiveContainer>
		</ChartWrapper>
	);
}
