import { useCallback } from 'react';

import { api } from '../../../services';
import { type Asset } from '../../../services/asset/types';
import { isImage } from '../../../utils/files';
import { type FileData } from '../types';

import type { FileMessage, FileMessageCreateParams } from '@sendbird/chat/message';

// interface UseHandleSendFilesProps {
// 	sendFileMessage: (params: UserMessageCreateParams) => Promise<UserMessage>;
// }
interface UseHandleSendFilesProps {
	sendFileMessage: (params: FileMessageCreateParams) => Promise<FileMessage>;
}

export const useHandleSendFiles = ({ sendFileMessage }: UseHandleSendFilesProps) => {
	const handleSendFiles = useCallback(
		async (files: File[]) => {
			const filesMessageData: FileData[] = await Promise.all(
				files.map(async (item, _) => {
					const response = await api.asset.postAsset({ file: item, thumbnail: isImage(item.type) });

					return convertFileData(response);
				}),
			);
			const imageFiles: FileData[] = [];
			const otherFiles: FileData[] = [];
			filesMessageData.forEach((file: FileData) => {
				if (file.isImage) {
					imageFiles.push(file);
				} else {
					otherFiles.push(file);
				}
			});

			// 이미지 제외한 파일은 단일 전송. 이미지 파일은 data 부에 files 안에 멀티 이미지로 전송
			return await otherFiles.reduce(
				// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
				async (previousPromise: Promise<FileMessage | void>, item: FileData) => {
					return await previousPromise.then(async () => {
						return await sendFileMessage({
							fileName: '파일을 보냈습니다.',
							fileUrl: item.url,
							data: JSON.stringify({ files: [item] }),
						});
					});
				},
				(async () => {
					if (imageFiles.length === 0) {
						await Promise.resolve();
					} else {
						return await sendFileMessage({
							fileName: '사진을 보냈습니다.',
							fileUrl: imageFiles[0].url,
							data: JSON.stringify({ files: imageFiles }),
						});
					}
				})(),
			);
		},

		[sendFileMessage],
	);

	return { handleSendFiles };
};

function convertFileData(data: Asset): FileData {
	const { id, name, size, contentType, url, thumbnailUrl, expiredAt } = data;

	return {
		assetId: id,
		fileName: name,
		isImage: isImage(contentType),
		url,
		thumbnailUrl,
		size,
		expiredAt,
	};
}
