import React from 'react';

import { ChartLegendIcon } from '../../../components/Charts';
import { Flex } from '../../../components/Common';
import { ListTable } from '../../../components/Table';
import { commaizeNumber, fractionNumber } from '../../../utils/format';
import { StatsStyledTable } from '../components/StatsTable';

import type { Columns } from '../../../components/Table/ListTable/types';
import type { Props } from 'recharts/types/component/DefaultLegendContent';

export interface PieLegendPayloadItem {
	fill: string;
	name: string;
	percent: number;
}

interface WeekdayPieLegend extends PieLegendPayloadItem {
	sales: number;
	saleCount: number;
}

export function CustomPieLegend(props: Props) {
	const { payload } = props;
	if (!payload) return <></>;
	const data = payload
		.map((item) => {
			if (isItemWithPayload(item)) {
				const { fill, name, percent, sales, saleCount } = item?.payload;
				return { fill, name, percent, sales, saleCount };
			}
			return null;
		})
		.filter((it) => it !== null) as WeekdayPieLegend[];

	return (
		<StatsStyledTable>
			<ListTable tableStyle={{ minWidth: '31rem' }} data={data} columns={AVERAGE_SALES_LEGEND} isScroll={false} />
		</StatsStyledTable>
	);
}

function isItemWithPayload(item: any): item is { payload: WeekdayPieLegend } {
	return (
		item?.payload &&
		'fill' in item.payload &&
		'name' in item.payload &&
		'percent' in item.payload &&
		'sales' in item.payload &&
		'saleCount' in item.payload
	);
}

const AVERAGE_SALES_LEGEND: Columns<WeekdayPieLegend> = [
	{
		key: 'name',
		headerName: '요일',
		width: { max: '8rem' },
		style: { justifyContent: 'center' },
		renderComponent: (value, item) => {
			return (
				<Flex $gap={'0.5rem'} $alignItems={'center'}>
					<ChartLegendIcon $type={'rect'} $color={item.fill} />
					{value}
				</Flex>
			);
		},
	},
	{
		key: 'sales',
		headerName: '매출',
		width: { min: '11.8rem' },
		style: { justifyContent: 'right', flex: 1 },
		renderComponent: (value) => commaizeNumber(fractionNumber(value, 2)) + '원',
	},
	{
		key: 'percent',
		headerName: '비율',
		width: { max: '5.6rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => `${(Number(value) * 100).toFixed(1)}%`,
	},
	{
		key: 'saleCount',
		headerName: '건수',
		width: { max: '5.6rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => commaizeNumber(fractionNumber(value, 0)) + '건',
	},
];
