import React, { useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { AffiliatedGroup } from './components/AffiliatedGroup';
import { PaymentHistory } from './components/PaymentHistory';
import { informationRows } from './rows';
import { Button, IconButton } from '../../../../components/Buttons';
import { Loading, InsideTitle, HStack, VStack } from '../../../../components/Common';
import { Description, Icon, IconID, InfoText, Tabs, Tag } from '../../../../components/Display';
import { TextArea, TextField } from '../../../../components/Forms';
import context from '../../../../context';
import { MEMO_LENGTH, MEMO_MESSAGES } from '../../../../feature/ChannelMemberInfo/components/MemoInfo';
import { NICKNAME_MAX_COUNT, NICKNAME_MESSAGES } from '../../../../feature/ChannelMemberInfo/components/NicknameInfo';
import { useValues } from '../../../../hooks/useValues';
import { useDeleteBlockMembers, usePostBlockMembers } from '../../../../services/block/queries';
import { type PostAndDeleteBlockMembersRequest } from '../../../../services/block/types';
import {
	membersKeys,
	useMemberInfo,
	usePutMemberMemo,
	usePutMemberNicknames,
} from '../../../../services/member/queries';

const INITIAL_VALUES: { memo: string; nickname: string } = {
	memo: '',
	nickname: '',
} as const;

const Detail = () => {
	const { values, onChangeValues, dispatch } = useValues(INITIAL_VALUES);
	const queryClient = useQueryClient();
	const { userId } = useParams();
	const { userInfo } = context.user.useValue();
	const { data, refetch, isLoading, isError, error, isSuccess } = useMemberInfo({
		storeId: userInfo.storeId,
		userId: Number(userId),
	});
	const params = useMemo(() => ({ userId: Number(userId), storeId: userInfo.storeId }), [userInfo, userId]);

	const { mutate: memoMutate } = usePutMemberMemo(params);

	const onMemoMutate = useCallback(() => {
		memoMutate(
			{ ...params, memo: values.memo ?? '' },
			{
				onSuccess: () => {
					toast.success(MEMO_MESSAGES.SUCCESS);
				},
				onError: (err) => {
					toast.error(`${MEMO_MESSAGES.ERROR}\n${err.response?.data.message}`);
				},
			},
		);
	}, [values]);
	const { mutate: nicknameMutate } = usePutMemberNicknames(params);

	const handleNickname = useCallback(
		(method: 'POST' | 'DELETE', value: string[] | string) => {
			try {
				if (!isSuccess) return;
				switch (method) {
					case 'POST':
						if (!Array.isArray(value) && value !== '')
							nicknameMutate(
								{ ...params, nicknames: [...data.nicknames, value] },
								{
									onSuccess: () => {
										toast.success(NICKNAME_MESSAGES.POST_SUCCESS);
									},
									onError: (err) => {
										toast.error(`${NICKNAME_MESSAGES.POST_ERROR}\n${err.response?.data.message}`);
									},
								},
							);
						break;
					case 'DELETE':
						if (Array.isArray(value))
							nicknameMutate(
								{ ...params, nicknames: value },
								{
									onSuccess: () => {
										toast.success(NICKNAME_MESSAGES.DELETE_SUCCESS);
									},
									onError: (err) => {
										toast.error(`${NICKNAME_MESSAGES.DELETE_ERROR}\n${err.response?.data.message}`);
									},
								},
							);
						break;
				}
			} finally {
				dispatch('DELETE', 'nickname');
			}
		},
		[data],
	);

	useEffect(() => {
		if (isError) {
			toast.error(error.response?.data.message);
		}
		if (isSuccess) {
			dispatch('SET', { memo: data.memo });
		}
	}, [isError, data]);

	const { mutate: postBlockMembers } = usePostBlockMembers({
		onSuccess: () => {
			toast.success('성공적으로 차단하였습니다.');
			queryClient.invalidateQueries({
				queryKey: membersKeys.group(params),
			});
			refetch();
		},
	});

	const { mutate: deleteBlockMembers } = useDeleteBlockMembers({
		onSuccess: () => {
			toast.success('성공적으로 차단해제하였습니다.');
			queryClient.invalidateQueries({
				queryKey: membersKeys.group(params),
			});
			refetch();
		},
	});

	const blockOrDelete = useCallback(() => {
		if (!userInfo || !userId) return;
		const blockParams: PostAndDeleteBlockMembersRequest = {
			storeId: userInfo.storeId,
			data: { userIds: [Number(userId)] },
		};

		switch (data?.isBlock) {
			case true:
				deleteBlockMembers(blockParams);
				break;
			case false:
				postBlockMembers(blockParams);
				break;
			default:
				break;
		}
	}, [userInfo, userId, data?.isBlock]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Container>
			<VStack>
				<HStack $gap="1.6rem">
					<VStack $flex="1">
						<InsideTitle
							title="기본 정보"
							rightRender={
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={blockOrDelete}
									shouldPrevent={true}
									mutationKey={['block']}
								>
									<Icon id={IconID[data?.isBlock ? 'MESSAGE' : 'BAN']} width="2rem" height="2rem" />
									{data?.isBlock ? '차단해제' : '차단하기'}
								</Button>
							}
						/>
						<Description
							data={data}
							rows={informationRows}
							labelStyle={{ $color: 'gray_700', $fontStyle: 'label_2', $fontWeight: 'medium' }}
							contentStyle={{ $fontStyle: 'label_2', $fontWeight: 'regular' }}
						/>
					</VStack>
					<VStack $flex="1">
						<InsideTitle
							title="고객메모"
							rightRender={
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={() => {
										onMemoMutate();
									}}
								>
									<Icon id={IconID.SAVE} width="2rem" height="2rem" />
									저장
								</Button>
							}
						/>
						<TextArea
							style={{ height: 'auto', flex: 1 }}
							name="memo"
							onChange={onChangeValues}
							value={values.memo}
							maxLength={MEMO_LENGTH}
							count={{ show: true, max: MEMO_LENGTH }}
							placeholder={MEMO_MESSAGES.PLACEHOLDER}
						/>
					</VStack>
				</HStack>
				<VStack>
					<InsideTitle title="닉네임" />
					<VStack>
						<VStack $gap="1.6rem">
							<VStack $gap="0.8rem">
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleNickname('POST', values.nickname);
									}}
								>
									<TextField
										style={{ flex: 1 }}
										count={{ show: true, max: 10 }}
										maxLength={10}
										placeholder={
											data?.nicknames.length !== NICKNAME_MAX_COUNT
												? NICKNAME_MESSAGES.PLACEHOLDER
												: NICKNAME_MESSAGES.INFO
										}
										name="nickname"
										value={values.nickname}
										onChange={onChangeValues}
										disabled={data?.nicknames.length === NICKNAME_MAX_COUNT}
										renderPrefix={
											isSuccess &&
											data.nicknames.length > 0 && (
												<HStack $gap={'0.6rem'} $wrap="wrap">
													{data.nicknames.map((nickname, idx) => (
														<Tag key={'nickname--' + nickname} fontSize={'body_3'} color={'PRIMARY'} type={'FILL'}>
															{nickname}
															<IconButton
																width="1.4rem"
																height="1.4rem"
																shouldPrevent={true}
																onClick={() => {
																	const filterNickname = data.nicknames.filter((_, i) => i !== idx);
																	handleNickname('DELETE', filterNickname);
																}}
															>
																<Icon id={IconID.BTN_CLOSE} width="1.3rem" height="1.3rem" defaultColor="gray_600" />
															</IconButton>
														</Tag>
													))}
												</HStack>
											)
										}
									/>
								</form>
								<InfoText
									text={`${NICKNAME_MESSAGES.INFO} (${data?.nicknames.length ?? 0}/${NICKNAME_MAX_COUNT}개)`}
									style={{ padding: 0 }}
									innerStyle={{ gap: '0.4rem', alignItems: 'center' }}
									font="body_3"
									color={{ background: 'white', font: 'gray_700' }}
								/>
							</VStack>
						</VStack>
					</VStack>
				</VStack>
			</VStack>
			<VStack>
				<Tabs
					tabGaps={{ horizon: '3.6rem' }}
					tabStyle={{ height: '4.4rem' }}
					labelStyle={{ $fontStyle: 'title_3' }}
					borderStyle={{ weight: { selected: '3px', default: '0px' } }}
					contents={[
						{
							label: '소속 그룹',
							panel: <AffiliatedGroup />,
						},
						{
							label: '결제 내역',
							panel: <PaymentHistory />,
						},
					]}
				/>
			</VStack>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.8rem;
`;
export default Detail;
