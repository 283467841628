import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import pharmsTableColumns from './tableColumns';
import { Button, IconButtons } from '../../../components/Buttons';
import { type IconButtonsData } from '../../../components/Buttons/IconButtons';
import { Icon } from '../../../components/Display';
import { Filter } from '../../../components/Filter';
import { FilterList } from '../../../components/Filter/components/FilterList';
import { type FilterItems } from '../../../components/Filter/components/FilterModal/types';
import { TextField, Select } from '../../../components/Forms';
import { type CategoryOptions } from '../../../components/Forms/Select/components/Options';
import { ConfirmModal } from '../../../components/Modals';
import { GroupModal } from '../../../components/Modals/GroupModal';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useSelectedItems } from '../../../hooks/useSelectedItems';
import { useValues } from '../../../hooks/useValues';
import { usePostBlockMembers } from '../../../services/block/queries';
import { useGetGroups } from '../../../services/groups/queries';
import { type Group } from '../../../services/groups/types';
import { useGetMembers, useGetMembersExcel } from '../../../services/member/queries';
import { type MemberData, type GetMembersRequest, type GetMemberSearchType } from '../../../services/member/types';
import { type Search } from '../../../services/types';
import {
	type DefaultOption,
	AGE_OPTIONS,
	GENDER_OPTIONS,
	IS_PAYMENT_OPTIONS,
	isFavoriteOptions,
} from '../../../utils/consts';
import { formatSearch } from '../../../utils/format';
import { handleChangeSorts } from '../../../utils/onChangeSorts';
import { onDownloadBlob } from '../../../utils/onDownloadBlob';

const tmpSearchOptions: Array<DefaultOption<GetMemberSearchType>> = [
	{
		key: 'PHONE',
		label: '전화번호',
	},
	{ key: 'NAME', label: '이름' },
	{
		key: 'NICKNAME',
		label: '닉네임',
	},
];

const Information = () => {
	const navigate = useNavigate();
	const { handleOpen } = context.modal.useDispatch();
	const { userInfo } = context.user.useValue();

	const { data: groupsData } = useGetGroups({ initListCount: 999 });

	const groupOptions = useMemo(() => {
		const result: CategoryOptions<Group> = [
			{
				category: null,
				options: [
					{
						id: -1,
						name: '전체',
						category: 'NORMAL',
						categoryName: '',
						description: '',
						isSystem: true,
						memberCount: 0,
						favoriteMemberCount: 0,
					},
				],
			},
		];
		if (!groupsData) return result;

		return groupsData.content.reduce((acc, cur) => {
			const group = acc.find((g) => g.category === cur.categoryName);
			if (group) {
				group.options.push(cur);
			} else {
				acc.push({ category: cur.categoryName, options: [cur] });
			}
			return acc;
		}, result);
	}, [groupsData]);

	const { data, refetch, isLoading, pagination, queries } = useGetMembers({
		storeId: userInfo.storeId,
		initialValues: {
			sorts: { property: 'registrationAt', direction: 'DESC' },
		},
	});

	const { selectedItems, handleSelectedItems } = useSelectedItems<MemberData>({
		dependency: [queries.values, pagination.curPage, pagination.listCount],
	});
	const selectedUserIds = useMemo(() => selectedItems.map((item) => item.userId), [selectedItems]);

	const { mutate } = usePostBlockMembers({
		onSuccess: () => {
			toast.success('선택한 회원을 차단하였습니다.');
			handleSelectedItems('RESET');
			refetch();
		},
	});

	const initTmpSearch: Search<GetMemberSearchType> = useMemo(
		() => ({
			searchCategory: 'PHONE',
			searchKeyword: '',
		}),
		[],
	);
	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(initTmpSearch);

	const onSearchSubmit = () => {
		pagination.resetCurPage();

		const trimValue = formatSearch(tmpSearch.searchKeyword ?? '', tmpSearch.searchCategory);
		queries.dispatch('SET', { search: { ...tmpSearch, searchKeyword: trimValue } });
	};

	const { mutate: excelMutate, isPending } = useGetMembersExcel({
		onSuccess: (res) => {
			onDownloadBlob(res, '회원목록.xlsx', 'application/octet-stream');
		},
		onError: (error) => {
			toast.error('엑셀 다운로드에 실패하였습니다.\n' + error.response?.data.message);
		},
	});

	const onExcelDownload = useCallback(() => {
		excelMutate(queries.values);
	}, [userInfo, queries]);

	const informationFilterItems = useMemo(() => {
		if (!groupsData) return [];
		return [
			{
				label: '성별',
				key: 'gender',
				type: 'RADIO',
				radioProps: {
					name: 'gender',
					options: GENDER_OPTIONS,
					$direction: 'COLUMN',
				},
			},
			{
				label: '결제여부',
				key: 'isPayment',
				type: 'RADIO',
				radioProps: {
					name: 'isPayment',
					options: IS_PAYMENT_OPTIONS,
					$direction: 'COLUMN',
				},
			},
			{
				label: '단골여부',
				key: 'isFavorite',
				type: 'RADIO',
				radioProps: {
					name: 'isFavorite',
					options: isFavoriteOptions,
					$direction: 'COLUMN',
				},
			},
			{
				label: '소속 그룹',
				key: 'groupIds',
				type: 'SELECT',
				selectProps: {
					multiple: { allKey: -1, label: '그룹' },
					optionsStyle: { maxHeight: '30.4rem' },
					options: groupOptions,
					selectKey: 'id',
					label: 'name',
				},
			},
			{
				label: '약국 등록일',
				key: 'period',
				type: 'PERIOD',
			},
			{
				label: '연령대',
				key: 'ages',
				type: 'SELECT',
				selectProps: {
					multiple: { allKey: 'ALL', label: '연령' },
					options: AGE_OPTIONS,
				},
			},
		] as FilterItems<GetMembersRequest>;
	}, [groupOptions]);

	const buttons = useMemo(() => {
		if (!userInfo) return [];

		return [
			{
				icon: {
					id: 'ban',
				},
				label: '고객차단',
				onClick: () => {
					handleOpen(
						<ConfirmModal
							confirm={() => {
								mutate({ storeId: userInfo?.storeId, data: { userIds: selectedUserIds } });
							}}
							message={
								'선택한 회원을 모두 차단하시겠습니까? \n차단된 회원은 메시지를 보낼 수 없고 회원목록에서 삭제됩니다.'
							}
							buttonMessage={{ confirm: '차단', shouldPrevent: true }}
						/>,
					);
				},
				disabled: selectedUserIds.length <= 0,
			},
			{
				icon: {
					id: 'group',
				},
				label: '그룹지정',
				onClick: () => {
					handleOpen(
						<GroupModal
							userIds={selectedUserIds}
							onSuccess={() => {
								toast.success('선택한 회원이 그룹에 추가되었습니다.');
								handleSelectedItems('RESET');
								refetch();
							}}
						/>,
					);
				},
				disabled: selectedUserIds.length <= 0,
			},
		] as IconButtonsData[];
	}, [selectedItems, selectedUserIds]);

	return (
		<React.Fragment>
			<ListTable<MemberData>
				data={data?.content}
				columns={pharmsTableColumns}
				isLoading={isLoading}
				pagination={pagination}
				bodyItemStyle={{ padding: '0 1.2rem' }}
				tableHeaderItemStyle={{ padding: '0 1.2rem' }}
				unit="명"
				selected={{ selectKey: 'userId', multiple: { selectedItems, handleSelectedItems } }}
				onRowClick={(item) => {
					navigate(`/member/information/detail/${item.userId}`);
				}}
				onCategoryClick={(column) => {
					handleChangeSorts(queries.values.sorts, column, (sorts) => {
						queries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={queries.values.sorts}
				headerLeftContent={
					<TableLeft>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								onSearchSubmit();
							}}
						>
							<Select
								options={tmpSearchOptions}
								value={tmpSearch.searchCategory}
								labelStyle={{ width: '10.4rem' }}
								onClick={(key) => {
									tmpSearchDispatch('SET', {
										searchCategory: key as GetMemberSearchType,
										searchKeyword: '',
									});
								}}
							/>
							<TextField
								renderPrefix={<Icon id="btn-search" width="2rem" height="2rem" />}
								inputSize={'sm'}
								style={{ borderRadius: '4px', width: '32rem' }}
								placeholder="검색어를 입력해주세요."
								value={tmpSearch.searchKeyword}
								name="searchKeyword"
								onChange={onChangeTmpSearch}
							/>
						</form>
						<Filter items={informationFilterItems} resetCurPage={pagination.resetCurPage} queries={queries} />
					</TableLeft>
				}
				headerRightContent={
					<TableRight>
						<IconButtons data={buttons} />
						<Button
							onClick={() => {
								onExcelDownload();
							}}
							shouldPrevent={true}
							mutationKey={['excel']}
							disabled={isPending}
							size={{ $paddingSize: 'M' }}
						>
							<Icon id="file" defaultColor="white" width="2rem" height="2rem" />
							엑셀 다운로드
						</Button>
					</TableRight>
				}
				headerBottomContent={
					<FilterList items={informationFilterItems} resetCurPage={pagination.resetCurPage} queries={queries} />
				}
			/>
		</React.Fragment>
	);
};

const TableRight = styled.div`
	display: flex;
	gap: 2rem;
	align-items: center;
`;
const TableLeft = styled.div`
	display: flex;
	gap: 0.8rem;
	> form {
		display: flex;
		gap: 0.8rem;
		align-items: center;
	}
`;

export default Information;
