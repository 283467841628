import styled, { css } from 'styled-components';

import { Footer } from './Footer';
import { InsideTitle } from './InsideTitle';
import { Loading } from './Loading';
import { changeColorKeyToType, type ColorKeys } from '../../utils/changeColorKeyToType';

interface BoxProps {
	$width?: string;
	$height?: string;
	$padding?: string;
	$bg?: ColorKeys;
}

const Box = styled.div<BoxProps>`
	${({ $bg }) =>
		$bg &&
		css`
			background-color: ${changeColorKeyToType($bg)};
		`}
	width: ${({ $width }) => ($width !== null ? $width : '100%')};
	${({ $height }) => $height && `height: ${$height}`};
	${({ $padding }) => $padding && `padding: ${$padding}`};
`;

const Center = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface FlexProps extends BoxProps {
	$gap?: string;
	$direction?: 'column' | 'row';
	$wrap?: 'wrap' | 'nowrap';
	$flex?: string;
	$justify?: 'start' | 'end' | 'between' | 'center';
	$alignItems?: 'start' | 'end' | 'stretch' | 'center';
	$alignSelf?: 'start' | 'end' | 'stretch' | 'center';
	$alignText?: 'left' | 'right' | 'center';
}

const Flex = styled(Box)<FlexProps>`
	display: flex;
	${({ $gap }) =>
		$gap &&
		css`
			gap: ${$gap};
		`};
	${({ $direction }) =>
		$direction === 'column' &&
		css`
			flex-direction: column;
		`};
	${({ $wrap }) =>
		$wrap &&
		css`
			flex-wrap: ${$wrap};
		`};
	${({ $flex }) =>
		$flex &&
		css`
			flex: ${$flex};
		`};
	${({ $justify }) => {
		switch ($justify) {
			case 'start':
				return css`
					justify-content: flex-start;
				`;
			case 'end':
				return css`
					justify-content: flex-end;
				`;
			case 'between':
				return css`
					justify-content: space-between;
				`;
			case 'center':
				return css`
					justify-content: center;
				`;
		}
	}};

	${({ $alignItems }) => {
		switch ($alignItems) {
			case 'start':
				return css`
					align-items: flex-start;
				`;
			case 'end':
				return css`
					align-items: flex-end;
				`;
			case 'stretch':
				return css`
					align-items: stretch;
				`;
			case 'center':
				return css`
					align-items: center;
				`;
		}
	}};

	${({ $alignSelf }) => {
		switch ($alignSelf) {
			case 'start':
				return css`
					align-self: flex-start;
				`;
			case 'end':
				return css`
					align-self: flex-end;
				`;
			case 'stretch':
				return css`
					align-self: stretch;
				`;
			case 'center':
				return css`
					align-self: center;
				`;
		}
	}};

	${({ $alignText }) => {
		switch ($alignText) {
			case 'left':
				return css`
					text-align: left;
				`;
			case 'right':
				return css`
					text-align: right;
				`;
			case 'center':
				return css`
					text-align: center;
				`;
		}
	}};
`;

const VStack = styled(Flex)`
	flex-direction: column;
`;

const HStack = styled(Flex)`
	flex-direction: row;
`;

interface GridProps {
	$columns?: number;
	$gap?: string;
}
const Grid = styled.div<GridProps>`
	display: grid;
	${({ $gap, $columns = 1 }) => css`
		grid-template-columns: repeat(${$columns}, 1fr);
		gap: ${$gap};
	`}
`;

export { Box, Center, Flex, VStack, HStack, Grid };

export { Loading, InsideTitle, Footer };
