import { type ButtonColorStyle, type ButtonFontStyle, type ButtonSizeType } from './types';

const buttonFontStyles: ButtonFontStyle = {
	XS: { fontKey: 'caption_1', fontWeight: 'regular' },
	S: { fontKey: 'caption_1', fontWeight: 'medium' },
	M: { fontKey: 'label_2', fontWeight: 'regular' },
	L: { fontKey: 'label_2', fontWeight: 'medium' },
	XL: { fontKey: 'title_2', fontWeight: 'bold' },
} as const;

const buttonPadding: Record<ButtonSizeType, string> = {
	XS: '0.6rem',
	S: '1rem',
	M: '1.2rem',
	L: '1.6rem',
	XL: '2.4rem',
} as const;

const buttonHeight: Record<ButtonSizeType, string> = {
	XS: '2.4rem',
	S: '2.8rem',
	M: '3.6rem',
	L: '4rem',
	XL: '5.2rem',
} as const;

const buttonColorStyles: ButtonColorStyle = {
	FILLED: {
		PRIMARY: {
			color: 'white',
			backgroundColor: 'primary_600',
			disabled: {
				backgroundColor: 'gray_400',
			},
			hover: {
				backgroundColor: 'primary_500',
			},
			active: {
				backgroundColor: 'primary_700',
			},
		},
		SECONDARY: {
			color: 'primary_800',
			backgroundColor: 'primary_200',
			disabled: {
				backgroundColor: 'gray_400',
				color: 'white',
			},
			hover: {
				backgroundColor: 'primary_100',
			},
			active: {
				backgroundColor: 'primary_300',
			},
		},
		TERTIARY: {
			color: 'gray_900',
			backgroundColor: 'gray_200',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'gray_100',
			},
			active: {
				backgroundColor: 'gray_300',
			},
		},
		RED: {
			color: 'white',
			backgroundColor: 'red_600',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'red_500',
			},
			active: {
				backgroundColor: 'red_700',
			},
		},
		GRAY: {
			color: 'gray_600',
			backgroundColor: 'gray_200',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'gray_100',
			},
			active: {
				backgroundColor: 'gray_300',
			},
		},
		ORANGE: {
			color: 'white',
			backgroundColor: 'yellow_600',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'yellow_500',
			},
			active: {
				backgroundColor: 'yellow_600',
			},
		},
		GREEN: {
			color: 'white',
			backgroundColor: 'green_600',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'green_500',
			},
			active: {
				backgroundColor: 'green_600',
			},
		},
		BLACK: {
			color: 'white',
			backgroundColor: 'gray_700',
			disabled: {
				backgroundColor: 'gray_200',
				color: 'gray_600',
			},
			hover: {
				backgroundColor: 'gray_600',
			},
			active: {
				backgroundColor: 'gray_700',
			},
		},
	},
	LINE: {
		PRIMARY: {
			color: 'primary_700',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'primary_500',
			},
			disabled: {
				color: 'gray_500',
				border: {
					color: 'gray_500',
				},
			},
			hover: {
				backgroundColor: 'primary_100',
			},
			active: {
				backgroundColor: 'primary_200',
			},
		},
		TERTIARY: {
			color: 'primary_700',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'primary_500',
			},
			disabled: {
				color: 'gray_500',
				border: {
					color: 'gray_500',
				},
			},
			hover: {
				backgroundColor: 'primary_100',
			},
			active: {
				backgroundColor: 'primary_200',
			},
		},
		SECONDARY: {
			color: 'gray_800',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'gray_300',
			},
			disabled: {
				color: 'gray_500',
				border: {
					color: 'gray_500',
				},
			},
			hover: {
				backgroundColor: 'gray_100',
			},
			active: {
				backgroundColor: 'gray_200',
			},
		},
		RED: {
			color: 'red_500',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'red_500',
			},
			disabled: {
				color: 'red_300',
				border: {
					color: 'red_300',
				},
			},
			hover: {
				backgroundColor: 'red_200',
			},
			active: {
				backgroundColor: 'red_300',
			},
		},
		GRAY: {
			color: 'red_500',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'red_500',
			},
			disabled: {
				color: 'red_300',
				border: {
					color: 'red_300',
				},
			},
			hover: {
				backgroundColor: 'red_200',
			},
			active: {
				backgroundColor: 'red_300',
			},
		},
		ORANGE: {
			color: 'red_500',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'red_500',
			},
			disabled: {
				color: 'red_300',
				border: {
					color: 'red_300',
				},
			},
			hover: {
				backgroundColor: 'red_200',
			},
			active: {
				backgroundColor: 'red_300',
			},
		},
		GREEN: {
			color: 'red_500',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'red_500',
			},
			disabled: {
				color: 'red_300',
				border: {
					color: 'red_300',
				},
			},
			hover: {
				backgroundColor: 'red_200',
			},
			active: {
				backgroundColor: 'red_300',
			},
		},
		BLACK: {
			color: 'black',
			backgroundColor: 'white',
			border: {
				width: '0.1rem',
				style: 'solid',
				color: 'gray_300',
			},
			disabled: {
				color: 'gray_300',
				border: {
					color: 'gray_300',
				},
			},
			hover: {
				backgroundColor: 'gray_100',
			},
			active: {
				backgroundColor: 'gray_200',
			},
		},
	},
} as const;

export const buttonStyles = {
	...buttonColorStyles,
	font: { ...buttonFontStyles },
	padding: { ...buttonPadding },
	height: { ...buttonHeight },
} as const;
