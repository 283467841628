import React from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon, IconID } from '../../../components/Display';
import context from '../../../context';
import Theme from '../../../lib/styledComponents/Theme';
import { formatPathArray } from '../../../utils/format';
import { type MenuList } from '../data/NavData';

interface NavListProps {
	menuList: MenuList;
	path: string[];
	openKeys: string[];
	handleOpenKeys: (key: string) => void;
	isParentHover: boolean;
}

const NavList = (props: NavListProps) => {
	const { menuList, path, openKeys, handleOpenKeys, isParentHover } = props;

	const { userInfo } = context.user.useValue();
	const { type } = userInfo ?? { type: null };
	const { label, list } = menuList;

	return (
		<Container $isParentHover={isParentHover}>
			<p className="label">{label}</p>
			<Depth01>
				{list.map((depth01) => {
					const isDepth01Type = depth01.grade.find((grage) => grage === type);
					const isActive = path[0] === formatPathArray(depth01.path)[0] || path[0] === formatPathArray(depth01.path)[0];

					const hasNextDepth = depth01.nextDepth && depth01.nextDepth.filter((el) => !el.isHidden).length > 0;

					const isOpen = openKeys.includes(depth01.key);
					const item = (
						<Depth01Item
							onClick={() => {
								handleOpenKeys(depth01.key);
							}}
							$isActive={isActive}
							$isOpen={isOpen}
							$isParentHover={isParentHover}
						>
							{depth01.iconID && (
								<Icon
									id={depth01.iconID}
									isActive={isActive}
									fill={isActive ? Theme.colors.primary.primary_600 : 'none'}
									stroke={!isActive ? Theme.colors.black : Theme.colors.white}
								/>
							)}
							<span className="depth-01-label">{depth01.label}</span>
							{hasNextDepth && (
								<Icon
									id={IconID.CHEVRON}
									defaultColor={'gray_600'}
									isActive={isActive}
									className="arrow"
									width={'1.8rem'}
								/>
							)}
						</Depth01Item>
					);
					if (!isDepth01Type) return <React.Fragment key={depth01.key} />;

					return (
						<li key={depth01.key}>
							{depth01.path && (!hasNextDepth || depth01.isBlank) ? (
								<Link to={depth01.path} target={depth01?.isBlank ? '_blank' : '_self'}>
									{item}
								</Link>
							) : (
								item
							)}
							{hasNextDepth && (
								<Depth02 $isOpen={isOpen} $isParentHover={isParentHover} $isHidden={depth01.isHidden}>
									{depth01.nextDepth!.map((depth02) => {
										const isDepth02Type = depth02.grade.find((grage) => grage === type);
										if (!depth02.path || !isDepth02Type) return <React.Fragment key={depth01.key + depth02.key} />;
										if (depth02.isHidden) return <></>;

										return (
											<li key={depth01.key + depth02.key}>
												<Link
													to={depth02?.isBlank ? depth02.path : depth01?.path + depth02.path}
													target={depth02?.isBlank ? '_blank' : '_self'}
												>
													<Depth02Item
														$isActive={
															path[0] === formatPathArray(depth01.path)[0] &&
															path[1] === formatPathArray(depth02.path)[0]
														}
													>
														<span className="bullet" />
														<span className="depth-02-label">{depth02.label}</span>
													</Depth02Item>
												</Link>
											</li>
										);
									})}
								</Depth02>
							)}
						</li>
					);
				})}
			</Depth01>
		</Container>
	);
};

const Depth02Item = styled.div<{ $isActive: boolean }>`
	display: flex;
	align-items: center;
	gap: 1.2rem;
	padding: 0 2.4rem;
	height: 5.2rem;
	border-right: 3px solid transparent;
	cursor: pointer;

	.depth-02-label {
		${({ theme }) => theme.font.body.body_2}
	}

	.bullet {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.4rem;
		height: 2.4rem;
		&::after {
			content: '';
			position: absolute;
			display: inline-block;
			width: 0.7rem;
			height: 0.7rem;
			background-color: ${({ theme }) => theme.colors.gray.gray_500};
			border-radius: 50%;
		}
		&::before {
			content: '';
			position: absolute;
			display: inline-block;
			width: 1px;
			height: 220%;
			background-color: ${({ theme }) => theme.colors.gray.gray_500};
		}
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			background-color: ${({ theme }) => theme.colors.primary.primary_100};
			border-right: 3px solid ${({ theme }) => theme.colors.primary.primary_600};

			.depth-02-label {
				font-weight: 500;
				color: ${({ theme }) => theme.colors.primary.primary_600};
			}

			.bullet::after {
				background-color: ${({ theme }) => theme.colors.primary.primary_600};
			}
		`}
`;

const Depth02 = styled.ul<{ $isOpen: boolean; $isParentHover: boolean; $isHidden?: boolean }>`
	display: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'flex')};
	flex-direction: column;
	max-height: ${({ $isOpen }) => ($isOpen ? '40rem' : '0')};
	overflow: hidden;
	transition: 0.3s max-height;

	li:first-child .bullet:before,
	li:last-child .bullet:before {
		height: 110%;
	}
	li:first-child .bullet:before {
		top: 60%;
	}
	li:last-child .bullet:before {
		bottom: 60%;
	}
	li:only-child .bullet:before {
		display: none;
	}
	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		${({ $isParentHover }) =>
			!$isParentHover &&
			css`
				display: none;
			`}
	}
`;

const Depth01Item = styled.div<{
	$isActive: boolean;
	$isOpen: boolean;
	$isParentHover: boolean;
}>`
	padding: 1.4rem 2.4rem;
	display: flex;
	gap: 1.2rem;
	align-items: center;
	cursor: pointer;

	.depth-01-label {
		${({ theme }) => theme.font.body.body_2}
	}
	.arrow {
		margin-left: auto;
		transition: 0.3s transform;
		transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg) translateY(0rem)' : 'rotate(0deg) translateY(0rem)')};
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			color: ${({ theme }) => theme.colors.primary.primary_500};
			font-weight: 700;
		`}
	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		${({ $isParentHover }) =>
			!$isParentHover &&
			css`
				.depth-01-label {
					display: none;
				}
				.arrow {
					display: none;
				}
			`}
	}
`;

const Depth01 = styled.ul`
	display: flex;
	flex-direction: column;
`;

const Container = styled.div<{ $isParentHover: boolean }>`
	padding: 0.8rem 0;
	.label {
		${({ theme }) => theme.font.body.body_2};
		color: ${({ theme }) => theme.colors.gray.gray_600};
		padding: 0.8rem 2.4rem;
		font-weight: 500;
	}
	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		${({ $isParentHover }) =>
			!$isParentHover &&
			css`
				.label {
					display: none;
				}
			`}
	}
`;

export default NavList;
