import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import { Placeholder, PlaceHolderTypes } from './Placeholder';
import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { VStack } from '../../../../components/Common';
import { useOnScrollPositionChangeDetector } from '../../../../hooks/useOnScrollPositionChangeDetector';
import { useChannelListContext } from '../../context/ChannelListProvider';
import { ChannelListItem } from '../ChannelListItem';

const REGULAR_EMPTY = '대화중인 단골 회원이 없습니다.';
const FROZEN_EMPTY = '대화중인 이전 회원이 없습니다.';

export const ChannelListView = () => {
	const { selectedChannelUrl, channelListDataSource, filters, onChannelSelect } = useChannelListContext();
	const { initialized, groupChannels, loadMore } = channelListDataSource;

	const wrapRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLDivElement>(null);

	/**
	 * 화면 높이 상 채널리스트를 더 불러올 수 있을 경우
	 */
	useEffect(() => {
		if (listRef.current && wrapRef.current) {
			if (listRef.current.clientHeight < wrapRef.current.clientHeight) {
				loadMore();
			}
		}
	}, [groupChannels]);

	const onScroll = useOnScrollPositionChangeDetector({
		onReachedBottom: () => {
			loadMore();
		},
	});

	if (!initialized) {
		return (
			<Container>
				<Placeholder type={PlaceHolderTypes.LOADING} />
			</Container>
		);
	}

	return (
		<Container
			ref={wrapRef}
			onScroll={onScroll}
			onContextMenu={(e) => {
				e.preventDefault();
			}}
		>
			<Wrapper ref={listRef}>
				{groupChannels.map((channel, index) => (
					<ChannelListItem
						key={index}
						channel={channel}
						onClick={() => {
							onChannelSelect(channel);
						}}
						isSelected={channel.url === selectedChannelUrl}
					/>
				))}
			</Wrapper>
			{initialized && groupChannels.length === 0 && (
				<Placeholder
					type={PlaceHolderTypes.NO_CHANNELS}
					comment={filters?.includeFrozen === true ? FROZEN_EMPTY : REGULAR_EMPTY}
				/>
			)}
		</Container>
	);
};

const Container = styled(VStack)`
	flex: 1 1 0;
	width: 29.4rem;
	overflow: hidden auto;
	${scrollStyle({ hideHorizontal: true })};
	&::-webkit-scrollbar {
		width: 0.6rem;
	}
`;

const Wrapper = styled(VStack)`
	height: fit-content;
`;
