import React from 'react';

import { type UserMessage } from '@sendbird/chat/message';

import DiscountInformation from './DiscountInformation';
import { MessageHeader, type MessageHeaderProps } from './MessageHeader';
import Products from './Products';
import { LABELS } from './PromotionMessages';
import { Container, Divider, Wrapper } from './styles';
import { Flex, VStack } from '../../../../../components/Common';
import { IconID, Label } from '../../../../../components/Display';
import { commaizeNumber } from '../../../../../utils/format';
import { getMessageData } from '../../../getMessageData';
import { type PaymentMessageData } from '../../../types';

type HeaderType = 'READY' | 'COMPLETED' | 'ZERO' | 'DIRECT';

const headerData: Record<HeaderType, MessageHeaderProps> = {
	READY: {
		iconType: IconID.MSG_PAYMENT,
		title: '결제 요청',
		subText: '고객님께 아래 결제 금액을 요청했습니다.\n' + '잠시만 기다려 주세요.',
	},
	ZERO: {
		iconType: IconID.MSG_PAYMENT,
		title: '결제 금액',
		subText: '결제할 금액이 없습니다. 고객님이 방문하시면 제품을 전달해 주세요.',
	},
	COMPLETED: {
		iconType: IconID.MSG_PAYMENT,
		title: '결제 완료',
		subText: '고객님이 앱에서 결제를 완료하였습니다.\n' + '고객님이 방문하시면 제품을 전달해 주세요.',
	},
	DIRECT: {
		iconType: IconID.MSG_PAYMENT,
		title: '현장결제 예정',
		subText: '고객님이 현장 결제를 선택하셨습니다.\n' + '고객님이 방문하시면 결제를 진행해 주세요.',
	},
};

interface PaymentMessagesProps {
	message: UserMessage;
}

export const PaymentMessages = ({ message }: PaymentMessagesProps) => {
	const messageData = getMessageData<PaymentMessageData>(message.data);

	if (messageData === null) {
		return <></>;
	}
	const { type, payment } = messageData;
	const { type: medicationType, status, totalAmount, products, actualAmount } = payment;

	return (
		<Container>
			<Wrapper>
				{type === 'REQUEST' && <MessageHeader {...headerData.READY} />}
				{type === 'RESPONSE' && status === 'ZERO' && <MessageHeader {...headerData.ZERO} />}
				{type === 'RESPONSE' && status === 'COMPLETED' && <MessageHeader {...headerData.COMPLETED} />}
				{type === 'RESPONSE' && status === 'DIRECT' && <MessageHeader {...headerData.DIRECT} />}

				<Products products={products ?? []} />
				<Divider />
				<Flex
					$gap={'1.2rem'}
					$direction={'column'}
					$alignSelf={'stretch'}
					$alignItems={'start'}
					$padding="1.2rem 1.6rem 1.6rem"
				>
					<VStack $gap="0.2rem" $width="100%">
						<Label $fontStyle={'body_3'} $fontWeight="bold">
							{LABELS[medicationType]}
						</Label>
						<Label $fontStyle={'title_1'} $fontWeight={'bold'} $alignSelf="end">
							{commaizeNumber(status === 'COMPLETED' && typeof actualAmount === 'number' ? actualAmount : totalAmount)}
							원
						</Label>
					</VStack>
					<DiscountInformation paymentData={payment} />
				</Flex>
			</Wrapper>
		</Container>
	);
};
