import React from 'react';

import { MessageHeader, type MessageHeaderProps } from './MessageHeader';
import { buttonStyle, Container } from './styles';
import { Button } from '../../../../../components/Buttons';
import { HStack } from '../../../../../components/Common';
import { IconID } from '../../../../../components/Display';
import context from '../../../../../context';
import { getMessageData } from '../../../getMessageData';
import { type SideEffectMessageData } from '../../../types';
import { SideEffectModal } from '../../SideEffectModal';

import type { UserMessage } from '@sendbird/chat/message';

const headerData: MessageHeaderProps = {
	iconType: IconID.MSG_SIDE_EFFECT,
	title: '부작용 문의',
	subText: '약사님! 부작용 문의가 있어요. 상세 내용 확인 후 답변해 주세요.',
};

interface SideEffectMessageProps {
	message: UserMessage;
}
export const SideEffectMessage = ({ message }: SideEffectMessageProps) => {
	const messageData = getMessageData<SideEffectMessageData>(message.data);
	const { handleOpen } = context.modal.useDispatch();

	if (messageData === null) {
		return <></>;
	}

	return (
		<Container>
			<MessageHeader {...headerData} />
			<HStack $gap="0.8rem" $padding="0 1.6rem 1.6rem" $width="100%">
				<Button
					onClick={() => {
						handleOpen(<SideEffectModal messageData={messageData} />);
					}}
					color={'TERTIARY'}
					{...buttonStyle}
				>
					{'상세 보기'}
				</Button>
			</HStack>
		</Container>
	);
};
