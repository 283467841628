import React, { useContext, useMemo, useState } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { type GroupChannelListParams, type GroupChannelListQueryParams } from '@sendbird/chat/lib/__definition';

import { useSBStateContext } from '../../../context/sendbirdSdk';
import { noop } from '../../../utils/noop';
import { useChannelList } from '../hooks/useChannelList';
import { useChannelListQuery } from '../hooks/useChannelListQuery';

export type ChannelListDataSource = ReturnType<typeof useChannelList>;

/**
 * metadataOrderKeyFilter - 이전 회원 채널은 metadata {freeze: true} 값 존재.
 */
export type ChannelListQueryFilterParamsType = Pick<
	GroupChannelListParams,
	'includeFrozen' | 'nicknameContainsFilter' | 'order' | 'metadataOrderKeyFilter'
>;

export interface ChannelListContextType {
	// Essential
	onChannelSelect: (channel: GroupChannel | null) => void;

	// Default
	selectedChannelUrl?: string | null;

	// Custom
	// for channel list collection (default)
	channelListDataSource: ChannelListDataSource;

	// for channel list query (filter)
	filters?: ChannelListQueryFilterParamsType;
	updateFilters: React.Dispatch<React.SetStateAction<ChannelListQueryFilterParamsType>>;
}

export type PartialRequired<T, RequiredKeys extends keyof T> = Partial<Omit<T, RequiredKeys>> &
	Required<Pick<T, RequiredKeys>>;

export interface ChannelListProviderProps extends PartialRequired<ChannelListContextType, 'onChannelSelect'> {
	children?: React.ReactNode;
}

const ChannelListContext = React.createContext<ChannelListContextType | null>(null);

const ChannelListProvider = (props: ChannelListProviderProps) => {
	const { children, onChannelSelect = noop, selectedChannelUrl, filters } = props;

	const globalStore = useSBStateContext();
	const { stores } = globalStore;
	const { sdkStore } = stores;
	const sdk = sdkStore.sdk;

	const [filterParams, setFilterParams] = useState<GroupChannelListQueryParams>(filters ?? {});

	const channelListDataSource = useChannelList(sdk, {});

	const isFiltering = useMemo(() => {
		const { includeFrozen, nicknameContainsFilter } = filterParams;
		if (!includeFrozen) {
			return nicknameContainsFilter !== undefined && nicknameContainsFilter !== '';
		} else {
			return true;
		}
	}, [
		Object.entries(filterParams ?? {})
			.map(([key, value]) => key + value)
			.join(),
	]);

	const filteredChannelListDataSource = useChannelListQuery(sdk, {
		filterParams,
	});

	return (
		<ChannelListContext.Provider
			value={{
				// Default
				selectedChannelUrl,
				// Essential
				onChannelSelect,
				// ReturnType<UseChannelList>
				channelListDataSource: isFiltering ? filteredChannelListDataSource : channelListDataSource,

				filters: filterParams,
				updateFilters: setFilterParams,
			}}
		>
			{children}
		</ChannelListContext.Provider>
	);
};

export const useChannelListContext = () => {
	const context = useContext(ChannelListContext);
	if (!context) {
		throw new Error('ChannelListContext not found. Use within the ChannelList module.');
	}
	return context;
};

export { ChannelListContext, ChannelListProvider };
