import React, { useCallback, useMemo, useState } from 'react';

import { format } from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../../components/Buttons';
import { Flex, InsideTitle } from '../../../components/Common';
import { Icon, IconID, Label } from '../../../components/Display';
import context from '../../../context';
import { type UnitQueryResultType, useGetPeriodDetails } from '../../../services/statistics/queries';
import { commaizeNumber } from '../../../utils/format';
import { HistoryStorage } from '../../../utils/historyStorage';
import { Calendar } from '../components/Calendar';
import { DateNavigation } from '../components/DateNavigation';
import { Container, Wrapper } from '../components/styles';
import { getDateEndOfUnit, getDateStartOfUnit, formatPeriod } from '../utils';

function SalesCalendar() {
	const navigate = useNavigate();
	const history = new HistoryStorage<string>('date');

	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;

	const [date, setDate] = useState<string>(() => history.get() ?? format(new Date(), 'yyyy-MM-dd'));

	const period = useMemo(() => {
		const [start, end] = [getDateStartOfUnit(date, 'monthly'), getDateEndOfUnit(date, 'monthly')];
		return formatPeriod([start, end]);
	}, [date]);

	const { data, isSuccess } = useGetPeriodDetails<'daily', Array<UnitQueryResultType<'daily'>>>({
		storeId,
		period: [period],
		unit: 'daily',
		select: (data) => data.slice().reverse(),
	});

	const handleUpdateDate = (updated: Date) => {
		const updatedDate = format(updated, 'yyyy-MM-dd');
		setDate(updatedDate);
		history.set(updatedDate);
	};

	const renderDayContent = useCallback(
		(date: string, numberOfDay: number) => {
			if (!isSuccess) {
				return <></>;
			}
			const details = [...data[0]];
			return (
				<DayContentWrapper>
					<DayContentItem $type={'px'}>
						<Label>{'조제'}</Label>
						<Label $textAlign={'right'} style={{ flex: '1 0 0' }}>
							{commaizeNumber(details[numberOfDay - 1]?.pxSales)}
						</Label>
					</DayContentItem>
					<DayContentItem $type={'sales'}>
						<Label>{'판매'}</Label>
						<Label $textAlign={'right'} style={{ flex: '1 0 0' }}>
							{commaizeNumber(details[numberOfDay - 1]?.sales)}
						</Label>
					</DayContentItem>
				</DayContentWrapper>
			);
		},
		[data, isSuccess],
	);

	return (
		<Container>
			<Wrapper>
				<InsideTitle
					title={'매출 달력'}
					style={{ minHeight: '6.4rem' }}
					rightRender={
						<Flex $justify={'center'} $alignItems={'center'} $gap={'1.6rem'}>
							<DateNavigation date={period.startDate} onChangeDate={handleUpdateDate} unit={'monthly'} />
							<Button
								onClick={() => {
									navigate(`/stats/details/monthly?date=${format(period.startDate, 'yyyy-MM')}`);
								}}
								buttonType={'LINE'}
								color={'BLACK'}
							>
								<Icon id={IconID.SEARCH_DETAILS} width={'1.6rem'} height={'1.6rem'} />
								{'월간 상세'}
							</Button>
						</Flex>
					}
				/>
				<Calendar
					date={date}
					onClickDay={(day) => {
						navigate(`/stats/details/daily?date=${day}`);
					}}
					onClickWeek={(startDate) => {
						navigate(`/stats/details/weekly?date=${startDate}`);
					}}
					renderDayContent={renderDayContent}
				/>
			</Wrapper>
		</Container>
	);
}

const DayContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
`;

const DayContentItem = styled.div<{ $type: 'px' | 'sales' }>`
	display: flex;
	padding: 0.4rem 0.8rem;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;

	border-radius: 4px;
	background-color: ${({ $type }) => ($type === 'px' ? '#f7e9ff' : '#DCFAF8')};

	${Label} {
		color: ${({ theme }) => theme.colors.gray.gray_900};
		${({ theme }) => theme.font.title.title_3};
		${({ theme }) => theme.font.weight.medium};
	}
`;

export default SalesCalendar;
