import React from 'react';

import { Flex } from '../../../../components/Common';
import { Label } from '../../../../components/Display';

interface DateSeparatorProps {
	children?: string | React.ReactElement;
}
export const DateSeparator = ({ children = '' }: DateSeparatorProps) => {
	return (
		<Flex $alignItems={'center'} $justify={'center'} $padding={'1.6rem 2.4rem'}>
			<Flex
				$height={'3.2rem'}
				$padding={'0 1.2rem'}
				$justify={'center'}
				$alignItems={'center'}
				$bg={'gray_100'}
				style={{
					borderRadius: '99px',
				}}
			>
				<Label $fontStyle={'caption_1'} $color={'gray_700'}>
					{children}
				</Label>
			</Flex>
		</Flex>
	);
};
