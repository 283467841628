import React, { useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import { tokenizeMessage } from './tokenize';
import { isAnnouncementMessage } from './utils';
import { VStack } from '../../../../../components/Common';
import { Icon, IconID, Label } from '../../../../../components/Display';
import { ContextMenu, MenuItem, MenuItems } from '../../../../../components/Modals/ContextMenu';
import { highlightedText } from '../../../../MessageSearch/components/SearchMessageItem';
import { useMessageSearchContext } from '../../../../MessageSearch/context/MessageSearchProvider';
import { useChannelContext } from '../../../context/ChannelProvider';
import { removeImageUrls } from '../../../utils';

import type { UserMessage } from '@sendbird/chat/message';

interface TextMessageBodyProps {
	isByMe: boolean;
	message: UserMessage;
	isImageMessage: boolean;
}

export const TextMessageBody = ({ isByMe, message, isImageMessage }: TextMessageBodyProps) => {
	const { deleteUserMessage } = useChannelContext();
	const { requestString, activeSearch } = useMessageSearchContext();
	const { resulting } = activeSearch;
	const messageRef = useRef<HTMLDivElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const showMenuItemDelete = useMemo(() => isByMe && !isAnnouncementMessage(message), [message, isByMe]);
	// 상대방이 읽었는지 확인 하는 코드
	// 2024-06-05 : 약사가 환자한테 다른 영수증 보낸 후 삭제하지 못해 약사는 읽어도 삭제가능하게 해달라는 요청사항이 있어서 제외
	// currentChannel?.getUnreadMemberCount(message) !== 0;

	const tokens = useMemo(() => tokenizeMessage(removeImageUrls(message.message)), [message?.message]);

	return (
		<TextMessageItem
			$isImageMessage={isImageMessage}
			$isByMe={isByMe}
			onContextMenu={(e) => {
				if (message.sendingStatus === 'succeeded') {
					e.preventDefault();
					setMenuOpen(true);
				}
			}}
			ref={messageRef}
		>
			<Label
				$fontStyle={'body_2'}
				$color={!isImageMessage && isByMe ? 'white' : 'black'}
				style={{
					width: '100%',
					wordBreak: 'break-word',
				}}
			>
				{tokens?.map((token, idx) => {
					const highlighted = resulting ? highlightedText(token.value, requestString) : token.value;
					if (token.type === 'url') {
						const url = /https?:\/\//.test(token.value) ? token.value : `http://${token.value}`;
						return (
							<a key={idx} href={url} target="_blank" rel="noopener noreferrer">
								{highlighted}
							</a>
						);
					} else {
						return <React.Fragment key={idx}>{highlighted}</React.Fragment>;
					}
				})}
			</Label>
			<ContextMenu
				isOpen={menuOpen}
				menuItems={() => (
					<MenuItems
						parentRef={messageRef}
						closeDropdown={() => {
							setMenuOpen(false);
						}}
						isOnCursor={true}
					>
						<MenuItem
							onClick={() => {
								navigator.clipboard.writeText(message.message);
								setMenuOpen(false);
							}}
						>
							<>
								{'복사'}
								<Icon id={IconID.VIEWER_COPY} width={'2rem'} height={'2rem'} />
							</>
						</MenuItem>
						{showMenuItemDelete && (
							<MenuItem
								onClick={() => {
									deleteUserMessage(message.messageId);
									setMenuOpen(false);
								}}
							>
								<>
									{'삭제'}
									<Icon id={IconID.BTN_DELETE} width={'2rem'} height={'2rem'} />
								</>
							</MenuItem>
						)}
					</MenuItems>
				)}
			/>
		</TextMessageItem>
	);
};

const TextMessageItem = styled(VStack)<{ $isByMe?: boolean; $isImageMessage: boolean }>`
	width: fit-content;
	max-width: 26.4rem;
	padding: 1rem 1.2rem;
	justify-content: center;
	align-items: center;
	white-space: break-spaces;

	${({ $isImageMessage }) =>
		$isImageMessage &&
		css`
			width: 100%;
		`}

	align-self: ${({ $isByMe }) => ($isByMe ? `flex-end` : `flex-start`)};

	background-color: ${({ theme, $isByMe, $isImageMessage }) =>
		$isImageMessage ? theme.colors.white : $isByMe ? theme.colors.primary.primary_700 : theme.colors.gray.gray_200};

	a {
		text-decoration: underline;
		color: ${({ theme, $isByMe, $isImageMessage }) =>
			!$isImageMessage && $isByMe ? theme.colors.yellow.yellow_400 : theme.colors.primary.primary_700};
	}

	mark {
		background-color: ${({ theme }) => theme.colors.black};
		color: ${({ theme }) => theme.colors.white};
	}
`;
