import React, { useMemo } from 'react';

import styled from 'styled-components';

import { commaizeNumber } from '../../../utils/format';
import { Icon, IconID, type IconProps, Label } from '../index';

import { calculateDiff, type ComparisonCardProps } from './index';

export interface SingleProps extends ComparisonCardProps {
	iconProps?: IconProps;
	standard?: string;
}

export function Single({ title, value, iconProps, unit = '', standard = '', type = 'count' }: SingleProps) {
	const { cur = 0, prev = 0 } = value;
	const diff = useMemo(() => calculateDiff(type, cur, prev), [type, cur, prev]);
	return (
		<Wrapper>
			<UpperWrapper>
				<UpperInner>
					<Label $color={'gray_900'} $fontWeight={'medium'} $fontStyle={'body_2'}>
						{title}
					</Label>
					<Label $fontWeight={'bold'} $fontStyle={'title_1'}>
						{commaizeNumber(cur)}
						{unit}
					</Label>
				</UpperInner>

				{iconProps?.id && (
					<IconWrapper>
						<Icon id={iconProps.id} width={iconProps?.width} height={iconProps?.height} />
					</IconWrapper>
				)}
			</UpperWrapper>
			<Inner>
				{diff !== 0 ? (
					<>
						<Icon id={diff > 0 ? IconID.FILLED_UP : IconID.FILLED_DOWN} width={'2rem'} height={'2rem'} />
						<Label
							$color={'gray_900'}
							$fontWeight={'medium'}
							$fontStyle={'body_3'}
						>{`${standard} ${commaizeNumber(Math.abs(diff))}${type === 'percent' ? '%' : ''} ${diff > 0 ? '증가' : '감소'}`}</Label>
					</>
				) : (
					<></>
				)}
			</Inner>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	padding: 1.6rem 2rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.2rem;
	flex: 1 0 0;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
`;

const UpperWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 1.2rem;
	align-self: stretch;
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	gap: 0.2rem;
	min-height: 2rem;
`;

const UpperInner = styled(Inner)`
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
`;

const IconWrapper = styled.div`
	display: flex;
	width: 4.4rem;
	height: 4.4rem;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	background: ${({ theme }) => theme.colors.gray.gray_100};
`;
