import React from 'react';

import { DRUG_USAGES_COLUMNS } from './columns';
import { StatsStyledTable } from './styles';
import ListTable from '../../../../components/Table/ListTable';
import { useGetDrugUsagesTotal } from '../../../../services/statistics/queries';

interface DrugUsagesProps {
	storeId: string;
	startDate: string;
	endDate: string;
}

export function DrugUsages({ storeId, startDate, endDate }: DrugUsagesProps) {
	const { data } = useGetDrugUsagesTotal({ storeId, startDate, endDate });

	return (
		<StatsStyledTable>
			<ListTable data={data} columns={DRUG_USAGES_COLUMNS} maxHeight={'16.5rem'} />
		</StatsStyledTable>
	);
}
