import React, { useMemo } from 'react';

import styled from 'styled-components';

import { DataKeys, initValue, type PickedDetail } from './AvgCardList';
import { ComparisonCard, type SingleCardListProps } from '../../../components/Display';
import { sum } from '../index';

import type { StatsDetail } from '../../../services/statistics/types';

type CardListDataType = { total: number } & PickedDetail & { pxSales: number };

const CARD_LIST_ITEMS: SingleCardListProps<CardListDataType>['items'] = [
	{ title: '금월 총 고객수', type: 'count', by: 'total', standard: '전월 동기시 대비' },
	{ title: '금월 총 조제 건수', type: 'count', by: 'pxCount', standard: '전월 동기시 대비' },
	{ title: '금월 총 판매 건수', type: 'count', by: 'saleCount', standard: '전월 동기시 대비' },
	{ title: '금월 매출 총이익', type: 'percent', by: 'grossProfit', unit: '원', standard: '전월 동기시 대비' },
	{ title: '금월 조제 총이익', type: 'percent', by: 'pxSales', unit: '원', standard: '전월 동기시 대비' },
	{ title: '금월 총 판매매출', type: 'percent', by: 'sales', unit: '원', standard: '전월 동기시 대비' },
];

interface TotalsCardListProps {
	ages: Array<{ total: number }>;
	details: StatsDetail[][];
	isSuccess: boolean;
}

export function TotalsCardList({ ages, details, isSuccess }: TotalsCardListProps) {
	const [cur, prev] = useMemo(() => {
		if (isSuccess) {
			const [curAges, prevAges] = [ages[0], ages[1]];
			const [curDetails, prevDetails] = [[...details[0]], [...details[1]].slice(0, details[0].length)];

			const prevDetailsTotals = DataKeys.reduce<PickedDetail>((acc, cur) => {
				const obj = { ...acc };
				obj[cur as keyof PickedDetail] = sum(prevDetails, cur);
				return obj;
			}, initValue);

			const curDetailsTotals = DataKeys.reduce<PickedDetail>((acc, cur) => {
				const obj = { ...acc };
				obj[cur as keyof PickedDetail] = sum(curDetails, cur);
				return obj;
			}, initValue);

			return [
				{
					...curAges,
					...curDetailsTotals,
					pxSales: curDetailsTotals.grossProfit - curDetailsTotals.sales,
				},
				{
					...prevAges,
					...prevDetailsTotals,
					pxSales: prevDetailsTotals.grossProfit - prevDetailsTotals.sales,
				},
			] as [CardListDataType, CardListDataType];
		}
		return [{}, {}] as [CardListDataType, CardListDataType];
	}, [isSuccess, ages, details]);

	return (
		<CardWrapper>
			<CardRow>
				<ComparisonCard.CardList<CardListDataType> data={cur} prevData={prev} items={CARD_LIST_ITEMS.slice(0, 3)} />
			</CardRow>
			<CardRow>
				<ComparisonCard.CardList<CardListDataType> data={cur} prevData={prev} items={CARD_LIST_ITEMS.slice(3)} />
			</CardRow>
		</CardWrapper>
	);
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.2rem;
	min-width: 78.4rem;
`;

const CardRow = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 1.2rem;
	align-self: stretch;
`;
