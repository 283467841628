import {
	type PharmImage,
	type PharmInfo,
	type PharmOpenNow,
	type PharmOpenWeekday,
	type GetPharmProfileResponse,
	type PutPharmProfileRequest,
	type PharmRequest,
	type OpenHours,
	type PharmRestDayResponse,
	type PostPharmRestDaysRequest,
	type DeletePharmRestDayRequest,
	type NoticeItem,
	type DetailDefaultParams,
	type PostNoticeRequest,
	type GetPharmNoticeResponse,
	type GetPharmNoticeRequest,
} from './types';
import { Delete, Get, Patch, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';

export const pharm = {
	getPharmInfo: async <T = PharmInfo>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}`);
		return response.data;
	},
	getPharmOpenWeekDay: async <T = PharmOpenWeekday>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/open/weekday`);
		return response.data;
	},
	getPharmOpenNow: async <T = PharmOpenNow>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/open/now`);
		return response.data;
	},
	getPharmImage: async <T = PharmImage>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/profile-image`, {
			responseType: 'blob',
		});
		return response.data;
	},
	getPharmProfile: async <T = GetPharmProfileResponse>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/profile`);
		return response.data;
	},
	putPharmProfile: async <T = any>({ storeId, ...rest }: PutPharmProfileRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/profile`, rest);
		return response.data;
	},
	getPharmOpenHours: async <T = OpenHours>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/open-hours`);
		return response.data;
	},
	putPharmOpenHours: async <T = any>({ storeId, ...rest }: PharmRequest & OpenHours): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/open-hours`, rest);
		return response.data;
	},
	getPharmRestDays: async <T = PharmRestDayResponse[]>({ storeId }: PharmRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/rest-days`);
		return response.data;
	},
	postPharmRestDays: async <T = PharmRestDayResponse>({ storeId, ...rest }: PostPharmRestDaysRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/rest-days`, rest);
		return response.data;
	},
	deletePharmRestDays: async <T = any>({ storeId, restDayId }: DeletePharmRestDayRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/rest-days/${restDayId}`);
		return response.data;
	},
	getNoticeList: async <T = GetPharmNoticeResponse>({ storeId, ...rest }: GetPharmNoticeRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/notices${formatQueryString(rest)}`);
		return response.data;
	},
	postNotice: async <T = NoticeItem>({ storeId, ...data }: PostNoticeRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/notices`, data);
		return response.data;
	},
	patchNoticePublic: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Patch<T>(`/pharms/${storeId}/notices/${id}/public`);
		return response.data;
	},
	deleteNoticePublic: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/notices/${id}/public`);
		return response.data;
	},
	patchNoticeMain: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Patch<T>(`/pharms/${storeId}/notices/${id}/main`);
		return response.data;
	},
	deleteNoticeMain: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/notices/${id}/main`);
		return response.data;
	},
	patchNoticePush: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Patch<T>(`/pharms/${storeId}/notices/${id}/push`);
		return response.data;
	},
	getNotice: async <T = NoticeItem>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/notices/${id}`);
		return response.data;
	},
	putNotice: async <T = any>({ storeId, id, ...data }: PostNoticeRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/notices/${id}`, data);
		return response.data;
	},
	deleteNotice: async <T = any>({ storeId, id }: DetailDefaultParams): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/notices/${id}`);
		return response.data;
	},
};
