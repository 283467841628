import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ellipsis1 } from '../../../../../../assets/styles/ellipsis';
import { Button, IconButton } from '../../../../../../components/Buttons';
import { Icon, IconID } from '../../../../../../components/Display';
import { GroupsModal } from '../../../../../../components/Modals/GroupsModal';
import { ListTable } from '../../../../../../components/Table';
import { type Columns } from '../../../../../../components/Table/ListTable/types';
import context from '../../../../../../context';
import { useDeleteMemberGroup, useMemberGroup, usePostMemberGroup } from '../../../../../../services/member/queries';
import { type GetMemberRequest, type MemberGroup } from '../../../../../../services/member/types';

export const AffiliatedGroup = () => {
	const { userId } = useParams();
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const params = useMemo(() => {
		const result: GetMemberRequest = { storeId: undefined, userId: undefined };
		if (userId) result.userId = Number(userId);
		if (userInfo) result.storeId = userInfo.storeId;
		return result;
	}, [userInfo, userId]);

	const { data, refetch } = useMemberGroup(params);
	const { mutate } = useDeleteMemberGroup({
		...params,
		onSuccess: () => {
			toast.success('성공적으로 그룹을 삭제하였습니다.');
			refetch();
		},
	});
	const memberGroupIds = useMemo(() => {
		if (!data) return [];
		return data.map((group) => group.id);
	}, [data]);

	const affiliatedGroupTableColumn = useMemo(() => {
		const result: Columns<MemberGroup> = [
			{
				key: 'name',
				headerName: '그룹명',
				width: { max: '28rem', min: '20rem' },
				isClick: true,
			},
			{
				key: 'categoryName',
				headerName: '카테고리',
				width: { max: '17.6rem', min: '14.4rem' },
				isClick: true,
			},
			{
				key: 'memberCount',
				headerName: '그룹 인원 수',
				renderComponent: (value) => value?.toLocaleString() + '명',
				width: { max: '20.8rem', min: '16rem' },
				style: { justifyContent: 'flex-end' },
				isClick: true,
			},
			{
				key: 'description',
				headerName: '그룹 설명',
				width: { max: '', min: '44rem' },
				renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
				isClick: true,
			},
			{
				key: 'id',
				headerName: '',
				renderComponent: (value) => (
					<div style={{ padding: '0 1.6rem' }}>
						<IconButton
							shouldPrevent={true}
							onClick={() => {
								mutate({ groupIds: [value] });
							}}
						>
							<Icon id={IconID.TRASH} />
						</IconButton>
					</div>
				),
				width: { max: '5.6rem', min: '' },
				style: { justifyContent: 'flex-end' },
			},
		];
		return result;
	}, []);

	const { mutate: postGroupMutate } = usePostMemberGroup({
		...params,
		onSuccess: () => {
			toast.success('성공적으로 그룹을 지정하였습니다.');
			refetch();
		},
	});

	return (
		<Container>
			<ListTable
				data={data}
				columns={affiliatedGroupTableColumn}
				isScroll={false}
				headerStyle={{ padding: '1.2rem 0' }}
				tableStyle={{ border: 'none' }}
				tableHeaderStyle={{ border: 'none' }}
				headerLeftContent={<span className="count">{data?.length}개의 그룹</span>}
				headerRightContent={
					<>
						<Button
							buttonType="LINE"
							color="SECONDARY"
							onClick={() => {
								handleOpen(
									<GroupsModal
										disabledGroupIds={memberGroupIds}
										onSelect={(groupIds) => {
											postGroupMutate({ groupIds });
										}}
									/>,
								);
							}}
						>
							<Icon id={IconID.GROUP} width="2rem" height="2rem" />
							그룹 지정
						</Button>
					</>
				}
				emptyMessage="그룹이 없습니다."
			/>
		</Container>
	);
};

const Container = styled.div`
	padding-top: 0.4rem;
	.count {
		${({ theme }) => theme.font.title.title_2};
		font-weight: 700;
	}
`;
