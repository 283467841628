import React, { type CSSProperties, useEffect } from 'react';

import styled from 'styled-components';

import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { useValues } from '../../../../hooks/useValues';
import { Button } from '../../../Buttons';
import { Icon, IconID } from '../../../Display';
import { FloatingModal, type FloatingModalProps } from '../../../Modals/FloatingModal';
import { type FilterProps } from '../../index';
import { FilterItem } from '../FilterItem';

interface FilterModalProps<T extends object> extends FilterProps<T>, Omit<FloatingModalProps, 'referenceRef'> {
	wrapperRef: React.RefObject<HTMLElement>;
	itemStyle?: CSSProperties;
	handleClose: () => void;
}

export const FilterModal = <T extends object>(props: FilterModalProps<T>) => {
	const {
		isOpen,
		handleClose,
		items,
		resetCurPage,
		wrapperRef,
		queries,
		itemStyle = { padding: '1.2rem 1.6rem' },
	} = props;
	const tmpQueries = useValues(queries.values);

	useEffect(() => {
		tmpQueries.dispatch('SET', queries.values);
	}, [queries.values, isOpen]);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		resetCurPage();
		queries.dispatch('SET', tmpQueries.values);
		handleClose();
	};

	return (
		<FloatingModal
			isOpen={isOpen}
			handleClose={handleClose}
			header={{ title: '검색 필터' }}
			referenceRef={wrapperRef}
			options={{ allowedPlacements: ['bottom-start', 'bottom-end'] }}
		>
			<Container onSubmit={onSubmit}>
				<div className="contents">
					{items.map((item) => (
						<FilterItem
							key={'filter__item--' + String(item.label)}
							item={item}
							queries={tmpQueries}
							style={itemStyle}
						/>
					))}
				</div>
				<div className="buttons">
					<Button
						buttonType="LINE"
						className="reset__button"
						onClick={() => {
							resetCurPage();
							queries.dispatch('RESET');
							tmpQueries.dispatch('RESET');
						}}
						style={{ marginRight: 'auto' }}
						size={{ $paddingSize: 'M' }}
					>
						<Icon id={IconID.ROTATE_RIGHT} defaultColor="primary_600" width="1.6rem" height="1.6rem" />
						초기화
					</Button>

					<Button color="SECONDARY" buttonType="LINE" size={{ $paddingSize: 'XL' }} onClick={handleClose}>
						취소
					</Button>
					<Button type="submit" size={{ $paddingSize: 'XL' }}>
						필터 적용
					</Button>
				</div>
			</Container>
		</FloatingModal>
	);
};

const Container = styled.form`
	width: 37.5rem;
	.contents {
		display: flex;
		flex-direction: column;
		max-height: 40rem;
		${scrollStyle()};
	}
	.buttons {
		border-top: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		display: flex;
		padding: 1.6rem;
		gap: 0.8rem;
		justify-content: flex-end;
	}
`;
