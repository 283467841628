import React, { type CSSProperties, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import { type Asset } from '../../../services/asset/types';
import { Icon, IconID, Label } from '../../Display';
import { FileUploader } from '../FileUploader';

const MAX_COUNT = 5;

interface ImageInputsProps {
	defaultAssets: Asset[];
	addImages: File[];
	setAddImages: React.Dispatch<React.SetStateAction<File[]>>;
	onDeleteImages?: (assetId: number) => void;
	imageBoxSize?: 'S' | 'M';
	style?: CSSProperties;
}
export const ImageInputs = (props: ImageInputsProps) => {
	const { defaultAssets, addImages, setAddImages, imageBoxSize = 'M', onDeleteImages, style } = props;

	const [hoveredImage, setHoveredImage] = useState<string>('');
	const [maxCount, setMaxCount] = useState<number>(MAX_COUNT - defaultAssets.length);

	const memoizedAssets = useMemo(() => [...defaultAssets], [defaultAssets]);

	const handleDeleteCur = (assetId: number, idx: number) => {
		onDeleteImages?.(assetId);
		memoizedAssets.splice(idx, 1);
		setMaxCount((prev) => prev + 1);
	};

	return (
		<Wrapper style={{ ...style }}>
			<FileUploader
				onChange={(file) => {
					setAddImages(file);
				}}
				values={addImages}
				format={['image']}
				maxCount={maxCount}
				maxCapacity={{ single: { unit: 'MB', size: 5 } }}
			>
				{({ onClickTrigger, onDelete, convertedUrls }) => (
					<>
						<ImageInput $imageBoxSize={imageBoxSize} onClick={onClickTrigger}>
							<Icon id={IconID.CAMERA} />
							<Label $color={'gray_800'} $fontStyle={'label_2'} $textAlign={'center'}>
								{'사진 추가'}
							</Label>
						</ImageInput>
						{memoizedAssets?.map((as, idx) => (
							<ImagePreview
								key={idx}
								$order={10 - idx}
								$imageBoxSize={imageBoxSize}
								$imageUrl={as.url}
								onMouseEnter={() => {
									setHoveredImage('as' + idx);
								}}
								onMouseLeave={() => {
									setHoveredImage('');
								}}
							>
								{'as' + idx === hoveredImage && (
									<HoverButton
										onClick={() => {
											handleDeleteCur(as.id, idx);
										}}
										$imageBoxSize={imageBoxSize}
									>
										{'삭제'}
									</HoverButton>
								)}
							</ImagePreview>
						))}
						{convertedUrls.map((url, idx) => (
							<ImagePreview
								key={idx}
								$order={5 - idx}
								$imageUrl={url}
								$imageBoxSize={imageBoxSize}
								onMouseEnter={() => {
									setHoveredImage('add' + idx);
								}}
								onMouseLeave={() => {
									setHoveredImage('');
								}}
							>
								{'add' + idx === hoveredImage && (
									<HoverButton
										onClick={() => {
											onDelete(idx);
										}}
										$imageBoxSize={imageBoxSize}
									>
										{'삭제'}
									</HoverButton>
								)}
							</ImagePreview>
						))}
					</>
				)}
			</FileUploader>
		</Wrapper>
	);
};

const Wrapper = styled.ul`
	display: flex;
	align-items: center;
	gap: 1.2rem;
	max-width: 58rem;
	flex-wrap: wrap;
	counter-reset: count 0;
`;

const ImageInput = styled.li<{ $imageBoxSize: 'S' | 'M' }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	order: -1;
	gap: 0.8rem;
	border-radius: 4px;
	border: 1.25px dashed ${({ theme }) => theme.colors.gray.gray_300};
	background-color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	${({ $imageBoxSize }) => {
		const imageBoxLength = $imageBoxSize === 'S' ? '8rem' : '14rem';
		return css`
			width: ${imageBoxLength};
			height: ${imageBoxLength};
		`;
	}}
`;

const ImagePreview = styled(ImageInput)<{ $imageUrl: string; $order: number }>`
	background: url(${({ $imageUrl }) => $imageUrl}) lightgray 50% / cover no-repeat;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	position: relative;
	isolation: isolate;
	overflow: hidden;
	cursor: auto;
	flex-shrink: 0;
	order: ${({ $order }) => $order};

	&::before {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		counter-increment: count 1;
		content: counter(count);
		opacity: 1;
		position: absolute;
		top: 0;
		left: 0;
		background: ${({ theme }) => theme.colors.black};
		border-radius: 0 0 8px;
		color: ${({ theme }) => theme.colors.white};
		${({ theme }) => theme.font.weight.bold};

		${({ $imageBoxSize }) => {
			const imageBoxLength = $imageBoxSize === 'S' ? '2.4rem' : '3.2rem';
			const fontStyle = $imageBoxSize === 'S' ? 'label_2' : 'label_1';
			return css`
				width: ${imageBoxLength};
				height: ${imageBoxLength};
				${({ theme }) => theme.font.label[fontStyle]}
			`;
		}}
	}
`;

const HoverButton = styled.div<{ $imageBoxSize: 'S' | 'M' }>`
	display: flex;
	width: 100%;
	padding: 0 0.8rem;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	color: ${({ theme }) => theme.colors.gray.gray_200};
	${({ theme }) => theme.font.label.label_2};
	${({ theme }) => theme.font.weight.medium};
	cursor: pointer;

	${({ $imageBoxSize }) => {
		const buttonHeight = $imageBoxSize === 'S' ? '2.4rem' : '3.2rem';
		return css`
			height: ${buttonHeight};
		`;
	}}
`;
