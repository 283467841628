import React from 'react';

import styled from 'styled-components';

import { Icon, type IconTypes } from '../../../../../components/Display';

export interface MessageHeaderProps {
	iconType: IconTypes;
	title: string;
	subText: string;
}
export const MessageHeader = ({ iconType, title, subText }: MessageHeaderProps) => {
	return (
		<Wrapper>
			<TitleWrap>
				<Icon id={iconType} />
				{title}
			</TitleWrap>
			<SubTextWrap>{subText}</SubTextWrap>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.6rem;
	align-self: stretch;
	padding: 1.6rem 1.6rem 1.2rem;
`;

const TitleWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	align-self: stretch;

	${({ theme }) => theme.font.title.title_2};
	${({ theme }) => theme.font.weight.bold};
`;

const SubTextWrap = styled.span`
	align-self: stretch;
	color: ${({ theme }) => theme.colors.gray.gray_700};
	${({ theme }) => theme.font.body.body_2};
	${({ theme }) => theme.font.weight.regular};
`;
