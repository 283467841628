import type React from 'react';
import { useEffect, useState, useRef } from 'react';

/**
 * @param {boolean} isOnCursor ui가 커서 바로 밑에 위치
 */
interface UseDropdownPositionProps {
	parentRef: React.RefObject<HTMLElement>;
	openLeft?: boolean;
	isContextmenu?: boolean;
	closeDropdown: () => void;
}

export const useDropdownPosition = (props: UseDropdownPositionProps) => {
	const { parentRef, openLeft = false, isContextmenu = false, closeDropdown } = props;

	const internalRef: React.RefObject<HTMLDivElement> = useRef(null);

	const [position, setPosition] = useState({ top: -1000, left: -1000 });

	// position: absolute
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const calculatePositionAbsolute = () => {
		if (!parentRef.current || !internalRef.current) return { top: 0, left: 0 };

		const parentRect = parentRef.current.getBoundingClientRect();
		const rect = internalRef.current.getBoundingClientRect();
		const { innerWidth, innerHeight } = window;

		const x = 0;
		const y = parentRect.height;

		let top = y;
		let left = x;

		if (parentRect.y + y + rect.height > innerHeight) {
			top = y - parentRect.height - rect.height;
		} else {
			top = parentRect.height;
		}

		if (openLeft) {
			// parent 오른쪽 기준 정렬
			if (parentRect.x - rect.width < 0) {
				// 화면 넘어갈때
				left = 0;
			} else {
				left = x - rect.width + parentRect.width;
			}
		} else {
			// parent 왼쪽 기준 정렬
			if (parentRect.x + rect.width > innerWidth) {
				left = x + parentRect.width - rect.width;
			} else {
				left = 0;
			}
		}

		setPosition({ top, left });
	};

	// position: fixed
	const calculatePosition = () => {
		if (isContextmenu) return;
		if (!parentRef.current || !internalRef.current) return { top: 0, left: 0 };

		const parentRect = parentRef.current.getBoundingClientRect();
		const rect = internalRef.current.getBoundingClientRect();

		const x = parentRect.left || 0;
		const y = parentRect.top || 0;
		const { innerWidth, innerHeight } = window;

		let top = y;
		let left = x;

		if (y + parentRect.height + rect.height > innerHeight) {
			// 화면 넘어갈 때
			top = y - rect.height;
		} else {
			top = y + parentRect.height;
		}

		if (openLeft) {
			// parent 오른쪽 기준 정렬
			if (x - rect.width < 0) {
				left = x;
			} else {
				left = x - rect.width + parentRect.width;
			}
		} else {
			// parent 왼쪽 기준 정렬
			if (x + rect.width > innerWidth) {
				// 화면 넘어갈때
				left = x + parentRect.width - rect.width;
			}
		}

		top = Math.max(0, top);
		left = Math.max(0, left);

		setPosition({ top, left });
	};

	/**
	 * contextmenu (mouse right click) 포지션
	 */
	const updatePosition = (e: MouseEvent) => {
		const x = e.pageX;
		const y = e.pageY;

		let left = x;
		let top = y;

		if (internalRef.current) {
			const rect = internalRef.current.getBoundingClientRect();
			const { innerWidth, innerHeight } = window;

			if (x + rect.width > innerWidth) {
				left = x - rect.width;
			}

			if (y + rect.height > innerHeight) {
				top = y - rect.height;
			}
		}
		setPosition({ left, top });
	};

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (parentRef.current && parentRef.current.contains(event.target as Node)) {
				calculatePosition();
				if (isContextmenu) {
					closeDropdown();
				}
			} else if (internalRef.current && !internalRef.current.contains(event.target as Node)) {
				closeDropdown();
			}
		};

		const handleContextmenu = (event: MouseEvent) => {
			if (parentRef.current && parentRef.current.contains(event.target as Node)) {
				updatePosition(event);
			} else if (internalRef.current && !internalRef.current.contains(event.target as Node)) {
				closeDropdown();
			}
		};

		const handleScroll = (e: Event) => {
			e.preventDefault();
			// closeDropdown();
		};

		document.addEventListener('wheel', handleScroll, { passive: false });
		document.addEventListener('contextmenu', handleContextmenu);
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('wheel', handleScroll);
			document.removeEventListener('contextmenu', handleContextmenu);
			document.removeEventListener('click', handleClick);
		};
	}, [internalRef]);

	return { position, dropdownRef: internalRef };
};
