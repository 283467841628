import { useRef } from 'react';

import { type SendbirdGroupChat } from '@sendbird/chat/groupChannel';

import { useChannelHandler } from './useChannelHandler';
import { basicParamsConstants } from './useChannelList';
import { useAsyncEffect } from '../../../hooks/useAsyncEffect';
import { usePreservedCallback } from '../../../hooks/usePreservedCallback';
import { type ChannelListQueryFilterParamsType } from '../context/ChannelListProvider';
import { useChannelListReducer } from '../reducer';

import type { GroupChannelListQuery, GroupChannelListQueryParams } from '@sendbird/chat/lib/__definition';

interface UseChannelListQuery {
	filterParams?: ChannelListQueryFilterParamsType;
}

const createChannelListQuery = (sdk: SendbirdGroupChat, queryParams: UseChannelListQuery['filterParams']) => {
	const basicParams: GroupChannelListQueryParams = {
		includeEmpty: basicParamsConstants.collection.groupChannel.defaultIncludeEmpty,
		includeFrozen: basicParamsConstants.collection.groupChannel.defaultIncludeFrozen,
		limit: basicParamsConstants.collection.groupChannel.defaultLimit,
		order: basicParamsConstants.collection.groupChannel.defaultOrder,
	};

	return sdk.groupChannel.createMyGroupChannelListQuery({
		...basicParams,
		...queryParams,
	});
};
/**
 * @description 검색, 필터링된 채널리스트 fetch
 * group channel list query 사용
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/channel/searching-channels/search-group-channels-by-name-url-or-other-filters}
 */
export const useChannelListQuery = (sdk: SendbirdGroupChat, options?: UseChannelListQuery) => {
	const queryRef = useRef<GroupChannelListQuery>();

	const {
		initialized,
		groupChannels,
		refreshing,
		appendChannels,
		updateChannels,
		deleteChannels,
		updateRefreshing,
		updateInitialized,
	} = useChannelListReducer();

	const init = usePreservedCallback(async (uid?: string) => {
		if (uid) {
			queryRef.current = createChannelListQuery(sdk, options?.filterParams);

			if (queryRef.current?.hasNext) {
				const channels = await queryRef.current?.next();
				appendChannels(channels ?? [], true);
			}
		}
	});

	useAsyncEffect(async () => {
		// 컬렉션을 사용하는 초기 화면에서는 init 하지 않음
		if (
			!(
				options?.filterParams?.includeFrozen === undefined &&
				options?.filterParams?.nicknameContainsFilter === undefined
			)
		) {
			updateInitialized(false);
			if (sdk.currentUser) {
				await init(sdk.currentUser.userId);
				updateInitialized(true);
			}
		}
	}, [
		sdk,
		sdk.currentUser,
		Object.entries(options?.filterParams ?? {})
			.map(([key, value]) => key + value)
			.join(),
	]);

	const refresh = usePreservedCallback(async () => {
		if (sdk.currentUser) {
			updateRefreshing(true);
			await init(sdk.currentUser.userId);
			updateRefreshing(false);
		}
	});

	const loadMore = usePreservedCallback(async () => {
		if (queryRef.current?.hasNext) {
			const channels = await queryRef.current?.next();
			appendChannels(channels ?? [], false);
		}
	});

	useChannelHandler(sdk, {
		onChannelFrozen: (channel) => {
			if (channel.isGroupChannel()) {
				!options?.filterParams?.includeFrozen && deleteChannels([channel.url]);
				options?.filterParams?.includeFrozen && appendChannels([channel], false);
			}
		},
		onChannelUnfrozen: (channel) => {
			if (channel.isGroupChannel()) {
				options?.filterParams?.includeFrozen && deleteChannels([channel.url]);
				!options?.filterParams?.includeFrozen && appendChannels([channel], false);
			}
		},
		onChannelChanged: (channel) => {
			if (channel.isGroupChannel()) {
				updateChannels([channel]);
			}
		},
		onMessageReceived: (channel, message) => {
			if (channel.isGroupChannel() && channel?.lastMessage?.isEqual(message)) {
				updateChannels([channel]);
			}
		},
		onMessageUpdated: (channel, message) => {
			if (channel.isGroupChannel() && channel?.lastMessage?.isEqual(message)) {
				updateChannels([channel]);
			}
		},
	});

	return {
		/**
		 * Initializing state, only available on first render
		 * */
		initialized,

		/**
		 * Get group channels state
		 * */
		groupChannels,

		/**
		 * Refresh, clear and reload messages from latest
		 * @return {Promise<void>}
		 * */
		refresh,

		/**
		 * Refreshing state, status is changes when the refresh is called.
		 * */
		refreshing,

		/**
		 * Fetch more channels to state
		 * @return {Promise<void>}
		 * */
		loadMore,
	};
};
