import React, { useCallback } from 'react';

import styled from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID, Label, type StyledLabelProps } from '../../../../components/Display';
import { FileUploader } from '../../../../components/Forms';
import { ConfirmModal, TemplatesModal } from '../../../../components/Modals';
import context from '../../../../context';
import { useSBStateContext } from '../../../../context/sendbirdSdk';
import { usePostPayment } from '../../../../services/chat/queries';
import { type PaymentRequest } from '../../../../services/chat/types';
import { type Template } from '../../../../services/templates/types';
import { useChannelContext } from '../../context/ChannelProvider';
import { getMemberId } from '../../context/utils';
import { useHandleSendFiles } from '../../hooks/useHandleSendFiles';
import { PaymentModal } from '../PaymentModal';

const buttonStyle = {
	width: '4.8rem',
	height: '4.8rem',
} as const;

const labelStyle: StyledLabelProps = {
	$fontStyle: 'caption_1',
	$fontWeight: 'medium',
	$color: 'gray_800',
} as const;

const iconBgColor = {
	PAYMENT: '#3ADA84',
	PHOTO: '#59C4F2',
	FILE: '#FFBA53',
	TEMPLATE: '#9E8EFF',
} as const;

export const MessageInputActions = () => {
	const { stores } = useSBStateContext();
	const { nickname } = stores.userStore.user;
	const { sendUserMessage, mutateMessage, sendFileMessage, channelUrl, member } = useChannelContext();
	const memberUserId = getMemberId(member.userId);

	const { handleSendFiles } = useHandleSendFiles({ sendFileMessage });
	// const { openModal } = useModalContext();
	const { handleOpen, handleClose } = context.modal.useDispatch();
	const { mutate } = usePostPayment();

	const onPaymentMessage = useCallback(
		(paymentRequestParams: PaymentRequest) => {
			handleOpen(
				<ConfirmModal
					confirm={async () => {
						await mutateMessage(mutate, paymentRequestParams);
						handleClose();
					}}
					message={'결제 요청을 보내시겠습니까?'}
				/>,
			);
		},
		[mutateMessage, mutate],
	);

	return (
		<ActionWrapper>
			<ActionItem>
				<IconButtonWrap
					onClick={() => {
						handleOpen(
							<PaymentModal
								channelUrl={channelUrl}
								memberUserId={memberUserId}
								storeName={nickname}
								onSubmit={(params) => {
									onPaymentMessage(params);
								}}
							/>,
						);
					}}
					{...buttonStyle}
					$bgColor={'PAYMENT'}
				>
					<Icon id={IconID.BTN_PAYMENT} {...buttonStyle} />
				</IconButtonWrap>
				<Label {...labelStyle}>{'결제 요청'}</Label>
			</ActionItem>
			<ActionItem>
				<FileUploader
					onChange={async (files) => await handleSendFiles(files)}
					values={[]}
					format={['image']}
					maxCount={10}
					maxCapacity={{ single: { unit: 'MB', size: 10 } }}
				>
					{({ onClickTrigger }) => (
						<>
							<IconButtonWrap onClick={onClickTrigger} {...buttonStyle} $bgColor={'PHOTO'}>
								<Icon id={IconID.BTN_PHOTO} width={'2.8rem'} height={'4.8rem'} />
							</IconButtonWrap>
							<Label {...labelStyle}>{'사진 첨부'}</Label>
						</>
					)}
				</FileUploader>
			</ActionItem>
			<ActionItem>
				<FileUploader
					onChange={async (files) => await handleSendFiles(files)}
					values={[]}
					format={['document', 'application']}
					maxCount={10}
					maxCapacity={{ single: { unit: 'MB', size: 10 } }}
				>
					{({ onClickTrigger }) => (
						<>
							<IconButtonWrap onClick={onClickTrigger} {...buttonStyle} $bgColor={'FILE'}>
								<Icon id={IconID.BTN_FILE} width={'2.8rem'} height={'4.8rem'} />
							</IconButtonWrap>
							<Label {...labelStyle}>{'파일 첨부'}</Label>
						</>
					)}
				</FileUploader>
			</ActionItem>
			<ActionItem>
				<IconButtonWrap
					onClick={() => {
						handleOpen(
							<TemplatesModal
								onSelect={async (template: Template) => {
									await sendUserMessage({ message: template.content });
								}}
							/>,
						);
					}}
					{...buttonStyle}
					$bgColor={'TEMPLATE'}
				>
					<Icon id={IconID.BTN_TEMPLATE} {...buttonStyle} />
				</IconButtonWrap>
				<Label {...labelStyle}>{'자주쓰는 문구'}</Label>
			</ActionItem>
		</ActionWrapper>
	);
};

const ActionWrapper = styled(Flex)`
	padding: 1.6rem 1.6rem 2.4rem;
	align-items: flex-start;
	align-self: stretch;
`;

const ActionItem = styled(Flex)`
	flex-direction: column;
	align-items: center;
	gap: 1.2rem;
	flex: 1 0 0;
`;

const IconButtonWrap = styled(IconButton)<{ $bgColor: keyof typeof iconBgColor }>`
	border-radius: 99px;
	background-color: ${({ $bgColor }) => iconBgColor[$bgColor]};
`;
