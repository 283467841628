import {
	type AnnouncementRequest,
	type PaymentRequest,
	type PrescriptionRequest,
	type PushPreferenceRequest,
} from './types';
import { Get, Post, Put } from '../index';
import { type Usage } from '../types';

export const chat = {
	putPrescription: async <T>({ groupId, ...params }: PrescriptionRequest): Promise<T> => {
		const response = await Put<T>(`/chats/prescriptions/${groupId}`, params);
		return response.data;
	},
	putPromotion: async <T>({ groupId, ...params }: PrescriptionRequest): Promise<T> => {
		const response = await Put<T>(`/chats/promotions/${groupId}`, params);
		return response.data;
	},
	putPharmSales: async <T>({ groupId, ...params }: PrescriptionRequest): Promise<T> => {
		const response = await Put<T>(`/chats/pharm-sales/${groupId}`, params);
		return response.data;
	},
	postPayment: async <T>(params: PaymentRequest): Promise<T> => {
		const response = await Post<T>(`/chats/payments`, params);
		return response.data;
	},
	putPushPreference: async <T>({ channelUrl, pushTriggerOption }: PushPreferenceRequest): Promise<T> => {
		const response = await Put<T>(`/chats/channels/${channelUrl}/push-preference`, { pushTriggerOption });
		return response.data;
	},
	postAnnouncement: async <T>(params: AnnouncementRequest): Promise<T> => {
		const response = await Post<T>(`/chats/announcements`, params);
		return response.data;
	},
	getAnnouncementsUsage: async <T = Usage>(): Promise<T> => {
		const res = await Get<T>('/chats/announcements/usage');
		return res.data;
	},
};
