import React, { useEffect, useRef, useState } from 'react';

import { type AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { scrollStyle } from '../../assets/styles/scrollStyle';
import { Button, IconButton } from '../../components/Buttons';
import { Icon, IconID, InfoText } from '../../components/Display';
import { Checkbox, TextField } from '../../components/Forms';
import { useSaveLoginId } from '../../feature/Login/hooks/useSaveLoginId';
import FormError from '../../feature/Login/utils/FormError';
import { useValues } from '../../hooks/useValues';
import ScrollFix from '../../lib/styledComponents/ScrollFix';
import { useLogin } from '../../services/users/queries';
import { type LoginRequest } from '../../services/users/types';
import { setToken } from '../../utils/handleToken';

interface Values extends LoginRequest {
	isSaveLoginId: boolean;
}

interface InputRefs {
	loginIdRef: React.MutableRefObject<HTMLInputElement | null>;
	passwordRef: React.MutableRefObject<HTMLInputElement | null>;
}

const Login = () => {
	const navigate = useNavigate();
	const { saveLoginId, removeLoginIdStorage, setLoginIdStorage } = useSaveLoginId();
	const inputRefs: InputRefs = {
		loginIdRef: useRef(null),
		passwordRef: useRef(null),
	};

	const initValues: Values = {
		accountType: 'PHARM_USER',
		loginId: '',
		password: '',
		isSaveLoginId: false,
	};
	const { values, onChangeValues, dispatch } = useValues(initValues);
	// error message
	const [error, setError] = useState('');

	// storage에 저장된 id가 있을경우 해당 id로 변경 및 체크박스 체크
	useEffect(() => {
		if (!saveLoginId) return;
		dispatch('SET', {
			loginId: saveLoginId.loginId,
			isSaveLoginId: true,
		});
	}, [saveLoginId]);

	const { mutate } = useLogin({
		onSuccess: (res) => {
			if (values.isSaveLoginId) {
				setLoginIdStorage(values.loginId);
			} else {
				removeLoginIdStorage();
			}

			const { accessToken, refreshToken } = res;
			setToken(accessToken, refreshToken);

			navigate('/crm/chat', { replace: true });
		},
		onError: (err: AxiosError) => {
			const { status } = err.response ?? {};
			switch (status) {
				case 400:
				case 404:
					setError('아이디(면허번호) 또는 비밀번호가 일치하지 않습니다. 입력하신 내용을 다시 확인해주세요.');
					break;
				default:
					toast.error(`로그인에 실패했습니다.\n${err.message}`);
					break;
			}
		},
	});

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const { isSaveLoginId, ...requestValues } = values;
			const { loginId, password } = requestValues;

			if (!loginId) throw new FormError('아이디(면허번호)를 입력해주세요.', 'loginId');
			if (!password) throw new FormError('비밀번호를 입력해주세요.', 'password');

			mutate(requestValues as LoginRequest);
		} catch (err: unknown) {
			if (err instanceof FormError) {
				switch (err.type) {
					case 'loginId':
						inputRefs.loginIdRef.current?.focus();
						break;
					case 'password':
						inputRefs.passwordRef.current?.focus();
						break;
				}
				setError(err.message);
			}
		}
	};

	return (
		<Container>
			{/* html{ overflow: hidden} style code */}
			<ScrollFix />
			<section className="section">
				<article className="section-form">
					<img src="/images/logo/logo-dark-pharm.svg" alt="logo" />
					<form onSubmit={onSubmit}>
						<div className="section-form-values">
							<div className="section-form-inputs">
								<TextField
									name="loginId"
									value={values.loginId}
									maxLength={20}
									onChange={onChangeValues}
									placeholder="아이디(면허번호)"
									ref={inputRefs.loginIdRef}
									style={{ height: '5.2rem', borderRadius: '8px' }}
									renderPrefix={
										<IconWrapper>
											<Icon id={IconID.USER_LOGIN} defaultColor={'gray_600'} width={'1.6rem'} />
										</IconWrapper>
									}
									renderSuffix={
										values.loginId && (
											<IconButton
												width="2rem"
												height="2rem"
												onClick={() => {
													dispatch('DELETE', 'loginId');
												}}
											>
												<Icon id={IconID.CIRCLE_DELETE} defaultColor={'gray_500'} width="2rem" height="2rem" />
											</IconButton>
										)
									}
								/>

								<TextField
									type="password"
									name="password"
									value={values.password}
									onChange={onChangeValues}
									maxLength={20}
									placeholder="비밀번호"
									ref={inputRefs.passwordRef}
									style={{ height: '5.2rem', borderRadius: '8px' }}
									renderPrefix={
										<IconWrapper>
											<Icon id={IconID.LOCK} defaultColor={'gray_600'} width={'1.6rem'} />
										</IconWrapper>
									}
									renderSuffix={
										values.password && (
											<IconButton
												width="2rem"
												height="2rem"
												onClick={() => {
													dispatch('DELETE', 'password');
												}}
											>
												<Icon id={IconID.CIRCLE_DELETE} defaultColor={'gray_500'} width="2rem" height="2rem" />
											</IconButton>
										)
									}
								/>
							</div>
							<div className="section-form-checkboxs">
								<Checkbox
									name="isSaveLoginId"
									label="아이디 저장"
									onChange={(e) => {
										onChangeValues(e, 'CHECKBOX');
									}}
									checked={values.isSaveLoginId}
									type="ROUNDED"
								/>
							</div>
							{error && <InfoText text={error} type={'error'} />}
						</div>
						<Button type="submit" size={{ $fontSize: 'XL', $heightSize: 'XL' }} shouldPrevent={true}>
							{'로그인'}
						</Button>
					</form>
				</article>
				<article className="section-support">
					<p className="section-support-number">고객지원 1600-3122</p>
					<p className="section-support-copyright">COPYRIGHT ⓒ 2023 GOODPHARM. ALL RIGHTS RESERVED.</p>
				</article>
			</section>
		</Container>
	);
};

const Container = styled.main`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	padding: 4rem;
	background: linear-gradient(-90deg, #13beb7, #0299a0);
	${scrollStyle({ hideHorizontal: true })};

	.section {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 44rem;
		gap: 2.8rem;

		&-form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 4rem;
			padding: 6.4rem 3.2rem;
			background-color: ${({ theme }) => theme.colors.white};
			border-radius: 12px;

			form {
				display: flex;
				flex-direction: column;
				gap: 3.6rem;
			}

			&-values {
				display: flex;
				flex-direction: column;
				gap: 1.6rem;
			}

			&-inputs {
				display: flex;
				flex-direction: column;
				gap: 1.2rem;
			}

			&-checkboxs {
				display: flex;
				gap: 0.6rem;
			}

			img {
				height: 4rem;
			}
		}

		&-support {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			align-items: center;
			text-align: center;
			color: ${({ theme }) => theme.colors.white};

			&-number {
				${({ theme }) => theme.font.title.title_1};
				font-weight: 700;
			}

			&-copyright {
				${({ theme }) => theme.font.caption.caption_2};
			}
		}
	}
`;

const IconWrapper = styled.span`
	display: flex;
	width: 2.4rem;
	height: 2.4rem;
	justify-content: center;
	align-items: center;
	padding-top: 0.3rem;
`;
export default Login;
