import React, { type InputHTMLAttributes, type CSSProperties } from 'react';

import styled, { css } from 'styled-components';

import { type DefaultOption } from '../../../utils/consts';
import { Icon } from '../../Display';
import { type FlexDirection } from '../types';

export interface RadioProps<T> extends InputHTMLAttributes<HTMLInputElement>, StyledProps {
	name: string;
	options: Array<DefaultOption<T>>;
	selectValue?: any;
	style?: CSSProperties;
	labelStyle?: CSSProperties;
	renderPrefix?: (key: T) => React.ReactNode;
	customKey?: string;
}

interface StyledProps {
	$direction?: FlexDirection;
	$disabled?: boolean;
}

export const Radio = <T,>(props: RadioProps<T>) => {
	const {
		$direction = 'ROW',
		$disabled = false,
		options,
		name,
		selectValue,
		style = {},
		labelStyle,
		customKey,
		renderPrefix,
		...rest
	} = props;

	return (
		<RadioWrapper {...{ style, $disabled, $direction }}>
			{options.map((option) => {
				const isChecked = option.key === selectValue;
				return (
					<li key={'input-radio-' + String(option.key)} style={{ ...labelStyle }}>
						<label htmlFor={'radio--' + String(option.key) + customKey ?? ''}>
							<Icon
								id={isChecked ? 'btn-radio-on' : 'btn-radio-off'}
								defaultColor="gray_400"
								activeColor="primary_600"
								isDisabled={rest.disabled ?? $disabled}
								isActive={isChecked}
								width="2rem"
								height="2rem"
							/>
							{option.label}
						</label>
						{renderPrefix && renderPrefix(option.key!)}
						<input
							id={'radio--' + String(option.key) + customKey ?? ''}
							type="radio"
							name={name}
							value={String(option.key)}
							checked={isChecked}
							{...rest}
							disabled={rest.disabled ?? $disabled}
						/>
					</li>
				);
			})}
		</RadioWrapper>
	);
};

const RadioWrapper = styled.ul<StyledProps>`
	display: flex;
	${({ $direction }) =>
		$direction === 'COLUMN'
			? css`
					flex-direction: column;
					align-items: flex-start;
				`
			: css`
					flex-direction: row;
					gap: 4.8rem;
				`}
	> li {
		display: inline-flex;
		align-items: center;
		height: 3.6rem;
		gap: 0.8rem;
		cursor: pointer;

		> input[type='radio'] {
			display: none;
		}
		> label {
			display: inline-flex;
			align-items: center;
			gap: 0.8rem;
			cursor: pointer;
			${({ theme }) => theme.font.label.label_2};
		}
	}

	${({ $disabled }) =>
		$disabled &&
		css`
			pointer-events: none;
		`}
`;
