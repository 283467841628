import React, { useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import { downloadFile, isAnnouncementMessage } from './utils';
import { IconButton } from '../../../../../components/Buttons';
import { Flex } from '../../../../../components/Common';
import { Icon, IconID, Label } from '../../../../../components/Display';
import { ContextMenu, MenuItem, MenuItems } from '../../../../../components/Modals/ContextMenu';
import { formatBirth, formatFileSize, truncateString } from '../../../../../utils/format';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getFileData, getFileMessageData } from '../../../getMessageData';
import { type CoreMessageType } from '../../../utils';

interface FileMessageBodyProps {
	isByMe: boolean;
	message: CoreMessageType;
}

export const FileMessageBody = ({ isByMe, message }: FileMessageBodyProps) => {
	const messageData = getFileMessageData(message.data);
	const [menuOpen, setMenuOpen] = useState(false);
	const { deleteUserMessage, deleteFileMessage } = useChannelContext();
	const messageRef = useRef<HTMLDivElement>(null);
	const showMenuItemDelete = useMemo(() => isByMe && !isAnnouncementMessage(message), [message, isByMe]);

	if (messageData === null) {
		return <></>;
	}

	const fileData = getFileData(messageData.files[0]);

	if (fileData === null) {
		return <></>;
	}

	const { fileName, size, expiredAt } = fileData;

	return (
		<FileMessageItem
			ref={messageRef}
			onContextMenu={(e) => {
				if (message.sendingStatus === 'succeeded') {
					e.preventDefault();
					setMenuOpen(true);
				}
			}}
		>
			<Wrapper>
				<IconButtonWrapper $isByMe={isByMe}>
					<StyledIconButton
						onClick={() => {
							downloadFile(fileData);
						}}
					>
						{isByMe ? (
							<Icon id={IconID.MSG_FILE} width={'1.6rem'} height={'1.8rem'} />
						) : (
							<Icon id={IconID.MSG_DOWNLOAD} width={'1.7rem'} height={'1.8rem'} />
						)}
					</StyledIconButton>
				</IconButtonWrapper>
				<ContentWrapper>
					<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
						{truncateString(fileName, 20)}
					</Label>
					<SubContentWrapper>
						<Label $fontStyle={'caption_1'} $color={'gray_700'}>
							{'유효기간 ' + formatBirth(expiredAt, 'dot')}
						</Label>
						<Label $fontStyle={'caption_1'} $color={'gray_700'}>
							{'용량 ' + formatFileSize(size)}
						</Label>
					</SubContentWrapper>
				</ContentWrapper>
			</Wrapper>
			<ContextMenu
				isOpen={menuOpen}
				menuItems={() => (
					<MenuItems
						parentRef={messageRef}
						closeDropdown={() => {
							setMenuOpen(false);
						}}
						isOnCursor={true}
					>
						{showMenuItemDelete && (
							<MenuItem
								onClick={() => {
									if (message.isFileMessage()) {
										deleteFileMessage(message.messageId);
									} else {
										deleteUserMessage(message.messageId);
									}
									setMenuOpen(false);
								}}
							>
								<>
									{'삭제'}
									<Icon id={IconID.BTN_DELETE} width={'2rem'} height={'2rem'} />
								</>
							</MenuItem>
						)}
					</MenuItems>
				)}
			/>
		</FileMessageItem>
	);
};

const FileMessageItem = styled(Flex)`
	width: 26.4rem;
	max-width: 26.4rem;
	padding: 1.6rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 12px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background-color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled(Flex)`
	align-items: center;
	gap: 1.2rem;
	align-self: stretch;
`;

const IconButtonWrapper = styled(Flex)<{ $isByMe: boolean }>`
	width: 4.4rem;
	height: 4.4rem;
	justify-content: center;
	align-items: center;
	border-radius: 999px;
	background-color: ${({ $isByMe, theme }) =>
		$isByMe ? theme.colors.primary.primary_100 : theme.colors.gray.gray_100};
`;

const StyledIconButton = styled(IconButton)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContentWrapper = styled(Flex)`
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.6rem;
	flex: 1 0 0;
`;

const SubContentWrapper = styled(Flex)`
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
`;
