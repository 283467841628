import { useRef } from 'react';

import { type GroupChannel, type SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import {
	MessageSearchOrder,
	type MessageSearchQuery,
	type MessageSearchQueryParams,
	type UserMessage,
} from '@sendbird/chat/message';

import { useAsyncLayoutEffect } from '../../../hooks/useAsyncEffect';
import { usePreservedCallback } from '../../../hooks/usePreservedCallback';
import { useMessageSearchReducer } from '../reducer';

interface UseGetSearchMessages {
	sdk: SendbirdGroupChat;
	currentChannel: GroupChannel | null;
	channelUrl: string;
	requestString?: string;
}

/**
 *
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/message/searching-messages-in-a-group-channel/search-messages-by-a-keyword}
 */
export function useGetSearchMessages({ sdk, currentChannel, channelUrl, requestString }: UseGetSearchMessages) {
	const {
		updateInitialized,
		updateLoading,
		updateMessages,

		initialized,
		loading,
		messages,
	} = useMessageSearchReducer();

	const queryRef = useRef<MessageSearchQuery>();

	const init = usePreservedCallback(async (requestString?: string) => {
		if (!requestString?.trim() || !currentChannel) {
			updateMessages([], true);
			return;
		}

		const channel = await currentChannel.refresh();
		const inputSearchMessageQueryObject: MessageSearchQueryParams = {
			order: MessageSearchOrder.TIMESTAMP,
			channelUrl,
			messageTimestampFrom: channel.invitedAt,
			keyword: requestString,
		};

		queryRef.current = sdk.createMessageSearchQuery(inputSearchMessageQueryObject);
		if (queryRef.current?.hasNext) {
			const messages = await queryRef.current?.next();
			updateMessages(messages as UserMessage[], true);
		}
	});

	useAsyncLayoutEffect(async () => {
		updateInitialized(false);
		updateLoading(true);
		if (sdk && currentChannel && channelUrl) {
			await init(requestString);
			updateLoading(false);
			updateInitialized(true);
		}
	}, [sdk, currentChannel, channelUrl, requestString]);

	const loadNext = usePreservedCallback(async () => {
		if (queryRef.current?.hasNext) {
			const messages = await queryRef.current?.next();
			updateMessages(messages as UserMessage[], false);
		}
	});

	return {
		initialized,
		loading,
		messages,

		loadNext,
		hasNext: queryRef.current?.hasNext ?? false,
	};
}
