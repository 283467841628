import React, { useMemo, useState } from 'react';

import { CustomPieLegend } from './CustomPieLegend';
import { ComposedChart, type ComposedChartProps, PieChart } from '../../../components/Charts';
import { InsideTitle } from '../../../components/Common';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { type UnitQueryResultType, useGetPeriodDetails } from '../../../services/statistics/queries';
import { type StatsDetailWeekday } from '../../../services/statistics/types';
import { commaizeNumber, fractionNumber } from '../../../utils/format';
import { PeriodChart } from '../components/PeriodChart';
import { PeriodPicker } from '../components/PeriodPicker';
import { StatsStyledTable, PeriodDetails } from '../components/StatsTable';
import { Container, Content, ItemsWrapper, Wrapper } from '../components/styles';
import { formatPeriod } from '../utils';

import type { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';

type DateRange = [Date | null, Date | null];

function getMinMaxBarColors(data?: StatsDetailWeekday[]) {
	if (!data) return [];

	const { min, max } = data.reduce(
		(acc, item) => {
			if (item.averageSalePrice > acc.max) acc.max = item.averageSalePrice;
			if (item.averageSalePrice < acc.min) acc.min = item.averageSalePrice;
			return acc;
		},
		{ min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY },
	);
	return data.map((item, index) => {
		if (item.averageSalePrice === max) return '#0E9098';
		if (item.averageSalePrice === min) return '#CED4DA';
		return '#5AD7D2';
	});
}

function Multilateral() {
	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;
	const [dateRange, setDateRange] = useState<DateRange>([null, null]);
	const period = useMemo(() => formatPeriod(dateRange), [dateRange]);

	const { data } = useGetPeriodDetails<'weekday', Array<UnitQueryResultType<'weekday'>>>({
		storeId,
		period: [period],
		unit: 'weekday',
	});

	const averageSales = useMemo(
		() => (data[0] ? Math.floor(data[0].reduce((acc, cur) => acc + cur.sales, 0) / data[0].length) : 0),
		[data[0]],
	);

	const chartProps: ComposedChartProps = useMemo(
		() => ({
			wrapperStyle: { minWidth: '47rem' },
			data: data[0],
			width: 470,
			height: 320,
			margin: { top: 90, right: 87, bottom: 50, left: 72 },
			showGrid: false,
			barProps: [{ dataKey: 'averageSalePrice', barSize: 12 }],
			barColors: getMinMaxBarColors(data[0]),
			legendProps: {
				wrapperStyle: {
					top: 20,
					right: 16,
					width: 'fit-content',
				},
				payload: [
					{ type: 'square', color: '#0E9098', value: '최고' },
					{ type: 'square', color: '#CED4DA', value: '최저' },
				],
				innerStyle: { gap: '2rem' },
			},
			xAxisProps: [
				{
					dataKey: 'dayOfWeek',
					padding: { left: 16, right: 16 },
					tickFormatter: (value) => `${value.slice(0, 1)}`,
				},
			],
		}),
		[data[0]],
	);

	return (
		<Container>
			<Wrapper>
				<Content $height={'54rem'}>
					<InsideTitle
						title={'요일별 평균 분석'}
						rightRender={
							<PeriodPicker
								initPeriod={dateRange}
								offset={12}
								label={'다각도 분석'}
								onChangePeriod={(updated) => {
									setDateRange(updated);
								}}
								unit={'daily'}
							/>
						}
						style={{ minHeight: '6.4rem' }}
					/>
					<PeriodChart storeId={storeId} {...period} unit={'weekday'} style={{ padding: '0 18rem' }} />
				</Content>
				<Content>
					<ItemsWrapper $gap={'2rem'}>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ minWidth: '64.5rem' }}>
							<InsideTitle title={'평균 판매 매출 기여도'} />
							<ItemsWrapper $gap={'2rem'}>
								<PieChart<StatsDetailWeekday>
									wrapperStyle={{ minWidth: '32rem', width: 'calc(100% - 33rem)' }}
									width={320}
									height={320}
									margin={{
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
									}}
									data={data[0]}
									dataKey={'sales'}
									pieProps={{
										nameKey: 'dayOfWeek',
									}}
									legendProps={{
										wrapperStyle: {
											top: 0,
											right: -330,
											maxWidth: '31rem',
										},
										content: (props: LegendProps) => <CustomPieLegend {...props} />,
									}}
									centerLabel={{
										name: '주 평균',
										value: commaizeNumber(averageSales) + '원',
									}}
								/>
							</ItemsWrapper>
						</ItemsWrapper>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ minWidth: '64.5rem' }}>
							<InsideTitle title={'평균 객단가'} />
							<ItemsWrapper $gap={'2rem'}>
								<ComposedChart {...chartProps} />
								<StatsStyledTable style={{ maxWidth: '16rem' }}>
									<ListTable
										data={data[0]}
										columns={[
											{
												key: 'dayOfWeek',
												headerName: '요일',
												width: { max: '8rem' },
												style: { justifyContent: 'center' },
											},
											{
												key: 'averageSalePrice',
												headerName: '객단가',
												width: { min: '8rem' },
												style: { justifyContent: 'right' },
												renderComponent: (value) => commaizeNumber(fractionNumber(value, 0)) + '원',
											},
										]}
										isScroll={false}
									/>
								</StatsStyledTable>
							</ItemsWrapper>
						</ItemsWrapper>
					</ItemsWrapper>
				</Content>
				<PeriodDetails storeId={storeId} {...period} unit={'weekday'} isAverage isScroll={false} />
			</Wrapper>
		</Container>
	);
}

export default Multilateral;
