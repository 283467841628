import React, { useMemo } from 'react';

import { format } from 'date-fns/format';
import { getMonth } from 'date-fns/getMonth';
import { subMonths } from 'date-fns/subMonths';

import { ComposedChart, type ComposedChartProps } from '../../../components/Charts';

import type { StatsDetailDaily } from '../../../services/statistics/types';

const MILLION = 10000;
interface GrossProfitLineProps {
	details: StatsDetailDaily[][];
	isSuccess: boolean;
	today: Date;
}

export function GrossProfitLine({ details, isSuccess, today }: GrossProfitLineProps) {
	const chartData = useMemo(() => {
		if (isSuccess) {
			const [cur, prev] = [[...details[0]], [...details[1]]];
			const standard = cur.length > prev.length ? cur : prev;

			return standard.reduce<Array<{ day: string; prev?: number; cur?: number }>>((acc, { day, ...rest }, idx) => {
				const added = {
					day: format(day, 'd'),
					prev: Math.floor(prev[idx]?.grossProfit / MILLION) ?? undefined,
					cur: Math.floor(cur[idx]?.grossProfit / MILLION) ?? undefined,
				};
				return [...acc, added];
			}, []);
		}
		return [];
	}, [isSuccess, details]);

	const chartProps: ComposedChartProps = useMemo(
		() => ({
			wrapperStyle: { minWidth: '65rem' },
			width: 650,
			height: 360,
			margin: { top: 104, right: 102, bottom: 50, left: 102 },
			legendProps: {
				wrapperStyle: { top: 20, right: 20, width: 'fit-content' },
				innerStyle: { gap: '2rem' },
			},
			xAxisProps: [
				{
					dataKey: 'day',
					padding: { left: 40, right: 40 },
					interval: 5,
				},
			],
			yAxisProps: [
				{
					label: {
						value: '매출 총이익(단위:만원)',
						offset: 30,
						position: 'top',
						className: 'yAxis-label-small',
					},
				},
			],
			lineProps: [
				{
					dataKey: 'prev',
					name: `${getMonth(subMonths(today, 1)) + 1}월`,
					stroke: '#5AD7D2',
					legendType: 'square',
					strokeWidth: 2.5,
				},
				{
					dataKey: 'cur',
					name: `${getMonth(today) + 1}월`,
					stroke: '#587DFF',
					legendType: 'square',
					strokeWidth: 2.5,
				},
			],
		}),
		[today],
	);

	return <ComposedChart data={chartData} {...chartProps} />;
}
