import { type DefaultTheme } from 'styled-components';

const colors = {
	white: '#fff',
	black: '#212529',
	red: {
		red_200: '#FFF5F5',
		red_300: '#FFCAC7',
		red_400: '#FC8E8B',
		red_500: '#F75B5B',
		red_600: '#F74444',
		red_700: '#E43030',
	},
	green: {
		green_200: '#E1FCEC',
		green_300: '#C1F7D7',
		green_400: '#7EE7AE',
		green_500: '#43DD8A',
		green_600: '#1CC880',
		green_700: '#13AF6E',
	},
	blue: {
		blue_200: '#F2F9FF',
		blue_300: '#CCDDFF',
		blue_400: '#99B8FF',
		blue_500: '#638DFF',
		blue_600: '#567AFA',
		blue_700: '#3862D6',
	},
	yellow: {
		yellow_200: '#FFF9E7',
		yellow_300: '#FFF1C8',
		yellow_400: '#FFD968',
		yellow_500: '#FFC518',
		yellow_600: '#FF9900',
		yellow_700: '#F57600',
	},
	gray: {
		gray_100: '#F7F9FA',
		gray_200: '#F1F3F5',
		gray_300: '#E9ECEF',
		gray_400: '#DDE1E6',
		gray_500: '#CED4DA',
		gray_600: '#ADB5BD',
		gray_700: '#868E96',
		gray_800: '#5E646C',
		gray_900: '#343A40',
	},
	primary: {
		primary_100: '#F2FCFC',
		primary_200: '#E7FBFA',
		primary_300: '#C2F2EF',
		primary_400: '#78E1DC',
		primary_500: '#49D4CE',
		primary_600: '#23C5BE',
		primary_700: '#0AB4AD',
		primary_800: '#16A59F',
		primary_900: '#08837E',
	},
} as const;

const font = {
	headline: {
		headline_1: 'font-size:3.6rem; line-height:4.8rem; letter-spacing: -.5px;',
		headline_2: 'font-size:3.2rem; line-height:4.4rem; letter-spacing: -.5px;',
		headline_3: 'font-size:2.8rem; line-height:4.0rem; letter-spacing: -.5px;',
		headline_4: 'font-size:2.4rem; line-height:3.6rem; letter-spacing: -.5px;',
	},
	title: {
		title_1: 'font-size:2.0rem; line-height:3.2rem; letter-spacing: -.3px;',
		title_2: 'font-size:1.8rem; line-height:2.8rem; letter-spacing: 0px;',
		title_3: 'font-size:1.6rem; line-height:2.4rem; letter-spacing: 0px;',
	},
	body: {
		body_1: 'font-size:1.5rem; line-height:2.2rem; letter-spacing: 0px;',
		body_2: 'font-size:1.4rem; line-height:2.0rem; letter-spacing: 0px;',
		body_3: 'font-size:1.3rem; line-height:1.8rem; letter-spacing: 0px;',
	},
	caption: {
		caption_1: 'font-size:1.2rem; line-height:1.6rem; letter-spacing: .3px;',
		caption_2: 'font-size:1.1rem; line-height:1.4rem; letter-spacing: .3px;',
	},
	label: {
		label_1: 'font-size:1.6rem; line-height:1.6rem; letter-spacing: 0px;',
		label_2: 'font-size:1.4rem; line-height:1.4rem; letter-spacing: 0px;',
	},
	weight: {
		bold: `font-weight: 700;`,
		medium: `font-weight: 500;`,
		regular: `font-weight: 400;`,
	},
} as const;

const shadow = {
	modal: '0px 4px 16px rgba(31, 33, 42, 0.06)',
	tooltip: '0px 2px 12px 0px rgba(0, 0, 0, 0.25)',
};

const breakPoint = {
	small: 'max-width: 640px',
	medium: 'max-width: 768px',
	large: 'max-width: 1024px',
	xLarge: 'max-width: 1280px',
};

const Theme: DefaultTheme = {
	colors,
	font,
	breakPoint,
	shadow,
};

type ColorsType = typeof colors;
type FontType = typeof font;
type BreakPointType = typeof breakPoint;
type ShadowType = typeof shadow;

export type { ColorsType, FontType, BreakPointType, ShadowType };

export default Theme;
