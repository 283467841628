import React from 'react';

import styled from 'styled-components';

import { toggleButtonLabels } from './consts';
import { Button } from '../../../../components/Buttons';
import { Icon, IconID } from '../../../../components/Display';

export type ToggleKey = 'isLateNight' | 'isYearRound' | 'isAnimalMeds' | 'isParking' | 'isPetFriendly' | 'isWheelchair';
interface ToggleButtonProps {
	name: ToggleKey;
	isChecked: boolean;
	onChange: (name: ToggleKey, value: boolean) => void;
}
export const ToggleButton = ({ name, isChecked, onChange }: ToggleButtonProps) => {
	return (
		<StyledButton
			onClick={() => {
				onChange(name, !isChecked);
			}}
			buttonType={'LINE'}
			size={{ $fontSize: 'M', $paddingSize: 'M' }}
			shouldPrevent={false}
			$isChecked={isChecked}
		>
			<Icon
				id={IconID.MARK_CHECKED}
				defaultColor={'gray_600'}
				activeColor={'primary_600'}
				isActive={isChecked}
				width={'2rem'}
				height={'2rem'}
			/>
			{toggleButtonLabels[name]}
		</StyledButton>
	);
};

const StyledButton = styled(Button)<{ $isChecked: boolean }>`
	color: ${({ theme, $isChecked }) => ($isChecked ? theme.colors.primary.primary_600 : theme.colors.gray.gray_600)};
	border-color: ${({ theme, $isChecked }) =>
		$isChecked ? theme.colors.primary.primary_400 : theme.colors.gray.gray_300};
	background-color: ${({ theme, $isChecked }) => ($isChecked ? theme.colors.primary.primary_100 : theme.colors.white)};
	gap: 0.8rem;
	width: 100%;
	justify-content: flex-start;
`;
