import { isValidCheck } from './isValidCheck';
import { type Age, type Search, type Sorts } from '../services/types';

// 세자리 단위씩 콤마
function commaizeNumber(value: string | number) {
	const numStr = String(value);
	const decimalPointIndex = numStr.indexOf('.');
	const commaizeRegExp = /(\d)(?=(\d\d\d)+(?!\d))/g;

	return decimalPointIndex > -1
		? numStr.slice(0, decimalPointIndex).replace(commaizeRegExp, '$1,') + numStr.slice(decimalPointIndex)
		: numStr.replace(commaizeRegExp, '$1,');
}

// 콤마 제거
function decommaizeNumber(numStr: string) {
	return Number(numStr.replace(/,/g, ''));
}

// number toFixed return number
function fractionNumber(value: string | number, digit: number) {
	const num = typeof value === 'string' ? parseFloat(value) : value;
	return Number(num.toFixed(digit));
}

// 숫자 (금액) 앞부분 0 제거
function removeLeadingZero(numStr: string) {
	return numStr.length > 1 ? numStr.replace(/^0+/, '') : numStr;
}

function truncateString(fullStr: string, strLen: number) {
	if (fullStr === null || fullStr === undefined) return '';
	fullStr = fullStr.normalize('NFC');
	if (fullStr.length <= strLen) return fullStr;
	const separator = '...';
	const sepLen = separator.length;
	const charsToShow = strLen - sepLen;
	const frontChars = Math.ceil(charsToShow / 2);
	const backChars = Math.floor(charsToShow / 2);
	return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
}

function formatPhoneNumber(number: string): string {
	if (!number) return '';
	// 0~9를 제외한 모든 문자를 제거
	const resetNumber = number.replace(/\D/g, '');
	// 8 = 1234-5678
	// 9 = 12-345-6789
	// 10 = 12-3456-7890
	// 11 = 123-4567-8901
	const regEx8 = /^(\d{4})(\d{4})$/;
	const regEx9 = /^(\d{2})(\d{3})(\d{4})$/;
	const regExElse = /^(\d{2,3})(\d{4})(\d{4})$/;

	switch (resetNumber.length) {
		case 8:
			return resetNumber.replace(regEx8, `$1-$2`);
		case 9:
			return resetNumber.replace(regEx9, `$1-$2-$3`);
		default:
			return resetNumber.replace(regExElse, `$1-$2-$3`);
	}
}

/**
 * 객체를 queryString으로 변경
 * @param obj
 * @returns
 */
const formatQueryString = <T extends Record<string, any>>(obj: T): string => {
	const keys = Object.keys(obj) as [string | any];

	const validatedKeys = keys.filter((key) => isValidCheck(key, obj[key]));

	if (!validatedKeys.length) return '';

	const result = validatedKeys.map((key) => {
		switch (key) {
			case 'sorts': {
				if (Array.isArray(obj[key])) {
					const filterSorts = (obj[key] as Array<Sorts<string>>).filter((sort) => !!sort.direction);
					const arraySort = filterSorts.map((sort) => `${key}=${String(sort.property)};${sort.direction}`);
					return arraySort.join('&');
				} else {
					const sortObj = obj[key] as Sorts<string>;
					return sortObj.property && sortObj.direction
						? `${key}=${String(sortObj.property)};${sortObj.direction}`
						: null;
				}
			}
			case 'search': {
				const { searchCategory, searchKeyword } = obj[key] as Search<string>;
				let mutableSearchKeyword = searchKeyword;
				if (searchCategory === 'PHONE' && !!searchKeyword) {
					mutableSearchKeyword = searchKeyword.replace(/\D/g, '');
				}
				return mutableSearchKeyword ? `searchCategory=${searchCategory}&searchKeyword=${mutableSearchKeyword}` : null;
			}
			case 'ages': {
				const formatOptions = (obj[key] as Age[]).map((el) => formatAgesKey(el));
				return formatOptions.join('&');
			}
			case 'period': {
				const { start, end } = obj[key];
				const resultArray: string[] = [];
				start && resultArray.push(`startDate=${formatDate(start)}`);
				end && resultArray.push(`endDate=${formatDate(end)}`);

				return resultArray.length > 1 ? resultArray.join('&') : resultArray[0];
			}
			case 'startDate':
			case 'endDate':
				return `${key}=${formatDate(obj[key])}`;
			default:
				if (Array.isArray(obj[key])) {
					const arrayObj = obj[key].map((el: any) => `${key}=${el}`);
					return arrayObj.join('&');
				} else {
					return `${key}=${obj[key]}`;
				}
		}
	});

	return `?${result.join('&')}`;
};

/**
 * path를 array형태로 반환
 * @param pathname
 * @returns
 */
const formatPathArray = (pathname: string | undefined): string[] => {
	if (!pathname) return [];
	let newPathname = pathname;
	if (pathname[0] === '/') newPathname = pathname.slice(1);

	return newPathname.split('/').filter((path) => path !== '');
};

/**
 * 날자의 형태 변환 ex)0000.00.00
 * @param {string} birth - 문자열
 * @param type -
 * @returns
 */
const formatBirth = (birth: string, type: 'hyphen' | 'dot' = 'hyphen'): string => {
	if (!birth) return '';
	const typeValue = type === 'dot' ? '.' : '-';
	// 0~9가 아닌 문자를 제외
	let digitsOnly = birth.replace(/\D/g, '');

	// 8자리를 초과하는 숫자를 제외
	digitsOnly = digitsOnly.slice(0, 8);

	return digitsOnly.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1${typeValue}$2${typeValue}$3`);
};

const formatAgesKey = (ageKey: Age): string | undefined => {
	const splitKey = ageKey.split('-');
	if (!splitKey.length) return undefined;
	if (splitKey.length === 2 && splitKey[0] === '70') splitKey[1] = '200';
	return `ages=${splitKey.join(';')}`;
};

/**
 *
 * @param {Date} date
 * @param format - default : "yyyy-MM-dd"
 * - yyyy : 연
 * - MM : 월
 * - dd : 일
 * - D : 요일
 * - hh : 시
 * - mm : 분
 * - ss : 초
 * @return {string}
 */
const formatDate = (date: Date | null, format = 'yyyy-MM-dd'): string => {
	if (!date) return '';
	if (typeof date === 'string') return date;

	const components: Record<string, string> = {
		yyyy: String(date.getFullYear()),
		MM: String(date.getMonth() + 1).padStart(2, '0'),
		dd: String(date.getDate()).padStart(2, '0'),
		D: ['일', '월', '화', '수', '목', '금', '토'][date.getDay()],
		hh: String(date.getHours()).padStart(2, '0'),
		mm: String(date.getMinutes()).padStart(2, '0'),
		ss: String(date.getSeconds()).padStart(2, '0'),
	};

	return format.replace(/yyyy|MM|dd|D|hh|mm|ss/g, (match) => components[match]);
};

export type YNType = 'Y' | 'N';
/**
 * boolean값에 따라 Y or N으로 바꿔주는 함수
 * @param boolean
 * @return {string}
 */
const formatYN = (boolean: boolean): 'Y' | 'N' => {
	if (boolean) {
		return 'Y';
	} else {
		return 'N';
	}
};

export type CapacityUnitType = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';

export const unitToBytes: Record<CapacityUnitType, number> = {
	B: 1,
	KB: 1024,
	MB: 1024 ** 2,
	GB: 1024 ** 3,
	TB: 1024 ** 4,
	PB: 1024 ** 5,
} as const;

export function formatFileSize(bytes: number): string {
	if (bytes === 0) return '0 B';

	const units = Object.keys(unitToBytes);
	const digitGroups = Math.floor(Math.log(bytes) / Math.log(1024));

	return `${(bytes / Math.pow(1024, digitGroups)).toFixed(2)} ${units[digitGroups]}`;
}

const formatDataCapacity = (
	size: number,
	startCapacity: CapacityUnitType,
	endCapacityUnit: CapacityUnitType,
): number => {
	// 시작 용량 단위를 bytes로 변환
	const sizeInBytes = size * unitToBytes[startCapacity];
	// 목표 용량 단위로 변환
	const convertedSize = sizeInBytes / unitToBytes[endCapacityUnit];

	return convertedSize;
};

/**
 * file을 base64형태로 변환
 * @param file
 * @returns
 */
const formatFileToBase64 = async (file: File): Promise<string> => {
	return await new Promise((resolve, reject) => {
		const url = URL.createObjectURL(file);
		resolve(url);
		// const reader = new FileReader();
		// reader.onload = (ev) => {
		// 	resolve(String(ev.target?.result));
		// };
		//
		// reader.onerror = (error) => {
		// 	reject(error);
		// };
		// reader.readAsDataURL(file);
	});
};

const formatTaxNo = (taxNo: string) => {
	// 숫자가 아닌 모든 문자 제거하여 정리된 문자열 생성
	const cleaned = taxNo.replace(/\D/g, '').trim();

	// 정규식을 사용하여 형식에 맞는지 확인하고 포맷팅
	const formatted = cleaned.replace(/^(\d{3})(\d{2})(\d{5})$/, '$1-$2-$3');

	// 포맷팅된 문자열이 원본과 다른 경우(즉, 형식에 맞게 변경된 경우)만 반환
	return formatted !== cleaned ? formatted : taxNo;
};

/**
 * Date 객체를 받으면 해당하는 달의 1일 말일을 Date객체로 리턴하는 함수
 * @param date
 */
const formatMonth = (date: Date) => {
	const start = new Date(date);
	start.setDate(1);
	const end = new Date(date);
	end.setMonth(end.getMonth() + 1);
	end.setDate(0);
	return {
		start,
		end,
	};
};

/**
 *
 * @param date - 기준이 될 Date 객체
 * @param offset - 증감할 day or month
 */
const createDateOffset = (date: Date, offset: { days?: number; months?: number }) => {
	const newDate = new Date(date);
	if (offset.months) newDate.setMonth(newDate.getMonth() + offset.months);
	if (offset.days) newDate.setDate(newDate.getDate() + offset.days);
	return newDate;
};

const EMOJI_REGEX =
	/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E0}-\u{1F1FF}]/gu;

const removeEmojis = (str: string) => {
	return str.replace(EMOJI_REGEX, '');
};

const formatSearch = (string: string, type?: string) => {
	switch (type) {
		case 'PHONE':
			return string.replace(/\D/g, '');
		default:
			return string.trim();
	}
};

export {
	commaizeNumber,
	decommaizeNumber,
	fractionNumber,
	removeLeadingZero,
	truncateString,
	formatPhoneNumber,
	formatQueryString,
	formatPathArray,
	formatBirth,
	formatAgesKey,
	formatDate,
	formatYN,
	formatDataCapacity,
	formatFileToBase64,
	formatTaxNo,
	formatMonth,
	createDateOffset,
	removeEmojis,
	formatSearch,
};
