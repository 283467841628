import { type MessageType } from './types';

const workerCode = () => {
	let intervalId: NodeJS.Timeout;
	self.onmessage = function (e: MessageEvent<MessageType>) {
		switch (e.data.type) {
			case 'START':
				intervalId = setInterval(() => {
					self.postMessage(new Date());
				}, e.data.interval);
				break;
			case 'END':
				clearInterval(intervalId);
				break;
		}
	};
};

let code = workerCode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const workerScript = URL.createObjectURL(blob);

export default workerScript;
