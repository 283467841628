import React, { type CSSProperties } from 'react';

import styled from 'styled-components';

import { IconButton } from '../../../Buttons';
import { Icon, IconID } from '../../../Display';

interface ItemTagProps {
	isOpen?: boolean;
	onClick?: () => void;
	label: string;
	value: string;
	handleReset: () => void;
	isDelete?: boolean;
	style?: CSSProperties;
}
export const FilterListItemTag = ({
	onClick,
	label,
	value,
	handleReset,
	isOpen,
	isDelete = true,
	style,
}: ItemTagProps) => {
	return (
		<Container className="tag" onClick={onClick} $isOpen={!!isOpen} style={style}>
			<div className="tag-inner">
				<span className="tag-inner-label">{label}</span>
				<div className="tag-inner-value">
					<span>{value}</span>
					{!!onClick && (
						<Icon
							className="tag-inner-value-icon"
							id={IconID.CHEVRON}
							width="1.6rem"
							height="1.6rem"
							defaultColor="gray_600"
						/>
					)}
				</div>
			</div>
			{isDelete && (
				<IconButton
					width="1.6rem"
					height="1.6rem"
					onClick={(e) => {
						e.stopPropagation();
						handleReset();
					}}
				>
					<Icon className="tag-close" width="100%" height="100%" id={IconID.BTN_CLOSE} defaultColor="gray_600" />
				</IconButton>
			)}
		</Container>
	);
};

const Container = styled.div<{ $isOpen: boolean }>`
	display: inline-flex;
	cursor: pointer;
	align-items: center;
	padding: 1.2rem;
	gap: 1.2rem;
	height: 3.6rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;
	.tag {
		&-inner {
			display: inline-flex;
			align-items: center;
			gap: 1.2rem;
			&-label {
				${({ theme }) => theme.font.label.label_2};
				font-weight: 500;
				color: ${({ theme }) => theme.colors.gray.gray_600};
			}
			&-value {
				display: inline-flex;
				align-items: center;
				gap: 0.4rem;
				span {
					${({ theme }) => theme.font.label.label_2};
					color: ${({ theme }) => theme.colors.primary.primary_600};
					font-weight: 500;
				}
				&-icon {
					transition: 0.3s transform;
					transform: ${({ $isOpen }) =>
						$isOpen ? 'rotate(180deg) translateY(0.1rem) ' : 'rotate(0deg) translateY(0)'};
				}
			}
		}
		&-close {
		}
	}
`;
