import { useReducer } from 'react';

import { type User } from '@sendbird/chat';

interface State {
	initialized: boolean;
	loading: boolean;
	user: User;
}

type Action =
	| {
			type: 'init_user';
			value: { user: User };
	  }
	| {
			type: 'reset_user';
	  }
	| { type: 'update_user_info'; value: { user: User } };

const initialState: State = {
	initialized: false,
	loading: false,
	user: {} as User,
};

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'init_user':
			return {
				initialized: true,
				loading: false,
				user: action.value.user,
			};
		case 'reset_user':
			return initialState;
		case 'update_user_info':
			return {
				...state,
				user: action.value.user,
			};
	}
};

export const useUserReducer = () => {
	const [userStore, dispatch] = useReducer(reducer, initialState);

	const initUser = (user: User) => {
		dispatch({ type: 'init_user', value: { user } });
	};

	const resetUser = () => {
		dispatch({ type: 'reset_user' });
	};

	const updateUserInfo = (user: User) => {
		dispatch({ type: 'update_user_info', value: { user } });
	};

	return {
		userStore,
		userDispatcher: {
			initUser,
			resetUser,
			updateUserInfo,
		},
	};
};
