import React from 'react';

import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';

import { MessageHeader, type MessageHeaderProps } from './MessageHeader';
import { buttonStyle, Container, Wrapper } from './styles';
import { Button } from '../../../../../components/Buttons';
import { Flex, VStack } from '../../../../../components/Common';
import { Icon, IconID, InfoText } from '../../../../../components/Display';
import { ViewerModal } from '../../../../../components/Modals/ViewerModal';
import context from '../../../../../context';
import { prescriptionMutationKeys, usePutPrescription } from '../../../../../services/chat/queries';
import { type PrescriptionRequest } from '../../../../../services/chat/types';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getMessageData, isQrData } from '../../../getMessageData';
import { type MessageType, type PrescriptionMessageData } from '../../../types';
import { isExpiredFile } from '../BasicMessage/utils';

import type { UserMessage } from '@sendbird/chat/message';

const headerData: Record<MessageType, MessageHeaderProps> = {
	REQUEST: {
		iconType: IconID.MSG_PRESCRIPTION,
		title: '처방전 접수 요청',
		subText: '약사님! 아래 처방전 내용 확인 후 승인 여부를 선택해 주세요.',
	},
	RESPONSE: {
		iconType: IconID.MSG_PRESCRIPTION,
		title: '처방전 접수 승인',
		subText: '처방전 접수가 승인되었습니다. 잠시만 기다려주시면 결제 금액을 알려드리겠습니다.',
	},
	REJECT: {
		iconType: IconID.MSG_PRESCRIPTION,
		title: '처방전 접수 거절',
		subText: '약국 내부 사정으로 인해 처방전 접수가 거절되었습니다. 불편을 드려 진심으로 죄송합니다.',
	},
};

interface PrescriptionMessagesProps {
	message: UserMessage;
}

export const PrescriptionMessages = ({ message }: PrescriptionMessagesProps) => {
	const messageData = getMessageData<PrescriptionMessageData>(message.data);

	if (messageData === null) {
		return <></>;
	}

	const { type } = messageData;

	if (type === 'REQUEST' && 'prescription' in messageData) {
		return <PrescriptionRequestMessage message={message} messageData={messageData} />;
	}

	return (
		<Container>
			<MessageHeader {...headerData[type]} />
		</Container>
	);
};

type PrescriptionRequestData = Extract<PrescriptionMessageData, { type: 'REQUEST' }>;

interface PrescriptionRequestProps {
	messageData: PrescriptionRequestData;
	message: UserMessage;
}

export const PrescriptionRequestMessage = ({ message, messageData }: PrescriptionRequestProps) => {
	const { prescription, groupId, disabled } = messageData;
	const { files, qrCodes } = prescription;
	const imageList = [...files, ...(qrCodes ?? [])];
	const { handleOpen } = context.modal.useDispatch();
	const { mutateMessage, disabledReason } = useChannelContext();
	const { mutate } = usePutPrescription();
	const isExpired = imageList.some(({ expiredAt }) => isExpiredFile(expiredAt));

	const handleReply = async (status: 'ACCEPT' | 'REJECT') => {
		await mutateMessage<PrescriptionRequest>(mutate, { groupId, status });
	};

	const buttonConfigs: Record<'READY' | 'DONE' | 'EXPIRED', React.ReactElement> = {
		READY: (
			<>
				<Button
					onClick={async () => {
						await handleReply('REJECT');
					}}
					color={'TERTIARY'}
					shouldPrevent={true}
					mutationKey={prescriptionMutationKeys}
					disabled={disabledReason !== 'none'}
					{...buttonStyle}
				>
					{'거절'}
				</Button>
				<Button
					onClick={async () => {
						await handleReply('ACCEPT');
					}}
					color={'SECONDARY'}
					shouldPrevent={true}
					mutationKey={prescriptionMutationKeys}
					disabled={disabledReason !== 'none'}
					{...buttonStyle}
				>
					{'승인'}
				</Button>
			</>
		),
		DONE: (
			<>
				<Button color={'TERTIARY'} disabled={true} {...buttonStyle}>
					{'완료'}
				</Button>
			</>
		),
		EXPIRED: (
			<>
				<Button color={'TERTIARY'} disabled={true} {...buttonStyle}>
					{'만료'}
				</Button>
			</>
		),
	};

	const handleClickImage = (idx: number) => {
		const triggerIndex = idx === 2 && imageList.length > 3 ? 0 : idx;
		handleOpen(
			<ViewerModal sender={message.sender} imageList={imageList} triggerIndex={triggerIndex} showExpiredAt={true} />,
		);
	};

	return (
		<Container>
			<MessageWrapper>
				<MessageHeader {...headerData.REQUEST} />
				<VStack $width="100%" $padding="1.2rem 1.6rem 1.6rem" $gap="1.6rem">
					<ImageList>
						{imageList.slice(0, 3).map((item, idx) =>
							isExpired ? (
								<ImageWrapper
									key={idx}
									$imageUrl={''}
									onClick={() => {
										toast.info(`만료된 처방전입니다.`);
										return null;
									}}
								>
									<Icon id={IconID.EXPIRED_FILE} width={'2.8rem'} height={'2.8rem'} />
								</ImageWrapper>
							) : (
								<ImageWrapper
									key={idx}
									$isQr={isQrData(item)}
									$imageUrl={!isQrData(item) ? item.thumbnailUrl ?? item.url : ''}
									$hasMore={imageList.length > 3}
									onClick={() => {
										handleClickImage(idx);
									}}
								>
									{idx === 2 && imageList.length > 3 && (
										<>
											<span style={{ paddingBottom: '0.3rem' }}>+</span>
											<span>{imageList.length - 3}</span>
										</>
									)}
								</ImageWrapper>
							),
						)}
					</ImageList>
					<InfoText
						text={
							isExpired
								? `이미지가 만료되었습니다.\n만료일시: ${imageList[0].expiredAt}`
								: `${imageList[0].expiredAt}에 이미지가 만료됩니다.`
						}
						color={{ background: 'gray_100', font: 'gray_800' }}
						style={{ padding: '1rem 1.6rem 1rem 1.2rem', width: '100%' }}
					/>
				</VStack>
			</MessageWrapper>
			<MessageWrapper>
				<Flex $gap={'0.8rem'} $alignSelf={'stretch'} $padding="0 1.6rem 1.6rem">
					{disabled ?? isExpired ? buttonConfigs.DONE : buttonConfigs.READY}
				</Flex>
				{/* <Flex $padding={'0.6rem 1.2rem'} $justify={'center'} $alignItems={'center'} $alignSelf={'stretch'}> */}
				{/*	<Label $fontStyle={'body_3'} $fontWeight={'medium'}> */}
				{/*		{'이용 방법을 모르겠어요!'} */}
				{/*	</Label> */}
				{/* </Flex> */}
			</MessageWrapper>
		</Container>
	);
};

const MessageWrapper = styled(Wrapper)`
	.mark-info-icon-wrapper {
		padding: 0.3rem 0.1rem;
	}
`;

const ImageList = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.8rem;
	align-self: stretch;
`;

const ImageWrapper = styled.div<{ $isQr?: boolean; $imageUrl?: string; $hasMore?: boolean }>`
	position: relative;
	isolation: isolate;
	display: flex;
	width: 7.2rem;
	height: 7.2rem;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_500};
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.font.title.title_1};
	${({ theme }) => theme.font.weight.medium};

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0.5);
	background-image: ${({ $isQr, $imageUrl }) => ($isQr ? `url("/images/message/qr-code.png")` : `url(${$imageUrl})`)};
	cursor: pointer;

	${({ $hasMore }) =>
		$hasMore &&
		css`
			&:last-child {
				&::after {
					content: '';
					position: absolute;
					opacity: 0.6;
					background: ${({ theme }) => theme.colors.black};
					inset: 0;
					z-index: -1;
				}
			}
		`}
`;
