import React, { type CSSProperties } from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { useDropdownPosition } from '../../../hooks/useDropdownPosition';

interface MenuItemsProps {
	children: React.ReactElement | React.ReactNode;
	parentRef: React.RefObject<HTMLElement>;
	openLeft?: boolean;
	isOnCursor?: boolean;
	closeDropdown: () => void;
	style?: CSSProperties;
}

/**
 *
 * @param {MenuItemsProps} props
 * @param {boolean} [props.openLeft=false] 위치 true: 왼쪽 false: 오른쪽
 * @param {boolean} [props.isOnCursor=false] 위치 true: 부모 컴포넌트 상관 없이 마우스 위에 contextmenu 처럼 위치 false: openLeft 값에 따라 부모 컴포넌트에 dropdown 처럼 위치
 */
export const MenuItems = (props: MenuItemsProps) => {
	const { children, parentRef, openLeft, isOnCursor, closeDropdown, style } = props;
	const { position, dropdownRef } = useDropdownPosition({
		parentRef,
		closeDropdown,
		openLeft,
		isContextmenu: isOnCursor,
	});

	return createPortal(
		<Container ref={dropdownRef} style={{ ...position, ...style }}>
			<Wrapper>{children}</Wrapper>
		</Container>,
		document.getElementById('goodpharm-contextmenu-portal')!,
	);
};

interface MenuItemProps {
	children: string | React.ReactElement;
	onClick?: () => void;
	style?: CSSProperties;
}

export const MenuItem = ({ children, onClick, style }: MenuItemProps) => {
	return (
		<ItemWrapper onClick={onClick} style={{ ...style }}>
			{children}
		</ItemWrapper>
	);
};

const Container = styled.div`
	position: fixed;
	z-index: 99999;
`;

const Wrapper = styled.div`
	border-radius: 8px;
	box-shadow: 0 0.6rem 1.6rem 0 rgba(31, 33, 42, 0.12);
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	width: 16rem;
	padding: 0.4rem 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ItemWrapper = styled.div`
	display: flex;
	height: 3.6rem;
	padding: 0 1.6rem;
	align-items: center;
	justify-content: space-between;
	align-self: stretch;
	cursor: pointer;

	color: ${({ theme }) => theme.colors.black};
	${({ theme }) => theme.font.body.body_2}
	${({ theme }) => theme.font.weight.regular}
`;
