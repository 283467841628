import React from 'react';

import styled from 'styled-components';

import FileBlock from './Blocks/FileBlock';
import ImageBlock from './Blocks/ImageBlock';
import MultipleFileBlock from './Blocks/MultipleFileBlock';
import MultipleImageBlock from './Blocks/MultipleImageBlock';
import StaticImageBlock from './Blocks/StaticImageBlock';
import TextBlock from './Blocks/TextBlock';
import { AssemblyDataParser } from './utils';
import { VStack } from '../../../../../components/Common';
import { type CoreMessageType } from '../../../utils';
import { BasicMessage } from '../BasicMessage';

interface AssemblyMessageProps {
	message: CoreMessageType;
	isByMe: boolean;
}

const AssemblyMessage = ({ message, isByMe }: AssemblyMessageProps) => {
	const messageData = AssemblyDataParser(message);
	if (!messageData) return <BasicMessage isByMe={isByMe} message={message} />;

	// TODO : header footer는 일단 null 상태 정해지면 만들어야함
	// TODO : TEXT, STATIC_IMAGE만 자동메시지를 위해 완성된 상태 나머지 UI 나올 시 만들어야함.
	return (
		<Container>
			{/* 기존 테스트 데이터 중 contents가 없는경우가 있음 */}
			{messageData.view.contents?.map((el, idx) => {
				const key = 'assembly--block--' + String(idx);
				switch (el.type) {
					case 'TEXT':
						return <TextBlock data={el} key={key} />;
					case 'STATIC_IMAGE':
						return <StaticImageBlock data={el} key={key} />;
					case 'FILE':
						return <FileBlock key={key} />;
					case 'IMAGE':
						return <ImageBlock key={key} />;
					case 'MULTIPLE_FILE':
						return <MultipleFileBlock key={key} />;
					case 'MULTIPLE_IMAGE':
						return <MultipleImageBlock key={key} />;
					default:
						return <></>;
				}
			})}
		</Container>
	);
};

const Container = styled(VStack)`
	max-width: 26.4rem;
	width: 26.4rem;
	border-radius: 12px;
	border: 1px solid #e9ecef;
	overflow: hidden;
`;
export default AssemblyMessage;
