import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Button } from '../../../components/Buttons';
import { InsideTitle } from '../../../components/Common';
import { Description, Tooltip, type DescriptionProps, Label, MarkInfoIcon } from '../../../components/Display';
import { ChangePasswordModal, ChangePhoneModal } from '../../../components/Modals';
import { ListTable } from '../../../components/Table';
import { type Columns } from '../../../components/Table/ListTable/types';
import context from '../../../context';
import { useGetContracts } from '../../../services/contracts/queries';
import { type Contract } from '../../../services/contracts/types';
import { type UserType, type UserInfo } from '../../../services/users/types';
import { SERVICE_STATE_VALUES, USER_TYPE_VALUES } from '../../../utils/consts';
import { formatPhoneNumber } from '../../../utils/format';

const Information = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const { data, isLoading } = useGetContracts({ storeId: userInfo?.storeId });

	const informationRows: DescriptionProps<UserInfo>['rows'] = useMemo(
		() => [
			{ key: 'name', label: '이름' },
			{ key: 'type', label: '사용자 권한', renderContent: (_, value) => USER_TYPE_VALUES[value as UserType] },
			{ key: 'loginId', label: '아이디' },
			{
				key: 'id',
				label: '비밀번호',
				renderContent: (_) => (
					<DescriptionFlexItem>
						<span>••••••••••••••••••••</span>
						<Button
							buttonType="LINE"
							size={{ $fontSize: 'S', $paddingSize: 'S', $heightSize: 'S' }}
							onClick={() => {
								handleOpen(<ChangePasswordModal />);
							}}
						>
							변경하기
						</Button>
					</DescriptionFlexItem>
				),
			},
			{
				key: 'phone',
				label: '연락처',
				renderContent: (_, value) => (
					<DescriptionFlexItem>
						<span>{value ? formatPhoneNumber(String(value)) : '-'}</span>
						<Button
							buttonType="LINE"
							size={{ $fontSize: 'S', $paddingSize: 'S', $heightSize: 'S' }}
							onClick={() => {
								handleOpen(<ChangePhoneModal />);
							}}
						>
							변경하기
						</Button>
					</DescriptionFlexItem>
				),
			},
		],
		[],
	);

	const column: Columns<Contract> = useMemo(
		() => [
			{
				key: 'id',
				headerName: 'No',
				renderComponent: (_, __, idx) => <React.Fragment>{data && data.length - idx}</React.Fragment>,
			},
			{ key: 'serviceName', headerName: '서비스명' },
			{
				key: 'serviceState',
				headerName: '서비스 상태',
				renderComponent: (value) => SERVICE_STATE_VALUES[value],
			},
			{ key: 'requestDate', headerName: '신청일' },
			{ key: 'contractDate', headerName: '계약일' },
			{ key: 'installationExpectDate', headerName: '설치 예정일' },
			{ key: 'installationDate', headerName: '설치일' },
		],
		[data],
	);

	return (
		<Container>
			<div className="top">
				<InsideTitle title="내 계정 정보" />
				<Description data={userInfo} rows={informationRows} />
			</div>
			<div className="bottom">
				<InsideTitle
					title={
						<div style={{ display: 'flex', gap: '0.6rem', alignItems: 'center' }}>
							<span>이용 서비스</span>
							<Tooltip
								floatingOptions={{ offset: 10, allowedPlacements: ['right'] }}
								trigger={
									<MarkInfoIcon type={'LINE'} bgColor={'gray_600'} color={'white'} width={'2rem'} height={'2rem'} />
								}
							>
								<Label $color={'white'} $fontStyle={'caption_1'}>
									{'현재 시점 기준으로, 고객님이 이용했거나 이용 중인 서비스를 조회하실 수 있습니다.'}
								</Label>
							</Tooltip>
						</div>
					}
				/>
				<ListTable
					data={data}
					columns={column}
					isLoading={isLoading}
					isScroll={false}
					headerStyle={{ padding: '1.2rem 0' }}
					tableStyle={{ border: 'none' }}
					tableHeaderStyle={{ border: 'none' }}
					emptyMessage="이용 서비스가 없습니다."
				/>
			</div>
		</Container>
	);
};

const DescriptionFlexItem = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	span {
		min-width: 14.3rem;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
`;

export default Information;
