import React, { useMemo } from 'react';

import { format } from 'date-fns/format';
import { subDays } from 'date-fns/subDays';
import { subMonths } from 'date-fns/subMonths';
import styled from 'styled-components';

import { AgeTotalsPie } from './components/AgeTotalsPie';
import { AvgCardList } from './components/AvgCardList';
import { GrossProfitLine } from './components/GrossProfitLine';
import { TotalsCardList } from './components/TotalsCardList';
import { scrollStyle } from '../../assets/styles/scrollStyle';
import { Button } from '../../components/Buttons';
import { Flex, InsideTitle } from '../../components/Common';
import { Icon, IconID, InfoText, Label } from '../../components/Display';
import { AlertModal } from '../../components/Modals';
import context from '../../context';
import {
	type UnitQueryResultType,
	useGetPeriodDetails,
	useGetStatsAges,
	useGetStatsLatest,
	usePostStatsCollect,
} from '../../services/statistics/queries';
import { type StatsDetail } from '../../services/statistics/types';
import { SaleProducts } from '../Stats/components/StatsTable';
import { getDateEndOfUnit, getDateStartOfUnit, formatPeriod } from '../Stats/utils';

export const sum = (arr: StatsDetail[], key: keyof StatsDetail) =>
	arr.reduce((acc, item) => acc + Number(item[key]), 0);
const Dashboard = () => {
	const { handleOpen } = context.modal.useDispatch();
	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;
	const today = useMemo(() => new Date(), []);

	const period = useMemo(() => {
		const [start, end] = [getDateStartOfUnit(today, 'monthly'), today];
		const prev = subMonths(start, 1);
		const [prevStart, prevEnd] = [prev, getDateEndOfUnit(prev, 'monthly')];
		const samePrevEnd = subMonths(end, 1);
		return {
			current: formatPeriod([start, end]),
			prevMonth: formatPeriod([prevStart, prevEnd]),
			prevRange: formatPeriod([prevStart, samePrevEnd]),
		};
	}, [today]);

	const { data: latest } = useGetStatsLatest({ storeId });

	const { mutate: postCollect, isPending } = usePostStatsCollect({ storeId });

	const { data: details, isSuccess: detailsIsSuccess } = useGetPeriodDetails<
		'daily',
		Array<UnitQueryResultType<'daily'>>
	>({
		storeId,
		period: [period.current, period.prevMonth],
		unit: 'daily',
		select: (data) => data.slice().reverse(),
	});

	const { data: agesAvg, isSuccess: agesAvgIsSuccess } = useGetStatsAges<{ total: number }>({
		storeId,
		period: [period.current, period.prevMonth],
		type: 'average',
		select: ({ total }) => ({ total: Math.floor(total) }),
	});

	const { data: agesTotals, isSuccess: agesTotalsIsSuccess } = useGetStatsAges({
		storeId,
		period: [period.current, period.prevRange],
		type: 'total',
	});

	const handleClickUpdate = () => {
		const { startDate, endDate } = formatPeriod([today, today]);
		postCollect(
			{ storeId, startDate, endDate },
			{
				onSuccess: () => {
					handleOpen(<AlertModal message={`요청에 성공하였습니다.\n데이터 갱신은 다소 시간이 걸릴 수 있습니다.`} />);
				},
				onError: () => {
					handleOpen(<AlertModal message={`요청에 실패하였습니다.`} />);
				},
			},
		);
	};

	return (
		<Container>
			<Header>
				<Flex $alignItems={'center'} $gap={'1.2rem'}>
					<Button
						buttonType={'LINE'}
						size={{
							$paddingSize: 'M',
						}}
						color={'BLACK'}
						disabled={isPending}
						shouldPrevent={true}
						onClick={handleClickUpdate}
					>
						<Icon
							id={IconID.UPDATE}
							disabledColor={'gray_300'}
							isDisabled={isPending}
							width={'1.6rem'}
							height={'1.6rem'}
						/>
						{'데이터 갱신'}
					</Button>
					<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_700'}>
						{`마지막으로 업데이트 된 시간 : ${format(latest ?? subDays(today, 1), 'yyyy-MM-dd')}`}
					</Label>
				</Flex>
				<InfoText
					text={'데이터는 매일 밤 12시에 자동 업데이트 됩니다.'}
					color={{ background: 'gray_100', font: 'primary_700' }}
					font={'body_2'}
					innerStyle={{ alignItems: 'center' }}
				/>
			</Header>
			<Wrapper>
				<Content>
					<AvgCardList isSuccess={detailsIsSuccess && agesAvgIsSuccess} ages={agesAvg} details={details} />
				</Content>
				<Content>
					<ItemsWrapper>
						<InsideTitle title={'월별 총계'} />
						<TotalsCardList ages={agesTotals} details={details} isSuccess={agesTotalsIsSuccess && detailsIsSuccess} />
					</ItemsWrapper>
					<ItemsWrapper>
						<InsideTitle title={'금월 BEST 상품'} />
						<SaleProducts storeId={storeId} {...period.current} maxHeight={'21.7rem'} />
					</ItemsWrapper>
				</Content>
				<Content>
					<ItemsWrapper>
						<InsideTitle title={'금월 연령대 별 고객 비율'} />
						<AgeTotalsPie ages={agesTotals} isSuccess={agesTotalsIsSuccess} />
					</ItemsWrapper>
					<ItemsWrapper>
						<InsideTitle title={'전월 대비 매출 그래프'} />
						<GrossProfitLine details={details} isSuccess={detailsIsSuccess} today={today} />
					</ItemsWrapper>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-width: 132rem;
	position: relative;
	.inside-title {
		min-height: 0;
		padding: 0;
		h3 {
			${({ theme }) => theme.font.title.title_1};
		}
	}
`;

const Wrapper = styled(Flex)`
	flex-direction: column;
	align-self: stretch;
	flex: 1 1 0;
	${scrollStyle()}
`;

const Content = styled(Flex)`
	padding: 2rem 0;
	width: 100%;
	gap: 2rem;
	align-items: start;
	align-self: stretch;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};

	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}
`;

const ItemsWrapper = styled(Flex)`
	flex-direction: column;
	gap: 1.2rem;
	flex: 1 0 0;
`;

const Header = styled.div`
	display: flex;
	padding: 1.2rem 2rem;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	background-color: ${({ theme }) => theme.colors.gray.gray_100};
	margin: -2rem -2rem 0;
`;

export default Dashboard;
