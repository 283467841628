import React from 'react';

import styled, { css } from 'styled-components';

import { changeColorKeyToType, type ColorKeys } from '../../../utils/changeColorKeyToType';
import { Icon, IconID } from '../Icon';

interface MarkInfoIconProps {
	type: 'FILLED' | 'LINE';
	bgColor: ColorKeys;
	color: ColorKeys;
	width?: string;
	height?: string;
	rotate?: boolean;
}

export const MarkInfoIcon = (props: MarkInfoIconProps) => {
	const { type, bgColor, color, width = '1.4rem', height = '1.4rem', rotate = false } = props;

	return (
		<Wrapper className={'mark-info-icon-wrapper'}>
			<IconWrapper $type={type} $color={bgColor} $width={width} $height={height} $rotate={rotate}>
				<Icon id={IconID.MARK_INFO} defaultColor={color} width={width} height={height} />
			</IconWrapper>
		</Wrapper>
	);
};

interface IconWrapperProps {
	$type: 'FILLED' | 'LINE';
	$color: ColorKeys;
	$width: string;
	$height: string;
	$rotate: boolean;
}

const Wrapper = styled.div`
	padding: 0.1rem;
`;

const IconWrapper = styled.div<IconWrapperProps>`
	width: ${({ $width }) => $width};
	height: ${({ $height }) => $height};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;

	${({ $rotate }) =>
		$rotate &&
		css`
			transform: rotate(180deg);
		`}

	${({ $type, $color }) => {
		if ($type === 'LINE') {
			return css`
				border: 1px solid ${changeColorKeyToType($color)};
				background-color: ${changeColorKeyToType($color)};
			`;
		}
		if ($type === 'FILLED') {
			return css`
				border: 0;
				background-color: ${changeColorKeyToType($color)};
			`;
		}
	}};
`;
