import React, { createContext, useContext, useEffect, useState } from 'react';

interface ContextValue {
	isLogin: boolean;
}

const AuthContext = createContext<ContextValue | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	// 로그인 여부
	const [isLogin, setLogin] = useState<boolean>(!!window.localStorage.getItem('accessToken'));

	const handleLogin = () => {
		setLogin(!!window.localStorage.getItem('accessToken'));
	};

	// auth event 발생 시 isLogin 업데이트
	useEffect(() => {
		window.addEventListener('auth', handleLogin);
		return () => {
			window.removeEventListener('auth', handleLogin);
		};
	}, []);

	return <AuthContext.Provider value={{ isLogin }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
	const state = useContext(AuthContext);
	if (!state) {
		throw new Error('Cannot find UserProvider');
	}
	return state;
};
