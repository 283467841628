import { useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { type GetBlockRequest, type PostAndDeleteBlockMembersRequest } from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';

export const blockKeys = {
	all: ['block'] as const,
	getMembers: (pagination: PaginationQueries, values: GetBlockRequest) =>
		[...blockKeys.all, 'getMembers', values, pagination] as const,
};

export const useGetBlockMembers = (storeId: string) => {
	const initValues: GetBlockRequest = useMemo(
		() => ({
			storeId,
			sorts: {
				property: null,
				direction: null,
			},
		}),
		[],
	);
	const pagination = usePagination();
	const queries = useValues(initValues);
	const { curPage, listCount, setPagination } = pagination;

	const queryInfo = useQuery({
		queryKey: blockKeys.getMembers({ curPage, listCount }, queries.values),
		queryFn: async () => await api.block.getBlockMembers({ page: curPage, limit: listCount, ...queries.values }),
		enabled: !!storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const usePostBlockMembers = (props?: MutateCallback<PostAndDeleteBlockMembersRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const postBlockMembers = useMutation({
		mutationFn: async (params: PostAndDeleteBlockMembersRequest) => await api.block.postBlockMembers(params),
		onSuccess,
		onError,
		mutationKey: ['block'],
	});
	return postBlockMembers;
};

export const useDeleteBlockMembers = (props?: MutateCallback<PostAndDeleteBlockMembersRequest, any>) => {
	const { onSuccess, onError } = props ?? {};

	const deleteBlockMembers = useMutation({
		mutationFn: async (params: PostAndDeleteBlockMembersRequest) => await api.block.deleteBlockMembers(params),
		onSuccess,
		onError,
		mutationKey: ['block'],
	});
	return deleteBlockMembers;
};
