import React, { useMemo } from 'react';

import { subMonths } from 'date-fns/subMonths';

import { GROSS_PROFIT_ROWS, TOTALS_ROWS } from './rows';
import { useGetTotalsGrossProfit } from '../../../../services/statistics/queries';
import { formatPeriod } from '../../utils';
import { ItemsWrapper } from '../styles';

import { DescriptionCard } from './index';

interface TotalsGrossProfitProps {
	storeId: string;
	dateRange: [Date | null, Date | null];
	isLastYear: boolean;
}

export function TotalsGrossProfit({ storeId, dateRange, isLastYear }: TotalsGrossProfitProps) {
	const period = useMemo(() => {
		let [start, end] = dateRange;
		start = isLastYear && start ? subMonths(start, 12) : start;
		end = isLastYear && end ? subMonths(end, 12) : end;
		return formatPeriod([start, end]);
	}, [dateRange, isLastYear]);

	const { data, isLoading, isSuccess } = useGetTotalsGrossProfit({ storeId, ...period });

	return (
		<ItemsWrapper $gap={'1.2rem'}>
			<DescriptionCard
				data={data}
				title={isLastYear ? '전년도 동기 매출 총계' : '매출 총계'}
				isLoading={isLoading || !isSuccess}
				style={{ maxWidth: '24rem' }}
				{...TOTALS_ROWS}
			/>
			<DescriptionCard
				data={data}
				title={isLastYear ? '전년도 동기 매출 총이익' : '매출 총이익'}
				isLoading={isLoading || !isSuccess}
				{...GROSS_PROFIT_ROWS}
			/>
		</ItemsWrapper>
	);
}
