import React from 'react';

import { type DescriptionProps } from '../../../../components/Display';
import { type Group } from '../../../../services/groups/types';
import { formatYN } from '../../../../utils/format';

export const groupDetailRows: DescriptionProps<Group>['rows'] = [
	{ key: 'name', label: '그룹명' },
	{ key: 'categoryName', label: '카테고리' },
	{
		key: 'isSystem',
		label: '커스텀 적용',
		renderContent: (_, value) => formatYN(!value as boolean),
	},
	{
		key: 'description',
		label: '그룹 설명',
		renderContent: (_, value) => <p style={{ whiteSpace: 'pre-wrap' }}>{value}</p>,
	},
];
