import { useMutation, useQuery } from '@tanstack/react-query';

import {
	type AnnouncementRequest,
	type PaymentRequest,
	type PrescriptionRequest,
	type PushPreferenceRequest,
} from './types';
import { api } from '..';
import { type MutateCallback } from '../../hooks/types';

import type { ErrorData } from '../types';
import type { AxiosError } from 'axios';

export const chatKeys = {
	all: ['chat'] as const,
	announcementUsage: () => [...chatKeys.all, 'announcementUsage'] as const,
} as const;

export const usePostAnnouncement = (props?: MutateCallback<AnnouncementRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation<unknown, AxiosError<ErrorData>, AnnouncementRequest>({
		mutationFn: async (params: AnnouncementRequest) => await api.chat.postAnnouncement(params),
		onSuccess,
		onError,
	});
};
export const useGetAnnouncementUsage = () => {
	return useQuery({
		queryKey: chatKeys.announcementUsage(),
		queryFn: async () => await api.chat.getAnnouncementsUsage(),
	});
};

export const prescriptionMutationKeys = [...chatKeys.all, 'prescription'];
export const promotionMutationKeys = [...chatKeys.all, 'promotion'];

export const usePutPrescription = () => {
	return useMutation<unknown, AxiosError<ErrorData>, PrescriptionRequest>({
		mutationFn: async (params: PrescriptionRequest) => await api.chat.putPrescription(params),
		mutationKey: prescriptionMutationKeys,
	});
};

export const usePutPromotion = () => {
	return useMutation<unknown, AxiosError<ErrorData>, PrescriptionRequest>({
		mutationFn: async (params: PrescriptionRequest) => await api.chat.putPromotion(params),
		mutationKey: promotionMutationKeys,
	});
};

export const usePutPharmSales = () =>
	useMutation<unknown, AxiosError<ErrorData>, PrescriptionRequest>({
		mutationFn: async (params) => await api.chat.putPharmSales(params),
	});

export const usePostPayment = () => {
	return useMutation<unknown, AxiosError<ErrorData>, PaymentRequest>({
		mutationFn: async (params: PaymentRequest) => await api.chat.postPayment(params),
	});
};

export const usePutPushPreference = () => {
	return useMutation<unknown, Error, PushPreferenceRequest>({
		mutationFn: async (params: PushPreferenceRequest) => await api.chat.putPushPreference(params),
	});
};
