import React, { useCallback, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Loading } from '../../components/Common';
import { Label } from '../../components/Display';
import { FileInput, FormItem, TextField } from '../../components/Forms';
import { ConfirmModal, Modal, type ModalProps } from '../../components/Modals';
import context from '../../context';
import { useValues } from '../../hooks/useValues';
import { useMutateWithAssets } from '../../services/asset/queries';
import { type Asset } from '../../services/asset/types';
import { useDeleteProducts, usePostProduct, usePutProducts } from '../../services/products/queries';
import { type PutProductRequest, type ProductItem } from '../../services/products/types';
import { createFileFromAssets } from '../../utils/files';
import { commaizeNumber, decommaizeNumber, removeLeadingZero } from '../../utils/format';
import { isValidCheck } from '../../utils/isValidCheck';
import { isNumberString } from '../../utils/validators';

interface ProductModalProps extends ModalProps {
	selectedItem: ProductItem | null;
	onSuccess: () => void;
}

interface TmpProductRequest extends PutProductRequest {
	files: File[];
	assetIds: number[];
}
const INITIAL_VALUES: TmpProductRequest = {
	id: -1,
	assetId: -1,
	barcode: '',
	name: '',
	price: 0,
	storeId: '',

	files: [],
	assetIds: [],
};

export const ProductModal = ({ selectedItem, onSuccess }: ProductModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleOpen, handleClose } = context.modal.useDispatch();

	const TYPE = useMemo(() => (selectedItem ? '수정' : '등록'), [selectedItem]);

	const { values, onChangeValues, dispatch } = useValues(INITIAL_VALUES);

	const isEqualAsset = useMemo(() => {
		if (values.files.length > 0 && selectedItem?.productAsset) {
			return (
				values.files[0].name === selectedItem?.productAsset.name &&
				values.files[0].size === selectedItem?.productAsset.size
			);
		} else {
			return false;
		}
	}, [values.files, selectedItem]);

	const updateValues = useCallback(async () => {
		if (selectedItem) {
			const { productAsset, createdAt, updatedAt, ...rest } = selectedItem;
			const assets: Asset[] = [];
			if (productAsset) assets.push(productAsset);
			const files = await createFileFromAssets(assets);
			dispatch('SET', { ...rest, files, assetId: assets[0]?.id });
		}
	}, [selectedItem]);

	useEffect(() => {
		updateValues();
	}, [selectedItem]);

	const { mutate, isPending } = usePostProduct({
		onSuccess: () => {
			toast.success('상품 ' + TYPE + '에 성공하였습니다.');
			handleClose();
			onSuccess();
		},
		onError: (err) => {
			toast.error('상품 ' + TYPE + '에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { mutate: putMutate, isPending: isPutPending } = usePutProducts({
		onSuccess: () => {
			toast.success('상품 ' + TYPE + '에 성공하였습니다.');
			handleClose();
			onSuccess();
		},
		onError: (err) => {
			toast.error('상품 ' + TYPE + '에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { handleMutateWithAssets, isAssetPending } = useMutateWithAssets<TmpProductRequest>({
		assetKey: 'assetIds',
		params: values,
		callback: (value) => {
			const { price, assetIds, files, assetId, ...rest } = value;
			const putParams = {
				...rest,
				assetId: isEqualAsset ? assetId : assetIds[0],
				price: decommaizeNumber(String(price)),
				storeId: userInfo.storeId,
			};
			if (selectedItem) {
				putMutate(putParams);
			} else {
				const { id, ...postParams } = putParams;
				mutate({
					...postParams,
				});
			}
		},
	});

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const files: File[] = [];
			if (!isEqualAsset && values.files.length > 0) files.push(values.files[0]);

			handleMutateWithAssets(files);
		},
		[values],
	);

	const isValid = useMemo(
		() => Object.entries({ name: values.name, price: values.price }).every(([key, value]) => isValidCheck(key, value)),
		[values.name, values.price],
	);

	const { mutate: deleteMutate, isPending: isDeleteMutate } = useDeleteProducts({
		onSuccess: () => {
			toast.success('상품 삭제에 성공하였습니다.');
			handleClose();
			onSuccess();
		},
		onError: (err) => {
			toast.error('상품 삭제에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onDelete = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					if (selectedItem) deleteMutate({ storeId: userInfo.storeId, ids: [selectedItem.id] });
				}}
				message={'판매상품을 삭제하시겠습니까?'}
			/>,
		);
	}, [selectedItem]);

	return (
		<Modal
			header={{ title: '판매상품 ' + TYPE }}
			footer={{
				button: [
					{
						children: '삭제',
						buttonType: 'LINE',
						color: 'RED',
						onClick: onDelete,
						size: { $paddingSize: 'XL' },
						style: { marginRight: 'auto', display: selectedItem ? '' : 'none' },
					},
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						type: 'submit',
						children: TYPE,
						style: { width: '10rem' },
						shouldPrevent: true,
						form: 'product__form',
						disabled: !isValid,
					},
				],
			}}
		>
			<Form {...{ id: 'product__form', onSubmit }}>
				{(isPending || isAssetPending || isDeleteMutate || isPutPending) && <Loading />}
				<FormItem label="상품명" isRequired>
					<TextField
						placeholder="상품명을 입력해주세요."
						name="name"
						value={values.name ?? ''}
						onChange={onChangeValues}
						inputSize={'lg'}
						maxLength={64}
					/>
				</FormItem>
				<FormItem label="정가" isRequired>
					<TextField
						name="price"
						value={values.price ?? 0}
						fontStyle={{ $textAlign: 'end' }}
						renderSuffix={
							<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_600'}>
								{'원'}
							</Label>
						}
						onBlur={(e) => {
							const { value, name } = e.target;
							onChangeValues({
								target: { value: commaizeNumber(value), name },
							} as React.ChangeEvent<HTMLInputElement>);
						}}
						onChange={(e) => {
							const { value, name } = e.target;
							let formattedValue = String(decommaizeNumber(value));
							if (isNumberString(formattedValue) || formattedValue === '') {
								formattedValue = removeLeadingZero(formattedValue);
								onChangeValues({
									target: { value: formattedValue, name },
								} as React.ChangeEvent<HTMLInputElement>);
							}
						}}
						inputSize={'lg'}
						style={{ textAlign: 'right' }}
					/>
				</FormItem>
				<FormItem label="바코드">
					<TextField
						placeholder="바코드를 입력해주세요."
						name="barcode"
						value={values.barcode ?? ''}
						onChange={onChangeValues}
						inputSize={'lg'}
						maxLength={100}
					/>
				</FormItem>
				<FormItem label="상품 이미지">
					<FileInput
						values={values.files}
						onChange={(files) => {
							dispatch('SET', { files });
						}}
						maxCapacity={{ size: 10, unit: 'MB' }}
						format={['image']}
						maxFileCount={1}
					/>
				</FormItem>
			</Form>
		</Modal>
	);
};

const Form = styled.form`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 64rem;
`;
