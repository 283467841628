import React from 'react';

import { type UserMessage } from '@sendbird/chat/message';

import { MessageHeader, type MessageHeaderProps } from './MessageHeader';
import { Container, Divider, Wrapper } from './styles';
import { Flex } from '../../../../../components/Common';
import { IconID, Label } from '../../../../../components/Display';
import { getMessageData } from '../../../getMessageData';
import { type ReceiptMessageData } from '../../../types';

const headerData: MessageHeaderProps = {
	iconType: IconID.MSG_RECEIPT,
	title: '약제비 영수증 요청',
	subText: '약사님 아래 기간의 약제비 영수증 요청을 받았습니다.',
} as const;

interface ReceiptMessageProps {
	message: UserMessage;
}

export const ReceiptMessage = ({ message }: ReceiptMessageProps) => {
	const messageData = getMessageData<ReceiptMessageData>(message.data);

	if (messageData === null) {
		return <></>;
	}

	const { startDate, endDate } = messageData.receipt;

	return (
		<Container>
			<Wrapper>
				<MessageHeader {...headerData} />
				<Divider />
				<Flex $justify={'between'} $alignItems={'center'} $alignSelf={'stretch'} $padding="1.2rem 1.6rem 1.6rem">
					<Label $color={'gray_700'} $fontStyle={'body_3'}>
						{'요청기간'}
					</Label>
					<Label $fontStyle={'body_3'}>{[startDate, endDate].map((date) => date.replace('-', '.')).join(' ~ ')}</Label>
				</Flex>
			</Wrapper>
		</Container>
	);
};
