import React, { type ButtonHTMLAttributes, forwardRef, useEffect, useRef } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { predicateMutating } from '../Button';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	width?: string;
	height?: string;
	shouldPrevent?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	(props: IconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
		const {
			children,
			width = '2.4rem',
			height = '2.4rem',
			style,
			type = 'button',
			shouldPrevent = false,
			disabled = false,
			...rest
		} = props;

		const isMutating = useIsMutating({ predicate: predicateMutating });
		const buttonRef = ref ?? useRef<HTMLButtonElement>(null);

		useEffect(() => {
			if (buttonRef && typeof ref === 'object' && 'current' in buttonRef && buttonRef.current) {
				if (isMutating && shouldPrevent && !buttonRef.current.disabled) {
					buttonRef.current.disabled = true;
				} else {
					buttonRef.current.disabled = disabled;
				}
			}
		}, [isMutating, shouldPrevent]);

		return (
			<button
				ref={buttonRef}
				type={type}
				style={{
					...style,
					width,
					height,
					cursor: 'pointer',
				}}
				tabIndex={-1}
				disabled={disabled}
				{...rest}
			>
				{children}
			</button>
		);
	},
);

IconButton.displayName = 'IconButton';
