import React, { useLayoutEffect } from 'react';

import { useSdkReducer } from './dux/sdk/reducer';
import { useUserReducer } from './dux/user/reducer';
import useConnect from './hooks/useConnect';
import { disconnectSdk } from './hooks/useConnect/disconnectSdk';
import { type ReconnectType } from './hooks/useConnect/types';
import { type MarkAsReadSchedulerType, useMarkAsReadScheduler } from './hooks/useMarkAsReadScheduler';
import useOnlineStatus from './hooks/useOnlineStatus';

export type SdkDataSource = ReturnType<typeof useSdkReducer>;
export type UserDataSource = ReturnType<typeof useUserReducer>;

interface SendbirdState {
	stores: {
		sdkStore: SdkDataSource['sdkStore'];
		userStore: UserDataSource['userStore'];
	};
	dispatchers: {
		sdkDispatcher: SdkDataSource['sdkDispatcher'];
		userDispatcher: UserDataSource['userDispatcher'];
		reconnect: ReconnectType;
	};
	config: {
		isOnline: boolean;
		markAsReadScheduler: MarkAsReadSchedulerType;
	};
}
interface SendbirdSdkProviderProps {
	userId: string;
	accessToken?: string;
	nickname?: string;
	children: React.ReactNode;
}

export const SendbirdSdkContext = React.createContext<SendbirdState | null>(null);

const SendbirdSdkProvider = (props: SendbirdSdkProviderProps) => {
	const { userId, accessToken, nickname = '', children } = props;
	const { sdkStore, sdkDispatcher } = useSdkReducer();
	const { userStore, userDispatcher } = useUserReducer();

	const sdk = sdkStore?.sdk;

	const reconnect = useConnect(
		{
			userId,
			accessToken,
		},
		{
			nickname,
			sdk,
			sdkDispatcher,
			userDispatcher,
		},
	);

	useLayoutEffect(() => {
		return () => {
			if (typeof sdk.disconnect === 'function') {
				disconnectSdk({
					sdk,
					sdkDispatcher,
					userDispatcher,
				});
			}
		};
	}, [sdk]);

	const isOnline = useOnlineStatus(sdkStore.sdk);
	const markAsReadScheduler = useMarkAsReadScheduler({
		isConnected: isOnline,
	});

	return (
		<SendbirdSdkContext.Provider
			value={{
				stores: {
					sdkStore,
					userStore,
				},
				dispatchers: {
					sdkDispatcher,
					userDispatcher,
					reconnect,
				},
				config: {
					isOnline,
					markAsReadScheduler,
				},
			}}
		>
			{children}
		</SendbirdSdkContext.Provider>
	);
};

function useSBStateContext() {
	const context = React.useContext(SendbirdSdkContext);
	if (!context) {
		throw new Error('useContext should be in Provider');
	}
	return context;
}

export { SendbirdSdkProvider, useSBStateContext };
