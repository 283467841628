import type React from 'react';
import { useState } from 'react';

interface DisclosureProps {
	children: (args: DisclosureRenderProps) => React.ReactElement;
	defaultIsOpen?: boolean;
}

interface DisclosureRenderProps {
	isOpen: boolean;
	toggle: () => void;
}

export const Disclosure = ({ children, defaultIsOpen = false }: DisclosureProps) => {
	const [isOpen, setIsOpen] = useState(defaultIsOpen);

	const toggleDisclosure = () => {
		setIsOpen((prev) => !prev);
	};

	return children({ isOpen, toggle: toggleDisclosure });
};
