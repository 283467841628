import React, { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { type AcceptableMimeTypes, getMimeTypesAccepts } from '../../../utils/files';
import { type CapacityUnitType, formatFileToBase64, unitToBytes } from '../../../utils/format';

interface MaxCapacityType {
	unit: CapacityUnitType;
	size: number;
}

interface FileUploaderProps {
	children: (args: FileUploaderRenderProps) => React.ReactElement;
	onChange: (files: File[]) => void;
	format?: AcceptableMimeTypes[];
	maxCount?: number;
	maxCapacity?: { total?: MaxCapacityType; single?: MaxCapacityType };
	values: File[];
}

interface FileUploaderRenderProps {
	onClickTrigger: () => void;
	convertedUrls: string[];
	onDelete: (idx: number) => void;
	onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ERROR_MESSAGE = {
	INVALID_FORMAT: '허용하지 않는 파일 포맷입니다.',
	COUNT_EXCEEDED: '등록 가능한 파일갯수를 초과하였습니다.',
	OVERSIZE: '제한 용량을 초과하였습니다.',
} as const;

type CheckValid = (files: File[], onSuccess: (files: File[]) => void, onError: (err: Error) => void) => void;

export const FileUploader = (props: FileUploaderProps) => {
	const { children, onChange, format, maxCount, maxCapacity, values } = props;
	const accepts = getMimeTypesAccepts(format);

	const ref = useRef<HTMLInputElement>(null);
	const [convertedUrls, setConvertedUrls] = useState<string[]>([]);
	const count = useRef(values.length);

	useEffect(() => {
		async function convertFilesToBase64() {
			const newFiles = values.slice(count.current);
			const converted = await Promise.all(newFiles.map(formatFileToBase64));
			setConvertedUrls((prev) => [...prev, ...converted]);
		}
		if (count.current !== values.length) {
			// 초기화
			if (values.length === 0) {
				setConvertedUrls([]);
			} else {
				convertFilesToBase64();
			}
			count.current = values.length;
		}
	}, [values]);

	const onClickTrigger = () => {
		ref.current?.click();
	};

	const checkValid: CheckValid = useCallback(
		(files, onSuccess, onError) => {
			try {
				// format check
				if (format !== undefined && files.some((file) => !accepts.includes(file.type))) {
					throw new Error(ERROR_MESSAGE.INVALID_FORMAT);
				}
				// count check
				if (maxCount !== undefined && files.length + values.length > maxCount) {
					throw new Error(ERROR_MESSAGE.COUNT_EXCEEDED);
				}
				// capacity check
				if (maxCapacity !== undefined) {
					if (maxCapacity.single) {
						const maxSize = maxCapacity.single.size * unitToBytes[maxCapacity.single.unit];
						if (files.some((file) => file.size > maxSize)) {
							throw new Error(ERROR_MESSAGE.OVERSIZE);
						}
					}
					if (maxCapacity.total) {
						const totalSize = files.reduce((acc, cur) => acc + cur.size, 0);
						const maxSize = maxCapacity.total.size * unitToBytes[maxCapacity.total.unit];
						if (totalSize > maxSize) {
							throw new Error(ERROR_MESSAGE.OVERSIZE);
						}
					}
				}

				onSuccess(files);
			} catch (err) {
				onError(err as Error);
			}
		},
		[format, maxCount, maxCapacity, values, accepts],
	);

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files?.length) return null;
		const filesArray = Array.from(files);
		checkValid(
			filesArray,
			(files) => {
				onChange([...values, ...files]);
			},
			(err) => {
				e.target.value = '';
				toast.error(err.message);
			},
		);
	};

	const onDelete = (targetIdx: number) => {
		const updated = values.filter((_, idx) => idx !== targetIdx);
		onChange(updated);
		setConvertedUrls((prev) => [...prev.slice(0, targetIdx), ...prev.slice(targetIdx + 1)]);
		count.current = updated.length;
	};

	return (
		<>
			{children({
				onClickTrigger,
				convertedUrls,
				onDelete,
				onFileChange,
			})}
			<input
				type={'file'}
				ref={ref}
				onChange={onFileChange}
				onClick={(e) => {
					const element = e.target as HTMLInputElement;
					element.value = '';
				}}
				accept={accepts}
				style={{ display: 'none' }}
				multiple={maxCount !== undefined && maxCount !== 1}
			/>
		</>
	);
};
