import { type Asset } from '../services/asset/types';

export type AcceptableMimeTypes = 'image' | 'video' | 'audio' | 'document' | 'application';

const SUPPORTED_MIMES = {
	IMAGE: [
		'image/jpeg',
		'image/jpg',
		'image/png',
		// 'image/gif',
		// 'image/svg+xml',
		// 'image/webp', // not supported in IE
	],
	VIDEO: [
		'video/mpeg',
		'video/ogg',
		'video/webm',
		'video/mp4',
		// 'video/quicktime', // NOTE: Do not support ThumbnailMessage for the .mov video
	],
	AUDIO: [
		'audio/aac',
		'audio/midi',
		'audio/x-midi',
		'audio/mpeg',
		'audio/ogg',
		'audio/opus',
		'audio/wav',
		'audio/webm',
		'audio/3gpp',
		'audio/3gpp2',
		'audio/mp3',
	],
	DOCUMENT: [
		'text/plain',
		'text/css',
		'text/csv',
		'text/html',
		'text/calendar',
		'text/javascript',
		'text/xml',
		'video/quicktime', // NOTE: Assume this video is a normal file, not video
	],
	APPLICATION: [
		'application/x-abiword',
		'application/x-freearc',
		'application/vnd.amazon.ebook',
		'application/octet-stream',
		'application/x-bzip',
		'application/x-bzip2',
		'application/x-cdf',
		'application/x-csh',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-fontobject',
		'application/epub+zip',
		'application/gzip',
		'application/java-archive',
		'application/json',
		'application/ld+json',
		'application/vnd.apple.installer+xml',
		'application/vnd.oasis.opendocument.presentation',
		'application/vnd.oasis.opendocument.spreadsheet',
		'application/vnd.oasis.opendocument.text',
		'application/ogg',
		'application/pdf',
		'application/x-httpd-php',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.rar',
		'application/rtf',
		'application/x-sh',
		'application/x-tar',
		'application/vnd.visio',
		'application/xhtml+xml',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/xml',
		'application/vnd.mozilla.xul+xml',
		'application/zip',
		'application/x-7z-compressed',
	],
};

export const getMimeTypesAccepts = (acceptableMimeTypes?: AcceptableMimeTypes[]): string => {
	if (Array.isArray(acceptableMimeTypes) && acceptableMimeTypes.length > 0) {
		return acceptableMimeTypes
			.reduce<string[]>((prev, curr) => {
				switch (curr) {
					case 'image': {
						prev.push(...SUPPORTED_MIMES.IMAGE);
						break;
					}
					case 'video': {
						prev.push(...SUPPORTED_MIMES.VIDEO);
						break;
					}
					case 'audio': {
						prev.push(...SUPPORTED_MIMES.AUDIO);
						break;
					}
					case 'document': {
						prev.push(...SUPPORTED_MIMES.DOCUMENT);
						break;
					}
					case 'application': {
						prev.push(...SUPPORTED_MIMES.APPLICATION);
						break;
					}
					default: {
						prev.push(curr);
						break;
					}
				}

				return prev;
			}, [])
			.join();
	}

	return Object.values(SUPPORTED_MIMES)
		.reduce((prev, curr) => prev.concat(curr), [])
		.join();
};
export const isImage = (type: string): boolean => SUPPORTED_MIMES.IMAGE.includes(type);

export const createFileFromAssets = async (assets: Asset[]) => {
	try {
		const files = await Promise.all(
			assets.map(async (asset) => {
				const res = await fetch(asset.url);
				const data = await res.blob();
				const file = new File([data], asset.name, {
					type: asset.contentType,
				});
				return file;
			}),
		);

		return files;
	} catch (err) {
		console.error(err);
	}
};

interface ReturnComparisonFilesToAssets {
	hasFiles: File[];
	deleteAssets: Asset[];
	addFiles: File[];
}

type ComparisonFilesToAssets = (assets: Asset[], files: File[]) => ReturnComparisonFilesToAssets;

/**
 * 에셋과 파일을 비교해서 삭제된것과 새로생긴것을 찾아내는 함수
 * 비교할 땐 name + size + type으로 비교한다.
 * @param {Asset[]} assets 에셋 배열
 * @param {File[]} files 파일 배열
 * @return {ReturnComparisonFilesToAssets}
 */
export const comparisonFilesToAssets: ComparisonFilesToAssets = (assets, files) => {
	const INITIAL_RETURN_VALUES: ReturnComparisonFilesToAssets = {
		hasFiles: [],
		deleteAssets: [],
		addFiles: [],
	};

	const assetsPK = new Set(assets.map((asset) => `${asset.name}-${asset.size}-${asset.contentType}`));

	const filesPK = new Set(files.map((file) => `${file.name}-${file.size}-${file.type}`));

	const result = assets.reduce((acc, asset) => {
		const assetKey = `${asset.name}-${asset.size}-${asset.contentType}`;
		if (filesPK.has(assetKey)) {
			const matchingFile = files.find((file) => `${file.name}-${file.size}-${file.type}` === assetKey);
			if (matchingFile) acc.hasFiles.push(matchingFile);
		} else {
			acc.deleteAssets.push(asset);
		}
		return acc;
	}, INITIAL_RETURN_VALUES);

	files.forEach((file) => {
		const fileKey = `${file.name}-${file.size}-${file.type}`;
		if (!assetsPK.has(fileKey)) {
			result.addFiles.push(file);
		}
	});

	return result;
};
