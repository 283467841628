import { useQuery } from '@tanstack/react-query';

import { api } from '..';

export const faqKeys = {
	all: ['faq'] as const,
	getList: () => [...faqKeys.all, 'list'] as const,
};

export const useGetFAQList = () => {
	return useQuery({
		queryKey: faqKeys.getList(),
		queryFn: async () => await api.faq.getList(),
	});
};
