import { type CategoryOptions } from './components/Options';

interface FindLabel<T extends object> {
	options: T[] | CategoryOptions<T>;
	selectKey?: keyof T;
	label?: keyof T;
	value?: T[keyof T] | Array<T[keyof T]>;
	multiple?: { allKey?: T[keyof T]; label?: string };
}

export const findLabel = <T extends object>(props: FindLabel<T>) => {
	const { value, selectKey = 'key', options, label = 'label', multiple } = props;
	let foundLabel: string | null = null;

	const searchOptions = (options: T[]) => {
		const target = Array.isArray(value) ? value[0] : value;

		return options.find((option) => option[selectKey as keyof T] === target);
	};

	const findLabel = () => {
		if (options.length > 0 && 'category' in options[0]) {
			for (const categoryOption of options as CategoryOptions<T>) {
				const foundOption = searchOptions(categoryOption.options);
				if (foundOption) {
					foundLabel = foundOption[label as keyof T] as string;
					break;
				}
			}
		} else {
			const foundOption = searchOptions(options as T[]);
			if (foundOption) {
				foundLabel = foundOption[label as keyof T] as string;
			}
		}
	};

	if (Array.isArray(value)) {
		if (value[0] === multiple?.allKey) {
			foundLabel = '전체 ' + multiple?.label;
		} else if (value.length > 1) {
			foundLabel = '다중' + multiple?.label + `(${value.length}개)`;
		} else {
			findLabel();
		}
	} else {
		findLabel();
	}

	return foundLabel;
};
