interface CustomHistoryState<T> {
	page?: Record<string, T>;
}

type HistoryState<T> = CustomHistoryState<T> | null;

type ReplaceState = (state: any, title: string | null, url?: string | URL | null | undefined) => void;

interface HistoryStorageAPI<T> {
	replaceState: ReplaceState;
	get: () => T | undefined;
	set: (value: T, replace: boolean) => void;
}

export class HistoryStorage<T> implements HistoryStorageAPI<T> {
	private readonly history: History;
	private readonly originalReplaceState: ReplaceState;

	private readonly key: string;

	constructor(key: string) {
		this.history = typeof window !== 'undefined' ? window.history : ({} as History);
		this.originalReplaceState = history.replaceState.bind(history) as any;
		this.key = key;
	}

	replaceState(state: any, title: string | null, url?: string | URL | null | undefined): void {
		this.originalReplaceState({ ...this.history.state, ...state }, title ?? document.title, url);
	}

	get(): T | undefined {
		return (this.history.state as CustomHistoryState<T>)?.page?.[this.key];
	}

	set(value: T, replace: boolean = false): void {
		const currentState: HistoryState<T> = this.history.state;
		const currentPage = currentState?.page ?? {};

		this.replaceState(
			{ page: replace ? { [this.key]: value } : { ...currentPage, [this.key]: value } },
			document.title,
		);
	}
}

// 과거 코드
// export const historyStorage = ((history: History) => {
// 	const originalReplaceState = history.replaceState.bind(history);

// 	const replaceState = (
// 		state: CustomHistoryState,
// 		title: string | null,
// 		url?: string | URL | null | undefined,
// 	): void => {
// 		originalReplaceState({ ...history.state, ...state }, title ?? document.title, url);
// 	};

// 	const get = <T>(key: string): T => (history.state as CustomHistoryState)?.page?.[key];

// 	const set = <T>(key: string, value: T, replace: boolean = false): void => {
// 		const currentState: HistoryState = history.state;
// 		const currentPage = currentState?.page ?? {};

// 		replaceState(
// 			{
// 				page: replace ? { [key]: value } : { ...currentPage, [key]: value },
// 			},
// 			document.title,
// 		);
// 	};

// 	return { set, get, replaceState };
// })(typeof window !== 'undefined' ? window.history : ({} as History));
