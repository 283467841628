import React, { useMemo } from 'react';

import styled, { css } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { CustomDatePicker } from '../../../lib/reactDatepicker/CustomDatePicker';
import { type Period } from '../../../services/types';
import { createDateOffset, formatDate } from '../../../utils/format';
import { Button } from '../../Buttons';
import { type FlexDirection } from '../types';

type Key = 'start' | 'end' | 'all';

export interface PeriodDateProps {
	start: Date | null;
	end: Date | null;
	onChange: (date: Date | Period, key: Key) => void;
	periodDirection?: FlexDirection;
	isAll?: boolean;
}

interface PeriodOption {
	label: string;
	start: Date | null;
	end: Date | null;
}

type PeriodOptions = PeriodOption[];

export const PeriodDate = (props: PeriodDateProps) => {
	const { start, end, onChange, periodDirection = 'ROW', isAll = true } = props;

	const createPeriodOptions = (isAll: boolean): PeriodOptions => {
		const now = new Date();
		const result: PeriodOptions = [
			{
				label: '당일',
				start: now,
				end: now,
			},
			{
				label: '1주일',
				start: createDateOffset(now, { days: -7 }),
				end: now,
			},
			{
				label: '1개월',
				start: createDateOffset(now, { months: -1 }),
				end: now,
			},
			{
				label: '6개월',
				start: createDateOffset(now, { months: -6 }),
				end: now,
			},
		];

		if (isAll) {
			result.unshift({
				label: '전체 ',
				start: null,
				end: null,
			});
		}

		return result;
	};

	const period = useMemo(() => createPeriodOptions(isAll), [isAll]);

	return (
		<PeriodDateWrapper $periodDirection={periodDirection}>
			<div className="datepicker__wrap">
				<CustomDatePicker
					selected={start}
					onChange={(date) => {
						if (!date) return;
						if (end && date > end) {
							onChange({ start: date, end: null }, 'all');
						} else {
							onChange(date, 'start');
						}
					}}
					placeholderText="시작일"
					selectsStart
					startDate={start}
					endDate={end}
					maxDate={new Date()}
				/>
				<i className="stick" />
				<CustomDatePicker
					selected={end}
					onChange={(date) => {
						if (!date) return;
						onChange(date, 'end');
					}}
					placeholderText="종료일"
					selectsEnd
					startDate={start}
					endDate={end}
					minDate={start}
					maxDate={new Date()}
				/>
			</div>
			<div className="period__selector">
				{period.map((el) => {
					const isActive = formatDate(start) === formatDate(el.start) && formatDate(end) === formatDate(el.end);
					return (
						<Button
							key={'period-' + el.label}
							buttonType="LINE"
							size={{ $fontSize: 'M', $paddingSize: 'M' }}
							color={isActive ? 'PRIMARY' : 'SECONDARY'}
							onClick={() => {
								onChange({ start: el.start, end: el.end }, 'all');
							}}
						>
							{el.label}
						</Button>
					);
				})}
			</div>
		</PeriodDateWrapper>
	);
};

const PeriodDateWrapper = styled.div<{ $periodDirection: FlexDirection }>`
	.datepicker__wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.8rem;
		.stick {
			display: inline-block;
			width: 0.6rem;
			height: 0.1rem;
			background-color: ${({ theme }) => theme.colors.gray.gray_600};
		}
	}
	.period__selector {
		display: flex;
		${({ $periodDirection }) =>
			$periodDirection === 'ROW'
				? css`
						margin-top: 0.8rem;
						flex-direction: row;
						gap: 0.8rem;
						> button {
							flex: 1;
							word-break: keep-all;
							text-align: center;
						}
					`
				: css`
						margin-top: 1.2rem;
						flex-direction: column;
						gap: 1.2rem;
					`}
	}
`;
