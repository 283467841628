/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { useMemo, useReducer } from 'react';

import { type SendableMessage } from '@sendbird/chat/lib/__definition';
import { type BaseMessage, SendingStatus } from '@sendbird/chat/message';

import {
	arrayToMapWithGetter,
	getMessageUniqId,
	isMyMessage,
	isNewMessage,
	isSendableMessage,
	type SendbirdMessage,
} from './utils';

type Action =
	| {
			type: 'update_initialized' | 'update_loading' | 'update_refreshing';
			value: { status: boolean };
	  }
	| {
			type: 'update_messages' | 'update_new_messages';
			value: {
				messages: BaseMessage[];
				clearBeforeAction: boolean;
				currentUserId?: string;
			};
	  }
	| {
			type: 'delete_messages';
			value: { messageIds: number[]; reqIds: string[] };
	  };

interface State {
	initialized: boolean;
	loading: boolean;
	refreshing: boolean;
	messageMap: Record<string, BaseMessage>;
	newMessageMap: Record<string, BaseMessage>;
}

const defaultReducer = ({ ...draft }: State, action: Action) => {
	switch (action.type) {
		case 'update_initialized': {
			draft.initialized = action.value.status;
			return draft;
		}
		case 'update_refreshing': {
			draft.refreshing = action.value.status;
			return draft;
		}
		case 'update_loading': {
			draft.loading = action.value.status;
			return draft;
		}
		case 'update_messages': {
			const userId = action.value.currentUserId;

			if (action.value.clearBeforeAction) {
				draft.messageMap = messagesToObject(action.value.messages);
			} else {
				// Filtering meaningless message updates
				const nextMessages = action.value.messages.filter((next) => {
					if (isMyMessage(next, userId)) {
						const prev = draft.messageMap[next.reqId] ?? draft.messageMap[next.messageId];
						if (isMyMessage(prev, userId)) {
							const shouldUpdate = shouldUpdateMessage(prev, next);
							// 서버에서 보내는 기능 메시지는 status 가 항상 SUCCEEDED 라서
							// prev.messageId === next.messageId 를 넣어줘야 같은 기능 메시지에서 메시지 UI가 덮어씌워지지 않는다
							if (shouldUpdate && prev.messageId === next.messageId) {
								// Remove existing messages before update to prevent duplicate display
								delete draft.messageMap[prev.reqId];
								delete draft.messageMap[prev.messageId];
							}
							return shouldUpdate;
						}
					}
					return true;
				});

				const obj = messagesToObject(nextMessages);
				draft.messageMap = { ...draft.messageMap, ...obj };
			}

			return draft;
		}
		case 'update_new_messages': {
			const userId = action.value.currentUserId;
			const newMessages = action.value.messages.filter((it) => isNewMessage(it, userId));

			if (action.value.clearBeforeAction) {
				draft.newMessageMap = arrayToMapWithGetter(newMessages, getMessageUniqId);
			} else {
				// Remove existing messages before update to prevent duplicate display
				const messageKeys = newMessages.map((it) => it.messageId);
				messageKeys.forEach((key) => delete draft.newMessageMap[key]);

				draft.newMessageMap = {
					...draft.newMessageMap,
					...arrayToMapWithGetter(newMessages, getMessageUniqId),
				};
			}

			return draft;
		}
		case 'delete_messages': {
			const key = 'messageMap';
			draft[key] = { ...draft[key] };
			action.value.messageIds.forEach((msgId) => {
				const message = draft[key][msgId];
				if (message) {
					if (isSendableMessage(message)) delete draft[key][message.reqId];
					delete draft[key][message.messageId];
				}
			});
			action.value.reqIds.forEach((reqId) => {
				const message = draft[key][reqId];
				if (message) {
					if (isSendableMessage(message)) delete draft[key][message.reqId];
					delete draft[key][message.messageId];
				}
			});

			return draft;
		}
	}
};

const messagesToObject = (messages: BaseMessage[]) => {
	return messages.reduce<Record<string, BaseMessage>>((accum, curr) => {
		if (isSendableMessage(curr)) {
			accum[curr.reqId] = curr;
			if (curr.sendingStatus === SendingStatus.SUCCEEDED) {
				accum[curr.messageId] = curr;
			}
		} else {
			accum[curr.messageId] = curr;
		}
		return accum;
	}, {});
};

const shouldUpdateMessage = (prev: SendableMessage, next: SendableMessage) => {
	// message data update (e.g. reactions) (현재 상관 없을듯)
	if (prev.sendingStatus === SendingStatus.SUCCEEDED) return next.sendingStatus === SendingStatus.SUCCEEDED;

	// message sending status update
	return prev.sendingStatus !== next.sendingStatus;
};

export const useChannelMessagesReducer = (sortComparator = defaultMessageComparator) => {
	const [{ initialized, loading, refreshing, messageMap, newMessageMap }, dispatch] = useReducer(defaultReducer, {
		initialized: false,
		loading: true,
		refreshing: false,
		messageMap: {},
		newMessageMap: {},
	});
	const updateMessages = (messages: BaseMessage[], clearBeforeAction: boolean, currentUserId?: string) => {
		dispatch({
			type: 'update_messages',
			value: { messages, clearBeforeAction, currentUserId },
		});
	};
	const deleteMessages = (messageIds: number[], reqIds: string[]) => {
		dispatch({ type: 'delete_messages', value: { messageIds, reqIds } });
	};
	const updateNewMessages = (messages: BaseMessage[], clearBeforeAction: boolean, currentUserId?: string) => {
		dispatch({
			type: 'update_new_messages',
			value: { messages, clearBeforeAction, currentUserId },
		});
	};
	const updateInitialized = (status: boolean) => {
		dispatch({ type: 'update_initialized', value: { status } });
	};
	const updateLoading = (status: boolean) => {
		dispatch({ type: 'update_loading', value: { status } });
	};
	const updateRefreshing = (status: boolean) => {
		dispatch({ type: 'update_refreshing', value: { status } });
	};

	const newMessages = Object.values(newMessageMap);
	const messages = useMemo(() => Array.from(new Set(Object.values(messageMap))).sort(sortComparator), [messageMap]);

	return {
		updateInitialized,
		updateLoading,
		updateRefreshing,
		updateMessages,
		deleteMessages,

		initialized,
		loading,
		refreshing,
		messages,

		newMessages,
		updateNewMessages,
	};
};

const LARGE_OFFSET = Math.floor(Number.MAX_SAFE_INTEGER / 10);

/**
 *
 * @description 메시지 createdAt 순으로 sorting
 * 전송되지 못한 메시지는 가장 하단에 남게 된다
 */
export function defaultMessageComparator(a: SendbirdMessage, b: SendbirdMessage) {
	let aStatusOffset = 0;
	let bStatusOffset = 0;

	if (isSendableMessage(a) && a.sendingStatus !== 'succeeded') aStatusOffset = LARGE_OFFSET;
	if (isSendableMessage(b) && b.sendingStatus !== 'succeeded') bStatusOffset = LARGE_OFFSET;

	return a.createdAt + aStatusOffset - (b.createdAt + bStatusOffset);
}
