import { useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
	type PostGroupsRequest,
	type Group,
	type PostGroupMembersRequest,
	type GetGroupDetailRequest,
	type GetGroupMembersRequest,
	type GetGroupsRequest,
	type PutGroupDetailRequest,
	type GroupCategory,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import context from '../../context';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';
import { type Usage, type PaginationRequest } from '../types';

export const groupsKeys = {
	all: ['groups'] as const,
	getGroups: (queries: PaginationRequest<Group>, pagination: PaginationQueries) =>
		[...groupsKeys.all, 'getGroups', queries, pagination] as const,
	getGroupDetail: ({ storeId, groupId }: GetGroupDetailRequest) =>
		[...groupsKeys.all, 'detail', storeId, groupId] as const,
	getGroupMembers: (
		{ storeId, groupId }: GetGroupDetailRequest,
		queries: GetGroupMembersRequest,
		pagination: PaginationQueries,
	) => [...groupsKeys.all, 'members', storeId, groupId, queries, pagination] as const,
	getGroupCategoriesUsage: (storeId: string) => [...groupsKeys.all, 'usage', storeId] as const,
} as const;

/**
 * @param props
 * @param props.initListCount - pagination initial list count
 * @returns
 */
export const useGetGroups = (props?: { initListCount?: number }) => {
	const { initListCount = 30 } = props ?? {};
	const { userInfo } = context.user.useValue();
	const initQueries: GetGroupsRequest = useMemo(
		() => ({
			storeId: userInfo.storeId,
			name: '',
			category: 'ALL',
			sorts: {
				property: null,
				direction: null,
			},
		}),
		[userInfo.storeId],
	);
	const queries = useValues(initQueries);
	const pagination = usePagination({ initListCount });
	const { curPage, listCount, setPagination } = pagination;

	const queryInfo = useQuery({
		queryKey: groupsKeys.getGroups(queries.values, { curPage, listCount }),
		queryFn: async () => await api.groups.getGroups({ limit: listCount, page: curPage, ...queries.values }),
		enabled: !!userInfo?.storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.isSuccess, queryInfo.data]);

	return { ...queryInfo, pagination, queries };
};

export const usePostGroups = (props?: MutateCallback<PostGroupsRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PostGroupsRequest) => await api.groups.postGroups(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetGroupMembers = (props: GetGroupDetailRequest) => {
	const { storeId, groupId } = props;
	const pagination = usePagination();
	const { curPage, setPagination, listCount } = pagination;
	const initQueries: GetGroupMembersRequest = useMemo(() => ({ sorts: { direction: null, property: null } }), []);
	const queries = useValues(initQueries);
	const queryInfo = useQuery({
		queryKey: groupsKeys.getGroupMembers(props, queries.values, { curPage, listCount }),
		queryFn: async () =>
			await api.groups.getGroupMembers({ ...props, ...queries.values, limit: listCount, page: curPage }),
		enabled: !!storeId && !!groupId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.isSuccess, queryInfo.data]);

	return { ...queryInfo, queries, pagination };
};

export const usePostGroupMembers = (props?: MutateCallback<PostGroupMembersRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PostGroupMembersRequest) => await api.groups.postGroupMembers(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useDeleteGroupMembers = (props?: MutateCallback<PostGroupMembersRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PostGroupMembersRequest) => await api.groups.deleteGroupMembers(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetGroupDetail = (props: GetGroupDetailRequest) => {
	const { storeId, groupId } = props;
	const queryInfo = useQuery({
		queryKey: groupsKeys.getGroupDetail(props),
		queryFn: async () => await api.groups.getGroupDetail(props),
		enabled: !!storeId && !!groupId,
	});

	return queryInfo;
};

export const usePutGroupDetail = (props?: MutateCallback<PutGroupDetailRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PutGroupDetailRequest) => await api.groups.putGroupDetail(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useDeleteGroupDetail = (props?: MutateCallback<GetGroupDetailRequest, any>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: GetGroupDetailRequest) => await api.groups.deleteGroupDetail(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetGroupCategoriesUsage = (storeId: string) => {
	const queryInfo = useQuery({
		queryKey: groupsKeys.getGroupCategoriesUsage(storeId),
		queryFn: async () => await api.groups.getGroupCategoriesUsage(storeId),
		enabled: !!storeId,
	});

	const reduceData = useMemo(() => {
		const usage: Record<GroupCategory, Usage> = {
			DISEASE: { count: 0, limit: 0 },
			NORMAL: { count: 0, limit: 0 },
			ALL: { count: 0, limit: 0 },
		};

		queryInfo.data?.forEach((item) => {
			usage[item.category].count += item.groupCount;
			usage[item.category].limit += item.groupLimit;
			usage.ALL.count += item.groupCount;
			usage.ALL.limit += item.groupLimit;
		});

		return usage;
	}, [queryInfo.data]);

	return { ...queryInfo, reduceData };
};
