import React, { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import * as STRING_SET from './consts';
import { formProps, ORDERED_SNS } from './consts';
import { SnsInput } from './SNSInput';
import { ToggleButton, type ToggleKey } from './ToggleButton';
import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { Button, IconButton } from '../../../../components/Buttons';
import { Flex, Footer, InsideTitle, Loading, VStack } from '../../../../components/Common';
import { Icon, IconID, InfoText, Label, MarkInfoIcon, Tag } from '../../../../components/Display';
import { FormItem, Radio, TextArea, TextField, ImageInputs } from '../../../../components/Forms';
import { ImageInfoText } from '../../../../components/Forms/ImageInputs/ImageInfoText';
import { AlertModal, Modal } from '../../../../components/Modals';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { useMutateWithAssets } from '../../../../services/asset/queries';
import { useGetPharmProfile, usePutPharmProfile } from '../../../../services/pharm/queries';
import { type PutPharmProfileRequest } from '../../../../services/pharm/types';
import { areDeeplyEqual } from '../../../../utils/validators';
import { replaceNull } from '../replaceNullValues';

interface ProfileProps {
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Profile = ({ setIsEditing }: ProfileProps) => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const { data, isLoading } = useGetPharmProfile({ storeId: userInfo.storeId });
	const { mutate, isPending } = usePutPharmProfile({ storeId: userInfo.storeId });

	const { values, dispatch, onChangeValues } = useValues<PutPharmProfileRequest>({} as PutPharmProfileRequest);
	const [keywordValue, setKeywordValue] = useState('');

	const onChangeKeywordValue = useCallback((e: ChangeEvent<HTMLInputElement> | 'RESET') => {
		if (e === 'RESET') {
			setKeywordValue('');
		} else {
			setKeywordValue(e.target.value);
		}
	}, []);

	const onKeyDownKeyword = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const { name, value } = e.currentTarget;
			if (
				!e.shiftKey &&
				e.key === 'Enter' &&
				(value || '').trim().length > 0 &&
				!e?.nativeEvent?.isComposing &&
				values.keywords.length < 5
			) {
				e.preventDefault();
				onChangeValues({
					target: { name, value: [...values.keywords, value] },
				} as unknown as React.ChangeEvent<HTMLInputElement>);
				onChangeKeywordValue('RESET');
			}
		},
		[values],
	);

	const { handleMutateWithAssets, isAssetPending } = useMutateWithAssets<PutPharmProfileRequest>({
		assetKey: 'assetIds',
		params: values,
		callback: (value) => {
			mutate(value, {
				onSuccess: () => {
					setAddImages([]);
					toast.success(STRING_SET.ON_SUCCESS);
				},
			});
		},
	});

	const [addImages, setAddImages] = useState<File[]>([]);
	const memoized = useMemo(() => {
		if (data) {
			const { assets, snsUrls: snsRes, ...rest } = replaceNull(data);
			const snsUrls = snsRes.map(({ type, url }) => ({ type, url }));
			return { assetIds: [], deleteAssetIds: [], snsUrls, ...rest };
		} else {
			return {};
		}
	}, [data]);

	useEffect(() => {
		if (memoized) {
			dispatch('SET', {
				...memoized,
			});
		}
	}, [memoized]);

	useEffect(() => {
		const isEditing = !areDeeplyEqual(values, memoized) || addImages.length > 0;
		setIsEditing((prev) => (prev !== isEditing ? isEditing : prev));
	}, [values, addImages]);

	if (isLoading || Object.keys(values).length < 1) {
		return <></>;
	}

	const handleSubmit = () => {
		if (values?.briefIntro.length < 20) {
			handleOpen(<AlertModal message={STRING_SET.BRIEF_VALID_ALERT} />);
		}

		handleMutateWithAssets(addImages);
	};

	const handleToggleButton = (name: ToggleKey, value: boolean) => {
		onChangeValues({
			target: { value, name },
		} as unknown as React.ChangeEvent<HTMLInputElement>);
	};

	return (
		<Container>
			{(isAssetPending || isPending) && <Loading />}
			<Wrapper>
				<Content>
					<InsideTitle title={'약국 소개'} isBorder={true} />
					<ContentInner $gap={'4.8rem'}>
						<VStack $gap={'1.6rem'} $width={'100%'}>
							<FormItem
								label={formProps.mainServiceType.label}
								isRequired={true}
								renderLabelSuffix={
									<IconButton
										onClick={() => {
											handleOpen(
												<Modal header={{ title: '대표 서비스란?' }}>
													<img width={480} src={'/images/others/main_service_info.png'} alt={'main_service_info'} />
												</Modal>,
											);
										}}
										width={'1.3rem'}
										height={'1.3rem'}
									>
										<Icon id={IconID.MARK_QUESTION} width={'1.3rem'} height={'1.3rem'} />
									</IconButton>
								}
							>
								<Radio<PutPharmProfileRequest['mainServiceType']>
									{...formProps.mainServiceType.inputProps}
									onChange={onChangeValues}
									selectValue={values?.mainServiceType}
								/>
							</FormItem>
							<FormItem
								label={formProps.briefIntro.label}
								isRequired={true}
								statusMessage={
									values?.briefIntro && values?.briefIntro.length < 20 ? STRING_SET.BRIEF_VALID_MESSAGE : undefined
								}
							>
								<TextField {...formProps.briefIntro.inputProps} value={values?.briefIntro} onChange={onChangeValues} />
							</FormItem>
							<FormItem label={formProps.detailIntro.label}>
								<TextArea {...formProps.detailIntro.inputProps} value={values?.detailIntro} onChange={onChangeValues} />
							</FormItem>
						</VStack>
						<VStack $gap={'1.6rem'} $width={'100%'}>
							<FormItem label={'대표 키워드'}>
								<Flex $gap={'0.8rem'}>
									<ToggleButton name={'isLateNight'} isChecked={values?.isLateNight} onChange={handleToggleButton} />
									<ToggleButton name={'isYearRound'} isChecked={values?.isYearRound} onChange={handleToggleButton} />
									<ToggleButton name={'isAnimalMeds'} isChecked={values?.isAnimalMeds} onChange={handleToggleButton} />
								</Flex>
							</FormItem>
							<FormItem label={formProps.keywords.label}>
								<VStack>
									<TextField
										{...formProps.keywords.inputProps}
										renderPrefix={<Icon id={IconID.MARK_HASH} width={'1.6rem'} height={'1.6rem'} />}
										onChange={onChangeKeywordValue}
										value={keywordValue}
										onKeyDown={onKeyDownKeyword}
									/>
									<Flex $gap={'0.8rem'} $padding={'0.8rem 0'} $wrap="wrap">
										{(values?.keywords || []).map((wd, idx) => (
											<Tag color={'PRIMARY'} size={'S'} type={'FILL'} key={idx}>
												<>
													{wd}
													<IconButton
														onClick={() => {
															const cur = values.keywords;
															onChangeValues({
																target: {
																	name: 'keywords',
																	value: [...cur.slice(0, idx), ...cur.slice(idx + 1)],
																},
															} as unknown as React.ChangeEvent<HTMLInputElement>);
														}}
														width={'1.2rem'}
														height={'1.2rem'}
													>
														<Icon id={IconID.BTN_CLOSE} defaultColor={'gray_600'} width={'1.2rem'} height={'1.2rem'} />
													</IconButton>
												</>
											</Tag>
										))}
									</Flex>
									<Flex $gap={'0.4rem'} $alignItems={'center'} $padding={'0.8rem 0'}>
										<MarkInfoIcon type={'LINE'} bgColor={'gray_700'} color={'white'} />
										<Label $color={'gray_700'} $fontStyle={'body_3'}>
											{`${STRING_SET.KEYWORD_INFO_MESSAGE} (${values?.keywords?.length ?? 0}/5개)`}
										</Label>
									</Flex>
								</VStack>
							</FormItem>
						</VStack>
						<FormItem label={'대표 사진'}>
							<VStack $gap="1.6rem">
								<ImageInputs
									defaultAssets={data?.assets ?? []}
									addImages={addImages}
									setAddImages={setAddImages}
									onDeleteImages={(id) => {
										const updated = [...values.deleteAssetIds, id];
										onChangeValues({
											target: { name: 'deleteAssetIds', value: updated },
										} as unknown as React.ChangeEvent<HTMLInputElement>);
									}}
								/>
								<InfoText
									style={{ padding: '1.6rem', width: '58rem' }}
									text={'권장 크기 : 1,125 * 900px'}
									color={{ background: 'gray_100', font: 'primary_700' }}
									font="label_2"
									innerStyle={{ alignItems: 'center', gap: '0.4rem' }}
									suffix={<ImageInfoText />}
								/>
							</VStack>
						</FormItem>
					</ContentInner>
				</Content>
				<Content>
					<InsideTitle title={'상세 정보'} isBorder={true} />
					<ContentInner>
						<VStack $gap={'2rem'} $width={'100%'}>
							<FormItem label={formProps.direction.label}>
								<TextField {...formProps.direction.inputProps} value={values?.direction} onChange={onChangeValues} />
							</FormItem>
							<FormItem label={formProps.paymentMethod.label}>
								<TextArea
									{...formProps.paymentMethod.inputProps}
									value={values?.paymentMethod}
									onChange={onChangeValues}
								/>
							</FormItem>
							<FormItem label={formProps.medsHandled.label}>
								<TextArea {...formProps.medsHandled.inputProps} value={values.medsHandled} onChange={onChangeValues} />
							</FormItem>
							<FormItem label={'편의시설 및 주차'}>
								<Flex $gap={'0.8rem'}>
									<ToggleButton name={'isParking'} isChecked={values?.isParking} onChange={handleToggleButton} />
									<ToggleButton
										name={'isPetFriendly'}
										isChecked={values?.isPetFriendly}
										onChange={handleToggleButton}
									/>
									<ToggleButton name={'isWheelchair'} isChecked={values?.isWheelchair} onChange={handleToggleButton} />
								</Flex>
							</FormItem>
							<FormItem label={'SNS 계정'}>
								{ORDERED_SNS.map((type, idx) => {
									const value = values?.snsUrls?.find((sns) => sns?.type === type)?.url ?? '';
									return (
										<SnsInput
											key={idx}
											type={type}
											value={value}
											onChange={(type, value) => {
												const updated = (values?.snsUrls || []).map((sns) =>
													sns.type === type
														? {
																type,
																url: value,
															}
														: sns,
												);
												onChangeValues({
													target: { value: updated, name: 'snsUrls' },
												} as unknown as React.ChangeEvent<HTMLInputElement>);
											}}
										/>
									);
								})}
							</FormItem>
						</VStack>
					</ContentInner>
				</Content>
			</Wrapper>
			<Footer>
				<Button style={{ width: '20rem' }} shouldPrevent={true} onClick={handleSubmit}>
					{'저장하기'}
				</Button>
			</Footer>
		</Container>
	);
};

const Container = styled(VStack)`
	align-self: stretch;
	height: 100%;
	width: 100%;
`;

const Wrapper = styled(VStack)`
	align-self: stretch;
	gap: 4.8rem;
	flex: 1 1 0;
	${scrollStyle()}
`;

const Content = styled(VStack)`
	&:not(:first-child) {
		padding-top: 1.2rem;
	}
`;

const ContentInner = styled(VStack)`
	max-width: 76.8rem;
	padding: 2.4rem 0;
	align-items: flex-start;
	align-self: stretch;
`;
