import { type DescriptionProps } from '../../../../components/Display';
import { type GetMemberTotalPayment, type GetMemberInfo } from '../../../../services/member/types';
import { type Gender } from '../../../../services/types';
import { GENDER_VALUES } from '../../../../utils/consts';
import { formatBirth, formatPhoneNumber } from '../../../../utils/format';

export const informationRows: DescriptionProps<GetMemberInfo>['rows'] = [
	{ key: 'name', label: '회원명' },
	{ key: 'birth', label: '생년월일' },
	{ key: 'gender', label: '성별', renderContent: (_, value) => GENDER_VALUES[value as Gender] },
	{ key: 'phone', label: '전화번호', renderContent: (_, value) => formatPhoneNumber(String(value)) },
	{ key: 'registrationAt', label: '약국등록일', renderContent: (_, value) => formatBirth(String(value)) },
];

export const paymentRows: DescriptionProps<GetMemberTotalPayment>['rows'] = [
	{ key: 'paymentCount', label: '결제 횟수', renderContent: (_, value) => value.toLocaleString() + '회' },
	{ key: 'paymentAmountTotal', label: '총 결제 금액', renderContent: (_, value) => value.toLocaleString() + '원' },
	{
		key: 'latestPaymentAt',
		label: '최근 결제일',
		renderContent: (_, value) => formatBirth(value as string),
	},
	{ key: 'latestPaymentAmount', label: '최근 결제 금액', renderContent: (_, value) => value.toLocaleString() + '원' },
];
