import React, { useMemo } from 'react';

import styled from 'styled-components';

import { scrollStyle } from '../../../../../assets/styles/scrollStyle';
import { Icon, IconID } from '../../../../../components/Display';
import { Modal, type ModalProps } from '../../../../../components/Modals/Modal';
import { type BasicMemberInfo } from '../../../../../services/member/types';
import { GENDER_VALUES } from '../../../../../utils/consts';
import { formatPhoneNumber } from '../../../../../utils/format';

interface TargetModalProps extends ModalProps {
	data: BasicMemberInfo[];
}
export const TargetModal = ({ data, ...rest }: TargetModalProps) => {
	const sortData = useMemo(() => data.sort((a, b) => (a.name > b.name ? 1 : -1)), [data]);

	return (
		<Modal header={{ title: '전송 대상 목록' }} {...rest}>
			<Container>
				{sortData.length === 0 && <p className="empty">전송 대상이 없습니다.</p>}
				{sortData.map((user) => (
					<li key={'selected__user--' + user.userId} className="item">
						<Icon id={IconID.MEMBER_CIRCLE} width="3.2rem" height="3.2rem" />
						<div className="item--content">
							<p className="item--content--title">{user.name}</p>
							<ul className="item--content--list">
								<li>{user.birth}</li>
								<li>{GENDER_VALUES[user.gender]}</li>
								<li>{formatPhoneNumber(user.phone)}</li>
							</ul>
						</div>
					</li>
				))}
			</Container>
		</Modal>
	);
};

const Container = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	width: 48rem;
	min-height: 20rem;
	max-height: 61.8rem;
	${scrollStyle({ hideHorizontal: true })}
	.empty {
		margin: auto;
		${({ theme }) => theme.font.label.label_2};
		color: ${({ theme }) => theme.colors.gray.gray_700};
	}
	.item {
		display: flex;
		gap: 0.8rem;
		align-items: center;
		padding: 0.8rem 2rem;
		&--content {
			&--title {
				${({ theme }) => theme.font.body.body_2};
				font-weight: 700;
			}
			&--list {
				display: flex;
				gap: 0.6rem;
				align-items: center;
				li {
					display: flex;
					gap: 0.6rem;
					align-items: center;
					${({ theme }) => theme.font.caption.caption_1};
					color: ${({ theme }) => theme.colors.gray.gray_700};
					&:not(:last-child)::after {
						content: '';
						display: inline-block;
						width: 0.1rem;
						height: 1rem;
						background-color: ${({ theme }) => theme.colors.gray.gray_300};
					}
				}
			}
		}
	}
`;
