import {
	type PromotionPK,
	type GetPromotionsReq,
	type GetPromotionsRes,
	type PromotionDetail,
	type GetPromotionCalculatesReq,
	type GetPromotionCalculatesRes,
	type PatchPromotionDeliveryParams,
} from './types';
import { Get, Patch } from '..';
import { formatQueryString } from '../../utils/format';

export const promotions = {
	getPromotions: async <T = GetPromotionsRes>({ storeId, ...rest }: GetPromotionsReq): Promise<T> =>
		(await Get<T>(`/pharms/${storeId}/promotions${formatQueryString(rest)}`)).data,
	getPromotion: async <T = PromotionDetail>({ id }: PromotionPK): Promise<T> =>
		(await Get<T>(`/promotions/${id}`)).data,
	getPromotionCalculates: async <T = GetPromotionCalculatesRes>({ storeId, ...rest }: GetPromotionCalculatesReq) =>
		(await Get<T>(`/pharms/${storeId}/promotion-calculates${formatQueryString(rest)}`)).data,
	patchPromotionDelivery: async <T = any>({
		storeId,
		id,
		isDeliveryAvailable,
	}: PatchPromotionDeliveryParams): Promise<T> => {
		const response = await Patch<T>(`/pharms/${storeId}/promotion/${id}/delivery`, { isDeliveryAvailable });
		return response.data;
	},
};
