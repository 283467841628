import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button } from '../../../../components/Buttons';
import { InsideTitle } from '../../../../components/Common';
import { Icon, IconID } from '../../../../components/Display';
import { FormItem, Select, TextArea, TextField } from '../../../../components/Forms';
import { ListTable } from '../../../../components/Table';
import context from '../../../../context';
import { useSelectedItems } from '../../../../hooks/useSelectedItems';
import { useValues } from '../../../../hooks/useValues';
import { useGetGroupCategoriesUsage, usePostGroups } from '../../../../services/groups/queries';
import { type PostGroupsRequest } from '../../../../services/groups/types';
import { type BasicMemberInfo } from '../../../../services/member/types';
import { type Sorts } from '../../../../services/types';
import { GROUP_CATEGORY_OPTIONS } from '../../../../utils/consts';
import onChangeSortDirection from '../../../../utils/onChangeSortDirection';
import { STATUS_MESSAGE } from '../const';
import { groupMemberTableColumns } from '../Detail/tableColumns';
import { MemberAddModal } from '../MemberAddModal';

const GroupCreate = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const navigate = useNavigate();

	const initValues: PostGroupsRequest = useMemo(
		() => ({
			category: undefined,
			name: '',
			description: '',
			userIds: [],
		}),
		[],
	);
	const { values, dispatch, onChangeValues } = useValues(initValues);

	const initTableSort: Sorts<BasicMemberInfo> = useMemo(() => ({ property: null, direction: null }), []);
	const [tableSort, setTableSort] = useState(initTableSort);

	const { mutate } = usePostGroups({
		onSuccess: () => {
			toast.success('그룹이 정상적을 생성되었습니다.');
			navigate(-1);
		},
		onError: () => {
			toast.error('그룹 생성에 실패하였습니다.');
		},
	});
	// table checkbox selected items
	const { selectedItems, handleSelectedItems } = useSelectedItems<BasicMemberInfo>({});

	// member add modal selected members
	const [selectedMembers, setSelectedMembers] = useState<BasicMemberInfo[]>([]);

	useEffect(() => {
		if (tableSort.property) {
			selectedMembers.sort((a, b) => {
				if (tableSort.property) {
					if (tableSort.direction === 'ASC') {
						if (a[tableSort.property] > b[tableSort.property]) {
							return -1;
						} else {
							return 1;
						}
					} else {
						if (a[tableSort.property] < b[tableSort.property]) {
							return -1;
						} else {
							return 1;
						}
					}
				} else {
					return 0;
				}
			});
		}
	}, [tableSort, selectedMembers]);

	const handleSelectedMembers = useCallback(
		(param: BasicMemberInfo[], type?: 'ADD' | 'DELETE') => {
			if (type === 'DELETE') {
				const filterMembers = selectedMembers.filter((el) => !param.includes(el));
				const userIds = filterMembers.map((user) => user.userId);
				handleSelectedItems('RESET');
				setSelectedMembers(filterMembers);
				dispatch('SET', { userIds });
			} else {
				const filterMembers = param.filter((el) => !selectedMembers.includes(el));
				const userIds = filterMembers.map((user) => user.userId);
				setSelectedMembers((prev) => [...prev, ...filterMembers]);
				dispatch('SET', { userIds: [...values.userIds, ...userIds] });
			}
		},
		[selectedMembers, values, selectedItems],
	);
	const { reduceData: usage } = useGetGroupCategoriesUsage(userInfo.storeId);

	const isValid = useMemo(() => {
		return values.category && values.name && usage[values.category].count < usage[values.category].limit;
	}, [values.category, values.name]);

	const onSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault();
			mutate({ storeId: userInfo?.storeId, ...values });
		},
		[values, userInfo],
	);

	return (
		<React.Fragment>
			<Container onSubmit={onSubmit}>
				<div className="top">
					<InsideTitle title="그룹 정보" />
					<div className="information">
						<FormItem
							label={'그룹명'}
							isRequired
							statusMessage={
								!!values.category && usage[values.category].count >= usage[values.category].limit
									? `${STATUS_MESSAGE.MAX_CATEGORY} (${usage[values.category].limit}/${usage[values.category].limit})`
									: ''
							}
						>
							<div style={{ display: 'flex', flex: '1', gap: '0.8rem' }}>
								<Select
									labelStyle={{ width: '12rem' }}
									options={GROUP_CATEGORY_OPTIONS.filter((option) => option.key !== 'ALL')}
									value={values.category}
									onClick={(key) => {
										dispatch('SET', { category: key } as Partial<PostGroupsRequest>);
									}}
									placeholder="카테고리"
								/>
								<TextField
									maxLength={10}
									name="name"
									value={values.name}
									onChange={onChangeValues}
									count={{ show: true, max: 10 }}
									placeholder="그룹명을 입력해주세요."
									style={{ flex: 1 }}
								/>
							</div>
						</FormItem>
						<FormItem label={'그룹설명'}>
							<TextArea
								maxLength={100}
								style={{ height: '9.6rem' }}
								count={{ show: true, max: 100 }}
								placeholder="그룹 설명을 입력해주세요."
								name="description"
								value={values.description}
								onChange={onChangeValues}
							/>
						</FormItem>
					</div>
				</div>
				<div className="bottom">
					<InsideTitle
						title="그룹 회원 목록"
						rightRender={
							<div className="inside__title--right__render">
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={() => {
										handleSelectedMembers(selectedItems, 'DELETE');
									}}
									disabled={selectedItems.length <= 0}
								>
									<Icon id={IconID.GROUP_MINUS} width="2rem" height="2rem" isDisabled={selectedItems.length <= 0} />
									그룹 해제
								</Button>
								<Button
									buttonType="LINE"
									color="SECONDARY"
									onClick={() => {
										handleOpen(<MemberAddModal handleSelectedMembers={handleSelectedMembers} />);
									}}
								>
									<Icon id={IconID.USER_PLUS} width="2rem" height="2rem" />
									회원 추가
								</Button>
							</div>
						}
					/>
					<ListTable
						data={selectedMembers}
						columns={groupMemberTableColumns}
						onCategoryClick={(column) => {
							setTableSort({ property: column.key, direction: onChangeSortDirection(tableSort.direction) });
						}}
						emptyMessage={
							<div style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem', alignItems: 'center' }}>
								<Icon id={IconID.ALERT_CIRCLE_MEDIUM} width="6rem" height="6rem" defaultColor="gray_600" />
								해당 그룹에 지정된 회원이 없습니다.
							</div>
						}
						selected={{ selectKey: 'userId', multiple: { selectedItems, handleSelectedItems } }}
						sortValues={tableSort}
					/>
				</div>
				<div className="button--wrap">
					<Button
						buttonType="LINE"
						color="SECONDARY"
						onClick={() => {
							navigate(-1);
						}}
					>
						취소
					</Button>
					<Button type="submit" disabled={!isValid} shouldPrevent={true}>
						저장
					</Button>
				</div>
			</Container>
		</React.Fragment>
	);
};

const Container = styled.form`
	display: flex;
	flex-direction: column;
	gap: 4rem;
	.top {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		.information {
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
	}
	.inside__title {
		&--right__render {
			display: flex;
			gap: 1rem;
		}
	}
	.button--wrap {
		display: flex;
		justify-content: center;
		gap: 0.8rem;
		padding-bottom: 2rem;
		button {
			width: 20rem;
		}
	}
`;

export default GroupCreate;
