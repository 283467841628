import styled, { css } from 'styled-components';

import { changeColorKeyToType } from '../../utils/changeColorKeyToType';
import { changeFontKeyToType } from '../../utils/changeFontKeyToType';

import type { StyledLabelProps } from '../Display';
import type { LegendType } from 'recharts/types/util/types';

export const ChartWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};

	.yAxis-label {
		${({ theme }) => theme.font.label.label_1};
		${({ theme }) => theme.font.weight.bold};
		fill: ${({ theme }) => theme.colors.black};
		&.left {
			text-anchor: end;
		}
		&.right {
			text-anchor: start;
		}
	}
	.yAxis-label-small {
		${({ theme }) => theme.font.caption.caption_1};
		${({ theme }) => theme.font.weight.medium};
		fill: ${({ theme }) => theme.colors.gray.gray_800};
	}
`;

export const ChartText = styled.text<StyledLabelProps>`
	${({ $fontStyle = 'label_2' }) => $fontStyle && changeFontKeyToType($fontStyle)};
	${({ theme, $fontWeight = 'medium' }) => $fontWeight && theme.font.weight[$fontWeight]}
	fill: ${({ $color = 'black' }) => $color && changeColorKeyToType($color)};
`;
export const ChartLegendIcon = styled.span<{ $type?: LegendType; $color?: string }>`
	width: 1.4rem;
	${({ $type }) => {
		switch ($type) {
			case 'plainline':
				return css`
					height: 0.25rem;
				`;
			case 'rect':
				return css`
					height: 1.2rem;
					width: 1.2rem;
					border-radius: 2px;
				`;
			case 'square':
			default:
				return css`
					height: 1.4rem;
				`;
		}
	}};

	background-color: ${({ $color }) => $color};
`;
