import {
	type PostTemplatesRequest,
	type DeleteTemplatesRequest,
	type GetTemplatesRequest,
	type GetTemplatesResponse,
	type PutTemplateDetailRequest,
	type Template,
	type GetTemplateDetailParams,
} from './types';
import { Delete, Get, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';
import { type Usage } from '../types';

export const templates = {
	getTemplates: async <T = GetTemplatesResponse>({ storeId, ...rest }: GetTemplatesRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/templates${formatQueryString(rest)}`);
		return response.data;
	},
	postTemplates: async <T = Template>({ storeId, ...rest }: PostTemplatesRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/templates`, rest);
		return response.data;
	},
	deleteTemplates: async <T = any>({ storeId, ...rest }: DeleteTemplatesRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/templates`, { data: rest });
		return response.data;
	},
	getTemplateDetail: async <T = Template>({ storeId, id }: GetTemplateDetailParams): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/templates/${id}`);
		return response.data;
	},
	putTemplateDetail: async <T = any>({ storeId, id, ...rest }: PutTemplateDetailRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/templates/${id}`, rest);
		return response.data;
	},
	getTemplatesUsage: async <T = Usage>(storeId: string): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/templates/usage`);
		return response.data;
	},
};
