import { type DefaultOption } from '../../../utils/consts';

export const TABLE_ROW_PADDING = '0 1rem' as const;

export const listCountOptions: Array<DefaultOption<number>> = [
	{ key: 10, label: '10개' },
	{ key: 30, label: '30개' },
	{ key: 50, label: '50개' },
	{ key: 100, label: '100개' },
] as const;
