import React, { useMemo, useRef } from 'react';

import styled, { css, type CSSProperties } from 'styled-components';

import { listCountOptions, TABLE_ROW_PADDING } from './constant';
import { handleMouseDown } from './resizeColumns';
import { type Column, type Columns } from './types';
import { scrollStyle } from '../../../assets/styles/scrollStyle';
import { type HandleSelectedItems } from '../../../hooks/useSelectedItems';
import { type Sorts } from '../../../services/types';
import { commaizeNumber } from '../../../utils/format';
import { Loading } from '../../Common';
import { Icon, IconID } from '../../Display';
import { Checkbox, Select } from '../../Forms';
import Pagination from '../Pagination';

import type usePagination from '../Pagination/hooks/usePagination';

interface ListTableProps<T extends object> {
	data: T[] | undefined;
	columns: Columns<T>;
	selected?: {
		selectedItem?: T | null;
		selectKey: keyof T;
		multiple?: {
			selectedItems: T[];
			handleSelectedItems: HandleSelectedItems<T>;
		};
	};
	pagination?: ReturnType<typeof usePagination>;
	isLoading?: boolean;
	emptyMessage?: string | React.ReactNode;
	unit?: string;
	headerLeftContent?: React.ReactNode;
	headerRightContent?: React.ReactNode;
	headerBottomContent?: React.ReactNode;
	onRowClick?: (item: T) => void;
	onCategoryClick?: (column: Column<T>) => void;
	isResizeColumn?: boolean;
	isListCount?: boolean;
	isScroll?: boolean;
	maxHeight?: string;
	tableStyle?: CSSProperties;
	headerStyle?: CSSProperties;
	tableHeaderStyle?: CSSProperties;
	tableHeaderItemStyle?: CSSProperties;
	footerStyle?: CSSProperties;
	bodyStyle?: CSSProperties;
	bodyRowStyle?: CSSProperties;
	bodyItemStyle?: CSSProperties;
	sortValues?: Sorts<T> | Array<Sorts<T>>;
	hasLastItemBorder?: boolean;
}

interface TableStyled {
	$isScroll: boolean;
	$maxHeight?: string;
	$isFooterRender: boolean;
	$hasLastItemBorder: boolean;
}

/**
 *
 * @param props.data - array data
 * @param props.column - column에 보여질 key를 담은 array, renderComponent가 없을 시 data[key]로 보여짐
 * @param props.selected - checkbox 사용 여부 useState의값을 받음 {selectedItems: T[], setSelectedItems: setStateAction}
 * @param props.pagination - pagination 사용 여부 usePagination을 사용할 것
 * @param props.emptyMessage - data가 없을때 보여질 message
 * @param props.isLoading - loading중 인지 여부
 * @param props.unit - 좌측 상단 전체건수의 단위 입력 시 전체건수 표출
 * @param props.headerLeftContent - 좌측상단에 render될 element
 * @param props.headerRightContent - 우측상단에 render될 element
 * @param props.headerBottomContent - 상단아래에 render될 element
 * @param props.onRowClick - table row 클릭 시 실행 될 함수
 * @param props.onCategoryClick  - table header의 category 클릭 시 실행 될 함수
 * @param props.isResizeColumn - header에 bar가 나오게되며 column 사이즈를 조절할 수 있게 됨
 * @param props.isListCount - table 갯수 제한 selectBox 사용 여부
 * @param props.isScroll - table body의 scroll 사용 여부
 * @param props.maxHeight - isScroll이 True일 때 max-height 값
 * @param props.tableStyle - table style
 * @param props.headerStyle - header의 style
 * @param props.tableHeaderStyle - table header의 style
 * @param props.tableHeaderItemStyle - table header 내부 item의 style
 * @param props.bodyStyle - body의 style
 * @param props.bodyRowStyle - body 내부 row의 style
 * @param props.bodyItemStyle - body 내부 item의 style
 * @param props.footerStyle - footer의 style
 * @param props.sortValues - 정렬값 헤더에 아이콘 변경을 위해 받음...
 * @param props.hasLastItemBorder - 마지막 item border 유무
 * @returns
 */
const ListTable = <T extends object>(props: ListTableProps<T>) => {
	const {
		data,
		columns,
		selected,
		pagination,
		emptyMessage,
		isLoading,
		unit,
		headerLeftContent,
		headerRightContent,
		headerBottomContent,
		onRowClick,
		onCategoryClick,
		isResizeColumn = false,
		isListCount = true,
		isScroll = true,
		maxHeight,
		tableStyle,
		headerStyle,
		tableHeaderStyle,
		tableHeaderItemStyle,
		bodyStyle,
		bodyRowStyle,
		bodyItemStyle,
		footerStyle,
		sortValues,
		hasLastItemBorder = true,
	} = props;
	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);

	const isHeaderRender = useMemo(() => {
		return headerLeftContent ?? headerRightContent ?? headerBottomContent;
	}, [headerLeftContent, headerRightContent, headerBottomContent]);
	const isFooterRender: boolean = useMemo(() => {
		return (
			(!!unit && !!pagination) ?? (data && data.length > 0 && pagination) ?? (isListCount && pagination?.listCount)
		);
	}, [unit, pagination, data, isListCount]);

	return (
		<Container
			$isScroll={isScroll}
			$isFooterRender={isFooterRender}
			$maxHeight={maxHeight}
			$hasLastItemBorder={hasLastItemBorder}
		>
			{/* 로딩처리 */}
			{isLoading && <Loading />}

			{/* Header */}
			{isHeaderRender && (
				<div className="header" style={headerStyle}>
					{headerLeftContent && <div className="header-left">{headerLeftContent}</div>}
					{headerRightContent && <div className="header-right">{headerRightContent}</div>}
					{headerBottomContent && <div className="header-bottom">{headerBottomContent}</div>}
				</div>
			)}

			{/* 테이블 영역 */}
			<div className="table" style={tableStyle}>
				{/* table header */}
				<div className="table-header" ref={headerRef} style={tableHeaderStyle}>
					{selected?.multiple &&
						(() => {
							const dataKeys = (data ?? []).map((d) => d[selected.selectKey]);
							const selectedItemKeys = (selected.multiple.selectedItems ?? []).map((item) => item[selected.selectKey]);
							const isChecked = dataKeys.every((item) => selectedItemKeys.includes(item));

							return (
								<label className="table-checkbox">
									<Checkbox
										onClick={() => {
											if (data && data?.length > 0 && selected?.multiple) {
												selected.multiple.handleSelectedItems(isChecked ? 'REMOVE' : 'PUSH', data, selected.selectKey);
											}
										}}
										readOnly
										checked={data && data.length > 0 && isChecked}
									/>
								</label>
							);
						})()}
					{columns.map((el, idx) => {
						const { isClick, width, key } = el;
						const hasClick = !!onCategoryClick && isClick;
						let sortKey: 'SORT' | 'SORT_ASC' | 'SORT_DESC' = 'SORT';
						if (Array.isArray(sortValues)) {
							const findSortProperty = sortValues.find((value) => value.property === key);
							if (!findSortProperty) {
								sortKey = 'SORT';
							} else if (findSortProperty?.direction === 'ASC') {
								sortKey = 'SORT_ASC';
							} else if (findSortProperty?.direction === 'DESC') {
								sortKey = 'SORT_DESC';
							} else {
								sortKey = 'SORT';
							}
						} else {
							if (sortValues?.property !== key) {
								sortKey = 'SORT';
							} else if (sortValues?.direction === 'ASC') {
								sortKey = 'SORT_ASC';
							} else if (sortValues?.direction === 'DESC') {
								sortKey = 'SORT_DESC';
							} else {
								sortKey = 'SORT';
							}
						}
						if (el.headerName)
							return (
								<div
									key={'table-header-' + String(key)}
									className={`table-item table-header-item${hasClick ? ' pointer' : ''}`}
									onClick={() => {
										hasClick && onCategoryClick(el);
									}}
									style={{
										maxWidth: width?.max,
										minWidth: width?.min,
										...tableHeaderItemStyle,
										...el?.style,
									}}
								>
									{el.headerName}

									{hasClick && (
										<Icon
											id={IconID[sortKey]}
											width="1.6rem"
											height="1.6rem"
											className={'sort'}
											defaultColor="gray_500"
										/>
									)}
									{isResizeColumn && (
										<button
											className="table-header-resize"
											onMouseDown={(e) => {
												handleMouseDown(e, idx, headerRef, bodyRef);
											}}
										/>
									)}
								</div>
							);
						else return <React.Fragment key={'table-header-' + String(key)} />;
					})}
				</div>

				{/* table body */}
				<div className="table-body" ref={bodyRef} style={bodyStyle}>
					{!data || data?.length <= 0 ? (
						// 데이터 없을 때
						<div className="table-body-empty">{emptyMessage ?? '데이터가 없습니다.'}</div>
					) : (
						data.map((item: T, idx: number) => {
							let isSelected = false;
							if (selected?.multiple) {
								isSelected = Boolean(
									selected.multiple.selectedItems.find(
										(selectedItem) => selectedItem[selected.selectKey] === item[selected.selectKey],
									),
								);
							} else if (selected?.selectedItem) {
								isSelected = selected.selectedItem[selected.selectKey] === item[selected.selectKey];
							}

							return (
								<div
									className={`table-body-row${onRowClick ? ' pointer' : ''}${isSelected ? ' selected' : ''}`}
									key={'table-body-row-' + idx}
									onClick={() => {
										if (onRowClick) {
											onRowClick(item);
										}
									}}
									style={bodyRowStyle}
								>
									{selected?.multiple &&
										(() => {
											return (
												<label
													className="table-checkbox"
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<Checkbox
														onClick={() => {
															selected.multiple?.handleSelectedItems(
																isSelected ? 'REMOVE' : 'PUSH',
																item,
																selected.selectKey,
															);
														}}
														readOnly
														checked={isSelected}
													/>
												</label>
											);
										})()}

									{/* 내부 아이템 */}
									{columns.map((el) => {
										const value = el.renderComponent
											? el.renderComponent(item[el.key], item, idx)
											: el.key && item[el.key];

										if (el.key) {
											return (
												<div
													className="table-item"
													key={`table-body-item-${idx}-${String(el.key)}`}
													style={{
														maxWidth: el?.width?.max,
														minWidth: el?.width?.min,
														...bodyItemStyle,
														...el?.style,
													}}
												>
													<>{typeof value === 'number' ? commaizeNumber(value) : value || el?.defaultValue}</>
												</div>
											);
										} else {
											return <React.Fragment key={'table-body-item-' + String(idx)} />;
										}
									})}
								</div>
							);
						})
					)}
				</div>
			</div>
			{isFooterRender && (
				<div className="footer" style={footerStyle}>
					{/* unit값이 있으면 전체 건수 표출 */}
					{unit && pagination && (
						<span className="unit">
							전체 : <span className="unit-count">{pagination.totalCount.toLocaleString() ?? 0}</span>
							{unit}
						</span>
					)}

					{/* 페이지네이션 */}
					{data && data.length > 0 && pagination && (
						<Pagination className="footer--pagination" pagination={pagination} />
					)}
					{isListCount && pagination?.listCount && (
						<Select
							className="footer--listCount"
							options={listCountOptions}
							value={pagination.listCount}
							onClick={(key) => {
								pagination.setPagination({ listCount: Number(key), curPage: 1 });
							}}
							labelStyle={{ width: '8.8rem' }}
						/>
					)}
				</div>
			)}
		</Container>
	);
};

const Container = styled.div<TableStyled>`
	position: relative;
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		padding: 1.6rem 0;
		align-items: center;

		&-right {
			margin-left: auto;
		}

		&-bottom {
			flex-basis: 100%;
		}
	}

	.pointer {
		cursor: pointer;

		&:hover {
		}
	}

	.table {
		border-radius: 4px;
		border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		overflow: hidden;

		${({ $isFooterRender }) =>
			$isFooterRender &&
			css`
				border-bottom: 0;
				border-radius: 4px 4px 0 0;
			`}
		&-header {
			display: flex;
			background-color: rgba(247, 249, 250, 0.6);
			padding: ${TABLE_ROW_PADDING};
			border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
			${({ theme }) => theme.font.body.body_2};
			font-weight: 700;

			&-resize {
				width: 0.2rem;
				height: calc(100% - 1rem);
				background-color: ${({ theme }) => theme.colors.gray.gray_300};
				position: absolute;
				right: 0;
			}

			&-item {
				justify-content: space-between;
				gap: 0.4rem;
			}
		}

		&-body {
			display: flex;
			flex-direction: column;
			${({ theme }) => theme.font.body.body_2};
			font-weight: 400;

			${({ $isScroll, $maxHeight }) =>
				$isScroll &&
				css`
					height: ${$maxHeight ?? '56rem'};
					${scrollStyle({ hideHorizontal: true })}
				`}
			&-empty {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 2.4rem;
				${({ theme }) => theme.font.label.label_2};
				font-weight: 500;
				color: ${({ theme }) => theme.colors.gray.gray_700};
				white-space: pre-wrap;
				text-align: center;
			}

			&-row {
				display: flex;
				padding: ${TABLE_ROW_PADDING};

				border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
				${({ $hasLastItemBorder }) =>
					!$hasLastItemBorder &&
					css`
						&:last-child {
							border-bottom: 0;
						}
					`}
				&.selected {
					background-color: ${({ theme }) => theme.colors.primary.primary_100} !important;
				}

				&.pointer:hover {
					background-color: ${({ theme }) => theme.colors.gray.gray_100};
				}
			}
		}

		&-checkbox {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 0.4rem;
			cursor: pointer;
		}

		&-item {
			position: relative;
			width: 100%;
			flex: 1;
			word-break: break-word;
			display: flex;
			align-items: center;
			min-height: 5.5rem;
			padding: 0 2rem;
		}
	}

	.footer {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1.6rem 2rem;
		margin-top: -0.1rem;
		border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		border-radius: 0 0 4px 4px;
		min-height: 6.8rem;

		.unit {
			${({ theme }) => theme.font.label.label_2};
			font-weight: 400;

			&-count {
				font-weight: 700;
				color: ${({ theme }) => theme.colors.primary.primary_600};
			}

			margin-right: auto;
		}

		&--pagination {
			position: absolute;
		}

		&--listCount {
			margin-left: auto;
		}
	}
`;

export default ListTable;
