import React from 'react';

import { type TooltipProps } from 'recharts';
import styled from 'styled-components';

import { Flex } from '../../../../components/Common';
import { Icon, IconID, Label, type StyledLabelProps } from '../../../../components/Display';
import { commaizeNumber } from '../../../../utils/format';

export function CustomTooltip({ active, payload, label }: TooltipProps<number, string>) {
	if (!active || !payload || !label) {
		return null;
	}

	const grossProfit = [...payload].filter(({ dataKey }) => dataKey !== 'sales').reverse();
	const sales = [...payload].filter(({ dataKey }) => dataKey === 'sales')[0];

	return (
		<TooltipWrapper>
			<TooltipInner $alignItems={'start'} $gap={'0.6rem'}>
				<Icon id={IconID.CALENDER} isActive={true} activeColor={'primary_700'} />
				<Label
					$fontStyle={'title_3'}
					$fontWeight={'bold'}
					$color={'primary_700'}
					style={{ whiteSpace: 'break-spaces' }}
				>
					{label}
				</Label>
			</TooltipInner>
			<TooltipInner $height={'1px'} $bg={'gray_300'} />
			<TooltipInner $gap={'0.8rem'} $direction={'column'}>
				<TooltipInner $alignItems={'start'} $gap={'0.2rem'}>
					<TooltipLabel>{'매출 총이익'}</TooltipLabel>
					<TooltipLabel>{grossProfit.reduce((acc, cur) => acc + (cur.value ?? 0), 0)}</TooltipLabel>
					<TooltipLabel>{'원'}</TooltipLabel>
				</TooltipInner>
				<TooltipItems>
					{grossProfit.map(({ dataKey, value, name }, idx) => (
						<TooltipItem key={`tooltip-item-${idx}`}>
							<Icon id={IconID.DOWNWARDS_RIGHT} width={'1.6rem'} height={'1.6rem'} />
							<TooltipLabel>{name ?? ''}</TooltipLabel>
							<TooltipLabel className={`${dataKey}-value`}>{value ?? 0}</TooltipLabel>
							<TooltipLabel>{'원'}</TooltipLabel>
						</TooltipItem>
					))}
				</TooltipItems>
			</TooltipInner>
			<TooltipInner $alignItems={'start'} $gap={'0.2rem'}>
				<TooltipLabel>{sales.name ?? ''}</TooltipLabel>
				<TooltipLabel>{sales.value ?? 0}</TooltipLabel>
				<TooltipLabel>{'원'}</TooltipLabel>
			</TooltipInner>
		</TooltipWrapper>
	);
}

const TooltipWrapper = styled.div`
	display: inline-flex;
	padding: 1.2rem 1.6rem 1.6rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.2rem;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.white};

	/* Tooltip-01 */
	box-shadow: ${({ theme }) => theme.shadow.tooltip};
`;

const TooltipInner = styled(Flex)`
	width: 18.8rem;
`;

const TooltipItems = styled(Flex)`
	align-items: flex-start;
	flex-direction: column;
	gap: 1rem;
	align-self: stretch;
	padding: 1rem 0.8rem;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.gray.gray_100};
	//justify-content: space-between;
`;

const TooltipItem = styled(Flex)`
	align-items: center;
	gap: 0.2rem;
	align-self: stretch;
	.nonInsureMargin,
	.pxPrice,
	.saleMargin {
		&-value--active {
			${({ theme }) => theme.font.weight.bold};
		}
	}
`;

const TooltipLabel = ({ children: text, className }: { children: string | number; className?: string }) => {
	const labelProps: StyledLabelProps = {
		$fontStyle: 'label_2',
		$fontWeight: 'medium',
		$color: 'gray_800',
		style: { whiteSpace: 'nowrap' },
		...(typeof text === 'number' && { $color: 'black', $textAlign: 'right', style: { flex: '1 0 0' } }),
	};

	return (
		<Label className={className} {...labelProps}>
			{typeof text === 'number' ? commaizeNumber(Math.floor(text)) : text}
		</Label>
	);
};
