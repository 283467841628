import onChangeSortDirection from './onChangeSortDirection';
import { type Column } from '../components/Table/ListTable/types';
import { type Sorts } from '../services/types';

export const handleChangeSorts = <T extends object>(
	sorts: Sorts<T> | Array<Sorts<T>> | undefined,
	column: Column<T>,
	callback: (sort: Sorts<T> | Array<Sorts<T>>) => void,
) => {
	if (Array.isArray(sorts)) {
		const findSort = sorts.find((sort) => sort.property === column.key);
		const filterSorts = sorts.filter((sort) => sort.property !== column.key);
		const { direction } = findSort ?? {};
		callback([...filterSorts, { property: column.key, direction: onChangeSortDirection(direction) }]);
	} else {
		const { direction, property } = sorts ?? {};
		callback({ property: column.key, direction: property === column.key ? onChangeSortDirection(direction) : 'ASC' });
	}
};
