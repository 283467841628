import { useQuery } from '@tanstack/react-query';

import { type NoticeIndex, type GetNoticeRequest } from './types';
import { api } from '..';

export const noticeKeys = {
	all: ['notice'] as const,
	list: ({ category }: GetNoticeRequest) => [...noticeKeys.all, 'list', category] as const,
	detail: ({ id }: NoticeIndex) => [...noticeKeys.all, 'detail', id] as const,
} as const;

export const useGetNotice = (props: GetNoticeRequest) => {
	return useQuery({
		queryKey: noticeKeys.list(props),
		queryFn: async () => await api.notice.getList(props),
	});
};

export const useGetNoticeDetail = (props: NoticeIndex) => {
	return useQuery({
		queryKey: noticeKeys.detail(props),
		queryFn: async () => await api.notice.getDetail(props),
	});
};
