import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import { Label, MarkInfoIcon, Tooltip } from '../../../../components/Display';
import { FormItem, Radio, TextField } from '../../../../components/Forms';
import { Modal } from '../../../../components/Modals';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { type PaymentRequest } from '../../../../services/chat/types';
import { DEFAULT_MEDICATION_OPTIONS } from '../../../../utils/consts';
import { commaizeNumber, decommaizeNumber, removeLeadingZero } from '../../../../utils/format';
import { isNumberString } from '../../../../utils/validators';
import { type PaymentMessageData } from '../../types';

type Payment = Omit<PaymentMessageData['payment'], 'status'>;

const defaultOrderName: Record<'ETC' | 'OTC', string> = {
	ETC: '처방의약품',
	OTC: '일반판매상품',
};

interface PaymentModalProps {
	memberUserId: number;
	channelUrl: string;
	onSubmit: (params: PaymentRequest) => void;
	storeName: string;
}
export const PaymentModal = ({ memberUserId, channelUrl, onSubmit, storeName }: PaymentModalProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { handleClose } = context.modal.useDispatch();
	const { values, dispatch, onChangeValues } = useValues<Payment>({
		type: 'ETC',
		totalAmount: 0,
		orderName: `[${storeName}]${defaultOrderName.ETC}`,
	});

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}

		return () => {
			// 모달 닫을 때 값 초기화
			dispatch('RESET');
		};
	}, []);

	const handleSubmit = () => {
		const paymentRequestParams: PaymentRequest = {
			userId: memberUserId,
			channelUrl,
			type: values.type,
			orderName: values.orderName,
			totalAmount: decommaizeNumber(String(values.totalAmount)),
		};
		onSubmit(paymentRequestParams);
	};

	const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		onChangeValues({ target: { value, name } } as React.ChangeEvent<HTMLInputElement>);
		if (value === 'ETC') {
			handleChangeOrderName({
				target: { value: `[${storeName}]${defaultOrderName.ETC}`, name: 'orderName' },
			} as React.ChangeEvent<HTMLInputElement>);
		}

		if (value === 'OTC') {
			handleChangeOrderName({
				target: { value: `[${storeName}]${defaultOrderName.OTC}`, name: 'orderName' },
			} as React.ChangeEvent<HTMLInputElement>);
		}
	};

	const handleChangeOrderName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		onChangeValues({ target: { value, name } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		let formattedValue = String(decommaizeNumber(value));

		if (values.totalAmount.toString() === '0') {
			formattedValue = formattedValue[0];
		}
		if (isNumberString(formattedValue) || formattedValue === '') {
			formattedValue = removeLeadingZero(formattedValue);
			onChangeValues({
				target: { value: formattedValue, name },
			} as React.ChangeEvent<HTMLInputElement>);
		}
	};

	const handleBlurPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		onChangeValues({
			target: { value: commaizeNumber(value), name },
		} as React.ChangeEvent<HTMLInputElement>);
	};
	return (
		<Modal
			header={{ title: '결제 요청' }}
			footer={{
				button: [
					{
						onClick: handleClose,
						buttonType: 'LINE',
						color: 'SECONDARY',
						children: '취소',
						size: { $paddingSize: 'XL' },
					},
					{
						onClick: handleSubmit,
						children: '전송',
						disabled: String(values.totalAmount).length === 0,
						shouldPrevent: true,
						style: { width: '10rem' },
					},
				],
			}}
		>
			<Container>
				<Wrapper>
					<FormItem
						label={'결제 분류'}
						renderLabelSuffix={
							<Tooltip
								floatingOptions={{ offset: 8, allowedPlacements: ['top-start'] }}
								trigger={<MarkInfoIcon type={'FILLED'} bgColor={'white'} color={'gray_700'} />}
							>
								<Label $color={'white'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
									{'처방의약품 결제는 고객이 쿠폰을 사용할 수 없습니다.'}
								</Label>
							</Tooltip>
						}
					>
						<Radio
							name={'type'}
							options={DEFAULT_MEDICATION_OPTIONS}
							selectValue={values.type}
							onChange={handleChangeType}
							style={{ gap: '4.8rem' }}
						/>
					</FormItem>
					<FormItem
						label={'주문명'}
						style={{ width: '100%' }}
						renderLabelSuffix={
							<Tooltip
								floatingOptions={{ offset: 8, allowedPlacements: ['top-start'] }}
								trigger={<MarkInfoIcon type={'FILLED'} bgColor={'white'} color={'gray_700'} />}
							>
								<Label $color={'white'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
									{'페이앱의 결제 현황의 상품명 및 고객에게 발송되는 SMS 내용에 표기됩니다.'}
								</Label>
							</Tooltip>
						}
					>
						<TextField
							name={'orderName'}
							placeholder={'주문명을 입력해주세요.'}
							onChange={handleChangeOrderName}
							value={values.orderName}
							inputSize={'sm'}
							fontStyle={{ $textAlign: 'start', $fontStyle: 'body_2' }}
						/>
					</FormItem>
					<FormItem label={'금액 입력'} style={{ width: '100%' }}>
						<TextField
							ref={inputRef}
							name={'totalAmount'}
							placeholder={'0'}
							onChange={handleChangePrice}
							onBlur={handleBlurPrice}
							value={values.totalAmount}
							inputSize={'sm'}
							fontStyle={{ $textAlign: 'end', $fontStyle: 'label_1', $fontWeight: 'bold' }}
							renderSuffix={
								<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_600'}>
									{'원'}
								</Label>
							}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && !e.shiftKey && !e?.nativeEvent?.isComposing) handleSubmit();
							}}
						/>
					</FormItem>
				</Wrapper>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	width: 80rem;
`;

const Wrapper = styled.div`
	display: flex;
	padding: 2rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	align-self: stretch;
`;
