import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { ListTable } from '../../components/Table';
import { type Columns } from '../../components/Table/ListTable/types';
import { useGetNotice } from '../../services/notice/queries';
import { type NoticeItem } from '../../services/notice/types';

const Notice = () => {
	const { isLoading, data } = useGetNotice({ category: 'MOBILE_PHARMACY_PHARMACIST' });
	const navigate = useNavigate();

	const columns: Columns<NoticeItem> = useMemo(
		() => [
			{
				key: 'id',
				headerName: '순번',
				width: { max: '9rem' },
				renderComponent: (_, __, idx) => (data ? data?.length - idx : '-'),
				style: { justifyContent: 'center' },
			},
			{
				key: 'title',
				headerName: '제목',
			},
			{
				key: 'publishedAt',
				headerName: '등록일',
				width: { max: '18rem', min: '13.6rem' },
			},
		],
		[data],
	);
	return (
		<React.Fragment>
			<ListTable
				data={data}
				columns={columns}
				isLoading={isLoading}
				emptyMessage="등록된 공지사항이 없습니다."
				isScroll={false}
				onRowClick={(item) => {
					navigate(`detail/${item.id}`);
				}}
			/>
		</React.Fragment>
	);
};
export default Notice;
