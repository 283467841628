import { IconID, type IconTypes } from '../../../components/Display';
import { type UserType } from '../../../services/users/types';

interface MenuType {
	key: string;
	label: string;
	path?: string;
	grade: UserType[];
	iconID?: IconTypes;
	isBlank?: boolean;
	nextDepth?: MenuType[];
	isHidden?: boolean;
}

interface MenuList {
	label: string;
	list: MenuType[];
}

const mobilePharmacyList: MenuList = {
	label: '모바일 약국',
	list: [
		// {
		// 	key: 'GPMF003000',
		// 	label: '대시보드',
		// 	grade: ['MASTER'],
		// 	path: '/',
		// 	iconID: IconID.NAV_DASHBOARD,
		// },
		{
			key: 'GPMF10',
			label: '모바일 약국',
			grade: ['MASTER', 'PARTNER', 'NORMAL'],
			iconID: IconID.NAV_MOBILE_PHARMACY,
			path: '/crm',
			nextDepth: [
				{
					key: '1010',
					label: '채팅',
					path: '/chat',
					grade: ['MASTER', 'PARTNER', 'NORMAL'],
				},
				{
					key: '2020',
					label: '다중 메시지 발송',
					path: '/multiple',
					grade: ['MASTER'],
				},
			],
		},
		{
			key: 'GPMF2010',
			label: '운영관리',
			grade: ['MASTER'],
			iconID: IconID.NAV_OPERATION,
			path: '/operation',
			nextDepth: [
				{
					key: '10',
					label: '약국기본정보',
					path: '/basic',
					grade: ['MASTER'],
					nextDepth: [
						{
							key: '10',
							label: '사용자 관리',
							grade: ['MASTER'],
							path: '/management',
							nextDepth: [
								{
									key: '10',
									label: '직원 수정',
									grade: ['MASTER'],
									path: '/detail',
								},
								{
									key: '20',
									label: '직원 등록',
									grade: ['MASTER'],
									path: '/registration',
								},
							],
						},
					],
				},
				{
					key: '20',
					label: '약국 정보 관리',
					path: '/app',
					grade: ['MASTER'],
				},
				{
					key: '30',
					label: '그룹 관리',
					path: '/group',
					grade: ['MASTER'],
					nextDepth: [
						{
							key: '10',
							label: '그룹 생성',
							grade: ['MASTER'],
							path: '/create',
						},
						{
							key: '20',
							label: '그룹 상세',
							grade: ['MASTER'],
							path: '/detail',
						},
					],
				},
				{
					key: '40',
					label: '자주쓰는 문구',
					path: '/phrase',
					grade: ['MASTER'],
				},
				{
					key: '50',
					label: '자동 메시지 관리',
					path: '/auto-message',
					grade: ['MASTER'],
				},
			],
		},
		{
			key: 'GPMF2020',
			label: '회원 관리',
			grade: ['MASTER'],
			iconID: IconID.NAV_MEMBER,
			path: '/member',
			nextDepth: [
				{
					key: '10',
					label: '회원목록',
					grade: ['MASTER'],
					path: '/information',
					nextDepth: [
						{
							key: '10',
							label: '회원 정보',
							grade: ['MASTER'],
							path: '/detail',
						},
					],
				},
				{
					key: '20',
					label: '차단회원 관리',
					grade: ['MASTER'],
					path: '/blacklist',
				},
			],
		},
		{
			key: 'GPMF2030',
			label: '결제 관리',
			grade: ['MASTER'],
			iconID: IconID.NAV_PAYMENT,
			path: '/payment',
			nextDepth: [
				{
					key: '10',
					label: '결제내역 관리',
					grade: ['MASTER'],
					path: '/history',
					nextDepth: [
						{
							key: '10',
							label: '결제내역 상세',
							grade: ['MASTER'],
							path: '/detail',
						},
					],
				},
				{
					key: '20',
					label: '페이앱',
					grade: ['MASTER'],
					path: 'https://seller.payapp.kr/a/signIn',
					isBlank: true,
				},
			],
		},
		// {
		// 	key: 'GPMF204010',
		// 	label: '약품 관리',
		// 	grade: ['MASTER'],
		// 	iconID: IconID.NAV_DRUG,
		// 	path: '/drug/information',
		// },
		{
			key: 'GPMF2050',
			label: '프로모션',
			grade: ['MASTER'],
			iconID: IconID.NAV_PROMOTION,
			path: '/promotion',
			nextDepth: [
				{
					key: '10',
					label: '이벤트 목록',
					grade: ['MASTER'],
					path: '/list',
					nextDepth: [{ key: '10', label: '이벤트 상세', grade: ['MASTER'], path: '/detail' }],
				},
				{
					key: '20',
					label: '이벤트 내역',
					grade: ['MASTER'],
					path: '/breakdown',
				},
				{
					key: '30',
					label: '약국 이벤트',
					grade: ['MASTER'],
					path: '/sales',
					nextDepth: [
						{
							key: '10',
							label: '약국 이벤트 생성',
							grade: ['MASTER'],
							path: '/add',
						},
						{
							key: '20',
							label: '약국 이벤트 상세',
							grade: ['MASTER'],
							path: '/detail',
						},
					],
				},
				{
					key: '40',
					label: '약국 이벤트 내역',
					grade: ['MASTER'],
					path: '/salesHistory',
				},
			],
		},
		{
			key: 'GPMF2060',
			label: '판매상품 관리',
			grade: ['MASTER'],
			path: '/product',
			iconID: IconID.NAV_PRODUCT,
		},
	],
};

const othersList: MenuList = {
	label: '기타',
	list: [
		// {
		// 	key: 'GPMF3010',
		// 	label: '통계',
		// 	grade: ['MASTER'],
		// 	iconID: IconID.NAV_STATS,
		// 	basePath: '/stats',
		// 	nextDepth: [
		// 		{
		// 			key: '10',
		// 			label: '사용자 통계',
		// 			grade: ['MASTER'],
		// 			path: '/user',
		// 		},
		// 		{
		// 			key: '20',
		// 			label: '결제 통계',
		// 			grade: ['MASTER'],
		// 			path: '/payment',
		// 		},
		// 	],
		// },
		{
			key: 'GPMF3020',
			label: '약국 A/S',
			grade: ['MASTER', 'NORMAL', 'PARTNER'],
			iconID: IconID.NAV_AS,
			path: '/as',
			nextDepth: [
				// {
				// 	key: '10',
				// 	label: '약국 문의사항',
				// 	grade: ['MASTER'],
				// 	path: '/inquiry',
				// },
				{
					key: '20',
					label: 'FAQ',
					grade: ['MASTER', 'PARTNER', 'NORMAL'],
					path: '/faq',
				},
				// {
				// 	key: '30',
				// 	label: '원격지원',
				// 	grade: ["MASTER"],
				// 	path: '/remote',
				// },
			],
		},
		{
			key: 'GPMF3030',
			label: '공지사항',
			grade: ['MASTER', 'PARTNER', 'NORMAL'],
			iconID: IconID.NAV_NOTICE,
			path: '/notice',
			nextDepth: [
				{
					key: '10',
					label: '공지사항 상세',
					grade: ['MASTER'],
					path: '/detail',
					isHidden: true,
				},
			],
		},
		// {
		// 	key: 'GPMF303010',
		// 	label: '매뉴얼',
		// 	grade: ['MASTER'],
		// 	iconID: IconID.NAV_MANUAL,
		// 	path: '/manual',
		// },
	],
};

const notIncludedList: MenuList = {
	label: 'GNB에 포함안된 리스트',
	list: [
		{
			key: 'GPM0020',
			label: '마이페이지',
			grade: ['MASTER', 'NORMAL', 'PARTNER'],
			iconID: IconID.NAV_STATS,
			path: '/mypage',
			nextDepth: [
				{
					key: '10',
					label: '마이페이지',
					grade: ['MASTER', 'NORMAL', 'PARTNER'],
					path: '/information',
				},
				{
					key: '20',
					label: '이용서비스',
					grade: ['MASTER'],
					path: '/service',
				},
				{
					key: '30',
					label: '내 결제정보',
					grade: ['MASTER'],
					path: '/payment',
				},
			],
		},
	],
};

const GP_V3_LIST: MenuList[] = [
	{
		label: '대시보드',
		list: [
			{
				key: 'GP3001',
				label: '대시보드',
				grade: ['MASTER', 'PARTNER', 'NORMAL'],
				iconID: IconID.NAV_DASHBOARD,
				path: '/dashboard',
			},
		],
	},
	{
		label: '굿팜 3.0',
		list: [
			{
				key: 'GP3002',
				label: '페이앱',
				grade: ['MASTER'],
				iconID: IconID.NAV_PAY_APP,
				path: 'https://seller.payapp.kr/a/signIn',
				isBlank: true,
			},
			// {
			// 	key: 'GP3003',
			// 	label: '문자 발송',
			// 	grade: ['MASTER'],
			// 	iconID: IconID.NAV_DASHBOARD,
			// 	path: '/',
			// },
		],
	},
	{
		label: '통계',
		list: [
			{
				key: 'GP3003',
				label: '경영분석',
				grade: ['MASTER'],
				iconID: IconID.NAV_STATS,
				path: '/stats',
				nextDepth: [
					{
						key: '10',
						label: '기간 별 분석',
						path: '/period',
						grade: ['MASTER'],
					},
					{
						key: '20',
						label: '다각도 분석',
						path: '/multilateral',
						grade: ['MASTER'],
					},
					{
						key: '30',
						label: '매출 상세 분석',
						path: '/details',
						grade: ['MASTER'],
						nextDepth: [
							{
								key: '3010',
								label: '일일 매출 상세',
								grade: ['MASTER'],
								path: '/daily',
							},
							{
								key: '3020',
								label: '주간 매출 상세',
								grade: ['MASTER'],
								path: '/weekly',
							},
							{
								key: '3030',
								label: '월간 매출 상세',
								grade: ['MASTER'],
								path: '/monthly',
							},
						],
					},
				],
			},
		],
	},
];

export { mobilePharmacyList, othersList, notIncludedList, GP_V3_LIST };
export type { MenuList, MenuType };
