import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/auth';
import GlobalStyle from './lib/styledComponents/GlobalStyle';
import Theme from './lib/styledComponents/Theme';
import Routers from './Router';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false } }, // 모든 쿼리에서 브라우저 창이 다시 포커스될 때마다 재호출 하지 않음
});

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={Theme}>
				<GlobalStyle />
				<ToastContainer autoClose={2500} theme="colored" hideProgressBar closeButton={false} />
				<AuthProvider>
					<Routers />
				</AuthProvider>
			</ThemeProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};

export default App;
