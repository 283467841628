import React, { type ReactElement, useState } from 'react';

import styled from 'styled-components';

import { MenuItems, MenuItem } from './MenuItems';

type MenuDisplayingFunc = () => void;

interface ContextMenuProps {
	menuTrigger?: (func: MenuDisplayingFunc) => ReactElement;
	menuItems: (func: MenuDisplayingFunc) => ReactElement;
	isOpen?: boolean;
}
export const ContextMenu = ({ menuTrigger, menuItems, isOpen }: ContextMenuProps) => {
	const [showMenu, setShowMenu] = useState(false);
	return (
		<Container>
			{menuTrigger?.(() => {
				setShowMenu((prev) => !prev);
			})}
			{(showMenu || isOpen) &&
				menuItems(() => {
					setShowMenu(false);
				})}
		</Container>
	);
};

export { MenuItems, MenuItem };

const Container = styled.div`
	display: inline;
`;
