import styled, { css } from 'styled-components';

import type { ButtonProps } from '../../../../../components/Buttons/Button';

export const Container = styled.div`
	display: flex;
	width: 26.4rem;
	max-width: 26.4rem;
	flex-direction: column;
	align-items: flex-start;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 12px;
	background-color: ${({ theme }) => theme.colors.white};
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
`;

export const Divider = styled.span`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
`;

export const ProductItem = styled.div`
	display: flex;
	padding: 0.8rem;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.gray.gray_100};
`;

export const ProductImageWrap = styled.div<{ $hasBorder?: boolean }>`
	width: 6.8rem;
	height: 6.8rem;
	border-radius: 8px;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	${({ theme, $hasBorder }) =>
		$hasBorder &&
		css`
			border: 1px solid ${theme.colors.gray.gray_400};
		`}
`;

export const ProductInfo = styled.div`
	display: flex;
	align-items: center;
	flex: 1 0 0;
`;

export const buttonStyle: Pick<ButtonProps, 'size' | 'style'> = {
	size: { $heightSize: 'M' },
	style: { borderRadius: '6px', width: '100%' },
};
