import React, { useMemo } from 'react';

import styled from 'styled-components';

import { hasQuantityAndActualPrice } from './PromotionMessages';
import { ProductImageWrap } from './styles';
import { ellipsis1 } from '../../../../../assets/styles/ellipsis';
import { HStack, VStack } from '../../../../../components/Common';
import { Icon, IconID, ImageRenderer, Label } from '../../../../../components/Display';
import Theme from '../../../../../lib/styledComponents/Theme';
import { type Product } from '../../../types';

const Products = ({ products }: { products: Product[] }) => {
	// 총개수, 가격
	const { totalAmount, totalQuantity } = useMemo(
		() =>
			products.reduce(
				(acc, cur) => {
					acc.totalQuantity += cur.quantity;
					acc.totalAmount += cur.actualPrice * cur.quantity;
					return acc;
				},
				{ totalAmount: 0, totalQuantity: 0 },
			),
		[products],
	);

	if (products.length <= 0) return <React.Fragment />;

	return (
		<VStack $gap="1.2rem" style={{ width: '100%', padding: '1.2rem 1.6rem 1.6rem' }}>
			<VStack $gap="2rem" style={{ width: '100%' }}>
				{products.map((p, i) => (
					<HStack key={i} $gap="0.8rem" $alignItems="center">
						<ProductImageWrap $hasBorder>
							<ImageRenderer
								url={p.file?.url}
								alt={'event-product'}
								width={'6.8rem'}
								height={'6.8rem'}
								fixedSize={true}
								defaultComponent={DefaultImage}
							/>
						</ProductImageWrap>
						<VStack $gap="0.2rem" style={{ flex: 1 }}>
							<Label $fontStyle={'body_2'} style={ellipsis1}>
								{p.name}
							</Label>
							{hasQuantityAndActualPrice(p) && (
								<React.Fragment>
									<HStack $gap="0.4rem" $alignItems="center">
										<Label
											className="quantity"
											$fontStyle="caption_2"
											$fontWeight="medium"
											$color="gray_800"
											style={{
												padding: '0 0.4rem',
												height: '1.8rem',
												border: `1px solid ${Theme.colors.gray.gray_400}`,
												borderRadius: '3px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											수량
										</Label>
										<Label $fontStyle="body_3">{p.quantity}개</Label>
									</HStack>
									<Label $fontStyle="body_2" $fontWeight="bold">
										{(p.actualPrice * p.quantity).toLocaleString()}원
									</Label>
								</React.Fragment>
							)}
						</VStack>
					</HStack>
				))}
				<HStack $justify="end" $gap="0.4rem">
					<Label $fontStyle="body_3" $color="gray_900" $fontWeight="medium">
						총 {totalQuantity}개
					</Label>
					<Label $fontStyle="body_3" $color="gray_500" $fontWeight="medium">
						/
					</Label>
					<Label $fontStyle="body_3" $color="gray_900" $fontWeight="medium">
						<Label $color="primary_700" $fontWeight="bold">
							{totalAmount}
						</Label>
						원
					</Label>
				</HStack>
			</VStack>
		</VStack>
	);
};

const DefaultImageWrapper = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
`;

export const DefaultImage = (
	<DefaultImageWrapper>
		<Icon id={IconID.PHOTO_CANCEL} width="2.4rem" height="2.4rem" defaultColor="gray_600" />
	</DefaultImageWrapper>
);

export default Products;
