import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, IconID } from '../../../components/Display';
import context from '../../../context';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { USER_TYPE_VALUES } from '../../../utils/consts';
import { removeToken } from '../../../utils/handleToken';

interface ContainerStyle {
	$isActive: boolean;
	$image: string;
}

const Profile = () => {
	const { userInfo, userImage } = context.user.useValue();
	const [isProfileOpen, setProfileOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick(ref, () => {
		setProfileOpen(false);
	});

	return (
		<Container $isActive={isProfileOpen} $image={userImage ?? '/images/header/profile.svg'} ref={ref}>
			<div
				className="main"
				onClick={() => {
					setProfileOpen(!isProfileOpen);
				}}
			>
				<div className="main-info">
					<div className="main-info-image" />
					<div className="main-info-text">
						<p className="main-info-text-name">{userInfo?.name}</p>
						<p className="main-info-text-type">{!!userInfo?.type && USER_TYPE_VALUES[userInfo.type]}</p>
					</div>
				</div>
				<Icon id={IconID.CHEVRON} width={'2rem'} height={'2rem'} className="main-arrow" />
			</div>

			{/* popup */}
			<div className="popup">
				<div className="popup-profile">
					<div className="popup-profile-image" />
					<div className="popup-profile-info">
						<p className="popup-profile-info-name">{userInfo?.name}</p>
						<p className="popup-profile-info-type">{!!userInfo?.type && USER_TYPE_VALUES[userInfo.type]}</p>
					</div>
				</div>
				<ul className="popup-list">
					<li className="popup-list-item">
						<Link
							to={'/mypage/information'}
							onClick={() => {
								setProfileOpen(false);
							}}
						>
							내 계정 정보
						</Link>
					</li>
					<li className="popup-list-item">
						<a
							onClick={() => {
								removeToken();
							}}
						>
							로그아웃
						</a>
					</li>
				</ul>
			</div>
		</Container>
	);
};

const Container = styled.div<ContainerStyle>`
	position: relative;
	.main {
		display: flex;
		align-items: center;
		width: 16rem;
		justify-content: space-between;
		cursor: pointer;

		&-info {
			display: flex;
			align-items: center;
			gap: 1.2rem;
			&-image {
				width: 3.2rem;
				height: 3.2rem;
				border-radius: 50%;
				background-image: url(${({ $image }) => $image});
				background-size: cover;
				background-color: ${({ theme }) => theme.colors.gray.gray_500};
				background-position: center center;
			}

			&-text {
				display: flex;
				gap: 0.2rem;
				flex-direction: column;

				&-name {
					${({ theme }) => theme.font.label.label_1};
					font-weight: bold;
				}
				&-type {
					${({ theme }) => theme.font.caption.caption_1};
				}
			}
		}

		&-arrow {
			transition: 0.3s transform;
			transform: ${({ $isActive }) =>
				$isActive ? 'rotate(180deg) translateY(0.5rem)' : 'rotate(0deg) translateY(0rem)'};
		}
	}

	.popup {
		position: absolute;
		display: ${({ $isActive }) => ($isActive ? 'flex' : 'none')};
		flex-direction: column;
		gap: 1.6rem;
		right: 0;
		width: 25rem;
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: 10px;
		border: 2px solid ${({ theme }) => theme.colors.gray.gray_200};
		padding: 3.2rem 2rem 1.2rem;

		&-profile {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;
			align-items: center;

			&-image {
				width: 5.2rem;
				height: 5.2rem;
				border-radius: 50%;
				background-image: url(${({ $image }) => $image});
				background-color: ${({ theme }) => theme.colors.gray.gray_500};
				background-size: cover;
				background-position: center center;
			}
			&-info {
				display: flex;
				flex-direction: column;
				text-align: center;

				&-name {
					${({ theme }) => theme.font.title.title_2};
					font-weight: bold;
				}
				&-type {
					${({ theme }) => theme.font.caption.caption_1};
					color: ${({ theme }) => theme.colors.primary.primary_600};
				}
			}
		}

		&-list {
			display: flex;
			flex-direction: column;
			&-item {
				a {
					display: block;
					padding: 1.6rem 0;
					cursor: pointer;
					${({ theme }) => theme.font.label.label_2};
					font-weight: 500;
				}

				&:not(:last-child) a {
					border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
				}
			}
		}
	}
`;
export default Profile;
