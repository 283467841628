import React, { type CSSProperties, type ReactElement, useLayoutEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { useLazyImageLoader } from '../../../hooks/useLazyImageLoader';
import { noop } from '../../../utils/noop';

/**
 *
 * @param {string} sideLength 기본(최소) 길이
 * @param {string | null} maxSideLength 최대길이
 * @param {number} ratio 넓이 높이 비율
 * @return {string} ex) '6rem'
 * @description 이미지 원본 사이즈 * 비율 값이 최대 길이보다 크면 최대 길이 반환. 그 외엔 기본 길이 반환
 */
function getDynamicMinLength(sideLength: string, maxSideLength: string | null, ratio: number): string {
	try {
		const min = parseFloat(sideLength);
		const max = parseFloat(maxSideLength ?? sideLength);
		const resized = min * ratio;

		if (resized <= min) {
			return min + 'rem';
		}

		if (resized >= max) {
			return max + 'rem';
		}
	} catch (e) {
		//
	}

	return sideLength;
}

interface ImageRendererProps {
	url: string;
	alt?: string;
	width?: string;
	maxWidth?: string | null;
	height?: string;
	maxHeight?: string | null;
	circle?: boolean;
	borderRadius?: string;
	fixedSize?: boolean;
	defaultComponent?: (() => ReactElement) | ReactElement;
	onLoad?: () => void;
	onError?: () => void;
}
/**
 * @description
 * IntersectionObserver 로 이미지 요소가 뷰포트에 포함 될 때 이미지를 렌더합니다
 * fixedSize=false (기본값)인 경우 원래 이미지를 크기 비율에 맞게 기본 길이값 또는 최대 길이값으로 이미지를 렌더합니다
 */
export const ImageRenderer = ({
	url,
	alt = '',
	width = '',
	maxWidth = null,
	height = '',
	maxHeight = null,
	circle = false,
	borderRadius,
	fixedSize = false,
	defaultComponent,
	onLoad = noop,
	onError = noop,
}: ImageRendererProps) => {
	const ref = useRef(null);
	const isLoaded = useLazyImageLoader(ref);
	const internalUrl = isLoaded ? url : null;

	const [defaultComponentVisible, setDefaultComponentVisible] = useState(false);
	const [ratio, setRatio] = useState(1);

	const renderDefault = () => {
		if (typeof defaultComponent === 'function') return defaultComponent();
		return defaultComponent;
	};

	const renderImage = () => {
		const backgroundStyle: CSSProperties = internalUrl
			? {
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundImage: `url(${internalUrl})`,
					backfaceVisibility: 'hidden',
				}
			: {};

		return (
			<ImageView
				style={{
					width: '100%',
					minWidth: width,
					maxWidth: fixedSize ? width : getDynamicMinLength(width, maxWidth, 1 / ratio),
					height: fixedSize ? height : getDynamicMinLength(height, maxHeight, ratio),
					position: 'absolute',
					borderRadius: circle ? '50%' : borderRadius,
					...backgroundStyle,
				}}
			/>
		);
	};

	return (
		width &&
		height && (
			<ImageView
				ref={ref}
				style={{
					width: '100%',
					minWidth: width,
					maxWidth: fixedSize ? width : getDynamicMinLength(width, maxWidth, 1 / ratio),
					height: fixedSize ? height : getDynamicMinLength(height, maxHeight, ratio),
				}}
			>
				{!internalUrl || defaultComponentVisible ? renderDefault() : renderImage()}
				{internalUrl && (
					<HiddenImageLoader
						src={internalUrl}
						alt={alt}
						onLoadStart={() => {
							setDefaultComponentVisible(true);
						}}
						onLoad={(ratio) => {
							setDefaultComponentVisible(false);
							setRatio(ratio);
							onLoad();
						}}
						onError={() => {
							setDefaultComponentVisible(true);
							onError();
						}}
					/>
				)}
			</ImageView>
		)
	);
};

// Image is loaded as a background-image, but this component serves as a hidden component to receive events indicating whether the image has actually been loaded.
const HiddenImageLoader = (props: {
	src: string;
	alt: string;
	onLoadStart?: () => void;
	onLoad?: (ratio: number) => void;
	onError?: () => void;
}) => {
	const { src, alt, onLoadStart = noop, onLoad = noop, onError = noop } = props;

	const reloadCtx = useRef({
		currSrc: src,
		prevSrc: src,
		loadFailure: false,
	});

	if (reloadCtx.current.currSrc !== src) {
		reloadCtx.current.prevSrc = reloadCtx.current.currSrc;
		reloadCtx.current.currSrc = src;
	}

	// SideEffect: If the image URL has changed or loading has failed, please try again
	useLayoutEffect(() => {
		if (src) {
			const sourceChanged = reloadCtx.current.prevSrc !== reloadCtx.current.currSrc;
			const loadFailure = reloadCtx.current.loadFailure;

			if (sourceChanged || loadFailure) {
				onLoadStart();
			}
		}
	}, [src, navigator.onLine]);

	return (
		<img
			style={{ display: 'none' }}
			src={src}
			alt={alt}
			onLoad={(e) => {
				reloadCtx.current.loadFailure = false;
				const ratio = e.currentTarget.height / e.currentTarget.width;
				onLoad(ratio);
			}}
			onError={() => {
				reloadCtx.current.loadFailure = true;
				onError();
			}}
		/>
	);
};

const ImageView = styled.div`
	overflow: hidden;
	position: relative;
`;
