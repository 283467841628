import React, { useEffect, useRef } from 'react';

import { toast } from 'react-toastify';

import { AutoMessageItem } from './components/Item';
import { STATUS_MESSAGE } from './const';
import { Grid, Loading, VStack } from '../../../components/Common';
import { Label } from '../../../components/Display';
import context from '../../../context';
import {
	useGetNotificationConfigInit,
	useGetNotificationConfigs,
} from '../../../services/notification-configs/queries';

const AutoMessage = () => {
	const { userInfo } = context.user.useValue();

	const { data, isSuccess, isLoading, refetch } = useGetNotificationConfigs({ storeId: userInfo.storeId });
	const { mutate } = useGetNotificationConfigInit();
	// react strict mode 에서 2번 호출하게 되는데 이를 방지
	const hasMutated = useRef(false);

	useEffect(() => {
		if (isSuccess && data.reduce((acc, cur) => acc + cur.data.length, 0) === 0 && !hasMutated.current) {
			hasMutated.current = true;
			mutate(
				{ storeId: userInfo.storeId },
				{
					onSuccess: () => {
						refetch();
					},
					onError: (err) => {
						toast.error(err.response?.data.message);
					},
				},
			);
		}
	}, [isSuccess]);

	if (isLoading) return <Loading />;

	if (isSuccess)
		return (
			<VStack $gap="2.4rem">
				{data.map((el) => (
					<VStack key={'auto__message--' + el.group} $gap="1rem">
						<Label $fontStyle="title_2" $fontWeight="bold">
							{el.groupName}
						</Label>
						<Grid $columns={5} $gap="2.4rem">
							{el.data.map((item) => (
								<AutoMessageItem
									key={'auto__message--' + item.id}
									data={item}
									onSuccess={() => {
										toast.success(STATUS_MESSAGE.ACTIVE.SUCCESS);
										refetch();
									}}
								/>
							))}
						</Grid>
					</VStack>
				))}
			</VStack>
		);
};

export default AutoMessage;
