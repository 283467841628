import React from 'react';

import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type Group } from '../../../services/groups/types';
import { formatYN } from '../../../utils/format';

export const groupTableColumns: Columns<Group> = [
	{
		key: 'name',
		headerName: '그룹명',
		isClick: true,
		width: { max: '26.4rem' },
	},
	{
		key: 'categoryName',
		headerName: '카테고리',
		isClick: true,
		width: { max: '20.8rem' },
	},
	{
		key: 'isSystem',
		headerName: '커스텀 적용',
		renderComponent: (value) => formatYN(!value),
		width: { max: '20rem' },
	},
	{
		key: 'description',
		headerName: '그룹 설명',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
		width: { max: '', min: '28rem' },
	},
	{
		key: 'memberCount',
		headerName: '그룹 인원 수',
		renderComponent: (value) => value?.toLocaleString() + '명',
		isClick: true,
		width: { max: '20.8rem' },
		style: { justifyContent: 'flex-end' },
	},
];
