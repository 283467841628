import React from 'react';

import { commaizeNumber } from '../../../utils/format';
import { ChartText } from '../styles';

import type { Props as LabelProps } from 'recharts/types/component/Label';

export function BarLabel({ value, x, y, width }: LabelProps) {
	if (x && y && value && width) {
		return (
			<ChartText x={x} y={y} dx={Number(width) / 2} dy={-8} textAnchor={'middle'} $color={'gray_900'}>
				{commaizeNumber(value) + '원'}
			</ChartText>
		);
	}
	return <></>;
}
