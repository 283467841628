import React, { forwardRef, type InputHTMLAttributes } from 'react';

import styled from 'styled-components';

import { removeEmojis } from '../../../utils/format';
import { InputCount, type InputCountProps } from '../TextField';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
	rows?: number;
	renderSuffix?: React.ReactNode;
	count?: InputCountProps;
	enableEmoji?: boolean;
	textAreaStyle?: React.CSSProperties;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(props: TextAreaProps, ref?: React.ForwardedRef<HTMLTextAreaElement>) => {
		const {
			readOnly,
			disabled,
			count,
			style = {},
			rows,
			renderSuffix,
			onChange,
			enableEmoji = false,
			textAreaStyle,
			...rest
		} = props;

		return (
			<Container style={{ ...style }} $readOnly={readOnly} $disabled={disabled}>
				<CustomTextarea
					{...{ ...rest, style: textAreaStyle, rows, readOnly, disabled, ref }}
					onChange={(e) => {
						if (e.target.maxLength > 0) {
							e.target.value = e.target.value.slice(0, e.target.maxLength);
						}

						if (!enableEmoji) {
							e.target.value = removeEmojis(e.target.value);
						}
						onChange && onChange(e);
					}}
				/>
				{renderSuffix && <SuffixWrap>{renderSuffix}</SuffixWrap>}
				{count && (
					<CountWrap>
						<InputCount count={props.value?.toString().length} max={count.max} />
					</CountWrap>
				)}
			</Container>
		);
	},
);

TextArea.displayName = 'TextArea';

const Container = styled.div<{ $readOnly?: boolean; $disabled?: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;
	align-self: stretch;
	gap: 1.2rem;
	padding: 1.2rem;
	background: ${({ theme, $readOnly, $disabled }) =>
		$readOnly ?? $disabled ? theme.colors.gray.gray_100 : theme.colors.white};
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	&:focus-within {
		border: 1px solid
			${({ theme, $readOnly }) => ($readOnly ? theme.colors.gray.gray_300 : theme.colors.primary.primary_600)};
	}
`;

const CustomTextarea = styled.textarea`
	border: 0;
	min-width: 0;
	position: relative;
	resize: none;
	width: 100%;
	height: 100%;
	${({ theme }) => theme.font.body.body_2};
	&::placeholder {
		color: ${({ theme }) => theme.colors.gray.gray_600};
	}
	background: inherit;
`;

const SuffixWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: none;
`;

const CountWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: none;
	align-self: flex-end;
`;
