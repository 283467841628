import { endOfMonth } from 'date-fns/endOfMonth';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';
import { toDate } from 'date-fns/toDate';

import { formatDate } from '../../utils/format';

import type { PeriodUnit } from '../../services/statistics/queries';

export const getDateStartOfUnit = (date: Date | string, unit: PeriodUnit) => {
	switch (unit) {
		case 'weekly':
			return startOfWeek(date);
		case 'monthly':
			return startOfMonth(date);
		case 'daily':
			return toDate(date);
	}
};

export const getDateEndOfUnit = (date: Date | string, unit: PeriodUnit) => {
	switch (unit) {
		case 'weekly':
			return endOfWeek(date);
		case 'monthly':
			return endOfMonth(date);
		case 'daily':
			return toDate(date);
	}
};

export const formatPeriod = ([start, end]: [Date | null, Date | null]) => ({
	startDate: formatDate(start),
	endDate: formatDate(end),
});
