import { useCallback } from 'react';

import {
	type FileMessage,
	type BaseMessageCreateParams,
	type UserMessage,
	type UserMessageCreateParams,
	type UserMessageUpdateParams,
	type FileMessageCreateParams,
	type FileMessageUpdateParams,
} from '@sendbird/chat/message';
import { type UseMutateFunction } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { type useChannelMessages } from './useChannelMessages';
import { DELETED_MESSAGE } from '../../../pages/CRM/Chat/consts';
import { type ErrorData } from '../../../services/types';
import { type ChannelProviderProps } from '../context/ChannelProvider';
import { type MessageCustomType } from '../types';

type MessageListDataSource = ReturnType<typeof useChannelMessages>;

interface MessageActions {
	sendUserMessage: (params: UserMessageCreateParams) => Promise<UserMessage>;
	// sendFileMessage: (params: UserMessageCreateParams) => Promise<UserMessage>;
	updateUserMessage: (messageId: number, params: UserMessageUpdateParams) => Promise<UserMessage>;
	deleteUserMessage: (messageId: number) => Promise<UserMessage>;
	sendCustomMessage: (params: UserMessageCreateParams, customType: MessageCustomType) => Promise<UserMessage>;
	mutateMessage: <T>(cb: UseMutateFunction<unknown, AxiosError<ErrorData>, T>, params: T) => Promise<void>;

	sendFileMessage: (params: FileMessageCreateParams) => Promise<FileMessage>;
	deleteFileMessage: (messageId: number) => Promise<FileMessage>;
}

interface Params extends ChannelProviderProps, MessageListDataSource {}

/**
 * @description This hook controls common processes related to message sending, updating.
 * */

export function useMessageActions(params: Params): MessageActions {
	const { sendUserMessage, updateUserMessage, updateFileMessage, sendFileMessage } = params;

	const buildInternalMessageParams = useCallback(
		<T extends BaseMessageCreateParams>(basicParams: T, customType?: string): T => {
			return { ...basicParams, ...(typeof customType === 'string' && { customType }) };
		},
		[],
	);

	return {
		sendUserMessage: useCallback(
			async (params) => {
				const internalParams = buildInternalMessageParams<UserMessageCreateParams>(params);

				return await sendUserMessage(internalParams, () => {});
			},
			[buildInternalMessageParams, sendUserMessage],
		),
		// sendbird sendFileMessage 가 아닌 GP_FILE 커스텀 타입으로 sendUserMessage
		// sendFileMessage: useCallback(
		// 	async (params) => {
		// 		const internalParams = buildInternalMessageParams<UserMessageCreateParams>(params, 'GP_FILE');

		// 		return await sendUserMessage(internalParams, () => {});
		// 	},
		// 	[buildInternalMessageParams, sendUserMessage],
		// ),
		sendFileMessage: useCallback(
			async (params) => {
				const internalParams = buildInternalMessageParams<FileMessageCreateParams>(params, 'GP_FILE');

				return await sendFileMessage(internalParams, () => {});
			},
			[buildInternalMessageParams, sendFileMessage],
		),
		mutateMessage: useCallback(async <T>(cb: UseMutateFunction<unknown, AxiosError<ErrorData>, T>, params: T) => {
			cb(params, {
				onError: (err) => {
					toast.error(err.response?.data.message);
				},
			});
		}, []),
		sendCustomMessage: useCallback(
			async (params, customType) => {
				const internalParams = buildInternalMessageParams<UserMessageCreateParams>(params, 'GP_' + customType);
				return await sendUserMessage(internalParams, () => {});
			},
			[buildInternalMessageParams, sendUserMessage],
		),
		updateUserMessage: useCallback(
			async (messageId, params) => {
				const internalParams = buildInternalMessageParams<UserMessageUpdateParams>(params);

				return await updateUserMessage(messageId, internalParams);
			},
			[buildInternalMessageParams, updateUserMessage],
		),
		// sendbird deleteUserMessage 가 아닌 GP_DELETED 커스텀 타입으로 updateUserMessage
		deleteUserMessage: useCallback(
			async (messageId) => {
				const params: UserMessageUpdateParams = {
					message: DELETED_MESSAGE,
					customType: 'GP_DELETED',
				};
				const internalParams = buildInternalMessageParams<UserMessageUpdateParams>(params);

				return await updateUserMessage(messageId, internalParams);
			},
			[buildInternalMessageParams, updateUserMessage],
		),
		deleteFileMessage: useCallback(
			async (messageId) => {
				const params: FileMessageUpdateParams = {
					customType: 'GP_DELETED',
				};
				const internalParams = buildInternalMessageParams<FileMessageUpdateParams>(params);

				return await updateFileMessage(messageId, internalParams);
			},
			[buildInternalMessageParams, updateFileMessage],
		),
	};
}
