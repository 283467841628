export interface PubSubTypes<T extends keyof any, U extends { topic: T; payload: any }> {
	subscribe: <K extends T>(topic: K, subscriber: (data: PayloadByTopic<K, U>) => void) => { remove: () => void };
	publish: <K extends T>(topic: K, data: PayloadByTopic<K, U>) => void;
}

type PayloadByTopic<T extends keyof any, U> = U extends {
	topic: T;
	payload: infer P;
}
	? P
	: never;

export const pubSubFactory = <
	T extends keyof any = keyof any,
	U extends { topic: T; payload: any } = any,
>(): PubSubTypes<T, U> => {
	const topics = {} as Record<T, Set<(data: any) => void>>;

	return {
		subscribe: (topic, listener) => {
			topics[topic] ??= new Set();
			topics[topic].add(listener);
			return {
				remove: () => {
					topics[topic].delete(listener);
				},
			};
		},
		publish: (topic, info) => {
			if (topics[topic]) {
				topics[topic].forEach((fn) => {
					setTimeout(() => {
						fn(info ?? {});
					}, 0);
				});
			}
		},
	};
};
