import React, { useRef, useState, useLayoutEffect } from 'react';

import { type UseFloatingReturn } from '@floating-ui/react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { scrollStyle } from '../../../assets/styles/scrollStyle';
import context from '../../../context';
import { HStack } from '../../Common';
import { ModalFooter, type ModalFooterProps } from '../ModalFooter';
import { ModalHeader } from '../ModalHeader';

import type { OutletHeaderProps } from '../ModalHeader';

// FloatingModal과 같이 사용하기위한 Modal에서 기본적으로 사용하는 Props
export interface DefaultModalProps {
	header?: OutletHeaderProps;
	footer?: ModalFooterProps;
	zIndex?: number;
}

export interface ModalProps extends DefaultModalProps {
	isBackdrop?: boolean;
	isBackdropClose?: boolean;
	isScroll?: boolean;
	children?: React.ReactNode;
}

interface WrapperProps {
	$floating?: UseFloatingReturn;
	$zIndex?: number;
	$isScroll: boolean;
}

/**
 * Modal의 기본 Setting
 * @param param.isBackdrop - backdrop 보임 여부
 * @param param.isBackdropClose - backdrop 클릭시 modal close 여부
 * @param param.isScroll - 모달의 최대 width, height가 browser inner를 넘지 못하게 함
 * @param param.zIndex - modals 안에서 z-index값
 * @param param.header - header의 title, style
 * @param param.footer - header의 title, close버튼 포함 여부
 * @returns
 */
export const Modal = ({
	isBackdrop = true,
	isBackdropClose = false,
	isScroll = true,
	children,
	zIndex,
	header,
	footer,
}: ModalProps) => {
	const { handleClose } = context.modal.useDispatch();
	const [domReady, setDomReady] = useState(false);
	const modalRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		setDomReady(true);
	}, []);

	const modalComponents = (
		<ModalWrapper ref={modalRef} $zIndex={zIndex} $isScroll={isScroll}>
			{isBackdrop && (
				<div
					className="backdrop"
					onClick={() => {
						isBackdropClose && handleClose();
					}}
				/>
			)}
			<HStack className="inner">
				{header && <ModalHeader {...header} handleClose={handleClose} />}
				{children}
				{footer && <ModalFooter {...footer} />}
			</HStack>
		</ModalWrapper>
	);

	if (domReady) {
		return <React.Fragment>{createPortal(modalComponents, document.getElementById('modal-portal')!)}</React.Fragment>;
	} else {
		return null;
	}
};

const ModalWrapper = styled.div<WrapperProps>`
	${({ $floating, $zIndex }) =>
		!$floating
			? css`
					position: fixed;
					inset: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: ${$zIndex};
				`
			: css`
					position: absolute;
					top: 0;
					left: 0;
					transform: translate(${`${String($floating.x)}px, ${String($floating.y)}px`});
					z-index: ${$zIndex ?? 996};
				`}

	.backdrop {
		position: fixed;
		inset: 0;
		background-color: rgba(0, 0, 0, 0.4);
	}
	.inner {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		box-shadow: ${({ theme }) => theme.shadow.modal};
		border-radius: 6px;
	}
	> .inner {
		${({ $isScroll }) =>
			$isScroll &&
			css`
				max-height: calc(100vh - 4rem);
				max-width: calc(100vw - 4rem);
				${scrollStyle()};
			`}
	}
`;
