import React from 'react';

import { ellipsis1 } from '../../assets/styles/ellipsis';
import { AssetsImage } from '../../components/Display';
import { type Columns } from '../../components/Table/ListTable/types';
import { type ProductItem } from '../../services/products/types';
import { type Search } from '../../services/types';
import { type DefaultOption } from '../../utils/consts';

export const PRODUCT_COLUMNS = (assetSize = '4.6rem'): Columns<ProductItem> => [
	{
		key: 'productAsset',
		headerName: '상품 이미지',
		renderComponent: (value) => <AssetsImage assets={value} $width={assetSize} />,
		style: { justifyContent: 'center' },
		width: { max: '16rem' },
	},
	{
		key: 'barcode',
		headerName: '바코드',
		width: { max: '20rem' },
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
	},
	{
		key: 'name',
		headerName: '상품명',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
	},
	{
		key: 'price',
		headerName: '정가',
		width: { max: '20rem' },
		style: { justifyContent: 'flex-end' },
		renderComponent: (value) => value.toLocaleString() + '원',
	},
];
export type ProductSearchType = 'name' | 'barcode';
export const INITIAL_PRODUCT_TMP_SEARCH: Search<ProductSearchType> = {
	searchCategory: 'name',
	searchKeyword: '',
};

export const PRODUCT_SEARCH_OPTIONS: Array<DefaultOption<ProductSearchType>> = [
	{
		key: 'name',
		label: '상품명',
	},
	{ key: 'barcode', label: '바코드' },
];
