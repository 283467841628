import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { type BaseChannel, type SendableMessage } from '@sendbird/chat/lib/__definition';
import { type BaseMessage, type FileMessage, type UserMessage } from '@sendbird/chat/message';
import { format } from 'date-fns/format';

export type SendbirdMessage = BaseMessage | UserMessage | SendableMessage;
export type CoreMessageType = UserMessage | FileMessage;

export function isSendableMessage(msg?: SendbirdMessage | null): msg is SendableMessage {
	return msg !== undefined && msg !== null && 'sendingStatus' in msg;
}

export function isMyMessage(
	msg?: SendbirdMessage | null,
	currentUserId = '##__USER_ID_IS_NOT_PROVIDED__##',
): msg is SendableMessage {
	if (!isSendableMessage(msg)) return false;
	return msg.sender?.userId === currentUserId;
}

export function isNewMessage(msg: SendbirdMessage, currentUserId?: string) {
	const myMessage = isMyMessage(msg, currentUserId);
	if (myMessage) return false;
	if (msg.isAdminMessage()) return false;
	return msg.updatedAt === 0;
}

export function getMessageUniqId(msg: BaseMessage | SendableMessage) {
	if ('sendingStatus' in msg) {
		if (msg.sendingStatus === 'succeeded') return String(msg.messageId);
		return msg.reqId;
	}

	return String(msg.messageId);
}

export function arrayToMapWithGetter<T>(arr: T[], getSelector: (item: T) => string) {
	return arr.reduce<Record<string, T>>((accum, curr) => {
		const _key = getSelector(curr);
		accum[_key] = curr;
		return accum;
	}, {});
}
/**
 * exported, should be backward compatible
 * @returns [chainTop: `boolean`, chainBottom: `boolean`]
 */
export const compareMessagesForGrouping = (
	prevMessage: CoreMessageType,
	currMessage: CoreMessageType,
	nextMessage: CoreMessageType,
	currentChannel?: GroupChannel,
) => {
	if (!currentChannel || currentChannel.channelType !== 'group') {
		return [isSameGroup(prevMessage, currMessage), isSameGroup(currMessage, nextMessage)];
	}

	const sendingStatus = currMessage?.sendingStatus || '';
	const isAcceptable = sendingStatus !== 'pending' && sendingStatus !== 'failed';
	return [
		isSameGroup(prevMessage, currMessage, currentChannel) && isAcceptable,
		isSameGroup(currMessage, nextMessage, currentChannel) && isAcceptable,
	];
};

export const getMessageCreatedAt = (message: BaseMessage) => format(message.createdAt, 'p');

export const isSameGroup = (
	message: CoreMessageType,
	comparingMessage: CoreMessageType,
	currentChannel?: GroupChannel,
) => {
	if (
		!(
			message &&
			comparingMessage &&
			message.messageType &&
			message.messageType !== 'admin' &&
			comparingMessage.messageType &&
			comparingMessage?.messageType !== 'admin' &&
			'sender' in message &&
			'sender' in comparingMessage &&
			message.createdAt &&
			comparingMessage.createdAt &&
			message.sender.userId &&
			comparingMessage.sender.userId
		)
	) {
		return false;
	}
	return (
		message?.sendingStatus === comparingMessage?.sendingStatus &&
		message?.sender?.userId === comparingMessage?.sender?.userId &&
		getMessageCreatedAt(message) === getMessageCreatedAt(comparingMessage) &&
		(currentChannel ? isReadMessage(currentChannel, message) === isReadMessage(currentChannel, comparingMessage) : true)
	);
};

export const isReadMessage = (channel: GroupChannel, message: CoreMessageType): boolean =>
	getOutgoingMessageState(channel, message) === 'READ';

const getOutgoingMessageState = (channel: GroupChannel | null, message: CoreMessageType): string => {
	if (!message || !('sendingStatus' in message)) return 'NONE';

	if (message.sendingStatus === 'pending') {
		return 'PENDING';
	}
	if (message.sendingStatus === 'failed') {
		return 'FAILED';
	}
	if (channel?.isGroupChannel?.()) {
		if (channel.getUnreadMemberCount?.(message) === 0) {
			return 'READ';
		} else if (channel.getUndeliveredMemberCount?.(message) === 0) {
			return 'DELIVERED';
		}
	}
	if (message.sendingStatus === 'succeeded') {
		return 'SENT';
	}
	return 'NONE';
};

/**
 * Diff utils for channel
 * @param {BaseChannel} [a]
 * @param {BaseChannel} [b]
 * @returns {boolean}
 * */
export function isDifferentChannel<T extends BaseChannel>(a?: T, b?: T): boolean {
	if (!a || !b) return true;
	return a.url !== b.url;
}

export const removeImageUrls = (inputString: string): string => {
	if (!inputString) return '';
	const regex = /Ωhttp.*?\.(png|jpeg|jpg)/g;
	return inputString.replace(regex, '');
};
