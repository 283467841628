import { type CanvasLike, drawCanvas, init } from '@tybys/qrcodegen';

async function drawQr({ canvas, bytesStr }: { canvas: HTMLCanvasElement; bytesStr: string }) {
	await init().then((api) => {
		const bytes = bytesStr.split(',').map((s) => Number(s));
		const matrix = api.encodeBinary(new Uint8Array(bytes));
		canvas.width = 500;
		canvas.height = 500;
		canvas.style.width = '20rem';
		canvas.style.height = '20rem';
		drawCanvas(canvas as CanvasLike, matrix);
	});
}

function resizeImageLength({
	width,
	height,
	maxWidth,
	maxHeight,
}: {
	width: number;
	height: number;
	maxWidth: number;
	maxHeight: number;
}) {
	let targetWidth = Math.min(width, maxWidth);
	let targetHeight = Math.min(height, maxHeight);

	// 너비와 높이가 최대 길이를 초과할 경우
	if (width > maxWidth || height > maxHeight) {
		if (width > height) {
			targetWidth = maxWidth;
			targetHeight = height * (maxWidth / width);
		} else {
			targetHeight = maxHeight;
			targetWidth = width * (maxHeight / height);
		}
	}

	return [targetWidth, targetHeight];
}

const MAX_LENGTH = 1008;

async function drawImgToCanvas({
	url,
	canvas,
	resize,
	maxHeight,
}: {
	url: string;
	canvas: HTMLCanvasElement;
	resize?: boolean;
	maxHeight?: number;
}) {
	const image = new Image();
	return await new Promise((resolve, reject) => {
		image.crossOrigin = 'Anonymous';
		image.src = url;
		image.onload = () => {
			const originWidth = image.width;
			const originHeight = image.height;
			let targetWidth = originWidth;
			let targetHeight = originHeight;

			if (resize) {
				[targetWidth, targetHeight] = resizeImageLength({
					width: originWidth,
					height: originHeight,
					maxWidth: MAX_LENGTH,
					maxHeight: maxHeight ?? MAX_LENGTH,
				});
			}

			canvas.width = Math.floor(targetWidth);
			canvas.height = Math.floor(targetHeight);
			canvas.style.width = '';
			canvas.style.height = '';

			const ctx = canvas.getContext('2d');
			if (!ctx) {
				reject(new Error('Failed to get canvas 2d context'));
				return;
			}

			ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

			resolve(canvas);
		};
	});
}

export { drawQr, resizeImageLength, drawImgToCanvas };
