import React from 'react';

import { Flex, Loading } from '../../../../components/Common';
import { Icon, IconID, Label } from '../../../../components/Display';

export const PlaceHolderTypes = {
	LOADING: 'LOADING',
	NO_CHANNELS: 'NO_CHANNELS',
	NO_CHANNEL: 'NO_CHANNEL',
	NO_MESSAGES: 'NO_MESSAGES',
} as const;

interface PlaceholderProps {
	comment?: string;
	type: keyof typeof PlaceHolderTypes;
}

export const Placeholder = (props: PlaceholderProps) => {
	const { type, comment } = props;

	return (
		<Flex $flex={'1 0 0'} $justify={'center'} $alignItems={'center'} style={{ position: 'relative' }}>
			{type === PlaceHolderTypes.LOADING && <Loading />}
			{type === PlaceHolderTypes.NO_CHANNELS && (
				<Label $color={'gray_700'} $fontStyle={'body_2'}>
					{comment}
				</Label>
			)}
			{(type === PlaceHolderTypes.NO_CHANNEL || type === PlaceHolderTypes.NO_MESSAGES) && (
				<Flex $direction={'column'} $alignItems={'center'} $gap={'1.6rem'} $flex={'1 0 0'}>
					<Icon id={IconID.CHAT} width={'8rem'} height={'8rem'} />
					<Label $color={'gray_700'} $fontStyle={'body_2'}>
						{comment}
					</Label>
				</Flex>
			)}
		</Flex>
	);
};
