import React, { useEffect, useState } from 'react';

import { useBlocker } from 'react-router-dom';
import styled from 'styled-components';

import { Notice } from './Notice';
import { Profile } from './Profile';
import { Schedule } from './Schedule';
import { Tabs } from '../../../components/Display';
import { ConfirmModal } from '../../../components/Modals';
import context from '../../../context';

type ModalActionFunc = () => void;
const MODAL_MESSAGE = `입력한 내용을 저장하지 않았습니다.\n나가기 시 작성 중인 내용은 초기화됩니다.\n입력을 중단하고 나가시겠습니까?`;

const App = () => {
	const { handleOpen } = context.modal.useDispatch();

	const [selectedTab, setSelectedTab] = useState<number>(0);
	// 영업 정보 영업 시간 수정 중일시 탭 이동, 라우터 이동, 화면 끌 때 알림 모달을 띄우기 위한 값
	const [isEditing, setIsEditing] = useState(false);

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && isEditing,
	);

	const handlePreventMove = (onConfirm: ModalActionFunc, onCancel: ModalActionFunc) => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					onConfirm();
				}}
				message={MODAL_MESSAGE}
				buttonMessage={{
					confirm: '나가기',
				}}
			/>,
		);
	};

	const handlePreventUnload = (e: BeforeUnloadEvent) => {
		e.preventDefault();
		e.returnValue = false;
	};

	useEffect(() => {
		if (isEditing) {
			if (blocker.state === 'blocked') {
				handlePreventMove(
					() => {
						blocker.proceed();
					},
					() => {
						blocker.reset();
					},
				);
			}
			(() => {
				window.addEventListener('beforeunload', handlePreventUnload);
			})();
		}
		return () => {
			window.removeEventListener('beforeunload', handlePreventUnload);
		};
	}, [isEditing, blocker]);

	const tabConfigs = [
		{
			label: '영업 정보',
			id: 'profile',
			panel: <Profile setIsEditing={setIsEditing} />,
		},
		{
			label: '약국 소식',
			id: 'notice',
			panel: <Notice />,
		},
		{
			label: '영업 시간',
			id: 'hours',
			panel: <Schedule setIsEditing={setIsEditing} />,
		},
	];

	const handleChangeTab = (idx: number) => {
		if (isEditing) {
			handlePreventMove(
				() => {
					setSelectedTab(idx);
					setIsEditing(false);
				},
				() => {},
			);
		} else {
			setSelectedTab(idx);
			setIsEditing(false);
		}
	};

	return (
		<Container>
			<Tabs
				defaultIndex={selectedTab}
				disableAutoSelect={true}
				onChange={handleChangeTab}
				contents={tabConfigs}
				tabGaps={{ vertical: '1.2rem', horizon: '1.6rem' }}
				hasBorderBottom={true}
				tabStyle={{ padding: '0 1.2rem', height: '5.6rem', zIndex: 2 }}
				labelStyle={{ $fontStyle: 'title_2' }}
				borderStyle={{ weight: { selected: '3px', default: '0px' } }}
			></Tabs>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
`;

export default App;
