import { type NoticeIndex, type NoticeItem, type GetNoticeRequest, type GetNoticeResponse } from './types';
import { Get } from '..';
import { formatQueryString } from '../../utils/format';

export const notice = {
	getList: async <T = GetNoticeResponse>(props: GetNoticeRequest): Promise<T> => {
		const response = await Get<T>(`/notices${formatQueryString(props)}`);
		return response.data;
	},
	getDetail: async <T = NoticeItem>({ id }: NoticeIndex): Promise<T> => (await Get<T>(`/notices/${id}`)).data,
};
