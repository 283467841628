import React, { useMemo } from 'react';

import { SwitchButton } from '../../../../components/Buttons';
import { usePutPushPreference } from '../../../../services/chat/queries';
import { InfoTitle } from '../InfoTitle';

import type { GroupChannel } from '@sendbird/chat/groupChannel';

export const NotificationSetting = ({ channel }: { channel: GroupChannel }) => {
	const { mutate: mutatePushPreference, isPending } = usePutPushPreference();
	const isOn = useMemo(() => channel.myPushTriggerOption !== 'off', [channel.myPushTriggerOption]);

	const handleToggleSwitch = (isOn: boolean) => {
		const pushTriggerOption = isOn ? 'default' : 'off';
		if (!isPending) {
			mutatePushPreference({ channelUrl: channel?.url, pushTriggerOption }, {});
		}
	};

	return (
		<InfoTitle title={'채팅 알림'} renderSuffix={<SwitchButton isOn={isOn} toggleSwitch={handleToggleSwitch} />} />
	);
};
