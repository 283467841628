import React from 'react';

import { SALES_ROWS } from './rows';
import { useGetSalesTotal } from '../../../../services/statistics/queries';

import { DescriptionCard } from './index';

interface SalesCardProps {
	storeId: string;
	startDate: string;
	endDate: string;
}

export function SalesCard({ storeId, startDate, endDate }: SalesCardProps) {
	const { data, isLoading, isSuccess } = useGetSalesTotal({
		storeId,
		startDate,
		endDate,
	});

	return (
		<DescriptionCard
			data={data}
			title={'판매'}
			isLoading={isLoading || !isSuccess}
			rowGaps={{ horizon: '4rem', vertical: '1.6rem' }}
			{...SALES_ROWS}
		/>
	);
}
