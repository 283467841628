type ErrorType = 'loginId' | 'password';

class FormError extends Error {
	type: ErrorType;
	constructor(message: string, type: ErrorType) {
		super(message);
		this.name = 'FormError';
		this.type = type;
	}
}

export type { ErrorType };

export default FormError;
