import { useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import {
	type StatisticsRequest,
	type GetPaymentDetailRequest,
	type GetPaymentsRequest,
	type PaymentDetail,
	type GetPaymentSearchType,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';
import { type DefaultOption } from '../../utils/consts';
import { formatMonth } from '../../utils/format';
import { type ErrorData } from '../types';

export const paymentsKeys = {
	all: ['payments'] as const,
	getPayments: (pagination: PaginationQueries, values: GetPaymentsRequest) =>
		[...paymentsKeys.all, values, pagination] as const,
	getPaymentDetail: ({ storeId, paymentId }: GetPaymentDetailRequest) =>
		[...paymentsKeys.all, 'detail', storeId, paymentId] as const,
	couponsStatistics: ({ storeId, ...queries }: StatisticsRequest) =>
		[...paymentsKeys.all, 'couponsStatistics', storeId, queries] as const,
	pointsStatistics: ({ storeId, ...queries }: StatisticsRequest) =>
		[...paymentsKeys.all, 'pointsStatistics', storeId, queries] as const,
};

export const PAYMENTS_SEARCH_OPTIONS: Array<DefaultOption<GetPaymentSearchType>> = [
	{
		key: 'NAME',
		label: '회원명',
	},
	{ key: 'PRODUCT_NAME', label: '상품명' },
];

interface UseGetPaymentsOptions {
	enabled?: boolean;
	historyKey?: {
		pagination: string;
		queries: string;
	};
	setInitialValues?: Partial<GetPaymentsRequest>;
}
export const useGetPayments = (storeId: string, options?: UseGetPaymentsOptions) => {
	const { enabled, historyKey, setInitialValues } = options ?? {};
	const initValues: GetPaymentsRequest = useMemo(
		() => ({
			storeId,
			medicationType: 'ALL',
			isApproval: 'ALL',
			payType: 'ALL',
			promotionId: -1,
			pharmSaleId: -1,
			search: {
				searchCategory: 'NAME',
				searchKeyword: '',
			},
			period: { start: null, end: null },
			sorts: {
				property: 'approvalAt',
				direction: 'DESC',
			},
		}),
		[storeId],
	);
	const pagination = usePagination({ historyKey: historyKey?.pagination });

	const { curPage, listCount, setPagination } = pagination;
	const queries = useValues(initValues, historyKey?.queries);

	useEffect(() => {
		if (setInitialValues) queries.dispatch('SET', setInitialValues);
	}, []);

	const queryInfo = useQuery({
		queryKey: paymentsKeys.getPayments({ curPage, listCount }, queries.values),
		queryFn: async () =>
			await api.payment.getPayments({
				page: curPage,
				limit: listCount,
				...queries.values,
			}),
		enabled: !!storeId && enabled,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isRefetching]);

	return { ...queryInfo, queries, pagination };
};

export const useGetPaymentDetail = (params: GetPaymentDetailRequest) => {
	const queryInfo = useQuery<PaymentDetail, AxiosError<ErrorData>>({
		queryKey: paymentsKeys.getPaymentDetail(params),
		queryFn: async () => await api.payment.getPaymentDetail(params),
		enabled: !!params.paymentId && !!params.storeId,
	});
	return queryInfo;
};

export const useGetPaymentsExcel = (props: MutateCallback<GetPaymentsRequest, Blob>) => {
	const { onError, onSuccess } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: GetPaymentsRequest) => await api.payment.getPaymentsExcel(params),
		onSuccess,
		onError,
	});
	return queryInfo;
};

export const useGetCouponsStatistics = (storeId: string) => {
	const { start, end } = useMemo(() => formatMonth(new Date()), []);

	const initValues: StatisticsRequest = useMemo(
		() => ({
			storeId,
			period: {
				start,
				end,
			},
		}),
		[storeId],
	);
	const queries = useValues(initValues, 'couponsStatistics');
	const queryInfo = useQuery({
		queryKey: paymentsKeys.couponsStatistics(queries.values),
		queryFn: async () => await api.payment.getCouponsStatistics(queries.values),
	});

	return { ...queryInfo, queries };
};

export const useGetPointsStatistics = (storeId: string) => {
	const { start, end } = useMemo(() => formatMonth(new Date()), []);

	const initValues: StatisticsRequest = useMemo(
		() => ({
			storeId,
			period: {
				start,
				end,
			},
		}),
		[storeId],
	);
	const queries = useValues(initValues, 'pointsStatistics');
	const queryInfo = useQuery({
		queryKey: paymentsKeys.pointsStatistics(queries.values),
		queryFn: async () => await api.payment.getPointsStatistics(queries.values),
	});

	return { ...queryInfo, queries };
};

export const usePostPickup = (callback: MutateCallback<GetPaymentDetailRequest, unknown>) =>
	useMutation({ mutationFn: async (params) => await api.payment.postPickup(params), ...callback });
