import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { alertMessage, DELETE_REST_DAYS_MODAL_TITLE, scheduleLabels, TIME_KEY_GROUPS } from './consts';
import { DayHours } from './DayHours';
import { RestDayModal } from './RestDayModal';
import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { Button } from '../../../../components/Buttons';
import { InsideTitle, Loading, VStack } from '../../../../components/Common';
import { Icon, IconID, Label, MarkInfoIcon } from '../../../../components/Display';
import { AlertModal, ConfirmModal } from '../../../../components/Modals';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import {
	OpenHoursMutationKeys,
	useDeletePharmRestDays,
	useGetPharmOpenHours,
	useGetPharmRestDays,
	usePostPharmRestDays,
	usePutPharmOpenHours,
} from '../../../../services/pharm/queries';
import { formatDate } from '../../../../utils/format';
import { areDeeplyEqual } from '../../../../utils/validators';

import type { OpenHours } from '../../../../services/pharm/types';

interface ScheduleProps {
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Schedule = ({ setIsEditing }: ScheduleProps) => {
	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;
	const { handleOpen, handleClose } = context.modal.useDispatch();

	const { data: openHours, isLoading: openHoursLoading, isSuccess } = useGetPharmOpenHours({ storeId });
	const { values: openHoursValues, dispatch: openHoursDispatch } = useValues<OpenHours>({} as OpenHours);
	const { mutate: openHoursMutate, isPending: isOpenHoursPending } = usePutPharmOpenHours({ storeId });

	const { data: restDays, isLoading: restDaysLoading } = useGetPharmRestDays({ storeId });

	const { mutate: addRestDaysMutate } = usePostPharmRestDays({ storeId });
	const { mutate: deleteRestDaysMutate, isPending: isDeleteRestDaysPending } = useDeletePharmRestDays({ storeId });

	const [validOpenHoursMessage, setValidOpenHoursMessage] = useState<string>('');

	useEffect(() => {
		if (isSuccess) {
			openHoursDispatch('SET', openHours);
		}
	}, [openHours]);

	useEffect(() => {
		const isEditing = !areDeeplyEqual(openHoursValues, openHours ?? {}) || !!validOpenHoursMessage.length;
		setIsEditing((prev) => (prev !== isEditing ? isEditing : prev));
	}, [openHoursValues]);

	const formattedRestDays = useMemo(() => {
		if (!restDays) return [];
		return restDays.slice().map((day) => ({ ...day, date: formatDate(new Date(day.date), 'yyyy-MM-dd D요일') }));
	}, [restDays]);

	const handleClickSave = async () => {
		if (validOpenHoursMessage !== '') {
			handleOpen(<AlertModal message={validOpenHoursMessage} />);
			return null;
		}
		openHoursMutate(
			{ storeId, ...openHoursValues },
			{
				onSuccess: () => {
					toast.success(alertMessage.openHours.success);
				},
				onError: () => {
					toast.error(alertMessage.openHours.error);
				},
			},
		);
	};

	const handleClickRegister = (date: Date) => {
		addRestDaysMutate(
			{ storeId, date: formatDate(date) },
			{
				onSuccess: () => {
					toast.success(alertMessage.registerRestDays.success);
				},
				onError: () => {
					toast.error(alertMessage.registerRestDays.error);
				},
			},
		);
		handleClose();
	};

	const handleClickCancel = (value: number, date: string) => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					deleteRestDaysMutate(
						{ storeId, restDayId: String(value) },
						{
							onSuccess: () => {
								toast.success(alertMessage.deleteRestDays.success);
							},
							onError: () => {
								toast.error(alertMessage.deleteRestDays.error);
							},
						},
					);
				}}
				message={`${DELETE_REST_DAYS_MODAL_TITLE}${date}`}
				buttonMessage={{ confirm: '등록 취소', shouldPrevent: true }}
			/>,
		);
	};

	return (
		<Container>
			{(openHoursLoading || restDaysLoading || isOpenHoursPending || isDeleteRestDaysPending) && <Loading />}
			<Wrapper>
				<Content>
					<InsideTitle
						title={scheduleLabels.openHours.title}
						rightRender={
							<Button
								style={{ width: '10rem' }}
								onClick={handleClickSave}
								shouldPrevent={true}
								mutationKey={OpenHoursMutationKeys}
							>
								{scheduleLabels.openHours.button}
							</Button>
						}
					/>
					<WeekWrapper>
						{Object.keys(openHoursValues).length &&
							TIME_KEY_GROUPS.map((timeKey, idx) => (
								<DayHours
									key={idx}
									dispatch={openHoursDispatch}
									timeKey={timeKey}
									openHours={openHoursValues}
									setAlertMessage={setValidOpenHoursMessage}
								/>
							))}
					</WeekWrapper>
					<InfoWrapper>
						<MarkInfoIcon type={'LINE'} bgColor={'gray_700'} color={'white'} />
						<Label $color={'gray_700'} $fontStyle={'body_3'}>
							{scheduleLabels.openHours.info}
						</Label>
					</InfoWrapper>
				</Content>
				<Content>
					<InsideTitle
						title={scheduleLabels.restDays.title}
						rightRender={
							<Button
								buttonType={'LINE'}
								color={'SECONDARY'}
								size={{ $paddingSize: 'M' }}
								shouldPrevent={false}
								onClick={() => {
									handleOpen(
										<RestDayModal
											onRegister={handleClickRegister}
											excludeDates={[...formattedRestDays.map((cur) => cur.date)]}
										/>,
									);
								}}
							>
								<Icon id={IconID.VACATION} width={'2rem'} height={'2rem'} defaultColor={'gray_800'} />
								{scheduleLabels.restDays.button}
							</Button>
						}
					/>
					<RestDaysWrapper>
						{formattedRestDays.length ? (
							<>
								{formattedRestDays.map((day, idx) => (
									<RestDayItem key={idx}>
										<RestDayNumber>{idx + 1}</RestDayNumber>
										<RestDayDate>{day.date}</RestDayDate>
										<Button
											size={{ $fontSize: 'M' }}
											style={{ border: 0, backgroundColor: 'inherit', padding: '0 0.4rem' }}
											buttonType={'LINE'}
											color={'RED'}
											onClick={() => {
												handleClickCancel(day.id, day.date);
											}}
										>
											{'등록취소'}
										</Button>
									</RestDayItem>
								))}
							</>
						) : (
							<Label $color={'gray_700'} $fontStyle={'label_2'}>
								{scheduleLabels.restDays.placeholder}
							</Label>
						)}
					</RestDaysWrapper>
					<InfoWrapper>
						<MarkInfoIcon type={'LINE'} bgColor={'gray_700'} color={'white'} />
						<Label $color={'gray_700'} $fontStyle={'body_3'}>
							{scheduleLabels.restDays.info}
						</Label>
					</InfoWrapper>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled(VStack)`
	align-self: stretch;
	height: 100%;
	width: 100%;
`;

const Wrapper = styled(VStack)`
	align-items: flex-start;
	align-self: stretch;
	flex: 1 1 0;
	gap: 2.4rem;
	${scrollStyle()}
`;

const Content = styled(VStack)`
	align-items: stretch;
	align-self: stretch;
	max-width: 86rem;
`;

const WeekWrapper = styled(VStack)`
	overflow: hidden;
	border-radius: 4px 4px 0 0;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const RestDaysWrapper = styled(VStack)`
	min-height: 7.2rem;
	padding: 1.2rem 1.6rem;
	justify-content: center;
	align-items: center;
	gap: 1.2rem;
	align-self: stretch;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.colors.gray.gray_100};
`;

const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	height: 4.8rem;
`;

const RestDayItem = styled.div`
	display: flex;
	align-items: center;
	gap: 1.2rem;
	align-self: stretch;
`;

const RestDayNumber = styled.div`
	display: flex;
	width: 2.4rem;
	height: 2.4rem;
	padding: 0 1.2rem;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.gray.gray_800};
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.font.label.label_2};
	${({ theme }) => theme.font.weight.bold};
`;

const RestDayDate = styled.div`
	display: flex;
	height: 4rem;
	padding: 1.2rem;
	align-items: center;
	gap: 0.8rem;
	flex: 1 0 0;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	opacity: 0.8;
	background: ${({ theme }) => theme.colors.white};

	${({ theme }) => theme.font.label.label_2};
`;
