import { useEffect, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import {
	type GetMemberRequest,
	type GetMembersRequest,
	type PostAndDeleteMemberData,
	type GetMemberInfo,
	type GetMemberActivityRequest,
	type GetMemberPaymentsRequest,
	type MemberPayment,
	type PutMemberMemoRequest,
	type PutMemberNicknamesRequest,
	type GetMemberActivities,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { useValues } from '../../hooks/useValues';
import { type ErrorData, type PaginationRequest } from '../types';

import type { MutateCallback, QuerySelect } from '../../hooks/types';

export const membersKeys = {
	all: ['members'] as const,
	list: (pagination: PaginationQueries, queries: GetMembersRequest) =>
		[...membersKeys.all, 'list', pagination, queries] as const,
	info: ({ storeId, userId }: GetMemberRequest) => [...membersKeys.all, 'info', storeId, userId] as const,
	group: ({ storeId, userId }: GetMemberRequest) => [...membersKeys.all, 'group', storeId, userId] as const,
	activity: ({ storeId, userId, isPayment, sorts }: GetMemberActivityRequest) =>
		[...membersKeys.all, 'activity', storeId, userId, isPayment, sorts] as const,
	payment: (
		{ storeId, userId }: GetMemberRequest,
		pagination: PaginationQueries,
		sorts: PaginationRequest<MemberPayment>['sorts'],
	) => [...membersKeys.all, 'payments', storeId, userId, pagination, sorts] as const,
	totalPayment: ({ storeId, userId }: GetMemberRequest) =>
		[...membersKeys.all, 'total-payments', storeId, userId] as const,
	count: (storeId: string) => [...membersKeys.all, 'count', storeId] as const,
} as const;
interface UseGetMembersProps {
	storeId: string;
	historyKey?: {
		pagination: string;
		queries: string;
	};
	initialValues?: Partial<GetMembersRequest>;
}
export const useGetMembers = ({
	storeId,
	initialValues,
	historyKey = { pagination: 'getMembersPage', queries: 'getMembersQueries' },
}: UseGetMembersProps) => {
	const pagination = usePagination({ historyKey: historyKey.pagination });
	const { curPage, listCount, setPagination } = pagination;
	const initValues: GetMembersRequest = useMemo(
		() => ({
			storeId,
			gender: 'ALL',
			ages: ['ALL'],
			isPayment: 'ALL',
			groupIds: [-1],
			period: {
				start: null,
				end: null,
			},
			search: {
				searchCategory: 'PHONE',
				searchKeyword: '',
			},
			sorts: {
				property: null,
				direction: null,
			},
			isFavorite: 'ALL',
			...initialValues,
		}),
		[storeId, initialValues],
	);
	const queries = useValues(initValues, historyKey.queries);

	const queryInfo = useQuery({
		queryKey: membersKeys.list({ curPage, listCount }, queries.values),
		queryFn: async () => await api.member.getMembers({ page: curPage, limit: listCount, ...queries.values }),
		enabled: !!storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination, initValues };
};

export const useGetMembersExcel = (props: MutateCallback<GetMembersRequest, Blob>) => {
	const { onSuccess, onError } = props ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: GetMembersRequest) => await api.member.getMembersExcel(params),
		onSuccess,
		onError,
		mutationKey: ['excel'],
	});

	return queryInfo;
};

export function useMemberInfo<TResult = GetMemberInfo>({
	storeId,
	userId,
	select,
}: GetMemberRequest & QuerySelect<GetMemberInfo, TResult>) {
	return useQuery<GetMemberInfo, AxiosError<ErrorData>, TResult>({
		queryKey: membersKeys.info({ storeId, userId }),
		queryFn: async () => await api.member.getMember({ storeId, userId }),
		enabled: !!storeId && !!userId,
		select: (data) => (typeof select === 'function' ? select?.(data) : data) as TResult,
	});
}

export function useMemberGroup({ storeId, userId }: GetMemberRequest) {
	return useQuery({
		queryKey: membersKeys.group({ storeId, userId }),
		queryFn: async () => await api.member.getMemberGroup({ storeId, userId }),
		enabled: !!storeId && !!userId,
	});
}

export function useDeleteMemberGroup({
	storeId,
	userId,
	onSuccess,
	onError,
}: GetMemberRequest & MutateCallback<PostAndDeleteMemberData, any>) {
	return useMutation({
		mutationFn: async (data: PostAndDeleteMemberData) => await api.member.deleteMemberGroup({ storeId, userId, data }),
		onSuccess,
		onError,
	});
}

export function usePostMemberGroup({
	storeId,
	userId,
	onSuccess,
	onError,
}: GetMemberRequest & MutateCallback<PostAndDeleteMemberData, any>) {
	return useMutation({
		mutationFn: async (data: PostAndDeleteMemberData) => await api.member.postMemberGroup({ storeId, userId, data }),
		onSuccess,
		onError,
	});
}

export function useMemberActivities<TResult = GetMemberActivities>({
	storeId,
	userId,
	isPayment,
	sorts,
	select,
}: GetMemberActivityRequest & QuerySelect<GetMemberActivities, TResult>) {
	return useQuery<GetMemberActivities, AxiosError<ErrorData>, TResult>({
		queryKey: membersKeys.activity({ storeId, userId, isPayment, sorts }),
		queryFn: async () => await api.member.getMemberActivities({ storeId, userId, isPayment, sorts }),
		enabled: !!storeId && !!userId,
		select: (data) => (typeof select === 'function' ? select?.(data) : data) as TResult,
	});
}

export function useMemberPayments({ storeId, userId }: GetMemberPaymentsRequest) {
	const pagination = usePagination();
	const { curPage, listCount, setPagination } = pagination;
	const initValues: PaginationRequest<MemberPayment> = useMemo(
		() => ({ sorts: { direction: 'DESC', property: 'approvalAt' } }),
		[],
	);
	const queries = useValues(initValues);
	const queryInfo = useQuery({
		queryKey: membersKeys.payment({ storeId, userId }, { curPage, listCount }, queries.values.sorts),
		queryFn: async () =>
			await api.member.getMemberPayments({
				storeId,
				userId,
				page: curPage,
				limit: listCount,
				sorts: queries.values.sorts,
			}),
		enabled: !!storeId && !!userId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.isSuccess]);

	return { ...queryInfo, pagination, queries };
}

export function useMemberTotalPayment({ storeId, userId }: GetMemberRequest) {
	return useQuery({
		queryKey: membersKeys.totalPayment({ storeId, userId }),
		queryFn: async () => await api.member.getMemberTotalPayment({ storeId, userId }),
		enabled: !!storeId && !!userId,
	});
}

export const useGetMemberCount = (storeId: string) => {
	return useQuery({
		queryKey: membersKeys.count(storeId),
		queryFn: async () => await api.member.getMemberCount({ storeId }),
		enabled: !!storeId,
	});
};

export const usePutMemberMemo = ({ storeId, userId }: GetMemberRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, AxiosError<ErrorData>, PutMemberMemoRequest>({
		mutationFn: async (params: PutMemberMemoRequest) => {
			await api.member.putMemberMemo(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: membersKeys.info({ storeId, userId }),
			});
		},
	});
};

export const usePutMemberNicknames = ({ storeId, userId }: GetMemberRequest) => {
	const queryClient = useQueryClient();
	return useMutation<unknown, AxiosError<ErrorData>, PutMemberNicknamesRequest>({
		mutationFn: async (params: PutMemberNicknamesRequest) => {
			await api.member.putMemberNicknames(params);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: membersKeys.info({ storeId, userId }),
			});
		},
	});
};
