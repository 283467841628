import React from 'react';

import styled from 'styled-components';

export const Footer = ({ children, ...rest }: React.HTMLAttributes<HTMLElement>) => {
	return <Container {...rest}>{children}</Container>;
};

const Container = styled.footer`
	position: sticky;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 2rem;
	margin: 0 -2rem -2rem;
	display: flex;
	gap: 0.8rem;
	justify-content: flex-end;
	left: 0;
	right: 0;
	bottom: 0;
	border-top: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;
