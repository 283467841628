import React from 'react';

import { useNavigate } from 'react-router-dom';

import { groupTableColumns } from './tableColumns';
import { Button } from '../../../components/Buttons';
import { Flex, InsideTitle } from '../../../components/Common';
import { Icon, IconID } from '../../../components/Display';
import { Filter } from '../../../components/Filter';
import { FilterList } from '../../../components/Filter/components/FilterList';
import { type FilterItems } from '../../../components/Filter/components/FilterModal/types';
import { ListTable } from '../../../components/Table';
import { useGetGroups } from '../../../services/groups/queries';
import { type GetGroupsRequest } from '../../../services/groups/types';
import { GROUP_CATEGORY_OPTIONS } from '../../../utils/consts';
import { handleChangeSorts } from '../../../utils/onChangeSorts';

const Group = () => {
	const { data, pagination, queries } = useGetGroups();
	const navigate = useNavigate();

	const filterItems: FilterItems<GetGroupsRequest> = [
		{
			label: '그룹',
			key: 'category',
			type: 'RADIO',
			radioProps: {
				name: 'category',
				options: GROUP_CATEGORY_OPTIONS,
				$direction: 'COLUMN',
			},
		},
	];

	return (
		<React.Fragment>
			<ListTable
				data={data?.content}
				columns={groupTableColumns}
				unit="개"
				pagination={pagination}
				onRowClick={(item) => {
					navigate(`/operation/group/detail/${item.id}`);
				}}
				onCategoryClick={(column) => {
					handleChangeSorts(queries.values.sorts, column, (sorts) => {
						queries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={queries.values.sorts}
				headerLeftContent={<InsideTitle title="전체 그룹" style={{ minHeight: 'auto', padding: '0' }} />}
				headerRightContent={
					<Flex $gap={'0.8rem'}>
						<Filter items={filterItems} resetCurPage={pagination.resetCurPage} queries={queries} />
						<Button
							buttonType="LINE"
							color="SECONDARY"
							onClick={() => {
								navigate('/operation/group/create');
							}}
						>
							<Icon id={IconID.GROUP_PLUS} width="2rem" height="2rem" />
							그룹 생성
						</Button>
					</Flex>
				}
				headerBottomContent={
					<FilterList items={filterItems} resetCurPage={pagination.resetCurPage} queries={queries} />
				}
			/>
		</React.Fragment>
	);
};

export default Group;
