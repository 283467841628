import React, { type CSSProperties, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import {
	DAILY_DETAILS_COLUMNS,
	MONTHLY_DETAILS_COLUMNS,
	WEEKDAY_AVG_DETAILS_COLUMNS,
	WEEKDAY_DETAILS_COLUMNS,
	WEEKLY_DETAILS_COLUMNS,
} from './columns';
import { StatsStyledTable } from './styles';
import { InsideTitle } from '../../../../components/Common';
import { Label } from '../../../../components/Display';
import ListTable from '../../../../components/Table/ListTable';
import { type Columns } from '../../../../components/Table/ListTable/types';
import {
	type ExtendedPeriodUnit,
	type UnitQueryResultType,
	useGetPeriodDetails,
} from '../../../../services/statistics/queries';
import { Content } from '../styles';

interface PeriodDetailsProps {
	storeId: string;
	startDate: string;
	endDate: string;
	unit: ExtendedPeriodUnit;
	showViewDetails?: boolean; // 상세 컬럼 유무
	isAverage?: boolean;
	maxHeight?: string;
	isScroll?: boolean;
	style?: CSSProperties;
}

export function PeriodDetails({
	storeId,
	startDate,
	endDate,
	unit,
	showViewDetails = false,
	isAverage = false,
	maxHeight,
	isScroll,
	style,
}: PeriodDetailsProps) {
	const navigate = useNavigate();

	const { data } = useGetPeriodDetails<typeof unit>({
		storeId,
		unit,
		period: [{ startDate, endDate }],
	});

	const periodColumns = useMemo(() => {
		switch (unit) {
			case 'daily':
				return [
					...DAILY_DETAILS_COLUMNS,
					{
						...(showViewDetails && {
							key: 'receivePrice',
							headerName: '상세보기',
							width: { max: '8rem' },
							style: { justifyContent: 'center' },
							renderComponent: (_, item) => {
								return (
									<Label
										as={'button'}
										className={'table-item-as-button'}
										onClick={() => {
											navigate(`/stats/details/daily?date=${item.day}`);
										}}
									>
										{'상세'}
									</Label>
								);
							},
						}),
					},
				] as Columns<UnitQueryResultType<'daily'>>;
			case 'weekly':
				return [
					...WEEKLY_DETAILS_COLUMNS,
					{
						...(showViewDetails && {
							key: 'receivePrice',
							headerName: '상세보기',
							width: { max: '8rem' },
							style: { justifyContent: 'center' },
							renderComponent: (_, item) => {
								return (
									<Label
										as={'button'}
										className={'table-item-as-button'}
										onClick={() => {
											navigate(`/stats/details/weekly?date=${item.startDate}`);
										}}
									>
										{'상세'}
									</Label>
								);
							},
						}),
					},
				] as Columns<UnitQueryResultType<'weekly'>>;
			case 'monthly':
				return [
					...MONTHLY_DETAILS_COLUMNS,
					{
						...(showViewDetails && {
							key: 'receivePrice',
							headerName: '상세보기',
							width: { max: '8rem' },
							style: { justifyContent: 'center' },
							renderComponent: (_, item) => {
								return (
									<Label
										as={'button'}
										className={'table-item-as-button'}
										onClick={() => {
											navigate(`/stats/details/monthly?date=${item.month}`);
										}}
									>
										{'상세'}
									</Label>
								);
							},
						}),
					},
				] as Columns<UnitQueryResultType<'monthly'>>;
			case 'weekday':
				return (isAverage ? [...WEEKDAY_AVG_DETAILS_COLUMNS] : [...WEEKDAY_DETAILS_COLUMNS]) as Columns<
					UnitQueryResultType<'weekday'>
				>;
		}
	}, [unit, showViewDetails, navigate, isAverage]);

	return (
		<Content $gap={'1.2rem'}>
			<InsideTitle title={'상세 통계'} />
			<StatsStyledTable style={style}>
				<ListTable<UnitQueryResultType<typeof unit>>
					data={data[0]}
					columns={[...periodColumns] as Columns<UnitQueryResultType<typeof unit>>}
					maxHeight={maxHeight}
					isScroll={isScroll}
				/>
			</StatsStyledTable>
		</Content>
	);
}
