import React from 'react';

import { StyledContainer } from './quill';

export const TextEditorViewer = ({ data }: { data: string }) => {
	return (
		<StyledContainer>
			<div className="ql-editor" dangerouslySetInnerHTML={{ __html: data }} />
		</StyledContainer>
	);
};
