import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FormItem, TextField } from '../../../../../components/Forms';
import { Modal } from '../../../../../components/Modals';
import context from '../../../../../context';
import { useValues } from '../../../../../hooks/useValues';
import { usePostPasswordMatches } from '../../../../../services/users/queries';

export const PasswordCheckModal = () => {
	const navigate = useNavigate();
	const { handleClose } = context.modal.useDispatch();
	const { values, onChangeValues } = useValues({ password: '' });
	const [statusMessage, setStatusMessage] = useState('');

	const { mutate } = usePostPasswordMatches({
		onSuccess: (res) => {
			if (res.matches) {
				handleClose();
				navigate('/operation/basic/management');
			} else {
				setStatusMessage('비밀번호가 일치하지 않습니다. 다시 입력해 주세요.');
			}
		},
		onError: (err) => {
			toast.error('비밀번호 검증에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onSubmit = useCallback(() => {
		mutate(values);
	}, [values]);

	const onKeydown = useCallback(
		(e?: React.KeyboardEvent<HTMLInputElement>) => {
			if (e?.key === 'Enter') onSubmit();
		},
		[values],
	);

	return (
		<Modal
			header={{
				title: (
					<Title>
						<p>사용자 관리</p>
						<p className="description">안전한 관리를 위해 비밀번호를 입력해 주세요.</p>
					</Title>
				),
			}}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '확인',
						onClick: onSubmit,
						style: { width: '10rem' },
						shouldPrevent: true,
						disabled: values.password.length <= 0,
					},
				],
			}}
		>
			<Container>
				<FormItem label={'비밀번호'} statusMessage={statusMessage} isRequired>
					<TextField
						value={values.password}
						onChange={onChangeValues}
						name="password"
						placeholder="비밀번호를 입력해 주세요."
						type="password"
						style={{ height: '4rem' }}
						onKeyDown={onKeydown}
					/>
				</FormItem>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	width: 80rem;
	padding: 2.4rem 2rem 3.2rem;
`;

const Title = styled.div`
	display: flex;
	flex-direction: column;
	.description {
		${({ theme }) => theme.font.body.body_2};
		font-weight: 400;
		color: ${({ theme }) => theme.colors.gray.gray_800};
	}
`;
