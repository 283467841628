import React, { useCallback, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';

import { Button, IconButton } from '../../../../components/Buttons';
import { HStack, VStack } from '../../../../components/Common';
import { Icon, IconID, InfoText, Tag } from '../../../../components/Display';
import { TextField } from '../../../../components/Forms';
import { useValues } from '../../../../hooks/useValues';
import { usePutMemberNicknames } from '../../../../services/member/queries';
import { type PutMemberNicknamesRequest } from '../../../../services/member/types';
import { InfoTitle } from '../InfoTitle';

const INITIAL_VALUES: { nickname: string } = {
	nickname: '',
};

export const NICKNAME_MAX_COUNT = 3;

export const NICKNAME_MESSAGES = {
	INFO: `닉네임은 ${NICKNAME_MAX_COUNT}개까지 등록 가능합니다.`,
	POST_SUCCESS: '닉네임이 추가되었습니다.',
	POST_ERROR: '등록에 실패하였습니다.',
	DELETE_SUCCESS: '닉네임이 삭제되었습니다.',
	DELETE_ERROR: '삭제에 실패하였습니다.',
	PLACEHOLDER: '닉네임을 입력해 주세요.',
} as const;

export const NicknameInfo = ({ nicknames, ...params }: PutMemberNicknamesRequest) => {
	const [isOpen, setOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const { mutate } = usePutMemberNicknames(params);
	const { values, dispatch, onChangeValues } = useValues(INITIAL_VALUES);

	const handleNickname = useCallback(
		(method: 'POST' | 'DELETE', value: string[] | string) => {
			try {
				switch (method) {
					case 'POST':
						if (!Array.isArray(value) && value !== '')
							mutate(
								{ ...params, nicknames: [...nicknames, value] },
								{
									onSuccess: () => {
										toast.success(NICKNAME_MESSAGES.POST_SUCCESS);
										setOpen(false);
									},
									onError: (err) => {
										toast.error(`${NICKNAME_MESSAGES.POST_ERROR}\n${err.response?.data.message}`);
									},
								},
							);
						break;
					case 'DELETE':
						if (Array.isArray(value))
							mutate(
								{ ...params, nicknames: value },
								{
									onSuccess: () => {
										toast.success(NICKNAME_MESSAGES.DELETE_SUCCESS);
									},
									onError: (err) => {
										toast.error(`${NICKNAME_MESSAGES.DELETE_ERROR}\n${err.response?.data.message}`);
									},
								},
							);
						break;
				}
			} finally {
				dispatch('RESET');
			}
		},
		[nicknames],
	);

	return (
		<VStack $gap={'0.8rem'} $alignSelf={'stretch'}>
			<InfoTitle
				title={'닉네임'}
				renderSuffix={
					<Button
						onClick={() => {
							setOpen(!isOpen);
							const input = inputRef.current;
							if (input && !isOpen) {
								input.focus();
							}
						}}
						buttonType="LINE"
						color="SECONDARY"
						size={{ $fontSize: 'S', $paddingSize: 'S', $heightSize: 'XS' }}
					>
						추가
					</Button>
				}
			/>
			<Form
				$isOpen={isOpen}
				onSubmit={(e) => {
					e.preventDefault();
					if (isOpen)
						if (nicknames.length !== NICKNAME_MAX_COUNT) handleNickname('POST', values.nickname);
						else toast.error(NICKNAME_MESSAGES.INFO);
				}}
			>
				<TextField
					name="nickname"
					value={values.nickname}
					onChange={onChangeValues}
					placeholder={NICKNAME_MESSAGES.PLACEHOLDER}
					maxLength={10}
					count={{ show: true, max: 10 }}
					disabled={nicknames.length === NICKNAME_MAX_COUNT}
					ref={inputRef}
				/>
				<InfoText
					text={`${NICKNAME_MESSAGES.INFO} (${nicknames.length}/${NICKNAME_MAX_COUNT}개)`}
					style={{ padding: 0 }}
					innerStyle={{ gap: '0.4rem', alignItems: 'center' }}
					font="caption_1"
					color={{ background: 'white', font: 'gray_700' }}
				/>
			</Form>

			{nicknames.length > 0 && (
				<HStack $gap={'0.6rem'} $wrap="wrap">
					{nicknames.map((nickname, idx) => (
						<Tag key={'nickname--' + nickname} fontSize={'body_3'} color={'PRIMARY'} type={'FILL'}>
							{nickname}
							<IconButton
								width="1.4rem"
								height="1.4rem"
								shouldPrevent={true}
								onClick={() => {
									const filterNickname = nicknames.filter((_, i) => i !== idx);
									handleNickname('DELETE', filterNickname);
								}}
							>
								<Icon id={IconID.BTN_CLOSE} width="1.3rem" height="1.3rem" defaultColor="gray_600" />
							</IconButton>
						</Tag>
					))}
				</HStack>
			)}
		</VStack>
	);
};

const Form = styled.form<{ $isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	${({ $isOpen }) =>
		!$isOpen &&
		css`
			pointer-events: none;
			opacity: 0;
			max-height: 0;
		`}
`;
