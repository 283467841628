import React from 'react';

import styled, { type CSSProperties } from 'styled-components';

import { changeColorKeyToType, type ColorKeys } from '../../../utils/changeColorKeyToType';
import { changeFontKeyToType, type FontKeys } from '../../../utils/changeFontKeyToType';
import { MarkInfoIcon } from '../MarkInfoIcon';

interface Color {
	background: ColorKeys;
	font: ColorKeys;
}

interface InfoTextProps {
	text: string;
	type?: 'info' | 'error';
	color?: Color;
	font?: FontKeys;
	style?: CSSProperties;
	innerStyle?: CSSProperties;
	suffix?: React.ReactNode;
}

/**
 *
 * @description type='info' 아이콘 'i' type='error' 아이콘 '!'
 */
export const InfoText = ({
	text,
	type = 'info',
	color = { background: 'red_200', font: 'red_600' },
	font = 'body_3',
	style,
	innerStyle,
	suffix,
}: InfoTextProps) => {
	return (
		<InfoTextWrap $color={color} style={style} $font={font} className="info__text">
			<div className="info__text--inner" style={innerStyle}>
				<MarkInfoIcon type={'LINE'} bgColor={color?.font} color={color?.background} rotate={type === 'error'} />
				<span>{text}</span>
			</div>
			{suffix && <div className="suffix">{suffix}</div>}
		</InfoTextWrap>
	);
};

const InfoTextWrap = styled.div<{ $color: Color; $font: FontKeys }>`
	background-color: ${({ $color }) => changeColorKeyToType($color.background)};
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 1.2rem 1.6rem 1.2rem 1.2rem;
	border-radius: 8px;

	.info__text--inner {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		white-space: pre-wrap;
		gap: 0.6rem;
		span {
			flex: 1;
			word-break: break-all;
			${({ $font }) => changeFontKeyToType($font)};
			${({ theme }) => theme.font.weight.medium};
			color: ${({ $color }) => changeColorKeyToType($color.font)};
		}
	}
	.suffix {
		flex: 1;
	}
`;
