import React, { type CSSProperties } from 'react';

import styled, { css } from 'styled-components';

interface InsideTitleProps {
	title: string | React.ReactNode;
	rightRender?: React.ReactNode;
	isBorder?: boolean;
	style?: CSSProperties;
}

export const InsideTitle = ({ title, rightRender, isBorder = false, style }: InsideTitleProps) => {
	return (
		<Wrapper className={'inside-title'} $isBorder={isBorder} style={style}>
			<h3>{title}</h3>
			{rightRender && <div className="right">{rightRender}</div>}
		</Wrapper>
	);
};

const Wrapper = styled.div<{ $isBorder: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1.2rem 0;
	min-height: 6.4rem;
	h3 {
		${({ theme }) => theme.font.title.title_2};
		${({ theme }) => theme.font.weight.bold};
	}
	${({ $isBorder }) =>
		$isBorder &&
		css`
			border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
		`}
`;
