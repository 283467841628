import React, { useMemo, useRef } from 'react';

import styled, { css } from 'styled-components';

import context from '../../../context';
import { type Asset } from '../../../services/asset/types';
import { ViewerModal } from '../../Modals/ViewerModal';

const ERROR_IMAGE = '/images/others/image_error.png';
interface ImageProps extends Omit<StyleProps, '$url' | '$length'> {
	assets: Asset[] | Asset;
}
interface StyleProps {
	$length: number;
	$width?: string;
	$height?: string;
	$aspectRatio?: string;
}
export const AssetsImage = ({ assets, $width = '14rem', ...rest }: ImageProps) => {
	const { handleOpen } = context.modal.useDispatch();
	const errorRef = useRef(false);
	const target = useMemo(() => {
		const result: Asset[] = [];
		if (Array.isArray(assets) && assets.length > 0) result.push(...assets);
		else if (!Array.isArray(assets) && assets) result.push(assets);
		return result;
	}, [assets]);

	const handleClickImage = (assets: Asset[]) => {
		const imageList = assets.map((el) => ({ fileName: el.name, url: el.url, expiredAt: el.expiredAt }));
		handleOpen(<ViewerModal imageList={imageList} />);
	};
	return (
		<Wrapper
			{...{
				$length: Array.isArray(assets) ? assets.length : assets ? 1 : 0,
				$width,
				...rest,
			}}
			onClick={(e) => {
				e.stopPropagation();
				target.length > 0 && !errorRef.current && handleClickImage(target);
			}}
		>
			<img
				src={target.length > 0 ? target[0].url : ERROR_IMAGE}
				alt={target[0]?.name}
				loading="lazy"
				onError={(e) => {
					(e.target as HTMLImageElement).src = ERROR_IMAGE;
					(e.target as HTMLImageElement).style.objectFit = 'contain';
					errorRef.current = true;
				}}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.picture<StyleProps>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: ${({ $length }) => ($length === 0 ? 'contain' : 'cover')};
		image-rendering: -webkit-optimize-contrast; /* chrome */
		image-rendering: crisp-edges;
		transform: translateZ(0);
		backface-visibility: hidden;
	}

	border-radius: 4px;

	${({ $width, $height, $aspectRatio }) => css`
		width: ${$width};
		${$height
			? css`
					height: ${$height};
				`
			: css`
					aspect-ratio: ${$aspectRatio ?? '1/1'};
				`}
	`};
	${({ $length }) =>
		$length > 0 &&
		css`
			cursor: pointer;
		`}
	${({ $length }) =>
		$length > 1 &&
		css`
			&::after {
				content: '+${$length}';
				position: absolute;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				right: 0;
				bottom: 0;
				width: 3.2rem;
				height: 3.2rem;
				border-radius: 4px 0 0 0;
				background-color: rgba(0, 0, 0, 0.8);
				color: ${({ theme }) => theme.colors.white};
				${({ theme }) => theme.font.label.label_2};
				font-weight: 700;
			}
		`}
`;
