import { type Direction } from '../services/types';

const onChangeSortDirection = (direction?: Direction): Direction => {
	switch (direction) {
		case null:
		case undefined:
			return 'ASC';
		case 'ASC':
			return 'DESC';
		case 'DESC':
		default:
			return null;
	}
};

export default onChangeSortDirection;
