import React from 'react';

import { Single, type SingleProps } from './Single';

export interface SingleCardListProps<TData extends object> {
	data: TData;
	prevData: TData;
	items: Array<Omit<SingleProps, 'value'> & { by: keyof TData }>;
}

export function SingleCardList<TData extends object>({ data, prevData, items }: SingleCardListProps<TData>) {
	return (
		<>
			{items.map(({ by, ...rest }, idx) => (
				<Single key={idx} value={{ prev: prevData[by] as number, cur: data[by] as number }} {...rest} />
			))}
		</>
	);
}
