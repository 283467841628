/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
// https://stackoverflow.com/a/2117523
// used mainly for dom key generation

export function uuidv4(): string {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => {
		const num = parseInt(c, 10); // Convert c to a number
		return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
	});
}
