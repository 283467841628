import { useCallback, useRef } from 'react';

/**
 * Preserves a reference to the given callback function as an argument while the component is mounted.
 * Wraps the given callback in React's Ref to preserve the reference.
 * @param callback want to be preserved
 */
export function usePreservedCallback<T extends (...args: any[]) => any>(callback: T) {
	const callbackRef = useRef<T>(callback);
	callbackRef.current = callback;

	return useCallback((...args: unknown[]) => callbackRef.current(...args), [callbackRef]) as T;
}
