export const onDownloadBlob = async (data: string | Blob | null, fileName?: string, type?: string) => {
	if (data !== null) {
		const blob = new Blob([data], { ...(type !== undefined && { type }) });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		if (fileName) {
			a.download = fileName.normalize('NFC');
		}

		a.click();

		URL.revokeObjectURL(url);
	}
};
