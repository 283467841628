import React, { useMemo } from 'react';

import { addDays } from 'date-fns/addDays';
import { endOfMonth } from 'date-fns/endOfMonth';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { isFuture } from 'date-fns/isFuture';
import { isSameMonth } from 'date-fns/isSameMonth';
import { isSaturday } from 'date-fns/isSaturday';
import { isSunday } from 'date-fns/isSunday';
import { isToday } from 'date-fns/isToday';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';
import styled from 'styled-components';

import { Button } from '../../../../components/Buttons';
import { Icon, IconID, Label } from '../../../../components/Display';

interface CalendarProps {
	date: string;
	onClickWeek?: (startDate: string) => void;
	onClickDay?: (date: string) => void;
	renderDayContent?: (date: string, numberOfDay: number) => React.ReactElement;
}

export function Calendar({ date, onClickWeek, onClickDay, renderDayContent }: CalendarProps) {
	const { monthStart, startDate, endDate } = useMemo(() => {
		const monthStart = startOfMonth(date);
		const monthEnd = endOfMonth(monthStart);
		const startDate = startOfWeek(monthStart);
		const endDate = endOfWeek(monthEnd);

		return {
			monthStart,
			startDate,
			endDate,
		};
	}, [date]);

	const rows = [];
	let days = [];
	let day = startDate;
	let formattedDate = '';

	while (day <= endDate) {
		const startOfWeek = format(day, 'yyyy-MM-dd');

		days.push(
			<Cell style={{ justifyContent: 'center', padding: '0.8rem 1.2rem' }}>
				<Button
					onClick={() => {
						onClickWeek?.(startOfWeek);
					}}
					disabled={isFuture(startOfWeek)}
					buttonType={'LINE'}
					color={'BLACK'}
				>
					<Icon
						id={IconID.SEARCH_DETAILS}
						isDisabled={isFuture(startOfWeek)}
						disabledColor={'gray_300'}
						width={'1.6rem'}
						height={'1.6rem'}
					/>
					{'상세'}
				</Button>
			</Cell>,
		);
		for (let i = 0; i < 7; i++) {
			formattedDate = format(day, 'd');
			const cloneDay = format(day, 'yyyy-MM-dd');

			days.push(
				<Cell
					className={!isSameMonth(cloneDay, monthStart) || isFuture(cloneDay) ? 'invalid' : 'valid'}
					onClick={() => {
						onClickDay?.(cloneDay);
					}}
				>
					<CellLabel
						className={
							!isSameMonth(cloneDay, monthStart)
								? 'disabled'
								: isToday(cloneDay)
									? 'today'
									: isSunday(cloneDay)
										? 'sunday'
										: isSaturday(cloneDay)
											? 'saturday'
											: 'cell'
						}
						$fontStyle={'title_1'}
						$fontWeight={'bold'}
					>
						{formattedDate}
					</CellLabel>
					{isSameMonth(cloneDay, monthStart) ? renderDayContent?.(cloneDay, parseInt(formattedDate)) : <></>}
				</Cell>,
			);

			day = addDays(day, 1);
		}
		rows.push(
			<RowWrapper>
				{days.map((component, idx) => (
					<React.Fragment key={`cell${idx}`}>{component}</React.Fragment>
				))}
			</RowWrapper>,
		);
		days = [];
	}

	return (
		<Wrapper>
			<HeaderWrapper>
				{['주간 상세', '일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'].map((value, idx) => (
					<HeaderCell key={idx}>
						<CellLabel
							className={value === '일요일' ? 'sunday' : value === '토요일' ? 'saturday' : 'header'}
							$fontStyle={'title_3'}
							$fontWeight={'medium'}
						>
							{value}
						</CellLabel>
					</HeaderCell>
				))}
			</HeaderWrapper>
			{rows.map((component, idx) => (
				<React.Fragment key={`row${idx}`}>{component}</React.Fragment>
			))}
		</Wrapper>
	);
}

const CellLabel = styled(Label)`
	align-self: stretch;
	&.sunday {
		color: ${({ theme }) => theme.colors.red.red_500};
	}
	&.saturday {
		color: ${({ theme }) => theme.colors.blue.blue_600};
	}

	&.today {
		color: ${({ theme }) => theme.colors.primary.primary_700};
	}

	&.disabled {
		color: ${({ theme }) => theme.colors.gray.gray_500};
	}

	&.header {
		color: ${({ theme }) => theme.colors.gray.gray_800};
	}

	color: ${({ theme }) => theme.colors.gray.gray_900};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
	min-width: 132rem;
`;

const HeaderWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background: ${({ theme }) => theme.colors.gray.gray_100};
`;

const RowWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 15rem;
	align-items: flex-start;

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}
`;

const HeaderCell = styled.div`
	display: flex;
	padding: 0.8rem;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;

	&:not(:last-child) {
		border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}
`;

const Cell = styled.div`
	display: flex;
	padding: 0.8rem 1.2rem 1.6rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;

	&.valid {
		&:hover {
			cursor: pointer;
			background: ${({ theme }) => theme.colors.gray.gray_200};
		}
	}

	&.invalid {
		pointer-events: none;
	}

	&:not(:last-child) {
		border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}
`;
