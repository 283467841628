import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { HistoryStorage } from '../../utils/historyStorage';

type DispatchTypes = 'SET' | 'DELETE' | 'RESET';

export interface Dispatch<T> {
	(type: 'SET', payload: Partial<T>): void;
	(type: 'DELETE', payload: keyof T): void;
	(type: 'RESET'): void;
}

type ChangeType = 'DEFAULT' | 'CHECKBOX';

type OnChangeValues = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type?: ChangeType) => void;

export interface UseValuesReturn<T> {
	values: T;
	onChangeValues: OnChangeValues;
	dispatch: Dispatch<T>;
}

/**
 * @param initialValues 기본값
 * @returns {object}
 * @property {values} 저장 된 값 object
 * @property {onChangeValues} 인풋용 onChange 함수
 * @property {dispatch} 값을 변경할 때 사용하는 함수 SET, DELETE, RESET이 있음
 */
export const useValues = <T>(initialValues: T, historyKey?: string): UseValuesReturn<T> => {
	const copyInitialValues = { ...initialValues };
	const history = historyKey && new HistoryStorage<T>(historyKey);

	// history에 저장되어 있을경우 값 가져오기
	const storedData = useMemo(() => {
		if (history && historyKey) {
			return history.get();
		}
	}, []);

	const [values, setValues] = useState(storedData ?? copyInitialValues);

	useEffect(() => {
		if (history) {
			history.set(values);
		}
	}, [values]);

	const dispatch: Dispatch<T> = (type: DispatchTypes, payload?: Partial<T> | keyof T) => {
		switch (type) {
			case 'SET':
				if (typeof payload === 'object' && payload !== null) {
					setValues((prev) => ({
						...prev,
						...payload,
					}));
				}
				break;

			case 'DELETE':
				if (typeof payload === 'string') {
					setValues((prev) => ({
						...prev,
						[payload]: copyInitialValues[payload],
					}));
				}
				break;
			case 'RESET':
				setValues(copyInitialValues);
		}
	};

	const onChangeValues: OnChangeValues = (e, type = 'DEFAULT') => {
		const { value, name } = e.target;
		switch (type) {
			case 'CHECKBOX': {
				const checked = values[name as keyof T] as boolean;
				setValues((prev) => ({
					...prev,
					[name]: !checked,
				}));
				break;
			}
			default:
				setValues((prev) => ({
					...prev,
					[name]: value,
				}));
				break;
		}
	};

	return { values, dispatch, onChangeValues };
};
