import React from 'react';

import { ChartText } from '../styles';

import type { PieLabelRenderProps } from 'recharts';

export function PieLabel(props: PieLabelRenderProps) {
	const { cx, cy, startAngle, midAngle, endAngle, outerRadius, name, percent, fill, index } = props;

	if (
		typeof cx !== 'number' ||
		typeof cy !== 'number' ||
		typeof startAngle !== 'number' ||
		typeof midAngle !== 'number' ||
		typeof endAngle !== 'number' ||
		typeof outerRadius !== 'number' ||
		typeof index !== 'number' ||
		!name ||
		!percent
	) {
		return <></>;
	}

	const narrow = Math.abs(startAngle - endAngle) < 25;

	const RADIAN = Math.PI / 180;
	const sin = Math.sin(-RADIAN * midAngle); // 위쪽 음수(< 0) 아래쪽 양수(> 0)
	const cos = Math.cos(-RADIAN * midAngle); // 왼쪽 음수(< 0) 오른쪽 양수(> 0)
	const sx = cx + outerRadius * cos;
	const sy = cy + outerRadius * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;

	if (narrow) {
		let [ex, ey] = [mx, my];
		if (Math.abs(sin) > 0.9) {
			ex = mx + cos * 22 * (index % 2 === 0 ? 5 : 10);
		}

		if (Math.abs(cos) > 0.9) {
			ey = my + sin * 22 * (index % 2 === 0 ? 14 : 7);
		}

		const fx = ex + (cos >= 0 ? 1 : -1) * 28;

		return (
			<>
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
				<ChartText x={fx} y={ey} textAnchor={'middle'} $fontWeight={'medium'} $color={'gray_900'}>
					{name}
				</ChartText>
				<ChartText x={fx} y={ey} dy={18} textAnchor={'middle'} $fontWeight={'bold'} $color={'gray_900'}>
					{`${(percent * 100).toFixed(1)}%`}
				</ChartText>
			</>
		);
	}

	return (
		<>
			<ChartText x={mx} y={my} textAnchor={'middle'} $fontWeight={'medium'} $color={'gray_900'}>
				{name}
			</ChartText>
			<ChartText x={mx} y={my} dy={18} textAnchor={'middle'} $fontWeight={'bold'} $color={'gray_900'}>
				{`${(percent * 100).toFixed(1)}%`}
			</ChartText>
		</>
	);
}
