import { type MutableRefObject, useLayoutEffect, useRef, useState } from 'react';

import { useOnScrollPositionChangeDetectorWithRef } from '../../../hooks/useOnScrollPositionChangeDetector';
import { pubSubFactory } from '../pubSub';

type PromiseResolver = () => void;
export type ScrollTopics = 'scrollToBottom' | 'scroll';
export type ScrollTopicUnion =
	| {
			topic: 'scrollToBottom';
			payload: { animated?: boolean; resolve?: PromiseResolver };
	  }
	| {
			topic: 'scroll';
			payload: {
				top?: number;
				animated?: boolean;
				lazy?: boolean;
				resolve?: PromiseResolver;
			};
	  };

function runCallback(callback: () => void, lazy = true) {
	if (lazy) {
		setTimeout(() => {
			callback();
		});
	} else {
		callback();
	}
}

/**
 * 메시지리스트 scroll publish subscribe 설정
 */
export function useMessageListScroll() {
	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollDistanceFromBottomRef = useRef(0);

	const [scrollPubSub] = useState(() => pubSubFactory<ScrollTopics, ScrollTopicUnion>());
	const [isScrollBottomReached, setIsScrollBottomReached] = useState(false);

	useLayoutEffect(() => {
		const unsubscribes: Array<{ remove: () => void }> = [];

		unsubscribes.push(
			scrollPubSub.subscribe('scrollToBottom', ({ resolve, animated }) => {
				runCallback(() => {
					if (!scrollRef.current) return;

					if (scrollRef.current.scroll) {
						scrollRef.current.scroll({
							top: scrollRef.current.scrollHeight,
							behavior: animated ? 'smooth' : 'auto',
						});
					} else {
						scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
					}

					// Update data by manual update
					scrollDistanceFromBottomRef.current = 0;
					setIsScrollBottomReached(true);

					if (typeof resolve === 'function') resolve();
				});
			}),
		);

		unsubscribes.push(
			scrollPubSub.subscribe('scroll', ({ top, lazy, resolve }) => {
				runCallback(() => {
					if (!scrollRef.current) return;
					const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

					if (scrollRef.current.scroll) {
						scrollRef.current.scroll({ top });
					} else {
						scrollRef.current.scrollTop = top!;
					}

					// Update data by manual update
					scrollDistanceFromBottomRef.current = Math.max(0, scrollHeight - scrollTop - clientHeight);
					setIsScrollBottomReached(scrollDistanceFromBottomRef.current === 0);

					if (typeof resolve === 'function') resolve();
				}, lazy);
			}),
		);

		return () => {
			unsubscribes.forEach(({ remove }) => {
				remove();
			});
		};
	}, []);

	// Update data by scroll events
	useOnScrollPositionChangeDetectorWithRef(scrollRef, {
		onReachedTop({ distanceFromBottom }) {
			setIsScrollBottomReached(false);
			scrollDistanceFromBottomRef.current = distanceFromBottom;
		},
		onInBetween({ distanceFromBottom }) {
			setIsScrollBottomReached(false);
			scrollDistanceFromBottomRef.current = distanceFromBottom;
		},
		onReachedBottom({ distanceFromBottom }) {
			setIsScrollBottomReached(true);
			scrollDistanceFromBottomRef.current = distanceFromBottom;
		},
	});

	return {
		scrollRef: scrollRef as MutableRefObject<HTMLDivElement>,
		scrollPubSub,
		isScrollBottomReached,
		setIsScrollBottomReached,
		scrollDistanceFromBottomRef,
	};
}
