import React, { useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Flex, Loading } from '../../../../components/Common';
import { FormItem, Select, TextArea, TextField } from '../../../../components/Forms';
import { Modal, type ModalProps } from '../../../../components/Modals';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { useGetGroupCategoriesUsage, useGetGroupDetail, usePutGroupDetail } from '../../../../services/groups/queries';
import { type GroupCategory, type PutGroupDetailRequest } from '../../../../services/groups/types';
import { GROUP_CATEGORY_OPTIONS } from '../../../../utils/consts';
import { STATUS_MESSAGE } from '../const';

interface GroupAddModalProps extends ModalProps {
	refetch: () => void;
}

export const GroupAddModal = ({ refetch }: GroupAddModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleClose } = context.modal.useDispatch();
	const { groupId } = useParams();

	const initialValues: PutGroupDetailRequest = useMemo(
		() => ({ storeId: '', groupId: -1, name: '', description: '', category: 'NORMAL' }),
		[],
	);
	const {
		reduceData: usage,
		isError: isUsageError,
		isLoading: isUsageLoading,
	} = useGetGroupCategoriesUsage(userInfo.storeId);

	const { data, isLoading, isSuccess, isError } = useGetGroupDetail({
		storeId: userInfo.storeId,
		groupId: Number(groupId),
	});

	const { values, onChangeValues, dispatch } = useValues(initialValues);

	useEffect(() => {
		if (isError || isUsageError) {
			toast.error(`데이터를 불러오는데 실패하였습니다.`);
			handleClose();
		}

		if (isSuccess && data) {
			const { category, id, name, description } = data;

			dispatch('SET', {
				category,
				groupId: id,
				name: name ?? '',
				description: description ?? '',
				storeId: userInfo.storeId,
			});
		}
	}, [data, isSuccess, userInfo, isError, isUsageError]);

	const { mutate } = usePutGroupDetail({
		onSuccess: () => {
			refetch();
			handleClose();
			toast.success('그룹 정보가 저장되었습니다.');
		},
		onError: (err) => {
			toast.error('수정에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onSubmit = useCallback(() => {
		mutate(values);
	}, [values]);

	return (
		<Modal
			header={{ title: '그룹 수정' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '저장',
						onClick: onSubmit,
						style: { width: '10rem' },
						disabled:
							values.description.length <= 0 ||
							values.name.length <= 0 ||
							(!!values.category &&
								values.category !== data?.category &&
								usage[values.category].count >= usage[values.category].limit),
						shouldPrevent: true,
					},
				],
			}}
		>
			<Container>
				{(isLoading || isUsageLoading) && <Loading />}
				<FormItem
					label="그룹명"
					statusMessage={
						!!values.category &&
						values.category !== data?.category &&
						usage[values.category].count >= usage[values.category].limit
							? `${STATUS_MESSAGE.MAX_CATEGORY} (${usage[values.category].limit}/${usage[values.category].limit})`
							: ''
					}
				>
					<Flex $gap="0.8rem">
						<Select
							options={GROUP_CATEGORY_OPTIONS.filter((option) => option.key !== 'ALL')}
							value={values.category}
							onClick={(value) => {
								dispatch('SET', { category: value as GroupCategory });
							}}
							labelStyle={{ minWidth: '12rem' }}
						/>
						<TextField
							value={values.name}
							onChange={onChangeValues}
							name="name"
							maxLength={10}
							count={{ show: true, max: 10 }}
							style={{ flex: 1 }}
						/>
					</Flex>
				</FormItem>
				<FormItem label="그룹 설명">
					<TextArea
						value={values.description}
						onChange={onChangeValues}
						name="description"
						maxLength={100}
						count={{ show: true, max: 100 }}
					/>
				</FormItem>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 80rem;
`;
