import React, { useCallback } from 'react';

import styled from 'styled-components';

import { PRODUCT_COLUMNS, INITIAL_PRODUCT_TMP_SEARCH, PRODUCT_SEARCH_OPTIONS, type ProductSearchType } from './const';
import { ProductModal } from './ProductModal';
import { Button } from '../../components/Buttons';
import { Icon, IconID } from '../../components/Display';
import { Select, TextField } from '../../components/Forms';
import { ListTable } from '../../components/Table';
import context from '../../context';
import { useValues } from '../../hooks/useValues';
import { useGetProducts } from '../../services/products/queries';
import { type ProductItem } from '../../services/products/types';

export const Product = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const { data, pagination, queries, refetch } = useGetProducts(userInfo.storeId);

	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(INITIAL_PRODUCT_TMP_SEARCH);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			queries.dispatch('SET', { [tmpSearch.searchCategory]: tmpSearch.searchKeyword });
		},
		[tmpSearch],
	);

	const handleModal = useCallback((selectedItem: ProductItem | null) => {
		handleOpen(
			<ProductModal
				{...{
					selectedItem,
					handleClose: () => {},
				}}
				onSuccess={() => {
					refetch();
					pagination.setCurPage(1);
				}}
			/>,
		);
	}, []);

	return (
		<React.Fragment>
			<ListTable
				data={data?.content}
				columns={PRODUCT_COLUMNS()}
				{...{ pagination }}
				onRowClick={(item) => {
					handleModal(item);
				}}
				unit="개"
				headerLeftContent={
					<TableLeft onSubmit={onSubmit}>
						<Select
							options={PRODUCT_SEARCH_OPTIONS}
							value={tmpSearch.searchCategory}
							labelStyle={{ width: '10.4rem' }}
							onClick={(key) => {
								tmpSearchDispatch('SET', {
									searchCategory: key as ProductSearchType,
									searchKeyword: '',
								});
							}}
						/>
						<TextField
							renderPrefix={<Icon id={IconID.BTN_SEARCH} width="2rem" height="2rem" />}
							inputSize={'sm'}
							style={{ borderRadius: '4px', width: '32rem' }}
							placeholder="검색어를 입력해주세요."
							value={tmpSearch.searchKeyword}
							name="searchKeyword"
							onChange={onChangeTmpSearch}
						/>
					</TableLeft>
				}
				headerRightContent={
					<Button
						onClick={() => {
							handleModal(null);
						}}
					>
						상품 등록
					</Button>
				}
			/>
		</React.Fragment>
	);
};

const TableLeft = styled.form`
	display: flex;
	gap: 0.8rem;
	align-items: center;
`;
