import React, { type CSSProperties, useCallback, useEffect, useRef } from 'react';

import { type UseFloatingReturn } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { scrollStyle } from '../../../../../assets/styles/scrollStyle';
import { useCustomFloating } from '../../../../../lib/floatingUi/useCustomFloating';
import { Option } from '../Option';
import { OptionsLabel } from '../OptionsLabel';

interface CategoryOption<T extends object> {
	category: string | null;
	options: T[];
}

export type CategoryOptions<T extends object> = Array<CategoryOption<T>>;

export type ValidOptionValue<T> = Exclude<T[keyof T], undefined | null>;
export interface DefaultOptionsProps<T extends object> {
	options: T[] | CategoryOptions<T>;
	selectKey?: keyof T;
	label?: keyof T;
	value?: T[keyof T] | Array<T[keyof T]>;
	disabledKeys?: Array<T[keyof T]>;
	onClick?: (value: ValidOptionValue<T>) => void;
	multiple?: { allKey: ValidOptionValue<T>; label: string };
	optionsStyle?: CSSProperties;
	onScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
	scrollToSelect?: boolean;
	customLabel?: (item: T, options: { isDisabled?: boolean; isSelected: boolean }) => React.ReactNode;
}

interface OptionsProps<T extends object> extends DefaultOptionsProps<T> {
	isOpen?: boolean;
	handleClose?: () => void;
	referenceRef?: React.RefObject<HTMLElement>;
}

export const Options = <T extends object>(props: OptionsProps<T>) => {
	const {
		isOpen = true,
		handleClose,
		options,
		selectKey = 'key',
		label = 'label',
		value,
		referenceRef,
		disabledKeys,
		onClick,
		multiple,
		optionsStyle = { maxHeight: '30rem' },
		onScroll,
		scrollToSelect = false,
		customLabel,
	} = props;
	const optionsRef = useRef(null);
	const selectedRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (!selectedRef.current || !isOpen || !scrollToSelect) return;
		const windowScrollY = window.scrollY;
		selectedRef.current.scrollIntoView({
			behavior: 'instant',
			block: 'start',
		});
		// window scroll 은 이전 값 유지
		window.scrollTo({
			behavior: 'instant',
			top: windowScrollY,
		});
	}, [selectedRef, isOpen]);

	const floating = useCustomFloating({
		referenceRef,
		floatingRef: optionsRef,
		isOpen: isOpen && !!referenceRef,
	});

	const handleChange = useCallback(
		(option: T) => {
			const key = selectKey as keyof T;
			onClick && onClick(option[key] as ValidOptionValue<T>);
			if (!Array.isArray(value)) {
				handleClose?.();
			}
		},
		[value, multiple?.allKey],
	);

	const renderOption = (option: T, idx: number) => {
		const isSelected = Array.isArray(value)
			? value.includes(option[selectKey as keyof T])
			: option[selectKey as keyof T] === value;
		const isDisabled = selectKey && disabledKeys?.includes(option[selectKey as keyof T]);
		return (
			<Option
				key={'option--' + option[selectKey as keyof T] + idx}
				isSelected={isSelected}
				isDisabled={isDisabled}
				isMultiple={Array.isArray(value)}
				onClick={() => {
					handleChange(option);
				}}
				selectedRef={selectedRef}
			>
				{customLabel ? customLabel(option, { isSelected, isDisabled }) : String(option[label as keyof T])}
			</Option>
		);
	};

	return (
		<Container
			ref={optionsRef}
			$floating={floating}
			$isOpen={isOpen}
			$minWidth={referenceRef?.current?.clientWidth + 'px' ?? 'auto'}
			style={optionsStyle}
			onScroll={(e) => {
				onScroll?.(e);
			}}
		>
			{options.map((option, idx) => {
				if ('category' in option) {
					return (
						<React.Fragment key={'option--' + option.category + idx}>
							{option.category && <OptionsLabel>{option.category}</OptionsLabel>}
							{option.options.map((option, idx) => renderOption(option, idx))}
						</React.Fragment>
					);
				} else {
					return renderOption(option, idx);
				}
			})}
		</Container>
	);
};

const Container = styled.ul<{
	$floating: UseFloatingReturn;
	$isOpen: boolean;
	$minWidth: string;
}>`
	background-color: ${({ theme }) => theme.colors.white};
	display: ${({ $isOpen }) => ($isOpen ? 'inline-flex' : 'none')};
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	min-width: ${({ $minWidth }) => $minWidth};

	${scrollStyle({ hideHorizontal: true })}

	${({ $floating }) =>
		$floating.isPositioned &&
		css`
			border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
			z-index: 10;
			box-shadow: ${({ theme }) => theme.shadow.modal};
			position: ${$floating.strategy};
			top: 0;
			left: 0;
			transform: translate(${`${$floating?.x}px, ${$floating?.y}px`});
		`}
`;
