import React, { type CSSProperties, useEffect, useMemo, useState } from 'react';

import { CustomTooltip } from './CustomTooltip';
import { xAxisDataKey, xExtraTickFormatter, xTickFormatter } from './utils';
import { ComposedChart, type ComposedChartProps } from '../../../../components/Charts';
import {
	type ExtendedPeriodUnit,
	type UnitQueryResultType,
	useGetPeriodDetails,
} from '../../../../services/statistics/queries';

const X_AXIS_PADDING = { left: 16, right: 16 };
const Y_AXIS_TICK_SIZE = 20;
const Y_AXIS_WIDTH = 80;
const Y_AXIS_LABEL_OFFSET = 32;

type BarDataKey = 'saleMargin' | 'pxPrice' | 'nonInsureMargin';

const BAR_PROPS: Array<{ dataKey: BarDataKey; name: string; fill: string }> = [
	{
		dataKey: 'saleMargin',
		name: '판매 마진',
		fill: '#5AD7D2',
	},
	{
		dataKey: 'pxPrice',
		name: '조제료',
		fill: '#AB8DFF',
	},
	{ dataKey: 'nonInsureMargin', name: '비급여', fill: '#587DFF' },
] as const;

/**
 * @description
 * hover 된 bar 의 value text 를 bord 처리
 */
const addClassNameToElem = (className: string) => {
	try {
		const elem = document.getElementsByClassName(className);
		elem[0].classList.add(className + '--active');
	} catch {
		// noop
	}
};

const removeClassNameFromElem = (className: string) => {
	try {
		const elem = document.getElementsByClassName(className);
		elem[0].classList.remove(className + '--active');
	} catch {
		// noop
	}
};

interface PeriodChartProps {
	storeId: string;
	startDate: string;
	endDate: string;
	unit: ExtendedPeriodUnit;
	style?: CSSProperties;
}

export function PeriodChart({ storeId, startDate, endDate, unit, style }: PeriodChartProps) {
	const { data } = useGetPeriodDetails<typeof unit, Array<UnitQueryResultType<typeof unit>>>({
		storeId,
		unit,
		period: [{ startDate, endDate }],
		select: (data) => (unit === 'weekday' ? data : data.slice().reverse()),
	});
	const [activated, setActivated] = useState<'all' | BarDataKey | 'sales'>('all');

	const handleClick = (dataKey: BarDataKey | 'sales') => {
		setActivated((prev) => (prev !== dataKey ? dataKey : 'all'));
	};

	useEffect(() => {
		setActivated('all');
	}, [unit]);

	const defaultChartProps: ComposedChartProps = useMemo(
		() => ({
			width: 1320,
			height: 456,
			margin: { top: 100, right: 114, bottom: 50, left: 114 },
			legendProps: {
				wrapperStyle: { top: 28 },
			},
			yAxisProps: [
				{
					dataKey: 'grossProfit',
					yAxisId: 'grossProfit',
					tickSize: Y_AXIS_TICK_SIZE,
					width: Y_AXIS_WIDTH,
					axisLine: false,
					label: {
						value: '매출 총이익',
						position: 'top',
						offset: Y_AXIS_LABEL_OFFSET,
						dx: Y_AXIS_TICK_SIZE,
						className: 'yAxis-label left',
					},
				},
				{
					dataKey: 'sales',
					yAxisId: 'sales',
					tickSize: Y_AXIS_TICK_SIZE,
					width: Y_AXIS_WIDTH,
					axisLine: false,
					orientation: 'right',
					label: {
						value: '판매 매출',
						position: 'top',
						offset: Y_AXIS_LABEL_OFFSET,
						dx: -Y_AXIS_TICK_SIZE,
						className: 'yAxis-label right',
					},
				},
			],
		}),
		[],
	);

	const chartProps: ComposedChartProps = useMemo(
		() => ({
			barProps: BAR_PROPS.map(({ dataKey, name, fill }) => ({
				dataKey,
				name,
				fill,
				stackId: 'a',
				yAxisId: 'grossProfit',
				legendType: 'square',
				cursor: 'pointer',
				maxBarSize: 28,
				onMouseEnter: () => {
					addClassNameToElem(`${dataKey}-value`);
				},
				onMouseLeave: () => {
					removeClassNameFromElem(`${dataKey}-value`);
				},
				onClick: () => {
					handleClick(dataKey);
				},
				opacity: activated === 'all' || activated === dataKey ? 1 : 0.5,
			})),
			lineProps: [
				{
					dataKey: 'sales',
					name: '판매 매출',
					stroke: '#F74459',
					yAxisId: 'sales',
					legendType: 'plainline',
					cursor: 'pointer',
					strokeWidth: 2.5,
					onClick: () => {
						handleClick('sales');
					},
					opacity: activated === 'all' || activated === 'sales' ? 1 : 0.5,
				},
			],
			xAxisProps: [
				{
					dataKey: (obj) => xAxisDataKey<typeof unit>(obj),
					padding: X_AXIS_PADDING,
					tickFormatter: (value, index) => xTickFormatter(value, unit),
				},
				{
					dataKey: (obj) => xAxisDataKey<typeof unit>(obj),
					padding: X_AXIS_PADDING,
					axisLine: false,
					height: 1,
					xAxisId: 'extra',
					fontColor: 'gray_800',
					tickFormatter: (value, index) => xExtraTickFormatter(value as string, index, unit),
				},
			],

			tooltipProps: {
				cursor: true,
				content: <CustomTooltip />,
			},
		}),
		[unit, activated],
	);

	return <ComposedChart wrapperStyle={style} data={data[0] ?? []} {...defaultChartProps} {...chartProps} />;
}
