import styled from 'styled-components';

export const StatsStyledTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	.table {
		::-webkit-scrollbar {
			width: 0;
		}
		color: ${({ theme }) => theme.colors.gray.gray_900};
		&-item {
			min-height: 0;
			height: 3.2rem;
			padding: 0 0.8rem;
			justify-self: stretch;
			white-space: nowrap;
			&:not(:last-child) {
				border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
			}
			&-as-button {
				text-decoration: underline;
				color: ${({ theme }) => theme.colors.blue.blue_600};
			}
		}
		&-header {
			padding: 0;
			align-items: flex-start;
			align-self: stretch;
			&-item {
				background-color: ${({ theme }) => theme.colors.gray.gray_200} !important;
				${({ theme }) => theme.font.label.label_2};
				${({ theme }) => theme.font.weight.medium};
			}
		}
		&-body {
			&-row {
				padding: 0;
			}
		}
	}
`;
