import { useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
	type PostProductRequest,
	type ProductItem,
	type GetProductsRequest,
	type DeleteProductRequest,
	type PutProductRequest,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';

export const productsKeys = {
	all: ['products'] as const,
	getProducts: (pagination: PaginationQueries, values: GetProductsRequest) =>
		[...productsKeys.all, 'list', values, pagination] as const,
	postProduct: () => [...productsKeys.all, 'post'],
	putProduct: () => [...productsKeys.all, 'put'],
	deleteProducts: () => [...productsKeys.all, 'delete'],
} as const;

export const useGetProducts = (storeId: string) => {
	const INITIAL_VALUES: GetProductsRequest = {
		storeId,
		sorts: { direction: 'DESC', property: 'createdAt' },
		name: '',
		barcode: '',
	};
	const pagination = usePagination({ historyKey: 'getProductsPagination' });

	const { curPage, listCount, setPagination } = pagination;
	const queries = useValues(INITIAL_VALUES, 'getProducts');

	const queryInfo = useQuery({
		queryKey: productsKeys.getProducts({ curPage, listCount }, queries.values),
		queryFn: async () =>
			await api.products.getProducts({
				page: curPage,
				limit: listCount,
				...queries.values,
			}),
		enabled: !!storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const usePostProduct = (callback: MutateCallback<PostProductRequest, ProductItem>) =>
	useMutation({
		mutationKey: productsKeys.postProduct(),
		mutationFn: async (params) => await api.products.postProduct(params),
		...callback,
	});

export const usePutProducts = (callback: MutateCallback<PutProductRequest, unknown>) =>
	useMutation({
		mutationKey: productsKeys.putProduct(),
		mutationFn: async (params) => await api.products.putProduct(params),
		...callback,
	});

export const useDeleteProducts = (callback: MutateCallback<DeleteProductRequest, unknown>) =>
	useMutation({
		mutationKey: productsKeys.deleteProducts(),
		mutationFn: async (params) => await api.products.deleteProducts(params),
		...callback,
	});
