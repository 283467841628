import React from 'react';

import { Flex } from '../../../../components/Common';
import { Label } from '../../../../components/Display';

interface InfoTitleProps {
	title: string;
	renderSuffix?: React.ReactElement;
}
export const InfoTitle = ({ title, renderSuffix }: InfoTitleProps) => {
	return (
		<Flex $justify={'between'} $alignItems={'center'} $alignSelf={'stretch'}>
			<Label $fontStyle={'body_1'} $fontWeight={'bold'}>
				{title}
			</Label>
			{renderSuffix}
		</Flex>
	);
};
