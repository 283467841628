import React from 'react';

import styled from 'styled-components';

import { type TextBlockType } from '../types';

interface TextBlockProps {
	data: TextBlockType;
}
const TextBlock = ({ data }: TextBlockProps) => {
	return <Wrapper>{data.text}</Wrapper>;
};

const Wrapper = styled.p`
	width: 100%;
	padding: 1.6rem;
	${({ theme }) => theme.font.body.body_2};
`;

export default TextBlock;
