import React from 'react';

import styled from 'styled-components';

import context from '../../../context';
import { Modal, type ModalProps } from '../Modal';

interface AlertModalProps extends ModalProps {
	message: React.ReactNode | null;
}

export const AlertModal = ({ message }: AlertModalProps) => {
	const { handleClose } = context.modal.useDispatch();
	return (
		<Modal
			zIndex={1}
			footer={{
				$hasBorderTop: false,
				style: { padding: '0 2.4rem 2.8rem' },
				button: [{ children: '확인', style: { width: '10.4rem', height: '3.6rem' }, onClick: handleClose }],
			}}
		>
			<ConfirmModalWrapper>
				<div className="contents">{message}</div>
			</ConfirmModalWrapper>
		</Modal>
	);
};

const ConfirmModalWrapper = styled.div`
	padding: 3.2rem 2.8rem 2.8rem;
	display: flex;
	flex-direction: column;
	min-width: 40rem;
	.contents {
		${({ theme }) => theme.font.body.body_2};
		white-space: pre-wrap;
		text-align: left;
	}
`;
