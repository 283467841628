import { useCallback, useEffect, useState } from 'react';

interface UseSelectedItems<T extends object> {
	initialValues?: T[];
	dependency?: any[];
}

type Action = 'REMOVE' | 'PUSH' | 'RESET';

export type HandleSelectedItems<T> = (action: Action, clickItems?: T | T[], selectKey?: keyof T) => void;

export const useSelectedItems = <T extends object>({ initialValues, dependency }: UseSelectedItems<T>) => {
	const [selectedItems, setSelectedItems] = useState<T[]>(initialValues ?? []);

	useEffect(() => {
		if (dependency) {
			setSelectedItems([]);
		}
	}, dependency);

	// 단일 select 함수
	const singleSelectItem = useCallback(
		(clickItems: T, selectKey: keyof T, action: Action) => {
			if (action === 'PUSH') {
				setSelectedItems((prev) => [...prev, clickItems]);
			}
			if (action === 'REMOVE') {
				const filterItems = selectedItems.filter((selectedItem) => selectedItem[selectKey] !== clickItems[selectKey]);
				setSelectedItems(filterItems);
			}
		},
		[selectedItems],
	);

	const handleSelectedItems: HandleSelectedItems<T> = useCallback(
		(action, clickItems, selectKey) => {
			if (action === 'RESET') {
				setSelectedItems(initialValues ?? []);
			} else if (clickItems && selectKey) {
				if (dependency) {
					if (Array.isArray(clickItems)) {
						if (action === 'PUSH') {
							setSelectedItems(clickItems);
						}
						if (action === 'REMOVE') {
							setSelectedItems([]);
						}
					} else {
						singleSelectItem(clickItems, selectKey, action);
					}
				} else {
					if (Array.isArray(clickItems)) {
						const clickItemsKeys = clickItems.map((item) => item[selectKey]);
						const selectedItemKeys = selectedItems.map((item) => item[selectKey]);

						if (action === 'PUSH') {
							const filterItems = clickItems.filter((item) => !selectedItemKeys.includes(item[selectKey]));
							setSelectedItems((prev) => [...prev, ...filterItems]);
						}
						if (action === 'REMOVE') {
							const filterItems = selectedItems.filter((item) => !clickItemsKeys.includes(item[selectKey]));
							setSelectedItems(filterItems);
						}
					} else {
						singleSelectItem(clickItems, selectKey, action);
					}
				}
			}
		},
		[selectedItems, ...[dependency ?? []]],
	);

	return { selectedItems, handleSelectedItems };
};
