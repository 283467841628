import React from 'react';

import styled from 'styled-components';

import { ImageRenderer } from '../../../../../components/Display';
import { type AutoCustomType } from '../../../types';

import type { UserMessage } from '@sendbird/chat/message';

type GPAutoCustomType = `GP_${AutoCustomType | 'PROMOTION_RESPONSE' | 'PROMOTION_REJECT'}`;

interface AutoMessageProps {
	message: UserMessage;
	customType: GPAutoCustomType;
}

// https://assets.goodpharm.kr/mobilePharmacy/img_chatbot_prescription_reject.png - 처방접 접수거절
// https://assets.goodpharm.kr/mobilePharmacy/img_chatbot_prescription_accept.png // 처방전 접수승인

const ASSET_URL = 'https://assets.goodpharm.kr/mobilePharmacy/';

const IMAGE_CONFIGS: Record<GPAutoCustomType, string> = {
	GP_CLOSE: 'img_chatbot_close.png', // 영엄종료
	GP_BUSY: 'img_chatbot_busy.png', // 혼잡시간대
	GP_WELCOME: 'img_chatbot_welcome.png', // 단골약국 등록
	GP_SUPPLEMENT: 'img_chatbot_supplement.png', // 영양제 상담
	GP_PROMOTION_RESPONSE: 'img_chatbot_check_stock.png', // 재고확인
	GP_PROMOTION_REJECT: 'img_chatbot_purchase_reject.png', // 구매불가
	GP_PICKED_UP: 'img_chatbot_picked_up.png', // 제품 수령 완료
} as const;

export const AutoMessage = ({ message, customType }: AutoMessageProps) => {
	return (
		<Container>
			<ImageRenderer
				url={ASSET_URL + IMAGE_CONFIGS[customType]}
				alt={'chat-auto-message'}
				width={'100%'}
				height={'14rem'}
				fixedSize={true}
			/>
			<Content>{message.message}</Content>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	max-width: 26.4rem;
	width: 26.4rem;
	flex-direction: column;
	justify-content: flex-end;
	white-space: pre-wrap;
	align-items: flex-start;
	border-radius: 12px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	overflow: hidden;
`;

const Content = styled.div`
	display: flex;
	width: 26.4rem;
	padding: 1.6rem;
	${({ theme }) => theme.font.body.body_2};
`;
