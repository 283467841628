import React from 'react';

import { commaizeNumber } from '../../../utils/format';
import { ChartText } from '../styles';

export function CustomYAxis(props: any) {
	if (props && 'x' in props && 'y' in props && 'payload' in props && 'textAnchor' in props) {
		const { x, y, textAnchor, payload } = props;

		return (
			<ChartText x={x} y={y + 4} $color={'gray_800'} textAnchor={textAnchor}>
				{commaizeNumber(payload?.value)}
			</ChartText>
		);
	}
	return <></>;
}
