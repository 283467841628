import { type Columns } from '../../../components/Table/ListTable/types';
import { type BlockMember } from '../../../services/block/types';
import { GENDER_VALUES } from '../../../utils/consts';

export const blacklistTableColumns: Columns<BlockMember> = [
	{ key: 'name', headerName: '회원명', isClick: true },
	{ key: 'birth', headerName: '생년월일', isClick: true },
	{
		key: 'gender',
		headerName: '성별',
		renderComponent: (value) => GENDER_VALUES[value],
		isClick: true,
	},
	{ key: 'registrationAt', headerName: '약국 등록일', isClick: true },
	{ key: 'blockedAt', headerName: '차단 일시', isClick: true },
];
