import React from 'react';

import { VStack } from '../../../../components/Common';
import { FileUploader } from '../../../../components/Forms';
import { ConfirmModal } from '../../../../components/Modals';
import context from '../../../../context';
import { isImage } from '../../../../utils/files';
import { Placeholder, PlaceHolderTypes } from '../../../ChannelList/components/ChannelListView/Placeholder';
import { ChannelMemberInfo } from '../../../ChannelMemberInfo';
import { SearchMessageNavigator } from '../../../MessageSearch/components/SearchMessageNavigator';
import { useMessageSearchContext } from '../../../MessageSearch/context/MessageSearchProvider';
import { useChannelContext } from '../../context/ChannelProvider';
import { useHandleSendFiles } from '../../hooks/useHandleSendFiles';
import { ChannelHeader } from '../ChannelHeader';
import { MessageInput } from '../MessageInput';
import { MessageList } from '../MessageList';

const NO_CHANNEL_SELECTED = '대화방을 선택해주세요.';
export const ChannelUI = () => {
	const { handleOpen } = context.modal.useDispatch();
	const { channelUrl, currentChannel, disabledReason, sendFileMessage, showMemberInfo, onMemberInfoClick } =
		useChannelContext();
	const { activeSearch } = useMessageSearchContext();
	const { handleSendFiles } = useHandleSendFiles({ sendFileMessage });

	const handleChangeFile = (files: File[]) => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					handleSendFiles(files);
				}}
				message={`${isImage(files[0].type) ? '사진' : '파일'}을 전송하시겠습니까?`}
			/>,
		);
	};

	const handleDropAndPaste = (files: FileList, onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
		if (activeSearch.searching || disabledReason !== 'none') return;
		onFileChange({ target: { files } } as React.ChangeEvent<HTMLInputElement>);
	};

	if (!channelUrl || !currentChannel) {
		return <Placeholder type={PlaceHolderTypes.NO_CHANNEL} comment={NO_CHANNEL_SELECTED} />;
	}

	return (
		<>
			<VStack $height={'100%'} $width={'100%'}>
				<FileUploader
					onChange={handleChangeFile}
					values={[]}
					format={['image', 'document', 'application']}
					maxCount={10}
					maxCapacity={{ single: { unit: 'MB', size: 10 } }}
				>
					{({ onFileChange }) => (
						<VStack
							onDragOver={(e) => {
								e.preventDefault();
							}}
							onDragLeave={(e) => {
								e.preventDefault();
							}}
							onDrop={(e) => {
								e.preventDefault();
								if (!e.dataTransfer) return;
								const files = e.dataTransfer.files;
								handleDropAndPaste(files, onFileChange);
							}}
							onPaste={(e) => {
								const files = e.clipboardData.files;
								if (files.length) e.preventDefault();
								handleDropAndPaste(files, onFileChange);
							}}
							$height={'100%'}
							$width={'100%'}
						>
							<ChannelHeader currentChannel={currentChannel} />
							<MessageList />
							{activeSearch.searching ? <SearchMessageNavigator /> : <MessageInput currentChannel={currentChannel} />}
						</VStack>
					)}
				</FileUploader>
			</VStack>
			{showMemberInfo && <ChannelMemberInfo currentChannel={currentChannel} onCloseClick={onMemberInfoClick} />}
		</>
	);
};
