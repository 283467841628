import { css, type CSSProperties } from 'styled-components';

/** 1줄 말줄임 */
export const ellipsis1: CSSProperties = {
	// display: 'block',
	// whiteSpace: 'nowrap',
	// overflow: 'hidden',
	// textOverflow: 'ellipsis',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: 1,
	WebkitBoxOrient: 'vertical',
};

/** 2줄 말줄임 */
export const ellipsis2: CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
};

export const ellipsisStyle = (clamp: number) =>
	clamp === 1
		? css`
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			`
		: css`
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: ${clamp};
				-webkit-box-orient: vertical;
			`;
