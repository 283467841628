import React from 'react';

import styled, { css, type CSSProperties } from 'styled-components';

import { type ColorKeys } from '../../../utils/changeColorKeyToType';
import { Label } from '../../Display';
import { type FlexDirection } from '../types';

interface FormItemProps {
	direction?: FlexDirection;
	label: React.ReactNode;
	isRequired?: boolean;
	statusMessage?: React.ReactNode;
	statusColor?: ColorKeys;
	renderLabelPrefix?: React.ReactElement;
	renderLabelSuffix?: React.ReactElement;
	labelStyle?: CSSProperties;
	style?: CSSProperties;
	children: React.ReactNode;
}
export const FormItem = (props: FormItemProps) => {
	const {
		direction = 'ROW',
		label,
		isRequired = false,
		statusColor = 'red_600',
		statusMessage,
		renderLabelPrefix,
		renderLabelSuffix,
		labelStyle,
		style = {},
		children,
	} = props;
	return (
		<Container $direction={direction} style={{ ...style }}>
			<LabelWrapper style={{ ...labelStyle }}>
				{renderLabelPrefix}
				<FormLabel $isRequired={isRequired}>{label}</FormLabel>
				{renderLabelSuffix}
			</LabelWrapper>
			<InputWrapper>
				{children}
				{statusMessage && (
					<Label $fontWeight={'medium'} $fontStyle={'body_3'} $color={statusColor}>
						{statusMessage}
					</Label>
				)}
			</InputWrapper>
		</Container>
	);
};

const Container = styled.div<{ $direction: FlexDirection }>`
	display: flex;
	align-items: flex-start;
	flex: 1 0 0;
	flex-direction: ${({ $direction }) => ($direction === 'ROW' ? 'row' : 'column')};
`;

const LabelWrapper = styled.div<{ $isRequired?: boolean }>`
	display: flex;
	width: 18.8rem;
	height: 4rem;
	align-items: center;
	gap: 0.4rem;
	white-space: nowrap;
`;

const FormLabel = styled.div<{ $isRequired?: boolean }>`
	display: flex;
	align-items: center;
	${({ theme }) => theme.font.label.label_2};
	${({ theme }) => theme.font.weight.medium};
	color: ${({ theme }) => theme.colors.gray.gray_700};

	${({ $isRequired }) =>
		$isRequired &&
		css`
			&::after {
				content: '*';
				color: ${({ theme }) => theme.colors.red.red_600};
			}
		`}
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.8rem;
	flex: 1 0 0;
`;
