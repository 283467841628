import React, { forwardRef, type InputHTMLAttributes } from 'react';

import styled, { css, type CSSProperties } from 'styled-components';

import { changeFontKeyToType } from '../../../utils/changeFontKeyToType';
import { removeEmojis } from '../../../utils/format';
import { Label, type StyledLabelProps } from '../../Display';

type InputSize = 'sm' | 'md' | 'lg';

export interface InputCountProps {
	show: boolean;
	max: number;
}

export const InputCount = ({ count = 0, max }: { count?: number; max: number }) => {
	return (
		<>
			<Label $fontStyle={'body_2'} $fontWeight={'regular'} $color={count > 0 ? 'primary_600' : 'gray_600'}>
				{count}
			</Label>
			<Label $fontStyle={'body_2'} $fontWeight={'regular'} $color={'gray_600'}>
				{'/' + max + '자'}
			</Label>
		</>
	);
};
interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	inputSize?: InputSize;
	fontStyle?: StyledLabelProps;
	style?: CSSProperties;
	renderPrefix?: React.ReactNode;
	renderSuffix?: React.ReactNode;
	count?: InputCountProps;
	enableEmoji?: boolean;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
	(props: TextFieldProps, ref?: React.ForwardedRef<HTMLInputElement>) => {
		const {
			inputSize = 'md',
			fontStyle = {},
			style = {},
			renderPrefix,
			renderSuffix,
			count,
			readOnly,
			disabled,
			onChange,
			enableEmoji = false,
			...rest
		} = props;

		return (
			<Container $size={inputSize} style={{ ...style }} $readOnly={readOnly} $disabled={disabled}>
				{renderPrefix && <RenderWrap>{renderPrefix}</RenderWrap>}
				<CustomInput
					{...rest}
					ref={ref}
					{...fontStyle}
					readOnly={readOnly}
					disabled={disabled}
					onWheel={(e) => {
						if ((e.target as HTMLInputElement).type === 'number') (e.target as HTMLInputElement).blur();
					}}
					onChange={(e) => {
						if (e.target.maxLength > 0) {
							e.target.value = e.target.value.slice(0, e.target.maxLength);
						}

						if (!enableEmoji) {
							e.target.value = removeEmojis(e.target.value);
						}
						if (e.target.type === 'number') {
							e.target.value = e.target.value.replace(/\D/g, '');
						}
						onChange && onChange(e);
					}}
				/>
				{count && (
					<RenderWrap>
						<InputCount count={props.value?.toString().length} max={count.max} />
					</RenderWrap>
				)}
				{renderSuffix && <RenderWrap>{renderSuffix}</RenderWrap>}
			</Container>
		);
	},
);

TextField.displayName = 'TextField';

interface ContainerProps {
	$size: InputSize;
	$readOnly?: boolean;
	$disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	align-self: stretch;
	gap: 0.8rem;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background: ${({ theme, $readOnly, $disabled }) =>
		$readOnly ?? $disabled ? theme.colors.gray.gray_100 : theme.colors.white};

	${({ $size }) =>
		$size === 'sm' &&
		css`
			padding: 0.9rem 1.2rem;
			${CustomInput} {
				${({ theme }) => theme.font.label.label_2};
			}
		`};
	${({ $size }) =>
		$size === 'md' &&
		css`
			padding: 0.7rem 1.2rem;
		`};
	${({ $size }) =>
		$size === 'lg' &&
		css`
			padding: 1rem 1.2rem;
		`};

	&:focus-within {
		border: 1px solid
			${({ theme, $readOnly }) => ($readOnly ? theme.colors.gray.gray_300 : theme.colors.primary.primary_600)};
	}
`;

const CustomInput = styled.input<StyledLabelProps>`
	border: 0;
	${({ $fontStyle = 'body_2' }) => $fontStyle && changeFontKeyToType($fontStyle)};
	${({ theme, $fontWeight = 'regular' }) => $fontWeight && theme.font.weight[$fontWeight]}
	${({ $textAlign }) =>
		$textAlign &&
		css`
			text-align: ${$textAlign};
		`}
	min-width: 0;
	position: relative;
	width: 100%;
	&::placeholder {
		color: ${({ theme }) => theme.colors.gray.gray_600};
	}
	caret-color: ${({ theme }) => theme.colors.primary.primary_600};
	&:read-only,
	&:disabled {
		background: ${({ theme }) => theme.colors.gray.gray_100};
	}
	&[type='number'] {
		text-align: right;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
`;

const RenderWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: none;
`;
