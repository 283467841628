import { formatDate } from 'date-fns/format';

import { type Columns } from '../../../../components/Table/ListTable/types';
import Theme from '../../../../lib/styledComponents/Theme';
import {
	type StatsDetail,
	type DrugUsage,
	type SaleProduct,
	type StatsDetailDaily,
	type StatsDetailMonthly,
	type StatsDetailWeekday,
	type StatsDetailWeekly,
} from '../../../../services/statistics/types';
import { fractionNumber } from '../../../../utils/format';

export const PERIOD_DETAILS_COLUMNS: Columns<StatsDetail> = [
	{ key: 'pxCount', headerName: '조제건수', width: { min: '7rem', max: '10rem' }, style: { justifyContent: 'right' } },
	{ key: 'pxSales', headerName: '조제매출', width: { min: '10rem', max: '10rem' }, style: { justifyContent: 'right' } },
	{
		key: 'saleCount',
		headerName: '판매건수',
		width: { min: '7rem', max: '10rem' },
		style: { justifyContent: 'right' },
	},
	{
		key: 'sales',
		headerName: '판매매출',
		width: { min: '15rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'right', flex: 1 },
	},
	{
		key: 'discountPrice',
		headerName: '할인금액',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => -value,
	},
	{
		key: 'purchasePrice',
		headerName: '사입/반품',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
		renderComponent: (_, item) => {
			return item.purchasePrice - item.receivePrice;
		},
	},
	{
		key: 'totalSales',
		headerName: '총 매출액',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
	},
	{
		key: 'nonInsureMargin',
		headerName: '비급여마진',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
	},
	{
		key: 'nonInsureExtraCharge',
		headerName: '비급여할증',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
	},
	{ key: 'pxPrice', headerName: '조제료', width: { min: '10rem', max: '10rem' }, style: { justifyContent: 'right' } },
	{
		key: 'saleMargin',
		headerName: '판매마진',
		width: { min: '10rem', max: '10rem' },
		style: { justifyContent: 'right' },
	},
	{
		key: 'grossProfit',
		headerName: '매출 총이익',
		width: { min: '15rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'right', flex: 1 },
	},
];

export const DAILY_DETAILS_COLUMNS: Columns<StatsDetailDaily> = [
	{
		key: 'day',
		headerName: '기간',
		width: { min: '10rem', max: '10rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'center' },
	},
	...(PERIOD_DETAILS_COLUMNS as Columns<StatsDetailDaily>),
];

export const WEEKLY_DETAILS_COLUMNS: Columns<StatsDetailWeekly> = [
	{
		key: 'startDate',
		headerName: '기간',
		width: { min: '10rem', max: '10rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'center' },
		renderComponent: (_, item) => {
			return `${formatDate(item.startDate, 'MM-dd')}~${formatDate(item.endDate, 'MM-dd')}`;
		},
	},
	...(PERIOD_DETAILS_COLUMNS as Columns<StatsDetailWeekly>),
];

export const MONTHLY_DETAILS_COLUMNS: Columns<StatsDetailMonthly> = [
	{
		key: 'month',
		headerName: '기간',
		width: { min: '10rem', max: '10rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'center' },
	},
	...(PERIOD_DETAILS_COLUMNS as Columns<StatsDetailMonthly>),
];

export const WEEKDAY_DETAILS_COLUMNS: Columns<StatsDetailWeekday> = [
	{
		key: 'dayOfWeek',
		headerName: '요일',
		width: { max: '8rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'center' },
	},
	{
		key: 'pxCount',
		headerName: '조제 건수',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 0),
	},
	{
		key: 'pxSales',
		headerName: '조제 매출',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'saleCount',
		headerName: '판매 건수',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 0),
	},
	{
		key: 'sales',
		headerName: '판매 매출',
		width: { min: '17.2rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'right', flex: 1 },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'totalSales',
		headerName: '매출액',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'nonInsureMargin',
		headerName: '비급여마진',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'nonInsureExtraCharge',
		headerName: '비급여할증',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'pxPrice',
		headerName: '조제료',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'saleMargin',
		headerName: '판매 마진',
		width: { max: '11.2rem' },
		style: { justifyContent: 'right' },
		renderComponent: (value) => fractionNumber(value, 2),
	},
	{
		key: 'grossProfit',
		headerName: '매출 총이익',
		width: { min: '17.2rem' },
		style: { backgroundColor: Theme.colors.primary.primary_200, justifyContent: 'right', flex: 1 },
		renderComponent: (value) => fractionNumber(value, 2),
	},
];

const WEEKDAY_AVG_DATA_KEYS: Array<keyof StatsDetailWeekday> = [
	'pxCount',
	'pxSales',
	'saleCount',
	'sales',
	'totalSales',
	'pxPrice',
	'saleMargin',
	'grossProfit',
];

// export const WEEKDAY_AVG_DETAILS_COLUMNS: Columns<StatsDetailWeekday> = WEEKDAY_DETAILS_COLUMNS.map(
// 	({ key, headerName, ...rest }, idx) =>
// 		WEEKDAY_AVG_DATA_KEYS.includes(key)
// 			? { key, headerName: '평균 ' + headerName, ...rest }
// 			: { key, headerName, ...rest },
// );
export const WEEKDAY_AVG_DETAILS_COLUMNS: Columns<StatsDetailWeekday> = WEEKDAY_DETAILS_COLUMNS.map((item, idx) =>
	WEEKDAY_AVG_DATA_KEYS.includes(item.key)
		? {
				...item,
				headerName: '평균' + item.headerName,
			}
		: { ...item },
);

export const SALE_PRODUCTS_COLUMNS: Columns<SaleProduct> = [
	{
		key: 'productCode',
		headerName: '순위',
		width: { min: '4.8rem', max: '4.8rem' },
		style: { justifyContent: 'center' },
		renderComponent: (item, _, idx) => idx + 1,
	},
	{
		key: 'productName',
		headerName: '상품명',
		width: { min: '34.2rem' },
		style: { flex: 1, justifyContent: 'left', overflow: 'hidden', textOverflow: 'ellipsis' },
	},
	{ key: 'saleAmount', headerName: '판매량', width: { min: '8rem', max: '8rem' }, style: { justifyContent: 'right' } },
	{
		key: 'saleCount',
		headerName: '판매 건수',
		width: { min: '8rem', max: '8rem' },
		style: { justifyContent: 'right' },
	},
	{ key: 'unitPrice', headerName: '판매가', width: { min: '10rem', max: '10rem' }, style: { justifyContent: 'right' } },
];

export const DRUG_USAGES_COLUMNS: Columns<DrugUsage> = [
	{
		key: 'drugCode',
		headerName: '순위',
		width: { min: '4.8rem', max: '4.8rem' },
		style: { justifyContent: 'center' },
		renderComponent: (item, _, idx) => idx + 1,
	},
	{
		key: 'drugName',
		headerName: '약품명',
		width: { min: '34.2rem' },
		style: {
			flex: 1,
			justifyContent: 'left',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	{ key: 'pxAmount', headerName: '사용량', width: { min: '8rem', max: '8rem' }, style: { justifyContent: 'right' } },
	{ key: 'pxCount', headerName: '조제 건수', width: { min: '8rem', max: '8rem' }, style: { justifyContent: 'right' } },
	{ key: 'unitPrice', headerName: '단가', width: { min: '10rem', max: '10rem' }, style: { justifyContent: 'right' } },
];
