import React from 'react';

import { SALE_PRODUCTS_COLUMNS } from './columns';
import { StatsStyledTable } from './styles';
import ListTable from '../../../../components/Table/ListTable';
import { useGetSaleProductsTotal } from '../../../../services/statistics/queries';

interface SaleProductsProps {
	storeId: string;
	startDate: string;
	endDate: string;
	maxHeight?: string;
}

export function SaleProducts({ storeId, startDate, endDate, maxHeight = '16.5rem' }: SaleProductsProps) {
	const { data } = useGetSaleProductsTotal({ storeId, startDate, endDate });

	return (
		<StatsStyledTable>
			<ListTable data={data} columns={SALE_PRODUCTS_COLUMNS} maxHeight={maxHeight} />
		</StatsStyledTable>
	);
}
