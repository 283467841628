import { type DefaultTheme } from 'styled-components';

import Theme, { type FontType } from '../lib/styledComponents/Theme';

type IncludeNestedKeys<T> = {
	[K in keyof T]: T[K] extends Record<string, any> ? keyof T[K] : never;
}[keyof T];

type FontKeys = Exclude<IncludeNestedKeys<FontType>, 'bold' | 'regular'>;

function changeFontKeyToType(fontKey: FontKeys): string {
	const fontPrefix = fontKey.split('_')[0];
	switch (fontPrefix) {
		case 'headline':
			return Theme.font.headline[fontKey as keyof DefaultTheme['font']['headline']];
		case 'title':
			return Theme.font.title[fontKey as keyof DefaultTheme['font']['title']];
		case 'body':
			return Theme.font.body[fontKey as keyof DefaultTheme['font']['body']];
		case 'caption':
			return Theme.font.caption[fontKey as keyof DefaultTheme['font']['caption']];
		case 'label':
			return Theme.font.label[fontKey as keyof DefaultTheme['font']['label']];
		default:
			return '';
	}
}

export type { FontKeys };
export { changeFontKeyToType };
