import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Calculate } from './components/Calculate';
import { PAYMENT_FILTER_ITEMS, PAYMENT_TABLE_COLUMNS } from './const';
import { Button } from '../../../components/Buttons';
import { InsideTitle } from '../../../components/Common';
import { Icon } from '../../../components/Display';
import { Filter } from '../../../components/Filter';
import { FilterList } from '../../../components/Filter/components/FilterList';
import { Select, TextField } from '../../../components/Forms';
import { ConfirmModal } from '../../../components/Modals';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { useGetPayments, useGetPaymentsExcel, usePostPickup } from '../../../services/payment/queries';
import { type GetPaymentSearchType } from '../../../services/payment/types';
import { type Search } from '../../../services/types';
import { type DefaultOption } from '../../../utils/consts';
import { formatSearch } from '../../../utils/format';
import { handleChangeSorts } from '../../../utils/onChangeSorts';
import { onDownloadBlob } from '../../../utils/onDownloadBlob';

const searchOptions: Array<DefaultOption<GetPaymentSearchType>> = [
	{
		key: 'PHONE',
		label: '전화번호',
	},
	{ key: 'NAME', label: '이름' },
];

const History = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const navigate = useNavigate();
	const { data, pagination, queries, isLoading, refetch } = useGetPayments(userInfo.storeId, {
		historyKey: {
			pagination: 'getPaymentsPage',
			queries: 'getPaymentsQueries',
		},
	});

	const initTmpSearch: Search<GetPaymentSearchType> = useMemo(
		() => ({
			searchCategory: 'PHONE',
			searchKeyword: '',
		}),
		[],
	);

	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(initTmpSearch);

	const onSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault();
			pagination.resetCurPage();

			const trimValue = formatSearch(tmpSearch.searchKeyword ?? '', tmpSearch.searchCategory);
			queries.dispatch('SET', { search: { ...tmpSearch, searchKeyword: trimValue } });
		},
		[tmpSearch],
	);

	const { mutate, isPending } = useGetPaymentsExcel({
		onSuccess: (res) => {
			onDownloadBlob(res, '결제내역.xlsx', 'application/octet-stream');
		},
		onError: (error) => {
			toast.error('다운로드에 실패하였습니다.\n' + error.response?.data.message);
		},
	});

	const onExcelDownload = useCallback(() => {
		mutate(queries.values);
	}, [queries.values]);

	const { mutate: pickupMutate, isPending: isPickupPending } = usePostPickup({
		onSuccess: () => {
			toast.success('상태 변경에 성공하였습니다.');
			refetch();
		},
		onError: (error) => {
			toast.error('상태 변경에 실패하였습니다.\n' + error.response?.data.message);
		},
	});

	const onPickup = useCallback(
		(paymentId: string) => {
			handleOpen(
				<ConfirmModal
					confirm={() => {
						pickupMutate({ storeId: userInfo.storeId, paymentId });
					}}
					message={'수령완료 상태로 변경하시겠습니까?'}
				/>,
			);
		},
		[userInfo],
	);

	return (
		<Container>
			<Calculate />
			<div>
				<InsideTitle title="결제 내역 조회" />
				<ListTable
					isLoading={isLoading || isPickupPending}
					data={data?.content}
					columns={PAYMENT_TABLE_COLUMNS({ onPickup })}
					headerStyle={{ paddingTop: 0 }}
					headerLeftContent={
						<TableLeft>
							<form onSubmit={onSubmit}>
								<Select
									options={searchOptions}
									value={tmpSearch.searchCategory}
									labelStyle={{ width: '10.4rem' }}
									onClick={(key) => {
										tmpSearchDispatch('SET', {
											searchCategory: key as GetPaymentSearchType,
											searchKeyword: '',
										});
									}}
								/>
								<TextField
									renderPrefix={<Icon id="btn-search" width="2rem" height="2rem" />}
									inputSize={'sm'}
									style={{ borderRadius: '4px', width: '32rem' }}
									placeholder="검색어를 입력해주세요."
									value={tmpSearch.searchKeyword}
									name="searchKeyword"
									onChange={onChangeTmpSearch}
								/>
							</form>
							<Filter items={PAYMENT_FILTER_ITEMS} resetCurPage={pagination.resetCurPage} queries={queries} />
						</TableLeft>
					}
					headerBottomContent={
						<FilterList items={PAYMENT_FILTER_ITEMS} resetCurPage={pagination.resetCurPage} queries={queries} />
					}
					headerRightContent={
						<Button
							onClick={() => {
								onExcelDownload();
							}}
							disabled={isPending}
							shouldPrevent={true}
							size={{ $paddingSize: 'M' }}
						>
							<Icon id="file" defaultColor="white" width="2rem" height="2rem" />
							엑셀 다운로드
						</Button>
					}
					onRowClick={(item) => {
						navigate(`/payment/history/detail/${item.id}`);
					}}
					onCategoryClick={(column) => {
						handleChangeSorts(queries.values.sorts, column, (sorts) => {
							queries.dispatch('SET', {
								sorts,
							});
						});
					}}
					sortValues={queries.values.sorts}
					pagination={pagination}
					unit="건"
					emptyMessage="결제 내역이 없습니다."
				/>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
`;
const TableLeft = styled.div`
	display: flex;
	gap: 0.8rem;
	> form {
		display: flex;
		gap: 0.8rem;
		align-items: center;
	}
`;

export default History;
