import {
	type PostGroupMembersRequest,
	type GetGroupsRequest,
	type GetGroupsResponse,
	type PostGroupsRequest,
	type GetGroupDetailRequest,
	type Group,
	type GetGroupMembersRequest,
	type GetGroupMembersResponse,
	type PutGroupDetailRequest,
	type GetGroupCategoriesUsageResponse,
} from './types';
import { Delete, Get, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';

export const groups = {
	getGroups: async <T = GetGroupsResponse>({ storeId, ...rest }: GetGroupsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/groups${formatQueryString(rest)}`);
		return response.data;
	},
	postGroups: async <T = any>({ storeId, ...rest }: PostGroupsRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/groups`, rest);
		return response.data;
	},
	getGroupDetail: async <T = Group>({ storeId, groupId }: GetGroupDetailRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/groups/${groupId}`);
		return response.data;
	},
	putGroupDetail: async <T = any>({ storeId, groupId, ...rest }: PutGroupDetailRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/groups/${groupId}`, rest);
		return response.data;
	},
	deleteGroupDetail: async <T = any>({ storeId, groupId }: GetGroupDetailRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/groups/${groupId}`);
		return response.data;
	},
	getGroupMembers: async <T = GetGroupMembersResponse>({
		storeId,
		groupId,
		...rest
	}: GetGroupMembersRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/groups/${groupId}/members${formatQueryString(rest)}`);
		return response.data;
	},
	postGroupMembers: async <T = any>(params: PostGroupMembersRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${params.storeId}/groups/${params.groupId}/members`, params.data);
		return response.data;
	},
	deleteGroupMembers: async <T = any>(params: PostGroupMembersRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${params.storeId}/groups/${params.groupId}/members`, {
			data: params.data,
		});
		return response.data;
	},
	getGroupCategoriesUsage: async <T = GetGroupCategoriesUsageResponse>(storeId: string): Promise<T> => {
		const res = await Get<T>(`/pharms/${storeId}/group-categories/usage`);
		return res.data;
	},
};
