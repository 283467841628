import {
	type PostProductRequest,
	type ProductItem,
	type GetProductsRequest,
	type GetProductsResponse,
	type PutProductRequest,
	type DeleteProductRequest,
} from './types';
import { Delete, Get, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';

const BASE_URL = (storeId: string) => `/pharms/${storeId}/products` as const;

export const products = {
	getProducts: async <T = GetProductsResponse>({ storeId, ...rest }: GetProductsRequest): Promise<T> =>
		(await Get<T>(BASE_URL(storeId) + formatQueryString(rest))).data,
	postProduct: async <T = ProductItem>({ storeId, ...data }: PostProductRequest) =>
		(await Post<T>(BASE_URL(storeId), data)).data,
	putProduct: async <T = ProductItem>({ storeId, id, ...data }: PutProductRequest) =>
		(await Put<T>(BASE_URL(storeId) + `/${id}`, data)).data,
	deleteProducts: async <T = unknown>({ storeId, ...data }: DeleteProductRequest) =>
		(await Delete<T>(BASE_URL(storeId), { data })).data,
};
