import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Flex, InsideTitle } from '../../../components/Common';
import { Label } from '../../../components/Display';
import context from '../../../context';
import { type PeriodUnit } from '../../../services/statistics/queries';
import { PrescriptionsCard, SalesCard, TotalsGrossProfit } from '../components/DescriptionCard';
import { PeriodChart } from '../components/PeriodChart';
import { PeriodPicker } from '../components/PeriodPicker';
import { DrugUsages, PeriodDetails, SaleProducts } from '../components/StatsTable';
import { Content, ItemsWrapper, Wrapper } from '../components/styles';
import { formatPeriod } from '../utils';

import { type DateRange, labels } from './index';

const offsets: Record<PeriodUnit, number> = {
	daily: 1,
	weekly: 3,
	monthly: 12,
};

interface PanelProps {
	unit: PeriodUnit;
	dateRange: DateRange;
	onChangePeriod: (updated: [Date | null, Date | null]) => void;
}

export function Panel({ unit, dateRange, onChangePeriod }: PanelProps) {
	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;
	const { startDate, endDate } = useMemo(() => formatPeriod(dateRange), [dateRange]);

	return (
		<Container>
			<Wrapper>
				<Content $height={'54rem'}>
					<InsideTitle
						title={'기간 분석'}
						rightRender={
							<PeriodPicker
								initPeriod={dateRange}
								label={labels[unit]}
								offset={offsets[unit]}
								onChangePeriod={onChangePeriod}
								unit={unit}
							/>
						}
						style={{ minHeight: '6.4rem' }}
					/>
					<PeriodChart storeId={storeId} startDate={startDate} endDate={endDate} unit={unit} />
				</Content>
				<Content $gap={'2rem'}>
					<InsideTitle title={'기간 총계'} />
					<ItemsWrapper $direction={'column'} $gap={'4.8rem'}>
						<ItemsWrapper $gap={'2rem'}>
							<PrescriptionsCard storeId={storeId} startDate={startDate} endDate={endDate} />
							<SalesCard storeId={storeId} startDate={startDate} endDate={endDate} />
						</ItemsWrapper>
						<ItemsWrapper $gap={'2rem'}>
							<TotalsGrossProfit storeId={storeId} dateRange={dateRange} isLastYear />
							<TotalsGrossProfit storeId={storeId} dateRange={dateRange} isLastYear={false} />
						</ItemsWrapper>
					</ItemsWrapper>
				</Content>
				<Content $gap={'2rem'}>
					<InsideTitle title={'상품 순위'} />
					<ItemsWrapper $gap={'2rem'}>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ width: '50%' }}>
							<Label $color={'gray_900'} $fontWeight={'bold'} $fontStyle={'title_3'}>
								{'BEST 판매량 상품'}
							</Label>
							<SaleProducts storeId={storeId} startDate={startDate} endDate={endDate} />
						</ItemsWrapper>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ width: '50%' }}>
							<Label $color={'gray_900'} $fontWeight={'bold'} $fontStyle={'title_3'}>
								{'최다 사용량 약품'}
							</Label>
							<DrugUsages storeId={storeId} startDate={startDate} endDate={endDate} />
						</ItemsWrapper>
					</ItemsWrapper>
				</Content>
				<PeriodDetails
					storeId={storeId}
					startDate={startDate}
					endDate={endDate}
					unit={unit}
					showViewDetails
					maxHeight={'36.5rem'}
				/>
			</Wrapper>
		</Container>
	);
}

const Container = styled(Flex)`
	flex-direction: column;
	align-self: stretch;
	height: 100%;
	width: 100%;
`;
