import React, { useMemo } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import styled from 'styled-components';

import { GroupInfo } from './components/GroupInfo';
import { HistoryInfo } from './components/HistoryInfo';
import { MemoInfo } from './components/MemoInfo';
import { NicknameInfo } from './components/NicknameInfo';
import { NotificationSetting } from './components/NotificationSetting';
import { scrollStyle } from '../../assets/styles/scrollStyle';
import { IconButton } from '../../components/Buttons';
import { Flex, HStack, VStack } from '../../components/Common';
import { Icon, IconID, Label } from '../../components/Display';
import { useSBStateContext } from '../../context/sendbirdSdk';
import { useMemberInfo } from '../../services/member/queries';
import { type GetMemberRequest } from '../../services/member/types';
import { GENDER_VALUES } from '../../utils/consts';
import { formatBirth, formatPhoneNumber } from '../../utils/format';
import { useChannelContext } from '../Channel/context/ChannelProvider';
import { getMemberId, getStoreId } from '../Channel/context/utils';
import { ChannelAvatar } from '../ChannelList/components/ChannelAvatar';
import { getChannelTitle } from '../ChannelList/components/ChannelListItem/utils';

export type MemberInfoParams = Required<GetMemberRequest>;
interface ChannelMemberInfoProps {
	currentChannel: GroupChannel;
	onCloseClick?: () => void;
}

export const ChannelMemberInfo = (props: ChannelMemberInfoProps) => {
	const { currentChannel, onCloseClick } = props;
	const { stores } = useSBStateContext();
	const { userId: currentUserId } = stores.userStore.user;

	const { member } = useChannelContext();
	const { profileUrl, nickname, userId, connectionStatus } = member;

	const storeId = getStoreId(currentUserId);
	const memberId = getMemberId(userId);

	const params = useMemo(() => ({ storeId, userId: memberId }), [storeId, memberId]);

	const { data, isSuccess } = useMemberInfo({
		...params,
		select: (data) => ({
			...data,
			birth: formatBirth(data.birth),
			gender: GENDER_VALUES[data.gender],
			phone: formatPhoneNumber(data.phone),
		}),
	});

	return (
		<Container>
			<Wrapper>
				<Flex $padding={'2.4rem 2rem'} $justify={'end'} $alignItems={'center'}>
					<IconButton onClick={onCloseClick} width={'2.4rem'} height={'2.4rem'}>
						<Icon id={IconID.BTN_CLOSE} width={'2.4rem'} height={'2.4rem'} />
					</IconButton>
				</Flex>

				<Flex
					$direction={'column'}
					$justify={'center'}
					$alignItems={'center'}
					$gap={'1.6rem'}
					$padding="1.6rem 1.6rem 2.4rem"
				>
					<ChannelAvatar
						profileUrl={profileUrl}
						width={'8rem'}
						height={'8rem'}
						status={{ isOnline: connectionStatus === 'online', borderWidth: '0.45rem', statusSize: '2.4rem' }}
					/>
					<VStack $gap="1.2rem" $alignItems="center">
						<Label $fontStyle={'title_1'} $fontWeight={'bold'}>
							{nickname ?? getChannelTitle(currentChannel, currentUserId)}
						</Label>
						{isSuccess && (
							<Information $gap="1.2rem" $alignItems="center">
								<HStack $gap="0.6rem">
									<span>{data.birth}</span>
									<span className="divide" />
									<span>{data.gender}</span>
								</HStack>
								<span>{data.phone}</span>
							</Information>
						)}
					</VStack>
				</Flex>
				{isSuccess && (
					<BorderWrapper $direction={'column'} $alignItems={'start'} $gap={'2rem'} $padding="2rem 1.6rem">
						<NotificationSetting channel={currentChannel} />
						<GroupInfo {...params} />
						<React.Fragment>
							<NicknameInfo {...{ ...params, nicknames: data.nicknames }} />
							<MemoInfo {...{ ...params, memo: data.memo }} />
						</React.Fragment>
					</BorderWrapper>
				)}
				<BorderWrapper $padding="2rem 1.6rem">
					<HistoryInfo {...params} />
				</BorderWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled(VStack)`
	min-width: 27.8rem;
	max-width: 27.8rem;
	width: 100%;
	height: 100%;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const Wrapper = styled(VStack)`
	flex: 1 1 0;

	overflow: hidden auto;
	${scrollStyle({ hideHorizontal: true })};
	&::-webkit-scrollbar {
		width: 0.6rem;
	}
`;

const Information = styled(VStack)`
	${({ theme }) => theme.font.label.label_2};
	.divide {
		color: ${({ theme }) => theme.colors.gray.gray_500};
		&::after {
			content: '|';
		}
	}
`;

const BorderWrapper = styled(VStack)`
	border-top: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;
