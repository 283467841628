import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button } from '../../../../../components/Buttons';
import { Footer, InsideTitle, Loading } from '../../../../../components/Common';
import { FormItem, Radio, TextField } from '../../../../../components/Forms';
import { AlertModal, ConfirmModal } from '../../../../../components/Modals';
import context from '../../../../../context';
import { useValues } from '../../../../../hooks/useValues';
import { useDeleteUserDetail, useGetUserDetail, usePutUserDetail } from '../../../../../services/users/queries';
import { type PutUserDetailRequest } from '../../../../../services/users/types';
import { type DefaultOption, USER_TYPE_OPTIONS } from '../../../../../utils/consts';
import { formatPhoneNumber } from '../../../../../utils/format';
import { passwordChecker } from '../../../../../utils/passwordChecker';

interface TmpPutUsersRequest extends PutUserDetailRequest {
	newPassword: string;
	confirmPassword: string;
}
const isActiveOptions: Array<DefaultOption<boolean>> = [
	{ key: true, label: '근무' },
	{ key: false, label: '퇴사' },
];

export const ModifyUsers = () => {
	const { userId } = useParams();
	const navigate = useNavigate();

	const [isPasswordChange, setPasswordChange] = useState(false);

	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const initialValues: TmpPutUsersRequest = useMemo(
		() => ({
			name: '',
			type: 'PARTNER',
			loginId: '',
			phone: '',
			password: '',
			isActive: true,

			newPassword: '',
			confirmPassword: '',
		}),
		[],
	);
	const { values, onChangeValues, dispatch } = useValues(initialValues);
	const validPassword = useMemo(() => passwordChecker(values.newPassword, values.confirmPassword), [values]);

	const { data, isLoading, isError, error } = useGetUserDetail({ storeId: userInfo.storeId, id: userId });

	useEffect(() => {
		if (data) {
			const { id, storeId, license, phone, ...mutableValues } = data;
			dispatch('SET', { ...mutableValues, phone: formatPhoneNumber(phone) });
		}
	}, [data]);

	const { mutate: deleteMutate } = useDeleteUserDetail({
		onSuccess: (res) => {
			navigate('/operation/basic/management');
		},
		onError: (err) => {
			toast.error('직원 삭제에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { mutate: putMutate } = usePutUserDetail({
		onSuccess: () => {
			navigate('/operation/basic/management');
			handleOpen(<AlertModal message={'직원 정보 수정이 완료되었습니다.'} />);
		},
		onError: (err) => {
			toast.error('직원 수정에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const isValid = useMemo(() => values.name !== '' && values.phone.length >= 12, [values.name, values.phone]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const { confirmPassword, newPassword, password, phone, ...rest } = values;
			const params: PutUserDetailRequest = {
				storeId: userInfo.storeId,
				id: userId,
				phone: phone.replaceAll('-', ''),
				...rest,
			};
			if (password) {
				params.password = password;
			}
			putMutate(params);
		},
		[values, userInfo, userId],
	);

	const onDelete = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					deleteMutate({ storeId: userInfo.storeId, id: userId });
				}}
				message={'직원을 삭제하시겠습니까?'}
				buttonMessage={{ shouldPrevent: true }}
			/>,
		);
	}, [userInfo, userId]);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		return error.message;
	}

	return (
		<Container onSubmit={onSubmit}>
			<div className="inner">
				<InsideTitle
					title="직원 정보"
					rightRender={
						<Button
							buttonType="LINE"
							color="RED"
							onClick={() => {
								onDelete();
							}}
						>
							직원 삭제
						</Button>
					}
					isBorder
				/>
				<div id="form">
					<FormItem label="권한" isRequired>
						<Radio
							name={'type'}
							options={USER_TYPE_OPTIONS.slice(1)}
							selectValue={values.type}
							onChange={onChangeValues}
						/>
					</FormItem>
					<FormItem label="이름" isRequired>
						<TextField
							onChange={onChangeValues}
							name="name"
							value={values.name}
							style={{ height: '4rem' }}
							placeholder="이름을 입력해 주세요."
						/>
					</FormItem>
					<FormItem label="아이디" isRequired>
						<TextField
							onChange={onChangeValues}
							name="loginId"
							value={values.loginId}
							style={{ height: '4rem' }}
							placeholder="5자리 면허번호"
							readOnly
						/>
					</FormItem>
					<FormItem label="비밀번호" isRequired>
						<div className="input__wrapper">
							<TextField
								onChange={onChangeValues}
								type="password"
								name="password"
								value={values.password !== '' ? values.password : '**********'}
								style={{ height: '4rem' }}
								placeholder="6~20자, 문자, 숫자, 특수문자"
								maxLength={20}
								readOnly
							/>
							<Button
								buttonType="LINE"
								color={isPasswordChange ? 'RED' : 'PRIMARY'}
								onClick={() => {
									setPasswordChange(!isPasswordChange);
								}}
							>
								{isPasswordChange ? '변경취소' : '변경하기'}
							</Button>
						</div>
					</FormItem>
					{isPasswordChange && (
						<React.Fragment>
							<FormItem
								label="새 비밀번호"
								statusMessage={validPassword.errorTarget === 'password' ? validPassword.message : undefined}
							>
								<TextField
									onChange={onChangeValues}
									type="password"
									name="newPassword"
									value={values.newPassword}
									style={{ height: '4rem' }}
									placeholder="1~20자, 문자, 숫자, 특수문자"
									maxLength={20}
								/>
							</FormItem>
							<FormItem
								label="새 비밀번호 확인"
								statusMessage={validPassword.errorTarget === 'confirmPassword' ? validPassword.message : undefined}
							>
								<div className="input__wrapper">
									<TextField
										onChange={onChangeValues}
										type="password"
										name="confirmPassword"
										value={values.confirmPassword}
										style={{ height: '4rem' }}
										placeholder="비밀번호를 한번 더 입력해 주세요."
										maxLength={20}
									/>
									<Button
										disabled={!validPassword.isConfirm}
										onClick={() => {
											setPasswordChange(false);
											dispatch('SET', { password: values.newPassword });
										}}
									>
										확인
									</Button>
								</div>
							</FormItem>
						</React.Fragment>
					)}
					<FormItem label="휴대폰 번호" isRequired>
						<TextField
							onChange={(e) => {
								dispatch('SET', { phone: formatPhoneNumber(e.target.value) });
							}}
							name="phone"
							value={values.phone}
							style={{ height: '4rem' }}
							placeholder="휴대폰 번호를 입력해 주세요."
							maxLength={13}
						/>
					</FormItem>
					<FormItem label="근무 여부" isRequired>
						<Radio
							name={'isActive'}
							options={isActiveOptions}
							selectValue={values.isActive}
							onChange={(e) => {
								dispatch('SET', { isActive: e.target.value === 'true' });
							}}
						/>
					</FormItem>
				</div>
			</div>
			<Footer>
				<Button
					style={{ width: '20rem' }}
					buttonType="LINE"
					color="SECONDARY"
					onClick={() => {
						handleOpen(
							<ConfirmModal
								confirm={() => {
									navigate(-1);
								}}
								message={'수정을 취소하시겠습니까?\n입력된 변경사항은 저장되지 않습니다.'}
							/>,
						);
					}}
				>
					취소
				</Button>
				<Button style={{ width: '20rem' }} disabled={!isValid} type="submit" shouldPrevent={true}>
					저장
				</Button>
			</Footer>
		</Container>
	);
};

const Container = styled.form`
	height: 100%;
	display: flex;
	flex-direction: column;
	.inner {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		#form {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			> div {
				max-width: 76.8rem;
			}
			.input__wrapper {
				display: flex;
				gap: 0.8rem;
				flex: 1;
				> div {
					flex: 1;
				}
			}
		}
	}
`;
