import React, { useCallback, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { usePutPassword } from '../../../services/users/queries';
import { passwordChecker } from '../../../utils/passwordChecker';
import { FormItem, TextField } from '../../Forms';
import { Modal } from '../Modal';

export const ChangePasswordModal = () => {
	const { handleClose } = context.modal.useDispatch();
	const initQueries = useMemo(() => ({ oldPassword: '', newPassword: '', confirmPassword: '' }), []);
	const { values, onChangeValues } = useValues(initQueries);
	const validPassword = useMemo(
		() => passwordChecker(values.newPassword, values.confirmPassword, values.oldPassword),
		[values],
	);
	const [isOldPasswordError, setOldPasswordError] = useState(false);

	const { mutate } = usePutPassword({
		onSuccess: () => {
			toast.success('비밀번호가 변경되었습니다.');
			handleClose();
		},
		onError: (err) => {
			if (err.response?.data.code === 'NOT_MATCHED_OLD_PASSWORD') {
				setOldPasswordError(true);
			} else {
				toast.error('비밀번호 변경에 실패하였습니다.\n' + err.response?.data.message);
			}
		},
	});

	const onSubmit = useCallback(() => {
		setOldPasswordError(false);
		if (!validPassword.isConfirm) return;

		const { confirmPassword, ...rest } = values;
		mutate(rest);
	}, [values.newPassword, values.oldPassword, validPassword.isConfirm]);

	return (
		<Modal
			header={{ title: '비밀번호 변경' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '변경',
						onClick: onSubmit,
						style: { width: '10rem' },
						shouldPrevent: true,
						disabled: !validPassword.isConfirm,
					},
				],
			}}
		>
			<Container>
				<FormItem
					label={'현재 비밀번호'}
					isRequired
					statusMessage={isOldPasswordError ? '비밀번호가 일치하지 않습니다.' : ''}
				>
					<TextField
						type="password"
						name="oldPassword"
						value={values.oldPassword}
						onChange={onChangeValues}
						inputSize={'lg'}
						style={{ flex: 1 }}
						placeholder="현재 비밀번호"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSubmit();
							}
						}}
					/>
				</FormItem>
				<FormItem
					label={'새 비밀번호'}
					isRequired
					statusMessage={validPassword.errorTarget === 'password' ? validPassword.message : undefined}
				>
					<TextField
						type="password"
						placeholder="1~20자, 영문, 숫자, 특수문자"
						maxLength={20}
						name="newPassword"
						value={values.newPassword}
						onChange={onChangeValues}
						inputSize={'lg'}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSubmit();
							}
						}}
					/>
				</FormItem>
				<FormItem
					label={'새 비밀번호 확인'}
					isRequired
					statusMessage={validPassword.errorTarget === 'confirmPassword' ? validPassword.message : undefined}
				>
					<TextField
						type="password"
						placeholder="비밀번호 재입력"
						maxLength={20}
						name="confirmPassword"
						value={values.confirmPassword}
						onChange={onChangeValues}
						inputSize={'lg'}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSubmit();
							}
						}}
					/>
				</FormItem>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 80rem;
`;
