import React from 'react';

import styled, { css } from 'styled-components';

import { type Types, IconID } from './types';
import { changeColorKeyToType, type ColorKeys } from '../../../utils/changeColorKeyToType';

interface IconSvgProps {
	$width?: string;
	$height?: string;
	$defaultColor?: ColorKeys;
	$activeColor?: ColorKeys;
	$disabledColor?: ColorKeys;
	$isActive?: boolean;
	$isDisabled?: boolean;
	$hoverColor?: ColorKeys;
}

export interface IconProps {
	id: Types;
	className?: string;
	fill?: string;
	stroke?: string;
	width?: string;
	height?: string;
	defaultColor?: ColorKeys;
	activeColor?: ColorKeys;
	disabledColor?: ColorKeys;
	hoverColor?: ColorKeys;
	isActive?: boolean;
	isDisabled?: boolean;
}

export const Icon = ({
	id,
	className,
	width = '2.4rem',
	height = '2.4rem',
	fill = 'none',
	stroke = 'none',
	defaultColor = 'black',
	activeColor = 'primary_600',
	disabledColor = 'gray_500',
	hoverColor,
	isActive = false,
	isDisabled = false,
}: IconProps) => {
	return (
		<>
			<Svg
				fill={fill}
				stroke={stroke}
				className={className}
				$width={width}
				$height={height}
				$defaultColor={defaultColor}
				$activeColor={activeColor}
				$disabledColor={disabledColor}
				$isActive={isActive}
				$isDisabled={isDisabled}
				$hoverColor={hoverColor}
			>
				<use href={`/sprite.svg#icon-${id}`} />
			</Svg>
		</>
	);
};

const Svg = styled.svg<IconSvgProps>`
	color: ${({ $defaultColor }) => $defaultColor && changeColorKeyToType($defaultColor)};
	color: ${({ $isActive, $activeColor }) => $isActive && $activeColor && changeColorKeyToType($activeColor)};
	color: ${({ $isDisabled, $disabledColor }) => $isDisabled && $disabledColor && changeColorKeyToType($disabledColor)};
	${({ $width, $height }) => css`
		width: ${$width};
		height: ${$height};
	`}
	&:hover {
		color: ${({ $hoverColor, $isActive, $isDisabled }) =>
			!$isDisabled && !$isActive && $hoverColor && changeColorKeyToType($hoverColor)};
	}
`;

export { IconID, type Types as IconTypes };
