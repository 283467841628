import React, { useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Navigate from './components/Navigate';
import Profile from './components/Profile';
import findMenu from './utils/findTitle';
import context from '../../context';
import { formatPathArray } from '../../utils/format';
import { HEADER_HEIGHT, NAV_SMALL_WIDTH } from '../constant';
import { mobilePharmacyList, othersList, notIncludedList, GP_V3_LIST } from '../Nav/data/NavData';

const Header = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const path = useMemo(() => formatPathArray(pathname), [pathname]);
	const { userInfo } = context.user.useValue();
	const findMenuItem = useMemo(() => {
		const menuListArr = [mobilePharmacyList, othersList, notIncludedList, ...GP_V3_LIST];
		let result;

		for (const menuList of menuListArr) {
			const findMenuItem = findMenu(menuList.list, path);
			if (findMenuItem) {
				result = findMenuItem;
				break;
			}
		}

		return result;
	}, [pathname]);

	useEffect(() => {
		if (findMenuItem && !findMenuItem.grade.includes(userInfo.type)) {
			navigate(-1);
			toast.error('접근 권한이 없습니다.');
		}
	}, [findMenuItem, userInfo]);

	return (
		<HeaderWrapper>
			<div className="left">
				{/* 앞,뒤로가기 버튼 */}
				{path.length >= 3 && <Navigate />}
				<h2 className="location">{findMenuItem?.label}</h2>
			</div>

			<div className="right">
				{/* <IconButton>
					<Icon id={IconID.ALARM} />
				</IconButton> */}
				<Profile />
			</div>
		</HeaderWrapper>
	);
};

const HeaderWrapper = styled.header`
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2rem;
	top: 0;
	width: 100%;
	height: ${HEADER_HEIGHT};
	background-color: ${({ theme }) => theme.colors.white};
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	z-index: 990;

	.left {
		display: flex;
		gap: 2rem;
		align-items: center;

		.location {
			${({ theme }) => theme.font.headline.headline_4};
			font-weight: 700;
		}
	}

	.right {
		display: flex;
		align-items: center;
		gap: 2.8rem;
	}
	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		left: ${NAV_SMALL_WIDTH};
	}
`;

export default Header;
