import {
	type SalesItemDetail,
	type GetSalesRequest,
	type GetSalesResponse,
	type PostSalesItem,
	type PutSalesItem,
	type CombineSalesItemPK,
	type ExistsResponse,
	type PatchSalesStatus,
} from './types';
import { Delete, Get, Patch, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';

const BASE_URL = (storeId: string) => `/pharms/${storeId}/sales` as const;

export const sales = {
	getSales: async <T = GetSalesResponse>({ storeId, ...rest }: GetSalesRequest): Promise<T> =>
		(await Get<T>(BASE_URL(storeId) + formatQueryString(rest))).data,
	postSale: async <T = SalesItemDetail>({ storeId, ...data }: PostSalesItem) =>
		(await Post<T>(BASE_URL(storeId), data)).data,
	getSale: async <T = SalesItemDetail>({ storeId, id }: CombineSalesItemPK) =>
		(await Get<T>(BASE_URL(storeId) + `/${id}`)).data,
	putSale: async <T = SalesItemDetail>({ storeId, id, ...data }: PutSalesItem) =>
		(await Put<T>(BASE_URL(storeId) + `/${id}`, data)).data,
	patchSaleStatus: async <T = unknown>({ storeId, id, ...data }: PatchSalesStatus) =>
		(await Patch<T>(BASE_URL(storeId) + `/${id}`, data)).data,
	deleteSale: async <T = unknown>({ storeId, id }: CombineSalesItemPK) =>
		(await Delete<T>(BASE_URL(storeId) + `/${id}`)).data,
	getExists: async <T = ExistsResponse>({ storeId, id }: CombineSalesItemPK) =>
		(await Get<T>(BASE_URL(storeId) + `/${id}/chat/exists`)).data,
	postCopy: async <T = SalesItemDetail>({ storeId, id }: CombineSalesItemPK) =>
		(await Post<T>(BASE_URL(storeId) + `/${id}/copy`)).data,
};
