import React from 'react';

import { type UserMessage } from '@sendbird/chat/message';
import styled from 'styled-components';

import { MessageHeader } from './MessageHeader';
import Products from './Products';
import { buttonStyle, Container, Divider, Wrapper } from './styles';
import { Button } from '../../../../../components/Buttons';
import { Flex } from '../../../../../components/Common';
import { IconID, Label, Icon } from '../../../../../components/Display';
import { promotionMutationKeys, usePutPharmSales, usePutPromotion } from '../../../../../services/chat/queries';
import { MEDICATION_VALUES } from '../../../../../utils/consts';
import { commaizeNumber } from '../../../../../utils/format';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getMessageData } from '../../../getMessageData';
import { type PromotionMessageData, type PharmSalesMessageData, type Product } from '../../../types';
import { AutoMessage } from '../AutoMessage';

import type { PrescriptionRequest } from '../../../../../services/chat/types';

export const LABELS = { ...MEDICATION_VALUES, PROMOTION: '이벤트 제품' } as const;
interface PromotionMessagesProps {
	message: UserMessage;
}

// pharmSales Message인지 Promotion Message인지 확인
export function isPharmSalesMessageData(
	data: PharmSalesMessageData | PromotionMessageData,
): data is PharmSalesMessageData {
	return (data as PharmSalesMessageData).pharmSale !== undefined;
}

export function hasQuantityAndActualPrice(product: Product): boolean {
	return !!product.actualPrice && !!product.quantity;
}

export const PromotionMessage = ({ message }: PromotionMessagesProps) => {
	const { mutateMessage, disabledReason } = useChannelContext();
	const { mutate: pharmSalesMutate } = usePutPharmSales();
	const { mutate: promotionMutate } = usePutPromotion();
	const messageData = getMessageData<PharmSalesMessageData | PromotionMessageData>(message.data);

	if (messageData === null) {
		return <></>;
	}

	const { type } = messageData;

	if (type === 'RESPONSE' || type === 'REJECT') {
		return <AutoMessage message={message} customType={`GP_PROMOTION_${type}`} />;
	}

	const { groupId, disabled } = messageData;
	const { products, totalAmount } = isPharmSalesMessageData(messageData)
		? messageData.pharmSale
		: messageData.promotion;

	const handleReply = async (status: 'ACCEPT' | 'REJECT') => {
		await mutateMessage<PrescriptionRequest>(
			isPharmSalesMessageData(messageData) ? pharmSalesMutate : promotionMutate,
			{ groupId, status },
		);
	};

	const buttonConfigs: Record<'READY' | 'DONE', React.ReactElement> = {
		READY: (
			<>
				<Button
					onClick={async () => {
						await handleReply('REJECT');
					}}
					color={'TERTIARY'}
					shouldPrevent={true}
					mutationKey={promotionMutationKeys}
					disabled={disabledReason !== 'none'}
					{...buttonStyle}
				>
					{'거절'}
				</Button>
				<Button
					onClick={async () => {
						await handleReply('ACCEPT');
					}}
					shouldPrevent={true}
					mutationKey={promotionMutationKeys}
					color={'SECONDARY'}
					disabled={disabledReason !== 'none'}
					{...buttonStyle}
				>
					{'승인'}
				</Button>
			</>
		),
		DONE: (
			<>
				<Button color={'TERTIARY'} disabled={true} {...buttonStyle}>
					{'완료'}
				</Button>
			</>
		),
	};

	return (
		<Container>
			<Wrapper>
				<MessageHeader
					iconType={IconID.MSG_PROMOTION}
					title={'구매 요청'}
					subText={
						isPharmSalesMessageData(messageData)
							? '제품 구매 요청이 도착했습니다.'
							: `${products[0].name}을 구매하고 싶어요.`
					}
				/>
				<Products products={products} />
			</Wrapper>
			<Divider />

			<Flex
				$gap={'.2rem'}
				$direction={'column'}
				$alignSelf={'stretch'}
				$alignItems={'start'}
				$padding="1.2rem 1.6rem 1.6rem"
			>
				<Label $fontStyle={'body_3'} $fontWeight="bold">
					{isPharmSalesMessageData(messageData) ? LABELS.PHARM_SALE : LABELS.PROMOTION}
				</Label>
				<Label $fontStyle={'title_1'} $fontWeight={'bold'} $alignSelf="end">
					{commaizeNumber(totalAmount)}원
				</Label>
			</Flex>
			<Wrapper style={{ padding: '0 1.6rem 1.6rem' }}>
				<Flex $gap={'0.8rem'} $alignSelf={'stretch'}>
					{disabled ? buttonConfigs.DONE : buttonConfigs.READY}
				</Flex>
			</Wrapper>
		</Container>
	);
};

const DefaultImageWrapper = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
`;

export const DefaultImage = (
	<DefaultImageWrapper>
		<Icon id={IconID.PHOTO_CANCEL} width="2.4rem" height="2.4rem" defaultColor="gray_600" />
	</DefaultImageWrapper>
);
