import React, { useMemo, useRef, useState } from 'react';

import styled, { css, type DefaultTheme } from 'styled-components';

import { RestViewer } from './RestViewer';
import { tagsStyle } from './style';
import { type TagsColorType, type TagsCornerType, type TagsSizeType, type TagsType } from './types';
import { ellipsisStyle } from '../../../assets/styles/ellipsis';
import { changeColorKeyToType } from '../../../utils/changeColorKeyToType';
import { changeFontKeyToType, type FontKeys } from '../../../utils/changeFontKeyToType';

interface TagProps {
	children?: React.ReactNode;
	isHash?: boolean;
	fontSize?: FontKeys;
	fontWeight?: keyof DefaultTheme['font']['weight'];
	size?: TagsSizeType;
	type?: TagsType;
	corner?: TagsCornerType;
	color?: TagsColorType;
	style?: React.CSSProperties;
}

interface ContainerStyle {
	$wrap?: 'wrap' | 'nowrap';
}
interface TagsProps extends TagProps, ContainerStyle {
	data: string[] | number[];
	count?: number;
	maxLength?: number;
}
interface TagStyleProps {
	$type: TagsType;
	$corner: TagsCornerType;
	$size: TagsSizeType;
	$color: TagsColorType;
	$fontSize?: FontKeys;
	$fontWeight?: keyof DefaultTheme['font']['weight'];
	style?: React.CSSProperties;
}

export const Tag = (props: TagProps) => {
	const {
		children,
		fontSize,
		fontWeight = 'regular',
		size = 'M',
		type = 'LINE',
		corner = 'SQUARE',
		color = 'GRAY',
		style,
	} = props;

	const tagStyleProps: TagStyleProps = {
		$type: type,
		$color: color,
		$corner: corner,
		$size: size,
		$fontSize: fontSize,
		$fontWeight: fontWeight,
		style,
	};

	return (
		<TagWrap {...{ ...tagStyleProps }} key={'tag-'}>
			{children}
		</TagWrap>
	);
};

export const Tags = (props: TagsProps) => {
	const {
		data,
		count,
		maxLength,
		isHash = false,
		fontSize,
		fontWeight = 'regular',
		size = 'M',
		type = 'LINE',
		corner = 'SQUARE',
		color = 'GRAY',
		$wrap = 'wrap',
		style,
	} = props;

	const tagStyleProps: TagStyleProps = {
		$type: type,
		$color: color,
		$corner: corner,
		$size: size,
		$fontSize: fontSize,
		$fontWeight: fontWeight,
		style,
	};

	const restContainerRef = useRef<HTMLLIElement>(null);
	const [isHover, setIsHover] = useState(false);
	const sliceData = useMemo(() => {
		if (maxLength) {
			return data.slice(0, maxLength);
		} else {
			return data;
		}
	}, [data, maxLength]);

	const rest = useMemo(() => {
		if (!count) return null;
		const restData = data.slice(maxLength);
		return { count: restData.length, data: restData };
	}, [count, sliceData]);

	return (
		<Container {...{ $wrap }}>
			{sliceData.map((el, idx) => {
				return (
					<TagWrap {...tagStyleProps} key={'tag-' + idx}>
						{isHash && '#'}
						<span>{el}</span>
					</TagWrap>
				);
			})}
			{!!rest && rest.count > 0 && (
				<React.Fragment>
					<TagWrap
						{...tagStyleProps}
						className="rest"
						ref={restContainerRef}
						onMouseEnter={() => {
							setIsHover(true);
						}}
						onMouseLeave={() => {
							setIsHover(false);
						}}
					>
						<span>+{rest.count}</span>
					</TagWrap>
					<RestViewer data={rest.data} referenceRef={restContainerRef} isHover={isHover} />
				</React.Fragment>
			)}
		</Container>
	);
};

const Container = styled.ul<ContainerStyle>`
	display: flex;
	flex-direction: row;
	gap: 0.6rem;
	flex-wrap: ${({ $wrap }) => $wrap};
	max-width: 100%;
`;

const TagWrap = styled.li<TagStyleProps>`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	max-width: 100%;
	min-width: 3.3rem;
	span {
		${ellipsisStyle(1)}
	}

	${({ $type, $corner, $size, $color }) => {
		const tagsColorStyle = tagsStyle[$type][$color];
		const tagsSizeStyle = tagsStyle.size[$size];
		return css`
			${changeFontKeyToType(tagsSizeStyle.fontKey)};
			${({ theme }) => theme.font.weight[tagsSizeStyle.fontWeight]};
			height: ${tagsSizeStyle.height};
			padding: ${tagsSizeStyle.padding};
			color: ${changeColorKeyToType(tagsColorStyle?.color)};
			border-radius: ${$corner !== 'ROUND' ? tagsSizeStyle.borderRadius : '99px'};
			background-color: ${changeColorKeyToType(tagsColorStyle?.backgroundColor)};
			border-width: ${tagsColorStyle?.border?.width};
			border-style: ${tagsColorStyle?.border?.style};
			border-color: ${changeColorKeyToType(tagsColorStyle?.border?.color)};
		`;
	}}
	&.rest {
		cursor: pointer;
	}
`;
