import type React from 'react';

import { type Placement, autoPlacement, autoUpdate, offset, useFloating } from '@floating-ui/react';
export interface UseCustomFloating {
	referenceRef?: React.RefObject<HTMLElement>;
	floatingRef: React.RefObject<HTMLElement>;
	isOpen?: boolean;
	options?: { offset?: number; allowedPlacements?: Placement[] };
}
export const useCustomFloating = ({ referenceRef, floatingRef, isOpen, options }: UseCustomFloating) => {
	const floating = useFloating({
		open: isOpen && !!referenceRef,
		whileElementsMounted: autoUpdate,
		placement: 'bottom-start',
		elements: {
			reference: referenceRef?.current,
			floating: floatingRef.current,
		},
		middleware: [
			offset(options?.offset ?? 4),
			autoPlacement({
				allowedPlacements: options?.allowedPlacements ?? ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
			}),
		],
	});
	return floating;
};
