import React from 'react';

import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { Tag } from '../../../components/Display';
import { type TagsColorType } from '../../../components/Display/Tags/types';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type SalesItem, type SalesStatus } from '../../../services/sales/types';

export const SALES_STATUS_COLOR: Record<SalesStatus, TagsColorType> = {
	READY: 'YELLOW',
	RUNNING: 'GREEN',
	COMPLETED: 'GRAY',
} as const;

export const SALES_STATUS_VALUES: Record<SalesStatus, string> = {
	READY: '준비중',
	RUNNING: '진행중',
	COMPLETED: '종료',
};

export const SALES_LIST_COLUMNS: Columns<SalesItem> = [
	{
		key: 'title',
		headerName: '이벤트 타이틀',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
	},
	{
		key: 'status',
		headerName: '진행상태',
		width: {
			max: '20rem',
		},
		renderComponent: (value) => (
			<Tag type="FILL" color={SALES_STATUS_COLOR[value]}>
				{SALES_STATUS_VALUES[value]}
			</Tag>
		),
	},
] as const;

export const INITIAL_DATA: Record<SalesStatus, SalesItem[]> = {
	READY: [],
	COMPLETED: [],
	RUNNING: [],
} as const;
