import { type DescriptionProps } from '../../../../components/Display';
import { type PaymentDetail } from '../../../../services/payment/types';
import { type Gender } from '../../../../services/types';
import {
	GENDER_VALUES,
	MEDICATION_VALUES,
	PAY_TYPE_VALUES,
	type PayType,
	type MedicationType,
} from '../../../../utils/consts';
import { formatPhoneNumber } from '../../../../utils/format';

export const informationRows: DescriptionProps<PaymentDetail>['rows'] = [
	{ key: 'name', label: '회원명', renderContent: (_, value) => (value as string) ?? '-' },
	{ key: 'phone', label: '전화번호', renderContent: (_, value) => (value ? formatPhoneNumber(String(value)) : '-') },
	{ key: 'birth', label: '생년월일', renderContent: (_, value) => (value as string) ?? '-' },
	{ key: 'gender', label: '성별', renderContent: (_, value) => (value ? GENDER_VALUES[value as Gender] : '-') },
];

export const paymentsRows: DescriptionProps<PaymentDetail>['rows'] = [
	{ key: 'approvalAt', label: '승인 일시' },
	{ key: 'pickupAt', label: '수령 일시', renderContent: (_, value) => (value as string) ?? '-' },
	{
		key: 'medicationType',
		label: '거래 유형',
		renderContent: (_, value) => (value ? MEDICATION_VALUES[value as MedicationType] : '-'),
	},
	{ key: 'id', label: '결제요청번호' },
	{
		key: 'coupon',
		label: '쿠폰 금액',
		renderContent: (_, value) => Number(value).toLocaleString() + '원',
	},
	{ key: 'point', label: '포인트 금액', renderContent: (_, value) => Number(value).toLocaleString() + '원' },
	{
		key: 'discountAmount',
		label: '할인 금액',
		renderContent: (_, __, item) => (Number(item.coupon) + Number(item.point)).toLocaleString() + '원',
	},
	{ key: 'actualAmount', label: '실결제 금액', renderContent: (_, value) => Number(value).toLocaleString() + '원' },
	{ key: 'totalAmount', label: '총 결제 금액', renderContent: (_, value) => Number(value).toLocaleString() + '원' },
	{ key: 'payType', label: '결제 유형', renderContent: (_, value) => PAY_TYPE_VALUES[value as PayType] },
	{ key: 'isApproval', label: '결제 상태', renderContent: (_, value) => (value ? '승인 완료' : '승인 취소') },
	{ key: 'cancelAt', label: '취소 일시', renderContent: (_, value) => (value as string) ?? '-' },
];
