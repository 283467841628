import React, { useMemo, type InputHTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconID } from '../../Display';

type CheckboxType = 'ROUNDED' | 'SQUARE';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	type?: CheckboxType;
	$size?: {
		width: string;
		height?: string;
	};
}

const DEFAULT_SIZE = '2rem';

export const Checkbox = ({ label, id, checked, type = 'SQUARE', $size, ...rest }: CheckboxProps) => {
	const iconKey = useMemo(() => {
		switch (type) {
			case 'ROUNDED':
				return 'CHECKBOX_ROUNDED';
			case 'SQUARE':
				return checked ? 'CHECKBOX_SQUARE_ON' : 'CHECKBOX_SQUARE_OFF';
		}
	}, [type, checked]);

	return (
		<CheckboxWrap htmlFor={id} $disabled={rest.disabled}>
			<Icon
				id={IconID[iconKey]}
				defaultColor={type === 'ROUNDED' ? 'gray_400' : 'gray_500'}
				activeColor="primary_600"
				isDisabled={rest.disabled}
				isActive={checked}
				width={$size?.width ?? DEFAULT_SIZE}
				height={$size?.height ?? $size?.width ?? DEFAULT_SIZE}
			/>
			<input type="checkbox" id={id} {...rest} />
			{label && <span>{label}</span>}
		</CheckboxWrap>
	);
};

const CheckboxWrap = styled.label<{ $disabled?: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.4rem;
	${({ $disabled }) =>
		$disabled &&
		css`
			pointer-events: none;
		`}
	input {
		display: none;
	}
	span {
		cursor: pointer;
		${({ theme }) => theme.font.body.body_2};
	}
`;
