import React, { type ReactNode, useRef, useEffect } from 'react';

import { type UseFloatingReturn } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { type UseCustomFloating, useCustomFloating } from '../../../lib/floatingUi/useCustomFloating';

interface TooltipProps {
	floatingOptions?: UseCustomFloating['options'];
	trigger: React.ReactElement;
	enabled?: boolean;
	children: ReactNode;
}

export const Tooltip = ({ floatingOptions, trigger, children, enabled = true }: TooltipProps) => {
	const referenceRef = useRef<HTMLDivElement>(null);
	const floatingRef = useRef<HTMLDivElement>(null);

	const floating = useCustomFloating({
		floatingRef,
		referenceRef,
		options: floatingOptions,
	});

	useEffect(() => {
		// Modal 안에서 렌더링 되는 경우 Ref가 지정되지 않는 문제가 있어 isPositioned가 false 일 때(해당경우) useEffect 안에서 ref를 재 지정해줌
		if (!floating.isPositioned) {
			if (floatingRef.current) floating.refs.setFloating(floatingRef.current);
			if (referenceRef.current) floating.refs.setReference(referenceRef.current);
		}
	}, []);

	return (
		<Container $enabled={enabled}>
			<TooltipButton className="tooltip__button" ref={referenceRef}>
				{trigger}
			</TooltipButton>
			<TooltipItem ref={floatingRef} $floating={floating} className="tooltip__item">
				{children}
			</TooltipItem>
		</Container>
	);
};

const TooltipItem = styled.div<{ $floating: UseFloatingReturn }>`
	${({ $floating }) =>
		$floating.isPositioned &&
		css`
			z-index: 10;
			box-shadow: ${({ theme }) => theme.shadow.tooltip};
			position: ${$floating.strategy};
			top: 0;
			left: 0;
			transform: translate(${`${$floating?.x}px, ${$floating?.y}px`});

			&::after {
				content: '';
				position: absolute;
				display: inline-block;
				width: 0;
				height: 0;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 10px solid ${({ theme }) => theme.colors.gray.gray_900};
				border-radius: 1px;
				${() => {
					switch ($floating.placement) {
						case 'top':
							return css`
								bottom: -6px;
							`;
						case 'right':
							return css`
								left: -9px;
								transform: rotate(90deg);
							`;
						case 'bottom':
							return css`
								top: -6px;
								transform: rotate(180deg);
							`;
						case 'left':
							return css`
								right: -9px;
								transform: rotate(-90deg);
							`;
						case 'top-start':
							return css`
								left: 8px;
								bottom: -6px;
							`;
						case 'top-end':
							return css`
								right: 8px;
								bottom: -6px;
							`;
						case 'bottom-start':
							return css`
								left: 8px;
								top: -6px;
								transform: rotate(180deg);
							`;
						case 'bottom-end':
							return css`
								right: 8px;
								top: -6px;
								transform: rotate(180deg);
							`;
					}
				}};
			}
		`}

	display: flex;
	padding: 0.8rem;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.gray.gray_900};
	align-items: center;
	justify-content: center;
`;

const Container = styled.div<{ $enabled: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;

	.tooltip__item {
		visibility: hidden;
		opacity: 0;
	}

	${({ $enabled }) =>
		$enabled &&
		css`
			.tooltip__button:not(:hover) + .tooltip__item {
				display: none;
			}
			.tooltip__button:hover + .tooltip__item {
				visibility: visible;
				opacity: 1;
			}
		`}
`;

const TooltipButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
