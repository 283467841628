import React, { type FormEvent, useCallback, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PROMOTION_CALCULATES_COLUMNS, PROMOTION_PAYMENT_COLUMNS } from './const';
import { Button } from '../../../components/Buttons';
import { InsideTitle, VStack } from '../../../components/Common';
import { Icon, IconID } from '../../../components/Display';
import { FilterList } from '../../../components/Filter/components/FilterList';
import { Select, TextField } from '../../../components/Forms';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { CustomDatePicker } from '../../../lib/reactDatepicker/CustomDatePicker';
import { CustomMonthPickerInput } from '../../../lib/reactDatepicker/CustomMonthPickerInput';
import { PAYMENTS_SEARCH_OPTIONS, useGetPayments, useGetPaymentsExcel } from '../../../services/payment/queries';
import { type GetPaymentSearchType } from '../../../services/payment/types';
import { useGetPromotionCalculates } from '../../../services/promotion/queries';
import { type PromotionCalculate } from '../../../services/promotion/types';
import { type Search } from '../../../services/types';
import { formatMonth, formatSearch } from '../../../utils/format';
import { handleChangeSorts } from '../../../utils/onChangeSorts';
import { onDownloadBlob } from '../../../utils/onDownloadBlob';

const INITIAL_TMP_SEARCH_VALUES: Search<GetPaymentSearchType> = {
	searchCategory: 'NAME',
	searchKeyword: '',
} as const;

export const Breakdown = () => {
	const { userInfo } = context.user.useValue();
	const [selectedItem, setSelectedItem] = useState<PromotionCalculate | null>(null);

	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(INITIAL_TMP_SEARCH_VALUES);

	const { data, isLoading, queries } = useGetPromotionCalculates({ storeId: userInfo.storeId });

	const { start, end } = useMemo(() => formatMonth(new Date()), []);

	const {
		data: paymentData,
		queries: paymentQueries,
		pagination,
	} = useGetPayments(userInfo.storeId, {
		enabled: !!selectedItem,
		setInitialValues: {
			period: {
				start,
				end,
			},
			medicationType: 'PROMOTION',
			isApproval: 'true',
		},
	});

	const { mutate, isPending } = useGetPaymentsExcel({
		onSuccess: (blob) => {
			onDownloadBlob(blob, '이벤트_참여내역.xlsx', 'application/octet-stream');
		},
		onError: (err) => {
			toast.error('엑셀 다운로드에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onExcelDownload = useCallback(() => {
		mutate(paymentQueries.values);
	}, [paymentQueries.values]);

	const handleSelected = useCallback(
		(item: PromotionCalculate) => {
			setSelectedItem((prev) => (!prev ? item : null));
			paymentQueries.dispatch('SET', { promotionId: item.id });
			if (item.id === selectedItem?.id) {
				pagination.setPagination({ totalCount: 0 });
			}
		},
		[selectedItem, queries.values],
	);

	const onSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			const trimValue = formatSearch(tmpSearch.searchKeyword ?? '', tmpSearch.searchCategory);
			paymentQueries.dispatch('SET', { search: { ...tmpSearch, searchKeyword: trimValue } });
		},
		[tmpSearch],
	);

	return (
		<VStack $gap={'4rem'}>
			<VStack>
				<InsideTitle
					title="이벤트 내역"
					rightRender={
						<CustomDatePicker
							selected={queries.values.period.start}
							dateFormat={'yyyy년 MM월'}
							customInput={
								<CustomMonthPickerInput
									date={queries.values.period.start ?? new Date()}
									increase={(period) => {
										queries.dispatch('SET', { period });
										paymentQueries.dispatch('SET', { period });
									}}
									decrease={(period) => {
										queries.dispatch('SET', { period });
										paymentQueries.dispatch('SET', { period });
									}}
								/>
							}
							onChange={(date) => {
								if (date) {
									const newMonth = formatMonth(date);
									queries.dispatch('SET', { period: newMonth });
									// paymentQueries.dispatch('SET', { period: newMonth });
								}
							}}
							maxDate={new Date()}
							showMonthYearPicker
							showFourColumnMonthYearPicker
							popperPlacement={'top-end'}
						/>
					}
				/>
				<ListTable
					data={data?.content}
					columns={PROMOTION_CALCULATES_COLUMNS}
					isScroll={false}
					isLoading={isLoading}
					onRowClick={(item) => {
						handleSelected(item);
					}}
					selected={{
						selectedItem,
						selectKey: 'id',
					}}
					hasLastItemBorder={false}
				/>
			</VStack>
			<ListTable
				data={selectedItem ? paymentData?.content : []}
				columns={PROMOTION_PAYMENT_COLUMNS}
				isLoading={isLoading}
				pagination={pagination}
				unit="건"
				onCategoryClick={(column) => {
					handleChangeSorts(paymentQueries.values.sorts, column, (sorts) => {
						paymentQueries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={paymentQueries.values.sorts}
				headerLeftContent={
					<TableLeft onSubmit={onSubmit}>
						<Select
							options={PAYMENTS_SEARCH_OPTIONS}
							value={tmpSearch.searchCategory}
							labelStyle={{ width: '10.4rem' }}
							onClick={(key) => {
								tmpSearchDispatch('SET', {
									searchCategory: key as GetPaymentSearchType,
									searchKeyword: '',
								});
							}}
						/>
						<TextField
							renderPrefix={<Icon id="btn-search" width="2rem" height="2rem" />}
							inputSize={'sm'}
							style={{ borderRadius: '4px', width: '32rem' }}
							placeholder="검색어를 입력해주세요."
							value={tmpSearch.searchKeyword}
							name="searchKeyword"
							onChange={onChangeTmpSearch}
						/>
					</TableLeft>
				}
				headerRightContent={
					<Button
						disabled={isPending || !selectedItem || (paymentData && paymentData.content.length <= 0)}
						onClick={() => {
							onExcelDownload();
						}}
						shouldPrevent={true}
						size={{ $paddingSize: 'M' }}
					>
						<Icon id={IconID.FILE} defaultColor="white" width="2rem" height="2rem" /> 엑셀 다운로드
					</Button>
				}
				headerBottomContent={<FilterList items={[]} resetCurPage={pagination.resetCurPage} queries={paymentQueries} />}
			/>
		</VStack>
	);
};
const TableLeft = styled.form`
	display: flex;
	gap: 0.8rem;
	align-items: center;
`;
