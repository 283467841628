import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { type Sender } from '@sendbird/chat/message';
import { useReactToPrint } from 'react-to-print';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { Slider } from './Slider';
import { drawImgToCanvas, drawQr } from './utils';
import { ViewerHeader } from './ViewerHeader';
import { ViewerTransform } from './ViewerTransform';
import context from '../../../context';
import { isQrData } from '../../../feature/Channel/getMessageData';
import { type QRData } from '../../../feature/Channel/types';
import { useKeyDown } from '../../../hooks/useKeyDown';
import { onDownloadBlob } from '../../../utils/onDownloadBlob';
import { ContextMenu, MenuItem, MenuItems } from '../ContextMenu';
import { Modal } from '../Modal';

export const CANVAS_ID = 'viewer-canvas';

interface ImageData {
	fileName: string;
	url: string;
	expiredAt: string;
}

interface ViewerModalProps {
	imageList: Array<ImageData | QRData>;
	sender?: Sender;
	triggerIndex?: number;
	showExpiredAt?: boolean;
}

/**
 *
 * @param sender
 * @param imageList
 * @param triggerIndex
 * @param showExpiredAt
 * @constructor
 */
export const ViewerModal = ({ sender, imageList, triggerIndex = 0, showExpiredAt = false }: ViewerModalProps) => {
	const [currentIndex, setCurrentIndex] = useState(triggerIndex);
	const { handleClose } = context.modal.useDispatch();
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [canvas, setCanvas] = useState<null | HTMLCanvasElement>(null);
	const setCanvasRef = useCallback((node: HTMLCanvasElement | null) => {
		if (node !== null) {
			setCanvas(node);
		}
	}, []);

	const ref = useRef<HTMLDivElement>(null);
	const resetZoomRef = useRef<HTMLButtonElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const currentImage = useMemo(() => imageList[currentIndex], [currentIndex, imageList]);
	const onKeyDown = useKeyDown(ref, {
		Escape: () => {
			handleClose();
		},
		ArrowLeft: () => {
			handleLeftClick();
		},
		ArrowRight: () => {
			handleRightClick();
		},
	});

	const { isQr, fileName } = useMemo(
		() =>
			isQrData(currentImage)
				? { isQr: true, fileName: currentImage.manufactur }
				: { isQr: false, fileName: currentImage.fileName },
		[currentImage],
	);

	useEffect(() => {
		renderImage(currentImage);
	}, [currentImage, canvas]);

	const renderImage = useCallback(
		(image: ImageData | QRData) => {
			if (!canvas) return;
			if (isQrData(image)) {
				drawQr({ canvas, bytesStr: image.data });
			} else {
				drawImgToCanvas({ url: image.url, canvas, resize: true, maxHeight: window.innerHeight - 72 });
			}
		},
		[canvas],
	);

	const handlePrint = useReactToPrint({
		content: () => contentRef.current,
	});

	useEffect(() => {
		const handleContextmenu = (event: MouseEvent) => {
			event.preventDefault();
			setMenuOpen(true);
		};
		if (contentRef.current) {
			contentRef.current.addEventListener('contextmenu', handleContextmenu);
		}

		return () => {
			if (contentRef.current) {
				contentRef.current.removeEventListener('contextmenu', handleContextmenu);
			}
		};
	}, []);

	const handleCopyClick = (onCopied: () => void) => {
		try {
			const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
			if (typeof ClipboardItem && navigator.clipboard.write) {
				canvas.toBlob((blob) => {
					const item = new ClipboardItem({ 'image/png': blob! });
					navigator.clipboard.write([item]);
				});
			} else {
				// ClipboardItem and write api not support firefox
				const img = document.createElement('img');
				img.src = canvas.toDataURL();
				const div = document.createElement('div');
				div.contentEditable = 'true';
				div.appendChild(img);
				document.body.appendChild(div);
				const range = document.createRange();
				range.selectNode(img);
				window.getSelection()?.removeAllRanges();
				window.getSelection()?.addRange(range);
				document.execCommand('copy');
				document.body.removeChild(div);
			}
			onCopied();
		} catch (e) {}
	};

	const handleDownloadClick = (imageList: Array<ImageData | QRData>, isJPG?: boolean) => {
		imageList.forEach(async (item) => {
			const canvas = document.createElement('canvas');
			let fileName = '';

			if (isQrData(item)) {
				fileName = `${item.manufactur}_qr_code.png`;
				await drawQr({ canvas, bytesStr: item.data });
			} else {
				fileName = item.fileName;
				await drawImgToCanvas({ canvas, url: item.url });
			}

			canvas.toBlob((blob) => {
				onDownloadBlob(blob, isJPG ? fileName.replace('.png', '.jpg') : fileName);
			});
		});
	};

	const handleLeftClick = () => {
		setCurrentIndex((prev) => (prev === 0 ? imageList.length - 1 : prev - 1));
		resetZoomRef.current && resetZoomRef.current.click();
	};

	const handleRightClick = () => {
		setCurrentIndex((prev) => (prev === imageList.length - 1 ? 0 : prev + 1));
		resetZoomRef.current && resetZoomRef.current.click();
	};

	return (
		<Modal isScroll={false}>
			<Container className={'image-viewer'} ref={ref} tabIndex={1} onKeyDown={onKeyDown}>
				<ViewerHeader
					sender={sender}
					isQr={isQr}
					fileName={fileName}
					close={handleClose}
					onPrintClick={() => {
						handlePrint();
					}}
					onCopyClick={handleCopyClick}
					onDownloadClick={() => {
						handleDownloadClick(imageList);
					}}
				/>
				<Wrapper>
					<TransformWrapper
						initialScale={1}
						minScale={0.5}
						maxScale={10}
						wheel={{ disabled: isQr }}
						doubleClick={{
							disabled: true,
						}}
					>
						<ViewerTransform
							isQr={isQr}
							setCanvasRef={setCanvasRef}
							resetZoomRef={resetZoomRef}
							contentRef={contentRef}
							count={{ total: imageList.length, current: currentIndex + 1 }}
							expiredAt={{ show: showExpiredAt, value: currentImage.expiredAt }}
						/>
					</TransformWrapper>
					{imageList.length > 1 && <Slider onLeftClick={handleLeftClick} onRightClick={handleRightClick} />}
				</Wrapper>
				{menuOpen && (
					<ContextMenu
						isOpen={menuOpen}
						menuItems={() => {
							return (
								<MenuItems
									parentRef={contentRef}
									closeDropdown={() => {
										setMenuOpen(false);
									}}
									isOnCursor={true}
								>
									<MenuItem
										onClick={() => {
											handleCopyClick(() => {});
											setMenuOpen(false);
										}}
									>
										{'이미지 복사'}
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleDownloadClick([currentImage]);
											setMenuOpen(false);
										}}
									>
										{'이미지 저장'}
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleDownloadClick([currentImage], true);
											setMenuOpen(false);
										}}
									>
										{'JPG로 저장'}
									</MenuItem>
								</MenuItems>
							);
						}}
					/>
				)}
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	&:focus {
		outline: none;
	}
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;

	.react-transform-wrapper {
		align-items: center;
		justify-content: center;
	}

	.react-transform-component {
		align-items: center;
		justify-content: center;
	}
`;
