import React, { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns/format';
import { toDate } from 'date-fns/toDate';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { CardList } from './CardList';
import { Comparison } from './Comparison';
import { Extras } from './Extras';
import { InsideTitle } from '../../../components/Common';
import { Label } from '../../../components/Display';
import context from '../../../context';
import { type PeriodUnit } from '../../../services/statistics/queries';
import { DateNavigation } from '../components/DateNavigation';
import { PrescriptionsCard, SalesCard } from '../components/DescriptionCard';
import { DrugUsages, SaleProducts } from '../components/StatsTable';
import { Container, Content, ItemsWrapper, Wrapper } from '../components/styles';
import { formatPeriod, getDateEndOfUnit, getDateStartOfUnit } from '../utils';

const validIds = ['daily', 'weekly', 'monthly'];
function Details() {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { userInfo } = context.user.useValue();
	const { storeId } = userInfo;

	const unit = useMemo(() => (validIds.includes(id ?? '') ? (id as PeriodUnit) : undefined), [id]);

	const [date, setDate] = useState<Date>(() => new Date());
	const { startDate, endDate } = useMemo(() => {
		const [start, end] = [getDateStartOfUnit(date, unit!), getDateEndOfUnit(date, unit!)];
		return formatPeriod([start, end]);
	}, [date, unit]);

	useEffect(() => {
		const dateStr = searchParams.get('date');
		if (dateStr) {
			setDate(toDate(dateStr));
		}
	}, [searchParams, unit]);

	const handleUpdateDate = (updated: Date) => {
		setSearchParams({ date: format(updated, unit === 'monthly' ? 'yyyy-MM' : 'yyyy-MM-dd') }, { replace: true });
	};

	if (unit === undefined) {
		return <Navigate to={'/stats/details'} />;
	}

	return (
		<Container>
			<Wrapper>
				<NavWrapper>
					<DateNavigation date={startDate} onChangeDate={handleUpdateDate} unit={unit} />
				</NavWrapper>
				<Content $gap={'2rem'}>
					<CardList storeId={storeId} unit={unit} date={date} />
					<ItemsWrapper $gap={'2rem'}>
						<PrescriptionsCard storeId={storeId} startDate={startDate} endDate={endDate} />
						<SalesCard storeId={storeId} startDate={startDate} endDate={endDate} />
					</ItemsWrapper>
				</Content>
				<Content>
					<ItemsWrapper $gap={'2rem'}>
						<Comparison storeId={storeId} unit={unit} date={date} target={unit === 'daily' ? 'lastWeek' : 'lastYear'} />
					</ItemsWrapper>
				</Content>
				<Content $gap={'2rem'}>
					<InsideTitle title={'상품 순위'} />
					<ItemsWrapper $gap={'2rem'}>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ width: '50%' }}>
							<Label $color={'gray_900'} $fontWeight={'bold'} $fontStyle={'title_3'}>
								{'BEST 판매량 상품'}
							</Label>
							<SaleProducts storeId={storeId} startDate={startDate} endDate={endDate} />
						</ItemsWrapper>
						<ItemsWrapper $gap={'1.2rem'} $direction={'column'} style={{ width: '50%' }}>
							<Label $color={'gray_900'} $fontWeight={'bold'} $fontStyle={'title_3'}>
								{'최다 사용량 약품'}
							</Label>
							<DrugUsages storeId={storeId} startDate={startDate} endDate={endDate} />
						</ItemsWrapper>
					</ItemsWrapper>
				</Content>
				{unit !== 'daily' && (
					<Extras
						storeId={storeId}
						startDate={startDate}
						endDate={endDate}
						unit={unit === 'monthly' ? unit : 'weekday'}
					/>
				)}
			</Wrapper>
		</Container>
	);
}

const NavWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 6.4rem;
	align-items: center;
	align-self: stretch;
	justify-content: center;
`;

export default Details;
