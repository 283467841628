import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { HStack, VStack } from '../../../../components/Common';
import { Icon, IconID } from '../../../../components/Display';
import { Select, TextField } from '../../../../components/Forms';
import { Modal, type ModalProps } from '../../../../components/Modals';
import { ListTable } from '../../../../components/Table';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { useGetProducts } from '../../../../services/products/queries';
import { type ProductItem } from '../../../../services/products/types';
import { type SalesProduct } from '../../../../services/sales/types';
import {
	INITIAL_PRODUCT_TMP_SEARCH,
	PRODUCT_COLUMNS,
	PRODUCT_SEARCH_OPTIONS,
	type ProductSearchType,
} from '../../../Product/const';

const FORM_KEY = 'product__add__form';

interface ProductAddModalProps extends ModalProps {
	onSelect: (product: SalesProduct) => void;
}

export const ProductAddModal = ({ onSelect }: ProductAddModalProps) => {
	const { userInfo } = context.user.useValue();
	const [selectedItem, setSelectedItem] = useState<ProductItem | null>(null);
	const { handleClose } = context.modal.useDispatch();
	const { data, pagination, queries, isLoading } = useGetProducts(userInfo.storeId);
	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(INITIAL_PRODUCT_TMP_SEARCH);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			try {
				if (selectedItem) onSelect({ ...selectedItem, productId: selectedItem.id, discountPrice: 0, isUsable: true });
			} finally {
				handleClose();
			}
		},
		[selectedItem],
	);

	const onSearch = useCallback(() => {
		queries.dispatch('SET', { [tmpSearch.searchCategory]: tmpSearch.searchKeyword });
	}, [tmpSearch]);

	return (
		<Modal
			header={{ title: '상품 등록' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						type: 'submit',
						children: '등록',
						style: { width: '10rem' },
						shouldPrevent: true,
						form: FORM_KEY,
						disabled: !selectedItem,
					},
				],
			}}
		>
			<form {...{ id: FORM_KEY, onSubmit }}>
				<Wrapper>
					<ListTable
						data={data?.content}
						columns={PRODUCT_COLUMNS('4rem')}
						{...{ isLoading, pagination }}
						unit="개"
						selected={{
							selectedItem,
							selectKey: 'id',
						}}
						onRowClick={(item) => {
							setSelectedItem(item === selectedItem ? null : item);
						}}
						headerLeftContent={
							<HStack $gap="0.8rem">
								<Select
									options={PRODUCT_SEARCH_OPTIONS}
									value={tmpSearch.searchCategory}
									labelStyle={{ width: '10.4rem' }}
									onClick={(key) => {
										tmpSearchDispatch('SET', {
											searchCategory: key as ProductSearchType,
											searchKeyword: '',
										});
									}}
								/>
								<TextField
									renderPrefix={<Icon id={IconID.BTN_SEARCH} width="2rem" height="2rem" />}
									inputSize={'sm'}
									style={{ borderRadius: '4px', width: '32rem' }}
									placeholder="검색어를 입력해주세요."
									value={tmpSearch.searchKeyword}
									name="searchKeyword"
									onChange={onChangeTmpSearch}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											onSearch();
										}
									}}
								/>
							</HStack>
						}
						maxHeight="35.2rem"
						bodyItemStyle={{ minHeight: '4.7rem' }}
						tableHeaderItemStyle={{ minHeight: '4.7rem' }}
						footerStyle={{ padding: '1.2rem 2rem' }}
					/>
				</Wrapper>
			</form>
		</Modal>
	);
};
const Wrapper = styled(VStack)`
	padding: 0 2rem 3.2rem;
	width: 84rem;
`;
