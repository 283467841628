import Theme, { type ColorsType } from '../lib/styledComponents/Theme';

import type { DefaultTheme } from 'styled-components';

// Utility type to filter out keys pointing to nested objects and include keys pointing to non-object values
type FilterObjectKeys<T> = {
	[K in keyof T]: T[K] extends Record<string, any> ? never : K;
}[keyof T];

// Utility type to extract and include keys of nested objects
type IncludeNestedKeys<T> = {
	[K in keyof T]: T[K] extends Record<string, any> ? keyof T[K] : never;
}[keyof T];

// Combining both to get the desired type
type ColorKeys = FilterObjectKeys<ColorsType> | IncludeNestedKeys<ColorsType>;

function changeColorKeyToType(colorKey: ColorKeys | undefined): string {
	if (!colorKey) return '';
	const colorPrefix = colorKey.split('_')[0];
	switch (colorPrefix) {
		case 'gray':
			return Theme.colors.gray[colorKey as keyof DefaultTheme['colors']['gray']];
		case 'primary':
			return Theme.colors.primary[colorKey as keyof DefaultTheme['colors']['primary']];
		case 'red':
			return Theme.colors.red[colorKey as keyof DefaultTheme['colors']['red']];
		case 'green':
			return Theme.colors.green[colorKey as keyof DefaultTheme['colors']['green']];
		case 'blue':
			return Theme.colors.blue[colorKey as keyof DefaultTheme['colors']['blue']];
		case 'yellow':
			return Theme.colors.yellow[colorKey as keyof DefaultTheme['colors']['yellow']];
		default:
			return Theme.colors[colorKey as keyof DefaultTheme['colors']] as string;
	}
}

export type { ColorKeys };
export { changeColorKeyToType };
