import React from 'react';

import styled from 'styled-components';

import { Icon, IconID } from '../../../../../components/Display';
import { type StatisticsResponse } from '../../../../../services/payment/types';

const DataLabel: Record<keyof StatisticsResponse, string> = {
	storeId: '스토어 아이디',
	purchaseCount: '결제 건 수',
	settledAmount: '정산 완료 금액',
	unsettledAmount: '미정산 금액',
};

type DataType = 'COUPON' | 'POINT';

const DataTypeLabel: Record<DataType, string> = {
	COUPON: '쿠폰',
	POINT: '포인트',
} as const;

export interface CalculateItemProps {
	data?: StatisticsResponse;
	type: DataType;
}

export const CalculateItem = ({ data, type }: CalculateItemProps) => {
	const keys = data ? Object.keys(data).filter((key) => key !== 'storeId') : [];

	return (
		<Container>
			<div className="label">
				<div className="icon__wrapper">
					<Icon id={IconID[type]} defaultColor="primary_600" width="2.4rem" height="2.4rem" />
				</div>
				<span>{DataTypeLabel[type]}</span>
			</div>
			<ul className="contents">
				{keys.map((key, idx) => (
					<li key={type + key + idx}>
						<p className="contents--label">{DataLabel[key as keyof StatisticsResponse]}</p>
						<p className="contents--item">
							{data ? data[key as keyof StatisticsResponse].toLocaleString() : 0}
							{key === 'purchaseCount' ? '건' : '원'}
						</p>
					</li>
				))}
			</ul>
		</Container>
	);
};

const Container = styled.div`
	flex: 1;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 8px;
	padding: 2rem;
	gap: 1.6rem;
	display: flex;
	flex-direction: column;
	min-height: 16.7rem;
	.label {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
		padding-bottom: 1.6rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		span {
			${({ theme }) => theme.font.title.title_2};
			font-weight: 700;
		}
	}
	.icon__wrapper {
		padding: 0.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 999px;
		background-color: ${({ theme }) => theme.colors.primary.primary_200};
	}
	.contents {
		display: flex;
		li {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.4rem;
		}
		&--label {
			${({ theme }) => theme.font.body.body_2};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.gray.gray_700};
		}
		&--item {
			${({ theme }) => theme.font.title.title_1};
			font-weight: 700;
		}
	}
`;
