import {
	type DrugUsage,
	type InboundsTotal,
	type LatestUpdatedAt,
	type OutboundsTotal,
	type PrescriptionTotal,
	type SaleProduct,
	type SalesTotal,
	type StatsAges,
	type StatsDetailDaily,
	type StatsDetailMonthly,
	type StatsDetailWeekday,
	type StatsDetailWeekly,
	type StatsRequest,
} from './types';
import { formatQueryString } from '../../utils/format';
import { Get, Post } from '../index';

export const statistics = {
	getStatsDaily: async <T = StatsDetailDaily[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/daily${formatQueryString(rest)}`);
		return response.data;
	},
	getStatsWeekly: async <T = StatsDetailWeekly[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/weekly${formatQueryString(rest)}`);
		return response.data;
	},
	getStatsMonthly: async <T = StatsDetailMonthly[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/monthly${formatQueryString(rest)}`);
		return response.data;
	},
	getStatsWeekday: async <T = StatsDetailWeekday[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/weekday${formatQueryString(rest)}`);
		return response.data;
	},
	// 총계 조제
	getPrescriptionsTotal: async <T = PrescriptionTotal>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/prescriptions/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 총계 판매
	getSalesTotal: async <T = SalesTotal>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/sales/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 총계 반품
	getOutboundsTotal: async <T = OutboundsTotal>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/outbounds/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 총계 사입
	getInboundsTotal: async <T = InboundsTotal>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/inbounds/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 판매 상품
	getSaleProductsTotal: async <T = SaleProduct[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/sale-products/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 약품 사용
	getDrugUsagesTotal: async <T = DrugUsage[]>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/drug-usages/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 고객수 총합
	getAgesTotal: async <T = StatsAges>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/ages/total${formatQueryString(rest)}`);
		return response.data;
	},
	// 고객수 평균
	getAgesAvg: async <T = StatsAges>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/ages/avg${formatQueryString(rest)}`);
		return response.data;
	},
	getStatsLatest: async <T = LatestUpdatedAt>({ storeId }: { storeId: string }): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/statistics/latest`);
		return response.data;
	},
	postStatsCollect: async <T = any>({ storeId, ...rest }: StatsRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/statistics/collect`, rest);
		return response.data;
	},
};
