import React from 'react';

import styled from 'styled-components';

import { ellipsis2 } from '../../../assets/styles/ellipsis';
import { HStack } from '../../../components/Common';
import { Icon, IconID, Label, Tooltip } from '../../../components/Display';
import { type FilterItems } from '../../../components/Filter/components/FilterModal/types';
import { Checkbox } from '../../../components/Forms';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type Payment, type GetPaymentsRequest } from '../../../services/payment/types';
import {
	IS_APPROVAL_OPTIONS,
	MEDICATION_OPTIONS,
	MEDICATION_VALUES,
	PAY_TYPE_OPTIONS,
	PAY_TYPE_VALUES,
} from '../../../utils/consts';
import { formatPhoneNumber } from '../../../utils/format';

export const PAYMENT_FILTER_ITEMS: FilterItems<GetPaymentsRequest> = [
	{
		label: '기간',
		key: 'period',
		type: 'PERIOD',
	},
	{
		label: '거래 유형',
		key: 'medicationType',
		type: 'SELECT',
		selectProps: {
			options: MEDICATION_OPTIONS,
			optionsStyle: { maxHeight: '30.4rem' },
		},
	},
	{
		label: '결제 유형',
		key: 'payType',
		type: 'SELECT',
		selectProps: {
			options: PAY_TYPE_OPTIONS,
			optionsStyle: { maxHeight: '30.4rem' },
		},
	},
	{
		label: '결제 상태',
		key: 'isApproval',
		type: 'RADIO',
		radioProps: {
			name: 'isApproval',
			options: IS_APPROVAL_OPTIONS,
			$direction: 'COLUMN',
		},
	},
] as const;

const renderCouponOrPointIcon = (type: 'POINT' | 'COUPON', item: Payment) => {
	const [isRender, amount, label] =
		type === 'POINT' ? [item.isPoint, item.point, '포인트'] : [item.isCoupon, item.coupon, '쿠폰'];

	return (
		isRender && (
			<Tooltip
				trigger={
					<IconWrapper>
						<Icon id={IconID[type]} defaultColor="primary_600" width="1.6rem" height="1.6rem" />
					</IconWrapper>
				}
			>
				<Label $color="white" $fontStyle="caption_1">
					{label} 사용 금액 : {amount.toLocaleString()}원
				</Label>
			</Tooltip>
		)
	);
};

const IconWrapper = styled.span`
	padding: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
	background-color: ${({ theme }) => theme.colors.primary.primary_200};
`;

export const PAYMENT_TABLE_COLUMNS = ({ onPickup }: { onPickup: (id: string) => void }): Columns<Payment> =>
	[
		{
			key: 'approvalAt',
			headerName: '승인 일시',
			width: { min: '12.6rem', max: '12.6rem' },
			style: { textAlign: 'center' },
			isClick: true,
			defaultValue: '-',
		},
		{
			key: 'name',
			headerName: '회원명',
			width: { min: '10rem' },
			isClick: true,
			renderComponent: (value) => <p style={ellipsis2}>{value}</p>,
		},
		{
			key: 'phone',
			headerName: '전화번호',
			renderComponent: (value) => formatPhoneNumber(value),
			width: { min: '16rem' },
		},
		{
			key: 'payType',
			headerName: '결제 유형',
			renderComponent: (value) => PAY_TYPE_VALUES[value],
			width: { min: '15rem' },
			isClick: true,
			defaultValue: '-',
		},
		{
			key: 'medicationType',
			headerName: '거래 유형',
			renderComponent: (value) => MEDICATION_VALUES[value],
			width: { min: '15rem' },
			isClick: true,
			defaultValue: '-',
		},
		{
			key: 'isApproval',
			headerName: '결제 상태',
			width: { min: '12rem' },
			renderComponent: (value) => (value ? '승인 완료' : '승인 취소'),
			isClick: true,
		},
		{
			key: 'totalAmount',
			headerName: '총 결제 금액',
			renderComponent: (value) => value.toLocaleString() + '원',
			style: { justifyContent: 'flex-end' },
			width: { min: '13rem' },
			isClick: true,
		},
		{
			key: 'actualAmount',
			headerName: '실결제 금액',
			renderComponent: (value, item) => {
				return (
					<HStack $gap="0.4rem" $alignItems="center">
						{renderCouponOrPointIcon('POINT', item)}
						{renderCouponOrPointIcon('COUPON', item)}
						{value.toLocaleString() + '원'}
					</HStack>
				);
			},
			style: { justifyContent: 'flex-end' },
			width: { min: '17rem' },
			isClick: true,
		},
		{
			key: 'id',
			headerName: '수령 여부',
			width: { min: '10rem', max: '10rem' },
			style: { justifyContent: 'center' },
			renderComponent: (_, item) => (
				<Tooltip
					floatingOptions={{ offset: 0 }}
					trigger={
						<label
							htmlFor=""
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								!item.pickupAt && onPickup(String(item.id));
							}}
						>
							<Checkbox $size={{ width: '2.4rem' }} checked={!!item.pickupAt} />
						</label>
					}
					enabled={!!item.pickupAt}
				>
					<PickUpItem>{item.pickupAt}</PickUpItem>
				</Tooltip>
			),
		},
	] as const;

const PickUpItem = styled.span`
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.font.body.body_3};
	white-space: nowrap;
`;
