import React, { type CSSProperties } from 'react';

import styled from 'styled-components';

import { Label } from '../../Display';
import { ChartLegendIcon } from '../styles';

import type { Props } from 'recharts/types/component/DefaultLegendContent';

interface CustomLegendProps {
	props: Props;
	innerStyle?: CSSProperties;
}

export function CustomLegend({ props, innerStyle }: CustomLegendProps) {
	const { payload } = props;
	if (!payload) return <></>;

	return (
		<LegendWrapper>
			<LegendInner style={innerStyle}>
				{[...payload].map((entry, idx) => {
					const { type = 'square', color, value } = entry;
					return (
						<LegendItem key={idx}>
							<ChartLegendIcon $type={type} $color={color} />
							<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
								{value}
							</Label>
						</LegendItem>
					);
				})}
			</LegendInner>
		</LegendWrapper>
	);
}

const LegendWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const LegendInner = styled.ul`
	display: inline-flex;
	padding: 1.2rem 2.4rem;
	align-items: flex-start;
	gap: 4.8rem;
	border-radius: 99px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
`;

const LegendItem = styled.li`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
