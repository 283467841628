import React, { type CSSProperties } from 'react';

import styled from 'styled-components';

import context from '../../../context';
import { Modal, type ModalProps } from '../Modal';

interface ConfirmModalProps extends ModalProps {
	confirm: () => void;
	message: React.ReactNode | null;
	buttonMessage?: {
		confirm?: string;
		shouldPrevent?: boolean;
		cancel?: string;
	};
}

const buttonStyle: CSSProperties = { width: '10.4rem', height: '3.6rem' };

export const ConfirmModal = ({ confirm, message, buttonMessage }: ConfirmModalProps) => {
	const { handleClose } = context.modal.useDispatch();
	return (
		<Modal
			zIndex={1}
			footer={{
				$hasBorderTop: false,
				style: { padding: '0 2.4rem 2.8rem' },
				button: [
					{
						children: buttonMessage?.cancel ?? '취소',
						style: buttonStyle,
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
					},
					{
						children: buttonMessage?.confirm ?? '확인',
						style: buttonStyle,
						onClick: () => {
							confirm();
							handleClose();
						},
						shouldPrevent: buttonMessage?.shouldPrevent,
					},
				],
			}}
		>
			<ConfirmModalWrapper>
				<div className="contents">{message}</div>
			</ConfirmModalWrapper>
		</Modal>
	);
};

const ConfirmModalWrapper = styled.div`
	padding: 3.2rem 2.8rem 2.8rem;
	display: flex;
	flex-direction: column;
	min-width: 40rem;
	.contents {
		${({ theme }) => theme.font.body.body_2};
		white-space: pre-wrap;
		text-align: left;
	}
	mark {
		background-color: transparent;
		color: ${({ theme }) => theme.colors.red.red_600};
	}
`;
