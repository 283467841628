import type { FileData, FileMessageData, MessageExtraData, QRData } from './types';

const isJSONString = (data: string) => {
	try {
		JSON.parse(data);
	} catch (e) {
		return false;
	}
	return true;
};

export const getMessageData = <T extends MessageExtraData>(dataStr: string): T | null => {
	if (isJSONString(dataStr)) {
		const data = JSON.parse(dataStr);
		if ('type' in data) {
			// base data
			if ('receipt' in data) {
				return data as T;
			}
			if ('sideEffect' in data) {
				return data as T;
			}
			if ('payment' in data) {
				return data as T;
			}
			if ((data.type === 'REQUEST' && 'promotion' in data) || data.type !== 'REQUEST') {
				return data as T;
			}
			if ((data.type === 'REQUEST' && 'prescription' in data) || data.type !== 'REQUEST') {
				return data as T;
			}
			if ((data.type === 'REQUEST' && 'pharmSale' in data) || data.type !== 'REQUEST') {
				return data as T;
			}
		}
	}
	return null;
};

export const getFileMessageData = (dataStr: string): FileMessageData | null => {
	if (isJSONString(dataStr)) {
		const data = JSON.parse(dataStr);
		if ('files' in data && data.files.length !== 0) {
			return data as FileMessageData;
		}
	}
	return null;
};

export const isQrData = (data: object): data is QRData => {
	try {
		return 'data' in data && 'manufactur' in data;
	} catch (e) {
		return false;
	}
};

export const isFileData = (data: object): data is FileData => {
	try {
		return (
			'assetId' in data &&
			'fileName' in data &&
			'isImage' in data &&
			'url' in data &&
			'thumbnailUrl' in data &&
			'size' in data &&
			'expiredAt' in data
		);
	} catch (e) {
		return false;
	}
};

export const getFileData = (data: object): FileData | null => {
	if (isFileData(data)) {
		return data;
	}
	return null;
};
