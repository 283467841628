import React, { useState } from 'react';

import { type Sender } from '@sendbird/chat/message';
import styled, { css } from 'styled-components';

import context from '../../../context';
import { useMemberInfo } from '../../../services/member/queries';
import { GENDER_VALUES } from '../../../utils/consts';
import { formatBirth, formatPhoneNumber } from '../../../utils/format';
import { IconButton } from '../../Buttons';
import { Flex } from '../../Common';
import { Icon, IconID, Label, type StyledLabelProps, Tooltip } from '../../Display';

interface ViewerHeaderProps {
	sender?: Sender;
	isQr: boolean;
	fileName: string;
	onPrintClick?: () => void;
	onDownloadClick: () => void;
	onCopyClick: (onCopied: () => void) => void;
	close: () => void;
}

const buttonStyle = {
	width: '2.8rem',
	height: '2.8rem',
};
export const ViewerHeader = (props: ViewerHeaderProps) => {
	const { sender, isQr, fileName, close, onPrintClick, onCopyClick, onDownloadClick } = props;
	const [isCopied, setIsCopied] = useState<boolean>(false);

	return (
		<Container>
			<HeaderLeft>
				<Label $fontStyle={'title_1'} $fontWeight={'bold'}>
					{isQr && sender ? <SenderInfo sender={sender} /> : '미리보기'}
				</Label>
			</HeaderLeft>
			<LabelWrapper>
				{isQr ? (
					<>
						<Label $fontStyle={'body_2'} $fontWeight={'bold'} $color={'black'}>
							{`[제조사: ${fileName}]`}
						</Label>
						<Label $fontStyle={'body_2'} $fontWeight={'medium'} $color={'gray_600'}>
							{'qr_code.png'}
						</Label>
					</>
				) : (
					<Label $fontStyle={'body_2'} $fontWeight={'medium'} $color={'gray_600'}>
						{fileName}
					</Label>
				)}
			</LabelWrapper>
			<HeaderRight>
				<HeaderRight>
					<Tooltip
						trigger={
							<IconButton onClick={onPrintClick} {...buttonStyle}>
								<Icon id={IconID.VIEWER_PRINTER} {...buttonStyle} />
							</IconButton>
						}
						floatingOptions={{ allowedPlacements: ['bottom'] }}
					>
						<Label $color={'white'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
							{'인쇄'}
						</Label>
					</Tooltip>
					<Tooltip
						trigger={
							<IconButton onClick={onDownloadClick} {...buttonStyle}>
								<Icon id={IconID.VIEWER_DOWNLOAD} {...buttonStyle} />
							</IconButton>
						}
						floatingOptions={{ allowedPlacements: ['bottom'] }}
					>
						<Label $color={'white'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
							{'저장'}
						</Label>
					</Tooltip>

					<Tooltip
						trigger={
							<IconButton
								onClick={() => {
									onCopyClick(() => {
										setIsCopied(true);
										setTimeout(() => {
											setIsCopied(false);
										}, 500);
									});
								}}
								{...buttonStyle}
							>
								<Icon id={IconID.VIEWER_COPY} {...buttonStyle} />
							</IconButton>
						}
						floatingOptions={{ allowedPlacements: ['bottom'] }}
					>
						<Label $color={'white'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
							{isCopied ? '복사완료' : '복사'}
						</Label>
					</Tooltip>
				</HeaderRight>
				<CloseWrapper>
					<IconButton onClick={close} width={'1.6rem'} height={'1.6rem'}>
						<Icon id={IconID.VIEWER_CLOSE} width={'1.6rem'} height={'1.6rem'} />
					</IconButton>
				</CloseWrapper>
			</HeaderRight>
		</Container>
	);
};

const SenderInfo = ({ sender }: { sender: Sender }) => {
	const userId = Number(sender.userId.replace('user_', ''));
	const { userInfo } = context.user.useValue();
	const { data: info } = useMemberInfo({ storeId: userInfo?.storeId, userId });

	if (!info) {
		return '고객 정보를 찾을 수 없습니다.';
	}

	return (
		<Flex className={'sender-info'} $alignItems={'center'} $gap={'1.2rem'}>
			<SenderAvatar $imageUrl={sender.profileUrl} />
			<Flex $direction={'column'} $justify={'center'} $alignItems={'start'} $alignSelf={'stretch'}>
				<Label $fontStyle={'title_2'} $fontWeight={'bold'}>
					{info.name}
				</Label>
				<Flex $alignItems={'center'} $gap={'0.8rem'}>
					<SenderSubInfoLabel {...SubInfoLabelStyles}>{formatBirth(info.birth)}</SenderSubInfoLabel>
					<SenderSubInfoLabel {...SubInfoLabelStyles}>{GENDER_VALUES[info.gender]}</SenderSubInfoLabel>
					<SenderSubInfoLabel {...SubInfoLabelStyles}>{formatPhoneNumber(info.phone)}</SenderSubInfoLabel>
				</Flex>
			</Flex>
		</Flex>
	);
};

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 7.2rem;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const HeaderLeft = styled.div`
	display: flex;
	align-items: center;
	padding-left: 2rem;
	gap: 1.2rem;
	min-width: 28rem;
`;

const LabelWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	position: absolute;
	left: calc(50% - 30rem);
	min-width: 60rem;
`;

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	gap: 2.8rem;
	align-self: stretch;
`;

const CloseWrapper = styled.div`
	display: flex;
	width: 7.2rem;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const SubInfoLabelStyles: StyledLabelProps = {
	$color: 'gray_700',
	$fontStyle: 'body_2',
};

const SenderSubInfoLabel = styled(Label)`
	&:not(:last-child) {
		padding-right: 0.8rem;
		border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}
`;

const SenderAvatar = styled.span<{ $imageUrl?: string }>`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
	background-image: url('/images/others/avatar-default.svg');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 80%;
	${({ $imageUrl }) =>
		$imageUrl &&
		css`
			background-image: url(${$imageUrl});
			background-size: cover;
			background-position: center;
		`}
`;
