import { useMemo } from 'react';

import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import {
	type InboundsTotal,
	type OutboundsTotal,
	type PrescriptionTotal,
	type SalesTotal,
	type StatsAges,
	type StatsDetailDaily,
	type StatsDetailMonthly,
	type StatsDetailWeekday,
	type StatsDetailWeekly,
	type StatsRequest,
} from './types';
import { api } from '../index';
import { type ErrorData } from '../types';

import type { QuerySelect } from '../../hooks/types';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const statsKeys = {
	all: ['stats'] as const,
	daily: (params: StatsRequest) => [...statsKeys.all, 'daily', params] as const,
	weekly: (params: StatsRequest) => [...statsKeys.all, 'weekly', params] as const,
	monthly: (params: StatsRequest) => [...statsKeys.all, 'monthly', params] as const,
	weekday: (params: StatsRequest) => [...statsKeys.all, 'weekday', params] as const,
	prescriptions: (params: StatsRequest) => [...statsKeys.all, 'prescriptions', params] as const,
	sales: (params: StatsRequest) => [...statsKeys.all, 'sales', params] as const,
	outbounds: (params: StatsRequest) => [...statsKeys.all, 'outbounds', params] as const,
	inbounds: (params: StatsRequest) => [...statsKeys.all, 'inbounds', params] as const,
	saleProducts: (params: StatsRequest) => [...statsKeys.all, 'sale-products', params] as const,
	drugUsages: (params: StatsRequest) => [...statsKeys.all, 'drug-usages', params] as const,
	agesTotal: (params: StatsRequest) => [...statsKeys.all, 'ages-total', params] as const,
	agesAvg: (params: StatsRequest) => [...statsKeys.all, 'ages-avg', params] as const,
	latest: (storeId: string) => [...statsKeys.all, 'latest', storeId] as const,
} as const;

const statsQueryFn = {
	daily: api.statistics.getStatsDaily,
	weekly: api.statistics.getStatsWeekly,
	monthly: api.statistics.getStatsMonthly,
	weekday: api.statistics.getStatsWeekday,
	prescriptions: api.statistics.getPrescriptionsTotal,
	sales: api.statistics.getSalesTotal,
	outbounds: api.statistics.getOutboundsTotal,
	inbounds: api.statistics.getInboundsTotal,
	saleProducts: api.statistics.getSaleProductsTotal,
	drugUsages: api.statistics.getDrugUsagesTotal,
	agesTotal: api.statistics.getAgesTotal,
	agesAvg: api.statistics.getAgesAvg,
	latest: api.statistics.getStatsLatest,
} as const;

export type UnitQueryResultType<T extends ExtendedPeriodUnit> = T extends 'daily'
	? StatsDetailDaily
	: T extends 'weekly'
		? StatsDetailWeekly
		: T extends 'monthly'
			? StatsDetailMonthly
			: StatsDetailWeekday;

export type PeriodUnit = 'daily' | 'weekly' | 'monthly';
export type ExtendedPeriodUnit = PeriodUnit | 'weekday';

export function useGetPrescriptionsTotal<TResult = PrescriptionTotal>({
	select,
	...params
}: StatsRequest & QuerySelect<PrescriptionTotal, TResult>) {
	return useQuery<PrescriptionTotal, AxiosError<ErrorData>, TResult>({
		queryKey: statsKeys.prescriptions(params),
		queryFn: async () => await statsQueryFn.prescriptions(params),
		enabled: !!params.storeId && !!params.startDate && !!params.endDate,
		select: (data) => (typeof select === 'function' ? select?.(data) : data) as TResult,
	});
}

export function useGetSalesTotal<TResult = SalesTotal>({
	select,
	...params
}: StatsRequest & QuerySelect<SalesTotal, TResult>) {
	return useQuery<SalesTotal, AxiosError<ErrorData>, TResult>({
		queryKey: statsKeys.sales(params),
		queryFn: async () => await statsQueryFn.sales(params),
		enabled: !!params.storeId && !!params.startDate && !!params.endDate,
		select: (data) => (typeof select === 'function' ? select?.(data) : data) as TResult,
	});
}

export type TotalsGrossProfit = Pick<
	PrescriptionTotal,
	'pxSales' | 'pxCount' | 'nonInsureMargin' | 'nonInsureExtraCharge' | 'pxPrice'
> &
	Pick<SalesTotal, 'sales' | 'discountPrice' | 'saleCount' | 'marginRatio' | 'saleMargin'> &
	OutboundsTotal &
	InboundsTotal;

export function useGetTotalsGrossProfit({ ...params }: StatsRequest) {
	const enabled = !!params.storeId && !!params.startDate && !!params.endDate;
	return useQueries({
		queries: [
			{
				queryKey: statsKeys.prescriptions(params),
				queryFn: async () => await statsQueryFn.prescriptions(params),
				enabled,
				select: ({ pxSales, pxCount, nonInsureMargin, nonInsureExtraCharge, pxPrice }: PrescriptionTotal) => ({
					pxSales,
					pxCount,
					nonInsureMargin,
					nonInsureExtraCharge,
					pxPrice,
				}),
			},
			{
				queryKey: statsKeys.sales(params),
				queryFn: async () => await statsQueryFn.sales(params),
				enabled,
				select: ({ sales, discountPrice, saleCount, marginRatio, saleMargin }: SalesTotal) => ({
					sales,
					discountPrice,
					saleCount,
					marginRatio,
					saleMargin,
				}),
			},
			{
				queryKey: statsKeys.outbounds(params),
				queryFn: async () => await statsQueryFn.outbounds(params),
				enabled,
			},
			{
				queryKey: statsKeys.inbounds(params),
				queryFn: async () => await statsQueryFn.inbounds(params),
				enabled,
			},
		],
		combine: (results) => ({
			data: results.reduce((cur, acc) => {
				return { ...acc.data, ...cur };
			}, {}) as TotalsGrossProfit,
			isLoading: results.some((result) => result.isLoading),
			isSuccess: results.every((result) => result.isSuccess),
		}),
	});
}

export function useGetSaleProductsTotal({ ...params }: StatsRequest) {
	return useQuery({
		queryKey: statsKeys.saleProducts(params),
		queryFn: async () => await statsQueryFn.saleProducts(params),
		enabled: !!params.storeId && !!params.startDate && !!params.endDate,
	});
}

export function useGetDrugUsagesTotal({ ...params }: StatsRequest) {
	return useQuery({
		queryKey: statsKeys.drugUsages(params),
		queryFn: async () => await statsQueryFn.drugUsages(params),
		enabled: !!params.storeId && !!params.startDate && !!params.endDate,
	});
}

interface StatsRequestPeriod {
	storeId: string;
	period: Array<{ startDate: string; endDate: string }>;
}

export function useGetStatsAges<TResult = StatsAges>({
	storeId,
	period,
	select,
	type,
}: StatsRequestPeriod &
	QuerySelect<StatsAges, TResult> & {
		type: 'average' | 'total';
	}) {
	const queryKeyByType = type === 'average' ? statsKeys.agesAvg : statsKeys.agesTotal;
	const queryFnByType = type === 'average' ? statsQueryFn.agesAvg : statsQueryFn.agesTotal;

	return useQueries({
		queries: period.map(({ startDate, endDate }) => ({
			queryKey: queryKeyByType({ storeId, startDate, endDate }),
			queryFn: async () => await queryFnByType({ storeId, startDate, endDate }),
			enabled: !!storeId && !!startDate && !!endDate,
			select: (data) => (typeof select === 'function' ? select?.(data) : data) as TResult,
		})) as Array<UseQueryOptions<StatsAges, AxiosError<ErrorData>, TResult>>,
		combine: (results) => ({
			data: results.filter(({ data }) => data !== undefined).map(({ data }) => data) as TResult[],
			isLoading: results.some((result) => result.isLoading),
			isSuccess: results.every((result) => result.isSuccess),
		}),
	});
}

const WEEKDAYS = {
	MONDAY: '월요일',
	TUESDAY: '화요일',
	WEDNESDAY: '수요일',
	THURSDAY: '목요일',
	FRIDAY: '금요일',
	SATURDAY: '토요일',
	SUNDAY: '일요일',
} as const;

export function useGetPeriodDetails<T extends ExtendedPeriodUnit, TResult = Array<UnitQueryResultType<T>>>({
	storeId,
	period,
	unit,
	select,
}: StatsRequestPeriod & {
	unit: T;
} & QuerySelect<Array<UnitQueryResultType<T>>, TResult>) {
	const unitQueryKey = useMemo(() => {
		switch (unit) {
			case 'daily':
				return statsKeys.daily;
			case 'weekly':
				return statsKeys.weekly;
			case 'monthly':
				return statsKeys.monthly;
			case 'weekday':
				return statsKeys.weekday;
			default:
				return statsKeys.daily;
		}
	}, [unit]);

	const unitQueryFn = useMemo(() => {
		switch (unit) {
			case 'daily':
				return statsQueryFn.daily;
			case 'weekly':
				return statsQueryFn.weekly;
			case 'monthly':
				return statsQueryFn.monthly;
			case 'weekday':
				return statsQueryFn.weekday;
			default:
				return statsQueryFn.daily;
		}
	}, [unit]);

	return useQueries({
		queries: period.map(({ startDate, endDate }) => ({
			queryKey: unitQueryKey({ storeId, startDate, endDate }),
			queryFn: async () => await unitQueryFn({ storeId, startDate, endDate }),
			enabled: !!storeId && !!startDate && !!endDate,
			select: (data) =>
				(unit === 'weekday'
					? (data as Array<UnitQueryResultType<'weekday'>>).map(({ dayOfWeek, ...rest }) => ({
							dayOfWeek: WEEKDAYS[dayOfWeek as keyof typeof WEEKDAYS],
							...rest,
						}))
					: typeof select === 'function'
						? select?.(data)
						: data) as TResult,
		})) as Array<UseQueryOptions<Array<UnitQueryResultType<T>>, AxiosError<ErrorData>, TResult>>,
		combine: (results) => ({
			data: results.filter(({ data }) => data !== undefined).map(({ data }) => data) as TResult[],
			isLoading: results.some((result) => result.isLoading),
			isSuccess: results.every((result) => result.isSuccess),
		}),
	});
}

export function useGetStatsLatest({ storeId }: { storeId: string }) {
	return useQuery({
		queryKey: statsKeys.latest(storeId),
		queryFn: async () => statsQueryFn.latest({ storeId }),
		enabled: !!storeId,
		select: (data) => data.date,
	});
}

export function usePostStatsCollect({ storeId }: { storeId: string }) {
	const queryClient = useQueryClient();
	return useMutation<unknown, AxiosError<ErrorData>, StatsRequest>({
		mutationFn: async (params: StatsRequest) => api.statistics.postStatsCollect(params),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: statsKeys.latest(storeId),
			});
		},
	});
}
