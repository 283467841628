import React, { useCallback, useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PhraseModal } from './PhraseModal';
import { ellipsisStyle } from '../../../assets/styles/ellipsis';
import { Button, IconButton } from '../../../components/Buttons';
import { HStack, InsideTitle, Loading, VStack } from '../../../components/Common';
import { Icon, IconID, Label } from '../../../components/Display';
import { AlertModal, ConfirmModal } from '../../../components/Modals';
import context from '../../../context';
import { useDeleteTemplates, useGetInfinityTemplates, useGetTemplatesUsage } from '../../../services/templates/queries';

const Phrase = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, isSuccess, refetch } =
		useGetInfinityTemplates(userInfo.storeId, 12);
	const observerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) fetchNextPage();
			},
			{ threshold: 1.0 },
		);

		if (observerRef.current) observer.observe(observerRef.current);

		return () => {
			if (observerRef.current) observer.unobserve(observerRef.current);
		};
	}, [hasNextPage, isFetchingNextPage, fetchNextPage, observerRef.current]);

	const { data: usage, refetch: usageRefetch } = useGetTemplatesUsage(userInfo.storeId);

	const { mutate } = useDeleteTemplates({
		onSuccess: () => {
			refetch();
			usageRefetch();
			toast.success('선택한 문구가 삭제되었습니다.');
		},
		onError: (err) => {
			toast.error('문구를 삭제하는데 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const handleDelete = useCallback(
		(templateId: number) => {
			if (!userInfo) return;
			handleOpen(
				<ConfirmModal
					confirm={() => {
						mutate({ storeId: userInfo.storeId, templateIds: [templateId] });
					}}
					message={'선택한 문구를 삭제하시겠습니까?'}
					buttonMessage={{ shouldPrevent: true }}
				/>,
			);
		},
		[userInfo],
	);

	const handleModal = useCallback((phraseId?: number) => {
		handleOpen(
			<PhraseModal
				refetch={() => {
					refetch();
					usageRefetch();
				}}
				phraseId={phraseId}
			/>,
		);
	}, []);

	return (
		<VStack style={{ height: '100%' }}>
			<InsideTitle
				title="목록"
				rightRender={
					<HStack $gap="1rem">
						<Button
							buttonType="LINE"
							color="SECONDARY"
							onClick={() => {
								if (usage && usage.count >= usage.limit) {
									handleOpen(<AlertModal message={`최대 생성 개수 ${usage.limit}개를 초과하였습니다.`} />);
								} else {
									handleModal();
								}
							}}
							disabled={isLoading}
						>
							<Icon id={IconID.MESSAGE_PLUS} width="2rem" height="2rem" />새 문구 등록
						</Button>
					</HStack>
				}
			/>
			{(isLoading || isFetchingNextPage) && <Loading />}
			{isSuccess &&
				(data.pages[0].total === 0 ? (
					<VStack $alignItems="center" $justify="center" $flex="1">
						<Label $color="gray_600">등록된 템플릿이 없습니다.</Label>
					</VStack>
				) : (
					<Grid>
						{data.pages.map((page) =>
							page.content.map((template) => (
								<Item key={'template--' + template.id} $gap="1.2rem" $padding="1.6rem 1.6rem 1rem">
									<HStack className="template--title" $justify="between" $gap="1.2rem">
										<Label $fontStyle="title_3" $fontWeight="bold">
											{template.title}
										</Label>
										<HStack $gap="0.8rem">
											<IconButton
												width="2.2rem"
												height="2.2rem"
												onClick={(e) => {
													e.stopPropagation();
													handleModal(template.id);
												}}
											>
												<Icon
													id={IconID.PENCIL}
													width="2.2rem"
													height="2.2rem"
													defaultColor="gray_600"
													hoverColor="black"
												/>
											</IconButton>
											<IconButton
												width="2.2rem"
												height="2.2rem"
												onClick={(e) => {
													e.stopPropagation();
													handleDelete(template.id);
												}}
											>
												<Icon
													id={IconID.BTN_DELETE}
													width="2.2rem"
													height="2.2rem"
													defaultColor="gray_600"
													hoverColor="black"
												/>
											</IconButton>
										</HStack>
									</HStack>

									<Label $fontStyle="body_2" className="template--content">
										{template.content}
									</Label>
								</Item>
							)),
						)}
					</Grid>
				))}
			{hasNextPage && !isFetchingNextPage && <div ref={observerRef} />}
		</VStack>
	);
};

const Item = styled(VStack)`
	display: flex;
	flex-direction: column;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;

	.template {
		&--title {
			padding-bottom: 1.2rem;
			border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
			max-width: 100%;
			min-width: 3.3rem;
			span {
				flex: 1;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				-webkit-line-clamp: 1;
			}
		}
		&--content {
			${ellipsisStyle(7)}
			height:14rem;
		}
	}
`;

const Grid = styled.ul`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1.6rem;
`;
export default Phrase;
