import React from 'react';

import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';

import { Layout } from './layout';
import Pages from './pages';

const router = createBrowserRouter([
	// 로그인을 하지 않았을때만 입장가능
	{
		element: <Layout.UnRequireAuth />,
		children: [
			{
				path: '/login',
				element: <Pages.Login />,
			},
		],
	},
	// 로그인 후 입장 가능
	{
		path: '/',
		element: <Layout.Default />,
		children: [
			{
				path: '',
				element: <Navigate replace to="/crm/chat" />,
			},
			{
				path: 'dashboard',
				element: <Pages.Dashboard />,
			},
			{
				path: 'mypage',
				children: [
					{
						path: '',
						element: <Navigate to="information" />,
					},
					{
						path: 'information',
						element: <Pages.MyPage.Information />,
					},
					{
						path: 'payment',
						element: <Pages.MyPage.Payment />,
					},
					{
						path: 'service',
						element: <Pages.MyPage.Service />,
					},
				],
			},
			{
				path: 'chat',
				element: <Navigate replace to="/crm/chat" />,
			},
			{
				path: 'crm',
				children: [
					{ path: 'chat', element: <Pages.CRM.Chat /> },
					{ path: 'multiple', element: <Pages.CRM.Multiple /> },
				],
			},
			{
				path: 'operation',
				children: [
					{
						path: '',
						element: <Navigate to={'basic'} />,
					},
					{
						path: 'basic',
						children: [
							{
								path: '',
								element: <Pages.Operation.Basic />,
							},
							{
								path: 'management',
								children: [
									{
										path: '',
										element: <Pages.Operation.ManagingUsers />,
									},
									{
										path: 'detail/:userId',
										element: <Pages.Operation.ModifyUsers />,
									},
									{
										path: 'registration',
										element: <Pages.Operation.RegistrationUser />,
									},
								],
							},
						],
					},
					{
						path: 'app',
						element: <Pages.Operation.App />,
					},
					{
						path: 'group',
						children: [
							{
								path: '',
								element: <Pages.Operation.Group />,
							},
							{
								path: 'create',
								element: <Pages.Operation.GroupCreate />,
							},
							{
								path: 'detail/:groupId',
								element: <Pages.Operation.GroupDetail />,
							},
						],
					},
					{
						path: 'phrase',
						element: <Pages.Operation.Phrase />,
					},
					{
						path: 'auto-message',
						element: <Pages.Operation.AutoMessage />,
					},
				],
			},
			{
				path: 'member',
				children: [
					{
						path: '',
						element: <Navigate to={'information'} />,
					},
					{
						path: 'information',
						children: [
							{
								path: '',
								element: <Pages.Member.Information />,
							},
							{
								path: 'detail/:userId',
								element: <Pages.Member.InformationDetail />,
							},
						],
					},
					{
						path: 'blacklist',
						element: <Pages.Member.BlackList />,
					},
				],
			},
			{
				path: 'payment',
				children: [
					{
						path: '',
						element: <Navigate to={'history'} />,
					},
					{
						path: 'history',
						children: [
							{
								path: '',
								element: <Pages.Payment.History />,
							},
							{
								path: 'detail/:paymentId',
								element: <Pages.Payment.HistoryDetail />,
							},
						],
					},
				],
			},
			{
				path: 'promotion',
				children: [
					{
						path: '',
						element: <Navigate to="list" />,
					},
					{
						path: 'list',
						children: [
							{
								path: '',
								element: <Pages.Promotion.List />,
							},
							{
								path: 'detail/:id',
								element: <Pages.Promotion.PromotionDetail />,
							},
						],
					},
					{
						path: 'breakdown',
						element: <Pages.Promotion.Breakdown />,
					},
					{
						path: 'sales',
						children: [
							{
								path: '',
								element: <Pages.Promotion.Sales />,
							},
							{
								path: 'add',
								element: <Pages.Promotion.SalesDetail />,
							},
							{
								path: 'detail/:id',
								element: <Pages.Promotion.SalesDetail />,
							},
						],
					},
					{
						path: 'salesHistory',
						element: <Pages.Promotion.SalesHistory />,
					},
				],
			},
			{
				path: 'product',
				element: <Pages.Product />,
			},
			// {
			// 	path: 'drug',
			// 	children: [
			// 		{
			// 			path: 'information',
			// 			element: <Pages.Drug.Information />,
			// 		},
			// 	],
			// },
			{
				path: 'stats',
				children: [
					{ path: 'period', element: <Pages.Stats.Period /> },
					{ path: 'multilateral', element: <Pages.Stats.Multilateral /> },
					{ path: 'details', element: <Pages.Stats.SalesCalendar /> },
					{ path: 'details/:id', element: <Pages.Stats.Details /> },
				],
			},
			{
				path: 'as',
				children: [
					{
						path: '',
						element: <Navigate to="faq" />,
					},
					// {
					// 	path: 'inquiry',
					// 	element: <Pages.AS.Inquiry />,
					// },
					{
						path: 'faq',
						element: <Pages.AS.FAQ />,
					},
					// {
					// 	path: 'remote',
					// 	element: <Pages.AS.FAQ />,
					// },
				],
			},
			{
				path: 'notice',
				children: [
					{
						path: '',
						element: <Pages.Notice.index />,
					},
					{
						path: 'detail/:id',
						element: <Pages.Notice.Detail />,
					},
				],
			},
			{
				path: 'manual',
				element: <Pages.Manual />,
			},
		],
	},
	{
		path: '*',
		element: <Pages.NotFound />,
	},
]);

const Routers = () => {
	return <RouterProvider router={router} />;
};
export default Routers;
