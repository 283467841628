import React, { useMemo } from 'react';

import { type ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import styled from 'styled-components';

import { IconButton } from '../../components/Buttons';
import { Icon, IconID } from '../../components/Display';

interface CustomHeaderProps {
	param: ReactDatePickerCustomHeaderProps;
	type: 'MONTH' | 'DATE' | 'YEAR';
}

export const CustomHeader = ({ param, type }: CustomHeaderProps) => {
	const {
		nextMonthButtonDisabled,
		nextYearButtonDisabled,
		prevMonthButtonDisabled,
		prevYearButtonDisabled,
		// selectingDate,
		date,
	} = param;

	const formatDate = useMemo(() => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1);
		const day = String(date.getDate()).padStart(2, '0');

		return { year, month, day };
	}, [date]);

	const typeValues = useMemo(() => {
		switch (type) {
			case 'DATE':
				return {
					prevButtonDisabled: prevMonthButtonDisabled,
					nextButtonDisabled: nextMonthButtonDisabled,
					onPrevClick: () => {
						param.decreaseMonth();
					},
					onNextClick: () => {
						param.increaseMonth();
					},
				};
			case 'MONTH':
				return {
					prevButtonDisabled: prevYearButtonDisabled,
					nextButtonDisabled: nextYearButtonDisabled,
					onPrevClick: () => {
						param.decreaseYear();
					},
					onNextClick: () => {
						param.increaseYear();
					},
				};
			case 'YEAR':
				return {
					prevButtonDisabled: prevYearButtonDisabled,
					nextButtonDisabled: nextYearButtonDisabled,
					onPrevClick: () => {
						param.decreaseYear();
					},
					onNextClick: () => {
						param.increaseYear();
					},
				};
		}
	}, [param, type]);

	return (
		<Container className="datepicker-custom-header">
			<span className="date">
				<strong>{formatDate.year}</strong>년
				{type !== 'YEAR' && formatDate.month && (
					<React.Fragment>
						{' '}
						<strong>{formatDate.month}</strong>월
					</React.Fragment>
				)}
			</span>
			<div className="button_wrapper">
				<IconButton
					onClick={() => {
						typeValues.onPrevClick();
					}}
					disabled={typeValues.prevButtonDisabled}
				>
					<Icon
						id={IconID.LINE_DATE_LEFT}
						defaultColor={'gray_900'}
						disabledColor={'gray_300'}
						isDisabled={typeValues.prevButtonDisabled}
					/>
				</IconButton>
				<IconButton
					onClick={() => {
						typeValues.onNextClick();
					}}
					disabled={typeValues.nextButtonDisabled}
				>
					<Icon
						id={IconID.LINE_DATE_RIGHT}
						defaultColor={'gray_900'}
						disabledColor={'gray_300'}
						isDisabled={typeValues.nextButtonDisabled}
					/>
				</IconButton>
			</div>
		</Container>
	);
};

const Container = styled.div`
	padding: 1.8rem 2rem;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
	display: flex;
	justify-content: space-between;
	.date {
		${({ theme }) => theme.font.title.title_3};
		font-weight: 400;
		strong {
			font-weight: 700;
		}
	}
	.button_wrapper {
		display: flex;
		flex-direction: row;
		gap: 0.8rem;
	}
`;
