import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button } from '../../../../../components/Buttons';
import { Footer, InsideTitle } from '../../../../../components/Common';
import { FormItem, Radio, TextField } from '../../../../../components/Forms';
import { ConfirmModal } from '../../../../../components/Modals';
import context from '../../../../../context';
import { useValues } from '../../../../../hooks/useValues';
import { usePostCheckDuplicateAccount, usePostUsers } from '../../../../../services/users/queries';
import { type PostUsersRequest } from '../../../../../services/users/types';
import { USER_TYPE_OPTIONS } from '../../../../../utils/consts';
import { formatPhoneNumber } from '../../../../../utils/format';
import { passwordChecker } from '../../../../../utils/passwordChecker';

interface TmpPostUsersRequest extends PostUsersRequest {
	isDuplicate: boolean;
	confirmPassword: string;
}

export const RegistrationUser = () => {
	const navigate = useNavigate();

	const { handleOpen } = context.modal.useDispatch();
	const { userInfo } = context.user.useValue();
	const initialValues: TmpPostUsersRequest = useMemo(
		() => ({
			name: '',
			type: 'PARTNER',
			loginId: '',
			phone: '',
			password: '',
			isActive: true,

			isDuplicate: true,
			confirmPassword: '',
		}),
		[],
	);
	const { values, onChangeValues, dispatch } = useValues(initialValues);
	const [statusMessage, setStatusMessage] = useState('');
	const validPassword = useMemo(() => passwordChecker(values.password, values.confirmPassword), [values]);

	const isValid = useMemo(() => {
		const { isDuplicate, name, loginId, phone } = values;

		return validPassword.isConfirm && !isDuplicate && name !== '' && loginId.length >= 5 && phone.length >= 12;
	}, [values, validPassword]);

	const { mutate } = usePostUsers({
		onSuccess: (res) => {
			navigate('/operation/basic/management');
		},
		onError: (err) => {
			toast.error('직원 등록에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { mutate: duplicateMutate } = usePostCheckDuplicateAccount({
		onSuccess: (res) => {
			if (res.exists) {
				setStatusMessage('이미 존재하는 아이디입니다. ');
			} else {
				setStatusMessage('사용 가능한 아이디입니다.');
				dispatch('SET', { isDuplicate: false });
			}
		},
		onError: (err) => {
			toast.error('확인에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onDuplicate = useCallback(() => {
		duplicateMutate({
			accountType: 'PHARM_USER',
			loginId: values.loginId,
		});
	}, [values.loginId]);

	useEffect(() => {
		setStatusMessage('');
		dispatch('SET', { isDuplicate: true });
	}, [values.loginId]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const { isDuplicate, confirmPassword, phone, ...rest } = values;
			handleOpen(
				<ConfirmModal
					confirm={() => {
						mutate({ ...rest, storeId: userInfo.storeId, phone: phone.replaceAll('-', '') });
					}}
					message={'신규 직원을 등록하시겠습니까?'}
					buttonMessage={{ shouldPrevent: true }}
				/>,
			);
		},
		[values, userInfo],
	);

	return (
		<Container onSubmit={onSubmit}>
			<div className="inner">
				<InsideTitle title="직원 정보" isBorder />
				<div id="form">
					<FormItem label="권한" isRequired>
						<Radio
							name={'type'}
							options={USER_TYPE_OPTIONS.slice(1)}
							selectValue={values.type}
							onChange={onChangeValues}
						/>
					</FormItem>
					<FormItem label="이름" isRequired>
						<TextField
							onChange={onChangeValues}
							name="name"
							value={values.name}
							style={{ height: '4rem' }}
							placeholder="이름을 입력해 주세요."
						/>
					</FormItem>
					<FormItem
						label="아이디"
						statusColor={values.isDuplicate ? 'red_600' : 'primary_600'}
						statusMessage={statusMessage}
						isRequired
					>
						{/* 영어, 숫자 조합 5~12자리 */}
						<div className="input__wrapper">
							<TextField
								onChange={onChangeValues}
								name="loginId"
								value={values.loginId.replaceAll(/[^a-zA-Z0-9]/g, '')}
								style={{ height: '4rem' }}
								placeholder={values.type === 'PARTNER' ? '5자리 면허번호를 입력해주세요.' : '아이디를 입력해주세요.'}
								maxLength={12}
							/>
							<Button
								disabled={values.loginId.length < 5}
								shouldPrevent={true}
								onClick={() => {
									onDuplicate();
								}}
							>
								중복확인
							</Button>
						</div>
					</FormItem>
					<FormItem
						label="비밀번호"
						statusMessage={validPassword.errorTarget === 'password' ? validPassword.message : undefined}
						isRequired
					>
						<TextField
							type="password"
							onChange={onChangeValues}
							name="password"
							value={values.password}
							style={{ height: '4rem' }}
							placeholder="8~20자, 문자, 숫자, 특수문자"
							maxLength={20}
						/>
					</FormItem>
					<FormItem
						label="비밀번호 확인"
						statusMessage={validPassword.errorTarget === 'confirmPassword' ? validPassword.message : undefined}
						isRequired
					>
						<TextField
							type="password"
							onChange={onChangeValues}
							name="confirmPassword"
							value={values.confirmPassword}
							maxLength={20}
							style={{ height: '4rem' }}
							placeholder="비밀번호를 한번 더 입력해 주세요."
						/>
					</FormItem>
					<FormItem label="휴대폰 번호" isRequired>
						<TextField
							onChange={onChangeValues}
							name="phone"
							value={formatPhoneNumber(values.phone)}
							maxLength={13}
							style={{ height: '4rem' }}
							placeholder="휴대폰 번호를 입력해주세요."
						/>
					</FormItem>
				</div>
			</div>
			<Footer>
				<Button
					style={{ width: '20rem' }}
					buttonType="LINE"
					color="SECONDARY"
					onClick={() => {
						handleOpen(
							<ConfirmModal
								confirm={() => {
									navigate(-1);
								}}
								message={'신규 직원 등록을 취소하시겠습니까?'}
							/>,
						);
					}}
				>
					취소
				</Button>
				<Button style={{ width: '20rem' }} disabled={!isValid} type="submit">
					등록
				</Button>
			</Footer>
		</Container>
	);
};

const Container = styled.form`
	height: 100%;
	display: flex;
	flex-direction: column;

	.inner {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		#form {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			> div {
				max-width: 76.8rem;
			}
			.input__wrapper {
				display: flex;
				gap: 0.8rem;
				flex: 1;
				> div {
					flex: 1;
				}
			}
		}
	}
`;
