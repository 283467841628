import React from 'react';

import { MenuItem } from '../../../../components/Modals/ContextMenu';
import { useDeleteBlockMembers, usePostBlockMembers } from '../../../../services/block/queries';
import { usePutPushPreference } from '../../../../services/chat/queries';
import { getMember, getMemberId, getStoreId } from '../../../Channel/context/utils';

import type { GroupChannel } from '@sendbird/chat/groupChannel';

interface ChannelStatusMenuProps {
	channel: GroupChannel;
	currentUserId: string;
	close: () => void;
}

interface menuProps {
	label: string;
	onClick: () => void;
}
export const ChannelStatusMenu = ({ channel, currentUserId, close }: ChannelStatusMenuProps) => {
	const { userId, isMuted } = getMember(channel, currentUserId);
	const { mutate: mutatePushPreference } = usePutPushPreference();
	const { mutate: mutateBlock } = usePostBlockMembers();
	const { mutate: mutateUnblock } = useDeleteBlockMembers();

	const handleChangePush = async (pushTriggerOption: 'off' | 'default') => {
		mutatePushPreference({ channelUrl: channel.url, pushTriggerOption });
	};

	const handleBlockMember = async (isBlocked: boolean) => {
		const storeId = getStoreId(currentUserId);
		const memberId = getMemberId(userId);
		const action = isBlocked ? mutateUnblock : mutateBlock;
		action({ storeId, data: { userIds: [memberId] } });
	};

	const pushStatus = channel.myPushTriggerOption === 'off' ? 'OFF' : 'DEFAULT';
	const blockStatus = isMuted ? 'BLOCKED' : 'UNBLOCKED';

	const pushMenuConfigs: Record<typeof pushStatus, menuProps> = {
		OFF: {
			label: '알람 켜기',
			onClick: async () => {
				await handleChangePush('default');
			},
		},
		DEFAULT: {
			label: '알람 끄기',
			onClick: async () => {
				await handleChangePush('off');
			},
		},
	};

	const blockMenuConfigs: Record<typeof blockStatus, menuProps> = {
		BLOCKED: {
			label: '대화 차단해제',
			onClick: async () => {
				await handleBlockMember(true);
			},
		},
		UNBLOCKED: {
			label: '대화 차단하기',
			onClick: async () => {
				await handleBlockMember(false);
			},
		},
	};

	return (
		<>
			<MenuItem
				onClick={() => {
					pushMenuConfigs[pushStatus].onClick();
					close();
				}}
			>
				{pushMenuConfigs[pushStatus].label}
			</MenuItem>
			<MenuItem
				onClick={() => {
					blockMenuConfigs[blockStatus].onClick();
					close();
				}}
			>
				{blockMenuConfigs[blockStatus].label}
			</MenuItem>
		</>
	);
};
