import React, { useCallback, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { SelectedItem } from './components/SelectedItem';
import { Target } from './components/Target';
import { Button, SwitchButton } from '../../../components/Buttons';
import { type ButtonColorType, type ButtonType } from '../../../components/Buttons/Button/types';
import { Loading, Footer, InsideTitle, Flex } from '../../../components/Common';
import { Icon, IconID, type IconTypes, Label } from '../../../components/Display';
import { FormItem, Radio, TextArea, TextField, FileInput } from '../../../components/Forms';
import { ConfirmModal, TemplatesModal } from '../../../components/Modals';
import { GroupsModal } from '../../../components/Modals/GroupsModal';
import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import Theme from '../../../lib/styledComponents/Theme';
import { useMutateWithAssets } from '../../../services/asset/queries';
import { useGetAnnouncementUsage, usePostAnnouncement } from '../../../services/chat/queries';
import { type AnnouncementRequest } from '../../../services/chat/types';
import { type Group } from '../../../services/groups/types';
import { useGetMemberCount, useGetMembers } from '../../../services/member/queries';
import { type BasicMemberInfo, type MemberData } from '../../../services/member/types';
import { type Template } from '../../../services/templates/types';
import { type DefaultOption } from '../../../utils/consts';
import { isValidCheck } from '../../../utils/isValidCheck';
import { MemberAddModal } from '../../Operation/Group/MemberAddModal';

const announcementData = [
	'다중 메시지 전송 시 발송이 늦어질 수 있는 점 양해 부탁드립니다.',
	'일괄 전송 선택 시 전체 고객에게 메시지가 발송됩니다.',
	'단골 약국으로 설정된 회원에게만 메시지가 발송됩니다.',
] as const;

type FormType = 'DIRECT' | 'SELECT';

const formOptions: Array<DefaultOption<FormType>> = [
	{ key: 'DIRECT', label: '직접 입력' },
	{ key: 'SELECT', label: '자주쓰는 문구' },
] as const;

export interface MultipleMessageType extends AnnouncementRequest {
	form: FormType;
	file: File[];
	groups: Group[];
	users: BasicMemberInfo[];
	template?: Template;
}

export interface CombineType extends Group, MemberData {
	item?: string;
	delete?: string;
}

interface ButtonProps {
	buttonType: ButtonType;
	color: ButtonColorType;
	shouldPrevent: boolean;
	disabled: boolean;
	onClick: () => void;
	id: IconTypes;
	width: string;
	height: string;
	string: string;
}

export interface TargetData {
	count: number;
	member: BasicMemberInfo[];
}
const Multiple = () => {
	const { userInfo } = context.user.useValue();
	const initValues: MultipleMessageType = {
		form: 'DIRECT',
		file: [],
		groups: [],
		users: [],
		template: undefined,

		isAll: false,
		message: '',
		userIds: [],
		groupIds: [],
		assetIds: [],
	};
	const { values, onChangeValues, dispatch } = useValues(initValues);
	const { handleOpen } = context.modal.useDispatch();
	const { data: memberCount } = useGetMemberCount(userInfo.storeId);

	const {
		data: memberData,
		pagination,
		queries,
	} = useGetMembers({
		storeId: userInfo.storeId,
		initialValues: { isFavorite: 'true' },
		historyKey: { pagination: 'favoriteGetMemberPage', queries: 'favoriteGetMember' },
	});

	const targetData = useMemo(() => {
		const result: TargetData = {
			count: values.users.length,
			member: values.users,
		};
		if (values.groupIds.length > 0) {
			if (!memberData) return result;
			const filterData = memberData.content.filter((member) => !values.userIds.includes(member.userId));
			result.count += filterData.length;
			result.member = [...values.users, ...filterData];
		}
		return result;
	}, [values.users, memberData]);

	useEffect(() => {
		pagination.setPagination({ listCount: 999 });
		queries.dispatch('SET', { groupIds: values.groupIds });
	}, [values.groupIds]);

	const buttons: ButtonProps[] = useMemo(
		() => [
			{
				buttonType: 'LINE',
				color: 'SECONDARY',
				shouldPrevent: false,
				disabled: values.isAll || memberCount?.favoriteCount === 0,
				onClick: () => {
					handleOpen(
						<MemberAddModal
							isFavorite={'true'}
							handleSelectedMembers={(users) => {
								const filterUsers = users.filter((user) => !values.userIds.includes(user.userId));
								const filterUserIds = filterUsers.map((user) => user.userId);
								dispatch('SET', {
									users: [...values.users, ...filterUsers],
									userIds: [...values.userIds, ...filterUserIds],
								});
							}}
						/>,
					);
				},
				id: IconID.USER_PLUS,
				width: '2rem',
				height: '2rem',
				string: '회원 추가',
			},
			{
				buttonType: 'LINE',
				color: 'SECONDARY',
				shouldPrevent: false,
				disabled: values.isAll || memberCount?.favoriteCount === 0,
				onClick: () => {
					handleOpen(
						<GroupsModal
							disabledGroupIds={values.groupIds}
							onSelect={(_, groups) => {
								const filterGroups = groups.filter((group) => !values.groupIds.includes(group.id));
								const filterGroupIds = filterGroups.map((group) => group.id);
								dispatch('SET', {
									groups: [...values.groups, ...filterGroups],
									groupIds: [...values.groupIds, ...filterGroupIds],
								});
							}}
							isFavoriteCount
						/>,
					);
				},
				id: IconID.GROUP_PLUS,
				width: '2rem',
				height: '2rem',
				string: '그룹 추가',
			},
		],
		[values, memberCount],
	);

	const handleFileChange = useCallback((files: File[]) => {
		dispatch('SET', { file: files });
	}, []);

	const { mutate: announcementMutate, isPending: isAnnouncementPending } = usePostAnnouncement({
		onSuccess: () => {
			dispatch('RESET');
			usageRefetch();
			toast.success('메시지가 성공적으로 전송되었습니다.');
		},
		onError: (err) => {
			toast.error('메시지 전송에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { handleMutateWithAssets, isAssetPending } = useMutateWithAssets<MultipleMessageType>({
		assetKey: 'assetIds',
		params: values,
		callback: (value) => {
			const { groups, users, form, file, template, ...rest } = value;
			announcementMutate(rest);
		},
	});

	const onSubmit = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					const { file } = values;
					handleMutateWithAssets(file, true);
				}}
				message={'다중 메시지를 전송하시겠습니까?'}
				buttonMessage={{ confirm: '전송', shouldPrevent: true }}
			/>,
		);
	}, [values]);

	const { data: usage, isSuccess, refetch: usageRefetch } = useGetAnnouncementUsage();

	return (
		<Container>
			{(isAnnouncementPending || isAssetPending) && <Loading $position="fixed" />}
			{/* 전송 대상 */}
			<Wrapper>
				<Flex $direction="column" $gap="1.2rem">
					<InsideTitle title="이용 안내" isBorder />
					<ul className="announcement">
						{announcementData.map((announcement, idx) => (
							<li className="announcement--item" key={'announcement--' + idx}>
								{announcement}
							</li>
						))}
					</ul>
				</Flex>
				<div className="top">
					<InsideTitle
						title={
							<Flex $gap="0.8rem" $alignItems="center">
								전송 대상
								<Target data={targetData} isAll={values.isAll} />
							</Flex>
						}
						isBorder
						rightRender={
							<Flex $gap="2rem" $alignItems="center">
								<Flex $gap={'0.8rem'} $alignItems={'center'}>
									<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
										{'일괄 전송'}
									</Label>
									<SwitchButton
										isOn={values.isAll}
										toggleSwitch={(isOn) => {
											dispatch('SET', { isAll: isOn });
										}}
										disabled={memberCount?.favoriteCount === 0}
									/>
								</Flex>
								<Flex $gap="1rem">
									{buttons.map((button, idx) => {
										const { id, width, height, disabled, string, ...rest } = button;
										return (
											<Button key={'button--' + idx} {...rest} disabled={disabled}>
												<Icon {...{ id, width, height }} isDisabled={disabled} />
												{string}
											</Button>
										);
									})}
								</Flex>
							</Flex>
						}
					/>
					{values.isAll ? (
						<SelectedItemsWrapper>
							<SelectedItem
								type="ALL"
								onDelete={() => {
									dispatch('SET', { isAll: false });
								}}
								$isActive
							/>
						</SelectedItemsWrapper>
					) : values.groups.length > 0 || values.users.length > 0 ? (
						<Flex $direction="column" $gap="2rem">
							{values.users.length > 0 && (
								<Flex $direction="column" $gap="0.8rem">
									<SelectedItemsLabel>회원</SelectedItemsLabel>
									<SelectedItemsWrapper>
										{values.users.map((user, idx) => (
											<SelectedItem
												key={'user__item--' + idx}
												type="USER"
												onDelete={(item) => {
													if (!item) return;
													const filterUsers = values.users.filter((user) => item.userId !== user.userId);
													const filterUserIds = filterUsers.map((user) => user.userId);
													dispatch('SET', { users: filterUsers, userIds: filterUserIds });
												}}
												item={user}
											/>
										))}
									</SelectedItemsWrapper>
								</Flex>
							)}
							{values.groups.length > 0 && (
								<Flex $direction="column" $gap="0.8rem">
									<SelectedItemsLabel>그룹</SelectedItemsLabel>
									<SelectedItemsWrapper>
										{values.groups.map((group, idx) => (
											<SelectedItem
												key={'group__item--' + idx}
												type="GROUP"
												onDelete={(item) => {
													if (!item) return;
													const filterGroups = values.groups.filter((group) => item.id !== group.id);
													const filterGroupIds = filterGroups.map((group) => group.id);
													dispatch('SET', { groups: filterGroups, groupIds: filterGroupIds });
												}}
												item={group}
											/>
										))}
									</SelectedItemsWrapper>
								</Flex>
							)}
						</Flex>
					) : (
						<p className="empty">전송할 대상을 선택해 주세요.</p>
					)}
				</div>

				{/* 내용 */}
				<div className="bottom">
					<InsideTitle title="내용" isBorder />
					<FormItem label={'형태'}>
						<Radio
							name={'form'}
							options={formOptions}
							selectValue={values.form}
							onChange={(e) => {
								dispatch('SET', { message: '', template: undefined });
								onChangeValues(e);
							}}
							style={{ gap: '4.8rem' }}
						/>
					</FormItem>
					<FormItem
						label={'보낼 메시지'}
						isRequired={true}
						statusMessage={
							values.file.length > 0 && Boolean(values.message.trim().length <= 0) ? `메시지 내용을 입력해주세요.` : ``
						}
					>
						{values.form === 'SELECT' && (
							<React.Fragment>
								<Button
									buttonType="LINE"
									color="SECONDARY"
									size={{ $fontSize: 'XS', $paddingSize: 'S', $heightSize: 'S' }}
									style={{ color: Theme.colors.black, alignSelf: 'flex-start' }}
									onClick={() => {
										handleOpen(
											<TemplatesModal
												selectTemplate={values.template}
												onSelect={(template: Template) => {
													dispatch('SET', { template, message: template.content });
												}}
											/>,
										);
									}}
								>
									템플릿 선택
								</Button>
								<TextField
									maxLength={20}
									count={{ show: true, max: 20 }}
									placeholder="자주쓰는 문구 템플릿을 선택해주세요."
									readOnly={true}
									name="templateTitle"
									value={values.template?.title ?? ''}
								/>
							</React.Fragment>
						)}
						{/* height: '9.6rem' */}
						<TextArea
							maxLength={1000}
							style={{ padding: '1.2rem' }}
							textAreaStyle={{
								minHeight: '4rem',
								maxHeight: '12rem',
								resize: 'vertical',
							}}
							count={{ show: true, max: 1000 }}
							placeholder={values.form === 'DIRECT' ? '내용을 입력해주세요.' : '자주쓰는 문구 템플릿을 선택해주세요.'}
							name="message"
							value={values.message}
							onChange={onChangeValues}
							readOnly={values.form === 'SELECT'}
							enableEmoji
						/>
					</FormItem>
					<FormItem label={'파일 첨부'}>
						<FileInput
							values={values.file}
							maxCapacity={{ size: 10, unit: 'MB' }}
							format={['image']}
							maxFileCount={10}
							onChange={handleFileChange}
						/>
					</FormItem>
				</div>
			</Wrapper>
			{/* 푸터 */}
			<Footer>
				{isSuccess && (
					<Usage>
						<span className="label">월 제한</span>
						<span className="usage">
							<span className="usage--count">{usage.count}</span>/<span className="usage--limit">{usage.limit}건</span>
						</span>
					</Usage>
				)}
				{(() => {
					const { form, isAll, ...rest } = values;
					const restKeys = Object.keys(rest) as Array<keyof MultipleMessageType>;
					let isValid = false;
					for (const key of restKeys) {
						if (isValid) break;
						if (isValidCheck(key, (rest as MultipleMessageType)[key])) {
							isValid = true;
						}
					}
					if (isValid) {
						return (
							<Button
								buttonType="LINE"
								style={{ border: 'none' }}
								onClick={() => {
									dispatch('RESET');
								}}
							>
								<Icon id={IconID.ROTATE_RIGHT} width="1.6rem" height="1.6rem" defaultColor="primary_600" />
								초기화
							</Button>
						);
					}
				})()}
				<Button
					style={{ width: '20rem' }}
					onClick={onSubmit}
					disabled={
						(Boolean(values.message.trim().length <= 0 || !values.isAll) &&
							Boolean(values.message.trim().length <= 0 || targetData.count === 0)) ||
						(isSuccess && usage.count >= usage.limit)
					}
				>
					전송하기
				</Button>
			</Footer>
		</Container>
	);
};

const Usage = styled.div`
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.6rem;
	${({ theme }) => theme.font.body.body_1};
	font-weight: 500;
	.usage {
		display: flex;
		align-items: center;
		&--count {
			color: ${({ theme }) => theme.colors.primary.primary_600};
			font-weight: 700;
		}
		&--limit {
			font-weight: 700;
		}
	}
`;

const SelectedItemsLabel = styled.label`
	${({ theme }) => theme.font.body.body_2};
	color: ${({ theme }) => theme.colors.gray.gray_700};
	font-weight: 500;
	padding: 0.4rem 0;
`;

const SelectedItemsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 0.8rem;
	flex-wrap: wrap;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
	height: 100%;
	flex: 1 1 0;
	.top {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
		.empty {
			${({ theme }) => theme.font.label.label_2};
			line-height: 3.6rem;
			color: ${({ theme }) => theme.colors.gray.gray_600};
		}
	}
	.bottom {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
`;

const Wrapper = styled.div`
	position: relative;
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	.announcement {
		padding: 1.2rem 1.6rem;
		background-color: ${({ theme }) => theme.colors.gray.gray_100};
		border-radius: 8px;
		&--item {
			${({ theme }) => theme.font.body.body_2};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.gray.gray_800};
			&::before {
				content: '- ';
			}
		}
	}
`;

export default Multiple;
