export type TokenType = 'string' | 'url' | 'undetermined';

export interface Token {
	type: TokenType;
	value: string;
}

export function identifyUrlsAndStrings(token: Token[]): Token[] {
	const URL_REG =
		/(?:https?:\/\/|www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.(xn--)?[a-z]{2,20}\b([-a-zA-Z0-9@:%_+[\],.~#?&/=]*[-a-zA-Z0-9@:%_+~#?&/=])*/g;
	const results: Token[] = token
		.map((token) => {
			if (token.type !== 'undetermined') {
				return token;
			}
			const { value = '' } = token;

			const matches = Array.from(value.matchAll(URL_REG));
			const founds = matches.map((value) => {
				const text = value[0];
				const start = value.index ?? 0;
				const end = start + text.length;
				return { text, start, end };
			});

			const items: Token[] = [{ value, type: 'string' }];
			let cursor = 0;
			founds.forEach(({ text, start, end }) => {
				const restText = items.pop()!.value;
				const head = restText.slice(0, start - cursor);
				const mid = text;
				const tail = restText.slice(end - cursor);

				if (head.length > 0) items.push({ value: head, type: 'string' });
				items.push({ value: mid, type: 'url' });
				if (tail.length > 0) items.push({ value: tail, type: 'string' });
				cursor = end;
			});

			return items;
		})
		.flat();

	return results;
}

export function combineNearbyStrings(tokens: Token[]): Token[] {
	const results: Token[] = tokens.reduce<Token[]>((acc, token) => {
		const lastToken = acc[acc.length - 1];
		if (lastToken?.type === 'string' && token.type === 'string') {
			lastToken.value = `${lastToken.value}${token.value}`;
			return acc;
		}
		return [...acc, token];
	}, []);
	return results;
}

export function tokenizeMessage(messageText: string) {
	const partialResult: Token[] = [
		{
			type: 'undetermined',
			value: messageText,
		},
	];

	const partialsWithUrls = identifyUrlsAndStrings(partialResult);

	return combineNearbyStrings(partialsWithUrls);
}
