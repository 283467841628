import { type TagsColorType } from '../components/Display/Tags/types';
import { type GroupCategory } from '../services/groups/types';
import { type PromotionStatus } from '../services/promotion/types';
import { type IsPayment, type Gender, type Age } from '../services/types';
import { type UserType } from '../services/users/types';

export const SCROLL_BUFFER = 10 as const;

export interface DefaultOption<T> {
	key?: T;
	label?: string;
}

export const USER_TYPE_VALUES: Record<UserType, string> = {
	MASTER: '대표약사',
	PARTNER: '근무약사',
	NORMAL: '일반직원',
} as const;

export const USER_TYPE_OPTIONS: Array<DefaultOption<UserType>> = [
	{
		key: 'MASTER',
		label: USER_TYPE_VALUES.MASTER,
	},
	{
		key: 'PARTNER',
		label: USER_TYPE_VALUES.PARTNER,
	},
	{
		key: 'NORMAL',
		label: USER_TYPE_VALUES.NORMAL,
	},
] as const;

export const GROUP_CATEGORY_VALUES: Record<GroupCategory, string> = {
	ALL: '전체',
	DISEASE: '질환',
	NORMAL: '일반',
} as const;

export const GROUP_CATEGORY_OPTIONS: Array<DefaultOption<GroupCategory>> = [
	{ key: 'ALL', label: GROUP_CATEGORY_VALUES.ALL },
	{ key: 'DISEASE', label: GROUP_CATEGORY_VALUES.DISEASE },
	{ key: 'NORMAL', label: GROUP_CATEGORY_VALUES.NORMAL },
] as const;

export const GENDER_VALUES: Record<Gender, string> = {
	ALL: '전체',
	F: '여자',
	M: '남자',
} as const;

export const GENDER_OPTIONS: Array<DefaultOption<Gender>> = [
	{ key: 'ALL', label: GENDER_VALUES.ALL },
	{ key: 'F', label: GENDER_VALUES.F },
	{ key: 'M', label: GENDER_VALUES.M },
] as const;

export const IS_PAYMENT_OPTIONS: Array<DefaultOption<IsPayment>> = [
	{ key: 'ALL', label: '전체' },
	{ key: 'true', label: '결제' },
	{ key: 'false', label: '미결제' },
] as const;

export const AGE_OPTIONS: Array<DefaultOption<Age>> = [
	{ key: 'ALL', label: '전체' },
	{ key: '0-9', label: '0~9세' },
	{ key: '10-19', label: '10~19세' },
	{ key: '20-29', label: '20~29세' },
	{ key: '30-39', label: '30~39세' },
	{ key: '40-49', label: '40~49세' },
	{ key: '50-59', label: '50~59세' },
	{ key: '60-69', label: '60~69세' },
	{ key: '70-', label: '70~' },
] as const;

export type MedicationType = 'OTC' | 'ETC' | 'ALL' | 'PROMOTION' | 'PHARM_SALE';

export const MEDICATION_VALUES: Record<MedicationType, string> = {
	ALL: '전체',
	OTC: '일반판매상품',
	ETC: '처방의약품(ETC)',
	PROMOTION: '프로모션',
	PHARM_SALE: '약국 이벤트',
};

export const DEFAULT_MEDICATION_OPTIONS: Array<DefaultOption<MedicationType>> = [
	{ key: 'ETC', label: MEDICATION_VALUES.ETC },
	{ key: 'OTC', label: MEDICATION_VALUES.OTC },
] as const;

export const MEDICATION_OPTIONS: Array<DefaultOption<MedicationType>> = [
	{ key: 'ALL', label: MEDICATION_VALUES.ALL },
	...DEFAULT_MEDICATION_OPTIONS,
	{ key: 'PROMOTION', label: MEDICATION_VALUES.PROMOTION },
	{ key: 'PHARM_SALE', label: MEDICATION_VALUES.PHARM_SALE },
] as const;

export type PayType =
	| 'ALL'
	| 'CASH'
	| 'CARD'
	| 'EASY_CARD'
	| 'KAKAO_PAY'
	| 'APPLE_PAY'
	| 'NAVER_PAY_BANK'
	| 'NAVER_PAY_CARD'
	| 'PAYAPP_CARD'
	| 'PAY_ZERO'
	| 'ONLY_POINT';

export const PAY_TYPE_VALUES: Record<PayType, string> = {
	ALL: '전체',
	CASH: '현장 결제',
	CARD: '카드',
	EASY_CARD: '간편 카드 결제',
	KAKAO_PAY: '카카오페이',
	APPLE_PAY: '애플페이',
	NAVER_PAY_BANK: '네이버 페이 (이체)',
	NAVER_PAY_CARD: '네이버 페이 (카드)',
	PAYAPP_CARD: '카드 (페이앱)',
	PAY_ZERO: '0원 결제',
	ONLY_POINT: '포인트 결제',
} as const;

export const PAY_TYPE_OPTIONS: Array<DefaultOption<PayType>> = [
	{ key: 'ALL', label: PAY_TYPE_VALUES.ALL },
	{ key: 'CASH', label: PAY_TYPE_VALUES.CASH },
	{ key: 'CARD', label: PAY_TYPE_VALUES.CARD },
	{ key: 'EASY_CARD', label: PAY_TYPE_VALUES.EASY_CARD },
	{ key: 'KAKAO_PAY', label: PAY_TYPE_VALUES.KAKAO_PAY },
	{ key: 'APPLE_PAY', label: PAY_TYPE_VALUES.APPLE_PAY },
	{ key: 'NAVER_PAY_BANK', label: PAY_TYPE_VALUES.NAVER_PAY_BANK },
	{ key: 'NAVER_PAY_CARD', label: PAY_TYPE_VALUES.NAVER_PAY_CARD },
	{ key: 'PAYAPP_CARD', label: PAY_TYPE_VALUES.PAYAPP_CARD },
	{ key: 'PAY_ZERO', label: PAY_TYPE_VALUES.PAY_ZERO },
	{ key: 'ONLY_POINT', label: PAY_TYPE_VALUES.ONLY_POINT },
] as const;

export type IsApprovalType = 'ALL' | 'true' | 'false';

export const IS_APPROVAL_VALUES: Record<IsApprovalType, string> = {
	ALL: '전체',
	true: '승인 완료',
	false: '승인 취소',
};

export const IS_APPROVAL_OPTIONS: Array<DefaultOption<IsApprovalType>> = [
	{ key: 'ALL', label: IS_APPROVAL_VALUES.ALL },
	{ key: 'true', label: IS_APPROVAL_VALUES.true },
	{ key: 'false', label: IS_APPROVAL_VALUES.false },
] as const;

/**
 * ARRANGE_INSTALLLATION 는 백엔드랑 광동에 원래 오타, 수정 금지
 */
export type ServiceState =
	| 'APPROVAL'
	| 'DEMO'
	| 'COMPLETE_INSTALLATION'
	| 'EXPECT_INSTALLATION'
	| 'ARRANGE_INSTALLLATION'
	| 'SALES_REQUIRED'
	| 'PENDING_INSTALLATION'
	| 'REQUEST'
	| 'CANCEL'
	| 'TEST';

// PG  가입, 계약 진행 없음
export const SERVICE_STATE_VALUES: Record<ServiceState, string> = {
	APPROVAL: '서비스중(결제 완료)',
	DEMO: '데모(광동 데모용)',
	COMPLETE_INSTALLATION: '설치 완료',
	EXPECT_INSTALLATION: '설치 예정',
	ARRANGE_INSTALLLATION: '설치일자 조율',
	SALES_REQUIRED: '2차 영업 필요',
	PENDING_INSTALLATION: '설치 보류',
	REQUEST: '신청',
	CANCEL: '해지',
	TEST: '테스트(굿팜 내부용)',
} as const;

export type ServiceType = 'FULL_PACKAGE' | 'MOBILE_PHARMACY' | 'MOBILE_BARCODE_FREE';

export const SERVICE_TYPE_VALUES: Record<ServiceType, string> = {
	FULL_PACKAGE: '풀패키지',
	MOBILE_PHARMACY: '모바일 약국',
	MOBILE_BARCODE_FREE: '모바일 바코드 프리',
} as const;

export const PROMOTION_STATUS_VALUES: Record<PromotionStatus, string> = {
	READY: '준비중',
	WAIT: '진행예정',
	RUNNING: '진행중',
	COMPLETED: '종료',
} as const;

export const PROMOTION_STATUS_TAG_COLOR: Record<PromotionStatus, TagsColorType> = {
	READY: 'YELLOW',
	WAIT: 'BLUE',
	RUNNING: 'PRIMARY',
	COMPLETED: 'GRAY',
} as const;

export type IsFavorite = 'true' | 'false' | 'ALL';

export const IS_FAVORITE_VALUES: Record<IsFavorite, string> = {
	ALL: '전체',
	true: '활성',
	false: '비활성',
} as const;

export const isFavoriteOptions: Array<DefaultOption<IsFavorite>> = [
	{ key: 'ALL', label: IS_FAVORITE_VALUES.ALL },
	{ key: 'true', label: IS_FAVORITE_VALUES.true },
	{ key: 'false', label: IS_FAVORITE_VALUES.false },
] as const;
