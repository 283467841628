import React from 'react';

import styled from 'styled-components';

import { Flex } from '../../../../../components/Common';
import { Icon, IconID, Label } from '../../../../../components/Display';
import { DELETED_MESSAGE } from '../../../../../pages/CRM/Chat/consts';

interface DeletedMessageBodyProps {
	isByMe: boolean;
}
export const DeletedMessageBody = ({ isByMe }: DeletedMessageBodyProps) => {
	return (
		<DeletedMessageItem $isByMe={isByMe}>
			<Icon id={IconID.MARK_ALERT} width={'2rem'} height={'2rem'} defaultColor={isByMe ? 'primary_700' : 'gray_200'} />
			<Label $color={'gray_500'} $fontStyle={'body_2'}>
				{DELETED_MESSAGE}
			</Label>
		</DeletedMessageItem>
	);
};

const DeletedMessageItem = styled(Flex)<{ $isByMe?: boolean }>`
	max-width: 26.4rem;
	padding: 1rem 1.2rem;
	justify-content: center;
	align-items: center;
	white-space: break-spaces;
	gap: 0.4rem;

	border-radius: ${({ $isByMe }) => ($isByMe ? `12px 2px 12px 12px` : `2px 12px 12px 12px`)};
	background-color: ${({ theme, $isByMe }) =>
		$isByMe ? theme.colors.primary.primary_700 : theme.colors.gray.gray_200};
`;
