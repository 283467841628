import React, { useContext, useEffect, useRef, useState } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';

import { useSBStateContext } from '../../../context/sendbirdSdk';
import { useGetSearchMessages } from '../hooks/useGetSearchMessages';
import { useSearchStringEffect } from '../hooks/useSearchStringEffect';

import type { UserMessage } from '@sendbird/chat/message';

export type SearchMessageDataSource = ReturnType<typeof useGetSearchMessages>;

export interface MessageSearchProviderProps {
	channelUrl: string;
	currentChannel: GroupChannel | null;
	onSearchMessageClick: (message: UserMessage) => void;

	children?: React.ReactNode;
}

interface MessageSearchContextType extends MessageSearchProviderProps, SearchMessageDataSource {
	selectedMessageId: number;

	searchString: string;
	setSearchString: React.Dispatch<React.SetStateAction<string>>;

	scrollRef: React.MutableRefObject<HTMLDivElement>;
	requestString: string;

	activeSearch: { searching: boolean; resulting: boolean };
	onSearchStart: () => void;
	onSearchCancel: () => void;
	onSearchResult: () => void;
}

const MessageSearchContext = React.createContext<MessageSearchContextType | null>(null);

export const MessageSearchProvider = (props: MessageSearchProviderProps) => {
	const {
		// message search props
		channelUrl,
		currentChannel,
		onSearchMessageClick,
		children,
	} = props;

	const { stores } = useSBStateContext();
	const { sdkStore } = stores;
	const [searchString, setSearchString] = useState('');

	const [selectedMessageId, setSelectedMessageId] = useState(0);

	const scrollRef = useRef<HTMLDivElement>(null);

	const requestString = useSearchStringEffect(searchString);

	const messageDataSource = useGetSearchMessages({
		sdk: sdkStore.sdk,
		currentChannel,
		channelUrl,
		requestString,
	});

	/**
	 * searching: 검색값을 입력중일 때, 검색된 메시지 리스트 모달이 뜬 상태
	 * resulting: 검색된 메시지를 탐색 중일때, 모달 닫고 해당 메시지로 이동된 후
	 */
	const [activeSearch, setActiveSearch] = useState<{ searching: boolean; resulting: boolean }>({
		searching: false,
		resulting: false,
	});

	useEffect(() => {
		if (searchString) {
			setSearchString('');
		}
		if (activeSearch.searching) {
			setActiveSearch({ searching: false, resulting: false });
		}
	}, [channelUrl]);

	useEffect(() => {
		if (selectedMessageId) {
			setSelectedMessageId(0);
		}
	}, [searchString]);

	const contextValue = {
		onSearchStart: () => {
			setActiveSearch({ searching: true, resulting: false });
		},
		onSearchCancel: () => {
			setActiveSearch({ searching: false, resulting: false });
			setSearchString('');
		},
		onSearchResult: () => {
			setActiveSearch({ searching: true, resulting: true });
		},
		onSearchMessageClick: (message: UserMessage) => {
			onSearchMessageClick(message);
			setSelectedMessageId(message.messageId);
		},
	};

	return (
		<MessageSearchContext.Provider
			value={{
				channelUrl,
				currentChannel,
				searchString,
				setSearchString,
				requestString,
				selectedMessageId,
				...contextValue,
				scrollRef: scrollRef as React.MutableRefObject<HTMLDivElement>,
				activeSearch,
				...messageDataSource,
			}}
		>
			{children}
		</MessageSearchContext.Provider>
	);
};

export const useMessageSearchContext = () => {
	const context = useContext(MessageSearchContext);
	if (!context) {
		throw new Error('MessageSearchContext not found. Use within the MessageSearch module');
	}
	return context;
};
