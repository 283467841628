import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { removeToken, setToken } from '../../utils/handleToken';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache, no-store, must-revalidate',
		Pragma: 'no-cache',
		Expires: 0,
	},
});

let cancelTokenSource = axios.CancelToken.source();

// request interceptors
instance.interceptors.request.use(
	(config) => {
		const newConfig = { ...config, cancelToken: cancelTokenSource.token };
		// token auto push
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			newConfig.headers.Authorization = 'Bearer ' + accessToken;
		}

		return newConfig;
	},
	async (err) => {
		return await Promise.reject(err);
	},
);

// response interceptor
instance.interceptors.response.use(
	(res: AxiosResponse<any>) => {
		return res;
	},
	async (err: AxiosError) => {
		const { config, response } = err;
		switch (response?.status) {
			case 401:
				if (config?.url === '/token/refresh') {
					cancelTokenSource.cancel('Session refresh failed, cancelling all requests.');

					cancelTokenSource = axios.CancelToken.source();
					removeToken();
					toast.error('인증 자격이 없습니다.');
					break;
				} else {
					const originRequest = config;
					const originRefreshToken = localStorage.getItem('refreshToken');
					if (!originRefreshToken) break;

					const { data } = await instance.post('/token/refresh', { refreshToken: originRefreshToken });

					const { accessToken, refreshToken } = data;
					if (accessToken) {
						setToken(accessToken, refreshToken);
						if (accessToken && originRequest) {
							originRequest.headers.Authorization = 'Bearer ' + accessToken;
							return await axios(originRequest);
						}
					}
				}
				break;
			case 409:
				toast.error('현재 너무 많은 요청이 발생하고 있습니다.\n잠시 후에 다시 시도해 주십시오.');
				break;
			default:
				break;
		}
		return await Promise.reject(err);
	},
);

export default instance;
