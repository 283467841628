import React, { useCallback } from 'react';

import { type AxiosError } from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button, SwitchButton } from '../../../../components/Buttons';
import { HStack, Loading, VStack } from '../../../../components/Common';
import { AssetsImage, Label } from '../../../../components/Display';
import { Select, TextArea, TextField } from '../../../../components/Forms';
import { useValues } from '../../../../hooks/useValues';
import { type Asset } from '../../../../services/asset/types';
import {
	useDeleteNotificationConfigActive,
	usePatchNotificationConfigActive,
	usePutNotificationConfig,
} from '../../../../services/notification-configs/queries';
import {
	type TimeUnit,
	type NotificationConfig,
	type TriggerState,
} from '../../../../services/notification-configs/types';
import { type ErrorData } from '../../../../services/types';
import { MAX_LENGTH, STATUS_MESSAGE, TEXTAREA_PLACEHOLDER, TIME_UNIT, TRIGGER_STATE } from '../const';

interface AutoMessageItemProps {
	data: NotificationConfig;
	onSuccess: () => void;
}
export const AutoMessageItem = ({ data, onSuccess }: AutoMessageItemProps) => {
	const { values, onChangeValues, dispatch } = useValues<NotificationConfig>({ ...data });

	const { mutate, isPending } = usePutNotificationConfig();
	const { mutate: deleteMutate, isPending: isDeletePending } = useDeleteNotificationConfigActive();
	const { mutate: patchMutate, isPending: isPatchPending } = usePatchNotificationConfigActive();
	const onError = useCallback(
		(err: AxiosError<ErrorData>) => toast.error(STATUS_MESSAGE.ERROR + err.response?.data.message),
		[],
	);
	const handleActive = useCallback(
		(isActive: boolean) => {
			const { storeId, id } = data;
			if (isActive)
				patchMutate(
					{ storeId, id },
					{
						onSuccess,
						onError,
					},
				);
			else
				deleteMutate(
					{ storeId, id },
					{
						onSuccess,
						onError,
					},
				);
		},
		[data],
	);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const { createdAt, updatedAt, asset, isActive, ...putParams } = values;
			mutate(
				{ ...putParams, isActive: data.isActive },
				{
					onSuccess: (res) => {
						toast.success(STATUS_MESSAGE.SUCCESS);
						dispatch('SET', res);
					},
					onError,
				},
			);
		},
		[values, data],
	);

	return (
		<Wrapper {...{ onSubmit }}>
			{(isPending || isDeletePending || isPatchPending) && <Loading />}
			<HStack $alignItems="center" $justify="between" $gap="1.2rem">
				<h5>{data.title}</h5>
				{data.triggerState !== 'IMMEDIATE' && (
					<SwitchButton
						isOn={data.isActive}
						toggleSwitch={(isActive) => {
							handleActive(isActive);
						}}
					/>
				)}
			</HStack>
			<AssetsImage
				assets={data.defaultImage ? ({ url: data.defaultImage } as Asset) : data.asset}
				$width="100%"
				$aspectRatio="16/8"
			/>
			<VStack className="form" $gap="1.2rem">
				{data.triggerState !== 'IMMEDIATE' && (
					<VStack $gap="0.6rem">
						<Label $fontStyle="label_2" $fontWeight="medium" $color="gray_700">
							알림 조건
						</Label>

						{(() => {
							switch (data.conditionType) {
								case 'TIME_INTERVAL':
									return (
										<HStack $gap="0.6rem">
											<TextField
												name="timeInterval"
												type="number"
												value={values.timeInterval}
												onChange={onChangeValues}
												style={{ flex: 1, height: '3.6rem' }}
											/>
											<Select
												options={TIME_UNIT}
												value={values.timeUnit}
												onClick={(value) => {
													dispatch('SET', { timeUnit: value as TimeUnit });
												}}
												labelStyle={{ width: '6rem', height: '3.6rem' }}
											/>
											<Select
												options={TRIGGER_STATE}
												value={values.triggerState}
												onClick={(value) => {
													dispatch('SET', { triggerState: value as TriggerState });
												}}
												labelStyle={{ width: '6rem', height: '3.6rem' }}
												disabled
											/>
										</HStack>
									);
								case 'RELATIVE_DATE':
									return <></>;
							}
						})()}
					</VStack>
				)}
				<TextArea
					name={'template'}
					count={{ max: MAX_LENGTH, show: true }}
					textAreaStyle={{
						resize: 'vertical',
						minHeight: '6.4rem',
						maxHeight: '30rem',
					}}
					style={{ flex: 1, justifyContent: 'flex-start' }}
					placeholder={TEXTAREA_PLACEHOLDER}
					value={values.template}
					maxLength={MAX_LENGTH}
					onChange={onChangeValues}
				/>
			</VStack>
			<Button type="submit" style={{ marginTop: 'auto' }}>
				메시지 저장
			</Button>
		</Wrapper>
	);
};
const Wrapper = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;
	position: relative;
	h5 {
		${({ theme }) => theme.font.title.title_2};
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.form {
		min-height: 19rem;
		flex: 1;
	}
`;
