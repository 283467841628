import React, { useRef, useState } from 'react';

import { ko } from 'date-fns/locale/ko';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import styled, { type CSSProperties } from 'styled-components';

import { CustomHeader } from './CustomHeader';
import { IconButton } from '../../components/Buttons';
import { Icon } from '../../components/Display';
import { TextField } from '../../components/Forms';

interface CustomDatePickerProps extends ReactDatePickerProps {
	style?: CSSProperties;
}

export const CustomDatePicker = ({
	customInput,
	showMonthYearPicker,
	renderCustomHeader,
	style,
	...defaultProps
}: CustomDatePickerProps) => {
	const ref = useRef<ReactDatePicker>(null);
	const [isOpen, setIsOpen] = useState(false);

	const handleIconClick = () => {
		if (ref.current) {
			ref.current.setOpen(!isOpen);
		}
	};

	return (
		<Container style={style}>
			<ReactDatePicker
				ref={ref}
				customInput={
					customInput ?? (
						<TextField
							value={''}
							readOnly
							renderSuffix={
								<IconButton
									onClick={handleIconClick}
									className={isOpen ? 'react-datepicker-ignore-onclickoutside' : ''}
									width="2rem"
									height="2rem"
								>
									<Icon id="calender" width="2rem" height="2rem" />
								</IconButton>
							}
						/>
					)
				}
				renderCustomHeader={(param) =>
					renderCustomHeader ? (
						renderCustomHeader?.(param)
					) : (
						<CustomHeader param={param} type={showMonthYearPicker ? 'MONTH' : 'DATE'} />
					)
				}
				dateFormat={'yyyy-MM-dd'}
				locale={ko}
				showMonthYearPicker={showMonthYearPicker}
				onCalendarOpen={() => {
					setIsOpen(true);
				}}
				onCalendarClose={() => {
					setIsOpen(false);
				}}
				{...defaultProps}
			/>
		</Container>
	);
};

const Container = styled.div`
	.react-datepicker {
		width: 25.6rem;
		box-shadow: ${({ theme }) => theme.shadow.modal};
		border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		padding-bottom: 2rem;
		&.react-datepicker--time-only {
			width: 8.5rem;
			padding-bottom: 0;
			.react-datepicker__aria-live {
				display: none;
			}
		}
	}
	.react-datepicker__tab-loop {
		position: absolute;
	}
	.react-datepicker__triangle {
		display: none;
	}
	.react-datepicker__header {
		background-color: transparent;
		border: none;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-bottom: 1.6rem;
		padding: 0;
	}

	.react-datepicker__month {
		margin: 0;
		display: flex;
		flex-direction: column;
		/* width: 25.6rem; */
		gap: 0.6rem;
		&-container {
			width: 100%;
		}
		&-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 1.6rem;
		}
		&-text {
			width: 3.6rem;
			margin: 0;
			padding: 0.9rem 0.4rem;
			border-radius: 999px;
			${({ theme }) => theme.font.label.label_2};
			&:hover {
				background-color: ${({ theme }) => theme.colors.gray.gray_100};
			}
			&--keyboard-selected,
			&--selected {
				color: ${({ theme }) => theme.colors.white};
				background-color: ${({ theme }) => theme.colors.primary.primary_600};
				font-weight: 400;
				&:hover {
					background-color: ${({ theme }) => theme.colors.primary.primary_600};
				}
			}
			&--disabled {
				color: ${({ theme }) => theme.colors.gray.gray_600};
				pointer-events: none;
			}
		}
	}
	.react-datepicker__week {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 1.6rem;
	}
	.react-datepicker__day {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.8rem;
		height: 2.8rem;
		border-radius: 999px;
		padding: 0.9rem 0;
		margin: 0;

		${({ theme }) => theme.font.label.label_2};
		font-weight: 400;
		color: ${({ theme }) => theme.colors.black};

		&:hover {
			background-color: ${({ theme }) => theme.colors.gray.gray_100};
		}

		&-names {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 1.6rem;
			margin-bottom: 0;
		}
		&-name {
			width: 2.8rem;
			height: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			${({ theme }) => theme.font.body.body_2};
			color: ${({ theme }) => theme.colors.black};
			margin: 0;
			&:first-child {
				color: ${({ theme }) => theme.colors.red.red_600};
			}
		}

		&--disabled {
			color: ${({ theme }) => theme.colors.gray.gray_600};
			&:hover {
				background-color: transparent;
			}
		}

		&--outside-month {
			color: ${({ theme }) => theme.colors.gray.gray_600};
		}

		&--today {
			color: ${({ theme }) => theme.colors.primary.primary_600};
		}

		&--keyboard-selected,
		&--selected,
		&--in-selecting-range {
			color: ${({ theme }) => theme.colors.white};
			background-color: ${({ theme }) => theme.colors.primary.primary_600};
			font-weight: 400;
			&:hover {
				background-color: ${({ theme }) => theme.colors.primary.primary_600};
			}
		}
	}
`;
