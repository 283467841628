import { useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
	type PromotionPK,
	type GetPromotionsReq,
	type UserPK,
	type GetPromotionCalculatesReq,
	type PatchPromotionDeliveryParams,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { useValues } from '../../hooks/useValues';
import { formatMonth } from '../../utils/format';

import type { MutateCallback } from '../../hooks/types';

export const promotionsKeys = {
	all: ['promotions'] as const,
	promotions: ({ curPage, listCount }: PaginationQueries, values: GetPromotionsReq) => [
		...promotionsKeys.all,
		'list',
		curPage,
		listCount,
		values,
	],
	promotion: ({ id }: PromotionPK) => [...promotionsKeys.all, 'detail', id],
	promotionCalculates: ({ curPage, listCount }: PaginationQueries, values: GetPromotionCalculatesReq) => [
		...promotionsKeys.all,
		'payment--list',
		curPage,
		listCount,
		values,
	],
} as const;

const GET_PROMOTIONS_INITIAL_VALUES: GetPromotionsReq = {
	statuses: undefined,
	storeId: '',
	sorts: {
		direction: 'ASC',
		property: 'startDate',
	},
} as const;

export const useGetPromotions = (params: UserPK) => {
	const queries = useValues({ ...GET_PROMOTIONS_INITIAL_VALUES, storeId: params.storeId });
	const pagination = usePagination();
	const { curPage, listCount, setPagination } = pagination;
	const queryInfo = useQuery({
		queryKey: promotionsKeys.promotions({ curPage, listCount }, queries.values),
		queryFn: async () =>
			await api.promotions.getPromotions({
				page: curPage,
				limit: listCount,
				...queries.values,
			}),
		enabled: !!params.storeId,
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const useGetPromotion = (params: PromotionPK) =>
	useQuery({
		queryKey: promotionsKeys.promotion(params),
		queryFn: async () => await api.promotions.getPromotion(params),
		enabled: !!params.id,
	});

const GET_PROMOTION_CALCULATES_INITIAL_VALUES: GetPromotionCalculatesReq = {
	storeId: '',
	period: {
		start: new Date(),
		end: new Date(),
	},
} as const;

export const useGetPromotionCalculates = (params: UserPK) => {
	const period = useMemo(() => formatMonth(new Date()), []);
	const pagination = usePagination({ initListCount: 1000, historyKey: 'getPromotionCalculatesPage' });
	const { curPage, listCount, setPagination } = pagination;
	const queries = useValues<GetPromotionCalculatesReq>(
		{ ...GET_PROMOTION_CALCULATES_INITIAL_VALUES, period, storeId: params.storeId },
		'getPromotionCalculates',
	);

	const queryInfo = useQuery({
		queryKey: promotionsKeys.promotionCalculates({ curPage, listCount }, queries.values),
		queryFn: async () =>
			await api.promotions.getPromotionCalculates({
				page: curPage,
				limit: listCount,
				...queries.values,
			}),
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, queries, pagination };
};

export const usePatchPromotionDelivery = (props?: MutateCallback<PatchPromotionDeliveryParams, any>) => {
	const { onSuccess, onError } = props ?? {};
	return useMutation({
		mutationFn: async (params: PatchPromotionDeliveryParams) => await api.promotions.patchPromotionDelivery(params),
		onSuccess,
		onError,
	});
};
