import { useEffect, useRef } from 'react';

import {
	type GroupChannel,
	type GroupChannelCollection,
	type GroupChannelCollectionParams,
	GroupChannelFilter,
	type GroupChannelFilterParams,
	GroupChannelListOrder,
	type SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
// import { MessageTypeFilter } from '@sendbird/chat/message';

import { useChannelHandler } from './useChannelHandler';
import { useAsyncEffect } from '../../../hooks/useAsyncEffect';
import { usePreservedCallback } from '../../../hooks/usePreservedCallback';
import { useChannelListReducer } from '../reducer';

export const basicParamsConstants = {
	collection: {
		groupChannel: {
			defaultLimit: 20,
			defaultOrder: GroupChannelListOrder.LATEST_LAST_MESSAGE,
			defaultIncludeEmpty: true,
			defaultIncludeFrozen: false,
		},
		message: {
			defaultLimit: { next: 25, prev: 25 },
			searchLimit: { next: 10, prev: 10 },
			// defaultMessageTypeFilter: MessageTypeFilter.USER,
		},
	},
};

type CollectionCreatorBasicParams = Omit<GroupChannelCollectionParams, 'filter'> &
	Pick<GroupChannelFilterParams, 'includeEmpty' | 'includeFrozen' | 'customTypesFilter'>;

/**
 * - pharmacy_to_pharmacy (약국1 : 약국1)
 * - pharmacy_to_user (약국1 : 환자1)
 * - pharmacy_to_pharmacies (약국1 : 약국N 단체방)
 * - pharmacy_to_users (약국1 : 환자N 단체방) <- 아마 사용할 일이 없을듯...
 */
type CustomTypes = 'pharmacy_to_pharmacy' | 'pharmacy_to_user' | 'pharmacy_to_pharmacies' | 'pharmacy_to_users';
interface UseChannelListOptions {
	onChannelsAdded?: (channels: GroupChannel[]) => void;
	onChannelsUpdated?: (channels: GroupChannel[]) => void;
	onChannelsDeleted?: (channelUrls: string[]) => void;
}

const createChannelListCollection = (sdk: SendbirdGroupChat) => {
	const basicPrams: CollectionCreatorBasicParams = {
		includeEmpty: basicParamsConstants.collection.groupChannel.defaultIncludeEmpty,
		includeFrozen: basicParamsConstants.collection.groupChannel.defaultIncludeFrozen,
		limit: basicParamsConstants.collection.groupChannel.defaultLimit,
		order: basicParamsConstants.collection.groupChannel.defaultOrder,
		customTypesFilter: ['pharmacy_to_user'] as CustomTypes[],
	};

	return sdk.groupChannel.createGroupChannelCollection({
		...basicPrams,
		filter: new GroupChannelFilter(basicPrams),
	});
};
/**
 * @description 기본 채널리스트 fetch
 * local caching group channel collection 사용
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/local-caching/using-group-channel-collection/group-channel-collection}
 */
export const useChannelList = (sdk: SendbirdGroupChat, options?: UseChannelListOptions) => {
	const collectionRef = useRef<GroupChannelCollection>();

	// update reference for event handler
	const internalOptions = useRef(options);
	internalOptions.current = options;

	const {
		initialized,
		groupChannels,
		refreshing,
		appendChannels,
		updateChannels,
		deleteChannels,
		updateRefreshing,
		updateInitialized,
	} = useChannelListReducer();

	const init = usePreservedCallback(async (uid?: string) => {
		if (collectionRef?.current) collectionRef?.current?.dispose();

		if (uid) {
			collectionRef.current = createChannelListCollection(sdk);

			collectionRef.current?.setGroupChannelCollectionHandler({
				onChannelsAdded: (_, channels) => {
					const groupChannels = channels.filter((channel): channel is GroupChannel => channel.isGroupChannel());
					updateChannels(groupChannels);
					internalOptions.current?.onChannelsAdded?.(groupChannels);
				},
				onChannelsUpdated: (_, channels) => {
					const groupChannels = channels.filter((channel): channel is GroupChannel => channel.isGroupChannel());
					updateChannels(groupChannels);
					internalOptions.current?.onChannelsUpdated?.(groupChannels);
				},
			});

			if (collectionRef.current?.hasMore) {
				await collectionRef.current?.loadMore();
				appendChannels(collectionRef.current?.channels ?? [], false);
			}
		}
	});

	useEffect(() => {
		return () => {
			if (collectionRef.current) collectionRef.current?.dispose();
		};
	}, []);

	useAsyncEffect(async () => {
		updateInitialized(false);
		if (sdk && sdk.currentUser) {
			await init(sdk.currentUser.userId);
			updateInitialized(true);
		}
	}, [sdk, sdk.currentUser]);

	useChannelHandler(sdk, {
		onChannelFrozen: (channel) => {
			deleteChannels([channel.url]);
		},
		onChannelUnfrozen: (channel) => {
			if (channel.isGroupChannel()) {
				appendChannels([channel], false);
			}
		},
	});

	const refresh = usePreservedCallback(async () => {
		if (sdk.currentUser) {
			updateRefreshing(true);
			await init(sdk.currentUser.userId);
			updateRefreshing(false);
		}
	});

	const loadMore = usePreservedCallback(async () => {
		if (collectionRef.current?.hasMore) {
			await collectionRef.current?.loadMore();
			appendChannels(collectionRef.current?.channels ?? [], false);
		}
	});

	return {
		/**
		 * Initializing state, only available on first render
		 * */
		initialized,

		/**
		 * Get group channels state
		 * */
		groupChannels,

		/**
		 * Refresh, clear and reload messages from latest
		 * @return {Promise<void>}
		 * */
		refresh,

		/**
		 * Refreshing state, status is changes when the refresh is called.
		 * */
		refreshing,

		/**
		 * Fetch more channels to state
		 * @return {Promise<void>}
		 * */
		loadMore,
	};
};
