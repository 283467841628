import React from 'react';

import { ellipsis1 } from '../../../../assets/styles/ellipsis';
import { IconButton } from '../../../../components/Buttons';
import { type ButtonColorType } from '../../../../components/Buttons/Button/types';
import { AssetsImage, Icon, IconID, Label } from '../../../../components/Display';
import { Checkbox, TextField } from '../../../../components/Forms';
import { type Columns } from '../../../../components/Table/ListTable/types';
import { type SalesStatus, type PutSalesItem, type SalesProduct } from '../../../../services/sales/types';
import { type DefaultOption } from '../../../../utils/consts';
import { commaizeNumber, decommaizeNumber, removeLeadingZero } from '../../../../utils/format';
import { isNumberString } from '../../../../utils/validators';
import { SALES_STATUS_VALUES } from '../const';

import { type HandleProduct } from '.';

export const FORM_KEY = 'sales__detail__form';

export const SALES_STATUS_OPTIONS: Array<DefaultOption<SalesStatus>> = [
	{ key: 'READY', label: SALES_STATUS_VALUES.READY },
	{ key: 'RUNNING', label: SALES_STATUS_VALUES.RUNNING },
	{ key: 'COMPLETED', label: SALES_STATUS_VALUES.COMPLETED },
] as const;

export const SALES_ACTION_MESSAGES = {
	STATUS: {
		READY: '해당 이벤트를 준비중 상태로 전환하시겠습니까?\n준비중 상태가 되면 해당 이벤트가 노출되지 않습니다.',
		RUNNING: (
			<>
				해당 이벤트를 진행중 상태로 전환하시겠습니까?
				<br />
				<Label $color="red_600" $fontStyle="body_3">
					진행상태 변경 시 저장하지 않은 정보는 반영되지 않습니다.
				</Label>
			</>
		),
		COMPLETED: '해당 이벤트를 종료 하시겠습니까?\n이벤트가 종료되면 요청받은 구매요청이 모두 거절됩니다.',
		SUCCESS: '이벤트 진행상태 변경에 성공하였습니다.',
	},
	POST: {
		CONFIRM: '이벤트를 등록하시겠습니까?',
		SUCCESS: '이벤트 등록에 성공하였습니다.',
	},
	PUT: {
		CONFIRM: '변경사항을 저장하시겠습니까?',
		SUCCESS: '이벤트 수정에 성공하였습니다.',
	},
	DELETE: {
		SUCCESS: '이벤트 삭제에 성공하였습니다.',
		ERROR: '이벤트 삭제에 실패하였습니다.',
		CONFIRM: '이벤트를 삭제하시겠습니까?\n결제 내역이 있는 이벤트는 삭제할 수 없습니다.',
	},
	COPY: {
		SUCCESS: '이벤트 복제에 성공하였습니다.',
		ERROR: '이벤트 복제에 실패하였습니다.',
		CONFIRM: '동일한 이벤트를 생성하시겠습니까?',
	},
	GET: {
		ERROR: '데이터를 불러오지 못했습니다.',
	},
} as const;

export const SALES_STATUS_BUTTON_COLOR: Record<SalesStatus, ButtonColorType> = {
	READY: 'ORANGE',
	RUNNING: 'GREEN',
	COMPLETED: 'BLACK',
} as const;

export const SALES_STATUS_INFO_MESSAGE: Record<SalesStatus, string> = {
	READY: '준비중 상태는 이벤트 타이틀, 배너, 상세 입력이 필수입니다.',
	RUNNING: '진행중 상태는 이벤트 타이틀, 배너, 상세 입력이 필수입니다.',
	COMPLETED: '이벤트 종료 시 다시 진행상태를 변경할 수 없습니다.',
} as const;

export interface TmpPutSalesItem extends PutSalesItem {
	contentFiles: File[];
	bannerFiles: File[];
}

export const INITIAL_VALUES: TmpPutSalesItem = {
	deleteContentAssetIds: [],
	products: [],
	contentAssetIds: [],
	bannerAssetId: undefined,
	title: '',
	storeId: '',
	id: -1,

	contentFiles: [],
	bannerFiles: [],
} as const;

export const VALIDATION_KEYS: Record<SalesStatus, Array<keyof Partial<TmpPutSalesItem>>> = {
	READY: ['bannerFiles', 'contentFiles', 'title'],
	RUNNING: ['bannerFiles', 'contentFiles', 'title'],
	COMPLETED: ['bannerFiles', 'contentFiles', 'title'],
} as const;

type ValidationStatusMessageKeys = 'title' | 'bannerFiles' | 'contentFiles' | 'products';

export const VALIDATION_STATUS_MESSAGE: Record<keyof Pick<TmpPutSalesItem, ValidationStatusMessageKeys>, string> = {
	title: '이벤트 타이틀을 입력해주세요.',
	bannerFiles: '이벤트 배너를 등록해주세요.',
	contentFiles: '이벤트 상세이미지를 등록해주세요.',
	products: '이벤트 상품을 등록해주세요.',
} as const;

export const PRODUCT_LIST_COLUMNS = (handleProduct: HandleProduct, status: SalesStatus): Columns<SalesProduct> =>
	[
		{
			key: 'productAsset',
			headerName: '상품 이미지',
			renderComponent: (value) => <AssetsImage assets={value} $width="4.6rem" />,
			style: { justifyContent: 'center' },
			width: { max: '12rem' },
		},

		{
			key: 'name',
			headerName: '상품명',
			renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
		},
		{
			key: 'price',
			headerName: '정가',
			width: { max: '20rem' },
			style: { justifyContent: 'flex-end' },
			renderComponent: (value) => value.toLocaleString() + '원',
		},
		{
			key: 'discountPrice',
			headerName: '할인 판매가',
			width: { max: '20rem' },
			style: { justifyContent: 'flex-end' },
			renderComponent: (value, item) => (
				<TextField
					name="discountPrice"
					value={value ?? 0}
					fontStyle={{ $textAlign: 'end' }}
					renderSuffix={
						<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_600'}>
							{'원'}
						</Label>
					}
					onBlur={(e) => {
						const { value } = e.target;
						handleProduct('DISCOUNT', item, commaizeNumber(value));
					}}
					onChange={(e) => {
						const { value } = e.target;
						let formattedValue = String(decommaizeNumber(value));
						if (isNumberString(formattedValue) || formattedValue === '')
							formattedValue = removeLeadingZero(formattedValue);
						if (Number(formattedValue) > item.price) formattedValue = item.price.toString();
						handleProduct('DISCOUNT', item, formattedValue);
					}}
					style={{ alignSelf: 'center' }}
					disabled={status !== 'READY'}
				/>
			),
		},
		{
			key: 'isUsable',
			headerName: '게시 여부',
			renderComponent: (value, item) => (
				<Checkbox
					$size={{
						width: '2.4rem',
					}}
					checked={value}
					onClick={() => {
						handleProduct('USE', item);
					}}
					disabled={status === 'COMPLETED'}
				/>
			),
			style: { justifyContent: 'center' },
			width: { max: '10rem' },
		},
		{
			key: 'id',
			headerName: '삭제',
			renderComponent: (_, item) => (
				<IconButton
					shouldPrevent={true}
					onClick={() => {
						handleProduct('DELETE', item);
					}}
					disabled={status !== 'READY'}
				>
					<Icon id={IconID.TRASH} isDisabled={status !== 'READY'} />
				</IconButton>
			),
			style: { justifyContent: 'center' },
			width: { max: '7rem' },
		},
	] as const;
