import React, { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Buttons';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<Container>
			<p className="number">404</p>
			<h2>Not Found</h2>
			<p>The resource requested could not be found on this server</p>
			<Button
				onClick={() => {
					navigate(-1);
				}}
			>
				Back
			</Button>
		</Container>
	);
};

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	padding: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1.6rem;
	.number {
		font-size: 12rem;
		line-height: 1;
		font-weight: bold;
	}
	h2 {
		font-size: 3rem;
	}
	p {
		text-align: center;
	}
`;

export default NotFound;
