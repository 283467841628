import React from 'react';

import { ImageRenderer } from '../../../../../../components/Display';
import { type StaticImageBlockType } from '../types';

interface StaticImageBlockProps {
	data: StaticImageBlockType;
}
const StaticImageBlock = ({ data }: StaticImageBlockProps) => {
	return <ImageRenderer url={data.url} alt={'static-image'} width={'100%'} height={'14rem'} fixedSize={true} />;
};

export default StaticImageBlock;
