import React from 'react';

import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { Tags } from '../../../components/Display/Tags';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type Payment } from '../../../services/payment/types';
import { type Search } from '../../../services/types';
import { PAY_TYPE_VALUES } from '../../../utils/consts';

export const SALES_HISTORY_COLUMNS: Columns<Payment> = [
	{
		width: {
			max: '17.7rem',
		},
		key: 'approvalAt',
		headerName: '승인 일시',
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		width: {
			max: '11.2rem',
		},
		key: 'name',
		headerName: '회원명',
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		key: 'pharmSaleTitle',
		headerName: '이벤트 타이틀',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		width: {
			max: '17.7rem',
		},
		key: 'productNames',
		headerName: '상품명',
		style: { justifyContent: 'space-between' },
		renderComponent: (value) => <Tags data={value} count={value.length} maxLength={1} $wrap="nowrap" />,
		isClick: true,
	},
	{
		width: {
			max: '14.8rem',
		},
		key: 'actualAmount',
		headerName: '결제 금액',
		renderComponent: (value) => value.toLocaleString() + '원',
		style: { justifyContent: 'flex-end' },
		isClick: true,
	},
	{
		width: {
			max: '14.4rem',
		},
		key: 'payType',
		headerName: '결제 유형',
		renderComponent: (value) => PAY_TYPE_VALUES[value],
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
];

export const INITIAL_SALES_HISTORY_TMP_SEARCH: Search<any> = {
	searchCategory: 'NAME',
	searchKeyword: '',
};
