import React, { useCallback } from 'react';

import { toast } from 'react-toastify';

import { blacklistTableColumns } from './tableColumns';
import { Button } from '../../../components/Buttons';
import { InsideTitle } from '../../../components/Common';
import { Icon, IconID } from '../../../components/Display';
import { ConfirmModal } from '../../../components/Modals';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useSelectedItems } from '../../../hooks/useSelectedItems';
import { useDeleteBlockMembers, useGetBlockMembers } from '../../../services/block/queries';
import { type PostAndDeleteBlockMembersRequest, type BlockMember } from '../../../services/block/types';
import { handleChangeSorts } from '../../../utils/onChangeSorts';

const BlackList = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const { data, pagination, refetch, isLoading, queries } = useGetBlockMembers(userInfo.storeId);
	const { selectedItems, handleSelectedItems } = useSelectedItems<BlockMember>({
		dependency: [queries.values, pagination.curPage, pagination.listCount],
	});

	const { mutate } = useDeleteBlockMembers({
		onSuccess: () => {
			toast.success('선택한 회원이 차단 해제되었습니다.');
			handleSelectedItems('RESET');
			refetch();
		},
	});

	const onDeleteBlock = useCallback(() => {
		if (!userInfo) return;
		const userIds = selectedItems.map((user) => user.userId);
		const params: PostAndDeleteBlockMembersRequest = {
			storeId: userInfo.storeId,
			data: {
				userIds,
			},
		};
		handleOpen(
			<ConfirmModal
				confirm={() => {
					mutate(params);
				}}
				message={'선택한 회원을 모두 차단 해제하시겠습니까?'}
			/>,
		);
	}, [selectedItems, userInfo]);

	return (
		<React.Fragment>
			<InsideTitle
				title="차단회원 목록"
				rightRender={
					<Button buttonType="LINE" color="SECONDARY" onClick={onDeleteBlock} disabled={selectedItems.length <= 0}>
						<Icon id={IconID.MESSAGE} width="2rem" height="2rem" isDisabled={selectedItems.length <= 0} />
						차단 해제
					</Button>
				}
			/>
			<ListTable
				data={data?.content}
				columns={blacklistTableColumns}
				isLoading={isLoading}
				unit="개"
				selected={{ selectKey: 'userId', multiple: { selectedItems, handleSelectedItems } }}
				pagination={pagination}
				emptyMessage="차단된 회원이 없습니다."
				onCategoryClick={(column) => {
					handleChangeSorts(queries.values.sorts, column, (sorts) => {
						queries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={queries.values.sorts}
			/>
		</React.Fragment>
	);
};

export default BlackList;
