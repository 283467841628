import React, { forwardRef, useMemo } from 'react';

import styled from 'styled-components';

import { IconButton } from '../../components/Buttons';
import { Icon, IconID } from '../../components/Display';
import { type Period } from '../../services/types';
import { createDateOffset, formatMonth } from '../../utils/format';

type HandleDate = (period: Period) => void;

interface CustomMonthPickerInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	date: Date;
	decrease: HandleDate;
	increase: HandleDate;
}

export const CustomMonthPickerInput = forwardRef<HTMLInputElement, CustomMonthPickerInputProps>(
	(props: CustomMonthPickerInputProps, ref?: React.ForwardedRef<HTMLInputElement>) => {
		const { increase, decrease, date, ...rest } = props;
		const curMonth = useMemo(() => {
			const now = new Date();
			return new Date(now.getFullYear(), now.getMonth() + 1, 1).getTime();
		}, []);

		const isDisabled = useMemo(
			() => new Date(curMonth).getTime() <= new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime(),
			[curMonth, date],
		);
		return (
			<Container>
				<IconButton
					width="1.6rem"
					height="1.6rem"
					onClick={() => {
						const decreaseDate = formatMonth(createDateOffset(date, { months: -1 }));
						decrease(decreaseDate);
					}}
					className="decrease"
				>
					<Icon id={IconID.CHEVRON} width="1.6rem" height="1.6rem" />
				</IconButton>
				<input ref={ref} {...rest} readOnly />
				<IconButton
					width="1.6rem"
					height="1.6rem"
					onClick={() => {
						const increaseDate = formatMonth(createDateOffset(date, { months: 1 }));
						increase(increaseDate);
					}}
					disabled={isDisabled}
					className="increase"
				>
					<Icon id={IconID.CHEVRON} width="1.6rem" height="1.6rem" isDisabled={isDisabled} />
				</IconButton>
			</Container>
		);
	},
);

CustomMonthPickerInput.displayName = 'CustomMonthPickerInput';

const Container = styled.div`
	display: flex;
	padding: 1rem 0;
	gap: 0.8rem;
	align-items: center;

	input {
		border: none;
		width: 8rem;
		${({ theme }) => theme.font.label.label_2};
		font-weight: 500;
		cursor: pointer;
	}
	.decrease {
		transform: rotate(90deg);
	}
	.increase {
		transform: rotate(-90deg);
	}
`;
