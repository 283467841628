import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
	FORM_KEY,
	INITIAL_VALUES,
	PRODUCT_LIST_COLUMNS,
	type TmpPutSalesItem,
	VALIDATION_KEYS,
	VALIDATION_STATUS_MESSAGE,
	SALES_STATUS_OPTIONS,
	SALES_STATUS_INFO_MESSAGE,
	SALES_STATUS_BUTTON_COLOR,
	SALES_ACTION_MESSAGES,
} from './const';
import { onChangeProductForm } from './function';
import { ProductAddModal } from './ProductAddModal';
import { Button } from '../../../../components/Buttons';
import { Footer, HStack, InsideTitle, Loading, VStack } from '../../../../components/Common';
import { InfoText } from '../../../../components/Display';
import { FileInput, FormItem, TextField } from '../../../../components/Forms';
import { ConfirmModal } from '../../../../components/Modals';
import { ListTable } from '../../../../components/Table';
import context from '../../../../context';
import { useValues } from '../../../../hooks/useValues';
import { api } from '../../../../services';
import {
	useDeleteSale,
	useGetSale,
	usePatchSaleStatus,
	usePostSale,
	usePostSaleCopy,
	usePutSale,
} from '../../../../services/sales/queries';
import { type SalesStatus, type SalesProduct } from '../../../../services/sales/types';
import { comparisonFilesToAssets, createFileFromAssets } from '../../../../utils/files';
import { isValidCheck } from '../../../../utils/isValidCheck';

type HandleProductType = 'ADD' | 'DELETE' | 'DISCOUNT' | 'USE';

export type HandleProduct = (type: HandleProductType, product: SalesProduct, value?: string) => void;

export const SalesDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();

	const { values, onChangeValues, dispatch } = useValues({ ...INITIAL_VALUES, storeId: userInfo.storeId });

	const [selectedProduct, setSelectedProduct] = useState<SalesProduct[]>([]);
	const combineParams = useMemo(
		() => ({
			storeId: userInfo.storeId,
			id: Number(id),
		}),
		[id, userInfo],
	);

	const handleProduct: HandleProduct = useCallback(
		(type, product, value) => {
			let result: SalesProduct | SalesProduct[] = [];
			try {
				switch (type) {
					case 'ADD': {
						const ids = selectedProduct.map((p) => p.productId);
						if (!ids.includes(product.productId)) {
							result = product;
						} else {
							result = selectedProduct;
						}
						break;
					}
					case 'DELETE': {
						const filterProduct = selectedProduct.filter((p) => p.productId !== product.productId);
						result = filterProduct;
						break;
					}
					case 'DISCOUNT': {
						const changeProduct = selectedProduct.map((p) => {
							if (p.productId === product.productId && value) p.discountPrice = value;
							return p;
						});
						result = changeProduct;
						break;
					}
					case 'USE': {
						const changeProduct = selectedProduct.map((p) => {
							if (p.productId === product.productId) p.isUsable = !p.isUsable;
							return p;
						});
						result = changeProduct;
						break;
					}
				}
			} finally {
				if (Array.isArray(result)) {
					setSelectedProduct(result);
					dispatch('SET', { products: onChangeProductForm(result) });
				} else {
					setSelectedProduct((prev) => [...prev, product]);
					dispatch('SET', { products: [...values.products, onChangeProductForm(result)] });
				}
			}
		},
		[selectedProduct],
	);

	const { data, isLoading: isDataLoading, isSuccess, refetch } = useGetSale(combineParams);

	const onChangeQueries = useCallback(async () => {
		try {
			if (!isSuccess) return;
			const { contentAssets, bannerAsset, saleProducts, createdAt, updatedAt, isSentPush, ...rest } = data;
			let bannerFiles: File[] | undefined = [];
			let contentFiles: File[] | undefined = [];
			if (bannerAsset) bannerFiles = await createFileFromAssets([bannerAsset]);
			if (contentAssets) contentFiles = await createFileFromAssets(contentAssets);
			setSelectedProduct(saleProducts);
			dispatch('SET', {
				...rest,
				bannerFiles,
				contentFiles,
				bannerAssetId: bannerAsset ? bannerAsset.id : undefined,
				products: onChangeProductForm(saleProducts),
			});
		} catch (err) {
			toast.error('데이터를 불러오지 못했습니다.\n' + (err as Error).message);
		}
	}, [data, id]);

	useEffect(() => {
		onChangeQueries();
	}, [data]);

	const invalidKeys = useMemo(
		() =>
			Object.entries(values)
				.filter(
					([key, value]) =>
						VALIDATION_KEYS[data?.status ?? 'READY'].includes(key as keyof TmpPutSalesItem) &&
						!isValidCheck(key, value),
				)
				.map(([key]) => key),
		[values],
	);

	const { mutate: postMutate, isPending: isPostPending } = usePostSale();
	const { mutate: putMutate, isPending: isPutPending } = usePutSale();

	const [isMutateLoading, setMutateLoading] = useState(false);

	const handleError = useCallback(
		(message: string | undefined) => {
			toast.error(`이벤트 ${id ? '변경' : '등록'}에 실패하였습니다.\n` + message ?? '');
		},
		[id],
	);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			handleOpen(
				<ConfirmModal
					message={SALES_ACTION_MESSAGES[id ? 'PUT' : 'POST'].CONFIRM}
					confirm={async () => {
						setMutateLoading(true);
						const { contentFiles, bannerFiles, ...params } = values;
						try {
							const { addFiles: addBannerFiles, deleteAssets: deleteBannerAssets } = comparisonFilesToAssets(
								data?.bannerAsset ? [data.bannerAsset] : [],
								bannerFiles,
							);
							if (deleteBannerAssets.length > 0) {
								params.bannerAssetId = undefined;
							}
							if (addBannerFiles.length > 0) {
								const bannerAsset = await api.asset.postAsset({ file: addBannerFiles[0] });
								params.bannerAssetId = bannerAsset.id;
							}

							const { addFiles, deleteAssets } = comparisonFilesToAssets(
								data?.contentAssets ? data.contentAssets : [],
								contentFiles,
							);
							if (addFiles.length > 0) {
								const contentAssets = await Promise.all(
									addFiles.map(async (file) => await api.asset.postAsset({ file })),
								);
								params.contentAssetIds = contentAssets.map((asset) => asset.id);
							}
							if (deleteAssets.length > 0) {
								params.deleteContentAssetIds = deleteAssets.map((asset) => asset.id);
							}
							if (id) {
								putMutate(params, {
									onSuccess: () => {
										toast.success(SALES_ACTION_MESSAGES.PUT.SUCCESS);
										refetch();
									},
									onError: (err) => {
										handleError(err.response?.data.message);
									},
								});
							} else {
								const { id, deleteContentAssetIds, ...postParams } = params;
								postMutate(postParams, {
									onSuccess: () => {
										toast.success(SALES_ACTION_MESSAGES.POST.SUCCESS);
										navigate(-1);
									},
									onError: (err) => {
										handleError(err.response?.data.message);
									},
								});
							}
						} catch (err) {
							handleError((err as Error).message);
						} finally {
							setMutateLoading(false);
						}
					}}
				/>,
			);
		},
		[userInfo, id, values, data],
	);
	const { mutate: deleteMutate, isPending: isDeletePending } = useDeleteSale();

	const onDelete = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					deleteMutate(combineParams, {
						onSuccess: () => {
							toast.success(SALES_ACTION_MESSAGES.DELETE.SUCCESS);
							navigate(-1);
						},
						onError: (err) => {
							toast.error(SALES_ACTION_MESSAGES.DELETE.ERROR + '\n' + err.response?.data.message);
						},
					});
				}}
				message={SALES_ACTION_MESSAGES.DELETE.CONFIRM}
			/>,
		);
	}, [id, userInfo]);

	const { mutate: copyMutate, isPending: isCopyPending } = usePostSaleCopy();

	const onClone = useCallback(() => {
		handleOpen(
			<ConfirmModal
				message={SALES_ACTION_MESSAGES.COPY.CONFIRM}
				confirm={() => {
					copyMutate(combineParams, {
						onSuccess: (res) => {
							toast.success(SALES_ACTION_MESSAGES.COPY.SUCCESS);
							navigate(-1);
						},
						onError: (err) => {
							toast.error(SALES_ACTION_MESSAGES.COPY.ERROR + '\n' + err.response?.data.message);
						},
					});
				}}
			/>,
		);
	}, [id, userInfo]);

	const { mutate: statusMutate, isPending: isStatusPending } = usePatchSaleStatus();

	const onChangeStatus = useCallback(
		(status: SalesStatus) => {
			handleOpen(
				<ConfirmModal
					message={SALES_ACTION_MESSAGES.STATUS[status]}
					confirm={() => {
						statusMutate(
							{
								status,
								...combineParams,
							},
							{
								onSuccess: () => {
									toast.success(SALES_ACTION_MESSAGES.STATUS.SUCCESS);
									refetch();
								},
								onError: (err) => {
									handleError(err.response?.data.message);
								},
							},
						);
					}}
				/>,
			);
		},
		[combineParams],
	);

	const isLoading = useMemo(
		() =>
			isMutateLoading ||
			isDataLoading ||
			isDeletePending ||
			isCopyPending ||
			isPostPending ||
			isPutPending ||
			isStatusPending,
		[isMutateLoading, isDataLoading, isDeletePending, isCopyPending, isPostPending, isPutPending, isStatusPending],
	);

	const SubmitButton = useMemo(
		() => (
			<Button type="submit" form={FORM_KEY} style={{ width: id ? '10rem' : '20rem' }} disabled={invalidKeys.length > 0}>
				{id ? '저장하기' : '생성하기'}
			</Button>
		),
		[id, invalidKeys],
	);

	return (
		<VStack style={{ height: '100%' }} $gap="2.4rem">
			{isLoading && <Loading $position="fixed" />}
			{!!id && (
				<React.Fragment>
					<Article $gap="1.6rem">
						<InsideTitle title={'이벤트 진행상태'} />
						<FormItem label="이벤트 진행상태">
							<VStack $gap="1.2rem">
								<HStack $gap="0.6rem">
									{SALES_STATUS_OPTIONS.map((status) => (
										<Button
											key={'status--' + status.key}
											size={{ $heightSize: 'M' }}
											color={data && data.status === status.key ? SALES_STATUS_BUTTON_COLOR[data.status] : 'GRAY'}
											style={{ width: '10rem' }}
											readonly={data && (data.status === status.key || data.status === 'COMPLETED')}
											onClick={() => {
												onChangeStatus(status.key!);
											}}
										>
											{status.label}
										</Button>
									))}
								</HStack>
								<InfoText
									text={SALES_STATUS_INFO_MESSAGE[data?.status ?? 'RUNNING']}
									color={{ background: 'white', font: 'gray_600' }}
									style={{ padding: 0 }}
								/>
							</VStack>
						</FormItem>
					</Article>
				</React.Fragment>
			)}
			<form {...{ onSubmit, style: { flex: '1' }, id: FORM_KEY }}>
				<Article $gap="1.6rem">
					<InsideTitle title={'이벤트 상세 정보'} rightRender={!!data && data.status !== 'COMPLETED' && SubmitButton} />
					<FormItem
						label={'이벤트 타이틀'}
						isRequired={VALIDATION_KEYS[data?.status ?? 'RUNNING'].includes('title')}
						statusMessage={invalidKeys.includes('title') ? VALIDATION_STATUS_MESSAGE.title : ''}
					>
						<TextField
							inputSize="lg"
							placeholder="이벤트 타이틀을 입력해주세요."
							name="title"
							value={values.title ?? ''}
							onChange={onChangeValues}
							maxLength={64}
							disabled={data && data?.status !== 'READY'}
						/>
					</FormItem>
					<FormItem
						label={'이벤트 상품'}
						isRequired={VALIDATION_KEYS[data?.status ?? 'RUNNING'].includes('products')}
						statusMessage={invalidKeys.includes('products') ? VALIDATION_STATUS_MESSAGE.products : ''}
					>
						<ListTable
							data={selectedProduct}
							columns={PRODUCT_LIST_COLUMNS(handleProduct, !data ? 'READY' : data.status)}
							isScroll={false}
							headerStyle={{ padding: '1rem 0' }}
							headerRightContent={
								data?.status === 'READY' || !data ? (
									<Button
										color="SECONDARY"
										buttonType="LINE"
										size={{ $heightSize: 'M' }}
										onClick={() => {
											handleOpen(
												<ProductAddModal
													onSelect={(product) => {
														handleProduct('ADD', product);
													}}
												/>,
											);
										}}
									>
										상품 등록
									</Button>
								) : undefined
							}
							hasLastItemBorder={false}
						/>
					</FormItem>
					<FormItem
						label={'이벤트 배너'}
						isRequired={VALIDATION_KEYS[data?.status ?? 'RUNNING'].includes('bannerFiles')}
						statusMessage={invalidKeys.includes('bannerFiles') ? VALIDATION_STATUS_MESSAGE.bannerFiles : ''}
					>
						<FileInput
							values={values.bannerFiles}
							onChange={(files) => {
								dispatch('SET', { bannerFiles: files });
							}}
							maxCapacity={{ size: 10, unit: 'MB' }}
							format={['image']}
							maxFileCount={1}
							disabled={data && data?.status !== 'READY'}
							tooltip={'권장 사이즈 : 가로 x 세로 = 304px x 80px (비율 19 : 5)'}
						/>
					</FormItem>
					<FormItem
						label={'이벤트 상세'}
						isRequired={VALIDATION_KEYS[data?.status ?? 'RUNNING'].includes('contentFiles')}
						statusMessage={invalidKeys.includes('contentFiles') ? VALIDATION_STATUS_MESSAGE.contentFiles : ''}
					>
						<FileInput
							values={values.contentFiles}
							onChange={(files) => {
								dispatch('SET', { contentFiles: files });
							}}
							maxCapacity={{ size: 10, unit: 'MB' }}
							format={['image']}
							maxFileCount={5}
							disabled={data && data?.status !== 'READY'}
						/>
					</FormItem>
				</Article>
			</form>

			<Footer style={{ display: isSuccess && data.status === 'RUNNING' ? 'none' : 'flex' }}>
				{isSuccess && data.status === 'READY' && (
					<Button
						color="RED"
						buttonType="LINE"
						style={{ width: '20rem', marginRight: 'auto' }}
						onClick={() => {
							onDelete();
						}}
					>
						삭제하기
					</Button>
				)}
				{isSuccess && data.status === 'COMPLETED' && (
					<Button
						style={{ width: '20rem' }}
						onClick={() => {
							onClone();
						}}
					>
						복제하기
					</Button>
				)}
				{!data && SubmitButton}
			</Footer>
		</VStack>
	);
};

const Article = styled(VStack)`
	max-width: 102.4rem;
`;
