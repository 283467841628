import styled from 'styled-components';

import { scrollStyle } from '../../../assets/styles/scrollStyle';
import { Flex } from '../../../components/Common';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-width: 132rem;
	position: relative;
	.inside-title {
		min-height: 0;
		padding: 0;
		h3 {
			${({ theme }) => theme.font.title.title_1};
		}
	}
`;

export const Wrapper = styled(Flex)`
	flex-direction: column;
	align-self: stretch;
	flex: 1 1 0;
	${scrollStyle()}
`;

export const Content = styled(Flex)`
	flex-direction: column;
	padding: 2rem 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}
`;

export const ItemsWrapper = styled(Flex)`
	width: 100%;
`;
