import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
	DAY_LABEL,
	DAY_OPTIONS,
	HOURS,
	INITIAL_HOUR,
	INITIAL_MINUTE,
	MINUTES,
	OPEN_HOURS_MESSAGES as MESSAGES,
	INITIAL_OPEN_VALUES,
	MAX_BUSY_COUNT,
	TIME_KEY_TO_DAY_OF_WEEK,
} from './consts';
import { TimeInputs } from './TimeInputs';
import {
	type OneDayStatus,
	getOneDayOpenHours,
	getOneDayStatus,
	type OneDayPeriod,
	type OneDayPeriodDetail,
	type OpenDayType,
	isStartBeforeClose,
	isBusyInDuty,
	type OneDayValue,
} from './utils';
import { Button } from '../../../../components/Buttons';
import { HStack, VStack } from '../../../../components/Common';
import { Icon, IconID, Label } from '../../../../components/Display';
import { Radio } from '../../../../components/Forms';
import { AlertModal } from '../../../../components/Modals';
import context from '../../../../context';
import { type Dispatch } from '../../../../hooks/useValues';
import Theme from '../../../../lib/styledComponents/Theme';
import { type OpenHours, type TimeKeyGroup } from '../../../../services/pharm/types';
import { changeColorKeyToType, type ColorKeys } from '../../../../utils/changeColorKeyToType';

export interface DayHoursProps {
	timeKey: TimeKeyGroup;
	openHours: OpenHours;
	dispatch: Dispatch<OpenHours>;
	setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
}
export const DayHours = (props: DayHoursProps) => {
	const { timeKey, openHours, dispatch, setAlertMessage } = props;
	const oneDayOpenHours = useMemo(() => getOneDayOpenHours(openHours, timeKey), [openHours, timeKey]);
	const [curOpenHours, setCurOpenHours] = useState({ ...oneDayOpenHours });
	const { handleOpen } = context.modal.useDispatch();
	const [status, setStatus] = useState<OneDayStatus>(getOneDayStatus(oneDayOpenHours));
	const handleChangeOpenHours = useCallback(
		(curOpenHours: OneDayValue) => {
			const newOpenHours = { ...openHours };
			const { start, close } = curOpenHours.duty;
			const hasUnsetDutyValue = [start.hour.key, close.hour.key].some((key) => key === -1);
			const hasUnsetBusyValue = curOpenHours.busy.some(({ start, close }) =>
				[start.hour.key, close.hour.key].some((key) => key === -1),
			);
			// // 설정되지 않은 시간 알림
			if (hasUnsetDutyValue && status === 'duty') {
				setAlertMessage(MESSAGES.SET_DUTY_OPEN_HOURS);
			} else if (hasUnsetBusyValue && status === 'busy') {
				setAlertMessage(MESSAGES.SET_BUSY_OPEN_HOURS);
			} else {
				setAlertMessage('');
			}
			Object.entries(curOpenHours.duty).forEach(([key, value]) => {
				newOpenHours[`dutyTime${timeKey}${key === 'start' ? 's' : 'c'}`] = `${value.hour.value}:${value.minute.value}`;
			});
			const filterBusyTimes = newOpenHours.busyTimes.filter((el) => el.dayOfWeek !== TIME_KEY_TO_DAY_OF_WEEK[timeKey]);
			if (curOpenHours.busy.length > 0) {
				filterBusyTimes.push(
					...curOpenHours.busy.map((busyTime) => ({
						dayOfWeek: TIME_KEY_TO_DAY_OF_WEEK[timeKey],
						startAt: `${busyTime.start.hour.value}:${busyTime.start.minute.value}`,
						endAt: `${busyTime.close.hour.value}:${busyTime.close.minute.value}`,
					})),
				);
			}
			newOpenHours.busyTimes = filterBusyTimes;
			dispatch('SET', newOpenHours);
		},
		[openHours, status, timeKey],
	);
	useEffect(() => {
		handleChangeOpenHours(curOpenHours);
	}, [curOpenHours]);

	const handleChangeStatus = useCallback(
		(value: OneDayStatus) => {
			const currentStatus = value === status ? 'duty' : (value as OneDayStatus);
			setStatus(currentStatus);
			const newOpenHours = { ...curOpenHours };
			const dutyHours = newOpenHours.duty;
			const busyHours = newOpenHours.busy;
			const hasDutyValue = dutyHours.start.hour.key !== -1 || dutyHours.close.hour.key !== -1;
			const hasBusyValue = busyHours.length > 0;
			switch (currentStatus) {
				case 'duty': {
					if (hasBusyValue) {
						handleBusyHours('RESET');
					}
					break;
				}
				case 'rest': {
					if (hasDutyValue) {
						dutyHours.start.hour = INITIAL_HOUR;
						dutyHours.start.minute = INITIAL_MINUTE;
						dutyHours.close.hour = INITIAL_HOUR;
						dutyHours.close.hour = INITIAL_HOUR;
					}
					if (hasBusyValue) {
						busyHours.length = 0;
					}
					setCurOpenHours(newOpenHours);
					break;
				}
				case 'busy': {
					handleBusyHours('PUSH');
					break;
				}
			}
		},
		[timeKey],
	);
	const handleBusyHours = useCallback(
		(type: 'PUSH' | 'RESET' | 'DELETE', targetIdx?: number) => {
			setCurOpenHours((prev) => {
				const newOpenHours = { ...prev };
				switch (type) {
					case 'RESET':
						newOpenHours.busy = [];
						break;
					case 'DELETE': {
						const deleteOpenHours = newOpenHours.busy.filter((_, idx) => idx !== targetIdx);
						newOpenHours.busy = [...deleteOpenHours];
						if (deleteOpenHours.length <= 0) setStatus('duty');
						break;
					}
					case 'PUSH':
						newOpenHours.busy = [...newOpenHours.busy, { ...INITIAL_OPEN_VALUES }];
						break;
				}
				return newOpenHours;
			});
		},
		[curOpenHours],
	);
	const handleChangeTimes = useCallback(
		(value: string | number, status: OneDayPeriod, type: OpenDayType, unit: OneDayPeriodDetail, idx?: number) => {
			setCurOpenHours((prev) => {
				try {
					const tmpCurOpenHours = { ...prev };
					const updatedValue =
						unit === 'hour'
							? HOURS.find((hour) => hour.key === Number(value)) ?? INITIAL_HOUR
							: MINUTES.find((min) => min.key === Number(value)) ?? INITIAL_MINUTE;
					switch (type) {
						case 'busy': {
							if (typeof idx !== 'number') break;
							tmpCurOpenHours.busy[idx] = {
								...tmpCurOpenHours.busy[idx],
								[status]: { ...tmpCurOpenHours.busy[idx][status], [unit]: { ...updatedValue } },
							};
							if (unit === 'hour') {
								// 시간 선택시 분 자동 0분 선택
								if (tmpCurOpenHours.busy[idx][status].minute.key === -1) {
									tmpCurOpenHours.busy[idx] = {
										...tmpCurOpenHours.busy[idx],
										[status]: { ...tmpCurOpenHours.busy[idx][status], minute: { ...MINUTES[0] } },
									};
								}
							}
							break;
						}
						case 'duty': {
							tmpCurOpenHours.duty = {
								...tmpCurOpenHours.duty,
								[status]: { ...tmpCurOpenHours.duty[status], [unit]: { ...updatedValue } },
							};
							if (unit === 'hour') {
								if (tmpCurOpenHours.duty[status].minute.key === -1) {
									tmpCurOpenHours.duty = {
										...tmpCurOpenHours.duty,
										[status]: { ...tmpCurOpenHours.duty[status], minute: { ...MINUTES[0] } },
									};
								}
							}
							break;
						}
					}
					if (!isStartBeforeClose(tmpCurOpenHours)) throw new Error(MESSAGES.SET_START_BEFORE_CLOSE);
					if (!isBusyInDuty(tmpCurOpenHours)) throw new Error(MESSAGES.SET_BUSY_IN_DUTY);
					return tmpCurOpenHours;
				} catch (err) {
					handleOpen(<AlertModal message={(err as Error).message} />);
					return prev;
				}
			});
		},
		[],
	);

	return (
		<Container>
			<Inner $flex="1">
				<HStack $flex="1">
					<DayLabel $color={DAY_LABEL[timeKey]?.color}>{DAY_LABEL[timeKey].label}</DayLabel>
					<TimeInputs
						disabled={status === 'rest'}
						type={'duty'}
						times={curOpenHours.duty}
						handleChangeTimes={handleChangeTimes}
					/>
				</HStack>
				{status === 'busy' && (
					<HStack $flex="1" style={{ backgroundColor: Theme.colors.red.red_200 }}>
						<DayLabel $color={DAY_LABEL[timeKey]?.color} $isBusy>
							혼잡 시간대
						</DayLabel>
						<VStack $flex="1">
							{curOpenHours.busy.map((hour, idx) => {
								return (
									<TimeInputs
										key={'busy--' + idx + timeKey}
										disabled={false}
										type={'busy'}
										dutyTimes={curOpenHours.duty}
										times={hour}
										handleChangeTimes={handleChangeTimes}
										idx={idx}
										prefix={
											<Button
												color="RED"
												onClick={() => {
													handleBusyHours('DELETE', idx);
												}}
												size={{
													$paddingSize: 'XS',
													$heightSize: 'XS',
												}}
											>
												<Icon id={IconID.TRASH_FILLED} defaultColor="white" width="1.6rem" height="1.6rem" />
											</Button>
										}
									/>
								);
							})}
						</VStack>
					</HStack>
				)}
			</Inner>
			<OptionsWrapper>
				<Radio
					style={{ gap: '1rem' }}
					labelStyle={{ height: '2rem' }}
					$direction={'COLUMN'}
					name={'status'}
					onChange={(e) => {
						handleChangeStatus(e.target.value as OneDayStatus);
					}}
					selectValue={status}
					options={DAY_OPTIONS}
					customKey={timeKey}
					renderPrefix={(key) =>
						key === 'busy' &&
						curOpenHours.busy.length > 0 && (
							<Button
								onClick={(e) => {
									e.stopPropagation();
									handleBusyHours('PUSH');
								}}
								size={{
									$paddingSize: 'XS',
									$heightSize: 'XS',
								}}
								disabled={curOpenHours.busy.length >= MAX_BUSY_COUNT}
							>
								<HStack $gap="0.2rem" $alignItems="center">
									<Icon id={IconID.ALARM_PLUS} defaultColor="white" width="1.6rem" height="1.6rem" />
									<Label $fontStyle="caption_2" $fontWeight="medium" $color="white">
										추가
									</Label>
								</HStack>
							</Button>
						)
					}
				/>
			</OptionsWrapper>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}
`;

const Inner = styled(VStack)`
	min-height: 8rem;
`;

const DayLabel = styled(VStack)<{ $color?: ColorKeys; $isBusy?: boolean }>`
	align-items: center;
	justify-content: center;

	width: 12rem;
	color: ${({ $color }) => changeColorKeyToType($color ?? 'black')};
	${({ theme }) => theme.font.label.label_2};
	background-color: ${({ theme, $isBusy }) => ($isBusy ? theme.colors.red.red_200 : theme.colors.gray.gray_100)};
	border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const OptionsWrapper = styled(VStack)`
	width: 18.4rem;
	padding: 0 1.6rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;
