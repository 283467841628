import React, { useEffect, useMemo, useRef, useState } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import styled from 'styled-components';

import { MessageInputActions } from './MessageInputActions';
import { IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID } from '../../../../components/Display';
import { TextArea } from '../../../../components/Forms';
import { useChannelContext } from '../../context/ChannelProvider';

interface MessageInputProps {
	currentChannel: GroupChannel;
}
export const MessageInput = (props: MessageInputProps) => {
	const { currentChannel } = props;
	const { disabledReason, scrollRef, scrollPubSub, sendUserMessage } = useChannelContext();

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const [inputValue, setInputValue] = useState<string>('');
	const [showActions, setShowActions] = useState<boolean>(true);

	const isDisabled = disabledReason !== 'none';
	const placeholder = useMemo(() => {
		switch (disabledReason) {
			case 'frozen':
				return '단골 약국 비활성 회원과는 대화가 불가능합니다.';
			case 'mute':
				return '차단한 회원과는 대화가 불가합니다.';
			case 'invalid':
				return '해당 채팅방은 더 이상 채팅 기능을 사용하지 않습니다.';
			case 'none':
			default:
				return '메시지를 입력해주세요.';
		}
	}, [disabledReason]);

	const handleScrollHeight = () => {
		if (!scrollRef.current) return;

		const prevViewInfo = {
			scrollTop: scrollRef.current.scrollTop,
			clientHeight: scrollRef.current.clientHeight,
			scrollHeight: scrollRef.current.scrollHeight,
		};

		setShowActions((prev) => !prev);

		requestAnimationFrame(() => {
			const nextViewInfo = {
				scrollTop: scrollRef.current.scrollTop,
				clientHeight: scrollRef.current.clientHeight,
				scrollHeight: scrollRef.current.scrollHeight,
			};
			const viewUpdated = prevViewInfo.clientHeight - nextViewInfo.clientHeight;
			if (viewUpdated !== 0) {
				scrollPubSub.publish('scroll', {
					top: prevViewInfo.scrollTop + viewUpdated,
					lazy: false,
				});
			}
		});
	};

	const setInputHeight = (reset?: boolean) => {
		const elem = textareaRef.current;
		if (elem) {
			const { scrollHeight, offsetHeight } = elem;
			if (scrollHeight > offsetHeight) {
				elem.style.height = '100%';
			}
			if (reset ?? elem.value === '') {
				elem.style.height = 'auto';
			}
		}
	};

	const resetInput = () => {
		setInputValue('');
	};

	const sendMessage = () => {
		const textField = textareaRef?.current;
		if (textField && inputValue.trim().length > 0) {
			sendUserMessage({ message: inputValue });
			resetInput();
			setInputHeight(true);
			textField.focus();
		}
	};

	// clear input value when channel changes
	useEffect(() => {
		resetInput();
		setInputHeight(true);
		setShowActions(true);
	}, [currentChannel?.url]);

	return (
		<Container>
			<InputWrapper>
				<Flex>
					<IconButton
						onClick={() => {
							handleScrollHeight();
						}}
						disabled={isDisabled}
					>
						{showActions && !isDisabled ? (
							<Icon id={IconID.BTN_CLOSE} defaultColor={'gray_700'} width={'1.4rem'} />
						) : (
							<Icon id={IconID.BTN_PLUS} defaultColor={'gray_700'} disabledColor={'gray_500'} isDisabled={isDisabled} />
						)}
					</IconButton>
				</Flex>
				<TextArea
					className={'chat-message-input'}
					onKeyDown={(e) => {
						if (
							!e.shiftKey &&
							e.key === 'Enter' &&
							(textareaRef.current?.value ?? inputValue ?? '').trim().length > 0 &&
							!e?.nativeEvent?.isComposing
						) {
							e.preventDefault();
							sendMessage();
						}
					}}
					ref={textareaRef}
					maxLength={300}
					disabled={isDisabled}
					autoFocus={true}
					placeholder={placeholder}
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
						setInputHeight();
					}}
					style={{
						height: '5.6rem',
						width: '100%',
						borderRadius: '8px',
						flexDirection: 'row',
						padding: '0.8rem 1.6rem',
					}}
					rows={1}
					renderSuffix={
						<IconButton
							onClick={() => {
								sendMessage();
							}}
						>
							<Icon className={'chat-btn-send'} id={IconID.BTN_SEND} defaultColor={'gray_500'} />
						</IconButton>
					}
					enableEmoji={true}
				/>
			</InputWrapper>
			{showActions && !isDisabled && <MessageInputActions />}
		</Container>
	);
};

const Container = styled(Flex)`
	position: relative;
	flex-direction: column;
	align-items: flex-start;
	border-top: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const InputWrapper = styled(Flex)`
	padding: 1.2rem 1.6rem;
	align-self: stretch;
	align-items: center;
	gap: 1.2rem;
	> div:focus-within .chat-btn-send {
		color: ${({ theme }) => theme.colors.primary.primary_600};
	}
`;
