import React from 'react';

import styled, { css } from 'styled-components';

import { ImageRenderer } from '../../../../components/Display';

interface Status {
	isOnline: boolean;
	statusSize?: string;
	borderWidth?: string;
}
interface ChannelAvatarProps {
	profileUrl?: string;
	width?: string;
	height?: string;
	status?: Status;
}
export const ChannelAvatar = (props: ChannelAvatarProps) => {
	const { profileUrl = '', width = '4rem', height = '4rem', status } = props;

	if (profileUrl !== '') {
		return (
			<AvatarWrapper $width={width} $height={height} $status={status}>
				<ImageRenderer url={profileUrl} alt={profileUrl} width={width} height={height} circle={true} fixedSize={true} />
			</AvatarWrapper>
		);
	}

	return <AvatarWrapper $width={width} $height={height} $status={status} />;
};

const AvatarWrapper = styled.span<{
	$width: string;
	$height: string;
	$status?: Status;
}>`
	position: relative;
	width: ${({ $width }) => $width};
	height: ${({ $height }) => $height};
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background-color: ${({ theme }) => theme.colors.gray.gray_300};
	background-image: url('/images/others/avatar-default.svg');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	${({ theme, $status }) => {
		if ($status) {
			const { isOnline, statusSize = '1.6rem', borderWidth = '0.3rem' } = $status;
			return (
				$status &&
				css`
					&::after {
						content: '';
						position: absolute;
						background-color: ${({ theme }) =>
							isOnline ? theme.colors.primary.primary_500 : theme.colors.gray.gray_500};
						height: ${statusSize};
						width: ${statusSize};
						right: -3px;
						bottom: -3px;
						border-radius: ${statusSize};
						border: ${borderWidth} solid ${({ theme }) => theme.colors.white};
						box-sizing: border-box;
					}
				`
			);
		}
	}}
`;
