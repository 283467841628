import React from 'react';

import { ellipsis2 } from '../../../assets/styles/ellipsis';
import { Tags } from '../../../components/Display/Tags';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type MemberData } from '../../../services/member/types';
import { GENDER_VALUES, IS_FAVORITE_VALUES } from '../../../utils/consts';
import { formatBirth, formatPhoneNumber } from '../../../utils/format';

const pharmsTableColumns: Columns<MemberData> = [
	{
		key: 'name',
		headerName: '회원명',
		width: { min: '8.8rem' },
		isClick: true,
		renderComponent: (value) => <p style={ellipsis2}>{value}</p>,
	},
	{
		key: 'birth',
		headerName: '생년월일',
		isClick: true,
		renderComponent: (value) => formatBirth(value),
		width: { min: '10.6rem' },
	},
	{
		key: 'gender',
		headerName: '성별',
		width: { min: '6.9rem' },
		isClick: true,
		renderComponent: (value) => GENDER_VALUES[value],
	},
	{
		key: 'phone',
		headerName: '전화번호',
		isClick: true,
		renderComponent: (value) => formatPhoneNumber(value),
		width: { min: '14.4rem' },
	},
	{
		key: 'nicknames',
		headerName: '닉네임',
		renderComponent: (value) => (
			<Tags data={value} count={value.length} maxLength={1} color="PRIMARY" type="FILL" $wrap="nowrap" />
		),
		width: { min: '18.2rem' },
	},
	{
		key: 'groupNames',
		headerName: '소속 그룹',
		renderComponent: (value, item) => (
			<Tags data={value} count={item.groupCount} maxLength={1} color="YELLOW" type="FILL" $wrap="nowrap" />
		),
		width: { min: '18.2rem' },
	},
	{
		key: 'registrationAt',
		headerName: '약국 등록일',
		renderComponent: (value) => formatBirth(value),
		width: { min: '11.6rem' },
		isClick: true,
	},
	{
		key: 'isFavorite',
		headerName: '단골 여부',
		renderComponent: (value) => IS_FAVORITE_VALUES[value],
		width: { min: '10.6rem' },
		isClick: true,
	},
	{
		key: 'paymentCount',
		headerName: '결제 횟수',
		width: { min: '9.8rem' },
		isClick: true,
		renderComponent: (value) => value.toLocaleString(),
		style: { justifyContent: 'flex-end' },
	},
	{
		key: 'paymentAmountTotal',
		headerName: '총 결제 금액',
		isClick: true,
		renderComponent: (value) => value.toLocaleString(),
		style: { justifyContent: 'flex-end' },
		width: { min: '13.5rem' },
	},
];

export default pharmsTableColumns;
