import React, { createContext, useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useWorkerInterval } from '../../hooks/useWorkerInterval';
import { useGetPharmImage } from '../../services/pharm/queries';
import { useGetUserInfo, usePostHeartbeat } from '../../services/users/queries';
import { type UserInfo } from '../../services/users/types';
import { useAuthContext } from '../auth';

interface UserContextValue {
	userInfo: UserInfo;
	userImage: string | null;
}

const UserContext = createContext<UserContextValue | null>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
	const { isLogin } = useAuthContext();
	const [userImage, setUserImage] = useState<string | null>(null);

	const { data: userInfo, isError: isInfoError, error: infoError } = useGetUserInfo(isLogin);

	const {
		data: image,
		isError: isImageError,
		error: imageError,
	} = useGetPharmImage({ storeId: userInfo?.storeId ?? '' });

	useEffect(() => {
		if (isInfoError) toast.error(`유저정보를 불러오지 못했습니다.\n${infoError.message}`);
		if (isImageError) toast.error(`유저정보를 불러오지 못했습니다.\n${imageError.message}`);
	}, [isInfoError]);

	useEffect(() => {
		if (!image) return;
		const file = new File([image], 'imageName');
		setUserImage(URL.createObjectURL(file));
	}, [image]);

	const { mutate } = usePostHeartbeat({
		onError: (err) => {
			toast.error(err.response?.data.message);
		},
	});

	useWorkerInterval(
		() => {
			mutate({ platformType: 'WEB', serviceType: 'MOBILE_PHARMACY' });
		},
		1000 * 60 * 5, // 5분에 한번씩 요청
	);

	if (!userInfo) {
		return <></>;
	}

	return (
		<UserContext.Provider
			value={{
				userInfo,
				userImage,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

const useValue = () => {
	const state = useContext(UserContext);
	if (!state) {
		throw new Error('Cannot find UserProvider');
	}
	return state;
};

const user = { useValue };

export default user;
