import React from 'react';

import { ChannelListUI } from './components/ChannelListUI';
import { ChannelListProvider, type ChannelListProviderProps } from './context/ChannelListProvider';

export interface ChannelListProps extends ChannelListProviderProps {}

const ChannelList = (props: ChannelListProps) => {
	return (
		<ChannelListProvider {...props}>
			<ChannelListUI />
		</ChannelListProvider>
	);
};

export default ChannelList;
