import React from 'react';

import { Flex } from '../../../../components/Common';
import { Label } from '../../../../components/Display';
import { useMemberActivities } from '../../../../services/member/queries';
import { type GetMemberActivities, type GetMemberActivityRequest } from '../../../../services/member/types';
import { commaizeNumber, formatDate } from '../../../../utils/format';
import { type MemberInfoParams } from '../../index';
import { InfoDisclosure } from '../InfoDisclosure';

function getTotalAmount(data: string) {
	try {
		const { totalAmount } = JSON.parse(data);
		return totalAmount;
	} catch (e) {
		return 0;
	}
}

function transformActivities(isPayment: boolean) {
	return {
		isPayment,
		select: (data: GetMemberActivities) => {
			return data.content.slice(0, 3).map(({ createdAt, message, data }) => ({
				label: message,
				date: formatDate(new Date(createdAt), 'yyyy.MM.dd. hh:mm'),
				...(isPayment && { totalAmount: getTotalAmount(data) }),
			}));
		},
	};
}

export const HistoryInfo = (params: MemberInfoParams) => {
	const activityParams: GetMemberActivityRequest = { ...params, sorts: [{ property: 'createdAt', direction: 'DESC' }] };

	const { data: payments, isSuccess: paymentsSuccess } = useMemberActivities<HistoryInfoItemProps[]>({
		...activityParams,
		...transformActivities(true),
	});

	const { data: activities, isSuccess: activitiesSuccess } = useMemberActivities({
		...activityParams,
		...transformActivities(false),
	});

	return (
		<InfoDisclosure title={'히스토리'}>
			<Flex $direction={'column'} $alignItems={'start'} $gap={'1.6rem'} $alignSelf={'stretch'}>
				<Flex $direction={'column'} $alignItems={'start'} $gap={'1.6rem'} $alignSelf={'stretch'}>
					{paymentsSuccess && payments?.length ? (
						<Flex $direction={'column'} $alignItems={'start'} $alignSelf={'stretch'}>
							<Label $color={'gray_600'} $fontWeight={'medium'} $fontStyle={'caption_1'}>
								{'결제'}
							</Label>
							<Flex $direction={'column'} $alignItems={'start'} $alignSelf={'stretch'}>
								{payments.map((data, idx) => (
									<HistoryInfoItem key={idx} {...data} />
								))}
							</Flex>
						</Flex>
					) : (
						<></>
					)}
					{activitiesSuccess && activities?.length ? (
						<Flex $direction={'column'} $alignItems={'start'} $alignSelf={'stretch'}>
							<Label $color={'gray_600'} $fontWeight={'medium'} $fontStyle={'caption_1'}>
								{'기타'}
							</Label>
							<Flex $direction={'column'} $alignItems={'start'} $alignSelf={'stretch'}>
								{activities.map((data, idx) => (
									<HistoryInfoItem key={idx} {...data} />
								))}
							</Flex>
						</Flex>
					) : (
						<></>
					)}
				</Flex>
			</Flex>
		</InfoDisclosure>
	);
};

interface HistoryInfoItemProps {
	label: string;
	totalAmount?: number;
	date: string;
}

const HistoryInfoItem = ({ label, totalAmount, date }: HistoryInfoItemProps) => {
	return (
		<Flex $padding={'0.6rem 0'} $justify={'between'} $alignItems={'start'} $alignSelf={'stretch'}>
			<Flex $direction={'column'} $alignItems={'start'} $gap={'0.2rem'} $flex={'1 0 0'}>
				<Label $fontStyle={'body_2'} $fontWeight={'medium'} $textAlign={'center'}>
					{label}
				</Label>
				<Label $color={'gray_600'} $fontStyle={'caption_1'} $fontWeight={'medium'} $textAlign={'center'}>
					{date}
				</Label>
			</Flex>
			{typeof totalAmount === 'number' && (
				<Label $fontStyle={'body_2'} $fontWeight={'bold'} $textAlign={'center'}>
					{commaizeNumber(totalAmount) + '원'}
				</Label>
			)}
		</Flex>
	);
};
