import React, { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { NoticeAddModal } from './NoticeAddModal';
import { Button } from '../../../../components/Buttons';
import { InsideTitle } from '../../../../components/Common';
import { AssetsImage, Icon, IconID } from '../../../../components/Display';
import { ConfirmModal } from '../../../../components/Modals';
import { ListTable } from '../../../../components/Table';
import { type Columns } from '../../../../components/Table/ListTable/types';
import context from '../../../../context';
import Theme from '../../../../lib/styledComponents/Theme';
import {
	NoticeMainMutationKeys,
	NoticePublicMutationKeys,
	pharmsKeys,
	useDeleteNotice,
	useDeleteNoticeMain,
	useDeleteNoticePublic,
	useGetNoticeList,
	usePatchNoticeMain,
	usePatchNoticePublic,
	usePatchNoticePush,
} from '../../../../services/pharm/queries';
import { type DetailDefaultParams, type NoticeItem } from '../../../../services/pharm/types';
import { type ErrorData } from '../../../../services/types';

export const Notice = () => {
	const queryClient = useQueryClient();
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const { data, pagination, refetch } = useGetNoticeList(userInfo.storeId);

	const onInvalidate = useCallback(
		(id: number) => {
			queryClient.invalidateQueries({
				queryKey: pharmsKeys.noticeDetail({ storeId: userInfo.storeId, id }),
			});
		},
		[userInfo],
	);

	const handler = useMemo(
		() => ({
			onSuccess: () => {
				refetch();
				toast.success('설정에 성공하였습니다.');
			},
			onError: (err: AxiosError<ErrorData, any>) => {
				toast.error('오류로 인하여 설정에 실패하였습니다.\n' + err.response?.data.message);
			},
		}),
		[],
	);

	const { mutate: pushPatchMutate } = usePatchNoticePush({
		onSuccess: () => {
			refetch();
			toast.success('소식 알림이 전송되었습니다.');
		},
		onError: (err) => {
			toast.error('소식 알림을 전송하지 못했습니다.\n' + err.response?.data.message);
		},
	});
	const { mutate: publicPatchMutate } = usePatchNoticePublic(handler);
	const { mutate: publicDeleteMutate } = useDeleteNoticePublic(handler);
	const { mutate: mainPatchMutate } = usePatchNoticeMain(handler);
	const { mutate: mainDeleteMutate } = useDeleteNoticeMain(handler);
	const { mutate: deleteMutate } = useDeleteNotice({
		onSuccess: () => {
			refetch();
			toast.success('소식이 삭제되었습니다.');
		},
		onError: (err) => {
			toast.error('소식을 삭제하지 못했습니다.\n' + err.response?.data.message);
		},
	});
	const handleDelete = useCallback((params: DetailDefaultParams) => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					deleteMutate(params);
				}}
				message={'삭제된 소식은 복구할 수 없습니다. 소식을 삭제하시겠습니까?'}
				buttonMessage={{
					confirm: '삭제',
					shouldPrevent: true,
				}}
			/>,
		);
	}, []);

	const handlePushClick = (params: DetailDefaultParams) => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					pushPatchMutate(params);
				}}
				message={
					<>
						단골 고객들께 새로운 소식에 대한 앱 푸시 알림이 전송됩니다.
						<br />
						알림을 전송하시겠습니까?
						<br />
						<mark>1회만 발송 가능 / 발송 취소 불가</mark>
					</>
				}
				buttonMessage={{
					confirm: '전송',
					shouldPrevent: true,
				}}
			/>,
		);
	};

	const openNoticeModal = useCallback((item?: NoticeItem) => {
		handleOpen(
			<NoticeAddModal
				id={item?.id}
				refetch={() => {
					refetch();
				}}
			/>,
		);
	}, []);

	const columns: Columns<NoticeItem> = useMemo(
		() => [
			{
				key: 'assets',
				width: {
					max: '14rem',
				},
				renderComponent: (value) => {
					return <AssetsImage assets={value ?? []} />;
				},
			},
			{
				key: 'content',
				renderComponent: (_, item) => {
					const { content, createdAt, isSentPush, isMain, isPublic, title, id } = item;
					const patchParams: DetailDefaultParams = {
						storeId: userInfo.storeId,
						id,
					};

					return (
						<Content $isPublic={isPublic}>
							<div className="information">
								<div className="information--inner">
									<p className="information--title">{title}</p>
									<p className="information--etc">
										<span className="information--created__at">{createdAt}</span>
										<span className="information--is__public">{isPublic ? '공개' : '비공개'}</span>
									</p>
								</div>
								{isMain && <label className="information--is__main">대표소식</label>}
								<ul className="information--buttons">
									<li className="information--buttons--item">
										<NoticeButton
											buttonType="LINE"
											color="SECONDARY"
											disabled={isSentPush}
											onClick={() => {
												handlePushClick(patchParams);
											}}
										>
											<Icon
												id={IconID.BELL}
												width="2rem"
												height="2rem"
												disabledColor={'gray_600'}
												isDisabled={isSentPush}
											/>
											{'알림 발송'}
										</NoticeButton>
									</li>
									<li className="information--buttons--item">
										<NoticeButton
											buttonType="LINE"
											color="SECONDARY"
											shouldPrevent={true}
											mutationKey={NoticeMainMutationKeys}
											onClick={() => {
												isMain ? mainDeleteMutate(patchParams) : mainPatchMutate(patchParams);
												onInvalidate(item.id);
											}}
										>
											<Icon
												id={IconID[isMain ? 'PIN_OFF' : 'PIN_ON']}
												width="2rem"
												height="2rem"
												disabledColor={'gray_600'}
											/>
											{isMain ? '대표해제' : '대표설정'}
										</NoticeButton>
									</li>
									<li className="information--buttons--item">
										<NoticeButton
											buttonType="LINE"
											color="SECONDARY"
											shouldPrevent={true}
											mutationKey={NoticePublicMutationKeys}
											onClick={() => {
												isPublic ? publicDeleteMutate(patchParams) : publicPatchMutate(patchParams);
												onInvalidate(item.id);
											}}
										>
											<Icon
												id={IconID[isPublic ? 'EYE_OFF' : 'EYE_ON']}
												width="2rem"
												height="2rem"
												disabledColor={'gray_600'}
											/>
											{isPublic ? '비공개' : '공개'}
										</NoticeButton>
									</li>
									<li className="information--buttons--item">
										<NoticeButton
											buttonType="LINE"
											color="SECONDARY"
											onClick={() => {
												openNoticeModal(item);
											}}
										>
											<Icon id={IconID.PENCIL} width="2rem" height="2rem" /> 수정
										</NoticeButton>
									</li>
									<li className="information--buttons--item">
										<NoticeButton
											buttonType="LINE"
											color="RED"
											style={{ border: `1px solid ${Theme.colors.gray.gray_500}` }}
											onClick={() => {
												handleDelete(patchParams);
											}}
										>
											<Icon id={IconID.TRASH} defaultColor="red_600" width="2rem" height="2rem" /> 삭제
										</NoticeButton>
									</li>
								</ul>
							</div>
							<p className="content">{content}</p>
						</Content>
					);
				},
			},
		],
		[userInfo],
	);

	return (
		<Container>
			<InsideTitle
				title="등록된 소식"
				rightRender={
					<Button
						buttonType="LINE"
						color="SECONDARY"
						shouldPrevent={false}
						onClick={() => {
							openNoticeModal();
						}}
					>
						<Icon id={IconID.MESSAGE_PLUS} width="2rem" height="2rem" />
						소식 등록
					</Button>
				}
			/>
			<ListTable
				data={data?.content}
				columns={columns}
				pagination={pagination}
				isListCount={false}
				bodyRowStyle={{ padding: '2.4rem', gap: '3.2rem' }}
				bodyItemStyle={{ padding: 0 }}
				maxHeight="75.6rem"
				unit="개"
				tableHeaderStyle={{ borderBottom: 0 }}
				emptyMessage={
					<EmptyMessage>
						등록된 소식이 없습니다.
						<br />
						소식을 등록해 단골 고객님과 소통해 보세요.
					</EmptyMessage>
				}
			/>
		</Container>
	);
};
const Container = styled.div``;

const NoticeButton = styled(Button)`
	padding: 0 12px 0 10px;
	height: 3.2rem;
	font-weight: 400;
`;

const Content = styled.div<{ $isPublic: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	gap: 1.2rem;

	&:hover {
		.information {
			&--is__main {
				display: none;
			}

			&--buttons {
				display: inline-flex;
			}
		}
	}

	.information {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&--buttons {
			display: none;
			gap: 0.8rem;
			button {
				white-space: nowrap;
			}
		}

		&--is__main {
			border-radius: 99px;
			padding: 0.8rem 1.2rem;
			background-color: ${({ theme }) => theme.colors.primary.primary_600};
			display: inline-flex;
			align-items: center;
			justify-content: center;
			${({ theme }) => theme.font.label.label_2};
			font-weight: 700;
			color: ${({ theme }) => theme.colors.white};
		}

		&--inner {
			display: flex;
			flex-direction: column;
			gap: 0.4rem;
		}

		&--title {
			${({ theme }) => theme.font.title.title_2};
			font-weight: 700;
			min-height: 2.8rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}

		&--etc {
			display: flex;
			gap: 0.4rem;
			${({ theme }) => theme.font.label.label_2}
		}

		&--created__at {
			display: inline-flex;
			gap: 0.4rem;
			align-items: center;
			color: ${({ theme }) => theme.colors.gray.gray_700};

			&::after {
				content: '•';
				color: ${({ theme }) => theme.colors.gray.gray_400};
			}
		}

		&--is__public {
			color: ${({ theme, $isPublic }) => ($isPublic ? theme.colors.green.green_700 : theme.colors.red.red_600)};
		}
	}

	.content {
		flex: 1;
		${({ theme }) => theme.font.body.body_2};
		color: ${({ theme }) => theme.colors.gray.gray_800};
	}
`;

const EmptyMessage = styled.span`
	${({ theme }) => theme.font.body.body_2};
`;
