import React, { useCallback, useMemo } from 'react';

import { toast } from 'react-toastify';

import { INITIAL_SALES_HISTORY_TMP_SEARCH, SALES_HISTORY_COLUMNS } from './const';
import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { Button } from '../../../components/Buttons';
import { HStack, InsideTitle, VStack } from '../../../components/Common';
import { Icon, IconID } from '../../../components/Display';
import { FilterList } from '../../../components/Filter/components/FilterList';
import { Select, TextField } from '../../../components/Forms';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useValues } from '../../../hooks/useValues';
import { PAYMENTS_SEARCH_OPTIONS, useGetPayments, useGetPaymentsExcel } from '../../../services/payment/queries';
import { useGetSales } from '../../../services/sales/queries';
import { type DefaultOption } from '../../../utils/consts';
import { formatSearch } from '../../../utils/format';
import { handleChangeSorts } from '../../../utils/onChangeSorts';
import { onDownloadBlob } from '../../../utils/onDownloadBlob';

const INITIAL_EVENT_OPTIONS: Array<DefaultOption<number>> = [{ key: -1, label: '전체' }] as const;

export const SalesHistory = () => {
	const { userInfo } = context.user.useValue();

	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues(INITIAL_SALES_HISTORY_TMP_SEARCH);

	const { data, isSuccess, isLoading } = useGetSales(userInfo.storeId);

	const eventOptions = useMemo(
		() =>
			isSuccess
				? data.content.reduce((acc, cur) => [...acc, { key: cur.id, label: cur.title }], [...INITIAL_EVENT_OPTIONS])
				: INITIAL_EVENT_OPTIONS,
		[data],
	);

	const {
		data: paymentData,
		queries: paymentQueries,
		pagination,
	} = useGetPayments(userInfo.storeId, {
		setInitialValues: {
			medicationType: 'PHARM_SALE',
			isApproval: 'true',
		},
	});

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			const trimValue = formatSearch(tmpSearch.searchKeyword ?? '', tmpSearch.searchCategory);
			paymentQueries.dispatch('SET', { search: { ...tmpSearch, searchKeyword: trimValue } });
		},
		[tmpSearch],
	);

	const { mutate, isPending } = useGetPaymentsExcel({
		onSuccess: (blob) => {
			onDownloadBlob(blob, '약국_이벤트_내역.xlsx', 'application/octet-stream');
		},
		onError: (err) => {
			toast.error('엑셀 다운로드에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const onExcelDownload = useCallback(() => {
		mutate(paymentQueries.values);
	}, [paymentQueries.values]);
	return (
		<VStack>
			<InsideTitle
				title="약국 이벤트 내역"
				rightRender={
					<Select
						options={eventOptions}
						value={paymentQueries.values.pharmSaleId}
						labelStyle={{ width: '20rem', gap: '0.4rem' }}
						onClick={(pharmSaleId) => {
							paymentQueries.dispatch('SET', { pharmSaleId: Number(pharmSaleId) });
						}}
						customValue={(value) => <p style={ellipsis1}>{value}</p>}
						customLabel={(item) => <p style={{ ...ellipsis1, maxWidth: '40rem' }}>{item.label}</p>}
					/>
				}
			/>
			<ListTable
				data={paymentData?.content}
				columns={SALES_HISTORY_COLUMNS}
				isLoading={isLoading}
				pagination={pagination}
				unit="건"
				onCategoryClick={(column) => {
					handleChangeSorts(paymentQueries.values.sorts, column, (sorts) => {
						paymentQueries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={paymentQueries.values.sorts}
				headerLeftContent={
					<form {...{ onSubmit }}>
						<HStack $gap="0.8rem">
							<Select
								options={PAYMENTS_SEARCH_OPTIONS}
								value={tmpSearch.searchCategory}
								labelStyle={{ width: '10.4rem' }}
								onClick={(key) => {
									tmpSearchDispatch('SET', {
										searchCategory: key,
										searchKeyword: '',
									});
								}}
							/>
							<TextField
								renderPrefix={<Icon id="btn-search" width="2rem" height="2rem" />}
								inputSize={'sm'}
								style={{ borderRadius: '4px', width: '32rem' }}
								placeholder="검색어를 입력해주세요."
								value={tmpSearch.searchKeyword}
								name="searchKeyword"
								onChange={onChangeTmpSearch}
							/>
						</HStack>
					</form>
				}
				headerRightContent={
					<Button
						disabled={isPending || (paymentData && paymentData.content.length <= 0)}
						onClick={() => {
							onExcelDownload();
						}}
						shouldPrevent={true}
						size={{ $paddingSize: 'M' }}
					>
						<Icon id={IconID.FILE} defaultColor="white" width="2rem" height="2rem" /> 엑셀 다운로드
					</Button>
				}
				headerBottomContent={<FilterList items={[]} resetCurPage={pagination.resetCurPage} queries={paymentQueries} />}
			/>
		</VStack>
	);
};
