import React, { useEffect, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import { ellipsisStyle } from '../../../assets/styles/ellipsis';
import { scrollStyle } from '../../../assets/styles/scrollStyle';
import context from '../../../context';
import ScrollFix from '../../../lib/styledComponents/ScrollFix';
import { useGetInfinityTemplates } from '../../../services/templates/queries';
import { type Template } from '../../../services/templates/types';
import { Loading, VStack } from '../../Common';
import { Label } from '../../Display';
import { Modal, type ModalProps } from '../Modal';

interface TemplateModalProps extends ModalProps {
	selectTemplate?: Template;
	onSelect: (template: Template) => void;
}
export const TemplatesModal = ({ selectTemplate, onSelect, ...modalProps }: TemplateModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleClose } = context.modal.useDispatch();
	const [selectItem, setSelectItem] = useState<Template | undefined>(undefined);

	const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, isSuccess } = useGetInfinityTemplates(
		userInfo.storeId,
	);
	const observerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) fetchNextPage();
			},
			{ threshold: 1.0 },
		);

		if (observerRef.current) observer.observe(observerRef.current);

		return () => {
			if (observerRef.current) observer.unobserve(observerRef.current);
		};
	}, [hasNextPage, isFetchingNextPage, fetchNextPage, observerRef.current]);

	return (
		<Modal
			{...modalProps}
			header={{ title: '템플릿 선택' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: handleClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '선택',
						onClick: () => {
							selectItem && onSelect(selectItem || selectTemplate);
							handleClose();
						},
						shouldPrevent: true,
						style: { width: '10rem' },
						disabled: !selectItem && !selectTemplate,
					},
				],
			}}
		>
			<ScrollFix />
			<Container>
				{(isLoading || isFetchingNextPage) && <Loading />}
				{isSuccess &&
					(data.pages[0].total === 0 ? (
						<VStack $alignItems="center" $justify="center" $flex="1">
							<Label $color="gray_600">등록된 템플릿이 없습니다.</Label>
						</VStack>
					) : (
						data.pages.map((page, idx) => (
							<ListWrapper key={'page--' + idx}>
								{page.content.map((template) => (
									<Item
										key={'template--' + template.id}
										onClick={() => {
											setSelectItem(template);
										}}
										$isSelected={selectItem ? template.id === selectItem?.id : template.id === selectTemplate?.id}
									>
										<p className="template--title">{template.title}</p>
										<p className="template--content">{template.content}</p>
									</Item>
								))}
							</ListWrapper>
						))
					))}

				{hasNextPage && !isFetchingNextPage && <div ref={observerRef} />}
			</Container>
		</Modal>
	);
};

const Container = styled(VStack)`
	gap: 1.6rem;
	padding: 2rem;
	width: 80rem;
	height: 54.4rem;
	${scrollStyle({ hideHorizontal: true })}
`;
const Item = styled.li<{ $isSelected: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 1.6rem 1.6rem 1rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	border-radius: 4px;
	cursor: pointer;
	${({ $isSelected, theme }) =>
		$isSelected &&
		css`
			background-color: ${theme.colors.primary.primary_100};
			border: 1px solid ${theme.colors.primary.primary_600};
		`}
	.template {
		&--title {
			${({ theme }) => theme.font.title.title_3};
			font-weight: 700;
			padding-bottom: 1.2rem;
			border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
		}
		&--content {
			${({ theme }) => theme.font.body.body_2};
			${ellipsisStyle(7)}
			height:14rem;
		}
	}
`;

const ListWrapper = styled.ul`
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.6rem;
`;
