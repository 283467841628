import React from 'react';

import styled from 'styled-components';

import { IconButton } from '../../../../../components/Buttons';
import { Icon, IconID } from '../../../../../components/Display';
import { type Group } from '../../../../../services/groups/types';
import { type BasicMemberInfo } from '../../../../../services/member/types';
import { GENDER_VALUES } from '../../../../../utils/consts';

type ItemTypes = 'GROUP' | 'USER' | 'ALL';

interface StyledProps {
	$isActive?: boolean;
}
interface SelectedItemProps<T extends object> extends StyledProps {
	onDelete: (item?: T) => void;
	type: ItemTypes;
	item?: T;
}

export const SelectedItem = <T extends object>({ onDelete, type, item, $isActive = false }: SelectedItemProps<T>) => {
	return (
		<Wrapper $isActive={$isActive}>
			<div className="inner">
				<Icon id={IconID.MEMBER_CIRCLE} width="2rem" height="2rem" />
				<div className="inner--content">
					<span>{type === 'ALL' ? '전체 회원' : (item as BasicMemberInfo | Group).name}</span>
					{type !== 'ALL' && (
						<ul>
							{type === 'GROUP' ? (
								<li>{(item as Group).favoriteMemberCount}명</li>
							) : (
								<React.Fragment>
									<li>{(item as BasicMemberInfo).birth}</li>
									<li>{GENDER_VALUES[(item as BasicMemberInfo).gender]}</li>
								</React.Fragment>
							)}
						</ul>
					)}
				</div>
			</div>
			<IconButton
				width="1.6rem"
				height="1.6rem"
				onClick={() => {
					onDelete(item);
				}}
			>
				<Icon id={IconID.BTN_CLOSE} width="1.6rem" height="1.6rem" defaultColor="gray_600" />
			</IconButton>
		</Wrapper>
	);
};
const Wrapper = styled.li<StyledProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.2rem;
	padding: 0.8rem 1.2rem;
	border-radius: 4px;
	border: 1px solid
		${({ $isActive, theme }) => (!$isActive ? theme.colors.gray.gray_300 : theme.colors.primary.primary_600)};
	${({ theme }) => theme.font.label.label_2};
	font-weight: 500;
	color: ${({ $isActive, theme }) => (!$isActive ? theme.colors.black : theme.colors.primary.primary_600)};
	.inner {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		&--content {
			display: flex;
			gap: 0.6rem;
			ul {
				display: flex;
				gap: 0.6rem;
				color: ${({ theme }) => theme.colors.gray.gray_700};
				&:not(:last-child)::after {
					content: '';
					display: inline-block;
					width: 0.1rem;
					height: 1rem;
					background-color: ${({ theme }) => theme.colors.gray.gray_300};
				}
			}
		}
	}
`;
