/**
 *
 * @todo 모두 선택 되었을 때 전체로 바껴야함
 */

export const handleMultiSelect = <T>(value: T, current: T[], allKey: T, allLength?: number): T[] => {
	const valueSet = new Set(current);
	if (value === allKey && !valueSet.has(allKey)) {
		valueSet.clear();
		valueSet.add(value);
	} else {
		valueSet.has(value) ? valueSet.delete(value) : valueSet.add(value);
		if (valueSet.has(allKey)) {
			valueSet.delete(allKey);
		}
	}

	if (allLength && valueSet.size === allLength - 1) {
		valueSet.clear();
		valueSet.add(allKey);
	}

	if (allKey && valueSet.size === 0) {
		valueSet.add(allKey);
	}

	return Array.from(valueSet);
};
