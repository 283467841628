import React, { useEffect, useState } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PHARM_USERID_PREFIX } from './consts';
import { Flex } from '../../../components/Common';
import context from '../../../context';
import { SendbirdSdkProvider } from '../../../context/sendbirdSdk';
import Channel from '../../../feature/Channel';
import ChannelList from '../../../feature/ChannelList';

function Chat() {
	const accessToken = localStorage.getItem('accessToken');
	const { userInfo } = context.user.useValue();

	const [channelUrl, setChannelUrl] = useState<string>('');
	const [showMemberInfo, setShowMemberInfo] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	/**
	 * @description ?channelUrl= 로 접근 시(=채팅 알림 시스템에서 접근)
	 * 1. searchParams channelUrl 을 localStorage 에 임시 저장 후, searchParams 삭제
	 * 2. localStorage 에 임시 저장된 channelUrl 로 채팅방 바로 접근
	 * 3. localStorage channelUrl 삭제
	 */
	useEffect(() => {
		const channelUrlFromSearchParams = searchParams.get('channelUrl');
		if (channelUrlFromSearchParams) {
			localStorage.setItem('channelUrl', channelUrlFromSearchParams);
		}
		setSearchParams({}, { replace: true });
		const channelUrlFromLocalStorage = localStorage.getItem('channelUrl');
		if (channelUrlFromLocalStorage) {
			setChannelUrl(channelUrlFromLocalStorage);
			localStorage.removeItem('channelUrl');
		}
	}, []);

	const updateFocusedChannel = (channel: GroupChannel | null) => {
		if (channel) {
			setChannelUrl(channel.url);
		} else {
			setChannelUrl('');
		}
	};

	const channelListProps = {
		onChannelSelect: updateFocusedChannel,
		selectedChannelUrl: channelUrl,
	};

	const channelProps = {
		channelUrl,
		showMemberInfo,
		onMemberInfoClick: () => {
			setShowMemberInfo((prev) => !prev);
		},
	};

	return (
		<SendbirdSdkProvider
			userId={PHARM_USERID_PREFIX + userInfo.storeId}
			accessToken={accessToken!}
			nickname={userInfo.pharmName}
		>
			<Container $alignItems={'start'} $justify={'center'} $bg={'gray_100'}>
				<Wrapper className={'app-wrap'}>
					<Wrapper className={'channellist-wrap'}>
						<ChannelList {...channelListProps} />
					</Wrapper>
					<Wrapper className={'channel-wrap'}>
						<Channel {...channelProps} />
					</Wrapper>
				</Wrapper>
			</Container>
		</SendbirdSdkProvider>
	);
}

const Container = styled(Flex)`
	height: 100%;
`;

const Wrapper = styled(Flex)`
	height: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	border-left: 1px solid ${({ theme }) => theme.colors.gray.gray_300};

	&:last-child {
		border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	}

	&.app-wrap {
		border: 0;
		max-width: 120rem;
		width: 100%;
	}

	&.channellist-wrap {
		width: 29.4rem;
	}

	&.channel-wrap {
		flex: 1;
		position: relative;
		width: calc(100% - 29.4rem);
	}
`;
export default Chat;
