import React, { useCallback, useEffect } from 'react';

import { toast } from 'react-toastify';

import { Button } from '../../../../components/Buttons';
import { VStack } from '../../../../components/Common';
import { TextArea } from '../../../../components/Forms';
import { useValues } from '../../../../hooks/useValues';
import { usePutMemberMemo } from '../../../../services/member/queries';
import { type MemberMemo, type PutMemberMemoRequest } from '../../../../services/member/types';
import { InfoTitle } from '../InfoTitle';

const INITIAL_VALUES: MemberMemo = {
	memo: '',
} as const;

export const MEMO_MESSAGES = {
	PLACEHOLDER: '어떤 고객인지 떠올릴 수 있도록 메모를 남겨보세요.',
	SUCCESS: '메모가 저장되었습니다.',
	ERROR: '메모를 저장하지 못했습니다.',
} as const;
export const MEMO_LENGTH = 300;

export const MemoInfo = ({ memo, ...params }: PutMemberMemoRequest) => {
	const { values, onChangeValues, dispatch } = useValues(INITIAL_VALUES);

	useEffect(() => {
		if (memo) dispatch('SET', { memo });
		else dispatch('RESET');
	}, [memo]);

	const { mutate } = usePutMemberMemo(params);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			mutate(
				{ ...params, memo: values.memo },
				{
					onSuccess: () => {
						toast.success(MEMO_MESSAGES.SUCCESS);
					},
					onError: (err) => {
						toast.error(`${MEMO_MESSAGES.ERROR}\n${err.response?.data.message}`);
					},
				},
			);
		},
		[values],
	);

	return (
		<VStack $gap={'0.8rem'} $alignSelf={'stretch'}>
			<InfoTitle
				title={'고객 메모'}
				renderSuffix={
					<Button
						type="submit"
						form={'memo__form'}
						buttonType="LINE"
						color="SECONDARY"
						size={{ $fontSize: 'S', $paddingSize: 'S', $heightSize: 'XS' }}
					>
						저장
					</Button>
				}
			/>
			<form id={'memo__form'} {...{ onSubmit }}>
				<TextArea
					name="memo"
					textAreaStyle={{
						resize: 'vertical',
						minHeight: '6rem',
						maxHeight: '30rem',
					}}
					onChange={onChangeValues}
					value={values.memo}
					maxLength={MEMO_LENGTH}
					count={{ show: true, max: MEMO_LENGTH }}
					placeholder={MEMO_MESSAGES.PLACEHOLDER}
				/>
			</form>
		</VStack>
	);
};
