import { type TotalsGrossProfit } from '../../../../services/statistics/queries';
import { type PrescriptionTotal, type SalesTotal } from '../../../../services/statistics/types';

import { type DescriptionCardProps } from './index';

type Rows<T extends object> = Pick<DescriptionCardProps<T>, 'rows' | 'totalRow'>;

export const PRESCRIPTIONS_ROWS: Rows<PrescriptionTotal> = {
	rows: [
		{
			items: [
				{ key: 'pxCount', label: '조제 건수', unit: '건' },
				{ key: 'insureDrugPrice', label: '급여 약가', unit: '원' },
			],
		},
		{
			items: [
				{ key: 'insurePrice', label: '급여 조제료', unit: '원' },
				{ key: 'nonInsureDrugPrice', label: '비급여 약가', unit: '원' },
			],
		},
		{
			items: [
				{ key: 'nonInsurePrice', label: '비급여 조제료', unit: '원' },
				{ key: 'receivePrice', label: '수납 금액', unit: '원' },
			],
		},
	],
	totalRow: { key: 'pxSales', label: '조제 매출', unit: '원' },
};

export const SALES_ROWS: Rows<SalesTotal> = {
	rows: [
		{
			items: [
				{ key: 'saleCount', label: '판매 건수', unit: '건' },
				{ key: 'saleMargin', label: '판매 마진', unit: '원' },
			],
		},
		{
			items: [
				{
					key: 'averageSalePrice',
					label: '객단가',
					unit: '원',
					value: (_, value) => {
						return Number(value.toFixed(2));
					},
				},
				{
					key: 'marginRatio',
					label: '마진율',
					unit: '%',
					value: (_, value) => {
						return Number(value.toFixed(2));
					},
				},
			],
		},
		{
			items: [
				{ key: 'empty', label: '', unit: '' },
				{ key: 'discountPrice', label: '할인액', unit: '원' },
			],
		},
	],
	totalRow: { key: 'sales', label: '판매 매출', unit: '원' },
};

export const TOTALS_ROWS: Rows<TotalsGrossProfit> = {
	rows: [
		{
			items: [
				{ key: 'sales', label: '판매 매출' },
				{ key: 'pxSales', label: '조제 매출' },
				{ key: 'purchasePrice', label: '사입 금액', value: (_, value) => -value },
				{ key: 'returnPrice', label: '반품 금액' },
				{ key: 'discountPrice', label: '할인 금액', value: (_, value) => -value },
			],
		},
	],
	totalRow: {
		key: 'sales',
		label: '총 매출액',
		value: ({ sales, pxSales, purchasePrice, returnPrice, discountPrice }) =>
			sales + pxSales - purchasePrice + returnPrice - discountPrice,
	},
};

export const GROSS_PROFIT_ROWS: Rows<TotalsGrossProfit> = {
	rows: [
		{
			items: [
				{ key: 'pxCount', label: '조제 건수', unit: '건' },
				{ key: 'nonInsureMargin', label: '비급여마진' },
				{ key: 'nonInsureExtraCharge', label: '비급여할증' },
				{ key: 'pxPrice', label: '조제료' },
			],
			title: '조제',
		},
		{
			items: [
				{ key: 'saleCount', label: '판매 건수', unit: '건' },
				{ key: 'sales', label: '판매 매출' },
				{
					key: 'marginRatio',
					label: '마진율',
					unit: '%',
					value: (_, value) => {
						return Number(value.toFixed(2));
					},
				},
				{ key: 'saleMargin', label: '판매 마진' },
			],
			title: '판매',
		},
	],
	totalRow: {
		key: 'sales',
		label: '매출 총이익',
		value: ({ nonInsureMargin, nonInsureExtraCharge, pxPrice, saleMargin }) =>
			nonInsureMargin + nonInsureExtraCharge + pxPrice + saleMargin,
	},
};
