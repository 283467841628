import {
	type GetPaymentsResponse,
	type GetPaymentDetailRequest,
	type GetPaymentsRequest,
	type PaymentDetail,
	type StatisticsResponse,
	type StatisticsRequest,
} from './types';
import { Get, Post } from '..';
import { formatQueryString } from '../../utils/format';

export const payment = {
	getPayments: async <T = GetPaymentsResponse>({ storeId, ...rest }: GetPaymentsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/payments${formatQueryString(rest)}`);
		return response.data;
	},
	getPaymentDetail: async <T = PaymentDetail>({ storeId, paymentId }: GetPaymentDetailRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/payments/${paymentId}`);
		return response.data;
	},
	getPaymentsExcel: async <T = Blob>({ storeId, ...rest }: GetPaymentsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/payments/excel${formatQueryString(rest)}`, {
			responseType: 'blob',
		});
		return response.data;
	},
	getCouponsStatistics: async <T = StatisticsResponse>({ storeId, ...rest }: StatisticsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/coupons/statistics${formatQueryString(rest)}`);
		return response.data;
	},
	getPointsStatistics: async <T = StatisticsResponse>({ storeId, ...rest }: StatisticsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/points/statistics${formatQueryString(rest)}`);
		return response.data;
	},
	postPickup: async <T = unknown>({ storeId, ...data }: GetPaymentDetailRequest) =>
		(await Post<T>(`/pharms/${storeId}/pickup`, data)).data,
};
