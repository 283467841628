import React from 'react';

import styled from 'styled-components';

import { changeColorKeyToType, type ColorKeys } from '../../../utils/changeColorKeyToType';
import { Label } from '../Label';

const MAX_LEVEL = 2;
const BADGE_OVER = '+';
interface BadgeProps {
	count: number | string;
	bgColor?: ColorKeys;
}
export const Badge = ({ count, bgColor = 'primary_600' }: BadgeProps) => {
	const maximumNumber = parseInt('9'.repeat(MAX_LEVEL), 10);
	return (
		<Container $bgColor={bgColor}>
			<Label $color={'white'} $fontStyle={'caption_2'}>
				{typeof count === 'string' ? count : count > maximumNumber ? `${maximumNumber + BADGE_OVER}` : count}
			</Label>
		</Container>
	);
};

const Container = styled.div<{ $bgColor: ColorKeys }>`
	height: 2rem;
	min-width: 2rem;
	padding: 0.4rem 0.6rem;
	border-radius: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ $bgColor }) => changeColorKeyToType($bgColor)};
`;
