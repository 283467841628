import React from 'react';

import styled, { keyframes } from 'styled-components';

interface LoadingProps {
	$position?: 'absolute' | 'fixed';
}
export const Loading = ({ $position = 'absolute' }: LoadingProps) => {
	return (
		<Container {...{ $position }}>
			<div className="spinner" />
		</Container>
	);
};

const rotate = keyframes`
to {
   transform: rotate(360deg); 
}
`;

const Container = styled.div<LoadingProps>`
	position: ${({ $position }) => $position};
	inset: 0;
	background-color: rgba(255, 255, 255, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	.spinner {
		width: 5rem;
		height: 5rem;
		border: 3px solid #2cc2bc;
		border-radius: 50%;
		border-top-color: #fff;

		animation: ${rotate} 1s ease-in-out infinite;
	}
`;
