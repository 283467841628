import React, { type CSSProperties } from 'react';

import styled, { css } from 'styled-components';

import { Button, type ButtonProps } from '../../Buttons/Button';
import { HStack } from '../../Common';

interface ModalFooterStyleProps {
	$hasPadding?: boolean;
	$hasBorderTop?: boolean;
	style?: CSSProperties;
}
export interface ModalFooterProps extends ModalFooterStyleProps {
	button?: ButtonProps[] | ButtonProps;
}

export const ModalFooter = ({
	$hasPadding = true,
	$hasBorderTop = true,
	button = [],
	style = {},
}: ModalFooterProps) => {
	return (
		<Container {...{ $hasBorderTop, $hasPadding, style }} $gap="0.8rem" $alignItems="center" $justify="end">
			{Array.isArray(button) ? button.map((props, idx) => <Button {...props} key={idx} />) : <Button {...button} />}
		</Container>
	);
};

const Container = styled(HStack)<ModalFooterStyleProps>`
	${({ $hasPadding }) =>
		$hasPadding &&
		css`
			padding: 1.6rem;
		`}
	${({ $hasBorderTop, theme }) =>
		$hasBorderTop &&
		css`
			border-top: 1px solid ${theme.colors.gray.gray_300};
		`}
`;
