import React, { useCallback, useMemo } from 'react';

import { addDays } from 'date-fns/addDays';
import { addMonths } from 'date-fns/addMonths';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { isSameDay } from 'date-fns/isSameDay';
import { isThisMonth } from 'date-fns/isThisMonth';
import { isThisWeek } from 'date-fns/isThisWeek';
import { isToday } from 'date-fns/isToday';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { Icon, IconID, Label } from '../../../../components/Display';
import { type PeriodUnit } from '../../../../services/statistics/queries';
import { MIN_DATE } from '../../consts';

interface DateNavigationProps {
	date: string;
	onChangeDate: (updated: Date) => void;
	unit: PeriodUnit;
}

export function DateNavigation({ date, onChangeDate, unit }: DateNavigationProps) {
	const prevButtonDisabled = useMemo(() => isSameDay(date, MIN_DATE), [date]);
	const nextButtonDisabled = useMemo(() => {
		if (unit === 'daily' && isToday(date)) {
			return true;
		}
		if (unit === 'weekly' && isThisWeek(date)) {
			return true;
		}
		if (unit === 'monthly' && isThisMonth(date)) {
			return true;
		}

		return false;
	}, [date, unit]);

	const decreaseDate = useCallback(() => {
		let updated: Date;
		switch (unit) {
			case 'daily':
				updated = addDays(date, -1);
				break;
			case 'weekly':
				updated = addDays(date, -7);
				break;
			case 'monthly':
				updated = addMonths(date, -1);
				break;
		}
		onChangeDate(updated!);
	}, [date, unit, onChangeDate]);

	const increaseDate = useCallback(() => {
		let updated: Date;
		switch (unit) {
			case 'daily':
				updated = addDays(date, 1);
				break;
			case 'weekly':
				updated = addDays(date, 7);
				break;
			case 'monthly':
				updated = addMonths(date, 1);
				break;
		}
		onChangeDate(updated!);
	}, [date, unit, onChangeDate]);

	return (
		<Inner>
			<IconButton onClick={decreaseDate} disabled={prevButtonDisabled} width={'2.8rem'} height={'2.8rem'}>
				<Icon
					id={IconID.LINE_DATE_LEFT}
					defaultColor={'gray_900'}
					disabledColor={'gray_300'}
					width={'2.8rem'}
					height={'2.8rem'}
					isDisabled={prevButtonDisabled}
				/>
			</IconButton>
			<Label $fontStyle={'title_1'} $fontWeight={'bold'} $textAlign={'center'}>
				{unit === 'daily' && format(date, 'yyyy-MM-dd (E)', { locale: ko })}
				{unit === 'weekly' &&
					`${format(date, 'yyyy-MM-dd (E)', { locale: ko })} ~ ${format(endOfWeek(date), 'yyyy-MM-dd (E)', { locale: ko })}`}
				{unit === 'monthly' && format(date, 'yyyy년 MM월')}
			</Label>
			<IconButton onClick={increaseDate} disabled={nextButtonDisabled} width={'2.8rem'} height={'2.8rem'}>
				<Icon
					id={IconID.LINE_DATE_RIGHT}
					defaultColor={'gray_900'}
					disabledColor={'gray_300'}
					width={'2.8rem'}
					height={'2.8rem'}
					isDisabled={nextButtonDisabled}
				/>
			</IconButton>
		</Inner>
	);
}

const Inner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
`;
