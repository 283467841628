import React, { useMemo } from 'react';

import { toast } from 'react-toastify';

import { Button, IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID, Tag } from '../../../../components/Display';
import { GroupsModal } from '../../../../components/Modals/GroupsModal';
import context from '../../../../context';
import { useDeleteMemberGroup, useMemberGroup, usePostMemberGroup } from '../../../../services/member/queries';
import { InfoTitle } from '../InfoTitle';

import type { MemberInfoParams } from '../../index';

export const GroupInfo = (params: MemberInfoParams) => {
	const { data, refetch, isSuccess } = useMemberGroup(params);
	const { handleOpen } = context.modal.useDispatch();

	const groupIds = useMemo(() => (data ?? []).map((d) => d.id), [data]);

	const { mutate: postGroupMutate } = usePostMemberGroup({
		...params,
		onSuccess: () => {
			toast.success('성공적으로 그룹을 지정하였습니다.');
			refetch();
		},
	});
	const { mutate: deleteGroupMutate } = useDeleteMemberGroup({
		...params,
		onSuccess: () => {
			toast.success('성공적으로 그룹을 삭제하였습니다.');
			refetch();
		},
	});

	return (
		<>
			<Flex $direction={'column'} $alignItems={'start'} $gap={'0.8rem'} $alignSelf={'stretch'}>
				<InfoTitle
					title={'소속 그룹'}
					renderSuffix={
						<Button
							onClick={() => {
								handleOpen(
									<GroupsModal
										onSelect={(groupIds) => {
											postGroupMutate({ groupIds });
										}}
										disabledGroupIds={groupIds}
									/>,
								);
							}}
							buttonType="LINE"
							color="SECONDARY"
							size={{ $fontSize: 'S', $paddingSize: 'S', $heightSize: 'XS' }}
						>
							추가
						</Button>
					}
				/>
				<Flex $gap={'0.6rem'} $wrap="wrap">
					{isSuccess &&
						data.map((group) => (
							<Tag key={'group--' + group.name} fontSize={'body_3'} color={'YELLOW'} type={'FILL'}>
								{group.name}
								<IconButton
									width="1.4rem"
									height="1.4rem"
									shouldPrevent={true}
									onClick={() => {
										deleteGroupMutate({ groupIds: [group.id] });
									}}
								>
									<Icon id={IconID.BTN_CLOSE} width="1.3rem" height="1.3rem" defaultColor="gray_600" />
								</IconButton>
							</Tag>
						))}
				</Flex>
			</Flex>
		</>
	);
};
