import React, { type CSSProperties, useMemo } from 'react';

import {
	Cell,
	Label,
	Legend,
	Pie,
	PieChart,
	type PieLabelRenderProps,
	type PieProps,
	ResponsiveContainer,
} from 'recharts';
import { type CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { type Props as LabelProps } from 'recharts/types/component/Label';
import { type Props as LegendProps } from 'recharts/types/component/Legend';

import Theme from '../../../lib/styledComponents/Theme';
import { ChartText, ChartWrapper } from '../index';
import { PieLabel } from '../PieLabel';
import { PieLegend } from '../PieLegend';

const PIE_CELL_COLORS = [
	'#625EFC',
	'#4890FC',
	'#30BFDE',
	'#43DDAF',
	'#90E13F',
	'#FFD749',
	'#FFA826',
	'#E74143',
	'#F160DA',
] as const;

type InternalLineProps = Omit<LegendProps, 'ref'>;

interface PieChartComponentProps<T extends object> extends Pick<CategoricalChartProps, 'width' | 'height' | 'margin'> {
	wrapperStyle?: CSSProperties;
	data?: T[];
	dataKey: keyof T;
	pieProps: Pick<PieProps, 'nameKey' | 'label'>;
	legendProps?: InternalLineProps;
	centerLabel: { name: string; value: string };
}

export function PieChartComponent<T extends object>({
	wrapperStyle,
	data,
	dataKey,
	width,
	height,
	margin,
	pieProps,
	legendProps,
	centerLabel,
}: PieChartComponentProps<T>) {
	if (!data) return <></>;

	/**
	 * 데이터가 없는 경우 placeholder 처럼 보여주기 위함
	 */
	const isEmpty = useMemo(() => data.every((entry) => entry[dataKey] === 0), [data, dataKey]);

	return (
		<ChartWrapper style={{ height, ...wrapperStyle }}>
			<ResponsiveContainer minHeight={height}>
				<PieChart width={width} height={height} margin={margin}>
					<Pie
						data={isEmpty ? [{ [dataKey]: 1 }] : data}
						outerRadius={100}
						innerRadius={70}
						stroke="none"
						startAngle={90}
						endAngle={-270}
						labelLine={false}
						dataKey={dataKey as string}
						label={(props: PieLabelRenderProps) => <PieLabel {...props} />}
						{...pieProps}
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={isEmpty ? Theme.colors.gray.gray_200 : PIE_CELL_COLORS[index]} />
						))}
						<Label content={(props) => <LabelContent {...props} {...centerLabel} />} />
					</Pie>
					<Legend verticalAlign={'middle'} align={'center'} content={<PieLegend />} {...legendProps} />
				</PieChart>
			</ResponsiveContainer>
		</ChartWrapper>
	);
}

/**
 *
 * @description - 파이 중앙부 라벨
 */
function LabelContent({ viewBox, name, value }: LabelProps & { name: string; value: string }) {
	if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
		const { cx, cy } = viewBox;

		return (
			<>
				<ChartText offset={5} x={cx} y={cy} textAnchor={'middle'} dy={-8} $fontWeight={'medium'}>
					{name}
				</ChartText>
				<ChartText offset={5} x={cx} y={cy} textAnchor={'middle'} dy={18} $fontWeight={'bold'} $fontStyle={'title_1'}>
					{value}
				</ChartText>
			</>
		);
	}

	return <></>;
}
