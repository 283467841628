import React, { useEffect, useMemo, useRef, useState } from 'react';

import { addDays } from 'date-fns/addDays';
import { addMonths } from 'date-fns/addMonths';
import { isAfter } from 'date-fns/isAfter';
import { min } from 'date-fns/min';
import styled, { css } from 'styled-components';

import { CustomHeader } from './CustomHeader';
import { Button } from '../../../../components/Buttons';
import { Icon, IconID, Label, MarkInfoIcon } from '../../../../components/Display';
import { FloatingModal } from '../../../../components/Modals/FloatingModal';
import { CustomDatePicker } from '../../../../lib/reactDatepicker/CustomDatePicker';
import { CustomHeader as MonthCustomHeader } from '../../../../lib/reactDatepicker/CustomHeader';
import { type PeriodUnit } from '../../../../services/statistics/queries';
import { formatDate } from '../../../../utils/format';
import { MIN_DATE } from '../../consts';
import { getDateEndOfUnit, getDateStartOfUnit } from '../../utils';

import type { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';

const createPeriodByEndDate = (offset: number, unit: PeriodUnit, date: Date) => {
	const end = getDateStartOfUnit(date, unit);
	let start = addMonths(end, -offset);
	start = addDays(start, 1);

	if (unit === 'monthly') {
		start = addMonths(start, 1);
	}

	return [getDateStartOfUnit(start, unit), end];
};

const createPeriodByStartDate = (offset: number, unit: PeriodUnit, date: Date) => {
	const start = getDateStartOfUnit(date, unit);
	let end = addMonths(start, offset);
	end = addDays(end, -1);

	return [start, getDateStartOfUnit(end, unit)];
};

interface PeriodPickerProps {
	className?: string;
	initPeriod: [Date | null, Date | null];
	offset: number; // month 기준
	onChangePeriod: (updated: [Date | null, Date | null]) => void;
	label: string;
	unit: PeriodUnit;
}

export function PeriodPicker({
	initPeriod: [initStartDate, initEndDate],
	offset,
	onChangePeriod,
	label,
	unit,
}: PeriodPickerProps) {
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const today = useMemo(() => new Date(), []);

	const pickerProps: Omit<ReactDatePickerProps, 'onChange'> = useMemo(
		() => ({
			showMonthYearPicker: unit === 'monthly',
			renderCustomHeader: (params: ReactDatePickerCustomHeaderProps) =>
				unit === 'monthly' ? <MonthCustomHeader type={'YEAR'} param={params} /> : <CustomHeader {...params} />,
		}),
		[unit],
	);

	useEffect(() => {
		let [newStartDate, newEndDate] = [initStartDate, initEndDate];
		if (newStartDate === null || newEndDate === null) {
			[newStartDate, newEndDate] = createPeriodByEndDate(offset, unit, today);
		}

		onChangePeriod([newStartDate, getDateEndOfUnit(newEndDate, unit)]);
		setIsOpen(false);
		setStartDate(newStartDate);
		setEndDate(newEndDate);
	}, [unit]);

	const handleChangeStartDate = (updated: Date | null) => {
		if (!updated || !endDate) return;
		const [updatedStart, offsetEnd] = createPeriodByStartDate(offset, unit, updated);
		let updateEnd = endDate;

		// start date after end date or range is over offset
		if (isAfter(updatedStart, endDate) || isAfter(updateEnd, offsetEnd)) {
			updateEnd = min([getDateStartOfUnit(today, unit), offsetEnd]);
		}

		setStartDate(updatedStart);
		setEndDate(updateEnd);
	};

	const handleChangeEndDate = (updated: Date | null) => {
		if (!updated || !startDate) return;
		const [offsetStart, updatedEnd] = createPeriodByEndDate(offset, unit, updated);
		let updatedStart = startDate;

		// range is over offset
		if (isAfter(offsetStart, updatedStart)) {
			updatedStart = offsetStart;
		}

		setStartDate(updatedStart);
		setEndDate(updatedEnd);
	};

	const toggleOpenPicker = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<Wrapper>
			<ButtonWrapper ref={buttonRef}>
				<Button buttonType={'LINE'} onClick={toggleOpenPicker} style={{ padding: '0 0.8rem' }}>
					<Icon id={IconID.CALENDER} isActive width="2rem" height="2rem" />
					<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
						{formatDate(isOpen ? startDate : initStartDate)}
					</Label>
					<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_600'}>
						{'~'}
					</Label>
					<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
						{formatDate(isOpen ? getDateEndOfUnit(endDate!, unit) : initEndDate)}
					</Label>
				</Button>
				<FloatingModal
					isOpen={isOpen}
					handleClose={() => {
						setIsOpen(false);
					}}
					referenceRef={buttonRef}
				>
					<PickerWrapper $weekly={unit === 'weekly'}>
						<CustomDatePicker
							open={isOpen}
							selected={startDate}
							startDate={startDate}
							endDate={endDate}
							selectsStart
							minDate={new Date(MIN_DATE)}
							maxDate={today}
							onChange={handleChangeStartDate}
							inline
							disabledKeyboardNavigation
							customInput={<></>}
							{...pickerProps}
						/>
						<CustomDatePicker
							open={isOpen}
							selected={endDate}
							startDate={startDate}
							endDate={endDate}
							selectsEnd
							minDate={startDate}
							maxDate={today}
							onChange={handleChangeEndDate}
							inline
							disabledKeyboardNavigation
							customInput={<></>}
							{...pickerProps}
						/>
					</PickerWrapper>
					<BottomWrapper>
						<InfoWrapper>
							<MarkInfoIcon type={'LINE'} bgColor={'gray_700'} color={'white'} />
							<Label $fontStyle={'label_2'} $color={'gray_700'} style={{ flex: '1 0 0' }}>
								{`${label}의 최대 선택 가능 기간은 `}
								<Label $fontStyle={'label_2'} $color={'primary_800'} $fontWeight={'bold'}>
									{`${getMaxPeriod(offset)}`}
								</Label>
								{`입니다.`}
							</Label>
						</InfoWrapper>
						<Button onClick={toggleOpenPicker} buttonType={'LINE'} color={'SECONDARY'} style={{ width: '10rem' }}>
							{'닫기'}
						</Button>
						<Button
							onClick={() => {
								onChangePeriod([startDate, getDateEndOfUnit(endDate!, unit)]);
								toggleOpenPicker();
							}}
							style={{ width: '10rem' }}
						>
							{'적용'}
						</Button>
					</BottomWrapper>
				</FloatingModal>
			</ButtonWrapper>
		</Wrapper>
	);
}

function getMaxPeriod(months: number) {
	const quotient = Math.floor(months / 12);
	const rest = months % 12;
	let result = '';

	if (quotient > 0) {
		result += `${quotient}년`;
	}

	if (rest > 0) {
		if (result) {
			result += ' ';
		}
		result += `${rest}개월`;
	}

	return result;
}

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
	position: relative;
`;

const PickerWrapper = styled.div<{ $weekly?: boolean }>`
	display: flex;
	padding: 1.6rem;
	gap: 1.6rem;

	.react-datepicker {
		box-shadow: none;
		border: none;
		width: 25.2rem;

		&__input-container {
			display: flex;
			width: 21rem;
			height: 3.6rem;
			align-items: center;

			> div {
				padding: 0 0.8rem;
				width: 100%;
			}
		}

		&__day {
			border-radius: 4px;

			${({ $weekly }) =>
				$weekly &&
				css`
					&--range-start ~ div,
					&--range-end ~ div {
						background: ${({ theme }) => theme.colors.primary.primary_700};
						color: ${({ theme }) => theme.colors.white};
					}
				`}

			&--in-selecting-range {
				background: ${({ theme }) => theme.colors.white};
				color: ${({ theme }) => theme.colors.black};
			}

			&--in-range {
				background: ${({ theme }) => theme.colors.primary.primary_200};
				color: ${({ theme }) => theme.colors.black};
			}

			&--range-start,
			&--range-end,
			&--selected {
				background: ${({ theme }) => theme.colors.primary.primary_700};
				color: ${({ theme }) => theme.colors.white};
			}

			&--disabled,
			&--outside-month {
				color: ${({ theme }) => theme.colors.gray.gray_600};
			}

			&--today {
				color: ${({ theme }) => theme.colors.blue.blue_600};
				${({ theme }) => theme.font.weight.bold};
			}
		}

		&__month {
			&-text {
				border-radius: 4px;
				&--in-selecting-range {
					background: ${({ theme }) => theme.colors.white};
					color: ${({ theme }) => theme.colors.black};
				}

				&--in-range {
					background: ${({ theme }) => theme.colors.primary.primary_200};
					color: ${({ theme }) => theme.colors.black};
				}

				&--range-start,
				&--range-end,
				&--selected {
					background: ${({ theme }) => theme.colors.primary.primary_700};
					color: ${({ theme }) => theme.colors.white};
				}

				&--today {
					color: ${({ theme }) => theme.colors.blue.blue_600};
					${({ theme }) => theme.font.weight.bold};
				}
			}
		}
	}

	.datepicker-custom-header {
		padding: 0 0.4rem 1.6rem;
	}
`;

const BottomWrapper = styled.div`
	display: flex;
	padding: 0 1.6rem 1.6rem;
	justify-content: flex-end;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
`;

const InfoWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.4rem;
	flex: 1 0 0;
`;
