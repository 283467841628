import React, { useMemo } from 'react';

import styled from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { Icon, IconID, type IconProps, Label } from '../../../../components/Display';
import { useMessageSearchContext } from '../../context/MessageSearchProvider';

const iconStyle: IconProps = {
	id: IconID.ARROW_CIRCLE,
	width: '2rem',
	height: '2rem',
	defaultColor: 'gray_800',
	disabledColor: 'gray_500',
};
/**
 * @description
 * 문구는 이전=예전 다음=최신 이지만 데이터는 최신순으로 가져오기때문에 변수명과 함수명은 반대입니다.
 */
export const SearchMessageNavigator = () => {
	const { activeSearch, onSearchMessageClick, selectedMessageId, messages, loadNext, hasNext } =
		useMessageSearchContext();

	const selectedMessageIndex = useMemo(
		() => messages.findIndex(({ messageId }) => messageId === selectedMessageId),
		[selectedMessageId, messages],
	);

	const hasPrevMessage = useMemo(() => selectedMessageIndex >= 1, [selectedMessageIndex]);
	const hasNextMessage = useMemo(() => {
		if (!messages.length) {
			return false;
		}
		return selectedMessageIndex < messages.length - 1;
	}, [selectedMessageIndex, messages.length]);

	const handlePrevClick = () => {
		if (hasPrevMessage) {
			const prevMessage = messages[selectedMessageIndex - 1];
			onSearchMessageClick(prevMessage);
		}
	};

	const handleNextClick = () => {
		if (hasNextMessage) {
			// 미리 다음 메시지 호출
			if (selectedMessageIndex === messages.length - 2 && hasNext) {
				loadNext();
			}
			if (selectedMessageIndex < messages.length - 1) {
				const nextMessage = messages[selectedMessageIndex + 1];
				onSearchMessageClick(nextMessage);
			}
		}
	};

	return (
		<Wrapper>
			<NavWrapper>
				<Label $color={'gray_800'} $fontStyle={'body_2'}>
					{'이전'}
				</Label>
				<ArrowWrapper className={'up'} onClick={handleNextClick}>
					<Icon {...iconStyle} isDisabled={!hasNextMessage || !activeSearch.resulting} />
				</ArrowWrapper>
			</NavWrapper>
			<NavWrapper>
				<Label $color={'gray_800'} $fontStyle={'body_2'}>
					{'다음'}
				</Label>
				<ArrowWrapper className={'down'} onClick={handlePrevClick}>
					<Icon {...iconStyle} isDisabled={!hasPrevMessage || !activeSearch.resulting} />
				</ArrowWrapper>
			</NavWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	position: relative;
	border-top: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	height: 7.6rem;
	padding: 1.2rem 2rem;
	justify-content: flex-end;
	align-items: center;
	align-self: stretch;
	gap: 1.6rem;
`;

const NavWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

const ArrowWrapper = styled(IconButton)`
	height: 2.4rem;

	&.down {
	}

	&.up {
		transform: rotate(180deg) translateY(0.2rem);
	}
`;
