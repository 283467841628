import { type Search, type Sorts } from '../services/types';

export const isValidCheck = (key: string, value: any) => {
	switch (key) {
		case 'sorts': {
			const typeValue = value as Sorts<string> | Array<Sorts<string>>;
			if (Array.isArray(typeValue)) {
				return typeValue.length > 0;
			} else {
				return !!typeValue.property;
			}
		}
		case 'search': {
			const typeValue = value as Search<string>;
			let mutableSearchKeyword = typeValue.searchKeyword;

			if (typeValue.searchCategory === 'PHONE' && !!typeValue.searchKeyword) {
				mutableSearchKeyword = typeValue.searchKeyword.replace(/\D/g, '');
			}
			return !!mutableSearchKeyword;
		}
		case 'isPayment':
			return value !== 'ALL';
		case 'period':
			return !!value.start || !!value.end;
		default:
			if (Array.isArray(value)) {
				const validArray = value.filter((el) => !!el && el !== '0' && el !== 'ALL' && el !== 0 && el !== -1);
				return validArray.length > 0;
			}
			return !!value && value !== 'ALL' && value !== '0' && value !== 0 && value !== -1;
	}
};
