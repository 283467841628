import type React from 'react';

// 최소 table column size
const MIN_WIDTH = 80;

const someFunctionToSetWidthOfColumn = (
	header: HTMLDivElement,
	body: NodeListOf<HTMLDivElement>,
	changeWidth: number,
) => {
	// 바뀔 값이 최소사이즈보다 작을 경우 취소
	if (changeWidth < MIN_WIDTH) return;

	// flex style code, flex-shrink를 1로 함으로 table이 넘치지 못하게 막음
	const flexStyle = `0 1 ${changeWidth}px`;
	header.style.flex = flexStyle;
	body.forEach((item) => (item.style.flex = flexStyle));
};

const handleMouseDown = (
	e: React.MouseEvent,
	idx: number,
	headerRef: React.RefObject<HTMLDivElement>,
	bodyRef: React.RefObject<HTMLDivElement>,
) => {
	if (!headerRef.current || !bodyRef.current) return;

	const header = headerRef.current.querySelectorAll('.table-item')[idx] as HTMLDivElement;
	const isCheckBox = !!bodyRef.current.querySelector('.table-body-row .table-checkbox');

	const bodyItems = bodyRef.current.querySelectorAll(
		`.table-body-row .table-item:nth-child(${idx + (isCheckBox ? 2 : 1)})`,
	);

	const initialX = e.clientX;
	const initialWidth = header.offsetWidth;

	const handleMouseMove = (ev: MouseEvent) => {
		const currentX = ev.clientX;
		const difference = currentX - initialX;

		someFunctionToSetWidthOfColumn(header, bodyItems as NodeListOf<HTMLDivElement>, initialWidth + difference);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	};

	document.addEventListener('mousemove', handleMouseMove);
	document.addEventListener('mouseup', handleMouseUp);
};
export { handleMouseDown };
