import { useReducer } from 'react';

import type { GroupChannel } from '@sendbird/chat/groupChannel';

/**
 * order 기준은 latest_last_message 만 사용해서 order 기능이 추가되지 않는다면 update_order 삭제 가능
 */
type Order = 'latest_last_message' | 'chronological' | 'channel_name_alphabetical' | 'metadata_value_alphabetical';

type Action =
	| {
			type: 'update_initialized' | 'update_refreshing';
			value: { status: boolean };
	  }
	| {
			type: 'update_channels';
			value: { channels: GroupChannel[] };
	  }
	| {
			type: 'delete_channels';
			value: { channelUrls: string[] };
	  }
	| {
			type: 'append_channels';
			value: { channels: GroupChannel[]; clearBeforeAction: boolean };
	  }
	| {
			type: 'update_order';
			value: { order?: Order };
	  };

interface State {
	initialized: boolean;
	refreshing: boolean;
	groupChannels: GroupChannel[];
	order?: Order;
}

const defaultReducer = ({ ...draft }: State, action: Action) => {
	const compareByOrder = createCompareByOrder(draft.order);

	switch (action.type) {
		case 'update_refreshing': {
			draft.refreshing = action.value.status;
			break;
		}
		case 'update_initialized': {
			draft.initialized = action.value.status;
			break;
		}
		case 'update_channels': {
			action.value.channels.forEach((freshChannel) => {
				const idx = draft.groupChannels.findIndex((staleChannel) => staleChannel.url === freshChannel.url);
				if (idx > -1) draft.groupChannels[idx] = freshChannel;
			});

			compareByOrder && (draft.groupChannels = draft.groupChannels.sort(compareByOrder));
			break;
		}
		case 'delete_channels': {
			action.value.channelUrls.forEach((url) => {
				const idx = draft.groupChannels.findIndex((c) => c.url === url);
				if (idx > -1) draft.groupChannels.splice(idx, 1);
			});

			compareByOrder && (draft.groupChannels = draft.groupChannels.sort(compareByOrder));
			break;
		}
		case 'append_channels': {
			const groupChannels = action.value.channels;
			if (action.value.clearBeforeAction) {
				// 이전 채널리스트에 덮어 씌우기
				draft.groupChannels = groupChannels;
			} else {
				draft.groupChannels = mergeObjectArrays(draft.groupChannels, groupChannels, 'url');
			}

			compareByOrder && (draft.groupChannels = draft.groupChannels.sort(compareByOrder));
			break;
		}
		case 'update_order': {
			draft.order = action.value.order;
			const compareByOrder = createCompareByOrder(draft.order);
			compareByOrder && (draft.groupChannels = draft.groupChannels.sort(compareByOrder));
			break;
		}
	}
	return draft;
};

export const useChannelListReducer = (order?: Order) => {
	const [{ initialized, refreshing, groupChannels }, dispatch] = useReducer(defaultReducer, {
		initialized: false,
		refreshing: false,
		groupChannels: [],
		order: 'latest_last_message',
	});

	const updateChannels = (channels: GroupChannel[]) => {
		dispatch({ type: 'update_channels', value: { channels } });
	};
	const deleteChannels = (channelUrls: string[]) => {
		dispatch({ type: 'delete_channels', value: { channelUrls } });
	};
	const appendChannels = (channels: GroupChannel[], clearBeforeAction: boolean) => {
		dispatch({
			type: 'append_channels',
			value: { channels, clearBeforeAction },
		});
	};
	const updateInitialized = (status: boolean) => {
		dispatch({ type: 'update_initialized', value: { status } });
	};
	const updateRefreshing = (status: boolean) => {
		dispatch({ type: 'update_refreshing', value: { status } });
	};
	const updateOrder = (order?: Order) => {
		dispatch({ type: 'update_order', value: { order } });
	};

	return {
		updateInitialized,
		updateRefreshing,
		updateChannels,
		deleteChannels,
		appendChannels,

		updateOrder,

		initialized,
		refreshing,
		groupChannels,
	};
};

const createCompareByOrder = (order?: Order) => {
	if (!order) return undefined;

	return (channel1: GroupChannel, channel2: GroupChannel): number => {
		switch (order) {
			case 'latest_last_message': {
				if (channel1.lastMessage && channel2.lastMessage) {
					return channel2.lastMessage.createdAt - channel1.lastMessage.createdAt;
				} else if (channel1.lastMessage) {
					return -1;
				} else if (channel2.lastMessage) {
					return 1;
				} else {
					return channel2.createdAt - channel1.createdAt;
				}
			}

			case 'chronological': {
				return channel2.createdAt - channel1.createdAt;
			}

			case 'channel_name_alphabetical': {
				return channel1.name.localeCompare(channel2.name);
			}
			default: {
				return 0;
			}
		}
	};
};

/**
 *
 * @param A 기존 배열 값
 * @param B 추가될 배열 값
 * @param key 중복 값을 체크할 기준(channel url)
 */
function mergeObjectArrays<T>(A: T[], B: T[], key: keyof T): T[] {
	const uniqueValues = new Set(A.map((obj) => obj[key]));
	const newArr = [...A];

	for (let i = 0; i < B.length; i++) {
		if (!uniqueValues.has(B[i][key])) {
			newArr.push(B[i]);
			uniqueValues.add(B[i][key]);
		}
	}

	return newArr;
}
