// a-z, A-Z, 0-9, '!', '@', '#', '$', '%', '^', '*', '~', '-', '_', '.' 만 허용
// 영어(소문자, 대문자 무관), 숫자, 각각 1개 이상의 문자 조합
// 특수문자는 선택사항으로 적용
// 최소 8자리
// 최대 20자리

type ErrorType = 'password' | 'confirmPassword' | 'etc';

class PasswordError extends Error {
	type: ErrorType;
	constructor(message: string, type: ErrorType) {
		super(message);
		this.name = 'PasswordError';
		this.type = type;
	}
}

interface PasswordCheckerResponse {
	isConfirm: boolean;
	errorTarget?: ErrorType;
	message?: string;
}
type PasswordChecker = (
	newPassword?: string,
	confirmPassword?: string,
	oldPassword?: string,
) => PasswordCheckerResponse;

// 비밀번호 길이
const LENGTH = {
	MIN_LENGTH: 8,
	MAX_LENGTH: 20,
} as const;

// 허용되는 문자
const REGEX = /^[a-zA-Z0-9!@#$%^*~\-_.]+$/;

// 허용되는 타입과 최소 포함 개수
const REGEX_TYPES = {
	TYPES: [/[a-zA-Z]/, /[0-9]/],
	LENGTH: 2,
} as const;

export const passwordChecker: PasswordChecker = (newPassword, confirmPassword, oldPassword) => {
	const validate = (condition: boolean, message: string, type: ErrorType) => {
		if (!condition) throw new PasswordError(message, type);
	};

	try {
		if (typeof oldPassword === 'string' && oldPassword.length < 1)
			throw new PasswordError('비밀번호가 입력되지 않았음', 'etc');

		if (!newPassword || newPassword.length < 1) throw new PasswordError('비밀번호가 입력되지 않았음', 'etc');

		// 최소 길이 검증
		validate(newPassword.length >= LENGTH.MIN_LENGTH, '최소 8자리 영문, 숫자를 조합하여 입력해 주세요.', 'password');

		// 최대 길이 검증
		validate(newPassword.length <= LENGTH.MAX_LENGTH, '패스워드는 최대 20자를 넘을 수 없습니다.', 'password');

		// 허용되는 문자 검증
		validate(REGEX.test(newPassword), `특수문자는 !, @, #, $, %, ^, *, ~, -, _, .만 가능합니다.`, 'password');

		// 문자 조합 검증
		const types = REGEX_TYPES.TYPES.filter((regex) => regex.test(newPassword)).length;
		validate(types >= REGEX_TYPES.LENGTH, `최소 1개 이상의 영문과 숫자를 포함해 주세요.`, 'password');

		// 비밀번호 일치 여부 검증
		if (typeof confirmPassword !== 'undefined')
			validate(newPassword === confirmPassword, '비밀번호가 일치하지 않습니다. 다시 입력해 주세요.', 'confirmPassword');

		return { isConfirm: true };
	} catch (err: unknown) {
		if (err instanceof PasswordError) {
			return {
				isConfirm: false,
				errorTarget: err.type,
				message: err.message,
			};
		} else {
			return {
				isConfirm: false,
				message: '알 수 없는 에러가 발생했습니다.',
			};
		}
	}
};
