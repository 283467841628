import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { INITIAL_DATA, SALES_LIST_COLUMNS } from './const';
import { Button, IconButton } from '../../../components/Buttons';
import { HStack, InsideTitle, VStack } from '../../../components/Common';
import { Icon, IconID, Label } from '../../../components/Display';
import { Modal } from '../../../components/Modals';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useGetSales } from '../../../services/sales/queries';

export const Sales = () => {
	const navigate = useNavigate();
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const { data, isLoading, isSuccess } = useGetSales(userInfo.storeId);

	const reduceData = useMemo(
		() =>
			isSuccess
				? data.content.reduce(
						(acc, cur) => {
							acc[cur.status] = [...acc[cur.status], cur];
							return acc;
						},
						{ ...INITIAL_DATA },
					)
				: { ...INITIAL_DATA },
		[data],
	);

	return (
		<VStack $gap="4rem">
			<VStack>
				<InsideTitle
					title={
						<HStack $alignItems="center" $gap="0.6rem">
							약국 이벤트 목록
							<IconButton
								onClick={() => {
									handleOpen(
										<Modal header={{ title: '약국 이벤트란?' }}>
											<VStack $padding="1.2rem 2rem 2rem" $gap="1.6rem" style={{ width: '100%', maxWidth: '48.4rem' }}>
												<Label $fontStyle="body_2">
													약국에서 자체적으로 생성할 수 있는 이벤트로 할인 이벤트를 진행할 수 있습니다. 진행하시는
													이벤트는 단골 고객님의 앱에서 약국 정보에 배너 형태로 노출됩니다.
												</Label>
												<img src={'/images/others/pharm_sales.png'} alt={'pharm_sales'} />
											</VStack>
										</Modal>,
									);
								}}
								width={'1.3rem'}
								height={'1.3rem'}
							>
								<Icon id={IconID.MARK_QUESTION} width={'1.4rem'} height={'1.4rem'} />
							</IconButton>
						</HStack>
					}
					rightRender={
						<Button
							onClick={() => {
								navigate('add');
							}}
						>
							이벤트 생성
						</Button>
					}
				/>
				<ListTable
					data={[...reduceData.RUNNING, ...reduceData.READY]}
					onRowClick={(item) => {
						navigate(`detail/${item.id}`);
					}}
					columns={SALES_LIST_COLUMNS}
					isScroll={false}
					hasLastItemBorder={false}
					{...{ isLoading }}
				/>
			</VStack>
			<VStack>
				<InsideTitle title={'종료된 약국 이벤트'} />
				<ListTable
					data={reduceData.COMPLETED}
					onRowClick={(item) => {
						navigate(`detail/${item.id}`);
					}}
					columns={SALES_LIST_COLUMNS}
					isScroll={false}
					hasLastItemBorder={false}
					{...{ isLoading }}
				/>
			</VStack>
		</VStack>
	);
};
