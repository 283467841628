import React, { useMemo } from 'react';

import { getMonth } from 'date-fns/getMonth';
import { getYear } from 'date-fns/getYear';
import styled from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { Icon, IconID } from '../../../../components/Display';
import { Select } from '../../../../components/Forms';

import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {}

export function CustomHeader({
	monthDate,
	date,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
}: CustomHeaderProps) {
	const [months, years] = useMemo(() => [range(1, 12, 1, '월'), range(new Date().getFullYear(), 2006, -1, '년')], []);

	return (
		<Wrapper>
			<IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} width={'2.8rem'} height={'2.8rem'}>
				<Icon
					id={IconID.LINE_DATE_LEFT}
					defaultColor={'gray_900'}
					disabledColor={'gray_300'}
					width={'2.8rem'}
					height={'2.8rem'}
					isDisabled={prevMonthButtonDisabled}
				/>
			</IconButton>
			<Select
				selectKey={'value'}
				options={years}
				value={getYear(date)}
				onClick={(value) => {
					changeYear(value as number);
				}}
				labelStyle={{ height: '2.8rem', padding: '0 0.8rem', width: '8.6rem' }}
			/>
			<Select
				selectKey={'value'}
				options={months}
				value={months[getMonth(monthDate)].value}
				onClick={(value) => {
					changeMonth((value as number) - 1);
				}}
				labelStyle={{ height: '2.8rem', padding: '0 0.8rem', width: '8.6rem' }}
			/>
			<IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} width={'2.8rem'} height={'2.8rem'}>
				<Icon
					id={IconID.LINE_DATE_RIGHT}
					defaultColor={'gray_900'}
					disabledColor={'gray_300'}
					width={'2.8rem'}
					height={'2.8rem'}
					isDisabled={nextMonthButtonDisabled}
				/>
			</IconButton>
		</Wrapper>
	);
}

const range = (start: number, stop: number, step: number, suffix: string) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
		const value = start + i * step;
		return {
			label: value + suffix,
			value,
		};
	});

const Wrapper = styled.div`
	display: flex;
	height: 2.8rem;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
`;
