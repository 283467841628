import React, { type CSSProperties } from 'react';

import styled from 'styled-components';

import { Flex, Loading, VStack } from '../../../../components/Common';
import { Label, type StyledLabelProps } from '../../../../components/Display';
import { commaizeNumber } from '../../../../utils/format';
export { PrescriptionsCard } from './PrescriptionsCard';
export { SalesCard } from './SalesCard';
export { TotalsGrossProfit } from './TotalsGrossProfit';

interface DescriptionItem<T> {
	key: keyof T | 'empty';
	label: string;
	unit?: string;
	value?: (item: T, value: T[keyof T]) => number;
	valueStyle?: StyledLabelProps;
}

interface DescriptionItemRow<T> {
	items: Array<DescriptionItem<T>>;
	title?: string;
}

/**
 * rows [[],[]]
 */
export interface DescriptionCardProps<T extends object> {
	data: T | undefined;
	title: string;
	rowGaps?: { vertical?: string; horizon?: string };
	rows: Array<DescriptionItemRow<T>>;
	totalRow: DescriptionItem<T>;
	isLoading?: boolean;
	style?: CSSProperties;
	cardStyle?: CSSProperties;
}

export function DescriptionCard<T extends object>(props: DescriptionCardProps<T>) {
	const {
		data,
		title,
		rows,
		rowGaps = { vertical: '1.6rem', horizon: '2.4rem' },
		totalRow,
		isLoading,
		style,
		cardStyle,
	} = props;

	return (
		<Wrapper style={style}>
			<Label {...labelStyles.title}>{title}</Label>
			<Content style={cardStyle}>
				{data && !isLoading ? (
					<>
						<RowWrapper $gap={rowGaps.horizon} className={'rows-wrapper'}>
							{rows.map(({ title, items }, idx) => {
								return (
									<RowWrapper key={idx} $gap={rowGaps.vertical} $direction={'column'} className={'row-wrapper'}>
										{title ? (
											<RowItem $height={'1.4rem'} $justify={'center'}>
												<Label {...labelStyles.rowEmphasis} $textAlign={'left'}>
													{title}
												</Label>
											</RowItem>
										) : (
											<></>
										)}
										{items.map((item, idx) => {
											return (
												<RowItem key={idx}>
													<Label {...labelStyles.rowLabel}>{item.label}</Label>
													<Label {...labelStyles.rowEmphasis} {...item.valueStyle}>
														{item.key === 'empty'
															? ''
															: item.value
																? commaizeNumber(item.value(data, data[item.key]))
																: commaizeNumber(item.value ?? (data[item.key] as number))}
													</Label>
													<Label {...labelStyles.rowLabel}>{item.unit ?? '원'}</Label>
												</RowItem>
											);
										})}
									</RowWrapper>
								);
							})}
						</RowWrapper>
						<Divider />
						<RowItem $alignItems={'center'}>
							<Label {...labelStyles.rowLabel}>{totalRow.label}</Label>
							<Label {...labelStyles.totalEmphasis} {...totalRow.valueStyle}>
								{totalRow.key === 'empty'
									? ''
									: totalRow.value
										? commaizeNumber(totalRow.value(data, data[totalRow.key]))
										: commaizeNumber(totalRow.value ?? (data[totalRow.key] as number))}
							</Label>
							<Label {...labelStyles.totalUnit}>{totalRow.unit ?? '원'}</Label>
						</RowItem>
					</>
				) : (
					<Loading />
				)}
			</Content>
		</Wrapper>
	);
}

const labelStyles: Record<string, StyledLabelProps & { style?: CSSProperties }> = {
	title: { $fontStyle: 'title_3', $fontWeight: 'bold', $color: 'gray_900' },
	totalEmphasis: {
		$fontStyle: 'title_2',
		$fontWeight: 'bold',
		$color: 'primary_700',
		$textAlign: 'right',
		style: { flex: '1 0 0' },
	},
	totalUnit: { $fontStyle: 'title_2', $fontWeight: 'medium', $color: 'gray_800' },
	rowLabel: { $fontStyle: 'label_2', $fontWeight: 'medium', $color: 'gray_800' },
	rowEmphasis: {
		$fontStyle: 'label_2',
		$fontWeight: 'bold',
		$color: 'black',
		$textAlign: 'right',
		style: { flex: '1 0 0' },
	},
} as const;

const Wrapper = styled(VStack)`
	gap: 1.2rem;
	align-items: flex-start;
	width: 100%;

	${Label} {
		white-space: nowrap;
	}
`;

const Content = styled(VStack)`
	padding: 2rem 2rem 1.2rem;
	gap: 1.6rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
`;

const RowWrapper = styled(Flex)`
	align-items: flex-end;
	width: 100%;
`;

const RowItem = styled(Flex)`
	align-self: stretch;
`;

const Divider = styled.span`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.colors.gray.gray_400};
`;
