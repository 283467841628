import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { EntireContextProvider } from '../../context';
import { useAuthContext } from '../../context/auth';
import { NAV_SMALL_WIDTH, NAV_WIDTH } from '../constant';
import Header from '../Header';
import Nav from '../Nav';

const Default = () => {
	const { isLogin } = useAuthContext();
	const location = useLocation();

	return (
		<React.Fragment>
			{isLogin ? (
				<EntireContextProvider>
					<Container $isReact={location.pathname === '/crm/chat'}>
						<Nav />
						<section className="section">
							<Header />
							<div className="inner">
								<Outlet />
							</div>
						</section>
					</Container>
				</EntireContextProvider>
			) : (
				<Navigate to="login" state={{ isReplace: true }} replace />
			)}
		</React.Fragment>
	);
};

const Container = styled.main<{ $isReact: boolean }>`
	display: flex;
	min-height: 100vh;
	padding-left: ${NAV_WIDTH};
	min-width: 102.4rem;
	> section {
		flex: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		> .inner {
			flex: 1;
			${({ $isReact }) =>
				!$isReact &&
				css`
					padding: 2rem;
					min-width: 120rem;
					max-width: 168rem;
				`}
		}
	}
	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		padding-left: ${NAV_SMALL_WIDTH};
	}
`;

export default Default;
