import React from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { type DescriptionProps, Icon, IconID } from '../../../../components/Display';
import { Modal } from '../../../../components/Modals';
import { ViewerModal } from '../../../../components/Modals/ViewerModal';
import context from '../../../../context';
import { isExpiredFile } from '../MessageContent/BasicMessage/utils';

import type { SideEffectMessageData } from '../../types';

interface SideEffectModalProps {
	messageData: SideEffectMessageData;
}
export const SideEffectModal = ({ messageData }: SideEffectModalProps) => {
	const { sideEffect } = messageData;
	const { handleOpen, handleClose } = context.modal.useDispatch();

	const itemsData: DescriptionProps<SideEffectMessageData['sideEffect']>['rows'] = [
		{ key: 'disease', label: '앓고있는 질병' },
		{ key: 'drug', label: '부작용 발생 및 의심 의약품' },
		{ key: 'symptoms', label: '부작용 의심 증상' },
		{
			key: 'files',
			label: '부작용 관련 사진',
			renderContent: (_, __, item) => (
				<>
					{(item.files ?? []).map(({ thumbnailUrl, url, expiredAt }, idx) =>
						isExpiredFile(expiredAt) ? (
							<ImageView
								key={idx}
								onClick={() => {
									toast.info(`만료된 이미지입니다.`);
									return null;
								}}
							>
								<Icon id={IconID.EXPIRED_FILE} width={'2.8rem'} height={'2.8rem'} />
							</ImageView>
						) : (
							<ImageView
								key={idx}
								$imageUrl={thumbnailUrl ?? url}
								onClick={() => {
									handleOpen(<ViewerModal imageList={item.files ?? []} triggerIndex={idx} />);
								}}
							/>
						),
					)}
				</>
			),
		},
	];

	return (
		<Modal
			header={{ title: '문의 내용' }}
			footer={{
				button: [
					{
						onClick: handleClose,
						buttonType: 'LINE',
						color: 'SECONDARY',
						children: '닫기',
						size: { $paddingSize: 'XL' },
					},
				],
			}}
		>
			<Container>
				{itemsData.map((item, idx) => (
					<SideEffectItem
						key={idx}
						label={item.label}
						content={
							item.renderContent
								? (item.renderContent(item.key, sideEffect[item.key], sideEffect) as React.ReactElement)
								: (sideEffect[item.key] as string)
						}
					/>
				))}
			</Container>
		</Modal>
	);
};

interface SideEffectItemProps {
	label: string;
	content: string | React.ReactElement;
}

const SideEffectItem = ({ label, content }: SideEffectItemProps) => {
	return (
		<ItemWrapper>
			<ItemLabel>{label}</ItemLabel>
			<ItemContent>{content}</ItemContent>
		</ItemWrapper>
	);
};

const Container = styled.div`
	display: flex;
	width: 80rem;
	padding: 0 1.6rem 2rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
`;

const ItemLabel = styled.div`
	display: flex;
	width: 18.8rem;
	padding: 1.6rem 0;
	align-items: flex-start;
	align-self: stretch;
	color: ${({ theme }) => theme.colors.gray.gray_700};
	${({ theme }) => theme.font.label.label_2};
	${({ theme }) => theme.font.weight.medium};
`;

const ItemContent = styled.div`
	display: flex;
	width: 18.8rem;
	padding: 1.6rem 1.2rem;
	gap: 1.6rem;
	flex: 1 0 0;
	align-items: flex-start;
	align-self: stretch;
	${({ theme }) => theme.font.label.label_2};
	${({ theme }) => theme.font.weight.regular};
`;

const ItemWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	align-self: stretch;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
		align-items: center;
		height: 4.8rem;
		${ItemLabel} {
			align-items: center;
		}
		${ItemContent} {
			align-items: center;
		}
	}
`;

const ImageView = styled.div<{ $imageUrl?: string }>`
	width: 8.8rem;
	height: 8.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_500};
	background-color: rgba(0, 0, 0, 0.5);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: ${({ $imageUrl }) => `url(${$imageUrl})`};
`;
