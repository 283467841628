import { type PostAndDeleteBlockMembersRequest, type GetBlockRequest, type GetBlockResponse } from './types';
import { Delete, Get, Post } from '..';
import { formatQueryString } from '../../utils/format';

export const block = {
	getBlockMembers: async <T = GetBlockResponse>({ storeId, ...params }: GetBlockRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/block-members${formatQueryString(params)}`);
		return response.data;
	},
	postBlockMembers: async <T = any>(params: PostAndDeleteBlockMembersRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${params.storeId}/block-members`, params.data);
		return response.data;
	},
	deleteBlockMembers: async <T = any>(params: PostAndDeleteBlockMembersRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${params.storeId}/block-members`, { data: params.data });
		return response.data;
	},
};
