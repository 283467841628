import React, { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { SwitchButton } from '../../../../../components/Buttons';
import { Flex, Loading, VStack } from '../../../../../components/Common';
import { InfoText, Label } from '../../../../../components/Display';
import { FormItem, TextArea, TextField, ImageInputs } from '../../../../../components/Forms';
import { ImageInfoText } from '../../../../../components/Forms/ImageInputs/ImageInfoText';
import { Modal, type ModalProps } from '../../../../../components/Modals';
import context from '../../../../../context';
import { useValues } from '../../../../../hooks/useValues';
import { useMutateWithAssets } from '../../../../../services/asset/queries';
import { pharmsKeys, useGetNotice, usePostNotice, usePutNotice } from '../../../../../services/pharm/queries';
import { type PostNoticeRequest } from '../../../../../services/pharm/types';

interface NoticeAddModalProps extends ModalProps {
	id?: number;
	refetch: () => void;
}

const COUNT = {
	TITLE: {
		MIN: 1,
		MAX: 200,
	},
	CONTENT: {
		MIN: 1,
		MAX: 2000,
	},
} as const;

const STATUS_MESSAGE = {
	TITLE: `${COUNT.TITLE.MIN}자 이상 입력해 주세요.`,
	CONTENT: `${COUNT.CONTENT.MIN}자 이상 입력해 주세요.`,
} as const;

export const NoticeAddModal = ({ id, refetch }: NoticeAddModalProps) => {
	const queryClient = useQueryClient();
	const { handleClose } = context.modal.useDispatch();
	const { userInfo } = context.user.useValue();

	const initialValues: PostNoticeRequest = useMemo(
		() => ({
			storeId: userInfo.storeId,
			id,
			title: '',
			content: '',
			isPublic: false,
			assets: [],

			assetIds: [],
			deleteAssetIds: [],
		}),
		[id, userInfo],
	);
	const { values, dispatch, onChangeValues } = useValues(initialValues);
	const [addImages, setAddImages] = useState<File[]>([]);

	const { data, isLoading, isError, error, isSuccess } = useGetNotice({
		storeId: userInfo.storeId,
		id,
	});

	useEffect(() => {
		if (isError) {
			handleClose();
			toast.error('데이터를 불러오는데 실패하였습니다.\n' + error.message);
		}
		if (isSuccess && data) {
			const { isMain, createdAt, updatedAt, ...mutableData } = data;
			dispatch('SET', mutableData);
		}
		if (!id) {
			dispatch('RESET');
		}
	}, [isError, isSuccess, data, id]);

	// 버튼 활성화 여부 조건 -- 제목 5글자이상 && 내용 20글자 이상
	const validation = useMemo(
		() => ({ title: values.title.length >= COUNT.TITLE.MIN, content: values.content.length >= COUNT.CONTENT.MIN }),
		[values.title, values.content],
	);

	const { mutate: postMutate, isPending: isPostPending } = usePostNotice({
		onSuccess: () => {
			refetch();
			handleClose();
			toast.success('새로운 소식이 등록되었습니다.');
		},
		onError: (err) => {
			toast.error('등록에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { mutate: putMutate, isPending: isPutPending } = usePutNotice({
		onSuccess: () => {
			refetch();
			handleClose();

			queryClient.invalidateQueries({
				queryKey: pharmsKeys.noticeDetail({ storeId: userInfo.storeId, id }),
			});
			setAddImages([]);
			toast.success('내용이 수정되었습니다.');
		},
		onError: (err) => {
			toast.error('등록에 실패하였습니다.\n' + err.response?.data.message);
		},
	});

	const { handleMutateWithAssets, isAssetPending } = useMutateWithAssets<PostNoticeRequest>({
		assetKey: 'assetIds',
		params: values,
		callback: (value) => {
			const { assets, ...params } = value;
			// 수정
			if (id) {
				putMutate(params);
			}
			// 등록
			else {
				postMutate(params);
			}
		},
	});

	const onSubmit = () => {
		handleMutateWithAssets(addImages, true);
	};

	return (
		<Modal
			header={{ title: id ? '내용 수정' : '소식 등록' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						size: { $paddingSize: 'XL' },
						onClick: handleClose,
					},
					{
						children: id ? '저장' : '등록',
						style: { width: '10rem' },
						onClick: onSubmit,
						shouldPrevent: true,
						disabled: !validation.content || !validation.title,
					},
				],
			}}
		>
			<Container>
				{(isLoading || isAssetPending || isPutPending || isPostPending) && <Loading />}
				<VStack>
					<FormItem
						label="제목"
						statusMessage={values.title.length > 0 && !validation.title ? STATUS_MESSAGE.TITLE : ''}
						isRequired={COUNT.TITLE.MIN > 0}
						style={{ minHeight: '7.8rem' }}
					>
						<TextField
							name="title"
							onChange={onChangeValues}
							value={values.title}
							placeholder="제목을 입력해 주세요."
							count={{ show: true, max: COUNT.TITLE.MAX }}
							maxLength={COUNT.TITLE.MAX}
						/>
					</FormItem>
					<FormItem
						label="내용"
						statusMessage={values.content.length > 0 && !validation.content ? STATUS_MESSAGE.CONTENT : ''}
						isRequired={COUNT.CONTENT.MIN > 0}
						style={{ minHeight: '21.6rem' }}
					>
						<TextArea
							name="content"
							onChange={onChangeValues}
							style={{ height: '17.6rem' }}
							value={values.content}
							placeholder="자세한 내용을 입력해 주세요."
							maxLength={COUNT.CONTENT.MAX}
							count={{ show: true, max: COUNT.CONTENT.MAX }}
						/>
					</FormItem>
				</VStack>
				<FormItem label="사진">
					<VStack $gap="1.6rem">
						<ImageInputs
							defaultAssets={values?.assets ?? []}
							addImages={addImages}
							setAddImages={setAddImages}
							onDeleteImages={(id) => {
								dispatch('SET', { deleteAssetIds: [...values.deleteAssetIds, id] });
							}}
							imageBoxSize="S"
						/>
						<InfoText
							text={'권장 크기 : 900 * 900px'}
							color={{ background: 'gray_100', font: 'primary_700' }}
							font="label_2"
							innerStyle={{ alignItems: 'center' }}
							suffix={<ImageInfoText />}
						/>
					</VStack>
				</FormItem>
				<FormItem label="노출상태" isRequired>
					<Flex $height={'4rem'} $alignItems={'center'}>
						<Flex $gap={'0.8rem'} $alignItems={'center'}>
							<SwitchButton
								isOn={values.isPublic}
								toggleSwitch={(isOn) => {
									dispatch('SET', { isPublic: isOn });
								}}
							/>
							<Label $fontStyle={'label_2'}>{values.isPublic ? '공개' : '비공개'}</Label>
						</Flex>
					</Flex>
				</FormItem>
			</Container>
		</Modal>
	);
};
const Container = styled.div`
	width: 80rem;
	min-height: 64rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;
