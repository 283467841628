import React from 'react';

import { TargetModal } from './TargetModal';
import { type TargetData } from '../..';
import { Button } from '../../../../../components/Buttons';
import { Icon, IconID } from '../../../../../components/Display';
import context from '../../../../../context';
import Theme from '../../../../../lib/styledComponents/Theme';

interface TargetProps {
	data: TargetData;
	isAll: boolean;
}
export const Target = ({ data, isAll }: TargetProps) => {
	const { handleOpen } = context.modal.useDispatch();

	return (
		<>
			<Button
				buttonType="LINE"
				color="SECONDARY"
				style={{ height: '3.2rem', padding: '0 0.8rem', color: Theme.colors.black }}
				onClick={() => {
					handleOpen(<TargetModal data={data.member} />);
				}}
				disabled={isAll}
			>
				<Icon id={IconID.USER} width="1.6rem" height="1.6rem" />
				{isAll ? '전체' : data.count}
			</Button>
		</>
	);
};
