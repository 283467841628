import React from 'react';

import { type DescriptionProps, Label } from '../../../components/Display';
import { type PharmInfo } from '../../../services/pharm/types';
import { formatPhoneNumber, formatTaxNo } from '../../../utils/format';

export const operationBasicRows: DescriptionProps<PharmInfo>['rows'] = [
	{ key: 'storeId', label: '스토어ID' },
	{ key: 'name', label: '약국명' },
	{
		key: 'regNumber',
		label: '약국번호',
		renderContent: (_, value) => (
			<Label $color={'primary_600'} $fontWeight={'bold'}>
				{value}
			</Label>
		),
	},
	{ key: 'address', label: '주소', renderContent: (_, value, item) => `${value} ${item.addressDetail}` },
	{ key: 'pharmMasterName', label: '대표약사' },
	{ key: 'tel', label: '대표 전화번호', renderContent: (_, value) => formatPhoneNumber(String(value)) },
	{ key: 'license', label: '면허번호' },
	{ key: 'taxNo', label: '사업자 번호', renderContent: (_, value) => formatTaxNo(String(value)) },
	{ key: 'managerName', label: '굿팜 담당자' },
	{ key: 'managerPhone', label: '굿팜 담당자 전화번호' },
	{ key: 'kdManagerName', label: '광동 담당자' },
];
