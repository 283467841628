import React from 'react';

import styled from 'styled-components';

import { InsideTitle, Loading } from '../../../../../components/Common';
import context from '../../../../../context';
import { CustomDatePicker } from '../../../../../lib/reactDatepicker/CustomDatePicker';
import { CustomMonthPickerInput } from '../../../../../lib/reactDatepicker/CustomMonthPickerInput';
import { useGetCouponsStatistics, useGetPointsStatistics } from '../../../../../services/payment/queries';
import { formatMonth } from '../../../../../utils/format';
import { CalculateItem } from '../CalculateItem';

export const Calculate = () => {
	const { userInfo } = context.user.useValue();

	const {
		queries: pointsQueries,
		data: pointsData,
		isLoading: isPointsLoading,
	} = useGetPointsStatistics(userInfo.storeId);
	const {
		queries: couponsQueries,
		data: couponsData,
		isLoading: isCouponsLoading,
	} = useGetCouponsStatistics(userInfo.storeId);

	return (
		<Container>
			<InsideTitle
				title="정산 조회"
				rightRender={
					// queries는  point로 사용 후 변경할 땐 point, coupon 둘 다 변경
					<CustomDatePicker
						selected={pointsQueries.values.period.start}
						dateFormat={'yyyy년 MM월'}
						customInput={
							<CustomMonthPickerInput
								date={couponsQueries.values.period.start ?? new Date()}
								increase={(period) => {
									couponsQueries.dispatch('SET', { period });
									pointsQueries.dispatch('SET', { period });
								}}
								decrease={(period) => {
									couponsQueries.dispatch('SET', { period });
									pointsQueries.dispatch('SET', { period });
								}}
							/>
						}
						onChange={(date) => {
							if (date) {
								const newMonth = formatMonth(date);
								couponsQueries.dispatch('SET', { period: newMonth });
								pointsQueries.dispatch('SET', { period: newMonth });
							}
						}}
						maxDate={new Date()}
						showMonthYearPicker
						showFourColumnMonthYearPicker
						popperPlacement={'top-end'}
					/>
				}
			/>
			<div className="contents">
				{(isPointsLoading || isCouponsLoading) && <Loading />}
				<CalculateItem data={couponsData} type="COUPON" />
				<CalculateItem data={pointsData} type="POINT" />
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	> .contents {
		position: relative;
		display: flex;
		gap: 1.6rem;
	}
`;
