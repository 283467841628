import { useEffect, useRef } from 'react';

import { GroupChannelHandler, type SendbirdGroupChat } from '@sendbird/chat/groupChannel';

import { uuidv4 } from '../../../utils/uuid';

/**
 *
 * @description 채널 이벤트 핸들러
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/event-handler/managing-channel-event-handlers/add-or-remove-a-channel-event-handler}
 */
export const useChannelHandler = (sdk: SendbirdGroupChat, hookHandler: GroupChannelHandler) => {
	const handlerRef = useRef(hookHandler);
	const handlerId = uuidv4();

	handlerRef.current = hookHandler;

	useEffect(() => {
		const handlerMapper = (handler: GroupChannelHandler): GroupChannelHandler => {
			const handlerKeys = Object.keys(handler);
			handlerKeys.forEach((key) => {
				// @ts-expect-error
				handler[key] = (...args) => handlerRef.current?.[key]?.(...args);
			});
			return handler;
		};
		sdk?.groupChannel?.addGroupChannelHandler(handlerId, handlerMapper(new GroupChannelHandler()));

		return () => {
			sdk?.groupChannel?.removeGroupChannelHandler(handlerId);
		};
	}, [sdk?.groupChannel, handlerId]);
};
