import { useEffect, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
	type PutUserInfoRequest,
	type PasswordRequest,
	type PasswordMatchesResponse,
	type UsersRequest,
	type GetUsersRequest,
	type PostUsersRequest,
	type UserInfo,
	type UserDetailRequest,
	type PutUserDetailRequest,
	type PostCheckDuplicateAccountRequest,
	type PostCheckDuplicateAccountResponse,
	type PutPasswordRequest,
	type LoginRequest,
	type HeartbeatRequest,
} from './types';
import { api } from '..';
import usePagination, { type PaginationQueries } from '../../components/Table/Pagination/hooks/usePagination';
import { type MutateCallback } from '../../hooks/types';
import { useValues } from '../../hooks/useValues';

export const usersKeys = {
	all: ['users'] as const,
	info: () => [...usersKeys.all, 'info'] as const,
	getMaster: ({ storeId }: UsersRequest) => [...usersKeys.all, 'master', storeId] as const,
	getUsers: ({ storeId }: UsersRequest, queries: GetUsersRequest, pagination: PaginationQueries) =>
		[...usersKeys.all, storeId, queries, pagination] as const,
	getUserDetail: ({ storeId, id }: UserDetailRequest) => [...usersKeys.all, 'detail', storeId, id] as const,
	postHeartbeat: () => [...usersKeys.all, 'heartbeat'] as const,
} as const;

export const useGetUserInfo = (enabled: boolean) => {
	return useQuery({
		queryKey: usersKeys.info(),
		queryFn: async () => await api.user.getUserInfo(),
		enabled,
	});
};

export const useLogin = (params: MutateCallback<LoginRequest, any>) => {
	const { onError, onSuccess } = params ?? {};
	return useMutation({
		mutationFn: async (params: LoginRequest) => await api.user.login(params),
		onSuccess,
		onError,
	});
};

export const usePutUserInfo = (params: MutateCallback<PutUserInfoRequest, any>) => {
	const queryClient = useQueryClient();
	const { onError, onSuccess } = params ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PutUserInfoRequest) => await api.user.putUserInfo(params),
		onSuccess: (data, variables, context) => {
			onSuccess?.(data, variables, context);
			queryClient.invalidateQueries({
				queryKey: usersKeys.info(),
			});
		},
		onError,
	});

	return queryInfo;
};

export const usePutPassword = (params: MutateCallback<PutPasswordRequest, any>) => {
	const { onError, onSuccess } = params ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PutPasswordRequest) => await api.user.putPassword(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const usePostPasswordMatches = (params: MutateCallback<PasswordRequest, PasswordMatchesResponse>) => {
	const { onError, onSuccess } = params ?? {};
	const queryInfo = useMutation({
		mutationFn: async (params: PasswordRequest) => await api.user.postPasswordMatches(params),
		onSuccess,
		onError,
	});

	return queryInfo;
};

export const useGetMaster = (param: UsersRequest) => {
	return useQuery({
		queryKey: usersKeys.getMaster(param),
		queryFn: async () => await api.user.getMaster(param),
	});
};

export const useGetUsers = (param: UsersRequest, isAll?: boolean) => {
	const initValue: GetUsersRequest = useMemo(
		() => ({
			sorts: {
				direction: null,
				property: null,
			},
		}),
		[],
	);
	const queries = useValues(initValue);
	const pagination = usePagination({ initListCount: isAll ? 999 : 10 });
	const { curPage, listCount, setPagination } = pagination;
	const queryInfo = useQuery({
		queryKey: usersKeys.getUsers(param, queries.values, { curPage, listCount }),
		queryFn: async () => await api.user.getUsers({ ...param, ...queries.values, page: curPage, limit: listCount }),
	});

	useEffect(() => {
		if (queryInfo.isSuccess && queryInfo.data) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data, queryInfo.isSuccess]);

	return { ...queryInfo, pagination, queries };
};

export const usePostUsers = (params: MutateCallback<PostUsersRequest, UserInfo>) => {
	const { onError, onSuccess } = params ?? {};
	return useMutation({
		mutationFn: async (params: PostUsersRequest) => await api.user.postUsers(params),
		onSuccess,
		onError,
	});
};

export const useGetUserDetail = (param: UserDetailRequest) => {
	return useQuery({
		queryKey: usersKeys.getUserDetail(param),
		queryFn: async () => await api.user.getUserDetail(param),
		enabled: !!param.id && !!param.storeId,
	});
};

export const usePutUserDetail = (params: MutateCallback<PutUserDetailRequest, any>) => {
	const { onError, onSuccess } = params ?? {};
	return useMutation({
		mutationFn: async (params: PutUserDetailRequest) => await api.user.putUserDetail(params),
		onSuccess,
		onError,
	});
};

export const useDeleteUserDetail = (params: MutateCallback<UserDetailRequest, any>) => {
	const { onError, onSuccess } = params ?? {};
	return useMutation({
		mutationFn: async (params: UserDetailRequest) => await api.user.deleteUserDetail(params),
		onSuccess,
		onError,
	});
};

export const usePostCheckDuplicateAccount = (
	params: MutateCallback<PostCheckDuplicateAccountRequest, PostCheckDuplicateAccountResponse>,
) => {
	const { onError, onSuccess } = params ?? {};
	return useMutation({
		mutationFn: async (params: PostCheckDuplicateAccountRequest) => await api.user.postCheckDuplicateAccount(params),
		onSuccess,
		onError,
	});
};

export const usePostHeartbeat = (callback: MutateCallback<HeartbeatRequest, string>) =>
	useMutation({
		mutationKey: usersKeys.postHeartbeat(),
		mutationFn: async (data) => await api.user.postHeartbeat(data),
		...callback,
	});
