import React, { useMemo } from 'react';

import { ComparisonCard, type SingleCardListProps } from '../../../components/Display';
import { type StatsDetail } from '../../../services/statistics/types';
import { CARD_LIST_ITEMS } from '../../Stats/Details/CardList';
import { sum } from '../index';

export type PickedDetail = Pick<StatsDetail, 'pxCount' | 'saleCount' | 'sales' | 'grossProfit'>;
export const initValue: PickedDetail = {
	pxCount: 0,
	saleCount: 0,
	sales: 0,
	grossProfit: 0,
};

export const DataKeys: Array<keyof PickedDetail> = ['pxCount', 'saleCount', 'sales', 'grossProfit'];

type CardListDataType = { total: number } & PickedDetail;

interface AvgCardListProps {
	ages: Array<{ total: number }>;
	details: StatsDetail[][];
	isSuccess: boolean;
}

export function AvgCardList({ ages, details, isSuccess }: AvgCardListProps) {
	const [cur, prev] = useMemo(() => {
		if (isSuccess) {
			const [curAges, prevAges] = [ages[0], ages[1]];
			const [curDetails, prevDetails] = [details[0], details[1]];

			const prevDetailsAvg = DataKeys.reduce<PickedDetail>((acc, cur) => {
				const obj = { ...acc };
				obj[cur as keyof PickedDetail] = Math.floor(sum(prevDetails, cur) / prevDetails.length);
				return obj;
			}, initValue);

			const curDetailsAvg = DataKeys.reduce<PickedDetail>((acc, cur) => {
				const obj = { ...acc };
				obj[cur as keyof PickedDetail] = Math.floor(sum(curDetails, cur) / curDetails.length);
				return obj;
			}, initValue);

			return [
				{ ...curAges, ...curDetailsAvg },
				{ ...prevAges, ...prevDetailsAvg },
			];
		}
		return [{}, {}] as [CardListDataType, CardListDataType];
	}, [isSuccess, ages, details]);

	const items: SingleCardListProps<CardListDataType>['items'] = useMemo(
		() =>
			CARD_LIST_ITEMS.map(({ title, ...rest }) => ({
				title: `금월 일평균 ` + title,
				standard: '1개월 전보다',
				...rest,
			})),
		[],
	);

	return <ComparisonCard.CardList<CardListDataType> data={cur} prevData={prev} items={items} />;
}
