export function isBoolean(val?: unknown): val is boolean {
	return typeof val === 'boolean';
}

export function isOneOf<T>(val: unknown, values: T[]): val is (typeof values)[number] {
	return values.includes(val as T);
}

export function isSet<T>(val: unknown): val is Set<T> {
	return val instanceof Set;
}

export function isNotEmptyArray(arr?: unknown[]): arr is unknown[] {
	if (!arr || !Array.isArray(arr)) return false;
	return arr.length !== 0;
}

export function isSameType(a: unknown, b: unknown): boolean {
	// we do this cause typeof can't differenciate object/ array/ null
	return Object.prototype.toString.call(a) === Object.prototype.toString.call(b);
}

export function isNumberString(value: string): boolean {
	return /^\d+$/.test(value);
}

export function areDeeplyEqual<T extends object>(x: T, y: T) {
	return JSON.stringify(x) === JSON.stringify(y);
}
