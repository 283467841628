import React, { useCallback, useRef, useState } from 'react';

import styled from 'styled-components';

import { FilterButton } from './components/FilterButton';
import { FilterModal } from './components/FilterModal';
import { type FilterItems } from './components/FilterModal/types';
import useOutsideClick from '../../hooks/useOutsideClick';
import { type UseValuesReturn } from '../../hooks/useValues';

export interface FilterProps<T extends object> {
	items: FilterItems<T>;
	resetCurPage: () => void;
	queries: UseValuesReturn<T>;
}

export const Filter = <T extends object>(props: FilterProps<T>) => {
	const [isOpen, setIsOpen] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const handleClose = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
		}
	}, [isOpen]);

	useOutsideClick(
		wrapperRef,
		() => {
			handleClose();
		},
		{ hasClassName: 'react-datepicker' },
	);

	return (
		<FilterWrap ref={wrapperRef}>
			<FilterButton
				isOpen={isOpen}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				{...props}
			/>
			<FilterModal isOpen={isOpen} handleClose={handleClose} wrapperRef={wrapperRef} {...props} />
		</FilterWrap>
	);
};
const FilterWrap = styled.div`
	position: relative;
`;
