import { useMemo } from 'react';

interface SaveLoginId {
	expirationDate: Date;
	loginId: string;
}

/**
 * @returns {Object} Storage methods and properties
 * @property {StorageObject} saveLoginId - 저장된 ID와 만료기한
 * @property {function} setLoginIdStorage - ID를 저장소에 설정하는 함수
 * @property {function} removeLoginIdStorage - 저장소에서 ID를 제거하는 함수
 */
export const useSaveLoginId = (): {
	saveLoginId: SaveLoginId | null;
	setLoginIdStorage: (id: string) => void;
	removeLoginIdStorage: () => void;
} => {
	/** key 값 */
	const KEY = 'saveLoginId';
	/** 현재 날짜 */

	/**
	 * storage에 값을 저장
	 * @param loginId 저장할 id 값
	 */
	const setLoginIdStorage = (loginId: string) => {
		// 만료기한 (현재 + 1달)
		const current = new Date();
		const EXPIRATION_DATE = current.setMonth(current.getMonth() + 1);
		const saveLoginIdValue = JSON.stringify({
			loginId,
			expirationDate: EXPIRATION_DATE,
		});
		JSON.stringify(saveLoginIdValue);

		window.localStorage.setItem(KEY, saveLoginIdValue);
	};

	/** storage에서 값을 가져온다 */
	const getLoginIdStorage = (): SaveLoginId | null => {
		const storageValue = window.localStorage.getItem(KEY);
		return storageValue ? JSON.parse(storageValue) : null;
	};

	/** storage에서 값을 삭제한다. */
	const removeLoginIdStorage = () => {
		window.localStorage.removeItem(KEY);
	};

	const saveLoginId = useMemo(() => {
		const storageValue = getLoginIdStorage();

		if (storageValue) {
			// 만료기한을 Date 형태로 변경
			const expirationDate = new Date(storageValue.expirationDate);
			// 만료기한이 지나지 않았다면 storage값을 return
			if (expirationDate >= new Date()) {
				return storageValue;
			}
			// 만료기한이 지났다면 storage에서 값을 삭제
			else {
				removeLoginIdStorage();
			}
		}
		return null;
	}, []);

	return {
		saveLoginId,
		setLoginIdStorage,
		removeLoginIdStorage,
	};
};
