import React from 'react';

import styled, { css } from 'styled-components';

import { Icon, IconID } from '../../../../Display';

interface OptionProps {
	isMultiple?: boolean;
	isSelected: boolean;
	isDisabled?: boolean;
	children?: React.ReactNode;
	onClick: () => void;
	selectedRef?: React.RefObject<HTMLLIElement>;
}

interface ContainerProps {
	$isSelected: boolean;
	$isDisabled?: boolean;
}

export const Option = ({ isSelected, isDisabled, isMultiple = false, children, onClick, selectedRef }: OptionProps) => {
	return (
		<Container
			onClick={onClick}
			$isDisabled={isDisabled}
			$isSelected={isSelected}
			ref={isSelected ? selectedRef : undefined}
		>
			<div className="option--label">{children}</div>
			{isMultiple && (
				<Icon
					id={IconID[isSelected || isDisabled ? 'CHECKBOX_SQUARE_ON' : 'CHECKBOX_SQUARE_OFF']}
					defaultColor={'gray_500'}
					activeColor="primary_600"
					isActive={isSelected}
					isDisabled={isDisabled}
				/>
			)}
		</Container>
	);
};

const Container = styled.li<ContainerProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 1.2rem;
	align-items: center;
	cursor: pointer;
	.option {
		&--label {
			flex: 1;
			${({ theme }) => theme.font.body.body_2};
			line-height: 4rem;
		}
	}
	&.category {
		min-height: 3.6rem;
		margin-top: 0.8rem;
		${({ theme }) => theme.font.body.body_2};
		font-weight: 500;
		color: ${({ theme }) => theme.colors.gray.gray_700};
		pointer-events: none;
		&:hover {
			background-color: transparent;
		}
	}
	&:hover {
		background-color: ${({ theme }) => theme.colors.gray.gray_100};
	}

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			pointer-events: none;
			background-color: ${({ theme }) => theme.colors.gray.gray_200};
			.option {
				&--label {
					color: ${({ theme }) => theme.colors.gray.gray_700};
				}
			}
		`}

	${({ $isSelected }) =>
		$isSelected &&
		css`
			background-color: ${({ theme }) => theme.colors.primary.primary_100};
			.option {
				&--label {
					color: ${({ theme }) => theme.colors.primary.primary_600};
				}
			}
		`}
`;
