import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { masterRows, tableColumns } from './rows';
import { Button } from '../../../../../components/Buttons';
import { InsideTitle } from '../../../../../components/Common';
import { Description, Icon, IconID } from '../../../../../components/Display';
import { ListTable } from '../../../../../components/Table';
import context from '../../../../../context';
import { useGetMaster, useGetUsers } from '../../../../../services/users/queries';

export const ManagingUsers = () => {
	const navigate = useNavigate();
	const { userInfo } = context.user.useValue();

	const { data } = useGetMaster({ storeId: userInfo.storeId });
	const { data: usersData, isLoading } = useGetUsers({ storeId: userInfo.storeId }, true);

	// 대표약사 제외
	const filterUsersData = useMemo(
		() => (usersData ? usersData.content.filter((user) => user.id !== userInfo.id) : []),
		[userInfo, usersData],
	);

	return (
		<Container>
			<div className="top">
				<InsideTitle title="대표약사 정보" />
				<Description data={data} rows={masterRows} />
			</div>
			<div className="bottom">
				<InsideTitle
					title={filterUsersData.length + '명의 직원'}
					rightRender={
						<Button
							buttonType="LINE"
							color="SECONDARY"
							onClick={() => {
								navigate(`/operation/basic/management/registration`);
							}}
						>
							<Icon id={IconID.BADGE} width="2rem" height="2rem" /> 직원 등록
						</Button>
					}
				/>
				<ListTable
					data={filterUsersData}
					isLoading={isLoading}
					columns={tableColumns}
					isScroll={false}
					headerStyle={{ padding: '1.2rem 0' }}
					tableStyle={{ border: 'none' }}
					tableHeaderStyle={{ border: 'none' }}
					emptyMessage="직원이 없습니다."
					onRowClick={(item) => {
						navigate(`/operation/basic/management/detail/${item.id}`);
					}}
				/>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
`;
