import React from 'react';

import styled from 'styled-components';

export const OptionsLabel = ({ children }: { children: React.ReactNode }) => {
	return <Container>{children}</Container>;
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 4rem;
	padding: 0 1.2rem;
	align-items: center;
	min-height: 3.6rem;
	margin-top: 0.8rem;
	${({ theme }) => theme.font.body.body_2};
	font-weight: 500;
	color: ${({ theme }) => theme.colors.gray.gray_700};
	pointer-events: none;
`;
