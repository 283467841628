import type React from 'react';
import { useEffect, useLayoutEffect, useCallback } from 'react';

import { usePreservedCallback } from '../usePreservedCallback';

type UseWindowKeyDown = (key: string, callback: () => void) => void;

export const useWindowKeyDown: UseWindowKeyDown = (key, callback) => {
	const handleKeydown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === key) callback();
		},
		[key, callback],
	);
	useEffect(() => {
		window.addEventListener('keydown', handleKeydown);
		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, [callback]);
};

type KeyDownCallbackMap = Record<string, (event: React.KeyboardEvent<HTMLDivElement>) => void>;

export function useKeyDown(
	ref: React.RefObject<HTMLDivElement>,
	keyDownCallbackMap: KeyDownCallbackMap,
): React.KeyboardEventHandler<HTMLDivElement> {
	useLayoutEffect(() => {
		if (!ref.current?.contains(document.activeElement)) {
			ref.current?.focus();
		}
	}, [ref.current]);

	const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = usePreservedCallback((event) => {
		const callback = keyDownCallbackMap[event.key];
		callback?.(event);
		event.stopPropagation();
	});

	return onKeyDown;
}
