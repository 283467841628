import React from 'react';

import styled from 'styled-components';

import { Icon } from '../../Display';
import { type Types } from '../../Display/Icon/types';
import { IconButton } from '../IconButton';

export interface IconButtonsData {
	icon: {
		id: Types;
		width?: string;
		height?: string;
	};
	label: JSX.Element | string | number;
	height?: string;
	onClick: () => void;
	disabled?: boolean;
}

interface IconButtonsProps {
	data: IconButtonsData[];
}

export const IconButtons = ({ data }: IconButtonsProps) => {
	return (
		<IconButtonWrap>
			{data.map((button, idx) => (
				<React.Fragment key={'icon-button-' + button.icon.id}>
					<IconButton
						width={'auto'}
						height={button.height ?? '2rem'}
						onClick={() => {
							button.onClick();
						}}
						disabled={button.disabled}
					>
						<Icon
							id={button.icon.id}
							width={button.icon.width ?? '2rem'}
							height={button.icon.height ?? '2rem'}
							isDisabled={button.disabled}
						/>
						{button.label}
					</IconButton>
					{idx !== data.length - 1 && <span className="dividing__line" />}
				</React.Fragment>
			))}
		</IconButtonWrap>
	);
};

const IconButtonWrap = styled.div`
	display: flex;
	gap: 1.2rem;
	align-items: center;
	button {
		position: relative;
		display: flex;
		align-items: center;
		gap: 0.4rem;

		${({ theme }) => theme.font.label.label_2};
	}
	.dividing__line {
		display: inline-block;
		width: 0.1rem;
		height: 1.2rem;
		background-color: ${({ theme }) => theme.colors.gray.gray_300};
	}
`;
