import React, { useMemo } from 'react';

import styled, { type CSSProperties } from 'styled-components';

import { IconButton } from '../../Buttons';
import { Icon, IconID } from '../../Display';

import type usePagination from './hooks/usePagination';

interface PaginationProps {
	pagination: ReturnType<typeof usePagination>;
	style?: CSSProperties;
	className?: string;
}

type ChangeType = 'FIRST' | 'PREV' | 'PAGE' | 'NEXT' | 'LAST';

interface HandlePage {
	(type: ChangeType): void;
	(type: ChangeType, page: number): void;
}

const Pagination = ({ pagination, style, className }: PaginationProps) => {
	const { curPage, totalPage, startPage, endPage, setCurPage } = pagination;
	const ARROW_COLOR = 'gray_900';
	const ICON_SIZE = '1.6rem';
	const ICON_BUTTON_SIZE = '3.2rem';

	const pages = useMemo(() => {
		const length = endPage - startPage + 1;
		return Array.from({ length: length || 1 }, (_, idx) => startPage + idx);
	}, [endPage, startPage]);

	const handlePage: HandlePage = (type: ChangeType, page?: number) => {
		try {
			switch (type) {
				case 'FIRST':
					setCurPage(1);
					break;
				case 'PREV':
					setCurPage(curPage - 1);
					break;
				case 'PAGE':
					if (page) setCurPage(page);
					break;
				case 'NEXT':
					setCurPage(curPage + 1);
					break;
				case 'LAST':
					setCurPage(totalPage);
					break;
				default:
					break;
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Container style={style} className={className}>
			{/* 맨앞으로 가기 버튼 */}
			<IconButton
				onClick={() => {
					handlePage('FIRST');
				}}
				className="move"
				disabled={curPage === 1}
				width={ICON_BUTTON_SIZE}
				height={ICON_BUTTON_SIZE}
			>
				<Icon
					id={IconID.PAGINATION_FIRST}
					defaultColor={ARROW_COLOR}
					width={ICON_SIZE}
					height={ICON_SIZE}
					disabledColor={'gray_500'}
					isDisabled={curPage === 1}
				/>
			</IconButton>
			{/* 앞으로가기 버튼 */}
			<IconButton
				onClick={() => {
					handlePage('PREV');
				}}
				className="move"
				disabled={curPage === 1}
				width={ICON_BUTTON_SIZE}
				height={ICON_BUTTON_SIZE}
			>
				<Icon
					id={IconID.PAGINATION_PREV}
					defaultColor={ARROW_COLOR}
					width={ICON_SIZE}
					height={ICON_SIZE}
					disabledColor={'gray_500'}
					isDisabled={curPage === 1}
				/>
			</IconButton>
			<ul className="page">
				{pages.map((page) => (
					<li key={'pagination_' + page} className={`page-item${page === curPage ? ' active' : ''}`}>
						<button
							type="button"
							onClick={() => {
								handlePage('PAGE', page);
							}}
						>
							{page}
						</button>
					</li>
				))}
			</ul>
			{/* 뒤로가기 버튼 */}
			<IconButton
				onClick={() => {
					handlePage('NEXT');
				}}
				className="move"
				disabled={curPage === totalPage || !totalPage}
				width={ICON_BUTTON_SIZE}
				height={ICON_BUTTON_SIZE}
			>
				<Icon
					id={IconID.PAGINATION_NEXT}
					defaultColor={ARROW_COLOR}
					width={ICON_SIZE}
					height={ICON_SIZE}
					disabledColor={'gray_500'}
					isDisabled={curPage === totalPage || !totalPage}
				/>
			</IconButton>
			{/* 맨 뒤로가기 버튼 */}
			<IconButton
				onClick={() => {
					handlePage('LAST');
				}}
				className="move"
				disabled={curPage === totalPage || !totalPage}
				width={ICON_BUTTON_SIZE}
				height={ICON_BUTTON_SIZE}
			>
				<Icon
					id={IconID.PAGINATION_LAST}
					defaultColor={ARROW_COLOR}
					width={ICON_SIZE}
					height={ICON_SIZE}
					disabledColor={'gray_500'}
					isDisabled={curPage === totalPage || !totalPage}
				/>
			</IconButton>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 0.6rem;
	justify-content: center;
	.move {
		transition: 0.3s color;
		&:disabled {
			cursor: auto;
		}
	}
	.page {
		display: flex;
		gap: 0.6rem;

		&-item {
			button {
				width: 3.2rem;
				height: 3.2rem;
				border-radius: 999px;
				padding: 1rem;
				display: flex;
				align-items: center;
				justify-content: center;
				${({ theme }) => theme.font.label.label_2};
				font-weight: 400;
				color: ${({ theme }) => theme.colors.gray.gray_900};
			}
			&.active {
				button {
					background-color: ${({ theme }) => theme.colors.primary.primary_600};
					color: ${({ theme }) => theme.colors.white};
				}
			}
		}
	}
`;

export default Pagination;
