import React, { useMemo } from 'react';

import { format } from 'date-fns/format';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';

import { commaizeNumber } from '../../../utils/format';
import { Icon, IconID, Label } from '../index';

import { calculateDiff, type ComparisonCardProps } from './index';

export interface WithDatesProps extends ComparisonCardProps {
	period: { cur: { startDate: string; endDate?: string }; prev: { startDate: string; endDate?: string } };
	titlePrefix?: { cur: string; prev: string };
}

export function WithDates({
	title,
	value,
	unit = '원',
	titlePrefix = { cur: '', prev: '' },
	type = 'percent',
	period,
}: WithDatesProps) {
	const { cur = 0, prev = 0 } = value;
	const { startDate, endDate } = period.cur;
	const { startDate: prevStartDate, endDate: prevEndDate } = period.prev;
	const diff = useMemo(() => calculateDiff(type, cur, prev), [type, cur, prev]);

	return (
		<Wrapper>
			<Inner>
				<DatesWrapper>{formatPeriod(prevStartDate, prevEndDate)}</DatesWrapper>
				<ItemsWrapper>
					<Label $color={'gray_900'} $fontStyle={'title_3'} $fontWeight={'medium'}>
						{titlePrefix.prev + title}
					</Label>
					<Label $fontStyle={'headline_3'} $fontWeight={'bold'}>
						{commaizeNumber(prev)}
						{unit}
					</Label>
				</ItemsWrapper>
			</Inner>
			<Inner>
				<DatesWrapper>{formatPeriod(startDate, endDate)}</DatesWrapper>
				<ItemsWrapper>
					<Label $color={'gray_900'} $fontStyle={'title_3'} $fontWeight={'medium'}>
						{titlePrefix.cur + title}
					</Label>
					<Label $fontStyle={'headline_3'} $fontWeight={'bold'}>
						{commaizeNumber(cur)}
						{unit}
					</Label>
					{diff !== 0 ? (
						<DiffWrapper>
							<Icon id={diff > 0 ? IconID.FILLED_UP : IconID.FILLED_DOWN} width={'2rem'} height={'2rem'} />
							<Label
								$color={'gray_900'}
								$fontWeight={'medium'}
								$fontStyle={'body_3'}
							>{`${commaizeNumber(Math.abs(diff))}${type === 'percent' ? '%' : ''}`}</Label>
						</DiffWrapper>
					) : (
						<></>
					)}
				</ItemsWrapper>
			</Inner>
		</Wrapper>
	);
}

function formatPeriod(start: string, end?: string) {
	let label: string = `${format(start, 'yyyy-MM-dd (E)', { locale: ko })}`;

	if (end && start !== end) {
		label += ` ~ ${format(end, 'yyyy-MM-dd (E)', { locale: ko })}`;
	}

	return label;
}

const Wrapper = styled.div`
	display: flex;
	padding: 3.2rem 0 2.8rem;
	justify-content: center;
	align-items: flex-start;
	gap: 8rem;
	align-self: stretch;

	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
`;

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
`;

const DatesWrapper = styled.div`
	display: flex;
	padding: 0.4rem 1.2rem;
	align-items: center;
	border-radius: 99px;
	border: 1px solid ${({ theme }) => theme.colors.primary.primary_600};

	color: ${({ theme }) => theme.colors.primary.primary_700};
	text-align: center;

	${({ theme }) => theme.font.body.body_3};
	${({ theme }) => theme.font.weight.medium};
`;

const ItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
`;

const DiffWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.2rem;
`;
