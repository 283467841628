import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import NavList from './components/NavList';
import { GP_V3_LIST, mobilePharmacyList, othersList } from './data/NavData';
import { scrollStyle } from '../../assets/styles/scrollStyle';
import context from '../../context';
import { formatPathArray } from '../../utils/format';
import { NAV_SMALL_WIDTH, NAV_WIDTH } from '../constant';

const Nav = () => {
	const { userInfo } = context.user.useValue();
	const { pathname } = useLocation();
	const path = useMemo(() => formatPathArray(pathname), [pathname]);
	const [openKeys, setOpenKeys] = useState<string[]>(['GPMF10']);
	const handleOpenKeys = useCallback(
		(key: string) => {
			setOpenKeys((prev) => {
				if (prev.includes(key)) {
					return prev.filter((k) => k !== key);
				} else {
					return [...prev, key];
				}
			});
		},
		[openKeys],
	);

	const NavLists = useMemo(() => [mobilePharmacyList, othersList], []);
	const ref = useRef<HTMLDivElement>(null);
	const [isHover, setIsHover] = useState(false);

	return (
		<Container
			ref={ref}
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
		>
			<h1 className="logo-wrap">
				<Link to="/crm/chat" className="logo">
					<span>굿팜</span>
				</Link>
			</h1>
			<nav>
				{NavLists.map((list, idx) => (
					<NavList
						key={'nav-list-' + idx}
						menuList={list}
						path={path}
						openKeys={openKeys}
						handleOpenKeys={handleOpenKeys}
						isParentHover={isHover}
					/>
				))}
				{process.env.REACT_APP_HOST_ENV === 'development' &&
					userInfo.storeId === '20240710987' &&
					[...GP_V3_LIST].map((list, idx) => (
						<NavList
							key={'nav-list-' + idx}
							menuList={list}
							path={path}
							openKeys={openKeys}
							handleOpenKeys={handleOpenKeys}
							isParentHover={isHover}
						/>
					))}
			</nav>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 991;
	min-width: ${NAV_WIDTH};
	box-sizing: border-box;
	height: 100vh;
	${scrollStyle({ hideHorizontal: true })};
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
	border-right: 1px solid ${({ theme }) => theme.colors.gray.gray_300};

	.logo {
		position: relative;
		width: 15.9rem;
		height: 2.8rem;
		background-image: url('/images/logo/logo-dark-pharm.svg');
		background-position: left center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: 0.3s all;

		&-wrap {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 8rem;
		}

		span {
			overflow: hidden;
			line-height: 0;
			text-indent: -9999px;
			position: absolute;
			width: 0;
			height: 0;
		}
	}

	@media screen and (${({ theme }) => theme.breakPoint.large}) {
		min-width: ${NAV_SMALL_WIDTH};
		transition: 0.3s min-width;
		&:not(:hover) .logo {
			width: 3.6rem;
			height: 3.2rem;
		}

		&:hover {
			min-width: ${NAV_WIDTH};
		}
	}
`;
export default Nav;
