import { useEffect, useState } from 'react';

import { ConnectionHandler } from '@sendbird/chat';

import { uuidv4 } from '../../../utils/uuid';

import type { SendbirdGroupChat } from '@sendbird/chat/groupChannel';

function useOnlineStatus(sdk: SendbirdGroupChat) {
	const [isOnline, setIsOnline] = useState(window?.navigator?.onLine ?? true);

	useEffect(() => {
		const handlerId = uuidv4();
		try {
			const handler = new ConnectionHandler({
				onDisconnected() {
					setIsOnline(false);
				},
				onReconnectStarted() {
					setIsOnline(false);
				},
				onReconnectSucceeded() {
					setIsOnline(true);
				},
				onReconnectFailed() {
					sdk.reconnect();
				},
			});

			if (sdk?.addConnectionHandler) {
				sdk.addConnectionHandler(handlerId, handler);
			}
		} catch {}

		return () => {
			try {
				sdk.removeConnectionHandler(handlerId);
			} catch {}
		};
	}, [sdk]);

	useEffect(() => {
		const tryReconnect = () => {
			try {
				if (sdk.connectionState !== 'OPEN') {
					// connection is not broken yet
					sdk.reconnect();
				}
			} catch {}
		};

		window.addEventListener('online', tryReconnect);
		return () => {
			window.removeEventListener('online', tryReconnect);
		};
	}, []);

	return isOnline;
}

export default useOnlineStatus;
