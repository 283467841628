import React, { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { informationRows, paymentsRows } from './rows';
import { Button } from '../../../../components/Buttons';
import { Loading, InsideTitle, Footer, VStack } from '../../../../components/Common';
import { Description } from '../../../../components/Display';
import { ConfirmModal } from '../../../../components/Modals';
import context from '../../../../context';
import { useGetPaymentDetail, usePostPickup } from '../../../../services/payment/queries';

export const HistoryDetail = () => {
	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const { paymentId } = useParams();

	const { data, isLoading, isError, error, isSuccess, refetch } = useGetPaymentDetail({
		storeId: userInfo?.storeId,
		paymentId,
	});

	const { mutate, isPending } = usePostPickup({
		onSuccess: () => {
			toast.success('상태 변경에 성공하였습니다.');
			refetch();
		},
		onError: (error) => {
			toast.error('상태 변경에 실패하였습니다.\n' + error.response?.data.message);
		},
	});

	const onPickup = useCallback(() => {
		handleOpen(
			<ConfirmModal
				confirm={() => {
					mutate({ storeId: userInfo.storeId, paymentId });
				}}
				message={'수령완료 상태로 변경하시겠습니까?'}
			/>,
		);
	}, [userInfo, paymentId]);

	useEffect(() => {
		if (isError && error) {
			toast.error(error.response?.data.message);
		}
	}, [isError]);

	return (
		<VStack $gap="2.4rem">
			{(isLoading || isPending) && <Loading $position="fixed" />}
			{isSuccess && (
				<Container>
					<div className="item">
						<InsideTitle title="회원 정보" />
						<Description data={data} rows={informationRows} />
					</div>
					<div className="item">
						<InsideTitle title="결제 내역 정보" />
						<Description data={data} rows={paymentsRows} />
					</div>
				</Container>
			)}
			{!data?.pickupAt && (
				<Footer>
					<Button
						style={{ width: '20rem' }}
						onClick={() => {
							onPickup();
						}}
					>
						수령 완료
					</Button>
				</Footer>
			)}
		</VStack>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
	.item {
		display: flex;
		flex-direction: column;
	}
`;
