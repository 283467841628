import { useLayoutEffect, useRef, useState } from 'react';

export function useLazyImageLoader(elemRef: React.MutableRefObject<any>) {
	const isLoaded = useRef(false);
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useLayoutEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;
			if (entry) setIsVisible(entry.isIntersecting);
		});

		if (elemRef.current) observer.observe(elemRef.current);
		return () => {
			observer.disconnect();
		};
	}, [elemRef.current]);

	if (isVisible) isLoaded.current = true;
	return isLoaded.current;
}
