import { useEffect, useLayoutEffect, useMemo } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';

import { schedulerFactory } from './schedularFactory';

export interface MarkAsReadSchedulerType {
	push: (channel: GroupChannel) => void;
	clear: () => void;
	getQueue: () => GroupChannel[];
}

interface Params {
	isConnected: boolean;
}

/**
 *
 * @description 메시지 읽음 처리 스케줄러
 */
export function useMarkAsReadScheduler({ isConnected }: Params): MarkAsReadSchedulerType {
	const markAsReadScheduler = useMemo(
		() =>
			schedulerFactory<GroupChannel>({
				cb: (channel) => {
					try {
						channel.markAsRead();
					} catch (error) {
						console.log('Channel: Mark as read failed', {
							channel,
							error,
						});
					}
				},
			}),
		[],
	);

	useEffect(() => {
		// for simplicity, we clear the queue when the connection is lost
		if (!isConnected) {
			markAsReadScheduler.clear();
		}
	}, [isConnected]);

	useLayoutEffect(() => {
		return () => {
			markAsReadScheduler.clear();
		};
	}, []);

	return markAsReadScheduler;
}
