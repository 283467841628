import React, { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Flex, Loading, VStack } from '../../../../components/Common';
import { Description, Tag, type DescriptionProps, TextEditorViewer } from '../../../../components/Display';
import { useGetPromotion } from '../../../../services/promotion/queries';
import {
	type PromotionStatus,
	type PromotionDetail as PromotionDetailType,
	type PromotionProduct,
} from '../../../../services/promotion/types';
import { PROMOTION_STATUS_TAG_COLOR, PROMOTION_STATUS_VALUES } from '../../../../utils/consts';

export const PromotionDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { isLoading, isError, isSuccess, data } = useGetPromotion({ id: Number(id) });

	useEffect(() => {
		if (isError) {
			toast.error('유효하지 않은 프로모션 입니다.');
			navigate(-1);
		}
	}, [isError]);

	const PromotionDetailRows: DescriptionProps<PromotionDetailType>['rows'] = useMemo(
		() => [
			{ key: 'title', label: '이벤트명' },
			{
				key: 'startDate',
				label: '이벤트 기간',
				renderContent: (_, value, item) =>
					String(value) + ' ~ ' + (item.isUntilSoldOut ? '소진 시까지' : item.endDate ?? ''),
			},
			{
				key: 'status',
				label: '이벤트 진행상태',
				renderContent: (_, value) => (
					<Tag type="FILL" color={PROMOTION_STATUS_TAG_COLOR[value as PromotionStatus]}>
						{PROMOTION_STATUS_VALUES[value as PromotionStatus]}
					</Tag>
				),
			},
			{
				key: 'products',
				label: '이벤트 상품',
				renderContent: (_, value) => (
					<Flex $direction="column" $gap="0.8rem">
						{(value as PromotionProduct[]).map((product, idx) => (
							<ProductItem key={'product--' + idx} className="product" $url={product.imageUrl}>
								<div className="product--image" />
								<Flex $direction="column" $gap="0.4rem" $flex="1">
									<p className="product--name">{product.name}</p>
									<p className="product--price">
										<span className={product.discountPrice ? 'product--discount' : ''}>
											{product.originalPrice.toLocaleString()}
										</span>
										{product.discountPrice && <span>{product.discountPrice.toLocaleString()}</span>}
									</p>
								</Flex>
							</ProductItem>
						))}
					</Flex>
				),
			},
			{
				key: 'url',
				label: '이벤트 상세 페이지',
				renderContent: (_, value) => (
					<a href={String(value)} target="_blank" rel="noreferrer">
						{String(value)}
					</a>
				),
			},
		],
		[],
	);

	if (isLoading) {
		return <Loading />;
	}

	if (isSuccess) {
		return (
			<VStack $gap="4rem">
				<Description data={data} rows={PromotionDetailRows} />
				<TextEditorViewer data={data.content} />
			</VStack>
		);
	}
};

const ProductItem = styled.div<{ $url: string }>`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	.product {
		&--image {
			width: 6rem;
			height: 6rem;
			background-color: ${({ theme }) => theme.colors.gray.gray_300};
			background-image: url(${({ $url }) => $url});
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		&--name {
			${({ theme }) => theme.font.label.label_1};
			font-weight: 600;
		}
		&--price {
			${({ theme }) => theme.font.label.label_2};
			display: flex;
			gap: 0.2rem;
			span {
				&::after {
					content: '원';
				}
			}
		}
		&--discount {
			color: ${({ theme }) => theme.colors.gray.gray_600};
			text-decoration: line-through;
		}
	}
`;
