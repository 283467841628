import React from 'react';

import { PasswordCheckModal } from './components/PasswordCheckModal';
import { operationBasicRows } from './rows';
import { Button } from '../../../components/Buttons';
import { Flex, InsideTitle } from '../../../components/Common';
import { Description, Icon, IconID, InfoText } from '../../../components/Display';
import context from '../../../context';
import { useGetPharmInfo } from '../../../services/pharm/queries';

const Basic = () => {
	const { userInfo } = context.user.useValue();
	const { data } = useGetPharmInfo({ storeId: userInfo.storeId });
	const { handleOpen } = context.modal.useDispatch();

	return (
		<Flex $direction="column">
			<InsideTitle
				title="기본 정보"
				rightRender={
					<Button
						buttonType="LINE"
						color="SECONDARY"
						onClick={() => {
							handleOpen(<PasswordCheckModal />);
						}}
					>
						<Icon id={IconID.USER_SETTING} width="2rem" height="2rem" />
						사용자 관리
					</Button>
				}
			/>
			<Description data={data} rows={operationBasicRows} />
			<InfoText
				text={'기본정보 수정을 원하시는 경우 굿팜 고객센터(1600-3122)에 문의해 주세요.'}
				color={{ background: 'white', font: 'gray_700' }}
				style={{ minHeight: '4.8rem', padding: 0, flexDirection: 'row', alignItems: 'center' }}
				innerStyle={{ alignItems: 'center' }}
			/>
		</Flex>
	);
};
export default Basic;
