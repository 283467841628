import React from 'react';

import styled, { css } from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID, Disclosure } from '../../../../components/Display';
import { InfoTitle } from '../InfoTitle';

interface InfoDisclosureProps {
	title: string;
	children: React.ReactElement;
}
export const InfoDisclosure = ({ title, children }: InfoDisclosureProps) => {
	return (
		<Disclosure defaultIsOpen={true}>
			{({ isOpen, toggle }) => (
				<Flex $direction={'column'} $alignItems={'start'} $gap={'1.6rem'} $alignSelf={'stretch'}>
					<InfoTitle
						title={title}
						renderSuffix={
							<ButtonWrap $isOpen={isOpen}>
								<IconButton onClick={toggle} width={'1.4rem'} height={'1.4rem'}>
									<Icon
										id={IconID.CHEVRON}
										defaultColor={'gray_600'}
										width={'1.4rem'}
										height={'1.4rem'}
										className={'arrow'}
									/>
								</IconButton>
							</ButtonWrap>
						}
					/>
					{isOpen && children}
				</Flex>
			)}
		</Disclosure>
	);
};

const ButtonWrap = styled(Flex)<{ $isOpen: boolean }>`
	.arrow {
		transition: 0.3s transform;
		transform: ${({ $isOpen }) =>
			$isOpen ? css`rotate(180deg) translateY(0rem)` : css`rotate(0deg) translateY(0rem)`};
	}
`;
