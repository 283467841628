import { useReducer } from 'react';

import type { SendbirdGroupChat } from '@sendbird/chat/groupChannel';

interface State {
	error: boolean;
	initialized: boolean;
	loading: boolean;
	sdk: SendbirdGroupChat;
}

type Action =
	| {
			type: 'init_sdk';
			value: { sdk: State['sdk'] };
	  }
	| {
			type: 'sdk_loading';
			value: { status: boolean };
	  }
	| {
			type: 'sdk_error';
	  }
	| {
			type: 'reset_sdk';
	  };

const initialState: State = {
	initialized: false,
	loading: false,
	sdk: {} as State['sdk'],
	error: false,
};

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'init_sdk':
			return {
				sdk: action.value.sdk,
				initialized: true,
				loading: false,
				error: false,
			};
		case 'sdk_loading':
			return {
				...state,
				initialized: false,
				loading: action.value.status,
			};
		case 'sdk_error':
			return {
				...state,
				initialized: false,
				loading: false,
				error: true,
			};
		case 'reset_sdk':
			return initialState;
	}
};

export const useSdkReducer = () => {
	const [sdkStore, dispatch] = useReducer(reducer, initialState);

	const initSdk = (sdk: State['sdk']) => {
		dispatch({ type: 'init_sdk', value: { sdk } });
	};

	const updateSdkLoading = (status: boolean) => {
		dispatch({ type: 'sdk_loading', value: { status } });
	};

	const updateSdkError = () => {
		dispatch({ type: 'sdk_error' });
	};

	const resetSdk = () => {
		dispatch({ type: 'reset_sdk' });
	};

	return {
		sdkStore,
		sdkDispatcher: {
			initSdk,
			updateSdkLoading,
			updateSdkError,
			resetSdk,
		},
	};
};
