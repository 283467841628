import styled, { css, type DefaultTheme } from 'styled-components';

import { changeColorKeyToType, type ColorKeys } from '../../../utils/changeColorKeyToType';
import { changeFontKeyToType, type FontKeys } from '../../../utils/changeFontKeyToType';

export interface StyledLabelProps {
	$color?: ColorKeys;
	$fontStyle?: FontKeys;
	$fontWeight?: keyof DefaultTheme['font']['weight'];
	$textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
	$alignSelf?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
}

export const Label = styled.span<StyledLabelProps>`
	color: ${({ $color = 'black' }) => $color && changeColorKeyToType($color)};
	${({ $fontStyle }) => $fontStyle && changeFontKeyToType($fontStyle)};
	${({ theme, $fontWeight = 'regular' }) => $fontWeight && theme.font.weight[$fontWeight]}
	${({ $textAlign }) =>
		$textAlign &&
		css`
			text-align: ${$textAlign};
		`}
	${({ $alignSelf }) =>
		$alignSelf &&
		css`
			align-self: ${$alignSelf};
		`}
`;
