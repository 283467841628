import { type DescriptionProps } from '../../../../../components/Display';
import { type Columns } from '../../../../../components/Table/ListTable/types';
import { type UserInfo } from '../../../../../services/users/types';
import { USER_TYPE_VALUES } from '../../../../../utils/consts';
import { formatPhoneNumber } from '../../../../../utils/format';

export const masterRows: DescriptionProps<UserInfo>['rows'] = [
	{ key: 'name', label: '계정명' },
	{ key: 'loginId', label: '아이디' },
	{ key: 'phone', label: '연락처', renderContent: (_, value, item) => formatPhoneNumber(String(value)) },
];

export const tableColumns: Columns<UserInfo> = [
	{ key: 'loginId', headerName: '직원 ID' },
	{ key: 'name', headerName: '직원명' },
	{ key: 'type', headerName: '권한', renderComponent: (value) => USER_TYPE_VALUES[value] },
	{ key: 'phone', headerName: '휴대폰 번호', renderComponent: (value) => formatPhoneNumber(value) },
	{ key: 'isActive', headerName: '근무 상태', renderComponent: (value) => (value ? '근무' : '퇴사') },
];
