import React, { useRef } from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import styled from 'styled-components';

import { Button, IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID, Label } from '../../../../components/Display';
import { Tags } from '../../../../components/Display/Tags';
import { ContextMenu, MenuItems } from '../../../../components/Modals/ContextMenu';
import { useSBStateContext } from '../../../../context/sendbirdSdk';
import { useMemberInfo } from '../../../../services/member/queries';
import { ChannelAvatar } from '../../../ChannelList/components/ChannelAvatar';
import { getChannelTitle } from '../../../ChannelList/components/ChannelListItem/utils';
import { ChannelStatusMenu } from '../../../ChannelList/components/ChannelStatusMenu';
import { MessageSearch } from '../../../MessageSearch';
import { useMessageSearchContext } from '../../../MessageSearch/context/MessageSearchProvider';
import { useChannelContext } from '../../context/ChannelProvider';
import { getMemberId, getStoreId } from '../../context/utils';

interface ChannelHeaderProps {
	currentChannel: GroupChannel;
}

export const ChannelHeader = (props: ChannelHeaderProps) => {
	const { currentChannel } = props;

	const { stores } = useSBStateContext();
	const { userId: currentUserId } = stores.userStore.user;

	const { showMemberInfo, onMemberInfoClick, member } = useChannelContext();
	const { profileUrl, connectionStatus, userId } = member;

	const { activeSearch, onSearchStart } = useMessageSearchContext();

	const contextmenuRef = useRef(null);

	const storeId = getStoreId(currentUserId);
	const memberId = getMemberId(userId);

	const { data: nicknames } = useMemberInfo<string[]>({ storeId, userId: memberId, select: (data) => data.nicknames });

	return (
		<Container
			$height={'7.2rem'}
			$padding={'1.6rem 2rem'}
			$justify={'between'}
			$alignItems={'center'}
			$alignSelf={'stretch'}
		>
			{!activeSearch?.searching ? (
				<>
					<Wrapper>
						<ChannelAvatar
							profileUrl={profileUrl}
							width="4.8rem"
							height="4.8rem"
							status={{ isOnline: connectionStatus === 'online' }}
						/>
						<Flex $direction={'column'} $gap="0.6rem">
							<Flex $alignItems={'center'} $gap={'0.6rem'}>
								<Label $fontStyle={'title_3'} $fontWeight={'bold'}>
									{getChannelTitle(currentChannel, currentUserId)}
								</Label>
								<Button
									buttonType="LINE"
									color="SECONDARY"
									size={{ $fontSize: 'S', $paddingSize: 'XS', $heightSize: 'XS' }}
									onClick={onMemberInfoClick}
								>
									{showMemberInfo ? '정보 닫기' : '정보 보기'}
								</Button>
							</Flex>

							{nicknames && nicknames.length > 0 && (
								<Tags
									data={nicknames}
									style={{ height: '2rem' }}
									fontSize="caption_2"
									fontWeight="medium"
									color="PRIMARY"
									type="FILL"
								/>
							)}
						</Flex>
					</Wrapper>
					<Wrapper>
						<IconButtonWrapper onClick={onSearchStart}>
							<Icon id={IconID.BTN_SEARCH} width={'2rem'} height={'2rem'} />
						</IconButtonWrapper>
						<ContextMenu
							menuTrigger={(toggleDropdown: () => void) => (
								<IconButtonWrapper ref={contextmenuRef} onClick={toggleDropdown}>
									<Icon id={IconID.BTN_MORE} width={'0.3rem'} height={'1.6rem'} />
								</IconButtonWrapper>
							)}
							menuItems={(close: () => void) => (
								<MenuItems parentRef={contextmenuRef} closeDropdown={close} openLeft={true}>
									<ChannelStatusMenu channel={currentChannel} currentUserId={currentUserId} close={close} />
								</MenuItems>
							)}
						/>
					</Wrapper>
				</>
			) : (
				<MessageSearch />
			)}
		</Container>
	);
};

const Container = styled(Flex)`
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	position: relative;
	gap: 1rem;
	height: 7.2rem;
`;

const Wrapper = styled(Flex)`
	gap: 1.2rem;
	align-items: center;
`;

const IconButtonWrapper = styled(IconButton)`
	display: flex;
	align-items: center;
	justify-content: center;
`;
