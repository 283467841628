import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { groupCreateMemberModalTableColumns } from './tableColumns';
import { Icon } from '../../../../components/Display';
import { FilterListItem } from '../../../../components/Filter/components/FilterListItem';
import { type FilterItems } from '../../../../components/Filter/components/FilterModal/types';
import { TextField } from '../../../../components/Forms';
import { Modal, type ModalProps } from '../../../../components/Modals';
import { ListTable } from '../../../../components/Table';
import context from '../../../../context';
import { useSelectedItems } from '../../../../hooks/useSelectedItems';
import { useValues } from '../../../../hooks/useValues';
import { useGetMembers } from '../../../../services/member/queries';
import {
	type GetMemberSearchType,
	type BasicMemberInfo,
	type GetMembersRequest,
	type MemberData,
} from '../../../../services/member/types';
import { type Search } from '../../../../services/types';
import { type IsFavorite, AGE_OPTIONS, GENDER_OPTIONS } from '../../../../utils/consts';
import { handleChangeSorts } from '../../../../utils/onChangeSorts';

interface MemberAddModalProps extends ModalProps {
	handleSelectedMembers: (param: BasicMemberInfo[]) => void;
	isFavorite?: IsFavorite;
}

export const MemberAddModal = ({ handleSelectedMembers, isFavorite }: MemberAddModalProps) => {
	const { userInfo } = context.user.useValue();
	const { handleClose } = context.modal.useDispatch();

	const { data, isLoading, pagination, queries } = useGetMembers({
		storeId: userInfo.storeId,
		initialValues: { isFavorite },
		historyKey: { pagination: 'favoriteGetMemberPageAddModal', queries: 'favoriteGetMemberAddModal' },
	});
	const { selectedItems, handleSelectedItems } = useSelectedItems<MemberData>({});

	const onClose = useCallback(async () => {
		await new Promise((resolve, reject) => {
			queries.dispatch('RESET');
			tmpSearchDispatch('RESET');
			pagination.setCurPage(1);
			resolve(200);
		}).then((res) => {
			if (res) handleClose();
		});
	}, []);

	const initTmpSearch: Search<GetMemberSearchType> = useMemo(
		() => ({
			searchCategory: 'NAME',
			searchKeyword: '',
		}),
		[],
	);
	const {
		values: tmpSearch,
		dispatch: tmpSearchDispatch,
		onChangeValues: onChangeTmpSearch,
	} = useValues({ ...initTmpSearch });

	const onSearchSubmit = () => {
		pagination.resetCurPage();
		queries.dispatch('SET', { search: tmpSearch });
	};

	const onSubmit = useCallback(() => {
		handleSelectedMembers(selectedItems);
		onClose();
	}, [selectedItems]);

	const filterItems = useMemo(() => {
		return [
			{
				label: '성별',
				key: 'gender',
				type: 'RADIO',
				radioProps: {
					name: 'gender',
					options: GENDER_OPTIONS,
					direction: 'COLUMN',
				},
			},
			{
				label: '연령대',
				key: 'ages',
				type: 'SELECT',
				selectProps: {
					multiple: { allKey: 'ALL', label: '연령' },
					options: AGE_OPTIONS,
				},
			},
			{
				label: '약국 등록일',
				key: 'period',
				type: 'PERIOD',
				periodDateProps: {
					periodDirection: 'COLUMN',
				},
			},
		] as FilterItems<GetMembersRequest>;
	}, []);

	return (
		<Modal
			header={{ title: '회원 추가' }}
			footer={{
				button: [
					{
						children: '취소',
						buttonType: 'LINE',
						color: 'SECONDARY',
						onClick: onClose,
						size: { $paddingSize: 'XL' },
					},
					{
						children: '저장',
						onClick: onSubmit,
						style: { width: '10rem' },
						disabled: selectedItems.length <= 0,
						shouldPrevent: true,
					},
				],
			}}
		>
			<Container>
				<ListTable
					isLoading={isLoading}
					data={data?.content}
					columns={groupCreateMemberModalTableColumns}
					selected={{ selectKey: 'userId', multiple: { selectedItems, handleSelectedItems } }}
					onCategoryClick={(column) => {
						handleChangeSorts(queries.values.sorts, column, (sorts) => {
							queries.dispatch('SET', {
								sorts,
							});
						});
					}}
					sortValues={queries.values.sorts}
					pagination={pagination}
					maxHeight="35.2rem"
					bodyItemStyle={{ minHeight: '4.7rem' }}
					tableHeaderItemStyle={{ minHeight: '4.7rem' }}
					footerStyle={{ padding: '1.2rem 2rem' }}
					unit="명"
					headerLeftContent={
						<div className="filter">
							{filterItems.map((item) => {
								return (
									<FilterListItem
										key={'active-item-' + String(item.key)}
										item={item}
										queries={queries}
										resetCurPage={pagination.resetCurPage}
										isDelete={false}
										hasAll
										itemStyle={{}}
									/>
								);
							})}
						</div>
					}
					headerRightContent={
						<TextField
							renderPrefix={<Icon id="btn-search" width="2rem" height="2rem" />}
							inputSize={'sm'}
							style={{ borderRadius: '4px', width: '20rem' }}
							placeholder="회원명을 입력해주세요."
							value={tmpSearch.searchKeyword}
							name="searchKeyword"
							onChange={onChangeTmpSearch}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									onSearchSubmit();
								}
							}}
						/>
					}
				/>
			</Container>
		</Modal>
	);
};

const Container = styled.form`
	width: 80rem;
	padding: 0 2rem 3.2rem;

	.filter {
		display: flex;
		gap: 0.8rem;
	}
`;
