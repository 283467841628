import React, { useMemo, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';

import { downloadFile, isAnnouncementMessage, isExpiredFile } from './utils';
import { Icon, IconID, ImageRenderer } from '../../../../../components/Display';
import { ContextMenu, MenuItem, MenuItems } from '../../../../../components/Modals/ContextMenu';
import { ViewerModal } from '../../../../../components/Modals/ViewerModal';
import context from '../../../../../context';
import { noop } from '../../../../../utils/noop';
import { useChannelContext } from '../../../context/ChannelProvider';
import { getFileData, getFileMessageData } from '../../../getMessageData';
import { type CoreMessageType } from '../../../utils';

const SHOW_IMAGE_MAX_COUNT = 4;

const IMAGE_SIDE_LENGTH = {
	SINGLE: {
		width: '26.4rem',
		height: '20rem',
	},
	THREE: {
		width: '8.7rem',
		height: '8.7rem',
	},
	EVEN: {
		width: '13.1rem',
		height: '13.1rem',
	},
};

function getMultipleImageSideLength(filesLen: number): { width: string; height: string } {
	if (filesLen === 1) {
		return IMAGE_SIDE_LENGTH.SINGLE;
	}

	if (filesLen === 3) {
		return IMAGE_SIDE_LENGTH.THREE;
	}

	return IMAGE_SIDE_LENGTH.EVEN;
}

interface ImageMessageBodyProps {
	isByMe: boolean;
	message: CoreMessageType;
	onMessageHeightChange?: () => void;
}

export const ImageMessageBody = ({ isByMe, message, onMessageHeightChange = noop }: ImageMessageBodyProps) => {
	const { handleOpen } = context.modal.useDispatch();
	const { deleteUserMessage, deleteFileMessage } = useChannelContext();
	const messageData = getFileMessageData(message.data);
	const messageRef = useRef<HTMLDivElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const showMenuItemDelete = useMemo(() => isByMe && !isAnnouncementMessage(message), [message, isByMe]);

	if (messageData === null) {
		return <></>;
	}

	const fileData = getFileData(messageData.files[0]);

	if (fileData === null) {
		return <></>;
	}

	const { files } = messageData;
	const { expiredAt } = fileData;
	const filesLen = files.length;

	const isExpired = isExpiredFile(expiredAt);

	const handleDownload = () => {
		files.forEach((file) => {
			downloadFile(file);
		});
	};

	return (
		<ImageMessageItem
			$isByMe={isByMe}
			onContextMenu={(e) => {
				if (!isExpired) {
					e.preventDefault();
					setMenuOpen(true);
				}
			}}
			ref={messageRef}
		>
			{isExpired ? (
				<ImageRendererWrapper
					onClick={() => {
						toast.info(`만료된 이미지입니다.`);
					}}
				>
					<Icon id={IconID.EXPIRED_FILE} width={'4rem'} height={'4rem'} />
				</ImageRendererWrapper>
			) : (
				<ImageGrid $more={filesLen - SHOW_IMAGE_MAX_COUNT}>
					{files.slice(0, SHOW_IMAGE_MAX_COUNT).map(({ url, fileName }, idx) => (
						<ImageRendererWrapper
							key={idx}
							onClick={() => {
								const triggerIndex = idx === 3 && filesLen > 4 ? 0 : idx;
								handleOpen(<ViewerModal imageList={files} triggerIndex={triggerIndex} />);
							}}
						>
							<ImageRenderer
								url={url}
								alt={fileName}
								{...getMultipleImageSideLength(filesLen)}
								maxWidth={'26.4rem'}
								maxHeight={'36rem'}
								fixedSize={filesLen > 1}
								onLoad={onMessageHeightChange}
							/>
						</ImageRendererWrapper>
					))}
				</ImageGrid>
			)}
			<ContextMenu
				isOpen={menuOpen}
				menuItems={() => (
					<MenuItems
						parentRef={messageRef}
						closeDropdown={() => {
							setMenuOpen(false);
						}}
						isOnCursor={true}
					>
						<MenuItem
							onClick={() => {
								handleDownload();
								setMenuOpen(false);
							}}
						>
							<>
								{'이미지 저장'}
								<Icon id={IconID.VIEWER_DOWNLOAD} width={'2rem'} height={'2rem'} />
							</>
						</MenuItem>
						{showMenuItemDelete && (
							<MenuItem
								onClick={() => {
									if (message.isFileMessage()) {
										deleteFileMessage(message.messageId);
									} else {
										deleteUserMessage(message.messageId);
									}
									setMenuOpen(false);
								}}
							>
								<>
									{'삭제'}
									<Icon id={IconID.BTN_DELETE} width={'2rem'} height={'2rem'} />
								</>
							</MenuItem>
						)}
					</MenuItems>
				)}
			/>
		</ImageMessageItem>
	);
};

const ImageMessageItem = styled.div<{ $isByMe?: boolean }>`
	display: flex;
	width: 26.4rem;
	min-height: 8.7rem;
	height: auto;
	max-height: 36rem;
	max-width: 26.4rem;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

const ImageRendererWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.5);
	isolation: isolate;
	position: relative;
	border-radius: 2px;
`;

const ImageGrid = styled.div<{ $more: number }>`
	display: grid;
	grid-gap: 0.2rem;
	grid-template-columns: repeat(2, 1fr);
	&:has(> :last-child:nth-child(1)) {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 0;
	}
	&:has(> :last-child:nth-child(3)) {
		grid-template-columns: repeat(3, 1fr);
	}

	${({ $more, theme }) =>
		$more > 0 &&
		css`
			${ImageRendererWrapper}:last-child {
				&::before {
					display: flex;
					z-index: 2;
					padding: 0.2rem 1rem;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-radius: 999px;
					position: absolute;
					background: rgba(0, 0, 0, 0.4);
					color: ${theme.colors.white};
					${theme.font.title.title_3};
					${theme.font.weight.bold};
					content: '+${$more}';
				}

				&::after {
					content: '';
					position: absolute;
					z-index: 1;
					opacity: 0.5;
					width: 100%;
					height: 100%;
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%) lightgray 50% / cover
						no-repeat;
				}
			}
		`}
`;
