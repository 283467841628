import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { getMessagePartsInfo } from './getMessagePartsInfo';
import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { Icon, IconID, Label } from '../../../../components/Display';
import { Placeholder, PlaceHolderTypes } from '../../../ChannelList/components/ChannelListView/Placeholder';
import { useChannelContext } from '../../context/ChannelProvider';
import { Message } from '../Message';

import type { CoreMessageType } from '../../utils';

export const MessageList = () => {
	const { currentChannel, hasNext, loading, messages, newMessages, scrollToBottom, isScrollBottomReached, scrollRef } =
		useChannelContext();

	const [unreadSinceDate, setUnreadSinceDate] = useState<Date>();

	useEffect(() => {
		if (isScrollBottomReached) {
			setUnreadSinceDate(undefined);
		} else {
			setUnreadSinceDate(new Date());
		}
	}, [isScrollBottomReached]);

	if (loading) {
		return <Placeholder type={PlaceHolderTypes.LOADING} />;
	}

	if (messages.length === 0) {
		return <Placeholder type={PlaceHolderTypes.NO_MESSAGES} comment={'이전 대화가 없습니다.'} />;
	}

	const scrollToBottomButton = () => {
		if (!hasNext() && isScrollBottomReached) return null;
		if (newMessages.length > 0) return null;

		return (
			<ToBottomWrapper>
				<ToBottomButton
					role={'button'}
					onClick={() => {
						scrollToBottom();
					}}
				>
					<Icon id={IconID.BTN_BOTTOM} width={'5rem'} height={'5rem'} />
				</ToBottomButton>
			</ToBottomWrapper>
		);
	};

	const unreadMessage = () => {
		if (isScrollBottomReached || !unreadSinceDate || !newMessages.length) return null;
		const lastMessage = newMessages[newMessages.length - 1];
		if (lastMessage && !lastMessage.isUserMessage()) return null;

		return (
			<UnreadMessageWrapper
				onClick={() => {
					scrollToBottom();
				}}
			>
				<UnreadMessageInner>
					<Label $fontStyle={'body_2'} $fontWeight={'medium'} $color={'gray_700'}>
						{'새 메시지'}
					</Label>
					<Label $fontStyle={'body_2'}>{lastMessage.message}</Label>
				</UnreadMessageInner>
				<Icon id={IconID.CHEVRON} height={'2rem'} width={'2rem'} defaultColor={'gray_600'} />
			</UnreadMessageWrapper>
		);
	};

	return (
		<Container>
			<ScrollContainer>
				<Wrapper ref={scrollRef}>
					{messages.map((message, idx) => {
						const { chainTop, chainBottom, hasSeparator } = getMessagePartsInfo({
							allMessages: messages as CoreMessageType[],
							currentIndex: idx,
							currentMessage: message as CoreMessageType,
							currentChannel: currentChannel!,
						});
						return (
							<Message
								key={idx}
								message={message as CoreMessageType}
								hasSeparator={hasSeparator}
								chainTop={chainTop}
								chainBottom={chainBottom}
							/>
						);
					})}
				</Wrapper>
			</ScrollContainer>
			{scrollToBottomButton()}
			{unreadMessage()}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	height: 100%;
	overflow: hidden;
	flex: 1 1 0;
`;

const ScrollContainer = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	position: relative;
`;

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	${scrollStyle({ hideHorizontal: true })};

	&::-webkit-scrollbar {
		width: 0.6rem;
	}

	padding: 0 2rem;
`;

const ToBottomWrapper = styled.div`
	position: sticky;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 6rem;
	height: 6rem;
	bottom: 1.6rem;
	left: calc(100% - 7rem);
`;

const ToBottomButton = styled.div`
	width: 5rem;
	height: 5rem;
	border-radius: 999px;
	cursor: pointer;
	fill: ${({ theme }) => theme.colors.white};
	filter: drop-shadow(0px 2px 10px rgba(31, 33, 42, 0.08)) drop-shadow(0px 0px 5px rgba(31, 33, 42, 0.02));
`;

const UnreadMessageWrapper = styled.div`
	position: sticky;
	bottom: 0.8rem;
	left: 2rem;
	display: flex;
	width: calc(100% - 4rem);
	max-width: 86.4rem;
	height: 5.6rem;
	padding: 1.2rem 1.6rem 1.2rem 1.2rem;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.colors.primary.primary_300};
	background: rgba(242, 252, 252, 0.9);
	cursor: pointer;
`;

const UnreadMessageInner = styled.div`
	display: flex;
	align-items: center;
	flex: 1 0 0;
	gap: 0.8rem;
	overflow: hidden;
`;
