import type { ToggleKey } from './ToggleButton';
import type { MainServiceType, SNSType } from '../../../../services/pharm/types';
import type { DefaultOption } from '../../../../utils/consts';

export const BRIEF_VALID_ALERT = `‘한 줄 소개’ 글자수가 20자 미만입니다.\n글자수를 20~35자 사이로 입력해 주세요.`;
export const BRIEF_VALID_MESSAGE = '20자 이상 입력해 주세요.';
export const ON_SUCCESS = '입력하신 내용이 저장되었습니다.';

export const SNS_VALID_MESSAGE = '올바른 URL 주소를 입력해 주세요.';

export const KEYWORD_INFO_MESSAGE = '약국을 대표하는 특징, 전문상담분야, 취급약품명 등을 입력하세요.';

export const mainServiceTypeOptions: Array<DefaultOption<MainServiceType>> = [
	{ key: 'PRESCRIPTION', label: '처방전 오더' },
	{ key: 'RECEIPT', label: '영수증 요청' },
	{ key: 'SUPPLEMENT', label: '영양제 상담' },
	{ key: 'CHAT', label: '약사 상담' },
] as const;

export const toggleButtonLabels: Record<ToggleKey, string> = {
	isLateNight: '심야약국',
	isYearRound: '연중무휴약국',
	isAnimalMeds: '동물의약품취급',
	isParking: '주차 가능',
	isPetFriendly: '반려동물 동반 가능',
	isWheelchair: '휠체어 이용 가능',
} as const;

export const ORDERED_SNS: SNSType[] = ['YOUTUBE', 'INSTAGRAM', 'NAVER', 'FACEBOOK'] as const;

export const SNSLabels: Record<SNSType, { label: string; src: string; validUrl: string }> = {
	YOUTUBE: { label: '유튜브', src: '/youtube.svg', validUrl: 'youtube' },
	INSTAGRAM: { label: '인스타그램', src: '/insta.svg', validUrl: 'instagram' },
	NAVER: { label: '네이버블로그', src: '/blog.svg', validUrl: 'blog.naver' },
	FACEBOOK: { label: '페이스북', src: '/facebook.svg', validUrl: 'facebook' },
} as const;

export const formProps = {
	mainServiceType: {
		label: '대표 서비스',
		inputProps: {
			name: 'mainServiceType',
			options: mainServiceTypeOptions,
			style: { gap: '4.8rem' },
		},
	},
	briefIntro: {
		label: '한 줄 소개',
		inputProps: {
			name: 'briefIntro',
			count: { show: true, max: 35 },
			placeholder: '우리 약국을 한 줄로 소개해 주세요.',
			maxLength: 35,
		},
	},
	detailIntro: {
		label: '상세 소개',
		inputProps: {
			name: 'detailIntro',
			count: { show: true, max: 300 },
			placeholder: '우리 약국의 특장점을 단골 고객님께 자세히 설명해 주세요.',
			maxLength: 300,
			style: { height: '17rem' },
		},
	},
	keywords: {
		label: '직접 입력 키워드',
		inputProps: {
			name: 'keywords',
			count: { show: true, max: 10 },
			maxLength: 10,
			placeholder: '직접 입력하기',
		},
	},
	direction: {
		label: '오시는 길',
		inputProps: {
			name: 'direction',
			count: { show: true, max: 50 },
			maxLength: 50,
			placeholder: '예) 강남역 1번 출구 앞 200m 직진하면 우측에 보이는 주유소 건물 1층입니다.',
		},
	},
	paymentMethod: {
		label: '결제수단',
		inputProps: {
			name: 'paymentMethod',
			count: { show: true, max: 100 },
			maxLength: 100,
			placeholder: '예) 지역화폐(지류형), 지역화폐(카드형), 제로페이, 카카오페이, 네이버페이, 애플페이',
		},
	},
	medsHandled: {
		label: '취급약품',
		inputProps: {
			name: 'medsHandled',
			count: { show: true, max: 100 },
			maxLength: 100,
			placeholder: '예) 내부기생충, 사상충, 귓병약, 피부약, 안약, 위장/설사, 항생제, 항진균제, 백신류, 영양제',
		},
	},
} as const;
