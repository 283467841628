import React, { useEffect, useRef } from 'react';

import { type UserMessage } from '@sendbird/chat/message';
import styled from 'styled-components';

import { VStack } from '../../../../../components/Common';
import { Label, ImageRenderer } from '../../../../../components/Display';
import { useLazyImageLoader } from '../../../../../hooks/useLazyImageLoader';
import { noop } from '../../../../../utils/noop';

interface OGMessageBodyProps {
	isByMe: boolean;
	message: UserMessage;
	onMessageHeightChange?: () => void;
}

export const OGMessageBody = ({ isByMe, message, onMessageHeightChange = noop }: OGMessageBodyProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const isLoaded = useLazyImageLoader(ref);
	const imageRef = useRef<HTMLDivElement>(null);
	const openUrl = () => {
		let safeURL = message?.ogMetaData?.url;
		if (safeURL) {
			if (!safeURL.startsWith('http://') && !safeURL.startsWith('https://')) {
				safeURL = 'https://' + safeURL;
			}
			window.open(safeURL, '_blank', 'noopener,noreferrer');
		}
	};

	useEffect(() => {
		if (isLoaded) {
			onMessageHeightChange();
		}
	}, [isLoaded, message?.ogMetaData?.url]);

	return (
		<OGMessageItem ref={ref} $isByMe={isByMe} onClick={openUrl}>
			{message?.ogMetaData?.defaultImage && (
				<OGMessageImage ref={imageRef}>
					<ImageRenderer
						url={message?.ogMetaData?.defaultImage.url ?? ''}
						alt={message?.ogMetaData?.defaultImage.alt ?? ''}
						width={'26.4rem'}
						height={'20rem'}
						fixedSize={true}
						onLoad={onMessageHeightChange}
						onError={() => {
							try {
								imageRef?.current?.classList?.add('og-thumbnail-empty');
							} catch (e) {
								// do nothing
							}
						}}
					/>
				</OGMessageImage>
			)}
			<OGMessageDesc>
				{message?.ogMetaData?.title && (
					<Label $fontStyle={'body_2'} $fontWeight={'medium'}>
						{message?.ogMetaData?.title}
					</Label>
				)}
				{message?.ogMetaData?.url && (
					<Label $fontStyle={'caption_1'} $color={'gray_700'}>
						{message?.ogMetaData?.url}
					</Label>
				)}
			</OGMessageDesc>
		</OGMessageItem>
	);
};

const OGMessageItem = styled(VStack)<{ $isByMe?: boolean }>`
	max-width: 26.4rem;
	width: 26.4rem;
	overflow: hidden;
	cursor: pointer;

	gap: 0.4rem;
	border-radius: ${({ $isByMe }) => ($isByMe ? `12px 2px 12px 12px` : `2px 12px 12px 12px`)};
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
`;

const OGMessageImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	&.og-thumbnail-empty {
		display: none;
	}
`;

const OGMessageDesc = styled(VStack)`
	padding: 1rem 1.2rem;
	gap: 1.2rem;
	word-break: break-word;
`;
