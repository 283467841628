import React from 'react';

import styled, { type CSSProperties } from 'styled-components';

import { Label, type StyledLabelProps } from '../Label';

interface DescriptionItem<T> {
	key: keyof T;
	label: string;
	content?: string | number;
	renderContent?: (key: keyof T, value: T[keyof T], item: T) => string | number | React.ReactElement;
}
export interface DescriptionProps<T extends object> {
	data: T | undefined;
	rows: Array<DescriptionItem<T>>;
	style?: CSSProperties;
	labelStyle?: StyledLabelProps;
	contentStyle?: StyledLabelProps;
}
export const Description = <T extends object>(props: DescriptionProps<T>) => {
	const { data, rows, style = {}, labelStyle, contentStyle } = props;

	if (!data) return <></>;

	return (
		<Container style={{ ...style }}>
			{rows.map((row, idx) => (
				<ItemList key={idx}>
					<ItemTitle>
						<Label $fontStyle={'label_2'} $fontWeight={'medium'} {...labelStyle} $color="gray_700">
							{row.label}
						</Label>
					</ItemTitle>
					<ItemContent>
						<Label $fontStyle={'label_2'} $fontWeight={'regular'} {...contentStyle}>
							{row.renderContent
								? row.renderContent(row.key, data[row.key], data)
								: row.content ?? (data[row.key] as string)}
						</Label>
					</ItemContent>
				</ItemList>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	padding: 0.8rem 2rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	background: ${({ theme }) => theme.colors.white};
`;

const ItemList = styled.div`
	display: flex;
	align-items: center;
	gap: 1.2rem;
	align-self: stretch;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
	}
`;

const ItemTitle = styled.div`
	display: flex;
	width: 18.8rem;
	height: 4.8rem;
	align-items: center;
	gap: 1rem;
`;

const ItemContent = styled.div`
	display: flex;
	flex: 1;
	max-width: 38.4rem;
	padding: 1.7rem 0;
	align-items: center;
	gap: 1rem;
`;
