import React, { type ReactNode, type CSSProperties } from 'react';

import styled, { css } from 'styled-components';

import { HStack } from '../../Common';
import { Icon, IconID } from '../../Display';

interface ModalHeaderStyleProps {
	$hasBorderBottom?: boolean;
	style?: CSSProperties;
}
export interface OutletHeaderProps extends ModalHeaderStyleProps {
	title: string | ReactNode;
}

interface ModalHeaderProps extends OutletHeaderProps {
	handleClose?: () => void;
}

export const ModalHeader = ({ title, handleClose, ...styleProps }: ModalHeaderProps) => {
	return (
		<Container {...{ ...styleProps }}>
			<Label>{title}</Label>
			<button onClick={handleClose}>
				<Icon id={IconID.BTN_CLOSE} defaultColor="gray_700" />
			</button>
		</Container>
	);
};

const Container = styled(HStack)<ModalHeaderStyleProps>`
	padding: 2rem 1.6rem 1.2rem;
	justify-content: center;
	align-items: center;
	gap: 1.6rem;
	align-self: stretch;
	${({ $hasBorderBottom, theme }) =>
		$hasBorderBottom &&
		css`
			border-bottom: 1px solid ${theme.colors.gray.gray_300};
		`}
`;

const Label = styled.span`
	flex: 1 0 0;
	${({ theme }) => theme.font.title.title_2};
	font-weight: 600;
`;
