import { useCallback, useEffect } from 'react';

import { connect } from './connect';
import { type ReconnectType, type StaticTypes, type TriggerTypes } from './types';

export default function useConnect(triggerTypes: TriggerTypes, staticTypes: StaticTypes): ReconnectType {
	const { userId, accessToken } = triggerTypes;
	const { nickname, sdk, sdkDispatcher, userDispatcher } = staticTypes;

	useEffect(() => {
		connect({
			userId,
			accessToken,
			nickname,
			sdk,
			sdkDispatcher,
			userDispatcher,
		});
	}, [userId]);

	return useCallback(async () => {
		try {
			await connect({
				userId,
				accessToken,
				nickname,
				sdk,
				sdkDispatcher,
				userDispatcher,
			});
		} catch (e) {}
	}, [sdk]);
}
