import { type DisconnectSdkTypes } from './types';

export async function disconnectSdk({ sdkDispatcher, userDispatcher, sdk }: DisconnectSdkTypes): Promise<boolean> {
	return await new Promise((resolve) => {
		const { updateSdkLoading, resetSdk } = sdkDispatcher;
		const { resetUser } = userDispatcher;
		updateSdkLoading(true);
		if (sdk?.disconnect) {
			sdk
				.disconnect()
				.then(() => {
					resetSdk();
					resetUser();
				})
				.finally(() => {
					resolve(true);
				});
		} else {
			resolve(true);
		}
	});
}
