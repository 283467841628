import { Delete, Get, Post, Put } from '..';
import { formatQueryString } from '../../utils/format';

import type {
	PostAndDeleteMemberRequest,
	GetMemberActivities,
	GetMemberActivityRequest,
	GetMemberGroups,
	GetMemberInfo,
	GetMemberPayments,
	GetMemberPaymentsRequest,
	GetMemberRequest,
	GetMembersRequest,
	GetMembersResponse,
	GetMemberTotalPayment,
	GetMemberCountResponse,
	PutMemberMemoRequest,
	PutMemberNicknamesRequest,
} from './types';

export const member = {
	getMembers: async <T = GetMembersResponse>({ storeId, ...params }: GetMembersRequest): Promise<T | undefined> => {
		const response = await Get<T>(`/pharms/${storeId}/members${formatQueryString(params)}`);
		return response.data;
	},
	getMembersExcel: async <T = Blob>({ storeId, ...params }: GetMembersRequest & { storeId?: string }): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/excel${formatQueryString(params)}`, {
			responseType: 'blob',
		});
		return response.data;
	},
	getMember: async <T = GetMemberInfo>({ storeId, userId }: GetMemberRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/${userId}`);
		return response.data;
	},
	getMemberTotalPayment: async <T = GetMemberTotalPayment>({ storeId, userId }: GetMemberRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/${userId}/total-payment`);
		return response.data;
	},
	getMemberPayments: async <T = GetMemberPayments>({
		storeId,
		userId,
		...rest
	}: GetMemberPaymentsRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/${userId}/payments${formatQueryString({ ...rest })}`);
		return response.data;
	},
	getMemberGroup: async <T = GetMemberGroups>({ storeId, userId }: GetMemberRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/${userId}/groups`);
		return response.data;
	},
	postMemberGroup: async <T = any>({ storeId, userId, data }: PostAndDeleteMemberRequest): Promise<T> => {
		const response = await Post<T>(`/pharms/${storeId}/members/${userId}/groups`, data);
		return response.data;
	},
	deleteMemberGroup: async <T = any>({ storeId, userId, data }: PostAndDeleteMemberRequest): Promise<T> => {
		const response = await Delete<T>(`/pharms/${storeId}/members/${userId}/groups`, { data });
		return response.data;
	},
	getMemberActivities: async <T = GetMemberActivities>({
		storeId,
		userId,
		...rest
	}: GetMemberActivityRequest): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/${userId}/activities${formatQueryString({ ...rest })}`);
		return response.data;
	},
	getMemberCount: async <T = GetMemberCountResponse>({ storeId }: { storeId: string }): Promise<T> => {
		const response = await Get<T>(`/pharms/${storeId}/members/count`);
		return response.data;
	},
	putMemberMemo: async <T = any>({ storeId, userId, ...rest }: PutMemberMemoRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/members/${userId}/notes`, rest);
		return response.data;
	},
	putMemberNicknames: async <T = any>({ storeId, userId, ...rest }: PutMemberNicknamesRequest): Promise<T> => {
		const response = await Put<T>(`/pharms/${storeId}/members/${userId}/nicknames`, rest);
		return response.data;
	},
};
