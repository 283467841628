import React, { useRef } from 'react';

import { type UseFloatingReturn } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { useCustomFloating } from '../../../../lib/floatingUi/useCustomFloating';

interface RestViewerProps {
	data: string[] | number[];
	referenceRef: React.RefObject<HTMLLIElement>;
	isHover: boolean;
}
export const RestViewer = ({ data, referenceRef, isHover }: RestViewerProps) => {
	const ref = useRef(null);
	const floating = useCustomFloating({
		referenceRef,
		floatingRef: ref,
		isOpen: !!referenceRef && isHover,
		options: { allowedPlacements: ['bottom-end', 'top-end'] },
	});

	return (
		<Container $floating={floating} ref={ref} $isHover={isHover}>
			{data.map((el, idx) => (
				<li key={'rest-item-' + el + idx} className="item">
					{el}
				</li>
			))}
		</Container>
	);
};

const Container = styled.ul<{ $floating: UseFloatingReturn; $isHover: boolean }>`
	display: ${({ $isHover }) => ($isHover ? 'flex' : 'none')};
	visibility: ${({ $isHover }) => ($isHover ? 'visible' : 'hidden')};
	position: absolute;
	top: 0;
	left: 0;
	${({ $floating }) => css`
		transform: translate(${$floating.x}px, ${$floating?.y}px);
	`};
	z-index: 1;
	flex-direction: column;
	gap: 0.2rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	box-shadow: ${({ theme }) => theme.shadow.modal};
	border-radius: 4px;
	padding: 0.8rem 1.2rem;

	.item {
		white-space: nowrap;
		${({ theme }) => theme.font.body.body_3};
		color: ${({ theme }) => theme.colors.gray.gray_800};
	}
`;
