import { useMutation, useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import {
	type PutSalesItem,
	type CombineSalesItemPK,
	type PostSalesItem,
	type SalesItemDetail,
	type ExistsResponse,
	type PatchSalesStatus,
} from './types';
import { api } from '..';
import { type ErrorData } from '../types';

export const salesKeys = {
	all: ['sales'] as const,
	getSales: (storeId: string) => [...salesKeys.all, 'list', storeId],
	postSale: () => [...salesKeys.all, 'post'],
	getSale: ({ storeId, id }: CombineSalesItemPK) => [...salesKeys.all, 'detail', storeId, id],
	putSale: () => [...salesKeys.all, 'put'],
	deleteSale: () => [...salesKeys.all, 'delete'],
	getSaleExits: () => [...salesKeys.all, 'exits'],
	postSaleCopy: () => [...salesKeys.all, 'copy'],
	patchSaleStatus: () => [...salesKeys.all, 'patch'],
} as const;

export const useGetSales = (storeId: string) =>
	useQuery({
		queryKey: salesKeys.getSales(storeId),
		queryFn: async () =>
			await api.sales.getSales({
				storeId,
				page: 1,
				limit: 999,
			}),
		enabled: !!storeId,
	});

export const usePostSale = () =>
	useMutation<SalesItemDetail, AxiosError<ErrorData>, PostSalesItem>({
		mutationKey: salesKeys.postSale(),
		mutationFn: async (param) => await api.sales.postSale(param),
	});

export const useGetSale = (props: CombineSalesItemPK) =>
	useQuery({
		queryKey: salesKeys.getSale(props),
		queryFn: async () => await api.sales.getSale(props),
		enabled: !!props.id && !!props.storeId,
	});

export const useDeleteSale = () =>
	useMutation<unknown, AxiosError<ErrorData>, CombineSalesItemPK>({
		mutationKey: salesKeys.deleteSale(),
		mutationFn: async (params: CombineSalesItemPK) => await api.sales.deleteSale(params),
	});
export const usePatchSaleStatus = () =>
	useMutation<unknown, AxiosError<ErrorData>, PatchSalesStatus>({
		mutationKey: salesKeys.patchSaleStatus(),
		mutationFn: async (params) => await api.sales.patchSaleStatus(params),
	});

export const usePutSale = () =>
	useMutation<SalesItemDetail, AxiosError<ErrorData>, PutSalesItem>({
		mutationKey: salesKeys.putSale(),
		mutationFn: async (params: PutSalesItem) => await api.sales.putSale(params),
	});

export const useGetSaleExits = () =>
	useMutation<ExistsResponse, AxiosError<ErrorData>, CombineSalesItemPK>({
		mutationKey: salesKeys.getSaleExits(),
		mutationFn: async (params) => await api.sales.getExists(params),
	});

export const usePostSaleCopy = () =>
	useMutation<SalesItemDetail, AxiosError<ErrorData>, CombineSalesItemPK>({
		mutationKey: salesKeys.postSaleCopy(),
		mutationFn: async (params) => await api.sales.postCopy(params),
	});
