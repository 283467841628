import { useEffect, useRef } from 'react';

import IntervalWorker from './intervalWorker';
import { type UseWorkerInterval, type MessageType } from './types';

export const useWorkerInterval: UseWorkerInterval = (callback, interval = 1000) => {
	const workerRef = useRef<Worker | null>(null);
	useEffect(() => {
		workerRef.current = new Worker(IntervalWorker);
		if (workerRef.current) {
			workerRef.current.postMessage({ type: 'START', interval } as MessageType);

			workerRef.current.onmessage = (e) => {
				callback(e);
			};
		}
		return () => {
			if (workerRef.current) {
				workerRef.current.postMessage({ type: 'END' } as MessageType);
				// worker 종료
				workerRef.current.terminate();
			}
		};
	}, []);
};
