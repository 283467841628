import { format } from 'date-fns/format';
import { getMonth } from 'date-fns/getMonth';
import { isFirstDayOfMonth } from 'date-fns/isFirstDayOfMonth';
import { ko } from 'date-fns/locale/ko';

import type { ExtendedPeriodUnit, UnitQueryResultType } from '../../../../services/statistics/queries';

const WEEK_JOIN = ` ~\n`;
export function xAxisDataKey<T extends ExtendedPeriodUnit>(obj: UnitQueryResultType<T>) {
	if ('day' in obj) {
		return obj.day;
	}
	if ('month' in obj) {
		return obj.month;
	}
	if ('dayOfWeek' in obj) {
		return `${obj.dayOfWeek}`;
	}
	if ('startDate' in obj && 'endDate' in obj) {
		return `${obj.startDate}${WEEK_JOIN}${obj.endDate}`;
	}
}

export function xTickFormatter(value: string, unit: ExtendedPeriodUnit) {
	switch (unit) {
		case 'daily':
			return `${format(value, 'd')}`;
		case 'weekly':
			return value
				.split(WEEK_JOIN)
				.map((date) => format(date, 'd'))
				.join('~');
		case 'monthly':
			return `${format(value, 'MMM', { locale: ko })}`;
		case 'weekday':
			return value;
		default:
			return '';
	}
}

export function xExtraTickFormatter(value: string, index: number, unit: ExtendedPeriodUnit) {
	let formatted = '';
	let show = false;
	switch (unit) {
		case 'daily':
			show = isFirstDayOfMonth(value);
			formatted = `${format(value, 'MMM', { locale: ko })}`;
			break;
		case 'weekly': {
			const [start, end] = value.split(WEEK_JOIN).map((date) => format(date, 'MMM', { locale: ko }));
			show = start !== end;
			formatted = end;
			break;
		}
		case 'monthly':
			show = getMonth(value) === 0;
			formatted = `${format(value, 'yyyy')}`;
			break;
	}

	if (show || index === 0) {
		return formatted;
	}

	return '';
}
