import React, { type CSSProperties, useMemo, useRef, useState, useCallback, useEffect } from 'react';

import styled, { css } from 'styled-components';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import { type UseValuesReturn } from '../../../../hooks/useValues';
import { type Period } from '../../../../services/types';
import { formatDate } from '../../../../utils/format';
import { type SelectProps } from '../../../Forms/Select';
import { Options } from '../../../Forms/Select/components/Options';
import { findLabel } from '../../../Forms/Select/util';
import { FloatingModal } from '../../../Modals/FloatingModal';
import { handleMultiSelect } from '../../utils';
import { FilterItem } from '../FilterItem';
import { FilterListItemTag } from '../FilterListItemTag';
import { type FilterType, type FilterItemType } from '../FilterModal/types';

interface FilterListItemProps<T extends object> {
	item: FilterItemType<T>;
	queries: UseValuesReturn<T>;
	resetCurPage: () => void;
	isDelete?: boolean;
	hasAll?: boolean;
	tagStyle?: CSSProperties;
	itemStyle?: CSSProperties;
}

export const FilterListItem = <T extends object>({
	item,
	queries,
	resetCurPage,
	isDelete,
	hasAll = false,
	tagStyle,
}: FilterListItemProps<T>) => {
	const { values, dispatch } = queries;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const filterListRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (isOpen) {
			resetCurPage();
		}
	}, [queries.values]);

	const onClose = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
		}
	}, [isOpen]);
	useOutsideClick(
		filterListRef,
		() => {
			onClose();
		},
		{ hasClassName: 'react-datepicker' },
	);

	const findValue = useMemo(() => {
		switch (item.type) {
			case 'PERIOD': {
				const { start, end } = values[item.key] as Period;
				const valueArray = [];
				start && valueArray.push(formatDate(start));
				end && valueArray.push(formatDate(end));
				valueArray.length === 0 && valueArray.push('전체');
				return valueArray.join(' ~ ');
			}
			case 'SELECT': {
				if (item.selectProps) {
					const { value, ...rest } = item.selectProps as SelectProps<T>;
					return findLabel({ value: values[item.key], ...rest }) ?? '';
				}
				return '';
			}
			case 'RADIO': {
				if (item.radioProps) {
					return item.radioProps.options.find((option) => option.key === values[item.key])?.label ?? '';
				}
				return '';
			}
			default:
				return '';
		}
	}, [values, item]);

	return (
		<FilterListItemWrapper $isOpen={isOpen} ref={filterListRef}>
			<FilterListItemTag
				label={item.label}
				value={findValue}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				isOpen={isOpen}
				handleReset={() => {
					dispatch('DELETE', item.key);
				}}
				isDelete={isDelete}
				style={tagStyle}
			/>
			<FloatingModal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false);
				}}
				referenceRef={filterListRef}
			>
				<SelectorModal $type={item.type}>
					{(() => {
						if (item.type !== 'SELECT') {
							return <FilterItem item={item} queries={queries} hasAll={hasAll} isFilterList />;
						} else {
							if (item.selectProps) {
								const { options, multiple, value, onClick, ...rest } = item.selectProps as SelectProps<T>;

								return (
									<Options
										options={hasAll ? options : options.slice(1)}
										value={queries.values[item.key]}
										onClick={(value) => {
											let newValue;
											if (multiple) {
												newValue = handleMultiSelect<typeof value>(
													value,
													queries.values[item.key] as Array<typeof value>,
													multiple.allKey,
												);
											} else {
												newValue = value;
											}
											queries.dispatch('SET', { [item.key]: newValue } as Partial<T>);
										}}
										{...rest}
									/>
								);
							}
						}
					})()}
				</SelectorModal>
			</FloatingModal>
		</FilterListItemWrapper>
	);
};

const SelectorModal = styled.div<{ $type?: FilterType }>`
	display: flex;
	flex-direction: column;
	${({ $type }) => {
		switch ($type) {
			case 'PERIOD':
				return css`
					min-width: 32.4rem;
					padding: 1.6rem 1.2rem 1.2rem;
					gap: 1.6rem;
				`;
			case 'RADIO':
				return css`
					min-width: 17.6rem;
					padding: 0.8rem 1.2rem 1.2rem;
					gap: 1.2rem;
				`;
			case 'SELECT':
				return css`
					min-width: 28rem;
					> button {
						margin: 1.2rem;
					}
				`;
			default:
				return css``;
		}
	}}
`;

const FilterListItemWrapper = styled.li<{ $isOpen: boolean }>`
	position: relative;
`;
