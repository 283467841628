import {
	type PostNotificationRequest,
	type NotificationConfig,
	type StoreId,
	type PutNotificationRequest,
	type NotificationConfigPK,
} from './types';
import { Delete, Get, Patch, Post, Put } from '..';

const BASE_PATH = (storeId?: string) => `/pharms${storeId ? `/${storeId}` : ''}/notification-configs` as const;

export default {
	variables: async () => await Get(BASE_PATH() + '/variables'),
	init: async <T = unknown>({ storeId }: StoreId) => (await Get<T>(BASE_PATH(storeId) + '/init')).data,
	getNotificationConfigs: async <T = NotificationConfig[]>({ storeId }: StoreId) =>
		(await Get<T>(BASE_PATH(storeId))).data,
	postNotificationConfig: async <T = NotificationConfig>({ storeId, ...data }: PostNotificationRequest) =>
		(await Post<T>(BASE_PATH(storeId), data)).data,
	putNotificationConfig: async <T = NotificationConfig>({ storeId, id, ...data }: PutNotificationRequest) =>
		(await Put<T>(BASE_PATH(storeId) + `/${id}`, data)).data,
	deleteNotificationActive: async <T = unknown>({ storeId, id }: StoreId & NotificationConfigPK) =>
		(await Delete<T>(BASE_PATH(storeId) + `/${id}/active`)).data,
	patchNotificationActive: async <T = unknown>({ storeId, id }: StoreId & NotificationConfigPK) =>
		(await Patch<T>(BASE_PATH(storeId) + `/${id}/active`)).data,
};
