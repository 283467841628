import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { isSameDay } from 'date-fns/isSameDay';

import { compareMessagesForGrouping, type CoreMessageType } from '../../utils';

export interface GetMessagePartsInfoProps {
	allMessages: CoreMessageType[];
	currentIndex: number;
	currentMessage: CoreMessageType;
	currentChannel: GroupChannel;
}

interface GetMessagePartsInfoReturnType {
	chainTop: boolean;
	chainBottom: boolean;
	hasSeparator: boolean;
}

export const getMessagePartsInfo = ({
	allMessages = [],
	currentIndex = 0,
	currentMessage,
	currentChannel,
}: GetMessagePartsInfoProps): GetMessagePartsInfoReturnType => {
	const previousMessage = allMessages[currentIndex - 1];
	const nextMessage = allMessages[currentIndex + 1];
	const [chainTop, chainBottom] = compareMessagesForGrouping(
		previousMessage,
		currentMessage,
		nextMessage,
		currentChannel,
	);
	const previousMessageCreatedAt = previousMessage?.createdAt;
	const currentCreatedAt = currentMessage.createdAt;

	// NOTE: for pending/failed messages
	const isLocalMessage = 'sendingStatus' in currentMessage && currentMessage.sendingStatus !== 'succeeded';

	// https://stackoverflow.com/a/41855608
	const hasSeparator = isLocalMessage
		? false
		: !(previousMessageCreatedAt && isSameDay(currentCreatedAt, previousMessageCreatedAt));
	return {
		chainTop,
		chainBottom,
		hasSeparator,
	};
};
