import React, { useRef } from 'react';

import { GroupChannelListOrder } from '@sendbird/chat/groupChannel';

import { IconButton } from '../../../../components/Buttons';
import { Flex } from '../../../../components/Common';
import { Icon, IconID, Label, Tabs } from '../../../../components/Display';
import { TextField } from '../../../../components/Forms';
import { useChannelListContext } from '../../context/ChannelListProvider';
import { ChannelListView } from '../ChannelListView';

export const ChannelListUI = () => {
	const { updateFilters } = useChannelListContext();

	const inputRef = useRef<HTMLInputElement>(null);

	const handleChangeQuery = () => {
		if (inputRef.current) {
			updateFilters((prev) => ({ ...prev, nicknameContainsFilter: inputRef.current?.value.trim() }));
		}
	};
	const handleChangeTab = (idx: number, id: string) => {
		if (idx === 0 || id === 'regular') {
			updateFilters((prev) => ({
				nicknameContainsFilter: prev.nicknameContainsFilter,
				includeFrozen: false,
			}));
		}
		if (idx === 1 || id === 'frozen') {
			updateFilters((prev) => ({
				...prev,
				includeFrozen: true,
				metadataOrderKeyFilter: 'freeze',
				order: GroupChannelListOrder.METADATA_VALUE_ALPHABETICAL,
			}));
		}
	};

	return (
		<Flex $padding={'2.4rem 0 0'} $gap={'0.8rem'} $direction={'column'} $height={'100%'}>
			<Flex $padding={'0 1.6rem'} $direction={'column'} $gap={'1.2rem'}>
				<Label $fontStyle={'title_1'} $fontWeight={'bold'}>
					{'채팅목록'}
				</Label>
				<TextField
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleChangeQuery();
						}
					}}
					onBlur={() => {
						if (inputRef.current?.value.trim().length === 0) {
							inputRef.current.value = '';
						}
					}}
					ref={inputRef}
					inputSize={'sm'}
					style={{ height: '4.4rem' }}
					name={'search-channel'}
					placeholder={'고객명을 입력해주세요.'}
					renderSuffix={
						<IconButton width="2rem" height="2rem" onClick={handleChangeQuery}>
							<Icon id={IconID.BTN_SEARCH} width="2rem" height="2rem" />
						</IconButton>
					}
				/>
			</Flex>
			<Flex $height={'100%'}>
				<Tabs
					tabStyle={{ width: '100%', height: '4.4rem' }}
					labelStyle={{ $fontStyle: 'body_2' }}
					onChange={(idx, id) => {
						handleChangeTab(idx, id!);
					}}
					panel={<ChannelListView />}
					contents={[
						{
							label: '단골 회원',
							id: 'regular',
						},
						{
							label: '이전 회원',
							id: 'frozen',
						},
					]}
				/>
			</Flex>
		</Flex>
	);
};
