import React, { useMemo } from 'react';

import { PieChart } from '../../../components/Charts';
import { type StatsAges } from '../../../services/statistics/types';
import { commaizeNumber } from '../../../utils/format';

const AGE_GROUP: Record<keyof StatsAges, string> = {
	total: '전체',
	age0To9: '0~9세',
	age10To19: '10~19세',
	age20To29: '20~29세',
	age30To39: '30~39세',
	age40To49: '40~49세',
	age50To59: '50~59세',
	age60To69: '60~69세',
	age70To79: '70~79세',
	age80AndAbove: '80세 이상',
};

interface AgeTotalsPieProps {
	ages: StatsAges[];
	isSuccess: boolean;
}

export function AgeTotalsPie({ ages, isSuccess }: AgeTotalsPieProps) {
	const chartData = useMemo(() => {
		if (isSuccess) {
			const { total, ...rest } = ages[0];
			return Object.entries(rest).map(([key, value]) => ({
				label: AGE_GROUP[key as keyof StatsAges],
				value,
			})) as Array<{ label: string; value: number }>;
		}
		return [];
	}, [ages, isSuccess]);

	return (
		<PieChart<{ label: string; value: number }>
			wrapperStyle={{ minWidth: '65rem' }}
			width={650}
			height={360}
			margin={{
				top: 25,
				right: 25,
				bottom: 25,
				left: 25,
			}}
			data={chartData}
			dataKey={'value'}
			pieProps={{ nameKey: 'label' }}
			legendProps={{
				wrapperStyle: {
					top: 25,
					right: 25,
				},
				layout: 'vertical',
				...(ages[0]?.total === 0 && { content: <></> }),
			}}
			centerLabel={{
				name: '총 고객수',
				value: commaizeNumber(ages[0]?.total) + '명',
			}}
		/>
	);
}
