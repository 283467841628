import React, { type ButtonHTMLAttributes, useEffect, useRef } from 'react';

import { type Mutation, useIsMutating } from '@tanstack/react-query';
import styled, { css } from 'styled-components';

import { buttonStyles } from './style';
import { type ButtonType, type ButtonColorType, type ButtonSizeType } from './types';
import { usersKeys } from '../../../services/users/queries';
import { changeColorKeyToType } from '../../../utils/changeColorKeyToType';
import { changeFontKeyToType } from '../../../utils/changeFontKeyToType';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	buttonType?: ButtonType;
	color?: ButtonColorType;
	size?: ButtonSizeProps;
	shouldPrevent?: boolean;
	mutationKey?: string[];
	readonly?: boolean;
}

interface ButtonSizeProps {
	$fontSize?: ButtonSizeType;
	$paddingSize?: ButtonSizeType;
	$heightSize?: ButtonSizeType;
}

interface CustomButtonProps extends ButtonSizeProps {
	$buttonType: ButtonType;
	$color: ButtonColorType;
	$readonly?: boolean;
}

/**
 * @param children
 * @param disabled
 * @param [type='button'] 버튼 기본 타입
 * @param {ButtonType} [buttonType='FILLED'] 버튼 스타일 타입
 * @param {ButtonColorType} [color='PRIMARY']
 * @param {ButtonSizeProps} [size={$fontSize='L', $paddingSize='L', $heightSize='L'}]
 * @param {ButtonSizeType} [size.$paddingSize] '0.6rem' '1rem' '1.2rem' '1.6rem' '2.4rem' 오직 좌우 패딩
 * @param {ButtonSizeType} [size.$heightSize] '2.4rem' '2.8rem' '3.6rem' '4rem' '5.2rem'
 * @param {boolean} [shouldPrevent] API(mutate) 호출 버튼시 이중 클릭(호출) 방지
 * @param {string[]} [mutationKey] react query 에 쓰이는 mutation key 로 prevent 할 버튼 판단
 * @param {boolean} readonly pointer-event none (disabled는 색까지 변경);
 * @param rest
 * @constructor
 */
export const Button = ({
	children,
	disabled = false,
	type = 'button',
	buttonType = 'FILLED',
	color = 'PRIMARY',
	size = {},
	shouldPrevent = false,
	mutationKey,
	readonly,
	...rest
}: ButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const isMutating = useIsMutating({ ...(mutationKey && { mutationKey, exact: true }), predicate: predicateMutating });

	useEffect(() => {
		if (buttonRef.current) {
			if (isMutating && shouldPrevent && !buttonRef.current.disabled) {
				buttonRef.current.disabled = true;
			} else {
				buttonRef.current.disabled = disabled;
			}
		}
	}, [isMutating, shouldPrevent]);

	return (
		<CustomButton
			ref={buttonRef}
			{...{
				disabled,
				type,
				$readonly: readonly,
				$buttonType: buttonType,
				$color: color,
				$fontSize: size?.$fontSize,
				$paddingSize: size?.$paddingSize,
				$heightSize: size?.$heightSize,
				...rest,
			}}
		>
			{children}
		</CustomButton>
	);
};

const CustomButton = styled.button<CustomButtonProps>`
	display: flex;
	flex-direction: row;
	gap: 0.4rem;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s;
	border-radius: 4px;
	${({ $readonly }) =>
		$readonly &&
		css`
			pointer-events: none;
		`}

	${({ $buttonType, $color }) => {
		const buttonColorStyle = buttonStyles[$buttonType][$color];

		return css`
			color: ${changeColorKeyToType(buttonColorStyle?.color)};
			background-color: ${changeColorKeyToType(buttonColorStyle?.backgroundColor)};
			border-width: ${buttonColorStyle?.border?.width};
			border-style: ${buttonColorStyle?.border?.style};
			border-color: ${changeColorKeyToType(buttonColorStyle?.border?.color)};

			&:disabled {
				color: ${changeColorKeyToType(buttonColorStyle?.disabled?.color)};
				background-color: ${changeColorKeyToType(buttonColorStyle?.disabled?.backgroundColor)};
				border-width: ${buttonColorStyle?.disabled?.border?.width};
				border-style: ${buttonColorStyle?.disabled?.border?.style};
				border-color: ${changeColorKeyToType(buttonColorStyle?.disabled?.border?.color)};
			}

			&:hover {
				color: ${changeColorKeyToType(buttonColorStyle?.hover?.color)};
				background-color: ${changeColorKeyToType(buttonColorStyle?.hover?.backgroundColor)};
				border-width: ${buttonColorStyle?.hover?.border?.width};
				border-style: ${buttonColorStyle?.hover?.border?.style};
				border-color: ${changeColorKeyToType(buttonColorStyle?.hover?.border?.color)};
			}

			&:active {
				color: ${changeColorKeyToType(buttonColorStyle?.active?.color)};
				background-color: ${changeColorKeyToType(buttonColorStyle?.active?.backgroundColor)};
				border-width: ${buttonColorStyle?.active?.border?.width};
				border-style: ${buttonColorStyle?.active?.border?.style};
				border-color: ${changeColorKeyToType(buttonColorStyle?.active?.border?.color)};
			}
		`;
	}}

	${({ $fontSize = 'L', $paddingSize = 'L', $heightSize = 'L' }) => {
		const fontStyles = buttonStyles.font[$fontSize];

		return css`
			${changeFontKeyToType(fontStyles.fontKey)}
			${({ theme }) => theme.font.weight[fontStyles.fontWeight]};
			padding: 0 ${buttonStyles.padding[$paddingSize]};
			height: ${buttonStyles.height[$heightSize]};
		`;
	}}
`;

export function predicateMutating(mutation: Mutation) {
	return mutation.options.mutationKey?.join('') !== usersKeys.postHeartbeat().join('');
}
