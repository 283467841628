import { type SendbirdError, type User } from '@sendbird/chat';

import { type SetupConnectionTypes } from './types';
import { setupSendbird } from '../../../../lib/sendbird/instance';

export async function setupConnection({
	userId,
	accessToken,
	nickname,
	sdkDispatcher,
	userDispatcher,
}: SetupConnectionTypes) {
	await new Promise<void>((resolve, reject) => {
		const { initSdk, resetSdk, updateSdkError, updateSdkLoading } = sdkDispatcher;
		const { initUser, updateUserInfo, resetUser } = userDispatcher;

		updateSdkLoading(true);

		if (userId) {
			const newSdk = setupSendbird();
			const connectCbSuccess = async (user: User) => {
				initSdk(newSdk);
				initUser(user);

				if (nickname !== user.nickname) {
					await newSdk
						.updateCurrentUserInfo({
							nickname: nickname || user.nickname,
						})
						.then((namedUser) => {
							updateUserInfo(namedUser);
						})
						.finally(() => {
							resolve();
						});
				} else {
					resolve();
				}
			};

			const connectCbError = (e: SendbirdError) => {
				resetSdk();
				resetUser();
				updateSdkError();
				reject(e);
			};

			newSdk
				.connect(userId, accessToken)
				.then((res) => {
					connectCbSuccess(res);
				})
				.catch((err) => {
					connectCbError(err);
				});
		} else {
			updateSdkError();
		}
	});
}
