import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { SearchMessageList } from './components/SearchMessageList';
import { useMessageSearchContext } from './context/MessageSearchProvider';
import { IconButton } from '../../components/Buttons';
import { Flex } from '../../components/Common';
import { Icon, IconID, Label } from '../../components/Display';
import { TextField } from '../../components/Forms';
import { AlertModal } from '../../components/Modals';
import context from '../../context';

export const MessageSearch = () => {
	const { handleOpen } = context.modal.useDispatch();

	const {
		activeSearch,

		setSearchString,

		onSearchMessageClick,
		onSearchStart,
		onSearchResult,
		onSearchCancel,

		messages,
		initialized,
		loading,
		loadNext,
	} = useMessageSearchContext();

	const { searching, resulting } = activeSearch;

	const inputRef = useRef<HTMLInputElement>(null);
	const referenceRef = useRef<HTMLDivElement>(null);

	const [inputString, setInputString] = useState('');

	useEffect(() => {
		if (!!inputString && resulting) {
			onSearchStart();
		}
		setSearchString(inputString);
	}, [inputString]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleChangeInputString = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputString(e.target.value);
	};

	const handleChangeKeyDownEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && (inputString || '').trim().length > 0 && !e?.nativeEvent?.isComposing) {
			e.preventDefault();
			if (!loading && initialized) {
				onSearchResult();
				if (inputRef.current) {
					inputRef.current.blur();
				}
				if (!messages.length) {
					handleOpen(<AlertModal message={'일치하는 검색 결과가 없습니다.'} />);
					setSearchString('');
				} else {
					onSearchMessageClick(messages[0]);
				}
			}
		}
	};

	const handleClickResetButton = () => {
		setInputString('');
		setSearchString('');
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<React.Fragment>
			<TextFieldWrapper ref={referenceRef}>
				<TextField
					ref={inputRef}
					inputSize={'lg'}
					placeholder={'메시지 검색 ( i.e. 텍스트 메시지만 검색됩니다.)'}
					style={{ height: '4rem', flex: '1' }}
					value={inputString}
					onBlur={() => {
						if (inputString.trim().length === 0) {
							setInputString('');
						}
					}}
					onChange={handleChangeInputString}
					onKeyDown={handleChangeKeyDownEnter}
					renderSuffix={
						<Flex $alignItems={'center'} $gap={'0.8rem'}>
							{!!inputString && (
								<IconButton width="2rem" height="2rem" onClick={handleClickResetButton}>
									<Icon id={IconID.CIRCLE_DELETE} width="1.6rem" height="2rem" defaultColor={'gray_500'} />
								</IconButton>
							)}
							<IconButton width="2rem" height="2rem">
								<Icon id={IconID.BTN_SEARCH} width="2rem" height="2rem" />
							</IconButton>
						</Flex>
					}
					enableEmoji={true}
				/>
			</TextFieldWrapper>
			<CancelButtonWrapper onClick={onSearchCancel}>
				<Label $fontStyle={'label_2'} $fontWeight={'medium'}>
					{'취소'}
				</Label>
			</CancelButtonWrapper>
			<SearchMessageList
				isOpen={!!inputString.length && searching && !resulting}
				referenceRef={referenceRef}
				loadNext={loadNext}
			/>
		</React.Fragment>
	);
};

const TextFieldWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-self: stretch;
	width: 100%;
`;

const CancelButtonWrapper = styled(Flex)`
	padding: 0.8rem;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
`;
