import React from 'react';

import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { Button } from '../../../components/Buttons';
import { Flex } from '../../../components/Common';
import { Tag } from '../../../components/Display';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type Promotion } from '../../../services/promotion/types';
import { PROMOTION_STATUS_TAG_COLOR, PROMOTION_STATUS_VALUES } from '../../../utils/consts';

type PromotionListColumns = ({
	onChangeDelivery,
}: {
	onChangeDelivery: (item: Promotion) => void;
}) => Columns<Promotion>;

export const PROMOTION_LIST_COLUMNS: PromotionListColumns = ({ onChangeDelivery }) =>
	[
		{
			key: 'title',
			headerName: '이벤트명',
			renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
		},
		{
			key: 'startDate',
			width: {
				max: '24rem',
			},
			headerName: '이벤트 기간',
			renderComponent: (_, item) => item.startDate + ' ~ ' + (item.isUntilSoldOut ? '소진 시까지' : item.endDate ?? ''),
		},
		{
			key: 'status',
			headerName: '진행상태',
			width: {
				max: '20rem',
			},
			renderComponent: (value) => (
				<Tag type="FILL" color={PROMOTION_STATUS_TAG_COLOR[value]}>
					{PROMOTION_STATUS_VALUES[value]}
				</Tag>
			),
		},
		{
			key: 'isAllPharm',
			headerName: '배송여부',
			width: {
				max: '20rem',
			},
			renderComponent: (_, item) => (
				<Flex $gap={'1rem'} $alignItems={'center'}>
					{item.isAllPharm || item.status !== 'RUNNING' ? (
						'-'
					) : (
						<>
							{item.isDeliveryAvailable ? '배송 가능' : '배송 불가'}
							<Button
								onClick={(e) => {
									e.stopPropagation();
									onChangeDelivery(item);
								}}
								buttonType={'LINE'}
								size={{ $fontSize: 'S', $heightSize: 'S' }}
							>
								{'변경'}
							</Button>
						</>
					)}
				</Flex>
			),
		},
	] as const;
