import { HOURS, INITIAL_HOUR, INITIAL_MINUTE, INITIAL_OPEN_VALUES, MINUTES, TIME_KEY_TO_DAY_OF_WEEK } from './consts';
import { type OpenHours, type TimeKeyGroup } from '../../../../services/pharm/types';

export const INIT_TIME = '00';

interface OneDayValueType {
	key: number;
	label: string;
	value: string;
}

export type OpenDayType = 'busy' | 'duty';
export type OneDayPeriod = 'start' | 'close';
export type OneDayPeriodDetail = 'hour' | 'minute';

type OneDayPeriodDetailValue = Record<OneDayPeriodDetail, OneDayValueType>;
export type OneDayPeriodValue = Record<OneDayPeriod, OneDayPeriodDetailValue>;

export interface OneDayValue {
	duty: OneDayPeriodValue;
	busy: OneDayPeriodValue[];
}

interface GetTimeOption {
	status: OneDayPeriod;
	times: OneDayPeriodValue;
	dutyTimes?: OneDayPeriodValue;
	type?: 'busy' | 'duty';
}

export const getHours = ({ status, times, type, dutyTimes }: GetTimeOption) => {
	return type === 'busy' ? getBusyHours({ status, times, dutyTimes }) : getDutyHours({ status, times });
};
export const getDutyHours = ({ status, times }: GetTimeOption) => {
	const { start } = times;

	// 종료 시간은 시작 시간보다 큰 것 부터 (익일) 작은 것까지
	if (status === 'close') {
		const startKey = start.hour.key;
		if (startKey === 0 || startKey === -1) {
			return HOURS.slice(0, 24);
		}
		return HOURS.slice(startKey + 1, startKey + 24);
	}
	return HOURS.slice(0, 24);
};
/**
 * @fix duty hours 값이 없으면 busy hours 옵션 값들이 정해지지 않음
 * 기본값을 주던가 운영 시간 먼저 셋팅하라고 알려줘야할듯
 */
export const getBusyHours = ({ status, dutyTimes, times }: GetTimeOption) => {
	if (!dutyTimes) return HOURS;
	let start = dutyTimes.start.hour.key;
	let end = dutyTimes.close.hour.key;

	if (status === 'start') {
		const busyEnd = times.close.hour.key;
		if (busyEnd !== -1) {
			end = busyEnd;
		}
	}
	if (status === 'close') {
		const busyStart = times.start.hour.key;
		if (busyStart !== -1) {
			start = busyStart;
		}
		start += 1;
		end += 1;
	}

	return HOURS.slice(start, end);
};

// // duty: 일반 영업 시간만 설정
// // busy: 혼잡 시간 설정
// // rest: 정기 휴무일
export type OneDayStatus = 'duty' | 'busy' | 'rest';

export function getOneDayStatus(values: OneDayValue): OneDayStatus {
	const isRest = values.duty.start.hour.value === INIT_TIME && values.duty.close.hour.value === INIT_TIME;

	const isBusy = values.busy.length > 0;

	if (isRest) {
		return 'rest';
	}
	if (isBusy) {
		return 'busy';
	}
	return 'duty';
}

export function isStartBeforeClose(values: OneDayValue) {
	let result = true;
	const dutyStartHour = values.duty.start.hour.key;
	const dutyCloseHour = values.duty.close.hour.key;

	if (dutyStartHour > dutyCloseHour) {
		// 초기값에서 변경할 수도 있어서..
		if (dutyStartHour === -1 || dutyCloseHour === -1) {
			result = true;
		} else {
			result = false;
		}
	}

	values.busy.forEach((el) => {
		const startKey = el.start.hour.key;
		const closeKey = el.close.hour.key;
		if (startKey > closeKey) {
			if (startKey === -1 || closeKey === -1) {
				result = true;
			} else {
				result = false;
			}
		}
	});

	return result;
}

export function isBusyInDuty(values: OneDayValue) {
	let result = true;
	const dutyStartHour = values.duty.start.hour.key;
	const dutyCloseHour = values.duty.close.hour.key;
	const dutyStartMin = values.duty.start.minute.key;
	const dutyCloseMin = values.duty.close.minute.key;

	// 혼잡 시간 설정 안함
	if (values.busy.length <= 0) {
		result = true;
	} else {
		values.busy.forEach(({ start, close }) => {
			const startHourKey = start.hour.key;
			const startMinKey = start.minute.key;
			const closeHourKey = close.hour.key;
			const closeMinKey = close.minute.key;

			// 혼잡 시간 초기 설정
			if (startHourKey < dutyStartHour && startHourKey !== -1) {
				result = false;
			} else if (startHourKey === dutyStartHour) {
				if (startMinKey < dutyStartMin && startMinKey !== -1) {
					result = false;
				}
			}

			if (closeHourKey > dutyCloseHour && closeHourKey !== -1) {
				result = false;
			} else if (closeHourKey === dutyCloseHour) {
				if (closeMinKey > dutyCloseMin && closeMinKey !== -1) {
					result = false;
				}
			}
		});
	}

	return result;
}

export const formatTimeOfObject = (string: string): OneDayPeriodDetailValue => {
	const [hourValue, minValue] = string.split(':');
	const hourObj = HOURS.find((hour) => hour.value === hourValue) ?? { ...INITIAL_HOUR };
	const minObj = MINUTES.find((min) => min.value === minValue) ?? { ...INITIAL_MINUTE };
	return {
		hour: hourObj.value === INIT_TIME ? { ...INITIAL_HOUR } : { ...hourObj },
		minute: minObj.value === INIT_TIME ? { ...INITIAL_MINUTE } : { ...minObj },
	};
};

export function getOneDayOpenHours(data: OpenHours, timeKey: TimeKeyGroup): OneDayValue {
	const oneDayDuty: OneDayPeriodValue = Object.entries(data)
		.filter(([key]) => key.includes(timeKey) && key.includes('duty' as OpenDayType))
		.reduce(
			(acc, [key, value]) => {
				const periodKey: OneDayPeriod = key.includes('s') ? 'start' : 'close';
				acc = { ...acc, [periodKey]: formatTimeOfObject(value) };
				return acc;
			},
			{ ...INITIAL_OPEN_VALUES },
		);

	const oneDayBusy: OneDayPeriodValue[] = data.busyTimes
		.filter((value) => value.dayOfWeek === TIME_KEY_TO_DAY_OF_WEEK[timeKey])
		.map((el) => ({ start: formatTimeOfObject(el.startAt), close: formatTimeOfObject(el.endAt) }));

	// 익일
	if (oneDayDuty.start.hour.key > oneDayDuty.close.hour.key) {
		oneDayDuty.close.hour.key += 24;
	}

	oneDayBusy.forEach((time, idx) => {
		if (time.start.hour.key > time.close.hour.key) {
			oneDayBusy[idx].close.hour.key += 24;
		}
	});
	return { duty: oneDayDuty, busy: oneDayBusy };
}
