import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { InsideTitle, Loading } from '../../../components/Common';
import { Icon, IconID } from '../../../components/Display';
import { useGetFAQList } from '../../../services/faq/queries';

export const noticeData: string[] = ['자주 하는 질문을 모아두었습니다.'] as const;

const FAQ = () => {
	const [selectedIdx, setSelectedIdx] = useState<number>(-1);

	const onChangeSelectedIdx = useCallback(
		(idx: number) => {
			if (idx === selectedIdx) {
				setSelectedIdx(-1);
			} else {
				setSelectedIdx(idx);
			}
		},
		[selectedIdx],
	);
	const { isLoading, data, isError, isSuccess } = useGetFAQList();

	useEffect(() => {
		if (isError) toast.error('데이터를 불러오는데 실패하였습니다.');
	}, [isError]);

	if (isLoading) return <Loading />;

	if (isSuccess) {
		return (
			<Container>
				<div className="notice">
					<InsideTitle title="FAQ 이용안내" isBorder />
					<ul className="notice--list">
						{noticeData.map((notice, idx) => (
							<li className="notice--item" key={'notice--' + idx}>
								{notice}
							</li>
						))}
					</ul>
				</div>

				<div className="content">
					<InsideTitle title="자주 묻는 질문" isBorder />
					<ul className="faq">
						{data.length > 0 ? (
							data.map((el, idx) => (
								<li className={`faq--item${selectedIdx === idx ? ' selected' : ''}`} key={'faq--' + idx}>
									<div
										className="faq--question"
										onClick={() => {
											onChangeSelectedIdx(idx);
										}}
									>
										<span className="faq--icon faq--question--icon">Q</span>
										<p className="faq--content faq--question--content">{el.title}</p>
										<Icon
											id={IconID.CHEVRON}
											width="2rem"
											height="2rem"
											defaultColor="gray_600"
											className="faq--question--chevron"
										/>
									</div>
									<div className="faq--answer">
										<span className="faq--icon faq--answer--icon">A</span>
										<p className="faq--content faq--answer--content">{el.content}</p>
									</div>
								</li>
							))
						) : (
							<p className="empty">등록된 질문이 없습니다.</p>
						)}
					</ul>
				</div>
			</Container>
		);
	}
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4rem;
	.notice {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
		&--list {
			background-color: ${({ theme }) => theme.colors.gray.gray_100};
			padding: 1.2rem 1.6rem;
			display: flex;
			flex-direction: column;
			gap: 0.6rem;
			border-radius: 8px;
		}
		&--item {
			${({ theme }) => theme.font.body.body_2};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.gray.gray_800};
			&::before {
				content: '- ';
			}
		}
	}

	.faq {
		display: flex;
		flex-direction: column;
		&--item {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_200};
		}
		&--item.selected {
			.faq--question--content {
				font-weight: 700;
			}
			.faq--answer {
				padding: 2rem;
				max-height: 100rem;
			}
			.faq--question--chevron {
				transform: rotate(180deg);
			}
		}

		&--icon {
			font-family: 'SUIT', sans-serif;
			font-size: 1.4rem;
			line-height: 1.4rem;
			font-weight: 800;
			width: 2.4rem;
			height: 2.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 999px;
		}

		&--content {
			${({ theme }) => theme.font.body.body_2};
			flex: 1;
		}

		&--question {
			padding: 2rem;
			display: flex;
			gap: 1.2rem;
			align-items: center;
			cursor: pointer;
			&--icon {
				background-color: ${({ theme }) => theme.colors.primary.primary_200};
				color: ${({ theme }) => theme.colors.primary.primary_800};
			}
			&--content {
			}
			&--chevron {
				transition: 0.15s transform;
				transform: rotate(0deg);
			}
		}

		&--answer {
			max-height: 0;
			overflow-y: hidden;
			display: flex;
			align-items: flex-start;
			padding: 0 2rem;
			gap: 1.2rem;
			background-color: ${({ theme }) => theme.colors.gray.gray_100};
			transition:
				0.15s max-height,
				0.15s padding;
			&--icon {
				background-color: ${({ theme }) => theme.colors.primary.primary_600};
				color: ${({ theme }) => theme.colors.white};
			}
			&--content {
				white-space: pre-wrap;
			}
		}
	}
	.empty {
		${({ theme }) => theme.font.body.body_2}
		color:${({ theme }) => theme.colors.gray.gray_700};
		margin-top: 1.2rem;
		text-align: center;
	}
`;
export default FAQ;
