import { type Asset, type AssetRequest } from './types';
import { Post } from '../index';

export const asset = {
	postAsset: async <T = Asset,>({ file, thumbnail = false }: AssetRequest): Promise<T> => {
		const formData = new FormData();
		formData.append('file', file);
		const response = await Post<T>(`/assets?thumbnail=${thumbnail}`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	},
};
