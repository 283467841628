import { createGlobalStyle } from 'styled-components';

import { barStyle } from '../../assets/styles/scrollStyle';

const GlobalStyle = createGlobalStyle`
    * {
        padding:0;
        margin:0;
        box-sizing: border-box;
    }
    html {
        font-size: 62.5%;
    }
    body {
        margin: 0;
        font-size: 1.6rem;
        ${barStyle()};
    }
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        &:disabled {
            cursor: default;
        }
    }

    html,
    body, 
    div, 
    span, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6, 
    p, 
    a, 
    ol, 
    ul, 
    li, 
    form, 
    label, 
    table, 
    caption, 
    tbody, 
    tfoot, 
    thead, 
    tr, 
    th, 
    td, 
    article,
    aside,
    footer,
    header,
    nav,
    section, 
    button {
        font-family: Pretendard, Helvetica, sans-serif;
    }   

    input {
        outline: none;
        ::placeholder {
            color: ${({ theme }) => theme.colors.gray.gray_600};
        }
    }
    button:disabled {
        pointer-events: none;
    }

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black}
    }
    a, button, textarea, g, path {
      outline:none;
    }

    .Toastify__toast{
        &-body > div {
            ${({ theme }) => theme.font.body.body_1};
            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
            &:last-child::before {
                display: block;
                ${({ theme }) => theme.font.title.title_2};
                font-weight: 700;
            }
        }
        &-icon {
            width: max-content;
            svg {
                display: none;
            }
            &::before {
                content: "";
                display: inline-block;
                width: 4rem;
                height: 4rem;
                background-position: center center;
                background-size: cover;
            }
        }
        &-container {
            width: 40rem;
        }

        &-theme--colored {
            &.Toastify__toast {
              white-space: pre-wrap;
                &--error {
                    background-color: ${({ theme }) => theme.colors.red.red_500} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/images/others/toast-error.svg");
                    }
                }
                &--success {
                    background-color: ${({ theme }) => theme.colors.green.green_600} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/images/others/toast-success.svg");
                    }
                }
                &--info {
                    background-color: ${({ theme }) => theme.colors.blue.blue_600} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/images/others/toast-info.svg");
                    }
                }
            }
            
        }
        &--error > .Toastify__toast-body > div:last-child::before {
            content:"Error";
        }
        &--success > .Toastify__toast-body > div:last-child::before {
            content:"Success";
        }
        &--info > .Toastify__toast-body > div:last-child::before {
            content:"Information";
        }
    }
`;

export default GlobalStyle;
