import { useReducer } from 'react';

import { type UserMessage } from '@sendbird/chat/message';

type Action =
	| {
			type: 'update_initialized' | 'update_loading';
			value: { status: boolean };
	  }
	| {
			type: 'update_messages';
			value: {
				messages: UserMessage[];
				clearBeforeAction: boolean;
			};
	  };

interface State {
	allMessages: UserMessage[];
	loading: boolean;
	initialized: boolean;
}

const defaultReducer = ({ ...draft }: State, action: Action) => {
	switch (action.type) {
		case 'update_initialized':
			return {
				...draft,
				initialized: action.value.status,
			};
		case 'update_loading':
			return {
				...draft,
				loading: action.value.status,
			};
		case 'update_messages':
			if (action.value.clearBeforeAction) {
				draft.allMessages = action.value.messages;
			} else {
				draft.allMessages = [...draft.allMessages, ...action.value.messages];
			}
			return draft;
	}
};

export const useMessageSearchReducer = () => {
	const [{ initialized, loading, allMessages }, dispatch] = useReducer(defaultReducer, {
		initialized: false,
		loading: true,
		allMessages: [],
	});

	const updateInitialized = (status: boolean) => {
		dispatch({ type: 'update_initialized', value: { status } });
	};
	const updateLoading = (status: boolean) => {
		dispatch({ type: 'update_loading', value: { status } });
	};

	const updateMessages = (messages: UserMessage[], clearBeforeAction: boolean) => {
		dispatch({
			type: 'update_messages',
			value: { messages, clearBeforeAction },
		});
	};

	/**
	 * 기능 메시지, 파일 메시지를 제외한 일반 텍스트 메시지와 다중 메시지의 텍스트 메시지만 검색 결과에서 필터링
	 */
	const messages = allMessages.filter(
		({ customType, messageType }) => messageType === 'user' && (!customType || customType === 'GP_ANNOUNCEMENT'),
	);

	return {
		updateInitialized,
		updateLoading,
		updateMessages,

		initialized,
		loading,
		messages,
	};
};
