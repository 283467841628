import React, { type CSSProperties, useMemo, useState, useRef } from 'react';

import styled, { css } from 'styled-components';

import { type DefaultOptionsProps, Options } from './components/Options';
import { findLabel } from './util';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { Icon, IconID } from '../../Display';

export interface SelectProps<T extends object> extends DefaultOptionsProps<T> {
	labelStyle?: CSSProperties;
	placeholder?: string;
	disabled?: boolean;
	className?: string;
	customValue?: (findValue: string) => React.ReactNode;
}

export const Select = <T extends object>(props: SelectProps<T>) => {
	const {
		options,
		selectKey = 'key',
		label = 'label',
		value,
		labelStyle,
		multiple,
		placeholder,
		className,
		disabled = false,
		customValue,
		...optionProps
	} = props;

	const [isOpen, setOpen] = useState(false);
	const containerRef = useRef(null);

	useOutsideClick(containerRef, () => {
		setOpen(false);
	});

	const findLabelValue = useMemo(
		() =>
			findLabel({
				options,
				selectKey: selectKey as keyof T,
				label: label as keyof T,
				value,
				multiple,
			}),
		[options, value],
	);

	return (
		<Container $isOpen={isOpen} $isNull={!findLabelValue} $disabled={disabled} ref={containerRef} className={className}>
			<div
				className="label"
				style={labelStyle}
				onClick={() => {
					setOpen(!isOpen);
				}}
			>
				<span className="label--item">
					{findLabelValue ? (customValue ? customValue(String(findLabelValue)) : findLabelValue) : placeholder}
				</span>
				<Icon id={IconID.CHEVRON} width="1.6rem" height="1.6rem" defaultColor="gray_600" className="label--arrow" />
			</div>

			<Options
				options={options}
				isOpen={isOpen}
				value={value}
				handleClose={() => {
					setOpen(false);
				}}
				selectKey={selectKey as keyof T}
				label={label as keyof T}
				referenceRef={containerRef}
				multiple={multiple}
				{...optionProps}
			/>
		</Container>
	);
};

const Container = styled.div<{ $disabled: boolean; $isOpen: boolean; $isNull: boolean }>`
	.label {
		padding: 1.1rem 1.2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid
			${({ theme, $isOpen }) => ($isOpen ? theme.colors.primary.primary_600 : theme.colors.gray.gray_300)};
		border-radius: 4px;
		cursor: pointer;
		${({ $isNull, theme }) =>
			$isNull &&
			css`
				color: ${theme.colors.gray.gray_600};
			`}

		&--item {
			${({ theme }) => theme.font.label.label_2};
			font-weight: 500;
		}

		&--arrow {
			transition: 0.3s transform;
			transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg) ' : 'rotate(0deg) ')};
		}
	}
	${({ $disabled }) => {
		if ($disabled)
			return css`
				background-color: ${({ theme }) => theme.colors.gray.gray_100};
				pointer-events: none;
			`;
	}}
`;
