import React from 'react';

import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { type UserMessage } from '@sendbird/chat/message';
import { format } from 'date-fns/format';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';

import { FailedMessageMenu } from './FailedMessageMenu';
import { getMessageType, isResendableMessage } from './utils';
import { Flex, VStack } from '../../../../components/Common';
import { Label } from '../../../../components/Display';
import { type AutoCustomType, type MessageTypes } from '../../types';
import AssemblyMessage from '../MessageContent/AssemblyMessage';
import { AutoMessage } from '../MessageContent/AutoMessage';
import { BasicMessage } from '../MessageContent/BasicMessage';
import { FunctionalMessage } from '../MessageContent/FunctionalMessage';

import type { CoreMessageType } from '../../utils';

interface MessageViewProps {
	userId: string;
	channel: GroupChannel | null;
	message: CoreMessageType;
	chainTop?: boolean;
	chainBottom?: boolean;
}

export const MessageView = (props: MessageViewProps) => {
	const { userId, channel, message, chainTop, chainBottom } = props;
	const isByMe =
		userId === message?.sender?.userId || message?.sendingStatus === 'pending' || message?.sendingStatus === 'failed';
	const createdAt = format(message.createdAt, 'a h:mm', { locale: ko });
	const showFailedMsgMenu = isResendableMessage(message, isByMe);

	const messageConfigs: Record<MessageTypes, React.ReactElement> = {
		BASIC: <BasicMessage message={message} isByMe={isByMe} />,
		AUTO: <AutoMessage message={message as UserMessage} customType={message.customType as `GP_${AutoCustomType}`} />,
		FUNCTIONAL: <FunctionalMessage message={message as UserMessage} />,
		ASSEMBLY: <AssemblyMessage message={message} isByMe={isByMe} />,
	};

	return (
		<Container
			className={'message-content'} // do not remove className, this is for message animated
			$isByMe={isByMe}
			$lastMessage={message.messageId === channel?.lastMessage?.messageId}
			$isGrouping={chainTop ?? chainBottom}
		>
			<Wrapper $isByMe={isByMe} $showFailedMsgMenu={showFailedMsgMenu}>
				{messageConfigs[getMessageType(message)]}
				{showFailedMsgMenu && <FailedMessageMenu message={message} />}
				{!showFailedMsgMenu && (
					<SuffixWrapper className={'message-suffix'}>
						{channel?.getUnreadMemberCount(message) !== 0 && (
							<Label $color={'primary_600'} $fontStyle={'caption_2'} $fontWeight={'medium'}>
								{channel?.getUnreadMemberCount(message)}
							</Label>
						)}
						{!chainBottom && (
							<Label $color={'gray_500'} $fontStyle={'caption_2'}>
								{createdAt}
							</Label>
						)}
					</SuffixWrapper>
				)}
			</Wrapper>
		</Container>
	);
};
const Container = styled(Flex)<{
	$isByMe?: boolean;
	$isGrouping?: boolean;
	$lastMessage?: boolean;
}>`
	justify-content: flex-start;
	align-items: flex-end;
	align-self: stretch;
	flex-direction: ${({ $isByMe }) => ($isByMe ? `row-reverse` : `row`)};
	margin-bottom: ${({ $isGrouping, $lastMessage }) => ($isGrouping ?? $lastMessage ? `0.8rem` : `1.6rem`)};
`;

const Wrapper = styled(Flex)<{
	$isByMe?: boolean;
	$showFailedMsgMenu?: boolean;
}>`
	gap: ${({ $showFailedMsgMenu }) => ($showFailedMsgMenu ? '0.4rem' : '0.8rem')};
	flex-direction: ${({ $isByMe }) => ($isByMe ? `row-reverse` : `row`)};
	justify-content: flex-start;
	align-items: flex-end;
	flex-basis: 32.6rem;
`;

const SuffixWrapper = styled(VStack)`
	align-items: flex-end;
	flex-wrap: nowrap;
	gap: 0.2rem;
`;
