import { useMutation, useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import {
	type NotificationConfigPK,
	type NotificationConfig,
	type PutNotificationRequest,
	type StoreId,
	type ReduceNotificationItem,
} from './types';
import { api } from '..';
import { type ErrorData } from '../types';

export const notificationConfigKeys = {
	all: ['notificationConfig'] as const,
	variables: () => [...notificationConfigKeys.all, 'variables'] as const,
	getNotificationConfigs: () => [...notificationConfigKeys.all, 'notificationConfigs'] as const,
	init: () => [...notificationConfigKeys.all, 'init'] as const,
	putNotificationConfig: () => [...notificationConfigKeys.all, 'put'] as const,
	deleteNotificationConfigActive: () => [...notificationConfigKeys.all, 'delete-active'] as const,
	patchNotificationConfigActive: () => [...notificationConfigKeys.all, 'patch-active'] as const,
} as const;

export const useGetNotificationConfigVariables = () =>
	useQuery({
		queryKey: notificationConfigKeys.variables(),
		queryFn: async () => await api.notificationConfigs.variables(),
	});

export const useGetNotificationConfigs = (params: StoreId) =>
	useQuery({
		queryKey: notificationConfigKeys.getNotificationConfigs(),
		queryFn: async () => await api.notificationConfigs.getNotificationConfigs(params),
		select: (data) =>
			data.reduce<ReduceNotificationItem[]>((acc, cur) => {
				const group = acc.find((el) => el.group === cur.group);
				if (group) {
					group.data.push(cur);
				} else {
					acc.push({ group: cur.group, groupName: cur.groupName, data: [cur] });
				}
				return acc;
				// acc[cur.group].push(cur);
				// return { ...acc };
			}, []),
	});

// 	const group = acc.find((g) => g.category === cur.categoryName);
// 	if (group) {
// 		group.options.push(cur);
// 	} else {
// 		acc.push({ category: cur.categoryName, options: [cur] });
// 	}
// 	return acc;
// }, result);
export const useGetNotificationConfigInit = () =>
	useMutation<unknown, AxiosError<ErrorData>, StoreId>({
		mutationKey: notificationConfigKeys.init(),
		mutationFn: async (params) => await api.notificationConfigs.init(params),
	});

export const usePutNotificationConfig = () =>
	useMutation<NotificationConfig, AxiosError<ErrorData>, PutNotificationRequest>({
		mutationKey: notificationConfigKeys.putNotificationConfig(),
		mutationFn: async (params) => await api.notificationConfigs.putNotificationConfig(params),
	});

export const useDeleteNotificationConfigActive = () =>
	useMutation<unknown, AxiosError<ErrorData>, StoreId & NotificationConfigPK>({
		mutationKey: notificationConfigKeys.deleteNotificationConfigActive(),
		mutationFn: async (params) => await api.notificationConfigs.deleteNotificationActive(params),
	});

export const usePatchNotificationConfigActive = () =>
	useMutation<unknown, AxiosError<ErrorData>, StoreId & NotificationConfigPK>({
		mutationKey: notificationConfigKeys.patchNotificationConfigActive(),
		mutationFn: async (params) => await api.notificationConfigs.patchNotificationActive(params),
	});
