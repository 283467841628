import React, { useRef } from 'react';

import { type Placement, type UseFloatingReturn } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { useCustomFloating } from '../../../lib/floatingUi/useCustomFloating';
import { type DefaultModalProps } from '../Modal';
import { ModalHeader } from '../ModalHeader';

export interface FloatingModalProps extends DefaultModalProps {
	isOpen: boolean;
	referenceRef: React.RefObject<HTMLElement>;
	options?: { offset?: number; allowedPlacements?: Placement[] };
	handleClose?: () => void;
	children?: React.ReactNode;
}

interface WrapperProps {
	$isOpen: boolean;
	$floating: UseFloatingReturn;
	$zIndex?: number;
}

export const FloatingModal = (props: FloatingModalProps) => {
	const { referenceRef, isOpen, children, header, handleClose, zIndex, options } = props;
	const modalRef = useRef<HTMLDivElement>(null);
	const floating = useCustomFloating({
		referenceRef,
		floatingRef: modalRef,
		options,
	});

	return (
		<Container ref={modalRef} $floating={floating} $isOpen={isOpen} $zIndex={zIndex}>
			<div className="inner">
				{header && <ModalHeader {...header} handleClose={handleClose} />}
				{children}
			</div>
		</Container>
	);
};

const Container = styled.div<WrapperProps>`
	${({ $isOpen }) =>
		$isOpen
			? css`
					visibility: visible;
					opacity: 1;
				`
			: css`
					visibility: hidden;
					opacity: 0;
				`}
	${({ $floating, $zIndex }) => css`
		position: ${$floating.strategy};
		top: 0;
		left: 0;
		transform: translate(${`${$floating?.x}px, ${$floating?.y}px`});
		z-index: ${$zIndex ?? 996};
	`}
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
	box-shadow: ${({ theme }) => theme.shadow.modal};
	border-radius: 4px;
`;
