import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { type BaseMessage } from '@sendbird/chat/message';
import { format } from 'date-fns/format';
import { isThisYear } from 'date-fns/isThisYear';
import { isToday } from 'date-fns/isToday';
import { isYesterday } from 'date-fns/isYesterday';
import { ko } from 'date-fns/locale/ko';

import { DELETED_MESSAGE } from '../../../../pages/CRM/Chat/consts';
import { isDeletedMessage } from '../../../Channel/components/MessageContent/BasicMessage/utils';
import { removeImageUrls } from '../../../Channel/utils';

export const getChannelTitle = (channel: GroupChannel, currentUserId: string): string => {
	if (!channel?.name && !channel?.members) {
		return '제목 없음';
	}
	if (channel?.name && channel.name !== 'Group Channel') {
		return channel.name;
	}

	if (channel?.members?.length === 1) {
		return '멤버 없음';
	}

	return channel?.members
		.filter(({ userId }) => userId !== currentUserId)
		.map(({ nickname }) => nickname || '이름 없음')
		.join(', ');
};

export const getLastMessageCreatedAt = (channel: GroupChannel) => {
	const createdAt = channel?.lastMessage?.createdAt;
	const optionalParam = { locale: ko };
	if (!createdAt) {
		return '';
	}
	if (isToday(createdAt)) {
		return format(createdAt, 'a h:mm', optionalParam);
	}
	if (isYesterday(createdAt)) {
		return '어제';
	}
	if (isThisYear(createdAt)) {
		return format(createdAt, 'MMM do', optionalParam);
	}
	return format(createdAt, 'yyyy/M/d', optionalParam);
};
export const getChannelUnreadMessageCount = (channel?: GroupChannel) =>
	channel?.unreadMessageCount ? channel.unreadMessageCount : 0;

const getPrettyLastMessage = (message: BaseMessage) => {
	if (!message) return '';

	// 모든 메시지는 sendbird 의 userMessage, fileMessage 형식으로만 주고 받음
	if (!message.isUserMessage() && !message.isFileMessage()) {
		return '잘못된 메시지 형식입니다.';
	}
	if (isDeletedMessage(message)) {
		return DELETED_MESSAGE;
	}
	if (message.isUserMessage()) {
		return removeImageUrls(message.message) ?? '';
	}
	if (message.isFileMessage()) {
		return message.name;
	}
};
export const getLastMessage = (channel?: GroupChannel) =>
	channel?.lastMessage ? getPrettyLastMessage(channel?.lastMessage) : '';
