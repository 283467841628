import React from 'react';

import styled from 'styled-components';

import { scrollStyle } from '../../../../assets/styles/scrollStyle';
import { Loading, VStack } from '../../../../components/Common';
import { Label } from '../../../../components/Display';
import { FloatingModal } from '../../../../components/Modals/FloatingModal';
import { useOnScrollPositionChangeDetector } from '../../../../hooks/useOnScrollPositionChangeDetector';
import { useMessageSearchContext } from '../../context/MessageSearchProvider';
import { SearchMessageItem } from '../SearchMessageItem';

interface SearchMessageListProps {
	isOpen: boolean;
	referenceRef: React.RefObject<HTMLElement>;
	loadNext: () => Promise<void>;
}
export const SearchMessageList = ({ isOpen, referenceRef, loadNext }: SearchMessageListProps) => {
	const { messages, requestString, loading } = useMessageSearchContext();

	const onScroll = useOnScrollPositionChangeDetector({
		onReachedBottom: () => {
			loadNext();
		},
	});

	if (!isOpen) {
		return null;
	}

	return (
		<FloatingModal referenceRef={referenceRef} isOpen={isOpen}>
			<Container onScroll={onScroll} style={{ width: referenceRef.current?.clientWidth ?? '100%' }}>
				{loading ? (
					<VStack $alignItems={'center'} $justify={'center'} $width={'100%'} $height={'100%'}>
						<Loading />
					</VStack>
				) : !messages.length ? (
					<VStack $alignItems={'center'} $justify={'center'} $width={'100%'} $height={'100%'}>
						<Label $color={'gray_700'} $fontWeight={'medium'} $fontStyle={'label_2'}>
							{'일치하는 검색 결과가 없습니다.'}
						</Label>
					</VStack>
				) : (
					<>
						<Label $fontStyle={'label_2'} $fontWeight={'medium'} $color={'gray_700'}>
							{'검색 결과'}
						</Label>
						<ItemWrapper>
							{messages.map((m, i) => (
								<SearchMessageItem key={i} requestString={requestString} message={m} />
							))}
						</ItemWrapper>
					</>
				)}
			</Container>
		</FloatingModal>
	);
};

const Container = styled(VStack)`
	height: 68rem;
	display: flex;
	padding: 2rem 2rem 0.8rem;
	align-items: flex-start;
	gap: 0.8rem;
	min-width: 53.5rem;
	flex: 1 0 0;
	max-width: 81.4rem;
	${scrollStyle({ hideHorizontal: true })}
	&::-webkit-scrollbar {
		width: 0.4rem;
	}
`;

const ItemWrapper = styled(VStack)`
	align-items: flex-start;
	align-self: stretch;
`;
