import { toast } from 'react-toastify';

import { onDownloadBlob } from '../../../../../utils/onDownloadBlob';
import { type CoreMessageType } from '../../../utils';

import type { FileData } from '../../../types';
import type { BaseMessage, UserMessage } from '@sendbird/chat/message';

export const isAnnouncementMessage = (message: BaseMessage): boolean =>
	message && !!message.customType && message.customType === 'GP_ANNOUNCEMENT';

export const isFileMessage = (message: BaseMessage): boolean =>
	message && !!message.customType && message.customType === 'GP_FILE';

export const isImageMessage = (data: BaseMessage['data']): boolean => {
	try {
		const json = JSON.parse(data);
		return json.files.length && json.files[0].isImage;
	} catch (e) {
		return false;
	}
};

export const isDeletedMessage = (message: BaseMessage): boolean =>
	message && !!message.customType && message.customType === 'GP_DELETED';

export const isExpiredFile = (expiredAt: string) => {
	if (new Date(expiredAt).getTime() - new Date().getTime() <= 0) {
		return true;
	}
	return false;
};

export const downloadFile = async (file: FileData | null) => {
	if (file === null) {
		toast.error(`저장할 수 없는 파일입니다.`);
		return null;
	}
	const { fileName, url, expiredAt } = file;

	if (isExpiredFile(expiredAt)) {
		toast.info(`만료된 파일입니다.`);
		return null;
	}

	fetch(url)
		.then(async (res) => await res.blob())
		.then((blob) => {
			onDownloadBlob(blob, fileName);
		});
};

export const isOGMessage = (message: CoreMessageType): message is UserMessage => {
	if (!message) return false;
	return (
		!!message.ogMetaData &&
		!!(
			message.ogMetaData.url ??
			message.ogMetaData.title ??
			message.ogMetaData.description ??
			message.ogMetaData.defaultImage
		)
	);
};
