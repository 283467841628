import { useEffect, useState } from 'react';

const DEBOUNCING_TIME = 500;

export function useSearchStringEffect(searchString: string): string {
	const [requestString, setRequestString] = useState('');
	const [debouncingTimer, setDebouncingTimer] = useState<NodeJS.Timeout>();

	useEffect(() => {
		clearTimeout(debouncingTimer);
		if (searchString) {
			setDebouncingTimer(
				setTimeout(() => {
					setRequestString(searchString);
				}, DEBOUNCING_TIME),
			);
		} else {
			setRequestString('');
		}
	}, [searchString]);

	return requestString;
}
