import { type CSSProperties } from 'styled-components';

import { type OneDayStatus, type OneDayPeriodValue } from './utils';
import { type StyledLabelProps } from '../../../../components/Display';
import { type ColorKeys } from '../../../../utils/changeColorKeyToType';

import type { DayOfWeek, TimeKeyGroup } from '../../../../services/pharm/types';
export const MAX_BUSY_COUNT = 3;
export const scheduleLabels = {
	openHours: {
		title: '요일별 영업시간',
		button: '저장',
		info: `혼잡 시간대를 설정하면 고객과의 채팅에서 ‘응대가 늦어질 수 있다’는 내용의 안내 메시지가 자동으로 발송됩니다.`,
	},
	restDays: {
		title: '임시 휴무일',
		button: '휴무일 등록',
		info: `임시휴무일을 지정하시면 영업 시간이 존재하더라도 굿팜 앱에서 휴무일로 표시됩니다.`,
		placeholder: `등록된 휴무일이 없습니다.`,
	},
} as const;
export const alertMessage = {
	openHours: {
		success: `영업시간이 수정되었습니다.`,
		error: `영업시간 수정에 실패했습니다.`,
	},
	registerRestDays: {
		success: `임시 휴무일이 등록되었습니다.`,
		error: `임시 휴무일 등록에 실패했습니다.`,
	},
	deleteRestDays: {
		success: `임시 휴무일이 삭제되었습니다.`,
		error: `임시 휴무일 삭제에 실패했습니다.`,
	},
} as const;

export const DELETE_REST_DAYS_MODAL_TITLE = '등록된 임시 휴무일을 취소하시겠습니까?\n휴무 일자 : ';

export const OPEN_HOURS_MESSAGES = {
	SET_DUTY_OPEN_HOURS: '요일별 영업 시간이 모두 입력되지 않았습니다.\n요일별 영업시간을 입력해 주세요.',
	SET_BUSY_OPEN_HOURS:
		'혼잡 시간대가 입력되지 않았습니다. 시간대를 입력하거나\n설정을 원하시지 않는 경우 체크박스 선택을 해제해 주세요.',
	SET_START_BEFORE_CLOSE: `시작 시간은 종료 시간 이전으로 설정되어야합니다.`,
	SET_BUSY_IN_DUTY: `혼잡 시간은 운영 시간 이내에 설정되어야합니다.`,
} as const;

export const TIME_KEY_GROUPS = Array.from({ length: 8 }, (_, idx) => (idx + 1).toString()) as TimeKeyGroup[];

export const HOURS = Array.from({ length: 48 }, (_, idx) => {
	let prefix = '오전 ';
	const hour = idx;
	let hourLabel: number | string = hour;
	let hourValue = hour;
	let suffix = '시';

	if (hour > 12 && hour < 24) {
		hourLabel -= 12;
		prefix = '오후 ';
	}
	if (hour >= 24) {
		hourLabel -= 24;
		hourValue -= 24;
		prefix = '익일 ';
	}

	if (hour === 12 || hour === 36) {
		hourLabel = '정오';
		prefix = '';
		suffix = '';
	}

	if (hour === 0 || hour === 24 || hour === 48) {
		hourLabel = '자정';
		prefix = '';
		suffix = '';
	}

	return {
		/**
		 * key = 익일 계산을 위함
		 * value = 서버와 주고 받을 값
		 */
		key: idx,
		label: prefix + hourLabel.toString() + suffix,
		value: hourValue.toString().padStart(2, '0'),
	};
});

export const INITIAL_HOUR: (typeof HOURS)[number] = {
	key: -1,
	label: '오전 0시',
	value: '00',
} as const;

export const MINUTES = Array.from({ length: 6 }, (_, idx) => ({
	key: idx * 10,
	label: (idx * 10).toString() + '분',
	value: (idx * 10).toString().padStart(2, '0'),
}));

export const INITIAL_MINUTE: (typeof MINUTES)[number] = {
	key: -1,
	label: '0분',
	value: '00',
} as const;

export const INITIAL_OPEN_VALUES: OneDayPeriodValue = {
	start: {
		hour: INITIAL_HOUR,
		minute: INITIAL_MINUTE,
	},
	close: {
		hour: INITIAL_HOUR,
		minute: INITIAL_MINUTE,
	},
} as const;

export const DAY_LABEL: Record<TimeKeyGroup, { label: string; color?: ColorKeys }> = {
	'1': { label: '월요일' },
	'2': { label: '화요일' },
	'3': { label: '수요일' },
	'4': { label: '목요일' },
	'5': { label: '금요일' },
	'6': { label: '토요일', color: 'blue_600' },
	'7': { label: '일요일', color: 'red_500' },
	'8': { label: '공휴일' },
};

export const TIME_KEY_TO_DAY_OF_WEEK: Record<TimeKeyGroup, DayOfWeek> = {
	1: 'MONDAY',
	2: 'TUESDAY',
	3: 'WEDNESDAY',
	4: 'THURSDAY',
	5: 'FRIDAY',
	6: 'SATURDAY',
	7: 'SUNDAY',
	8: 'HOLIDAY',
} as const;

export const DAY_OPTIONS: Array<{ key: OneDayStatus; label: string }> = [
	{ key: 'rest', label: '정기 휴무일' },
	{ key: 'busy', label: '혼잡 시간대' },
];

export const timeInputLabels: Record<string, StyledLabelProps & { style?: CSSProperties }> = {
	default: {
		$fontStyle: 'body_3',
		$fontWeight: 'medium',
		style: { whiteSpace: 'nowrap' },
	},
	colon: {
		$textAlign: 'center',
		$fontStyle: 'body_2',
	},
	tilde: {
		$color: 'gray_600',
		$fontStyle: 'body_2',
		$fontWeight: 'medium',
		$textAlign: 'center',
		style: { width: '2.2rem' },
	},
};

export const selectProps = {
	hour: {
		placeholder: '오전 0시',
		labelStyle: {
			height: '3.2rem',
			padding: '0 0.8rem 0 1.2rem',
			gap: '0.4rem',
			width: '10.4rem',
		},
		scrollToSelect: true,
	},
	minute: {
		placeholder: '0분',
		labelStyle: {
			height: '3.2rem',
			padding: '0 0.8rem 0 1.2rem',
			gap: '0.4rem',
			width: '7.6rem',
		},
		scrollToSelect: true,
	},
};
