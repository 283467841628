import React from 'react';

import modal, { ModalProvider } from './modalContext';
import user, { UserProvider } from './user';

/** 전역에서 사용할 context Provider  */
export const EntireContextProvider = ({ children }: { children: React.ReactNode }) => (
	<UserProvider>
		<ModalProvider>{children} </ModalProvider>
	</UserProvider>
);

export default { user, modal };
