import React, { useCallback, useMemo } from 'react';

import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';
import { isSameDay } from 'date-fns/isSameDay';
import { ko } from 'date-fns/locale/ko';
import { subWeeks } from 'date-fns/subWeeks';
import { subYears } from 'date-fns/subYears';

import { BarLabel, ComposedChart, type ComposedChartProps } from '../../../components/Charts';
import { InsideTitle } from '../../../components/Common';
import { ComparisonCard } from '../../../components/Display';
import { type PeriodUnit, useGetPeriodDetails } from '../../../services/statistics/queries';
import { ItemsWrapper } from '../components/styles';
import { formatPeriod, getDateEndOfUnit, getDateStartOfUnit } from '../utils';

import type { StatsDetailDaily } from '../../../services/statistics/types';

const NUMBER_OF_WEEK = 7;

const COMPARE_UNIT_WORD: Record<PeriodUnit, { cur: string; prev: string }> = {
	daily: { cur: '오늘의 ', prev: '지난주 일일 평균 ' },
	weekly: { cur: '금주의 ', prev: '전년도 동기 ' },
	monthly: { cur: '금월의 ', prev: '전년도 동기 ' },
} as const;
function getBarColors(data?: object[]) {
	if (!data) return [];
	return data.map((_, idx) => (idx === data.length - 1 ? '#0E9098' : '#5AD7D2'));
}

type AverageExtended = { averageSales?: number; averageGrossProfit?: number } & Pick<
	StatsDetailDaily,
	'day' | 'sales' | 'grossProfit'
>;

type CompareTarget = 'lastYear' | 'lastWeek';
interface ComparisonProps {
	storeId: string;
	unit: PeriodUnit;
	date: Date;
	target: CompareTarget;
}

export function Comparison({ storeId, unit, date, target }: ComparisonProps) {
	const comparisonTitle = target === 'lastYear' ? '전년도 동기 대비 ' : '평균 ';

	const period = useMemo(() => {
		const [start, end] = [getDateStartOfUnit(date, unit), getDateEndOfUnit(date, unit)];
		let prev = date;
		let [prevStart, prevEnd] = [prev, prev];
		if (target === 'lastYear') {
			prev = subYears(date, 1);
			[prevStart, prevEnd] = [prev, prev];

			if (unit === 'monthly') {
				prevEnd = getDateEndOfUnit(prevEnd, unit);
			}
			if (unit === 'weekly') {
				prevEnd = addDays(prev, 6);
			}
		} else {
			prev = subWeeks(date, 1);
			[prevStart, prevEnd] = [getDateStartOfUnit(prev, 'weekly'), getDateEndOfUnit(prev, 'weekly')];
		}
		return { cur: formatPeriod([start, end]), prev: formatPeriod([prevStart, prevEnd]) };
	}, [date, unit, target]);

	const { data, isSuccess } = useGetPeriodDetails<'daily'>({
		storeId,
		period: [period.cur, period.prev],
		unit: 'daily',
	});

	const chartData: AverageExtended[] = useMemo(() => {
		if (isSuccess) {
			const [prev, cur] = [data[1], data[0]];
			const sum = (arr: StatsDetailDaily[], key: keyof StatsDetailDaily) =>
				arr.reduce((acc, item) => acc + Number(item[key]), 0);

			const prevSalesSums = sum(prev, 'sales');
			const prevGrossProfitSums = sum(prev, 'grossProfit');

			if (target === 'lastYear') {
				const salesSums = sum(cur, 'sales');
				const grossProfitSums = sum(cur, 'grossProfit');
				return [
					{
						day: prev[0].day,
						sales: prevSalesSums,
						averageSales: prevSalesSums,
						grossProfit: prevGrossProfitSums,
						averageGrossProfit: prevGrossProfitSums,
					},
					{
						day: cur[0].day,
						sales: salesSums,
						averageSales: salesSums,
						grossProfit: grossProfitSums,
						averageGrossProfit: grossProfitSums,
					},
				];
			}

			if (target === 'lastWeek') {
				const avgPrev = prev
					.map(({ ...rest }) => ({
						averageSales: Math.floor(prevSalesSums / NUMBER_OF_WEEK),
						averageGrossProfit: Math.floor(prevGrossProfitSums / NUMBER_OF_WEEK),
						...rest,
					}))
					.reverse();

				return [...avgPrev, ...cur];
			}
		}
		return [];
	}, [target, isSuccess, data]);

	const xTickFormatter = useCallback(
		(value: string, target: CompareTarget) => {
			const { startDate, endDate } = period.cur;
			if (target === 'lastWeek') {
				if (isSameDay(value, endDate)) {
					return '오늘';
				}
				return format(value, 'EEEE', { locale: ko });
			}

			if (target === 'lastYear') {
				return `${format(value === endDate ? startDate : period.prev.startDate, 'yyyy-MM-dd (E)', { locale: ko })} ~\n${format(value, 'yyyy-MM-dd (E)', { locale: ko })}`;
			}

			return '';
		},
		[period.cur.endDate],
	);

	const chartProps: ComposedChartProps = useMemo(
		() => ({
			wrapperStyle: { minWidth: '65rem' },
			width: 650,
			height: 440,
			margin:
				target === 'lastWeek'
					? { top: 106, right: 125, bottom: 37, left: 70 }
					: { top: 104, right: 145, bottom: 60, left: 82 },
			legendProps: {
				wrapperStyle: { top: 32 },
				innerStyle: { gap: '2rem' },
				payload:
					target === 'lastWeek'
						? [
								{ type: 'square', color: '#0E9098', value: '오늘' },
								{ type: 'plainline', color: '#F74444', value: '평균' },
							]
						: [
								{ type: 'square', color: '#5AD7D2', value: '전년도 동기' },
								{ type: 'square', color: '#0E9098', value: unit === 'monthly' ? '금월' : '금주' },
							],
			},
			xAxisProps: [
				{
					dataKey: 'day',
					padding: target === 'lastWeek' ? { left: 16, right: 16 } : { left: 66, right: 66 },
					fontColor: 'gray_900',
					tickFormatter: (value) => xTickFormatter(value as string, target),
				},
			],
		}),
		[target, period.cur.endDate],
	);

	return (
		<>
			<ItemsWrapper $gap={'1.2rem'} $direction={'column'}>
				<InsideTitle title={comparisonTitle + '판매 매출 비교'} />
				<ComposedChart
					data={chartData}
					barColors={getBarColors(chartData)}
					barProps={[
						{
							dataKey: 'sales',
							barSize: target === 'lastYear' ? 64 : 12,
							label: (props) => <BarLabel {...props} />,
						},
					]}
					lineProps={
						target === 'lastWeek'
							? [
									{
										dataKey: 'averageSales',
										stroke: '#F74459',
										legendType: 'plainline',
										strokeWidth: 2.5,
									},
								]
							: []
					}
					{...chartProps}
				/>
				<ComparisonCard.WithDates
					period={{ ...period }}
					title={'판매 매출'}
					titlePrefix={COMPARE_UNIT_WORD[unit]}
					value={{ prev: chartData[0]?.averageSales, cur: chartData[chartData.length - 1]?.sales }}
				/>
			</ItemsWrapper>
			<ItemsWrapper $gap={'1.2rem'} $direction={'column'}>
				<InsideTitle title={comparisonTitle + '매출 총이익 비교'} />
				<ComposedChart
					data={chartData}
					barColors={getBarColors(chartData)}
					barProps={[
						{
							dataKey: 'grossProfit',
							barSize: target === 'lastYear' ? 64 : 12,
							label: (props) => <BarLabel {...props} />,
						},
					]}
					lineProps={
						target === 'lastWeek'
							? [
									{
										dataKey: 'averageGrossProfit',
										stroke: '#F74459',
										legendType: 'plainline',
										strokeWidth: 2.5,
									},
								]
							: []
					}
					{...chartProps}
				/>
				<ComparisonCard.WithDates
					period={{ ...period }}
					title={'매출 총이익'}
					titlePrefix={COMPARE_UNIT_WORD[unit]}
					value={{ prev: chartData[0]?.averageGrossProfit, cur: chartData[chartData.length - 1]?.grossProfit }}
				/>
			</ItemsWrapper>
		</>
	);
}
