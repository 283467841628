import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { paymentTableColumn } from './tableColumns';
import { Description } from '../../../../../../components/Display';
import { ListTable } from '../../../../../../components/Table';
import context from '../../../../../../context';
import { useMemberPayments, useMemberTotalPayment } from '../../../../../../services/member/queries';
import { type GetMemberRequest } from '../../../../../../services/member/types';
import { handleChangeSorts } from '../../../../../../utils/onChangeSorts';
import { paymentRows } from '../../rows';

export const PaymentHistory = () => {
	const { userId } = useParams();
	const { userInfo } = context.user.useValue();
	const params = useMemo(() => {
		const result: GetMemberRequest = { storeId: undefined, userId: undefined };
		if (userId) result.userId = Number(userId);
		if (userInfo) result.storeId = userInfo.storeId;
		return result;
	}, [userInfo, userId]);

	const { data: totalPaymentData } = useMemberTotalPayment(params);
	const { data, pagination, queries } = useMemberPayments(params);

	return (
		<Container>
			<Description data={totalPaymentData} rows={paymentRows} labelStyle={{ $color: 'gray_800' }} />
			<ListTable
				data={data?.content}
				columns={paymentTableColumn}
				pagination={pagination}
				unit="건"
				emptyMessage="결제 내역이 없습니다."
				onCategoryClick={(column) => {
					handleChangeSorts(queries.values.sorts, column, (sorts) => {
						queries.dispatch('SET', {
							sorts,
						});
					});
				}}
				sortValues={queries.values.sorts}
			/>
		</Container>
	);
};

const Container = styled.div`
	margin-top: 1.6rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;
