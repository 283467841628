import React from 'react';

import styled from 'styled-components';

import { HStack, VStack } from '../../../../../components/Common';
import { Icon, IconID, Label } from '../../../../../components/Display';
import Theme from '../../../../../lib/styledComponents/Theme';
import { type Payment } from '../../../types';

type DiscountTypes = Array<{
	key: keyof Payment;
	type?: 'INCREMENT' | 'DECREMENT';
	label: string;
	hasIcon: boolean;
	hasBorder: boolean;
	suffix?: string;
}>;
const DISCOUNT_TYPES: DiscountTypes = [
	{
		key: 'totalAmount',
		label: '구매금액',
		hasIcon: false,
		hasBorder: false,
	},
	{
		key: 'coupon',
		label: '쿠폰사용',
		hasIcon: true,
		hasBorder: false,
		type: 'DECREMENT',
	},
	{
		key: 'point',
		label: '포인트사용',
		hasIcon: true,
		hasBorder: false,
		suffix: 'P',
		type: 'DECREMENT',
	},
	{
		key: 'actualAmount',
		label: '최종 결제금액',
		hasIcon: false,
		hasBorder: true,
	},
];

const DiscountInformation = ({ paymentData }: { paymentData: Payment }) => {
	if (
		paymentData.status !== 'COMPLETED' ||
		(typeof paymentData.coupon === 'number' &&
			paymentData.coupon <= 0 &&
			typeof paymentData.point === 'number' &&
			paymentData.point <= 0)
	)
		return <React.Fragment />;

	return (
		<Wrapper $gap="0.4rem">
			{DISCOUNT_TYPES.map((type) => (
				<HStack
					key={'discount__type--' + type.key}
					$alignItems="center"
					$justify="between"
					{...(type.hasBorder && {
						...{ style: { borderTop: `1px solid ${Theme.colors.gray.gray_400}`, padding: '0.8rem 0 0.4rem' } },
					})}
				>
					<HStack $gap="0.2rem" $alignItems="center">
						{type.hasIcon && <Icon id={IconID.DOWNLOAD_RIGHT} defaultColor="gray_700" width="1.2rem" height="1.2rem" />}
						<Label $fontStyle="caption_1" $color="gray_700" $fontWeight="medium">
							{type.label}
						</Label>
					</HStack>

					<Label $fontStyle="caption_1" $color="gray_900" $fontWeight="medium">
						{type.type === 'DECREMENT' && Number(paymentData[type.key]) > 0 && '-'}
						{paymentData[type.key] && Number(paymentData[type.key]).toLocaleString()}
						{type.suffix ?? '원'}
					</Label>
				</HStack>
			))}
		</Wrapper>
	);
};

const Wrapper = styled(VStack)`
	width: 100%;
	padding: 0.8rem;
	border-radius: 6px;
	background-color: ${({ theme }) => theme.colors.gray.gray_100};
`;

export default DiscountInformation;
