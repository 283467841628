import React, { useMemo, type HtmlHTMLAttributes } from 'react';

import Theme from '../../../../lib/styledComponents/Theme';
import { isValidCheck } from '../../../../utils/isValidCheck';
import { Button } from '../../../Buttons';
import { Badge, Icon } from '../../../Display';
import { type FilterProps } from '../../index';

interface FilterButtonProps<T extends object> extends FilterProps<T>, HtmlHTMLAttributes<HTMLButtonElement> {
	isOpen: boolean;
}

type ButtonState = 'APPLY' | 'ACTIVE' | 'NORMAL';

export const FilterButton = <T extends object>({
	isOpen,
	items,
	resetCurPage,
	queries,
	...rest
}: FilterButtonProps<T>) => {
	const { values } = queries;
	const buttonState = useMemo(() => {
		const itemKeys = items.map((item) => item.key);
		let count = 0;
		itemKeys.forEach((key) => {
			if (isValidCheck(String(key), values[key])) {
				count += 1;
			}
		});
		// 카운트가 0 이상이면 = APPLY
		// 카운트가 0이고 isOpen true = ACTIVE
		// 둘다 아니면 = NORMAL
		const result: { state: ButtonState; count: number } = {
			state: 'NORMAL',
			count,
		};

		if (count > 0) {
			result.state = 'APPLY';
		} else if (isOpen) {
			result.state = 'ACTIVE';
		}

		return result;
	}, [values, items, isOpen]);

	return (
		<Button
			{...rest}
			buttonType="LINE"
			size={{ $paddingSize: 'M' }}
			color={buttonState.state === 'ACTIVE' || buttonState.state === 'APPLY' ? 'PRIMARY' : 'SECONDARY'}
			style={{
				gap: '.4rem',
				color: buttonState.state !== 'APPLY' ? Theme.colors.black : Theme.colors.primary.primary_600,
			}}
		>
			<Icon
				id={'filter'}
				width="2rem"
				height="2rem"
				defaultColor="black"
				activeColor="primary_600"
				isActive={buttonState.state === 'APPLY'}
			/>
			필터
			{buttonState.count > 0 && <Badge count={buttonState.count} />}
		</Button>
	);
};
