import React from 'react';

import { ellipsis1 } from '../../../assets/styles/ellipsis';
import { Tags } from '../../../components/Display/Tags';
import { type Columns } from '../../../components/Table/ListTable/types';
import { type Payment } from '../../../services/payment/types';
import { type PromotionCalculate } from '../../../services/promotion/types';
import { PAY_TYPE_VALUES } from '../../../utils/consts';

export const PROMOTION_CALCULATES_COLUMNS: Columns<PromotionCalculate> = [
	{
		key: 'title',
		headerName: '이벤트명',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
	},
	{
		width: {
			max: '22.4rem',
		},
		key: 'startDate',
		headerName: '기간',
		renderComponent: (_, item) => item.startDate + ' ~ ' + (item.isUntilSoldOut ? '소진 시 까지' : item.endDate),
	},
	{
		width: {
			max: '12.4rem',
		},
		key: 'calculatePrice',
		headerName: '할인액',
		renderComponent: (value) => value.toLocaleString() + '원',
		style: { justifyContent: 'flex-end' },
	},
	{
		width: {
			max: '12rem',
		},
		key: 'salesCount',
		headerName: '판매 건 수',
		renderComponent: (value) => value.toLocaleString() + '건',
		style: { justifyContent: 'flex-end' },
	},
	{
		width: {
			max: '16rem',
		},
		key: 'salesAmount',
		headerName: '매출액',
		renderComponent: (value) => value.toLocaleString() + '원',
		style: { justifyContent: 'flex-end' },
	},
] as const;

export const PROMOTION_PAYMENT_COLUMNS: Columns<Payment> = [
	{
		width: {
			max: '17.7rem',
		},
		key: 'approvalAt',
		headerName: '승인 일시',
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		width: {
			max: '11.2rem',
		},
		key: 'name',
		headerName: '회원명',
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		key: 'promotionTitle',
		headerName: '이벤트명',
		renderComponent: (value) => <p style={ellipsis1}>{value}</p>,
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
	{
		width: {
			max: '17.7rem',
		},
		key: 'productNames',
		headerName: '상품명',
		style: { justifyContent: 'space-between' },
		isClick: true,
		renderComponent: (value) => <Tags data={value} count={value.length} maxLength={1} $wrap="nowrap" />,
	},
	{
		width: {
			max: '14.8rem',
		},
		key: 'actualAmount',
		headerName: '결제 금액',
		renderComponent: (value) => value.toLocaleString() + '원',
		style: { justifyContent: 'flex-end' },
		isClick: true,
	},
	{
		width: {
			max: '14.4rem',
		},
		key: 'payType',
		headerName: '결제 유형',
		renderComponent: (value) => PAY_TYPE_VALUES[value],
		style: { justifyContent: 'space-between' },
		isClick: true,
	},
] as const;
