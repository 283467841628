import { useCallback } from 'react';

import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { api } from '..';

export const usePostAssets = () => {
	return useMutation({
		mutationFn: async ({ files, thumbnail }: { files: File[]; thumbnail?: boolean }) => {
			return await Promise.all(files.map(async (file) => await api.asset.postAsset({ file, thumbnail })));
		},
		onError: () => {
			toast.error(`파일 업로드에 실패했습니다.`);
		},
	});
};

interface UseMutateWithAssets<T extends object> {
	assetKey: keyof T;
	params: T;
	callback: UseMutationResult<unknown, Error, T>['mutate'];
}

interface UseMutateWithAssetsReturn {
	handleMutateWithAssets: (files: File[], thumbnail?: boolean) => void;
	isAssetPending: boolean;
}

export function useMutateWithAssets<T extends object>({
	assetKey,
	params,
	callback,
}: UseMutateWithAssets<T>): UseMutateWithAssetsReturn {
	const { mutate, isPending: isAssetPending } = usePostAssets();

	const handleMutateWithAssets = useCallback(
		(files: File[], thumbnail?: boolean) => {
			mutate(
				{ files, thumbnail },
				{
					onSuccess: (response) => {
						const updatedParams = {
							...params,
							[assetKey]: response.map(({ id }) => id),
						};
						callback(updatedParams);
					},
					onError: () => {
						callback(params);
					},
				},
			);
		},
		[mutate, assetKey, callback, params],
	);

	return { handleMutateWithAssets, isAssetPending };
}
