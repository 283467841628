import React, { useLayoutEffect, useRef, useState } from 'react';

import { format } from 'date-fns/format';
import { ko } from 'date-fns/locale/ko';
import styled, { css } from 'styled-components';

import { MessageView } from './MessageView';
import { VStack } from '../../../../components/Common';
import { useSBStateContext } from '../../../../context/sendbirdSdk';
import { useChannelContext } from '../../context/ChannelProvider';
import { DateSeparator } from '../DateSeparator';

import type { CoreMessageType } from '../../utils';

export interface MessageProps {
	message: CoreMessageType;
	hasSeparator?: boolean;
	chainTop?: boolean;
	chainBottom?: boolean;
	handleScroll?: (isBottomMessageAffected?: boolean) => void;
}

// message ref here
export const Message = (props: MessageProps) => {
	const { message, hasSeparator, chainTop, chainBottom } = props;
	const { stores } = useSBStateContext();
	const { userId } = stores.userStore.user;
	const { currentChannel, setAnimatedMessageId, animatedMessageId, onMessageAnimated } = useChannelContext();

	const [isAnimated, setIsAnimated] = useState(false);
	const messageScrollRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const timeouts: NodeJS.Timeout[] = [];

		if (animatedMessageId === message.messageId && messageScrollRef?.current) {
			timeouts.push(
				setTimeout(() => {
					setIsAnimated(true);
				}, 500),
			);

			timeouts.push(
				setTimeout(() => {
					setAnimatedMessageId?.(null);
					onMessageAnimated?.();
				}, 1600),
			);
		} else {
			setIsAnimated(false);
		}

		return () => {
			timeouts.forEach((it) => {
				clearTimeout(it);
			});
		};
	}, [animatedMessageId, message.messageId, messageScrollRef.current]);

	return (
		<Wrapper ref={messageScrollRef} data-sb-created-at={message.createdAt} $isAnimated={isAnimated}>
			{/* date-separator */}
			{hasSeparator && <DateSeparator>{format(message.createdAt, 'yyyy. MM. d (ccc)', { locale: ko })}</DateSeparator>}
			<MessageView
				userId={userId}
				channel={currentChannel}
				message={message}
				chainTop={chainTop}
				chainBottom={chainBottom}
			/>
		</Wrapper>
	);
};

const Wrapper = styled(VStack)<{ $isAnimated: boolean }>`
	${({ $isAnimated }) =>
		$isAnimated &&
		css`
			.message-content {
				animation: bounce 1s ease;
				@keyframes bounce {
					50% {
						transform: translateY(-10px);
					}
					60% {
						transform: translateY(0);
					}
					90% {
						transform: translateY(-10px);
					}
					100% {
						transform: translateY(0);
					}
				}
			}
		`};
`;
