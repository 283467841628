import React, { useMemo } from 'react';

import { subDays } from 'date-fns/subDays';
import { subMonths } from 'date-fns/subMonths';

import { InsideTitle, Loading } from '../../../components/Common';
import { IconID, ComparisonCard, type SingleCardListProps } from '../../../components/Display';
import { type PeriodUnit, useGetPeriodDetails, useGetStatsAges } from '../../../services/statistics/queries';
import { type StatsDetail } from '../../../services/statistics/types';
import { ItemsWrapper } from '../components/styles';
import { formatPeriod, getDateEndOfUnit, getDateStartOfUnit } from '../utils';

const COMPARE_UNIT_WORD: Record<PeriodUnit, string> = {
	daily: '1일 전보다',
	weekly: '1주 전보다',
	monthly: '1개월 전보다',
} as const;

const UNIT_TITLE: Record<PeriodUnit, string> = {
	daily: '일일',
	weekly: '주간',
	monthly: '월간',
};

type CardListDataType = { total: number } & Pick<StatsDetail, 'pxCount' | 'saleCount' | 'sales' | 'grossProfit'>;
export const CARD_LIST_ITEMS: SingleCardListProps<CardListDataType>['items'] = [
	{
		title: `고객수`,
		type: 'count',
		iconProps: { id: IconID.STATS_USER },
		by: 'total',
	},
	{
		title: '조제 건수',
		type: 'count',
		iconProps: { id: IconID.STATS_PILL },
		by: 'pxCount',
	},
	{
		title: '판매 건수',
		type: 'count',
		iconProps: { id: IconID.STATS_CARD, width: '2.2rem' },
		by: 'saleCount',
	},
	{
		title: '판매 매출',
		type: 'percent',
		iconProps: { id: IconID.STATS_SALES, width: '2.8rem', height: '2.8rem' },
		by: 'sales',
		unit: '원',
	},
	{
		title: '매출 총이익',
		type: 'percent',
		iconProps: { id: IconID.STATS_PROFIT, width: '4.4rem', height: '4.4rem' },
		by: 'grossProfit',
		unit: '원',
	},
];

interface CardListProps {
	storeId: string;
	unit: PeriodUnit;
	date: Date;
}

export function CardList({ storeId, unit, date }: CardListProps) {
	const period = useMemo(() => {
		let prev: Date = date;
		switch (unit) {
			case 'daily':
				prev = subDays(date, 1);
				break;
			case 'weekly':
				prev = subDays(date, 7);
				break;
			case 'monthly':
				prev = subMonths(date, 1);
				break;
		}
		return [date, prev].map((value) => {
			const [start, end] = [getDateStartOfUnit(value, unit), getDateEndOfUnit(value, unit)];
			return formatPeriod([start, end]);
		});
	}, [date, unit]);

	const {
		data: ages,
		isLoading: agesIsLoading,
		isSuccess: agesIsSuccess,
	} = useGetStatsAges<{ total: number }>({
		storeId,
		period,
		type: 'total',
		select: ({ total }) => ({ total }),
	});

	const {
		data: details,
		isLoading: detailsIsLoading,
		isSuccess: detailsIsSuccess,
	} = useGetPeriodDetails<typeof unit>({
		storeId,
		period,
		unit,
	});

	const [cur, prev] = useMemo(() => {
		if (agesIsSuccess && detailsIsSuccess) {
			const [curAges, prevAges] = ages;
			const [curDetails, prevDetails] = details.map((detail) => detail[0]);
			return [
				{ ...curAges, ...curDetails },
				{ ...prevAges, ...prevDetails },
			];
		}
		return [{}, {}] as [CardListDataType, CardListDataType];
	}, [agesIsSuccess, detailsIsSuccess, ages, details]);

	const items: SingleCardListProps<CardListDataType>['items'] = useMemo(
		() =>
			CARD_LIST_ITEMS.map(({ title, ...rest }, idx) => ({
				title: idx === 0 ? `${UNIT_TITLE[unit]} ` + title : title,
				standard: COMPARE_UNIT_WORD[unit],
				...rest,
			})),

		[unit],
	);

	return (
		<ItemsWrapper $gap={'1.2rem'} $direction={'column'} $alignItems={'start'} $alignSelf={'stretch'}>
			<InsideTitle title={`${UNIT_TITLE[unit]} 총계`} />
			<ItemsWrapper $gap={'2rem'} $alignItems={'start'} $alignSelf={'stretch'}>
				{(detailsIsLoading || agesIsLoading) && <Loading />}
				<ComparisonCard.CardList<CardListDataType> data={cur} prevData={prev} items={items} />
			</ItemsWrapper>
		</ItemsWrapper>
	);
}
