import { type TagsStyle } from './types';

export const tagsStyle: TagsStyle = {
	FILL: {
		PRIMARY: {
			color: 'primary_800',
			backgroundColor: 'primary_200',
		},
		GRAY: {
			color: 'gray_800',
			backgroundColor: 'gray_300',
		},
		YELLOW: {
			color: 'yellow_600',
			backgroundColor: 'yellow_200',
		},
		BLUE: {
			color: 'blue_600',
			backgroundColor: 'blue_200',
		},
		GREEN: {
			color: 'green_700',
			backgroundColor: 'green_200',
		},
	},
	LINE: {
		PRIMARY: {
			color: 'primary_800',
			backgroundColor: 'white',
			border: {
				width: '1px',
				style: 'solid',
				color: 'primary_400',
			},
		},
		GRAY: {
			color: 'black',
			backgroundColor: 'white',
			border: {
				width: '1px',
				style: 'solid',
				color: 'gray_300',
			},
		},
		YELLOW: {
			color: 'yellow_700',
			backgroundColor: 'white',
			border: {
				width: '1px',
				style: 'solid',
				color: 'yellow_400',
			},
		},
		BLUE: {
			color: 'blue_700',
			backgroundColor: 'white',
			border: {
				width: '1px',
				style: 'solid',
				color: 'blue_400',
			},
		},
		GREEN: {
			color: 'green_700',
			backgroundColor: 'white',
			border: {
				width: '1px',
				style: 'solid',
				color: 'green_400',
			},
		},
	},
	size: {
		S: {
			fontKey: 'caption_1',
			fontWeight: 'medium',
			height: '2.4rem',
			padding: '0 0.6rem',
			borderRadius: '4px',
		},
		M: {
			fontKey: 'body_3',
			fontWeight: 'medium',
			height: '2.8rem',
			padding: '0 0.8rem',
			borderRadius: '4px',
		},
		L: {
			fontKey: 'body_1',
			fontWeight: 'medium',
			height: '3.2rem',
			padding: '0 1rem',
			borderRadius: '4px',
		},
	},
};
