import React, { useCallback, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import Theme from '../../../lib/styledComponents/Theme';
import { type Asset } from '../../../services/asset/types';
import { getMimeTypesAccepts, type AcceptableMimeTypes } from '../../../utils/files';
import { type CapacityUnitType, formatFileSize } from '../../../utils/format';
import { Button, IconButton } from '../../Buttons';
import { HStack } from '../../Common';
import { AssetsImage, Icon, IconID } from '../../Display';
import { FileUploader } from '../FileUploader';

interface FileProps {
	maxFileCount?: number;
	maxCapacity?: { unit: CapacityUnitType; size: number };
	format?: AcceptableMimeTypes[];
	values: File[];
	onChange: (files: File[]) => void;
	disabled?: boolean;
	tooltip?: React.ReactNode;
}

const FileComponent = ({
	maxFileCount,
	maxCapacity,
	format,
	onChange,
	values,
	disabled = false,
	tooltip,
}: FileProps) => {
	const [dragOver, setDragOver] = useState(false);

	const totalSize = useMemo(() => formatFileSize(values.reduce((acc, cur) => acc + cur.size, 0)), [values]);

	const convertFormat = useMemo(() => {
		const accept = getMimeTypesAccepts(format);

		return {
			types: accept.split(','),
			format: accept.split(',').map((el) => el.split('/')[1]),
		};
	}, [format]);

	const handleDragOver = useCallback((e: React.DragEvent<HTMLUListElement>) => {
		e.preventDefault();
		setDragOver(true);
	}, []);

	const handleDragLeave = useCallback((e: React.DragEvent<HTMLUListElement>) => {
		e.preventDefault();
		setDragOver(false);
	}, []);

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLUListElement>, onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
			e.preventDefault();
			setDragOver(false);
			if (!e.dataTransfer) return;
			const files = e.dataTransfer.files;

			onFileChange({ target: { files } } as React.ChangeEvent<HTMLInputElement>);
		},
		[],
	);
	const convertFormatAsset = useCallback(
		(id: number, file: File, imageUrl: string): Asset => ({
			id,
			name: file.name,
			size: file.size,
			contentType: file.type,
			url: imageUrl,
			thumbnailUrl: imageUrl,
			expiredAt: '',
		}),
		[],
	);

	return (
		<Wrapper $dragOver={dragOver}>
			<FileUploader
				onChange={onChange}
				values={values}
				format={format}
				maxCount={maxFileCount}
				maxCapacity={maxCapacity !== undefined ? { single: { ...maxCapacity } } : undefined}
			>
				{({ onClickTrigger, onDelete, convertedUrls, onFileChange }) => (
					<>
						<div className="head">
							{/* 인풋 */}
							{!disabled && (
								<HStack $alignItems="center" $gap={'0.8rem'}>
									<Button
										buttonType="LINE"
										color="SECONDARY"
										size={{ $fontSize: 'XS', $paddingSize: 'S', $heightSize: 'S' }}
										style={{ color: Theme.colors.black }}
										onClick={onClickTrigger}
									>
										파일첨부
									</Button>
									{tooltip && <p className="tooltip">{tooltip}</p>}
								</HStack>
							)}
							{/* 파일 갯수, 총 용량 */}
							<div className="capacity">
								{maxFileCount && <span>{`${values.length}/${maxFileCount}개`}</span>}
								{maxCapacity && <span>{` (${totalSize})`}</span>}
							</div>
						</div>

						{/* 결과창 */}
						<ul
							className="result"
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={(e) => {
								handleDrop(e, onFileChange);
							}}
						>
							{values.length <= 0 ? (
								<Empty>
									<div className="icon__wrapper">
										<Icon id={IconID.LINE_BOLD_FILE} defaultColor="primary_600" width="2.4rem" height="2.4rem" />
									</div>
									<div className="content">
										<p>파일을 마우스로 끌어놓으세요.</p>
										{format && convertFormat.format.length > 0 && (
											<p className="format">파일 형식: 10MB 이하의 {convertFormat.format.join(', ')}</p>
										)}
									</div>
								</Empty>
							) : (
								values.map((file, idx) => (
									<Item key={'file-' + idx}>
										<AssetsImage assets={convertFormatAsset(idx, file, convertedUrls[idx])} $width="3.2rem" />

										<div className="content">
											<p className="content--name">{file.name}</p>
											<p className="content--size">용량 {formatFileSize(file.size)}</p>
										</div>
										{!disabled && (
											<IconButton
												width="2rem"
												height="2rem"
												onClick={() => {
													onDelete(idx);
												}}
											>
												<Icon id={IconID.BTN_CLOSE} width="2rem" height="2rem" defaultColor="gray_600" />
											</IconButton>
										)}
									</Item>
								))
							)}
						</ul>
					</>
				)}
			</FileUploader>
		</Wrapper>
	);
};

const Item = styled.li`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		&--name {
			${({ theme }) => theme.font.label.label_2};
			font-weight: 500;
		}
		&--size {
			${({ theme }) => theme.font.caption.caption_1};
			color: ${({ theme }) => theme.colors.gray.gray_600};
		}
	}
	> button {
	}
`;

const Empty = styled.li`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
	.icon__wrapper {
		display: inline-flex;
		width: 4rem;
		height: 4rem;
		border-radius: 999px;
		background-color: ${({ theme }) => theme.colors.primary.primary_100};
		align-items: center;
		justify-content: center;
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		p {
			${({ theme }) => theme.font.label.label_2};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.gray.gray_800};
			&.format {
				${({ theme }) => theme.font.caption.caption_1};
				color: ${({ theme }) => theme.colors.gray.gray_600};
			}
		}
	}
`;

const Wrapper = styled.div<{ $dragOver: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	.head {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		.capacity {
			margin-left: auto;
			${({ theme }) => theme.font.caption.caption_1};
			color: ${({ theme }) => theme.colors.gray.gray_600};
		}
		.tooltip {
			${({ theme }) => theme.font.caption.caption_1};
			color: ${({ theme }) => theme.colors.gray.gray_800};
		}
	}
	.result {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
		padding: 1.2rem;
		min-height: 9.6rem;
		border: 1px dashed ${({ theme }) => theme.colors.gray.gray_300};
		border-radius: 4px;
		${({ $dragOver }) =>
			$dragOver &&
			css`
				opacity: 0.8;
			`}
	}
`;

export const FileInput = React.memo(FileComponent);
