import AS from './AS';
import CRM from './CRM';
import Dashboard from './Dashboard';
import Drug from './Drug';
import Login from './Login';
import Manual from './Manual';
import Member from './Member';
import MyPage from './MyPage';
import NotFound from './NotFound';
import Notice from './Notice';
import NoticeDetail from './Notice/Detail';
import Operation from './Operation';
import Payment from './Payment';
import { Product } from './Product';
import Promotion from './Promotion';
import Stats from './Stats';

export default {
	Login,
	Dashboard,
	MyPage,
	NotFound,
	CRM,
	Operation,
	Member,
	Payment,
	Drug,
	AS,
	Manual,
	Stats,
	Promotion,
	Product,
	Notice: {
		index: Notice,
		Detail: NoticeDetail,
	},
};
