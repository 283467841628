import { disconnectSdk } from './disconnectSdk';
import { setupConnection } from './setupConnection';
import { type ConnectTypes } from './types';

export async function connect({
	userId,
	accessToken,
	nickname,
	sdk,
	sdkDispatcher,
	userDispatcher,
}: ConnectTypes): Promise<void> {
	try {
		await disconnectSdk({
			sdkDispatcher,
			userDispatcher,
			sdk,
		});
		await setupConnection({
			userId,
			accessToken,
			nickname,
			sdkDispatcher,
			userDispatcher,
		});
	} catch (e) {
		// Error - Store is not initialized.
		// 해당 에러 StrictMode 주석 시 사라짐
		console.error(e);
	}
}
