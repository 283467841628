import React from 'react';

import { ChartText } from '../styles';

import type { ColorKeys } from '../../../utils/changeColorKeyToType';

interface CustomXAxisProps {
	props: any;
	fontColor?: ColorKeys;
}

export function CustomXAxis({ props, fontColor }: CustomXAxisProps) {
	if (validateXAxisProps(props)) {
		const { x, y, payload, tickFormatter } = props;

		const formattedValue = tickFormatter ? tickFormatter(payload?.value, payload?.index) : `${payload?.value}`;
		const lines = formattedValue.split('\n');

		return (
			<ChartText x={x} y={y} $color={fontColor} textAnchor="middle">
				{lines.map((line, index) => (
					<tspan key={index} x={x} dx={index === 0 ? 0 : -6} dy={index === 0 ? 0 : '1.4em'}>
						{line}
					</tspan>
				))}
			</ChartText>
		);
	}
	return <></>;
}

interface ValidatedXAxisProps {
	x: number;
	y: number;
	tickFormatter?: (value: any, index: number) => string;
	payload: {
		value: string | number;
		index: number;
	};
}

function validateXAxisProps(props: any): props is ValidatedXAxisProps {
	return (
		props &&
		'x' in props &&
		'y' in props &&
		'tickFormatter' in props &&
		'payload' in props &&
		'value' in props.payload &&
		'index' in props.payload
	);
}
