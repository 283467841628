import { type Columns } from '../../../../components/Table/ListTable/types';
import { type BasicMemberInfo } from '../../../../services/member/types';
import { GENDER_VALUES, IS_FAVORITE_VALUES } from '../../../../utils/consts';
import { formatPhoneNumber } from '../../../../utils/format';

export const groupMemberTableColumns: Columns<BasicMemberInfo> = [
	{ key: 'name', headerName: '회원명', isClick: true },
	{ key: 'birth', headerName: '생년월일', isClick: true },
	{
		key: 'gender',
		headerName: '성별',
		isClick: true,
		renderComponent: (value) => GENDER_VALUES[value],
	},
	{
		key: 'phone',
		headerName: '전화번호',
		renderComponent: (value) => formatPhoneNumber(value),
	},
	{
		key: 'isFavorite',
		headerName: '단골 여부',
		renderComponent: (value) => IS_FAVORITE_VALUES[value],
	},
];
