import { type AutoMessageItem } from './types';
import { type TriggerState, type TimeUnit } from '../../../services/notification-configs/types';
import { type DefaultOption } from '../../../utils/consts';

export const AUTO_MESSAGE_ITEMS: AutoMessageItem[] = [
	{
		title: '처방전 오더 다음날 알림',
		key: 'etcOrder',
		maxLength: 100,
	},
	{ title: '약국등록 환영 메시지', key: 'etcOrder2', maxLength: 100 },
	{ title: '영양제 상담 메시지', key: 'etcOrder3', maxLength: 100 },
	{ title: '혼잡 시 메시지', key: 'etcOrder4', maxLength: 100 },
	{ title: '영업종료 시 메시지', key: 'etcOrder5', maxLength: 100 },
	{ title: '상품 수령 후 메시지', key: 'etcOrder6', maxLength: 100 },
] as const;

export const TEXTAREA_PLACEHOLDER = '메시지를 입력해주세요.';

export const STATUS_MESSAGE = {
	SUCCESS: '메시지 수정에 성공하였습니다.',
	ERROR: '메시지 수정에 실패하였습니다.\n',
	ACTIVE: {
		SUCCESS: '메시지 상태가 변경되었습니다.',
		ERROR: '메시지 상태를 변경하지 못했습니다. \n',
	},
} as const;

export const MAX_LENGTH = 300;

export const TIME_UNIT: Array<DefaultOption<TimeUnit>> = [
	{ key: 'DAY', label: '일' },
	{ key: 'HOUR', label: '시' },
	{ key: 'MINUTE', label: '분' },
];

export const TRIGGER_STATE: Array<DefaultOption<Exclude<TriggerState, 'IMMEDIATE'>>> = [
	{ key: 'PREVIOUS', label: '전' },
	{ key: 'NEXT', label: '후' },
];
