import React from 'react';

import styled from 'styled-components';

import { Label } from '../../Display';
import { ChartLegendIcon } from '../styles';

import type { Props } from 'recharts/types/component/DefaultLegendContent';

export function PieLegend(props: Props) {
	const { payload } = props;
	if (!payload) {
		return <></>;
	}

	return (
		<Wrapper>
			{payload.map(({ color, value }, idx) => (
				<Item key={`pie-legend-${idx}`}>
					<ChartLegendIcon $type={'rect'} $color={color} />
					<Label $fontStyle={'label_2'}>{value}</Label>
				</Item>
			))}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: inline-flex;
	padding: 2rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.8rem;

	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray_400};
`;

const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
`;
