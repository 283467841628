import { useEffect, useRef } from 'react';

import { CollectionEventSource } from '@sendbird/chat';
import {
	type GroupChannel,
	type MessageCollection,
	MessageCollectionInitPolicy,
	type MessageCollectionParams,
	MessageFilter,
	type MessageFilterParams,
	type SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import {
	type FileMessage,
	type FileMessageUpdateParams,
	type BaseMessage,
	type MessageRequestHandler,
	type UserMessage,
	type UserMessageCreateParams,
	type UserMessageUpdateParams,
	type FileMessageCreateParams,
} from '@sendbird/chat/message';
import { toast } from 'react-toastify';

import { useForceUpdate } from './useForceUpdate';
import { useAsyncLayoutEffect } from '../../../hooks/useAsyncEffect';
import { usePreservedCallback } from '../../../hooks/usePreservedCallback';
import { isNotEmptyArray } from '../../../utils/validators';
import { useChannelHandler } from '../../ChannelList/hooks/useChannelHandler';
import { basicParamsConstants } from '../../ChannelList/hooks/useChannelList';
import { useChannelMessagesReducer } from '../reducer';
import { isDifferentChannel, isMyMessage, type SendbirdMessage } from '../utils';

type CollectionCreatorBasicParams = Omit<MessageCollectionParams, 'filter'> &
	Pick<MessageFilterParams, 'replyType' | 'messageTypeFilter'>;

interface UseGroupChannelMessagesOptions {
	startingPoint?: number;

	markAsRead?: (channels: GroupChannel[]) => void;
	shouldCountNewMessages?: () => boolean;
	collectionCreator?: (basicParams: CollectionCreatorBasicParams) => MessageCollection;
	sortComparator?: (a: SendbirdMessage, b: SendbirdMessage) => number;

	onMessagesReceived?: (messages: SendbirdMessage[]) => void;
	onMessagesSentByMe?: (messages: SendbirdMessage[]) => void;
	onMessagesUpdated?: (messages: SendbirdMessage[]) => void;
	onChannelUpdated?: (channel: GroupChannel) => void;
	onCurrentUserBanned?: () => void;
}

const createMessageCollection = (channel: GroupChannel, options: UseGroupChannelMessagesOptions) => {
	const basicParams: CollectionCreatorBasicParams = {
		prevResultLimit: basicParamsConstants.collection.message.defaultLimit.prev,
		nextResultLimit: basicParamsConstants.collection.message.defaultLimit.next,
		startingPoint: options?.startingPoint,
		// messageTypeFilter: basicParamsConstants.collection.message.defaultMessageTypeFilter,
	};

	const passedCollection = options?.collectionCreator?.(basicParams);
	if (passedCollection) return passedCollection;

	return channel.createMessageCollection({
		...basicParams,
		filter: new MessageFilter(basicParams),
	});
};

/**
 * group channel messages hook
 * - Receive new messages from other users & should count new messages -> append to state(newMessages)
 * - onTopReached -> prev() -> fetch prev messages and append to state(messages)
 * - onBottomReached -> next() -> fetch next messages and append to state(messages)
 * @see {@link https://sendbird.com/docs/chat/sdk/v4/javascript/local-caching/using-message-collection/message-collection}
 * */
export const useChannelMessages = (
	sdk: SendbirdGroupChat,
	channel: GroupChannel,
	options: UseGroupChannelMessagesOptions = {},
) => {
	const internalOptions = useRef(options); // to keep reference of options in event handler
	internalOptions.current = options;

	const channelRef = useRef(channel); // to keep reference of channel in event handler
	channelRef.current = channel;

	const initialStartingPoint = internalOptions.current.startingPoint ?? Number.MAX_SAFE_INTEGER;
	const isFetching = useRef({ prev: false, next: false });
	const forceUpdate = useForceUpdate();

	const collectionRef = useRef<{
		initialized: boolean;
		apiInitialized: boolean;
		instance: MessageCollection | null;
	}>({
		initialized: false,
		apiInitialized: false,
		instance: null,
	});

	const {
		initialized,
		loading,
		refreshing,
		messages,
		newMessages,
		updateMessages,
		updateNewMessages,
		updateInitialized,
		updateLoading,
		updateRefreshing,
		deleteMessages,
	} = useChannelMessagesReducer();

	const markAsReadBySource = usePreservedCallback((source?: CollectionEventSource) => {
		if (!channelRef.current?.url) {
			return;
		}
		try {
			switch (source) {
				case CollectionEventSource.EVENT_MESSAGE_RECEIVED:
				case CollectionEventSource.EVENT_MESSAGE_SENT_SUCCESS:
				case CollectionEventSource.SYNC_MESSAGE_FILL:
				case undefined:
					internalOptions.current.markAsRead?.([channelRef.current]);
					break;
			}
		} catch (e) {}
	});

	const updateNewMessagesReceived = usePreservedCallback((source: CollectionEventSource, messages: BaseMessage[]) => {
		const incomingMessages = messages.filter((msg) => !isMyMessage(msg, sdk.currentUser?.userId));
		if (incomingMessages.length > 0) {
			switch (source) {
				case CollectionEventSource.EVENT_MESSAGE_RECEIVED:
				case CollectionEventSource.SYNC_MESSAGE_FILL: {
					if (internalOptions.current.shouldCountNewMessages?.()) {
						updateNewMessages(incomingMessages, false, sdk.currentUser?.userId);
					}
					internalOptions.current.onMessagesReceived?.(incomingMessages);
					break;
				}
			}
		}
	});

	const init = usePreservedCallback(async (startingPoint: number) => {
		await new Promise<void>((resolve) => {
			if (!channelRef.current?.url) {
				return;
			}

			if (collectionRef.current.instance) {
				collectionRef.current.instance.dispose();
			}

			markAsReadBySource();
			updateNewMessages([], true, sdk.currentUser?.userId);

			const updateUnsentMessages = () => {
				const { pendingMessages, failedMessages } = collectionRef.current.instance ?? {};
				if (isNotEmptyArray(pendingMessages)) updateMessages(pendingMessages, false, sdk.currentUser?.userId);
				if (isNotEmptyArray(failedMessages)) updateMessages(failedMessages, false, sdk.currentUser?.userId);
			};

			const collectionInstance = createMessageCollection(channelRef.current, {
				...internalOptions.current,
				startingPoint,
			});
			collectionRef.current = {
				apiInitialized: false,
				initialized: false,
				instance: collectionInstance,
			};

			collectionInstance.setMessageCollectionHandler({
				onMessagesAdded: (ctx, __, messages) => {
					markAsReadBySource(ctx.source);
					updateNewMessagesReceived(ctx.source, messages);

					updateMessages(messages, false, sdk.currentUser?.userId);
					if (isMyMessage(messages[0], sdk.currentUser?.userId)) {
						internalOptions.current.onMessagesSentByMe?.(messages);
					}
				},
				onMessagesUpdated: (ctx, __, messages) => {
					markAsReadBySource(ctx.source);
					updateNewMessagesReceived(ctx.source, messages);

					updateMessages(messages, false, sdk.currentUser?.userId);

					if (ctx.source === CollectionEventSource.EVENT_MESSAGE_UPDATED) {
						internalOptions.current.onMessagesUpdated?.(messages);
					}
				},
				onChannelUpdated: (_, channel) => {
					forceUpdate();
					internalOptions.current.onChannelUpdated?.(channel);
				},
				onHugeGapDetected: () => {
					init(Number.MAX_SAFE_INTEGER);
				},
			});

			collectionInstance
				.initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
				.onCacheResult((err, messages) => {
					if (err) {
						toast.error(err.message);
					} else if (messages) {
						updateMessages(messages, true, sdk.currentUser?.userId);
						updateUnsentMessages();
						if (messages.length > 0) {
							collectionRef.current.initialized = true;
							resolve();
						}
					}
				})
				.onApiResult((err, messages) => {
					if (err) {
						toast.error(err.message);
					} else if (messages) {
						updateMessages(messages, true, sdk.currentUser?.userId);
						if (!internalOptions.current.startingPoint) {
							internalOptions.current.onMessagesReceived?.(messages);
						}
						updateUnsentMessages();
					}

					collectionRef.current.initialized = true;
					collectionRef.current.apiInitialized = true;
					resolve();
				});
		});
	});

	useChannelHandler(sdk, {
		onChannelFrozen(channel) {
			if (channel.isGroupChannel() && !isDifferentChannel(channel, channelRef.current)) {
				forceUpdate();
			}
		},
		onChannelUnfrozen(channel) {
			if (channel.isGroupChannel() && !isDifferentChannel(channel, channelRef.current)) {
				forceUpdate();
			}
		},
		// 현재 약사가 mute, unmute 되는 일은 없음.
		onUserMuted(channel) {
			if (channel.isGroupChannel() && !isDifferentChannel(channel, channelRef.current)) {
				forceUpdate();
			}
		},
		onUserUnmuted(channel) {
			if (channel.isGroupChannel() && !isDifferentChannel(channel, channelRef.current)) {
				forceUpdate();
			}
		},
	});

	useAsyncLayoutEffect(async () => {
		updateInitialized(false);
		updateLoading(true);
		if (sdk.currentUser && channelRef.current) {
			await init(initialStartingPoint);
			updateLoading(false);
			updateInitialized(true);
		}
	}, [sdk, sdk.currentUser?.userId, channelRef.current?.url]);

	useEffect(() => {
		return () => {
			if (collectionRef.current.instance) {
				collectionRef.current.instance.dispose();
			}
		};
	}, []);

	const refresh = usePreservedCallback(async () => {
		if (sdk.currentUser && channelRef.current) {
			updateRefreshing(true);
			await init(Number.MAX_SAFE_INTEGER);
			updateRefreshing(false);
		}
	});

	const loadPrevious = usePreservedCallback(async () => {
		const collection = collectionRef.current.instance;
		if (collection?.hasPrevious && !isFetching.current.prev) {
			try {
				isFetching.current.prev = true;
				const list = await collection.loadPrevious();
				updateMessages(list, false, sdk.currentUser?.userId);
			} catch (e) {
			} finally {
				isFetching.current.prev = false;
			}
		}
	});

	const hasPrevious = usePreservedCallback(() => {
		const { initialized, instance } = collectionRef.current;
		if (initialized && instance) {
			return instance.hasPrevious;
		} else {
			return false;
		}
	});

	const loadNext = usePreservedCallback(async () => {
		const collection = collectionRef.current.instance;
		if (collection?.hasNext && !isFetching.current.next) {
			try {
				isFetching.current.next = true;
				const list = await collection.loadNext();
				updateMessages(list, false, sdk.currentUser?.userId);
			} catch (e) {
			} finally {
				isFetching.current.next = false;
			}
		}
	});

	const hasNext = usePreservedCallback(() => {
		const { initialized, apiInitialized, instance } = collectionRef.current;
		if (apiInitialized && initialized && instance) {
			return instance.hasNext;
		} else {
			return false;
		}
	});

	const sendUserMessage = usePreservedCallback(
		async (params: UserMessageCreateParams, onPending: (message: UserMessage) => void): Promise<UserMessage> => {
			if (!channelRef.current?.url) {
				throw new Error('Channel is required');
			}

			return await new Promise((resolve, reject) => {
				channelRef.current
					.sendUserMessage(params)
					.onPending((pendingMessage) => {
						if (pendingMessage.channelUrl === channelRef.current.url) {
							updateMessages([pendingMessage], false, sdk.currentUser?.userId);
						}
						onPending?.(pendingMessage as UserMessage);
					})
					.onSucceeded((sentMessage) => {
						if (sentMessage.channelUrl === channelRef.current.url) {
							updateMessages([sentMessage], false, sdk.currentUser?.userId);
						}
						resolve(sentMessage as UserMessage);
					})
					.onFailed((err, failedMessage) => {
						if (failedMessage && failedMessage.channelUrl === channelRef.current.url) {
							updateMessages([failedMessage], false, sdk.currentUser?.userId);
						}
						reject(err);
					});
			});
		},
	);

	const updateUserMessage = usePreservedCallback(
		async (messageId: number, params: UserMessageUpdateParams): Promise<UserMessage> => {
			if (!channelRef.current?.url) {
				throw new Error('Channel is required');
			}

			const updatedMessage = await channelRef.current.updateUserMessage(messageId, params);
			if (updatedMessage.channelUrl === channelRef.current.url) {
				updateMessages([updatedMessage], false, sdk.currentUser?.userId);
			}
			return updatedMessage;
		},
	);

	const resendMessage = usePreservedCallback(
		async <T extends UserMessage | FileMessage>(failedMessage: T): Promise<T> => {
			if (!channelRef.current.url) {
				throw new Error('Channel is required');
			}

			return await new Promise<T>((resolve, reject) => {
				let handler: MessageRequestHandler<UserMessage> | MessageRequestHandler<FileMessage> | undefined;

				if (failedMessage.isUserMessage()) handler = channelRef.current.resendMessage(failedMessage);
				if (failedMessage.isFileMessage()) handler = channelRef.current.resendMessage(failedMessage);

				if (handler) {
					if ('onPending' in handler) {
						handler.onPending((message) => {
							if (message.channelUrl === channelRef.current.url) {
								updateMessages([message], false, sdk.currentUser?.userId);
							}
						});
					}

					if ('onSucceeded' in handler) {
						handler.onSucceeded((message) => {
							if (message.channelUrl === channelRef.current.url) {
								updateMessages([message], false, sdk.currentUser?.userId);
							}
							resolve(message as T);
						});
					}

					if ('onFailed' in handler) {
						handler.onFailed((err, message) => {
							if (message && message.channelUrl === channelRef.current.url) {
								updateMessages([message], false, sdk.currentUser?.userId);
							}
							reject(err);
						});
					}
				}
			});
		},
	);

	const deleteFailedMessage = usePreservedCallback(
		async <T extends UserMessage | FileMessage>(message: T): Promise<void> => {
			if (!channelRef.current.url) {
				throw new Error('Channel is required');
			}

			if (message.sendingStatus === 'failed') {
				try {
					await collectionRef.current.instance?.removeFailedMessage(message.reqId);
				} finally {
					deleteMessages([message.messageId], [message.reqId]);
				}
			}
		},
	);

	const sendFileMessage = usePreservedCallback(
		async (params: FileMessageCreateParams, onPending: (message: FileMessage) => void): Promise<FileMessage> => {
			if (!channelRef.current?.url) {
				throw new Error('Channel is required');
			}

			return await new Promise((resolve, reject) => {
				channelRef.current
					.sendFileMessage(params)
					.onPending((pendingMessage) => {
						if (pendingMessage.channelUrl === channelRef.current.url) {
							updateMessages([pendingMessage], false, sdk.currentUser?.userId);
						}
						onPending?.(pendingMessage as FileMessage);
					})
					.onSucceeded((sentMessage) => {
						if (sentMessage.channelUrl === channelRef.current.url) {
							updateMessages([sentMessage], false, sdk.currentUser?.userId);
						}
						resolve(sentMessage as FileMessage);
					})
					.onFailed((err, failedMessage) => {
						if (failedMessage && failedMessage.channelUrl === channelRef.current.url) {
							updateMessages([failedMessage], false, sdk.currentUser?.userId);
						}
						reject(err);
					});
			});
		},
	);

	const updateFileMessage = usePreservedCallback(
		async (messageId: number, params: FileMessageUpdateParams): Promise<FileMessage> => {
			if (!channelRef.current?.url) {
				throw new Error('Channel is required');
			}

			const updatedMessage = await channelRef.current.updateFileMessage(messageId, params);
			if (updatedMessage.channelUrl === channelRef.current.url) {
				updateMessages([updatedMessage], false, sdk.currentUser?.userId);
			}
			return updatedMessage;
		},
	);

	const resetNewMessages = usePreservedCallback(() => {
		updateNewMessages([], true, sdk.currentUser?.userId);
	});

	const resetWithStartingPoint = usePreservedCallback(async (startingPoint: number) => {
		if (sdk.currentUser && channelRef.current) {
			updateLoading(true);
			updateMessages([], true, sdk.currentUser?.userId);
			await init(startingPoint);
			updateLoading(false);
		}
	});

	return {
		/**
		 * Initialized state, only available on first render
		 * */
		initialized,

		/**
		 * Loading state, status is changes on first mount or when the resetWithStartingPoint is called.
		 * */
		loading,

		/**
		 * Refreshing state, status is changes when the refresh is called.
		 * */
		refreshing,

		/**
		 * Get messages, this state is for render
		 * For example, if a user receives a new messages while searching for an old message
		 * for this case, new messages will be included here.
		 * */
		messages,

		/**
		 * If the `shouldCountNewMessages()` is true, only then push in the newMessages state.
		 * (Return false for the `shouldCountNewMessages()` if the message scroll is the most recent; otherwise, return true.)
		 *
		 * A new message means a message that meets the below conditions
		 * - Not admin message
		 * - Not updated message
		 * - Not current user's message
		 * */
		newMessages,

		/**
		 * Reset new message list
		 * @return {void}
		 * */
		resetNewMessages,

		/**
		 * Reset message list and create a new collection for latest messages
		 * @return {Promise<void>}
		 * */
		refresh,

		/**
		 * Load previous messages to state
		 * @return {Promise<void>}
		 * */
		loadPrevious,

		/**
		 * Check if there are more previous messages to fetch
		 * @return {boolean}
		 * */
		hasPrevious,

		/**
		 * Load next messages to state
		 * @return {Promise<void>}
		 * */
		loadNext,

		/**
		 * Check if there are more next messages to fetch
		 * @return {boolean}
		 * */
		hasNext,

		/**
		 * Send user message
		 * @param {UserMessageCreateParams} params user message create params
		 * @param {function} [onPending] pending message callback
		 * @return {Promise<UserMessage>} succeeded message
		 * */
		sendUserMessage,

		/**
		 * Update user message
		 * @param {number} messageId
		 * @param {UserMessageUpdateParams} params user message update params
		 * @return {Promise<UserMessage>}
		 * */
		updateUserMessage,

		/**
		 * Resend failed message
		 * @template {UserMessage} T
		 * @param {T} failedMessage message to resend
		 * @return {Promise<T>}
		 * */
		resendMessage,

		/**
		 * Send file message
		 * @param {FileMessageCreateParams} params file message create params
		 * @param {function} [onPending] pending message callback
		 * @return {Promise<UserMessage>} succeeded message
		 * */
		sendFileMessage,

		/**
		 * Update file message
		 * @param {number} messageId
		 * @param {FileMessageUpdateParams} params file message update params
		 * @return {Promise<FileMessage>}
		 * */
		updateFileMessage,

		/**
		 * Delete a failed message
		 * @template {UserMessage} T
		 * @param {T} message failed message
		 * @return {Promise<void>}
		 * */
		deleteFailedMessage,

		/**
		 * Reset message list and create a new collection with starting point
		 * @param {number} startingPoint
		 * @param {function} callback
		 * @return {void}
		 * */
		resetWithStartingPoint,
	};
};
