import App from './App';
import AutoMessage from './AutoMessage';
import Basic from './Basic';
import { ManagingUsers } from './Basic/components/ManagingUsers';
import { ModifyUsers } from './Basic/components/ModifyUsers';
import { RegistrationUser } from './Basic/components/RegistrationUser';
import Group from './Group';
import GroupCreate from './Group/Create';
import GroupDetail from './Group/Detail';
import Phrase from './Phrase';

export default {
	App,
	Basic,
	Group,
	GroupDetail,
	GroupCreate,
	Phrase,
	ManagingUsers,
	RegistrationUser,
	ModifyUsers,
	AutoMessage,
};
