import React from 'react';

import styled from 'styled-components';

import { MINUTES, selectProps, timeInputLabels } from './consts';
import {
	type OpenDayType,
	type OneDayPeriodValue,
	type OneDayPeriodDetail,
	type OneDayPeriod,
	getHours,
} from './utils';
import { Label } from '../../../../components/Display';
import { Select } from '../../../../components/Forms';

interface TimeInputsProps {
	type: OpenDayType;
	times: OneDayPeriodValue;
	disabled?: boolean;
	dutyTimes?: OneDayPeriodValue;
	idx?: number;
	handleChangeTimes: (
		value: string | number,
		status: OneDayPeriod,
		type: OpenDayType,
		unit: OneDayPeriodDetail,
		idx?: number,
	) => void;
	prefix?: React.ReactNode;
}
export const TimeInputs = (props: TimeInputsProps) => {
	const { type, times, handleChangeTimes, disabled, idx, prefix, dutyTimes } = props;
	const startHours = getHours({ status: 'start', times, type, dutyTimes });
	const closeHours = getHours({ status: 'close', times, type, dutyTimes });

	return (
		<TimeInputArea>
			<Label {...timeInputLabels.default}>{'시작'}</Label>
			<DropdownWrapper>
				<Select
					{...selectProps.hour}
					options={startHours}
					selectKey={'key'}
					value={times.start.hour.key}
					onClick={(value) => {
						handleChangeTimes(value, 'start', type, 'hour', idx);
					}}
					disabled={disabled}
				/>
				<Label {...timeInputLabels.colon}>{':'}</Label>
				<Select
					{...selectProps.minute}
					options={MINUTES}
					selectKey={'key'}
					value={times.start.minute.key}
					onClick={(value) => {
						handleChangeTimes(value, 'start', type, 'minute', idx);
					}}
					disabled={disabled}
				/>
			</DropdownWrapper>
			<Label {...timeInputLabels.tilde}>{'~'}</Label>
			<Label {...timeInputLabels.default}>{'종료'}</Label>
			<DropdownWrapper>
				<Select
					{...selectProps.hour}
					options={closeHours}
					selectKey={'key'}
					value={times.close.hour.key}
					onClick={(value) => {
						handleChangeTimes(value, 'close', type, 'hour', idx);
					}}
					disabled={disabled}
				/>
				<Label {...timeInputLabels.colon}>{':'}</Label>
				<Select
					{...selectProps.minute}
					options={MINUTES}
					value={times.close.minute.key}
					onClick={(value) => {
						handleChangeTimes(value, 'close', type, 'minute', idx);
					}}
					disabled={disabled}
				/>
			</DropdownWrapper>
			{prefix}
		</TimeInputArea>
	);
};

const TimeInputArea = styled.div`
	display: flex;
	padding: 0 1.4rem 0 2rem;
	justify-content: flex-start;
	align-items: center;
	gap: 0.8rem;
	flex: 1 0 0;
	min-height: 4.8rem;
`;

const DropdownWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
`;
