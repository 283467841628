import { type RequestSalesProductType, type SalesProduct } from '../../../../services/sales/types';
import { decommaizeNumber } from '../../../../utils/format';

// overloading signature
export function onChangeProductForm(product: SalesProduct): RequestSalesProductType;
export function onChangeProductForm(products: SalesProduct[]): RequestSalesProductType[];

/**
 * 상품을 등록하는 형태로 바꿔주는 함수
 * @param {SalesProduct | SalesProduct[]} products
 * @returns {RequestSalesProductType | RequestSalesProductType[]}
 */
export function onChangeProductForm(
	products: SalesProduct | SalesProduct[],
): RequestSalesProductType | RequestSalesProductType[] {
	if (Array.isArray(products)) {
		return products.map((product) => ({
			productId: product.productId,
			isUsable: product.isUsable,
			discountPrice: decommaizeNumber(String(product.discountPrice)),
		}));
	} else {
		return {
			productId: products.productId,
			isUsable: products.isUsable,
			discountPrice: decommaizeNumber(String(products.discountPrice)),
		};
	}
}
