import React, { useContext, useMemo, useState } from 'react';

import { type GroupChannel, type Member } from '@sendbird/chat/groupChannel';
import { type UserMessage } from '@sendbird/chat/message';

import { type DisabledReason, getDisabledReason, getMember, getMessageTopOffset, isContextMenuClosed } from './utils';
import { useSBStateContext } from '../../../context/sendbirdSdk';
import { useAsyncEffect, useAsyncLayoutEffect } from '../../../hooks/useAsyncEffect';
import { useOnScrollPositionChangeDetectorWithRef } from '../../../hooks/useOnScrollPositionChangeDetector';
import { usePreservedCallback } from '../../../hooks/usePreservedCallback';
import { MessageSearchProvider } from '../../MessageSearch/context/MessageSearchProvider';
import { useChannelMessages } from '../hooks/useChannelMessages';
import { useMessageActions } from '../hooks/useMessageActions';
import { type ScrollTopics, type ScrollTopicUnion, useMessageListScroll } from '../hooks/useMessageListScroll';
import { usePreventDuplicateRequest } from '../hooks/usePreventDuplicateRequest';
import { type PubSubTypes } from '../pubSub';

type MessageActions = ReturnType<typeof useMessageActions>;
type MessageListDataSourceWithoutActions = Omit<ReturnType<typeof useChannelMessages>, keyof MessageActions>;

interface ChannelContextBaseType {
	// Required
	channelUrl: string;

	// Click
	onMemberInfoClick?: () => void;
	showMemberInfo?: boolean;
}

export interface ChannelContextType
	extends ChannelContextBaseType,
		MessageListDataSourceWithoutActions,
		MessageActions {
	currentChannel: GroupChannel | null;
	// 약사 아닌 채팅방 멤버
	member: Member;

	scrollRef: React.MutableRefObject<HTMLDivElement>;
	scrollDistanceFromBottomRef: React.MutableRefObject<number>;
	scrollPubSub: PubSubTypes<ScrollTopics, ScrollTopicUnion>;

	// Message Focusing
	animatedMessageId: number | null;
	setAnimatedMessageId: React.Dispatch<React.SetStateAction<number | null>>;
	onMessageAnimated: () => void;
	onSearchMessageClick: (message: UserMessage) => void;

	isScrollBottomReached: boolean;
	setIsScrollBottomReached: React.Dispatch<React.SetStateAction<boolean>>;

	// Scroll Action
	scrollToBottom: () => void;
	scrollToMessage: (createdAt: number, messageId: number) => void;

	// send message disabled (frozen, mute, invalid channel url)
	disabledReason: DisabledReason;
}

export interface ChannelProviderProps extends ChannelContextBaseType {
	children?: React.ReactNode;
}

const ChannelContext = React.createContext<ChannelContextType | null>(null);

export const ChannelProvider = (props: ChannelProviderProps) => {
	const { channelUrl, children, showMemberInfo, onMemberInfoClick } = props;

	const { stores, config } = useSBStateContext();
	const { sdkStore, userStore } = stores;
	const { markAsReadScheduler } = config;

	// State
	const [currentChannel, setCurrentChannel] = useState<GroupChannel | null>(null);
	const [startingPoint, setStartingPoint] = useState<number | null>(null);
	const [animatedMessageId, setAnimatedMessageId] = useState<number | null>(null);

	// Ref
	const { scrollRef, scrollPubSub, scrollDistanceFromBottomRef, isScrollBottomReached, setIsScrollBottomReached } =
		useMessageListScroll();

	const preventDuplicateRequest = usePreventDuplicateRequest();
	const messageDataSource = useChannelMessages(sdkStore.sdk, currentChannel!, {
		startingPoint: startingPoint ?? undefined,
		shouldCountNewMessages: () => !isScrollBottomReached,
		markAsRead: (channels) => {
			if (isScrollBottomReached) {
				channels.forEach((channel) => {
					markAsReadScheduler.push(channel);
				});
			}
		},
		onMessagesReceived: () => {
			if (isScrollBottomReached && isContextMenuClosed()) {
				scrollPubSub.publish('scrollToBottom', {});
			}
		},
		// 서버에서 보내는 자동 응답 메시지(BUSY, CLOSE 등) 발신 시 가장 하단으로
		onMessagesSentByMe: () => {
			scrollPubSub.publish('scrollToBottom', {});
		},
		onChannelUpdated: (channel) => {
			setCurrentChannel(channel);
		},
	});

	useOnScrollPositionChangeDetectorWithRef(scrollRef, {
		async onReachedTop() {
			preventDuplicateRequest.lock();

			await preventDuplicateRequest.run(async () => {
				if (!messageDataSource.hasPrevious() || !scrollRef.current) return;

				const prevViewInfo = {
					scrollTop: scrollRef.current.scrollTop,
					scrollHeight: scrollRef.current.scrollHeight,
				};
				await messageDataSource.loadPrevious();

				setTimeout(() => {
					const nextViewInfo = {
						scrollHeight: scrollRef.current?.scrollHeight,
					};
					const viewUpdated = prevViewInfo.scrollHeight < nextViewInfo.scrollHeight;

					if (viewUpdated) {
						const bottomOffset = prevViewInfo.scrollHeight - prevViewInfo.scrollTop;
						scrollPubSub.publish('scroll', {
							top: nextViewInfo.scrollHeight - bottomOffset,
							lazy: false,
						});
					}
				});
			});

			preventDuplicateRequest.release();
		},
		async onReachedBottom() {
			preventDuplicateRequest.lock();

			await preventDuplicateRequest.run(async () => {
				if (!messageDataSource.hasNext() || !scrollRef.current) return;

				const prevViewInfo = {
					scrollTop: scrollRef.current.scrollTop,
					scrollHeight: scrollRef.current.scrollHeight,
				};
				await messageDataSource.loadNext();

				setTimeout(() => {
					const nextViewInfo = {
						scrollHeight: scrollRef.current?.scrollHeight,
					};
					const viewUpdated = prevViewInfo.scrollHeight < nextViewInfo.scrollHeight;

					if (viewUpdated) {
						scrollPubSub.publish('scroll', {
							top: prevViewInfo.scrollTop,
							lazy: false,
							animated: false,
						});
					}
				});
			});

			preventDuplicateRequest.release();

			if (currentChannel && !messageDataSource.hasNext()) {
				messageDataSource.resetNewMessages();
				markAsReadScheduler.push(currentChannel);
			}
		},
	});

	// SideEffect: Fetch and set to current channel by channelUrl prop.
	useAsyncEffect(async () => {
		if (sdkStore.initialized && channelUrl) {
			try {
				const channel = await sdkStore.sdk.groupChannel.getChannel(channelUrl);
				setCurrentChannel(channel);
			} catch (e) {
				setCurrentChannel(null);
			} finally {
				setStartingPoint(null);
				setAnimatedMessageId(null);
			}
		}
	}, [sdkStore.initialized, sdkStore.sdk, channelUrl]);

	// SideEffect: Scroll to the bottom
	//  - On the initialized message list
	//  - On messages sent from the thread
	useAsyncLayoutEffect(async () => {
		if (messageDataSource.initialized) {
			// it prevents message load from previous/next before scroll to bottom finished.
			preventDuplicateRequest.lock();
			await preventDuplicateRequest.run(async () => {
				await new Promise<void>((resolve) => {
					scrollPubSub.publish('scrollToBottom', { resolve, animated: false });
				});
			});
			preventDuplicateRequest.release();
		}

		return () => {
			scrollPubSub.publish('scrollToBottom', { animated: false });
		};
	}, [messageDataSource.initialized, channelUrl]);

	const scrollToBottom = usePreservedCallback(async () => {
		if (!scrollRef.current) return;

		setAnimatedMessageId(null);
		setIsScrollBottomReached(true);

		if (messageDataSource.hasNext()) {
			await messageDataSource.resetWithStartingPoint(Number.MAX_SAFE_INTEGER);
			scrollPubSub.publish('scrollToBottom', {});
		} else {
			scrollPubSub.publish('scrollToBottom', {});
		}

		if (currentChannel && !messageDataSource.hasNext()) {
			messageDataSource.resetNewMessages();
			markAsReadScheduler.push(currentChannel);
		}
	});
	/**
	 * 검색 된 메시지로 이동 시 사용
	 */
	const scrollToMessage = usePreservedCallback(async (createdAt: number, messageId: number) => {
		setAnimatedMessageId(null);
		const message = messageDataSource.messages.find((it) => it.messageId === messageId || it.createdAt === createdAt);
		if (message) {
			const topOffset = getMessageTopOffset(message.createdAt);
			if (topOffset) scrollPubSub.publish('scroll', { top: topOffset });
			setAnimatedMessageId(messageId);
		} else {
			await messageDataSource.resetWithStartingPoint(createdAt);
			setTimeout(() => {
				const topOffset = getMessageTopOffset(createdAt);
				if (topOffset) scrollPubSub.publish('scroll', { top: topOffset, lazy: false });
				setAnimatedMessageId(messageId);
			});
		}
	});

	const messageActions = useMessageActions({
		...props,
		...messageDataSource,
	});

	const onSearchMessageClick = (message: UserMessage) => {
		if (message.messageId === animatedMessageId) {
			setAnimatedMessageId(null);
			setTimeout(() => {
				setAnimatedMessageId(message.messageId);
			});
		} else {
			setStartingPoint(message.createdAt);
			scrollToMessage(message.createdAt, message.messageId);
		}
	};

	const disabledReason = useMemo(() => {
		return getDisabledReason(currentChannel, userStore.user.userId);
	}, [
		currentChannel?.url,
		currentChannel?.isFrozen,
		currentChannel?.members.find(({ userId }) => userId !== userStore.user.userId)?.isMuted,
	]);

	const member = useMemo(() => {
		return getMember(currentChannel, userStore.user.userId);
	}, [currentChannel, currentChannel?.members, userStore.user.userId]);

	return (
		<ChannelContext.Provider
			value={{
				// # Props
				channelUrl,
				showMemberInfo,

				// ## Focusing
				onMessageAnimated: () => {
					setAnimatedMessageId(null);
				},

				// ## Click
				onMemberInfoClick,
				onSearchMessageClick,

				// Internal Interface
				currentChannel,
				member,

				scrollRef,
				scrollDistanceFromBottomRef,
				scrollPubSub,

				animatedMessageId,
				setAnimatedMessageId,

				isScrollBottomReached,
				setIsScrollBottomReached,

				scrollToBottom,
				scrollToMessage,

				...messageDataSource,
				...messageActions,

				disabledReason,
			}}
		>
			<MessageSearchProvider
				channelUrl={channelUrl}
				currentChannel={currentChannel}
				onSearchMessageClick={onSearchMessageClick}
			>
				{children}
			</MessageSearchProvider>
		</ChannelContext.Provider>
	);
};

export const useChannelContext = () => {
	const context = useContext(ChannelContext);
	if (!context) {
		throw new Error('ChannelContext not found. Use within the Channel module');
	}
	return context;
};
