import React from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Icon, IconID } from '../../../../components/Display';
import { useChannelContext } from '../../context/ChannelProvider';

import type { CoreMessageType } from '../../utils';

interface FailedMessageMenuProps {
	message: CoreMessageType;
}

export const FailedMessageMenu = ({ message }: FailedMessageMenuProps) => {
	const { resendMessage, deleteFailedMessage } = useChannelContext();

	return (
		<Wrapper>
			<ButtonWrapper
				onClick={() => {
					if (message.isResendable) {
						resendMessage(message);
					} else {
						toast.error(`재전송할 수 없는 메시지입니다.`);
					}
				}}
			>
				<Icon id={IconID.BTN_RESEND} width={'1.6rem'} height={'1.6rem'} />
			</ButtonWrapper>
			<Divider />
			<ButtonWrapper
				onClick={() => {
					deleteFailedMessage(message);
				}}
				style={{ padding: '0.6rem' }}
			>
				<Icon id={IconID.BTN_DELETE_FAILED} width={'1.2rem'} height={'1.2rem'} />
			</ButtonWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.red.red_500};
	overflow: hidden;
`;

const ButtonWrapper = styled.div`
	display: flex;
	padding: 0.4rem;
	align-items: flex-start;
	cursor: pointer;
`;

const Divider = styled.span`
	width: 1px;
	align-self: stretch;
	background-color: ${({ theme }) => theme.colors.red.red_400};
`;
