import React from 'react';

import { PRESCRIPTIONS_ROWS } from './rows';
import { useGetPrescriptionsTotal } from '../../../../services/statistics/queries';

import { DescriptionCard } from './index';

interface PrescriptionsCardProps {
	storeId: string;
	startDate: string;
	endDate: string;
}

export function PrescriptionsCard({ storeId, startDate, endDate }: PrescriptionsCardProps) {
	const { data, isLoading, isSuccess } = useGetPrescriptionsTotal({
		storeId,
		startDate,
		endDate,
	});

	return (
		<DescriptionCard
			data={data}
			title={'조제'}
			isLoading={isLoading || !isSuccess}
			rowGaps={{ horizon: '4rem', vertical: '1.6rem' }}
			{...PRESCRIPTIONS_ROWS}
		/>
	);
}
