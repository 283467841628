import React from 'react';

import { type UserMessage } from '@sendbird/chat/message';
import { format } from 'date-fns/format';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';

import { IconButton } from '../../../../components/Buttons';
import { HStack, VStack } from '../../../../components/Common';
import { Icon, IconID, Label } from '../../../../components/Display';
import { removeImageUrls } from '../../../Channel/utils';
import { useMessageSearchContext } from '../../context/MessageSearchProvider';

interface SearchMessageItemProps {
	requestString: string;
	message: UserMessage;
}

export const SearchMessageItem = ({ requestString, message }: SearchMessageItemProps) => {
	const createdAt = format(message.createdAt, 'MMM do', { locale: ko });
	const { onSearchMessageClick, onSearchResult } = useMessageSearchContext();

	return (
		<Wrapper>
			<MessageWrapper>
				<Label $fontStyle={'body_2'}>{highlightedText(message.message, requestString)}</Label>
				<Label $fontStyle={'caption_1'} $color={'gray_700'}>
					{createdAt}
				</Label>
			</MessageWrapper>
			<IconButton
				onClick={() => {
					onSearchMessageClick(message);
					onSearchResult();
				}}
			>
				<Icon id={IconID.ARROW_NARROW} />
			</IconButton>
		</Wrapper>
	);
};

export const highlightedText = (text: string, query: string) => {
	if (query !== '') {
		const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		text = removeImageUrls(text);
		const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'));
		return (
			<>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? <mark key={index}>{part}</mark> : part,
				)}
			</>
		);
	}
	return text;
};

const Wrapper = styled(HStack)`
	padding: 1rem 0;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
`;

const MessageWrapper = styled(VStack)`
	align-items: flex-start;
	gap: 0.2rem;
	overflow: hidden;
	word-break: break-word;

	mark {
		background-color: transparent;
		color: ${({ theme }) => theme.colors.primary.primary_600};
	}
`;
