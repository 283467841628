import React from 'react';

import styled from 'styled-components';

import { IconButton } from '../../Buttons';
import { Icon, IconID } from '../../Display';

const buttonStyle = {
	width: '6rem',
	height: '6rem',
};
interface SliderProps {
	onLeftClick: () => void;
	onRightClick: () => void;
}
export const Slider = ({ onLeftClick, onRightClick }: Partial<SliderProps>) => {
	return (
		<>
			<ButtonWrapper className={'left'}>
				<IconButton {...buttonStyle} onClick={onLeftClick}>
					<Icon id={IconID.VIEWER_ARROW} {...buttonStyle} />
				</IconButton>
			</ButtonWrapper>
			<ButtonWrapper className={'right'}>
				<IconButton {...buttonStyle} onClick={onRightClick}>
					<Icon id={IconID.VIEWER_ARROW} {...buttonStyle} />
				</IconButton>
			</ButtonWrapper>
		</>
	);
};

const ButtonWrapper = styled.div`
	width: 6rem;
	height: 6rem;
	position: absolute;
	top: calc(50% - 3rem);
	&.left {
		left: 24px;
	}
	&.right {
		right: 24px;
		transform: rotate(180deg);
	}
`;
