import React, { type ButtonHTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

interface SwitchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isOn?: boolean;
	toggleSwitch?: (value: boolean) => void;
}
export const SwitchButton = ({ isOn = false, onClick, toggleSwitch, ...rest }: SwitchButtonProps) => {
	return (
		<SwitchButtonWrap
			{...rest}
			type={'button'}
			role={'switch'}
			$isOn={isOn}
			tabIndex={-1}
			onClick={(e) => {
				onClick?.(e);
				toggleSwitch?.(!isOn);
			}}
		>
			<SwitchRound aria-hidden={true} $isOn={isOn}></SwitchRound>
		</SwitchButtonWrap>
	);
};

const SwitchButtonWrap = styled.button<{ $isOn?: boolean }>`
	display: inline-flex;
	width: 3.2rem;
	background-color: ${({ theme, $isOn }) => ($isOn ? theme.colors.primary.primary_600 : theme.colors.gray.gray_400)};
	border-radius: 999px;
	padding: 0.2rem;
	transition: 0.25s background-color;

	&:disabled {
		pointer-events: none;
		background-color: ${({ theme }) => theme.colors.gray.gray_600};
	}
`;

const SwitchRound = styled.span<{ $isOn?: boolean }>`
	display: inline-block;
	width: 1.7rem;
	height: 1.7rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 999px;
	transition: 0.25s transform;
	${({ $isOn }) =>
		$isOn
			? css`
					transform: translate(1.1rem, 0);
				`
			: css`
					transform: translate(0, 0);
				`}
`;
