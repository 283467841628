/**
 * Note: `leading` has higher priority rather than `trailing`
 * */
export function useThrottle<T extends (...args: any[]) => void>(
	callback: T,
	delay: number,
	options: { leading?: boolean; trailing?: boolean } = {
		leading: true,
		trailing: false,
	},
) {
	let timer: NodeJS.Timeout | null = null;
	let trailingArgs: any[] | null = null;

	return ((...args: any[]) => {
		if (timer) {
			trailingArgs = args;
			return;
		}

		if (options.leading) {
			callback(...args);
		} else {
			trailingArgs = args;
		}

		const invoke = () => {
			if (options.trailing && trailingArgs) {
				callback(...trailingArgs);
				trailingArgs = null;
				timer = setTimeout(invoke, delay);
			} else {
				timer = null;
			}
		};

		timer = setTimeout(invoke, delay);
	}) as T;
}
