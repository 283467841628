import React from 'react';

import { ChannelUI } from './components/ChannelUI';
import { ChannelProvider, type ChannelProviderProps } from './context/ChannelProvider';

export interface ChannelProps extends ChannelProviderProps {}

const Channel = (props: ChannelProps) => {
	return (
		<ChannelProvider {...props}>
			<ChannelUI />
		</ChannelProvider>
	);
};

export default Channel;
