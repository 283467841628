import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useAuthContext } from '../../context/auth';

const UnRequireAuth = () => {
	const { isLogin } = useAuthContext();

	return isLogin ? <Navigate to="/crm/chat" state={{ isReplace: true }} replace /> : <Outlet />;
};

export default UnRequireAuth;
