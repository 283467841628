import React, { type CSSProperties, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { changeFontKeyToType } from '../../../utils/changeFontKeyToType';
import { type StyledLabelProps } from '../Label';

interface TabContent {
	label: string | React.ReactElement;
	panel?: React.ReactNode;
	id?: string;
	disabled?: boolean;
}

interface BorderStyle {
	weight?: { selected?: string; default?: string };
}

interface TabStyleProps {
	tabGaps?: { vertical?: string; horizon?: string };
	tabStyle?: CSSProperties;
	hasBorderBottom?: boolean;
	labelStyle?: StyledLabelProps;
	borderStyle?: BorderStyle;
}
interface TabsProps extends TabStyleProps {
	defaultIndex?: number;
	onChange?: (idx: number, id?: string) => void;
	panel?: React.ReactNode;
	contents: TabContent[];
	disableAutoSelect?: boolean;
}
export const Tabs = (props: TabsProps) => {
	const {
		defaultIndex = 0,
		onChange,
		contents,
		panel,
		disableAutoSelect = false,
		tabGaps,
		tabStyle,
		hasBorderBottom = false,
		labelStyle,
		borderStyle,
	} = props;
	const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

	useEffect(() => {
		if (disableAutoSelect) {
			setSelectedIndex(defaultIndex);
		}
	}, [defaultIndex, disableAutoSelect]);

	return (
		<TabGroup $gap={tabGaps?.vertical}>
			<TabList $gap={tabGaps?.horizon} $hasBorderBottom={hasBorderBottom}>
				{contents.map(({ label, id }, index) => (
					<Tab
						style={{ ...tabStyle }}
						{...labelStyle}
						key={index}
						$isSelected={selectedIndex === index}
						role={'button'}
						onClick={() => {
							!disableAutoSelect && setSelectedIndex(index);
							onChange && onChange(index, id);
						}}
						$borderStyle={borderStyle}
					>
						{label}
					</Tab>
				))}
			</TabList>
			{panel ?? contents[selectedIndex].panel}
		</TabGroup>
	);
};

const TabGroup = styled.div<{ $gap?: string }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	${({ $gap }) =>
		$gap &&
		css`
			gap: ${$gap};
		`}
`;

const TabList = styled.div<{ $gap?: string; $hasBorderBottom?: boolean }>`
	display: flex;
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.white};
	position: relative;

	${({ $gap }) =>
		$gap &&
		css`
			gap: ${$gap};
		`}

	${({ $hasBorderBottom }) =>
		$hasBorderBottom &&
		css`
			&::before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray_300};
			}
		`}
`;

const Tab = styled.div<{ $isSelected?: boolean; $borderStyle?: BorderStyle } & StyledLabelProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
	cursor: pointer;
	${({ $borderStyle }) => {
		const { weight } = $borderStyle ?? { weight: { default: '1px' } };
		return css`
			border-bottom: ${weight?.default} solid ${({ theme }) => theme.colors.gray.gray_400};
		`;
	}}
	color: ${({ theme }) => theme.colors.gray.gray_700};
	${({ $fontStyle }) => $fontStyle && changeFontKeyToType($fontStyle)};
	${({ theme, $fontWeight = 'regular' }) => $fontWeight && theme.font.weight[$fontWeight]}

	${({ theme, $isSelected, $borderStyle }) => {
		const { weight } = $borderStyle ?? { weight: { selected: '2.5px' } };
		return (
			$isSelected &&
			css`
				border-bottom: ${weight?.selected} solid ${theme.colors.primary.primary_600};
				color: ${theme.colors.primary.primary_600};
				${theme.font.weight.bold};
			`
		);
	}};
`;
