import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PROMOTION_LIST_COLUMNS } from './const';
import { VStack } from '../../../components/Common';
import { ConfirmModal } from '../../../components/Modals';
import { ListTable } from '../../../components/Table';
import context from '../../../context';
import { useGetPromotions, usePatchPromotionDelivery } from '../../../services/promotion/queries';
import { type Promotion, type PromotionStatus } from '../../../services/promotion/types';

const initialData: Record<PromotionStatus, Promotion[]> = {
	COMPLETED: [],
	READY: [],
	RUNNING: [],
	WAIT: [],
};

export const List = () => {
	const navigate = useNavigate();

	const { userInfo } = context.user.useValue();
	const { handleOpen } = context.modal.useDispatch();
	const { data, refetch, isLoading, queries, pagination } = useGetPromotions({ storeId: userInfo.storeId });
	const { mutate } = usePatchPromotionDelivery({
		onSuccess: () => {
			refetch();
			toast.success(`변경에 성공하였습니다.`);
		},
		onError: () => {
			toast.error(`변경에 실패하였습니다.`);
		},
	});

	const convertData = useMemo(() => {
		return (
			data?.content.reduce(
				(acc, cur) => {
					const statusGroup = acc[cur.status];
					return {
						...acc,
						[cur.status]: [...statusGroup, cur],
					};
				},
				{ ...initialData },
			) ?? initialData
		);
	}, [data]);

	const onChangeDelivery = useCallback(
		(item: Promotion) => {
			handleOpen(
				<ConfirmModal
					confirm={() => {
						mutate({
							storeId: userInfo.storeId,
							id: item.id,
							isDeliveryAvailable: !item.isDeliveryAvailable,
						});
					}}
					message={'배송 가능 여부를 변경하시겠습니까?'}
				/>,
			);
		},
		[userInfo],
	);

	const onNavigate = useCallback(
		(id: number) => {
			navigate(`/promotion/list/detail/${id}`);
		},
		[queries, pagination],
	);

	return (
		<VStack $gap={'4rem'}>
			<ListTable
				data={[...convertData.RUNNING, ...convertData.WAIT]}
				columns={PROMOTION_LIST_COLUMNS({ onChangeDelivery })}
				isScroll={false}
				isLoading={isLoading}
				emptyMessage="신청한 이벤트가 없습니다."
				onRowClick={(item) => {
					onNavigate(item.id);
				}}
				hasLastItemBorder={false}
			/>
		</VStack>
	);
};
